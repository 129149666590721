import { DEFAULT_TIMEZONE } from '../../../../environments/environment'
import { MdbAnalysisResultsId, MdbAnalysisLoadEventDetectionResults } from '../@types/load-event-detection'
import { IHttpResponse, IHttpService, IPromise } from 'angular'
import {
  AnalysisHttpService,
  AnalysisHttpServiceName,
  CreateLoadEventResponse,
  PendingData
} from '../analysis-http.service'
import { FerroConfiguration, FerroConfigurationName, GraphColorsFlatten } from '../../../service'
import { serviceClb } from '../../../service/types.service'
import fuseCurves from './fuse-curves.json'
import { EChartOption } from 'echarts'

export interface ParsedLoadEventJobsResponse {
  withoutAce: any
  withAce: any
  starttime: Date
  endtime: Date
  timestamp: Date
  yMax: number
  yMin: number
  xMax: number
  fuseAnnotations: any
}

export const FuseAnalysisServiceName = 'fuseAnalysisService'

export class FuseAnalysisService {
  id: string
  facilityId: number
  timezone = DEFAULT_TIMEZONE
  canceller: IPromise<unknown>

  aService: AnalysisHttpService
  $http: IHttpService
  colors: GraphColorsFlatten

  constructor(
    $http: IHttpService,
    ferroConfiguration: FerroConfiguration,
    analysisService: AnalysisHttpService
  ) {
    this.$http = $http
    this.aService = analysisService
    this.colors = ferroConfiguration.graphsColors
  }

  init(
    // EHUB MAC ADDRESS
    id: string,
    facilityId: number,
    timezone: string,
    canceller: IPromise<unknown>
  ): void {
    this.id = id
    this.facilityId = facilityId
    this.timezone = timezone
    this.canceller = canceller
  }

  getJob(
    resultsId: MdbAnalysisResultsId,
    callback: serviceClb<IHttpResponse<string> | PendingData, MdbAnalysisLoadEventDetectionResults>
  ): void {
    this.aService.getLoadEventJob(resultsId, this.facilityId, 'scatter', (error, data) => {
      if (error && (error as PendingData).processingstatus === 'PENDING') {
        callback(error as unknown as PendingData)
        return
      } else if (error) {
        callback(error)
      } else {
        callback(null, data)
      }
    })
  }

  createJob(
    starttime: Date,
    endtime: Date,
    callback: serviceClb<IHttpResponse<string>, CreateLoadEventResponse>
  ): void {
    this.aService.createJob(starttime, endtime, this.facilityId, callback)
  }

  getFuseCurves(callback: serviceClb<IHttpResponse<string>, EChartOption.SeriesLine[]>): void {
    this.$http<EChartOption.SeriesLine[]>({
      method: 'GET',
      url: fuseCurves as unknown as string
    }).then(result => {
      callback(null, result.data)
    }, callback)
  }
}

FuseAnalysisService.$inject = ['$http', FerroConfigurationName, AnalysisHttpServiceName]
