import {
  DefaultEmsConfigScheduleInput,
  EmsConfig,
  EmsConfigSchedule,
  EmsConfigScheduleInput,
  SystemControlTopologyInput
} from '@app/graphql/generated'
import { copy } from 'angular'

import { EmsConfigV2HttpService } from '@app/service/ems-config-v2-http.service'
import { EmsParam } from '@ferroamp/system-configuration-lib'
import { runQuery } from '../graphql'
import { emsConfigFormToEmsParam, prepareEmsConfigScheduleForMutation } from './system-configuration.parsers'

export const SystemConfigurationGraphqlServiceName = 'systemConfigurationGraphqlService'

export class SystemConfigurationGraphqlService {
  constructor(private emsConfig: EmsConfigV2HttpService) {}

  getSystemControlTopology(facilityId: number) {
    return runQuery.getSystemControlTopologyQuery({
      variables: { facilityId },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache'
    })
  }

  setSystemControlTopology(facilityId: number, topology: SystemControlTopologyInput) {
    return runQuery.setSystemControlTopologyMutation({
      variables: { facilityId: `${facilityId}`, data: topology }
    })
  }

  getEmsConfig(facilityId: number) {
    return runQuery.getEmsConfigQuery({
      variables: { facilityId },
      fetchPolicy: 'no-cache'
    })
  }
  // getEmsConfigSchedule() { }
  getEmsConfigSchedules(facilityId: number) {
    return runQuery.getEmsConfigSchedulesQuery({
      variables: { facilityId, excludeDefault: true },
      fetchPolicy: 'no-cache',
      errorPolicy: 'all'
    })
  }

  createEmsConfigSchedule(facilityId: number, emsConfigSchedule: EmsConfigScheduleInput | EmsConfigSchedule) {
    return runQuery.createEmsConfigScheduleMutation({
      variables: {
        facilityId: `${facilityId}`,
        emsConfigSchedule: prepareEmsConfigScheduleForMutation(emsConfigSchedule) as EmsConfigScheduleInput
      }
    })
  }

  updateEmsConfigSchedule(facilityId: number, emsConfigSchedule: EmsConfigSchedule) {
    const id = copy(emsConfigSchedule).id
    delete emsConfigSchedule.id
    emsConfigSchedule.emsParam = emsConfigFormToEmsParam(emsConfigSchedule.emsParam) as EmsConfig
    return runQuery.updateEmsConfigScheduleMutation({
      variables: {
        facilityId: `${facilityId}`,
        id,
        emsConfigSchedule: prepareEmsConfigScheduleForMutation(emsConfigSchedule) as EmsConfigScheduleInput
      }
    })
  }
  deleteEmsConfigSchedule(facilityId: number, id: string) {
    return runQuery.deleteEmsConfigScheduleMutation({
      variables: { facilityId: `${facilityId}`, id }
    })
  }

  async setDefaultEmsConfigSchedule(
    facilityId: number,
    defaultEmsConfigSchedule: DefaultEmsConfigScheduleInput,
    isActive?: boolean
  ) {
    const res = runQuery.setDefaultEmsConfigScheduleMutation({
      variables: {
        facilityId: `${facilityId}`,
        defaultEmsConfigSchedule: prepareEmsConfigScheduleForMutation(defaultEmsConfigSchedule)
      }
    })

    if (isActive) {
      const setRes = await this.emsConfig.initiateSetEmsConfig(
        facilityId,
        defaultEmsConfigSchedule.emsParam as EmsParam
      )
      if (setRes?.status !== 201) throw new Error('Couldnt set')
    }

    return res
  }
}

SystemConfigurationGraphqlService.$inject = ['emsConfigV2HttpService']
