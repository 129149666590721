import { IComponentOptions } from 'angular'

import templateUrl from './dashboard-block.component.html'
import './dashboard-block.component.scss'

class DashboardBlockController {
  feTitle: string
  imageId: string
  feInfo: string
  feClass: string
  feShow: boolean
  onLoaded: () => void

  $onInit(): void {
    if (this.onLoaded) {
      this.onLoaded()
    }
  }
}

export const DashboardBlockComponentName = 'dashboardBlock'

export const DashboardBlockComponent: IComponentOptions = {
  controller: DashboardBlockController,
  templateUrl,
  bindings: {
    feTitle: '<',
    feInfo: '<',
    feClass: '@',
    onLoaded: '&',
    feTooltip: '<'
  },
  transclude: true
}
