import { languagesAvailable } from '@app/service/language.service'

/*
Frickin hack to parse this:

"\"battery-maintenance-regular (RD01Z00019160004    ): Ongoing\" taint present,
which does not allow setting [\"ems-config-set: Ongoing\"]
(All non-tolerations are: [\"battery-maintenance-critical: Ongoing\",
\"battery-maintenance-regular: Ongoing\",
\"softwareupdate-sbc: Ongoing\",
\"fcr-event: Ongoing\",
\"softwareupdate-plc: Ongoing\",
\"softwareupdate-bms: Ongoing\",
\"ems-config-set: Ongoing\",
\"softwareupdate-epoc: Ongoing\"])"
*/

enum EmsConfigFailureReasonsTaints {
  BatteryMaintenanceRegularTaint = 'battery-maintenance-regular',
  BatteryMaintenanceCriticalTaint = 'battery-maintenance-critical',
  SoftwareUpdateSbc = 'softwareupdate-sbc',
  SoftwareUpdatePlc = 'softwareupdate-plc',
  SoftwareUpdateBms = 'softwareupdate-bms',
  SoftwareUpdateEpoc = 'softwareupdate-epoc',
  EmsConfigSet = 'ems-config-set',
  FcrEvent = 'fcr-event'
}

type taints = { [key in EmsConfigFailureReasonsTaints]: string }
const languages: {
  [key in languagesAvailable]: taints & {
    NOT_ALL_TERMINAL: string
    UNKNOWN_ERROR: string
    SCM_TIMEOUT: string
  }
} = {
  se: {
    'ems-config-set': 'Konfigurering av driftinställningar i konflikt. Vänligen försök igen om en stund',
    'softwareupdate-bms':
      'Batterihanteringssystemet uppdateras. Driftinställningar kan därför inte konfigureras just nu. Vänligen försök igen om en stund',
    'battery-maintenance-critical':
      'Batterierna genomgår kritiskt underhåll. Driftinställningar kan därför inte konfigureras just nu. Vänligen försök igen om en stund',
    'battery-maintenance-regular':
      'Batterierna genomgår rutinunderhåll. Driftinställningar kan därför inte konfigureras just nu. Vänligen försök igen om en stund',
    'softwareupdate-epoc':
      'Firmware uppdateras. Driftinställningar kan därför inte konfigureras just nu. Vänligen försök igen om en stund',
    'softwareupdate-plc':
      'En av DC-nätets noder uppdateras för närvarande. Driftinställningar kan därför inte just nu. Vänligen försök igen om en stund',
    'softwareupdate-sbc':
      'Systemets mjukvara uppdateras. Driftinställningar kan därför inte konfigureras just nu. Vänligen försök igen om en stund',
    'fcr-event':
      'Stödtjänster är aktiva. Driftinställningar kan därför inte konfigureras just nu. Vänligen försök igen om en stund',
    NOT_ALL_TERMINAL:
      'En eller flera av DC-nätets noder kunde inte kontaktas. Driftinställningar kan därför inte konfigureras just nu. Vänligen försök igen om en stund',
    UNKNOWN_ERROR:
      'Ett oväntat fel inträffade. Driftinställningar kan därför inte konfigureras just nu. Vänligen försök igen om en stund',
    SCM_TIMEOUT:
      'Systemet tidsgräns överskreds. Driftinställningar kan därför inte konfigureras just nu. Vänligen försök igen om en stund'
  },
  us: {
    'ems-config-set': 'Configuring conflicting operating settings. Please try again later.',
    'softwareupdate-bms':
      'The battery management system is currently being updated. Operational settings cannot be configured at this moment. Please try again later.',
    'battery-maintenance-critical':
      'The batteries are undergoing critical maintenance. Operational settings cannot be configured at this moment. Please try again later.',
    'battery-maintenance-regular':
      'The batteries are undergoing routine maintenance. Operational settings cannot be configured at this moment. Please try again later.',
    'softwareupdate-epoc':
      'The firmware is currently being updated. Operational settings cannot be configured at this moment. Please try again later.',
    'softwareupdate-plc':
      'One of the DC network nodes is currently being updated. Operational settings cannot be configured at this moment. Please try again later.',
    'softwareupdate-sbc':
      'The system software is currently being  updated. Operational settings cannot be configured at this moment. Please try again later.',
    'fcr-event':
      'FCR services are currently active. Operational settings cannot be configured at this moment. Please try again later.',
    NOT_ALL_TERMINAL:
      'One or more of the DC network nodes did not respond. Operational settings cannot be configured at this moment. Please try again later.',
    UNKNOWN_ERROR:
      'An unexpected error occurred. Operational settings cannot be configured at this moment. Please try again later.',
    SCM_TIMEOUT:
      'The system timed out. Operational settings cannot be configured at this moment. Please try again later.'
  }
}

const regexTests = (lang: (typeof languages)['us']) => ({
  'SCM: Not all': lang.NOT_ALL_TERMINAL,
  'SCM: Timeout': lang.SCM_TIMEOUT
})

export const reasonParser = ({ reason, language }: { reason?: string; language: languagesAvailable }) => {
  const texts = languages[language]
  const taintReason: EmsConfigFailureReasonsTaints = reason
    .replace(/"/g, '')
    .split(':')
    .at(0)
    ?.split(' ')
    ?.at(0)
    ?.replace(/\s/g, '') as EmsConfigFailureReasonsTaints
  const taint = Object.values(EmsConfigFailureReasonsTaints).find(r => {
    return taintReason === r
  })
  if (taint) return texts[taint]

  const re = regexTests(texts)
  const ops = Object.entries(re).find(([key]) => {
    return new RegExp(`^${key}`).test(reason)
  })
  if (ops) return ops.at(1)

  return texts.UNKNOWN_ERROR
}
