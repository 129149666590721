import { material } from 'angular'
import { Environment } from '../../environments/environment'
import moment from 'moment'

const english = {
  months: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ],
  shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  days: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
  shortDays: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
  firstDayOfWeek: 0,
  msgCalendar: 'Calendar',
  msgOpenCalendar: 'Open calendar',
  WEEK: 'Week'
}
const swedish = {
  months: [
    'Januari',
    'Februari',
    'Mars',
    'April',
    'Maj',
    'Juni',
    'Juli',
    'Augusti',
    'September',
    'Oktober',
    'November',
    'December'
  ],
  shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],
  days: ['Söndag', 'Måndag', 'Tisdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lördag'],
  shortDays: ['S', 'M', 'T', 'O', 'T', 'F', 'L'],
  firstDayOfWeek: 1,
  msgCalendar: 'Kalender',
  msgOpenCalendar: 'Öppna kalender',
  WEEK: 'Vecka'
}

export function localeConfig($mdDateLocaleProvider: material.IDateLocaleProvider): void {
  //@TODO use real language settings eg: sv-SE,en-US etc..
  //@TODO add format date on portal user meta database ?
  const language = Environment.language === 'us' ? english : swedish
  /**
   * reference https://material.angularjs.org/latest/api/service/$mdDateLocaleProvider#
   */

  $mdDateLocaleProvider.months = language.months
  $mdDateLocaleProvider.shortMonths = language.shortMonths
  $mdDateLocaleProvider.days = language.days
  $mdDateLocaleProvider.shortDays = language.shortDays
  $mdDateLocaleProvider.firstDayOfWeek = language.firstDayOfWeek
  $mdDateLocaleProvider.msgCalendar = language.msgCalendar
  $mdDateLocaleProvider.msgOpenCalendar = language.msgOpenCalendar
  // $mdDateLocaleProvider.firstRenderableDate = new Date(2014, 0, 1);
  // $mdDateLocaleProvider.lastRenderableDate = moment(new Date()).add(1, 'month').toDate();
  $mdDateLocaleProvider.formatDate = date => {
    return moment(date).format('YYYY-MM-DD')
  }

  $mdDateLocaleProvider.weekNumberFormatter = (weekNumber): string => {
    return `${language.WEEK} ${weekNumber}`
  }
}

localeConfig.$inject = ['$mdDateLocaleProvider']
