import { SystemInformationComponentName } from './system-information'
import { SystemConfigurationComponentName } from './system-configuration'
import { SystemTopologyV1ComponentName } from './system-topology/system-topology.v1.component'
import { ExportDataComponentName } from './export-data'
import { SystemUserAccessComponentName } from './system-user-access/system-user-access.component'
import { StateProvider } from '@uirouter/angularjs'
import { EmsConfigScheduleComponentName } from './system-configuration/ems-config-scheduler/ems-config-schedule.component'
import { AlarmsComponentName } from './alarms/alarms.component'
import { EmsSchedulePickerComponentName } from './system-configuration/ems-config-scheduler/ems-config-schedule-picker.component'

export const systemSettingsStates = {
  settings: 'system.settings',
  alarms: 'system.settings.alarms',
  systemInformation: 'system.settings.information',
  exportData: 'system.settings.exportData',
  cloudApi: 'system.settings.cloudApi',
  systemTopology: 'system.settings.topology',
  systemConfiguration: 'system.settings.systemConfiguration',
  scheduling: 'system.settings.systemConfigurationScheduling',
  schedulingEdit: 'system.settings.systemConfigurationSchedulingEdit',
  userAccess: 'system.settings.userAccess'
}

export function systemSettingsRouterConfig($stateProvider: StateProvider): void {
  $stateProvider
    .state(systemSettingsStates.settings, {
      url: '/settings',
      template: '<ui-view></ui-view>'
    })
    .state(systemSettingsStates.systemInformation, {
      url: '/information',
      component: SystemInformationComponentName
    })
    .state(systemSettingsStates.systemConfiguration, {
      url: '/configuration',
      component: SystemConfigurationComponentName
    })
    .state(systemSettingsStates.systemTopology, {
      url: '/topology',
      component: SystemTopologyV1ComponentName
    })
    .state(systemSettingsStates.exportData, {
      url: '/export-data',
      component: ExportDataComponentName
    })
    .state(systemSettingsStates.userAccess, {
      url: '/user-access',
      component: SystemUserAccessComponentName
    })
    .state(systemSettingsStates.scheduling, {
      url: '/scheduling',
      component: EmsConfigScheduleComponentName
    })
    .state(systemSettingsStates.schedulingEdit, {
      url: '/scheduling/edit?scheduleId&{isDefault:bool}&{isUpdating:bool}',
      component: EmsSchedulePickerComponentName
    })
    .state(systemSettingsStates.alarms, {
      url: '/alarms',
      component: AlarmsComponentName
    })
}

systemSettingsRouterConfig.$inject = ['$stateProvider']
