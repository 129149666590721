import { AppConfig } from '../types/window'

export const AVAILABLE_EXTERNAL_MODULES = {
  uiRouter: 'ui.router',
  mdDataTable: 'md.data.table',
  ngMaterial: 'ngMaterial',
  ngMessages: 'ngMessages',
  ngAnimate: 'ngAnimate',
  ngCookies: 'ngCookies',
  ngSanitize: 'ngSanitize',
  ngAria: 'ngAria'
}

const externalEnvironment = window.appConfig || ({} as unknown as AppConfig)

const { apiBaseUrl, authClientId, authREALM, authURL, roles } = externalEnvironment

export const Environment = {
  apiBase: apiBaseUrl,
  sub: '',
  isProd: true,
  environment: externalEnvironment.environment,
  release: externalEnvironment.release,
  language: '',
  userEmail: '',
  roles,
  userId: NaN,
  graphqlUri: apiBaseUrl,
  ip: externalEnvironment.ip,
  version: externalEnvironment.version,
  mapboxApiKey: externalEnvironment.mapboxApiKey,
  unleashUrl: `${apiBaseUrl}/unleash/proxy`,
  fronteendUnleashUrl: location.origin + '/frontend/features/proxy',
  unleashAppName: externalEnvironment.unleashAppName,
  keycloakUrl: authURL,
  keycloakRealm: authREALM,
  keycloakClient: authClientId,
  endpoints: {
    facilityData: apiBaseUrl + `/facility`,
    ehub: apiBaseUrl + `/ehub`,
    topologyV1: apiBaseUrl + '/settings/topology',
    systemStateUrl: apiBaseUrl + '/service/system-state/v1',
    analysisApiUrl: apiBaseUrl + '/service/analysis-api/v1',
    fns: apiBaseUrl + '/functions'
  },
  emsConfigV2Options: {
    wsNamespace: '',
    wsPath: '/service/ems-config/v1/io',
    emsConfChangedEvent: 'emsconfchanged',
    commandEvent: 'command',
    endpoint: apiBaseUrl + '/service/ems-config/v1'
  },
  systemStateV2Options: {
    wsNamespace: '',
    wsPath: '/service/system-state/v1/io',
    wsEvent: {
      statechanged: 'statechanged',
      command: 'command'
    },
    endpoint: apiBaseUrl + '/service/system-state/v1'
  },
  OpenWeatherMapApiKey: '29aa4964126da92c5a1231c1364a29ea'
}
export const OTHER_TRANSACTION_ONGOING_STRING = 'Other transaction ongoing'
export const DEFAULT_CURRENCY = 'SEK'
export const DEFAULT_TIMEZONE = 'Europe/Stockholm'
export const JWT_COOKIE_NAME = 'access_token'
