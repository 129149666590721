import { IComponentOptions, material } from 'angular'
import {
  LeftSideNavOptions,
  LeftSidenavTemplate,
  LeftSidenavTemplateUrl
} from '../components/left-sidenav/left-sidenav.component-template'
import {
  FeatureFlagService,
  FeatureFlagServiceName,
  FerroConfiguration,
  FerroConfigurationName,
  LanguageService
} from '../service'

import { RolesServices, RolesServicesName } from '@app/service/roles.services'
import englishProFaqUrl from '../../assets/docs/professional-deprecations/index-en.html'
import swedishProFaqUrl from '../../assets/docs/professional-deprecations/index-se.html'
import { LanguageServiceName } from '../service/language.service'
import { systemAnalysisStates } from './analysis/system-analysis.routing'
import { systemSettingsStates } from './settings/system-settings.routing.config'
import { SystemStates } from './system-routing.config'

class SystemSidebarController extends LeftSidenavTemplate {
  professionalTooltip: string
  proFAQUrl: string

  sections: LeftSideNavOptions[]
  isHidden = true
  title = 'Demo'

  constructor(
    $mdSidenav: material.ISidenavService,
    ferroConfiguration: FerroConfiguration,
    languageServices: LanguageService,
    _featureFlagService: FeatureFlagService,
    roles: RolesServices
  ) {
    super($mdSidenav)
    const languageAnalaysis = ferroConfiguration.language.SystemAnalysis
    const languageSystemSettings = ferroConfiguration.language.SystemSettings
    const languageSystemSidebar = ferroConfiguration.language.SystemSidebar
    this.professionalTooltip = languageSystemSidebar.PRO_INFORMATION
    this.proFAQUrl = languageServices.language === 'se' ? swedishProFaqUrl : englishProFaqUrl
    if (ferroConfiguration.facility) {
      this.title = ferroConfiguration.facility.name
    }

    const dashboard: LeftSideNavOptions = {
      uiref: SystemStates.dashboard,
      dis: false,
      ref: true,
      name: languageSystemSidebar.DASHBOARD,
      icon: 'dashboard',
      pro: false
    }

    const energyView: LeftSideNavOptions = {
      uiref: SystemStates.energyView,
      ref: true,
      dis: false,
      name: languageSystemSidebar.ENERGY_VIEW,
      icon: 'bar_chart',
      pro: false
    }

    const powerView: LeftSideNavOptions = {
      uiref: SystemStates.powerView,
      dis: false,
      ref: true,
      name: languageSystemSidebar.POWER_VIEW,
      icon: 'show_chart',
      pro: false
    }

    const liveData: LeftSideNavOptions = {
      uiref: SystemStates.liveData,
      ref: true,
      dis: false,
      name: languageSystemSidebar.LIVEDATA,
      icon: 'live_tv',
      pro: false
    }

    const ssoView: LeftSideNavOptions = {
      uiref: SystemStates.ssoView,
      ref: true,
      dis: false,
      name: languageSystemSidebar.SSO_VIEW,
      icon: 'wb_sunny',
      pro: false
    }

    const analysis: LeftSideNavOptions = {
      dis: false,
      click: true,
      name: languageAnalaysis.ANALYSIS,
      icon: 'analytics',
      hide: true,
      subsections: [
        {
          name: languageAnalaysis.DAILY_PROFILE,
          uiref: systemAnalysisStates.dailyTrend,
          pro: false
        },
        {
          name: languageAnalaysis.BATTERY_SIMULATION,
          uiref: systemAnalysisStates.batterySimulation,
          pro: false
        },
        {
          name: languageAnalaysis.SAVED_ANALYSIS,
          uiref: systemAnalysisStates.storedAnalysis,
          pro: false
        },
        {
          name: languageAnalaysis.FUSE_ANALYSIS,
          uiref: systemAnalysisStates.fuseAnalysis,
          pro: false
        },
        {
          name: languageAnalaysis.LOAD_SIGNATURE,
          uiref: systemAnalysisStates.loadSignature,
          pro: false
        }
      ]
    }

    const systemSettings: LeftSideNavOptions = {
      click: true,
      dis: false,
      name: languageSystemSettings.SYSTEM_SETTINGS,
      icon: 'settings',
      hide: true,
      subsections: [
        {
          uiref: systemSettingsStates.systemInformation,
          name: languageSystemSettings.SYSTEM_INFORMATION,
          pro: false
        },
        {
          uiref: systemSettingsStates.userAccess,
          name: languageSystemSettings.USER,
          pro: false
        },
        {
          uiref: systemSettingsStates.systemTopology,
          name: languageSystemSettings.SYSTEM_TOPOLOGY,
          pro: false
        },
        {
          uiref: systemSettingsStates.scheduling,
          name: ferroConfiguration.language.Scheduling.SCHEDULING,
          pro: false
        },
        {
          uiref: systemSettingsStates.systemConfiguration,
          name: languageSystemSettings.SYSTEM_CONFIGURATION,
          pro: false
        },
        {
          uiref: systemSettingsStates.alarms,
          name: languageSystemSettings.ALARMS,
          pro: false
        }
      ]
    }

    const menu = [dashboard, energyView, powerView, liveData, ssoView]

    menu.push(analysis)

    if (roles.canViewFacilitySystemSettings()) {
      menu.push(systemSettings)
    }

    this.sections = menu
  }
}

SystemSidebarController.$inject = [
  '$mdSidenav',
  FerroConfigurationName,
  LanguageServiceName,
  FeatureFlagServiceName,
  RolesServicesName
]

export const SystemSidebarComponentName = 'systemSidebarComponent'

export const SystemSideBarComponent: IComponentOptions = {
  controller: SystemSidebarController,
  templateUrl: LeftSidenavTemplateUrl
}
