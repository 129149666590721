import { IComponentOptions } from 'angular'
import './alarm-impact.component.scss'

export const AlarmImpactComponent: IComponentOptions = {
  template: `
    <div class="alarm-impact alarm-impact-{{$ctrl.impact}}">
      {{$ctrl.impact}}
    </div>
  `,

  bindings: {
    impact: '<'
  }
}
