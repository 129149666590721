import templateUrl from './evse-overview.component.html'
import { FerroConfiguration, FerroConfigurationName } from '../../../service'
import { LanguageSystemdashboard } from '../../../language/language.interface'
import { EvseStatus } from './evse-status.component'
import { energy2String, tooOneDecimal } from '../../../../lib/general'
import { echartsTimeFormatters } from '../../../../lib/echarts'
import { ObservableSubscription } from '@apollo/client'
import { runQuery } from '@app/graphql/graphql'
import { EvseMeterValue } from '@app/graphql/generated'
import { IScope } from 'angular'

class EvseOverviewController {
  l: LanguageSystemdashboard
  $scope: IScope

  timestamp: string
  status: EvseStatus = EvseStatus.OFFLINE
  evseMeter: EvseMeterValue = {
    energyActiveMeter: null,
    facilityId: null,
    terminalId: '',
    timestamp: ''
  }
  energy: string
  currents: {
    L1: number
    L2: number
    L3: number
  }

  timezone: string
  observerMeterValue: ObservableSubscription
  constructor(facilityConfiguration: FerroConfiguration, $scope: IScope) {
    this.$scope = $scope
    this.l = facilityConfiguration.language.SystemDashboard
    this.timezone = facilityConfiguration.facility.timezone

    //this.observerMeterValues
    const aabb = runQuery.onEvseMetervalueSubscription({
      variables: {
        facilityId: facilityConfiguration.facility.id.toString()
      }
    })

    this.observerMeterValue = aabb.subscribe(({ data }) => {
      this.onEvseMeterValues(data.newEvseMeterValue as EvseMeterValue)
    })
  }

  onEvseMeterValues(evseMeter: EvseMeterValue): void {
    this.$scope.$applyAsync(() => {
      this.evseMeter = evseMeter
      this.timestamp = echartsTimeFormatters(this.timezone).seconds(new Date(Number(evseMeter.timestamp)))
      this.currents = {
        L1: tooOneDecimal(evseMeter.currentL1),
        L2: tooOneDecimal(evseMeter.currentL2),
        L3: tooOneDecimal(evseMeter.currentL3)
      }
      this.energy = energy2String(evseMeter.energyActiveMeter)
      if (evseMeter.powerActive > 0) {
        this.status = EvseStatus.CHARGING
      } else {
        this.status = EvseStatus.ONLINE
      }
    })
  }

  $onDestroy(): void {
    if (this.observerMeterValue) this.observerMeterValue.unsubscribe()
  }
}

EvseOverviewController.$inject = [FerroConfigurationName, '$scope']

export const EvseOverviewComponent = {
  controller: EvseOverviewController,
  templateUrl
}
