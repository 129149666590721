import { copy } from 'angular'
import { EChartOption } from 'echarts'
import {
  defaultEchartsDataZoom,
  defaultEchartsGrid,
  defaultEchartsYAxisPower,
  echartsToolbox,
  echartsTooltip
} from '../../../../lib/echarts'
import { LanguageViews } from '../../../language/language.interface'
import { ElectricalComponentColors } from '../../../service/color.service'
import { LiveDataPowerUiInterface } from '../../../../types'
import { LiveDataGraphData } from './livedata-view.component'

export function genPreArray(length: number): LiveDataGraphData {
  const data: LiveDataGraphData = []
  for (let i = 0; i < length - 1; i += 1) {
    data.push({ data: [] })
  }
  return data
}

export type livedataChartType = '3phase' | 'power' | 'uDc'

// new Date(ts), ui.gridPower, ui.loadPower, ui.pvPower, ui.batPower
// l.ELECTRICAL_GRID, l.CONSUMPTION, l.SOLAR_PRODUCTION, l.BATTERY
function powerSeries(l: LanguageViews) {
  const series: EChartOption.Series[] = [
    {
      type: 'line',
      name: l.ELECTRICAL_GRID,
      data: [],
      areaStyle: { opacity: 0.2 },
      smooth: true
    },
    {
      type: 'line',
      name: l.CONSUMPTION,
      data: [],
      areaStyle: { opacity: 0.2 },
      smooth: true
    },
    {
      type: 'line',
      name: l.SOLAR_PRODUCTION,
      data: [],
      areaStyle: { opacity: 0.2 },
      smooth: true
    },
    {
      type: 'line',
      name: l.BATTERY,
      data: [],
      areaStyle: { opacity: 0.2 },
      smooth: true
    }
  ]
  return series
}

export function updateLiveData(
  ts: Date,
  currentData: LiveDataGraphData,
  values: number[]
): LiveDataGraphData {
  return currentData.map((serie, index) => {
    if (serie.data.length > 180) {
      serie.data.shift()
    }

    if (serie.data.length) {
      const latestTs = serie.data[serie.data.length - 1][0]

      if (latestTs == ts) return serie
    }

    serie.data.push([ts, values[index]])
    return serie
  })
}

export function updatePowerLiveData(
  uiData: LiveDataPowerUiInterface,
  ts: Date,
  currentData: LiveDataGraphData
): LiveDataGraphData {
  if (currentData[0].data.length > 180) {
    currentData[0].data.shift()
    currentData[1].data.shift()
    currentData[2].data.shift()
    currentData[3].data.shift()
  }

  currentData[0].data.push([ts, uiData.gridPower])
  currentData[1].data.push([ts, uiData.loadPower])
  currentData[2].data.push([ts, uiData.pvPower])
  currentData[3].data.push([ts, uiData.batPower])
  return currentData
}

function createLiveViewYAxis(
  name: string,
  unit: string,
  ops?: { max: number; min: number }
): EChartOption.YAxis {
  return {
    type: 'value',
    name: `${name} [${unit}]`,
    axisLabel: { formatter: `{value} [${unit}]` },
    min: ops ? ops.min : null,
    max: ops ? ops.max : null
  }
}

export function setupLivedataCharts(
  l: LanguageViews,
  colors: ElectricalComponentColors,
  dcBuMinMax: { min: number; max: number }
): {
  power: EChartOption
  dc: EChartOption
  gridCurrent: EChartOption
  ehubCurrent: EChartOption
  loadCurrent: EChartOption
  gridVoltage: EChartOption
} {
  const PHASELABEL = [l.PHASE_1, l.PHASE_2, l.PHASE_3]
  const POWERLABEL = [l.ELECTRICAL_GRID, l.CONSUMPTION, l.SOLAR_PRODUCTION, l.BATTERY]
  const xAxis: EChartOption.XAxis = {
    type: 'time',
    name: l.DATE_AND_TIME
  }

  const powerChartOptions: EChartOption = {
    legend: {
      data: POWERLABEL
    },
    color: [colors.grid.line, colors.consumption.line, colors.solar.line, colors.battery.line],
    tooltip: echartsTooltip(),
    toolbox: echartsToolbox(),
    grid: defaultEchartsGrid,
    xAxis: xAxis,
    dataZoom: defaultEchartsDataZoom,

    yAxis: defaultEchartsYAxisPower(l.POWER),
    series: powerSeries(l)
  }

  const dcBusChartOptions: EChartOption = {
    legend: {
      data: ['U+', 'U-']
    },
    color: [colors.grid.line, colors.neutral.line],
    tooltip: echartsTooltip(),
    toolbox: echartsToolbox(),
    xAxis,
    dataZoom: defaultEchartsDataZoom,

    yAxis: createLiveViewYAxis('Volt', 'V', dcBuMinMax),
    series: [
      {
        type: 'line',
        name: 'U+',
        data: [],
        smooth: true
      },
      {
        type: 'line',
        name: 'U-',
        data: [],
        smooth: true
      }
    ]
  }

  const PhaseOptions: EChartOption = {
    legend: { data: PHASELABEL },
    color: [colors.phase1.line, colors.phase2.line, colors.phase3.line],
    tooltip: echartsTooltip(),
    toolbox: echartsToolbox(),
    xAxis,
    dataZoom: defaultEchartsDataZoom,

    yAxis: createLiveViewYAxis('Ampere', 'Arms'),
    series: [
      {
        type: 'line',
        name: l.PHASE_1,
        data: [],
        smooth: true
      },
      {
        type: 'line',
        name: l.PHASE_2,
        data: [],
        smooth: true
      },
      {
        type: 'line',
        name: l.PHASE_3,
        data: [],
        smooth: true
      }
    ]
  }

  const gridVoltage = copy(PhaseOptions)
  gridVoltage.yAxis = createLiveViewYAxis('Volt', 'V')

  return {
    power: powerChartOptions,
    dc: dcBusChartOptions,
    gridCurrent: copy(PhaseOptions),
    ehubCurrent: copy(PhaseOptions),
    loadCurrent: copy(PhaseOptions),
    gridVoltage
  }
}
