import { IDeferred, IQService, IScope, material, copy } from 'angular'
import moment from 'moment-timezone'
import { LanguageViews } from '../../../language/language.interface'
import { DEFAULT_TIMEZONE } from '../../../../environments/environment'
import { PowerViewService, PowerViewServiceName } from './power-view.service'
import {
  ElectricalComponentColors,
  FeatureFlagService,
  FeatureFlagServiceName,
  FerroConfiguration,
  FerroConfigurationName
} from '../../../service'
import { UiDataQueryTypes } from '../../../../types'
import { ECharts } from 'echarts'
import {
  generateTitleAndLabels,
  powerViewTitleAndLabels,
  processMainAggregateDataType
} from './power-view.tools'
import { createPowerViewChart } from './power-view.chart'
import { ViewBoxNextPreviousOptions } from '../../../components/view-box/view-box.component'
import templateUrl from './power-view.component.html'
import DialogTemplate from './power-view-seconds.dialog.html'
import {
  clearEchartsInstance,
  echartsInitWrapper,
  echartsNoDataTitleOptions,
  echartsOnResize,
  echartsShowLoadingOptions,
  EchartsShowLoadingType
} from '../../../../lib'
import { PowerViewSecondsDialogController } from './power-view-seconds.dialog.controller'
import './power-view.component.scss'
import { GRAPHQL_ERRORS } from '../../../graphql/errors'
import {
  GraphqlPowerDataRetentionsService,
  GraphqlPowerDataRetentionsServiceName
} from '../../../graphql/retention.queries'
import { AmplitudeService, AmplitudeServiceName } from '@app/service/amplitude.service'

export const PowerViewComponentName = 'powerViewComponent'

class PowerViewController {
  showBatModule = false
  loading = true
  usingWeekly = true
  graph: ECharts
  graphTitle: string

  l: LanguageViews

  GRAPH_TYPES = ['main', 'ed', 'eq', 'er', 'id', 'iq', 'ir', 'u', 'udc'] //'lc',
  choosenValue: UiDataQueryTypes | 'main'
  resolution = 60
  starttime: Date
  endtime: Date
  maxDate: Date
  minDate: Date
  error = ''
  notice = ''
  showNextWeekButton = false

  timezone = DEFAULT_TIMEZONE

  colors: ElectricalComponentColors
  powerViewService: PowerViewService

  canceller: IDeferred<unknown>
  $q: IQService
  titels: powerViewTitleAndLabels

  batteryExists: boolean

  dateChanger: ViewBoxNextPreviousOptions
  showDataOptions = false

  $s: IScope
  $md: material.IDialogService
  zoomValues: { start: any; stop: any } = {
    start: null,
    stop: null
  }
  zoomDates: { starttime: Date; endtime: Date }
  activeDecision = false
  availableSecondResolution: Date
  amplitude: AmplitudeService
  private features: FeatureFlagService
  constructor(
    $q: IQService,
    powerViewService: PowerViewService,
    ferroConfiguration: FerroConfiguration,
    $scope: IScope,
    $mdDialog: material.IDialogService,
    graphqlPowerDataRetentionsService: GraphqlPowerDataRetentionsService,
    amplitude: AmplitudeService,
    features: FeatureFlagService
  ) {
    this.features = features
    this.canceller = $q.defer()
    this.amplitude = amplitude
    this.$s = $scope
    this.$md = $mdDialog
    this.$q = $q
    this.powerViewService = powerViewService
    powerViewService.init(Number(ferroConfiguration.facility.id), this.canceller.promise)
    this.l = ferroConfiguration.language.Views
    this.titels = generateTitleAndLabels(this.l)
    this.graphTitle = this.titels.main.t
    this.timezone = ferroConfiguration.facility.timezone || DEFAULT_TIMEZONE
    this.maxDate = ferroConfiguration.getMaxDate()
    this.minDate = graphqlPowerDataRetentionsService.getPowerData1MinuteMinDate()
    this.starttime = moment.tz(new Date(), this.timezone).startOf('day').subtract(7, 'days').toDate()
    this.endtime = moment.tz(new Date(), this.timezone).toDate()
    this.colors = ferroConfiguration.electricColors

    this.availableSecondResolution = graphqlPowerDataRetentionsService.getUiData1SecondMinDate()

    this.dateChanger = {
      previousLabel: this.l.PREVIOUS_WEEK,
      nextLabel: this.l.NEXT_WEEK,
      enabled: true,
      onPrevious: this.changeWeek('previous').bind(this),
      onNext: this.changeWeek('next').bind(this),
      showNext: false,
      showPrevious: true
    }
  }

  $onInit(): void {
    this.getWeeklyPowerData(this.starttime, this.endtime, 60)
  }

  $onDestroy(): void {
    if (this.graph) {
      clearEchartsInstance(this.graph)
    }
    this.canceller.resolve()
  }

  /**
   * What we do here is if we enables the next Week button
   */
  checkIfToday(): void {
    const et = new Date(this.endtime)
    const isToday =
      moment.tz(et, this.timezone).isSame(moment.tz(this.timezone), 'day') ||
      et.valueOf() > new Date().valueOf()
    if (isToday) this.endtime = moment.tz(new Date(), this.timezone).endOf('day').toDate()
    this.dateChanger.showNext = !isToday
  }

  changeWeek(type: 'previous' | 'next'): () => void {
    return () => {
      if (type === 'previous') {
        this.starttime = moment(this.starttime).subtract(7, 'days').toDate()
        this.endtime = moment(this.endtime).subtract(7, 'days').toDate()
        this.change()
      } else if (type === 'next') {
        this.starttime = moment(this.starttime).add(7, 'days').toDate()
        this.endtime = moment(this.endtime).add(7, 'days').toDate()
        this.change()
      } else {
        this.notice = this.l.INVALID_WEEK_PARAM
      }
    }
  }

  private _displayEndTime(): string {
    return ` ${moment.tz(this.endtime, this.timezone).format('YYYY-MM-DD')}`
  }

  private _displayStartTime(): string {
    return ` ${moment.tz(this.starttime, this.timezone).format('YYYY-MM-DD')}`
  }

  changeTime(type: 'starttime' | 'endtime'): void {
    this.notice = ''
    const starttime = new Date(this.starttime)
    const endtime = this.endtime
    const diff = (endtime.getTime() - starttime.getTime()) / 1000 //SECONDS

    if (type === 'starttime' && diff > 86400 * 7) {
      this.endtime = moment(starttime).add(7, 'day').endOf('day').toDate()
      this.notice = this.l.CHANGED_ENDTIME_TO + this._displayEndTime()
    } else if (type === 'endtime' && diff > 86400 * 7) {
      this.starttime = moment(endtime).subtract(7, 'day').startOf('day').toDate()
      this.notice = this.l.CHANGED_STARTTIME_TO + this._displayStartTime()
    } else if (diff < 0) {
      this.starttime = moment(endtime).subtract(7, 'days').startOf('day').toDate()
      this.notice = `${this.l.ENDTIME_GREATER_STARTTIME} ${
        this.l.CHANGED_STARTTIME_TO + this._displayStartTime()
      }`
    }

    this.endtime = moment.tz(this.endtime, this.timezone).endOf('day').toDate()
    this.starttime = moment.tz(this.starttime, this.timezone).startOf('day').toDate()
  }

  async plotMainGraph(data: processMainAggregateDataType): Promise<void> {
    if (!this.graph) {
      const ops = createPowerViewChart(this.titels, this.colors, this.l, this.timezone)
      this.graph = await echartsInitWrapper('powerViewGraph')
      echartsOnResize(this.graph)
      this.graph.on('dataZoom', this._onZoomCallback.bind(this))
      this.graph.setOption(ops)
    }

    this.graph.setOption({
      title: {
        show: false
      },
      dataset: {
        source: data.data
      }
    })
    this.stopLoading()

    const daysFromToday = moment
      .tz(this.timezone)
      .endOf('day')
      .diff(moment.tz(this.starttime, this.timezone), 'days')

    this.amplitude.logEvent('Get Powerdata', {
      dataType: 'main',
      resolution: 'minute',
      hourOfDay: moment.tz(this.starttime, this.timezone).hours(),
      numberOfHours: moment.tz(this.endtime, this.timezone).diff(this.starttime, 'hours'),
      dayFromNowToday: daysFromToday,
      activeDecision: this.activeDecision
    })
  }

  change(): void {
    this.activeDecision = true
    this.$s.$applyAsync(() => {
      this.loading = true
      // always remove this option if we change dates
      this.showDataOptions = false
    })
    if (this.graph) this.graph.showLoading(EchartsShowLoadingType, echartsShowLoadingOptions())
    this.error = ''
    this.notice = ''
    const starttime = new Date(copy(this.starttime))
    let endtime = new Date(copy(this.endtime))

    this.checkIfToday()
    if (endtime.getTime() - starttime.getTime() > 86400000 * 7 * 2) {
      this.notice = this.l.MAXIMUM_TWO_WEEKS
      return
    }
    if (endtime.getTime() < starttime.getTime()) {
      this.notice = this.l.ENDTIME_GREATER_STARTTIME
      return
    }
    if (endtime.getFullYear() < 2016) {
      this.notice = 'No data before year 2016'
      return
    }

    endtime = moment.tz(this.endtime, this.timezone).endOf('day').toDate()

    if (
      moment.tz(this.endtime, this.timezone).format('YYYYMMDD') ==
      moment.tz(new Date(), this.timezone).format('YYYYMMDD')
    ) {
      endtime = moment.tz(new Date(), this.timezone).toDate()
    }

    this.endtime = endtime
    this.canceller = this.$q.defer()
    this.getWeeklyPowerData(this.starttime, this.endtime, 60)
  }

  stopLoading(): void {
    this.$s.$applyAsync(() => {
      this.loading = false
    })
    if (this.graph) this.graph.hideLoading()
  }

  async getWeeklyPowerData(starttime: Date, endtime: Date, resolution: number): Promise<void> {
    const data = await this.powerViewService.getAggregateData(starttime, endtime, resolution).catch(error => {
      this.stopLoading()
      if (error.message === GRAPHQL_ERRORS.NO_DATA) {
        this.error = this.l.NO_DATA_FOUND
        if (this.graph)
          this.graph.setOption({
            title: echartsNoDataTitleOptions(this.l.NO_DATA_FOUND),
            dataset: {
              source: []
            }
          })
      }
      if (error.message === GRAPHQL_ERRORS.GENERAL_ERROR) {
        if (this.graph)
          this.graph.setOption({
            title: echartsNoDataTitleOptions(this.l.ERROR_OCCURRED),
            dataset: {
              source: []
            }
          })
        this.error = this.l.ERROR_OCCURRED
      }
      this.stopLoading()
    })
    if (!data) {
      this.error = this.l.NO_DATA_FOUND
      this.loading = false
      if (this.graph)
        this.graph.setOption({
          title: echartsNoDataTitleOptions(this.l.NO_DATA_FOUND),
          dataset: {
            source: []
          }
        })
    } else {
      this.plotMainGraph(data)
    }
  }

  showSeconds(): void {
    const options: material.IDialogOptions = {
      locals: {
        type: this.choosenValue,
        starttime: this.zoomDates.starttime,
        endtime: this.zoomDates.endtime
      },
      controller: [
        '$mdDialog',
        FerroConfigurationName,
        PowerViewServiceName,
        '$scope',
        'type',
        'starttime',
        'endtime',
        PowerViewSecondsDialogController
      ],
      controllerAs: 'vm',
      templateUrl: DialogTemplate,
      clickOutsideToClose: true
    }
    this.$md
      .show(options)
      .then(() => (this.choosenValue = null))
      .catch(() => (this.choosenValue = null))

    const daysFromToday = moment
      .tz(this.timezone)
      .endOf('day')
      .diff(moment.tz(this.zoomDates.starttime, this.timezone), 'days')

    this.amplitude.logEvent('Get Powerdata', {
      dataType: this.choosenValue,
      resolution: 'second',
      hourOfDay: moment.tz(this.zoomDates.starttime, this.timezone).hours(),
      numberOfHours: moment.tz(this.zoomDates.endtime, this.timezone).diff(this.zoomDates.starttime, 'hours'),
      dayFromNowToday: daysFromToday,
      activeDecision: true
    })
  }

  _onZoomCallback(): void {
    this.$s.$applyAsync(() => {
      const ops = this.graph.getOption()
      const dayInMilliseconds = 24 * 60 * 60 * 1000
      const range = ops.dataZoom[0]
      this.zoomValues = {
        start: range.end,
        stop: range.start
      }
      const dateDifference = (range.endValue as number) - (range.startValue as number)
      this.showDataOptions =
        dateDifference < dayInMilliseconds &&
        (range.startValue as number) > this.availableSecondResolution.getTime() &&
        this.features.isHistoricalPerSecondDataEnabled()

      this.zoomDates = {
        starttime: new Date(range.startValue),
        endtime: new Date(range.endValue)
      }
    })
  }
}

PowerViewController.$inject = [
  '$q',
  PowerViewServiceName,
  FerroConfigurationName,
  '$scope',
  '$mdDialog',
  GraphqlPowerDataRetentionsServiceName,
  AmplitudeServiceName,
  FeatureFlagServiceName
]

export const PowerViewComponent = {
  controller: PowerViewController,
  templateUrl: templateUrl
}
