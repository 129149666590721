import { svg } from 'd3-fetch'
import { select, Selection } from 'd3-selection'

import { dotHidden, genPowerText } from '../schematic.tools'

import { LanguageSystemdashboard } from '../../../app/language/language.interface'
import { SystemData } from '../../../types'
import powerSchematicBatteryUrl from './powershare-battery-schematic.svg'

export class PowershareBatterySchematic {
  private id: string
  private url = powerSchematicBatteryUrl
  private isReady = false
  private popUpisSet = false
  private svg: Selection<HTMLElement, unknown, null, undefined>

  batTitle: Selection<Element, unknown, HTMLElement, undefined>
  batteryPowerDirectionText: Selection<Element, unknown, HTMLElement, undefined>
  batPower: Selection<Element, unknown, HTMLElement, undefined>
  charDot: Selection<Element, unknown, HTMLElement, undefined>
  disCharDot: Selection<Element, unknown, HTMLElement, undefined>
  battery: Selection<Element, unknown, HTMLElement, undefined>
  batteryStatusPopUpLayer: Selection<Element, unknown, HTMLElement, undefined>
  batteryStatus: Selection<Element, unknown, HTMLElement, undefined>
  batteryStatusAttr: {
    height: number
    y: number
    status: number
  }

  l: LanguageSystemdashboard

  constructor(id: string, l: LanguageSystemdashboard) {
    this.id = id
    this.l = l

    svg(this.url).then(xml => {
      this.svg = select(document.getElementById(this.id).appendChild(xml.documentElement))
      this.start()
    })
  }

  start(): void {
    this.batTitle = select('#batteryTitle')
    this.batteryPowerDirectionText = select('#batteryPowerDirectionText')
    this.batPower = select('#batteryPowerText')
    this.charDot = select('#batteryDotChar')
    this.disCharDot = select('#batteryDotDisChar')
    this.battery = select('#BatteryLayer')
    this.batteryStatusPopUpLayer = select('#batteryStatusPopUpLayer')

    this.batteryStatus = select('#batteryChargeRectangle')
    this.batteryStatusAttr = {
      height: Number(this.batteryStatus.attr('height')),
      y: Number(this.batteryStatus.attr('y')),
      status: null
    }
    this.isReady = true
  }

  updateBatteryStatus(status: SystemData): boolean {
    if (!this.isReady) {
      return false
    }
    if (status.soc && status.rC && status.soh) {
      const height = (status.soc * this.batteryStatusAttr.height) / 100
      const yShift = this.batteryStatusAttr.height - height
      const newY = this.batteryStatusAttr.y + yShift
      this.batteryStatus.attr('y', newY).attr('height', height)
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this
      this.batteryStatusPopUpLayer.selectAll('text').each(function (d, i) {
        if (i === 0) {
          select(this).text(`${self.l.RATED_CAPACITY} ${status.rC} kWh`).style('font-size', '12px')
        } else if (i === 1) {
          select(this)
            .text(`${self.l.STATE_OF_CHARGE} ${Math.round(status.soc)}%`)
            .style('font-size', '12px')
        }
        //  else if (i == 2) select(this)
        // .text("State of health " + status.soh)
        // .style("font-size", "12px");
      })
      if (!this.popUpisSet) {
        this.addOnBatteryHoverShowRatedCapcity()
      }
      return true
    }

    return false
  }

  addOnBatteryHoverShowRatedCapcity(): void {
    const statusPopUp = this.batteryStatusPopUpLayer
    this.popUpisSet = true
    this.battery
      .on('mouseover', () => {
        statusPopUp.classed(dotHidden, false)
      })
      .on('mouseout', () => {
        statusPopUp.classed(dotHidden, true)
      })
  }

  update(batPower: number): void {
    if (!this.isReady) return
    this.batPower.text(genPowerText(batPower))
    if (batPower > 0) {
      this.charDot.classed(dotHidden, true)
      this.disCharDot.classed(dotHidden, false)
      this.batteryPowerDirectionText.text('Laddar ur')
    } else if (batPower < 0) {
      this.charDot.classed(dotHidden, false)
      this.disCharDot.classed(dotHidden, true)
      this.batteryPowerDirectionText.text('Laddar')
    } else {
      this.charDot.classed(dotHidden, true)
      this.disCharDot.classed(dotHidden, true)
      this.batteryPowerDirectionText.text('')
    }
  }
}
