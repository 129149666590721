import { IComponentOptions, material } from 'angular'
import './view-header.component.scss'

import templateUrl from './view-header.component.html'

import { LanguageViews } from '../../language/language.interface'
import { FerroConfiguration, FerroConfigurationName } from '../../service'
import { EXPORT_VIEW_CONTROL_SIDENAV_COMPONENT_ID } from '../view-controller-sidenav/view-controller-sidenav.component'

class ViewHeaderController {
  viewTitle: string
  feClass: string

  $md: material.ISidenavService
  l: LanguageViews

  constructor($mdSidenav: material.ISidenavService, ferroConfiguration: FerroConfiguration) {
    this.$md = $mdSidenav
    this.l = ferroConfiguration.language.Views
  }

  openViewControl(): void {
    try {
      this.$md(EXPORT_VIEW_CONTROL_SIDENAV_COMPONENT_ID).toggle()
    } catch (_e) {
      return
    }
  }
}

ViewHeaderController.$inject = ['$mdSidenav', FerroConfigurationName]

export const ViewHeaderComponent: IComponentOptions = {
  controller: ViewHeaderController,
  templateUrl: templateUrl,
  transclude: {
    toolbar: '?viewHeaderToolbar',
    content: '?viewHeaderContent'
  },
  bindings: {
    viewTitle: '<',
    feClass: '=',
    viewControl: '='
  }
}
