import { ITimeoutService, material } from 'angular'
import { SystemListState } from '../../app.routing'
import { GlobalSettingsStates } from '../../global-settings/global-settings-routes.config'
import { LanguageHeader } from '../../language/language.interface'
import { FerroConfiguration, FerroConfigurationName, GdprService, GdprServiceName } from '../../service'

import './header.component.scss'

import { Environment } from '@environments/environment'
import { isMobile } from '../../../lib'
import { keycloak } from '../../auth/keycloak'
import template from './header.component.html'

class HeaderController {
  isDemo: boolean
  sidebar: () => void
  refs = {
    createSystem: GlobalSettingsStates.createSystem,
    generalSettings: GlobalSettingsStates.generalSettings,
    systemList: SystemListState
  }

  contactLink: string
  l: LanguageHeader
  fc: FerroConfiguration
  gs: GdprService
  $s: material.ISidenavService
  $t: ITimeoutService
  isMobile = isMobile()
  accountName: string
  changeProfileUrl: string
  changePasswordUrl: string
  logoutLink: string

  constructor(
    ferroConfiguration: FerroConfiguration,
    $mdSidenav: material.ISidenavService,
    $timeout: ITimeoutService,
    gdprService: GdprService
  ) {
    const contactLanguage =
      ferroConfiguration.nowLang === 'se' ? 'om-ferroamp/kontakta-oss/' : 'en/about-ferroamp/get-in-touch/'
    this.contactLink = `https://ferroamp.com/${contactLanguage}`
    this.isDemo = ferroConfiguration.isDemo

    this.accountName = Environment.accountName
    this.$t = $timeout
    this.$s = $mdSidenav
    this.fc = ferroConfiguration
    this.gs = gdprService
  }

  async $onInit(): Promise<void> {
    this.changeProfileUrl = keycloak.createLoginUrl() + '&kc_action=UPDATE_PROFILE'

    this.changePasswordUrl = keycloak.createLoginUrl() + '&kc_action=UPDATE_PASSWORD'

    this.sidebar = () => {
      this.$s('headerSideNav').toggle()
    }

    this.$t(() => {
      this.l = this.fc.language.Header
      this.gs.run()
    }, 1500)

    this.logoutLink = keycloak.createLogoutUrl()
  }
}

HeaderController.$inject = [FerroConfigurationName, '$mdSidenav', '$timeout', GdprServiceName]

export const HeaderComponentName = 'headerComponent'

export const HeaderComponent = {
  controller: HeaderController,
  templateUrl: template
}
