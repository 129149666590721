import { LanguageInterface } from './language.interface'

export const EnglishLanguage: LanguageInterface = {
  Globally: {
    monthlon: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ],
    monshort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    daylong: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
    dayshort: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
    firstDateOfWeek: 1,
    engcountInfo: 'This feature require the latest software which includes energycounters in the EnergyHub',
    engcountInfo2: 'If you do not see any result we can upgrade the EnergyHub, please contact Ferroamp. ',
    discharge: 'Discharge',
    charge: 'Charge',
    NOT_AVAILABLE: 'Not available',
    EXPORT: 'Export',
    IMPORT: 'Import'
  },

  Scheduling: {
    TODAY: 'Today',
    SCHEDULING: 'Scheduling',
    INITIATION: `To initiate the schedule you have to first create a default schedule. Create the default schedule by
    click the button below, which creates the default schedule from your current operation settings. You
    will be able to modify the default schedule later.`,
    DEFAULT_INITIATION: `
    Once you have created the default schedule you will be able to create new scheduling events which
      will run instead of the default schedule.`,
    USE_CURRENT_OPERATION_AS_DEFAULT: 'Use current operation settings as default schedule',
    EDIT_DEFAULT: 'Edit default schedule',
    EVENTS: 'Events',
    CREATE_NEW_SCHEDULE: 'Create a new schedule',
    GO_TO_DOCUMENTATION: 'Go to documentation',
    LAST_APPLIED: 'Last Applied ',
    STARTTIME: 'Starttime',
    ENDTIME: 'Endtime',
    EDIT: 'Edit',
    REMOVE: 'Remove',
    READ_ONLY_CALENDAR: 'Currently, this is a readonly view, to visualize the weekly schedules.',
    DEFAULT_SCHEDULING: 'Default scheduling',
    SCHEDULING_EVENT: 'Scheduling event',
    SETTINGS_INVALID: 'The operations settings are invalid, please correct and try again',
    PLEASE_ADD_SUMMARY: 'The scheduling settings, please submit a summary  ',
    MUST_CHOOSEN_ONE_DAY: 'Must choose atleast 1 day',
    START_END_NOT_SAME: 'Start and End cannot be the same',
    CREATE_A_NEW: 'created a new',
    UPDATE_THE: 'updated the',
    UPDATED_THE_DEFAULT: 'update the default',
    UPDATED_THE_DEFAULT_APPLIED_NEXT:
      'Successfully updated the default schedule and will be applied next time no other schedules are active',
    SUCCESSFULLY: 'Successfully {VALUE} event',
    FAILED_TO: 'Failed to {VALUE} event',
    MODIFY_DEFAULT_SCHEDULE: 'Modify the default schedule',
    CREATE_SCHEDULE_INFO:
      'Create the scheduled by filling in during which hours it should occur and which days.',
    SUMMARY: 'Summary',
    SUMMARY_IS_REQUIRED: 'The summary is required',
    DESCRIPTION: 'Description',
    THE_TIME_INTERVAL: 'The time interval',
    IF_END_IS_LESS_INFO: 'If the end is less than start we assume that it should end the next day.',
    DURING_WHICH_DAYS: 'During which days do you want these events to occur?',
    DURING_WHICH_DAYS_CHOOSE: 'You can choose one or several but atleast one.',
    OPERATION_SETTINGS: 'Operational settings',
    OPERATION_SETTINGS_FOR_THE_EVENT: 'Set the operational settings for the scheduled event',
    STORE: 'Store',
    CALENDAR_VIEW_TITLE: 'Calendar',
    CONFIRM_ENABLE: 'Confirmation about enabling scheduling',
    CONFIRM_INFO: `Please read through the documentation at <a  href="{SUPPORT_LINK}" target="_blank">support.ferroamp.com</a>
        and make sure you understand before starting any scheduling.`,
    CONFIRM_UNDERSTAND: 'I understand and want to try it out.',
    CANCEL: 'Cancel',
    ERROR_OCCURED_PLEASE_TRY_AGAIN: 'Error occurred, please try again later.',
    SUCCESS_FULLY_SET_DEFAULT_SCHEDULE: 'Successfully set the default schedule',
    FAILED_REMOVED_EVENT: 'Failed to remove event, please try again later.',
    SUCCESS_FULLY_REMOVED_EVENT: 'Successfully removed event:',
    REMOVE_EVENT: 'Remove event:',
    SURE_REMOVE_EVENT: `Are you sure you want to remove this event?`,
    YES_IM_SURE: "Yes, I'm sure.",
    SHORT_SUMMARY: 'Short summary',
    DETAILED_DESCRIPTION: 'Detailed description',
    DEFAULT_SCHEMA_INFO: `When no selected schedule event is active, the system automatically reverts to the default schedule. To modify this schedule,
    click on 'Edit default schedule.'`,
    FOR_MORE_INFORMATION_SCHEDULING: 'For more information click here',
    SAVE_AND_APPLY: 'Save and apply'
  },
  Header: {
    CHANGE_SETTINGS: 'USER SETTINGS',
    CHANGE_PASSWORD: 'CHANGE PASSWORD',
    ADD_SYSTEM: 'ADD SYSTEM',
    SYSTEMS: 'SYSTEMS',
    SETTINGS: 'MY ACCOUNT',
    CONTACT: 'CONTACT',
    HELP: 'Help',
    INCIDENT_ALERT:
      'We\'re currently experiencing problems with some of our systems. You can read more <a target="_blank" href="https://support.ferroamp.com/en/support/solutions/47000526402">here</a>.',
    PLANNED_MAINTENANCE_ALERT:
      'Upcoming planned maintenance.   <a target="_blank" href="https://support.ferroamp.com/en/support/solutions/47000526402">Read more here</a>'
  },
  Comments: {
    LEAVE_A_COMMENT: 'Leave a comment',
    HELLO: 'Hello',
    INFO: "Please let us know what's on your mind. If you have any questions in the comment field we will answer them by to registered email of your account.",
    TITLE: 'Title',
    COMMENT: 'Comments',
    GREETINGS: 'Greetings Ferroamp',
    VISIT: 'Visit Ferroamp.com',
    CLOSE: 'Close',
    SEND_COMMENT: 'Send Comment',
    THANK_YOU: 'Thank you',
    THANKS_TEXT:
      'We appreciate all the comments and thought about our products and services. We will come back to you if there were any questions in the comment.'
  },
  SystemList: {
    SYSTEM: 'System',
    FILTER: 'Filter',
    LIST_PAGE: 'Page:',
    LIST_OF: 'of',
    ROWS_PER_PAGE: 'Rows per page:',
    UPDATED: 'System status',
    UPDATE_INFO_META: '  System Meta Information Needs Update, you will be redirected',
    UPDATE_INFO_OK: 'Everything is updated',
    CONSUMPTION_POWER: 'Consumption [kW]',
    PRODUCTION_POWER: 'Production [kW]',
    CONNECTION: 'Connection',
    STORAGE_POWER: 'Battery Power [kW]',
    STORAGE_STATUS: 'State of Charge [kWh]',
    TOTAL: 'Total',
    MULTISYSTEM: 'Multi Systems',
    ID: 'ID',
    NOT_STARTED: ' Not started?',
    OFFLINE: ' Offline',
    ONLINE: ' Online',
    WARNING: ' Warning!',
    ERROR_FETCHING_SYSTEMS: 'An error occurred when trying to fetch systems',
    ERROR_FETCHING_MULTISYSTEMS: 'An error occurred when trying to fetch multisystems',
    HIDE: 'Hide',
    SHOW: 'Show'
  },
  SystemSidebar: {
    DASHBOARD: 'Overview',
    POWER_VIEW: 'Power view',
    ANALYSIS: 'Analysis',
    LIVEDATA: 'Live Data',
    SYSTEM_SETTINGS: 'System settings',
    ENERGY_VIEW: 'Energy view',
    SSO_VIEW: 'SSO view',
    EXPORT_TITLE: 'Export Data',
    EXPORT_TOOLTIP: 'Will be implemented',
    ESO_VIEW: 'Eso View',
    PRO_INFORMATION:
      'Functions for advanced system analysis for professional users, click on "PRO" for more information.',
    ANCILLARY_SERVICE_VIEW: 'Ancillary service'
  },
  SystemDashboard: {
    EV_CURRENTS: 'Currents per phase to the electric car charger',
    EV_CHARGER: 'EV Charger',
    EV_OVERVIEW: 'EV overview',
    ONLINE: 'Online',
    OFFLINE: 'Offline',

    TOTAL_EV_ENERGY: 'Total amount of energy that charged the electric car',
    TIMESTAMP: 'Timestamp',
    MONTH: 'Month',
    SSO: 'SSO',
    HOUR: 'Hour',
    NO_LOCATION_PARAMS_EXPLAINATION:
      'To be able to use this functionality you must specify the Latitude and Longitude in the system information settings',
    ENABLED: 'Enabled',
    DISABLED: 'Disabled',
    UNAVAILABLE: 'Unavailable, not found',
    ENERGY_STORAGE: 'Energy storage',
    TURN_OFF: 'Turn off',
    DATA_VIEW: 'Data view',
    REFRESH: 'Refresh',
    EXPORT_DATA: 'Export data',
    IMPORTING: 'Importing',
    EXPORTING: 'Exporting',
    IDLE: 'Measurement mode',
    DASHBOARD: 'Overview',
    SAVE_IMAGE: 'Save Image',
    DISCHARGING: 'Discharging',
    CHARGING: 'Charging',
    SAVE_LAYOUT: 'Save layout',
    LAYOUT_SAVED: 'Layout saved.',
    LAYOUT_FAILED: 'Layout could not be saved. Unknown reason.',
    GRID_POWER: 'Grid power',
    GRID_POWER_INFO: 'The power measured at the main fuses',
    CONSUMPTION_POWER: 'Consumption power',
    CONSUMPTION_POWER_INFO: 'The measured consumption where the current transformers are connected',
    SOLAR_PRODUCTION: 'Solar power',
    SOLAR_PRODUCTION_INFO: 'Production from the solar',
    BATTERY_POWER: 'Battery power',
    BATTERY_POWER_INFO: 'The power from or to the batteries.',
    BATTERY_SOC: 'Battery State-Of-Charge (SOC)',
    CURRENT_FLOW: 'Current flow',
    CURRENT_FLOW_INFO:
      'The illustration shows active current components. The real currents in the <br> facilities and through the main fuses may be higher due to reactive current components. See "Power view"',
    POWER_FLOW: 'Power flow',
    POWER_FLOW_INFO: 'Here the power in the system is shown.',
    GRID_CURRENTS: 'Grid currents',
    GRID_CURRENTS_INFO: 'Currents measured at the main fuses.',
    PRODUCTION_SUMMARY: 'Production summary',
    TODAY: 'Today',
    TOTAL: 'Total',
    SELF_CONSUMPTION: 'Self consumption',
    ESTIMATED_INCOME: 'Estimated Revenue',
    EXPORTED_TO_GRID: 'Exported to grid',
    ESTIMATED_SAVING: 'Estimated savings',
    CONSUMPTION_SUMMARY: 'Consumption summary',
    ESTIMATED_COST: 'Estimated Cost',
    IMPORTED_FROM_GRID: 'Imported from grid',
    TIMEZONE: 'Timezone',
    SYSTEM_INFORMATION: 'General information',
    ADDRESS: 'Address',
    CITY: 'City',
    COUNTRY: 'Country',
    PEAK_POWER: 'Peak Power',
    INSTALLED: 'Installed',
    CONTACT: 'Contact',
    NO_SETTINGS_FOUND: 'No settings found',
    GO_TO_SYSTEM_CONFIGURATION: 'Go to system configuration',
    GO_TO_SYSTEM_INFORMATION: 'Go to system information',
    WEATHER_INFORMATION: 'Weather Information',
    TEMPERATURE: 'Temperature',
    SUNRISE: 'Sunrise',
    SUNSET: 'Sunset',
    WIND_SPEED: 'Wind speed',
    WIND_DIRECTION: 'Wind direction',
    PER_YEAR: 'Per year',
    SOLAR_ENERGY: 'Solar Energy',
    CONSUMPTION_ENERGY: 'Consumption',
    EXPORTED_ENERGY_TO_GRID: 'Exported to grid',
    PER_MONTH: 'Per month',
    AVERAGE_POWER: 'Average power',

    AVERAGE_POWER_LABELS: [
      'Datum',
      'Solar',
      'Consumption',
      'Imported from grid',
      'Self consumption',
      'Exported to grid'
    ],
    DAY: 'Day',
    WEEK: 'Week',
    SOLAR_STRINGS: 'Solar strings',
    POWER: 'Power',
    ENERGY: 'Energy',
    SOLAR_STRING_INFO:
      'This graph show the actual status of the solar strings. <br> The power show the actual producing power of each individual string.<br> The energy is the total energy from every string from the installation.',
    BATTERY_MODULES: 'Battery modules',
    BATTERY_MODULES_INFO: 'Actual power from each battery.',
    SELLING: 'Selling',
    BUYING: 'Buying',
    SOLAR: 'Solar',
    BATTERY: 'Battery',
    RATED_CAPACITY: 'Rated capacity',
    STATE_OF_CHARGE: 'State of charge',
    CONSUMPTION: 'Consumption',
    GRID: 'Grid',
    EXTERNAL_PRODUCTION: 'Ext. Production',
    MAIN_FUSE: 'Main fuse',
    DC_MICROGRID: 'DC Grid',
    DC_NANOGRID: 'DC Nanogrid',
    LOCATION: 'Location',
    NO_DATA_FOUND: 'No data found. Please try another time interval or contact support.',
    ERROR_OCCURRED: 'Error Occurred, please try again later or contact support.',
    SETTINGS: 'Settings',
    OPERATION_SETTINGS: 'Operation settings',
    OPERATION_MODE: 'Operation mode',
    ACE_THRESHOLD: 'ACE threshold',
    CHART_BOX: 'Charts'
  },
  Views: {
    DOWNLOAD: 'Download as',
    RESTORE_GRAPH: 'Restore Chart',
    ZOOM: 'Zoom',
    ENERGY: 'Energy',
    POWER: 'Power',
    POWER_VIEW: 'Power view',
    POWER_VIEW_INFORMATION:
      '<h4>Information</h4><p>Zoom by mark and drag. By holding shift and drag sideways it goes sideways. Zoom out by dubble click.</p>',
    VIEW_CONTROL: 'View Control',
    SAVE_IMAGE: 'Save Image',
    EXPORT_IMAGE_FILENAME: 'Choose image name',
    EXPORT_IMAGE_DEFAULT: 'Use graph<Date>.png',

    EXPORT_IMAGE_OWN_FILENAME: 'Use own image name',
    EXPORT_IMAGE_INFO: 'Fill the filename or else graph<Date>.png will be used as default.',
    PREVIOUS_WEEK: 'Previous week',
    NEXT_WEEK: 'Next week',
    PREVIOUS_DAY: 'Previous day',
    NEXT_DAY: 'Next day',
    SELECT_DATA_TO_DISPLAY: 'Choose what to plot',
    CHOOSE_PERIOD: 'Select date range',
    CUSTOMIZATION: 'Customization',
    HIGHLIGHT_CLOSEST: 'Highlight closest',
    LOCK_Y_AXIS: 'Lock y axis',
    LOWER: 'Lower',
    UPPER: 'Upper',
    RESET_ZOOM: 'Reset zoom',
    EXPORT_DATA: 'Export data',
    EXPORT_DATA_FILENAME: 'Choose file name',
    EXPORT: 'Export',
    EXPORT_DATA_DEFAULT: 'Choose data.csv',
    EXPORT_DATA_INFO: 'Fill the filename or else data.csv will be used as default.',
    CANCEL: 'Cancel',
    CLOSE_SIDENAV: 'Close sidebar',
    PRODUCTION_CONSUMPTION: 'Production and consumption',
    CONSUMPTION_PHASE_CURRENTS: 'Consumption phase currents',
    SOLAR_PRODUCTION: 'Solar production',
    GRID_REACTIVE_CURRENT: 'Grid reactive current',
    GRID_ACTIVE_CURRENT: 'Grid active current',
    GRID_CURRENT: 'Grid total current',
    ENERGYHUB_CURRENT: 'EnergyHub total current',
    ENERGYHUB_ACTIVE_CURRENT: 'EnergyHub active current',
    ENERGYHUB_REACTIVE_CURRENT: 'EnergyHub reactive current',
    GRID_VOLTAGE: 'Grid voltage',
    DC_GRID_VOLTAGE: 'DC voltage',
    BATTERY_STATUS: 'Battery status',
    DATE_AND_TIME: 'Date and  Time',
    CHANGE_PERIOD: 'Change period',
    RATED_CAPACITY: 'Rated capacity',
    STATE_OF_CHARGE: 'State of charge',
    BATTERY: 'Battery',
    CONSUMPTION: 'Consumption',
    CONSUMPTION_CURRENTS: 'Consumption currents',
    ELECTRICAL_GRID: 'Grid',
    PHASE_1: 'Phase 1',
    PHASE_2: 'Phase 2',
    PHASE_3: 'Phase 3',
    NEUTRAL: 'Neutral',
    DC_NEGATIVE: 'DC Negative',
    DC_POSITIVE: 'DC Positive',
    DATE: 'Date',
    CHOICE_INVALID: 'The choice is not available',
    ERROR_OCCURRED: 'Error Occurred. Please try again later or contact support.',
    NOT_DEFINED_SEARCH: 'Not defined search. Select another.',
    NO_DATA_FOUND: 'Found no data, try choose another time period or contact support.',
    INVALID_WEEK_PARAM: 'Undefined week parameter.',
    CHANGED_ENDTIME_TO: 'Set end time to ',
    CHANGED_STARTTIME_TO: 'Set start time to ',
    AUTOSET_ENDTIME: 'Changed end time to one week greater than start time',
    AUTOSET_STARTTIME: 'Changed start time to one week less than end time',
    ENDTIME_GREATER_STARTTIME: 'End time must be greater than start time',
    MAXIMUM_TWO_WEEKS: 'Cannot choose more than two weeks.',
    ENERGY_VIEW: 'Energy view',
    MONTHLY: 'Monthly',
    MONTH: 'Month',
    WEEKLY: 'Weekly',
    WEEK: 'Week',
    DAILY: 'Daily',
    DAY: 'Day',
    HOURLY: 'Hourly',
    HOUR: 'Hour',
    EQUARTZ: '15 minutes',
    CHART_TYPE: 'Chart type',
    BAR_GRAPH: 'Bar graph',
    LINE_GRAPH: 'Line graph',
    DATA_FORMAT: 'Data format',
    ACCUMULATED: 'Accumulated',
    RELATIVE: 'Relative',
    ENERGY_VIEW_GRAPH_TITLE_1: 'Total energy',
    ENERGY_VIEW_GRAPH_TITLE_2: 'Consumption per phase',
    IMPORTED_FROM_GRID: 'Imported from grid',
    EXPORTED_TO_GRID: 'Exported to grid',
    SELF_CONSUMPTION_OF_SOLAR: 'Self consumption of solar',
    DISTRIBUTION_OVER_TIME_PERIOD: 'Distribution over time period',
    WITH_PIES_CHARTS: 'with the pie charts',
    WHEN_MONTH_ATLEAST_THREE_MONTH: 'When choosing month, must atleast select a time period of 3 month',
    SHOW_PERCENT: 'Show percent',
    SHOW_KWH: 'Show kWh',
    TOTAL: 'Total',
    SSO_VIEW: 'SSO View',
    PER_SOLAR_STRING: 'Pv production per pv string',
    NO_AVAILABLE_SSOS: 'There seems to be no available solar string optimizers at this system',
    ESO_VIEW: 'ESO View',
    LIVE_DATA: 'Live data'
  },
  SystemAnalysis: {
    NO_AVAILABLE_DATA_BEFORE_DATE: 'No data available before date: ',
    DATE_AND_TIME: 'Date and  Time',
    LOAD_SIGNATURE_RESULT_PENDING: 'The job is still in progress',
    ANALYSIS: 'Analysis',
    VIEW_CONTOL: 'View control',
    TIME_OF_ANALYSIS: 'Time when analysis were created',
    CHOOSE_ANALYSIS_TOOLS: 'Analysis',
    SAVED_ANALYSIS: 'Saved reports',
    FUSE_ANALYSIS: 'Fuse Analysis',
    FUSE_ANALYSIS_INFO:
      'Start a new fuse analytics job by choosing a time interval and click start.                 NOTE! There is an limitation to one analysis job working per facility at time.',
    FUSE_ANALYSIS_INFO_2:
      '<p><b class="color-blue">NOTE: </b>The fuse analysis visualizes current consumption in the three phase conductors interpreted as events during the measurement period. These events are compared with pre-arcing characteristic curves for different fuse sizes. The curves are valid for single current peaks only. With complex current consumption patterns and a high base load or multiple adjacent current pulses this comparison may wrongly indicate a smaller fuse may suffice. Always complement the automated analysis by manually assessing the measured currents. See instruction (currently only in Swedish): </p>',
    ANALYSIS_JOB_IS_RUNNING:
      'There is a fuse- and loadprofile analysis job running with the following parmeters: start time: <STARTTIME> end time: <ENDTIME>. Job started at: <TIMESTAMP>\n\n You cannot start another job until this is finished',
    LOAD_SIGNATURE: 'Consumption signature',
    DAILY_PROFILE: 'Daily profile',
    POWER_ANALYSIS: 'Power Analysis',
    ACE_EVENTS: 'ACE Events',
    ACE_EVENTS_INFO:
      'By double clicking a column in the graph a dialog will be shown to visualize phase balancing (ACE), it will show EnergyHub and grid currents during a period of 60 minutes.',
    ACE_EVENT_TITLE: 'Count of ace event and estimated energytransfer',
    SUMMARY: 'Summary',
    DURATION_ACTIVE_ACE: 'Duration ACE were active',
    MEAN_ENERGY_PER_HOUR: 'Mean energy transfer per hour',
    TOTAL_ENERGY_TRANSFERRED: 'Total transferred energy through ACE',
    BATTERY_SIMULATION: 'Battery Simulation',
    SELECT_DATE_RANGE: 'Select date range',
    SAVE: 'Save',
    RESET_ZOOM: 'Reset Zoom',
    GROUPED: 'Grouped',
    STACKED: 'Stacked',
    REACTIVE_POWER: 'Reactive Power',
    ACTIVE_POWER: 'Active Power',
    GET_DATA: 'Get data',
    SAVE_IMAGE: 'Save Image',
    OPEN: 'Open analysis',
    NO_GENERATED_ANALYSIS: 'No generated analysis found',
    END_TIME: 'End time',
    START_TIME: 'Start time',
    START_ANALYSIS: 'Start analysis',
    WITHOUT_ACE: 'Without phase balancing',
    WITH_ACE: 'With phase balancing',
    TIMESTAMP: 'Timestamps',
    AVERAGE_POWER: 'Average power',
    PHASE_1: 'Phase 1',
    PHASE_2: 'Phase 2',
    PHASE_3: 'Phase 3',
    POWER: 'Power',
    TYPE: 'Type',
    DATE: 'Date',
    COMMENTS: 'Comments',
    USER: 'User',
    EVENTS: '#Events',
    OCCURRANCES: 'Occurrences',
    MEAN_CURRENT_RMS: 'Mean Current [A<sub>rms</sub>]',
    DURATION: 'Duration [s]',
    MEASURED: 'Measured',
    MAGNITUDE: 'magnitude',
    MEAN_CONSUMPTION_ACTIVE: 'Mean Consumption [kW]',
    MEAN_CONUSMPTION_REACTIVE: 'Mean reactive Consumption [VAr]',
    HOUR: 'Hour',
    BASE_CONSUMPTION: 'Base Consumption',
    AVERAGE_CONSUMPTION: 'Average Consumption',
    DAILY_PROFILE_WEEKDAYS: 'Daily Trend Per Phase and Weekdays',
    DAILY_PROFILE_WEEKENDS: 'Daily Trend Per Phase and Weekends',
    DAILY_PROFILE_AVERAGE_POWER_PER_OCCURRANCES: 'Average Power Per Hour Occurrences',
    DAILY_PROFILE_AVERAGE_POWER_INFO: 'This graph shows how many hours a mean power occurred.',
    CHOOSE_ONE: 'Choose one',
    NO_ONE: 'No one!',
    TYPE_OF_ANALYSIS: 'Type of analysis',
    EXECUTION_TIME: 'Execution time',
    LIKE_TO_DELETE_ANALYSIS: 'Would you like to remove this analysis?',
    DELETE: 'Delete',
    KEEP: 'Keep',
    ERROR_USER_MAP: 'Error getting email',
    WEEKDAYS: 'Weekdays',
    WEEKENDS: 'Weekends',
    HOUR_OF_DAY: 'Hour of day',
    MAX_MEAN_THREE_PHASE_POWER: '10 Max average power for three phase',
    MAX_MEAN_PER_PHASE_POWER: '10 max average powers for phase',
    NUMBER_OF_HOURS: 'Number of hours',
    CONSUMPTION_ACTIVE: 'Mean Consumption [kW]',
    CONSUMPTION_REACTIVE: 'Mean reactive Consumption [VAr]',
    TEMPERATURE: 'Temperature &#8451;',
    DATE_TIME: 'Date and Time',
    WEEKEND_MARKED_INFO: 'The gray marked areas representing weekends.',
    POWER_ANALYSIS_INFO: 'Poweranalysis calculates the average consumption power per day',
    GRID_AVERAGE_POWER: 'Grid average power',
    CONSUMPTION: 'Consumption',
    BATTERY_POWER: 'Battery power',
    SOLAR_PRODUCTION: 'Solar production',
    SIMULATED_BATTERY_POWER: 'Simulated battery power',
    SIMULATED_BATTERY_STATUS: 'Simulated battery status',
    GRID_POWER_NO_BATTERY: 'Grid without battery',
    GRID_POWER_WITH_BATTERY: 'Grid with battery',
    SELF_CONSUMPTION: 'Self consumption',
    GRID_POWER: 'Grid power',
    UPPER_LIMIT: 'Upper limit',
    LOWER_LIMIT: 'Lower limit',
    ENERGY: 'Energy',
    SECONDS: 'Seconds',
    MINUTES: 'Minutes',
    RESOLUTION: 'Resolution',
    ENERGY_QUARTERS: 'Energy per 15 minutes',
    ENERGY_HOURS: 'Energy per hour',
    SIMULATION_OF_BATTERIES: 'Simulation of batteries',
    SIMULATION_OF_BATTERIES_INFO:
      'NOTE! This simulation shows how a battery can improve the load profile. For a correct dimensioning of the system more parameters should be considered such as applications, battery life span, efficiency, response times etc.',
    ENERGY_AS_MEAN_POWER: 'Note, that when Energy is choosen the data will be scaled to average power.',
    RATED_CAPACITY: 'Rated capacity',
    CHARGE_THRESHOLD: 'Charge threshold',
    DISCHARGE_THRESHOLD: 'Discharge threshold',
    MAX_POWER: 'Max power',
    MAX_C_RATE: 'Max C-rate',
    BATTERY_STATUS_START: 'Battery status at start [% of Battery Size]',
    BATTERY_MAX_STORED: 'Battery MAX load [%]',
    BATTERY_MIN_STORED: 'Battery MIN load [%]',
    SIMULATE_BATTERY: 'Simulate battery',
    TOTAL_GRID_CURRENT: 'Total grid currents',
    ACTIVE_GRID_CURRENTS: 'Active grid currents',
    REACTIVE_GRID_CURRENTS: 'Reactive grid currents',
    ENERGYHUB_ACTIVE_CURRENT: 'Energyhub active current',
    STARTTIME_NOT_GREATER_ENDTIME: 'Start time cannot be greater than end time',
    NO_MORE_THAN_TWO_WEEKS_1_MIN: 'Cannot request more than 2 weeks in power mode, resolution 1 min',
    NO_MORE_THAN_24_HOURS_1_SEC: 'Cannot request more than 24 hours in power mode and resolution 1 second.',
    ERROR_OCCURRED:
      "An error occurred, try to change time period. If that doesn't work please contact support.",
    ERROR_OCCURRED_LIST: 'An error occurred when trying to get the list',
    NO_RESULT: 'No result',
    ERROR_START_ANALYSIS: 'Error when trying to create job. Please try again later or contact the support.',
    ANALYS_JOB_CREATED:
      'A analys job has started. The analysis will take a while so please check the saved analysis list in a few minutes.',
    CANNOT_REMOVE_AS_DEMO: 'As a demo user you cannot remove an analysis',
    NO_DATA_FOUND: 'No data found',
    ANALYSIS_SUCCESS: 'Analysis succeed',
    ANALYSIS_ERROR: 'Analysis failed.'
  },
  GlobalSettingsSidebar: {
    USERNAME: 'Username',
    OVERVIEW: 'General Settings',
    USER_INFO: 'User Information',
    CHANGE_PASSWORD: 'Change User Password',
    ADD_SYSTEM: 'Add system',
    CREATE_POWERSHARE: 'Create multi-system'
  },
  GlobalSettings: {
    GLOBAL_SETTINGS: 'Global settings',
    COLOR_PALETTE: 'Color palette',
    COLOR_PALETTE_INFO: 'Note that color settings only applies to graphs',
    DEFAULT: 'Default',
    COLOR_BLIND: 'Colorblind friendly',
    COLOR_MODE_CHANGED: 'Color palette has been changed',
    LANGUAGE_SETTINGS: 'Language Settings',
    USER_INFORMATION: 'User information',
    USER_IDENTIFICATION: 'User-ID',
    FIRST_NAME: 'First name',
    LAST_NAME: 'Last name',
    PHONE_NUMBER: 'Phone number',
    PHONE_NUMBER_INFO: 'Phone number is optional but is recommended for faster support',
    UPDATE_USER_INFORMATION: 'Update user information',
    USER_INFORMATION_UPDATED: 'User information updated',
    CREATED: 'Created',
    EMAIL: 'Email',
    CHANGE_PASSWORD: 'Change password',
    NEW_PASSWORD: 'New password',
    CHANGE_PASSWORD_INFO:
      'The password must be between 8 and 100 characters long, contain at least 1 digit, 1 uppercase and 1 lowercase letter.',
    CONFIRM_NEW_PASSWORD: 'Confirm new password',
    EXISTING_PASSWORD: 'Current password',
    FORM_INVALID: 'The form is invalid',
    PASSWORD_ERROR_MIN: 'Atleast 8 characters',
    PASSWORD_ERROR_MAX: 'Max 100 characters',
    REQUIRED_FIELD: 'Required field',
    PATTERN_MATCH_ERROR:
      'Incorrect format, must contain at least one digit, one uppercase and one lowercase letter',
    PASSWORD_VALID_CHARACTERS:
      'The password can only contain the following characters: a-ö, A-Ö, 0-9, @$!%*?',
    PASSWORD_MATCH: 'The passwords do not match',
    PASSWORD_TYPE: 'Password does not seem to match the requirements.',
    WRONG_EXISTING_PASSWORD: 'Incorrectly existing password',
    ERROR_OCCURRED: 'Something went wrong, please contact support if this continues',
    PASSWORD_CHANGED: 'Password changed',
    ADD_NEW_SYSTEM: 'Add system',
    DEVICE_IDENTIFICATION: 'System identification',
    DEVICE_IDENTIFICATION_LENGTH: 'Must be exactly 4x8 characters long',
    DEVICE_IDENTIFICATION_PATTERN: 'Allowed characaters: A-Z and 0-9.',
    DEVICE_IDENTIFICATION_INFO:
      'Device Identification is retrieved at purchase of your energy hub. It comes either in the box or by email from the Ferroamp staff',
    SYSTEM_DISPLAY_NAME: 'System display name',
    SYSTEM_DISPLAY_NAME_INFO:
      'What should this System name be? Example: " My own energy hub in the basement"',
    LOCATION_INFO:
      'Address, Country, City, Longitude, Latitude and Timezone is where the energy hub is located',
    ADDRESS: 'Address',
    CITY: 'City',
    COUNTRY: 'Country',
    LATITUDE: 'Latitude',
    LONGITUDE: 'Longitude',
    CONTACT_PERSON: 'Contact person',
    TIMEZONE: 'Timezone',
    TIMEZONE_ERROR: 'Error evaluating timezone. Please select manually',
    TIMEZONE_HINT: 'Required to show the data correctly. Can NOT be changed afterwards.',
    AGREEMENT_INFO: 'Read our agreement about the owner rights of the data and more, visit the link below.',
    AGREEMENT:
      'By adding a System, you accept Ferroamp\'s  <a target="_blank" href="/terms-and-conditions/user-agreement">user agreement</a>',
    GET_LOCATION: 'Get location',
    GET_LOCATION_INFO:
      'If you do not know you timezone, latitude or longitude click this button to auto fill the information. You must fill the address-, city-, and the country-field at least for this to work.',
    PLEASE_FILL: 'Please fill in',
    FOUND: 'Found',
    ADD_SYSTEM_SUCCESS:
      'Successfully created a new system. Please remember to restart the EnergyHub before making configuration changes.',
    NEW_SYSTEM_LINK: "You will be redirected to the facility's overview.",
    CreateOneFacilityNoExistingSingleBoardComputerError:
      'Incorrect registration key as it does not belong to an existing EnergyHub.',
    CreateOneFacilitySingleBoardComputerTakenError:
      'The registration key is already used to create a facility',
    CreateOneFacilityInvalidLifeCycleStageError: 'EnergyHub is not available as it is in an incorrect stage',
    CreateOneFacilityPendingFacilityError:
      'You have too many installations in progress, finish some before continuing this one',
    InvalidTimezoneError: 'The time zone is not correct. Please select another one and try again'
  },
  SystemSettings: {
    ENERGY_BIDDING_ZONE: 'Electricity bidding area',
    ENERGY_BIDDING_ZONE_HINT:
      'Electricity bidding area is required if you want to use spot price optimization',
    PAGE_WILL_BE_RELOADED: 'Page will be reload to apply the new settings.',
    ALARMS: 'Alarms',
    ALARMS_LOG: 'Alarms log',

    SYSTEM_PARAMETERS_UPDATE_UNAVAILABLE_STATE_RUNNING:
      'System Parameters cannot be changed while the system is running. Please stop the system before changing System Parameters.',
    SYSTEM_PARAMETERS: 'System parameters',
    REQUEST_TO_ENERGYHUB_TIMEDOUT: 'Failed. The energyhub system did not respond in time.',
    SENT_TO_ENERGY_HUB: 'The request has been sent to the energyhub system, waiting for response.',
    REFERENCE: 'Reference',
    LOWER: 'Lower',
    UPPER: 'Upper',
    CURRENT_THRESHOLD: 'Current threshold',
    GRID_POWER: 'Grid power',
    DISABLED: 'Disabled',
    ENABLED: 'Enabled',
    NO_DATA: 'No data found',
    SETTING: 'Settings',
    SYSTEM_SETTINGS: 'System settings',
    VIEW_CONTROL: 'View Control',
    SAVE_IMAGE: 'Save Image',
    SYSTEM_INFORMATION: 'System information',
    SYSTEM_ID: 'System-id',
    SYSTEM_ID_INFO: 'Automatic generated System identifaication',
    SYSTEM_NAME: 'Name of the system',
    SYSTEM_NAME_INFO: 'Will be used to display the system',
    CONTACT_PERSON: 'Contact person',
    INSTALLED: 'Installed',
    SYSTEM_INSTALLED: 'Installation date',
    SYSTEM_INSTALLED_INFO: 'When the system were installed',
    INFORMATION_LAST_UPDATED_BY: 'Last updated by',
    INFORMATION_LAST_UPDATED_BY_INFO: 'The user who last updated the system information',
    MODIFIED_DATE: 'Modified date',
    LOCATION_INFORMATION: 'Location',
    AT_THE_ENERGYHUB: ' at the Energyhub',
    ADDRESS: 'Address',
    CITY: 'City',
    COUNTRY: 'Country',
    TIMEZONE: 'Timezone',
    LONGITUDE: 'Longitude',
    LATITUDE: 'Latitude',
    SYSTEM_LOCATION_HINT:
      'If you do not know you timezon, latitude or longitude click this button to auto fill the information. You must fill the address-, city-, and the country-field atleast for this to work.',
    GET_SYSTEM_LOCATION: 'Get geolocation',
    ERROR_GET_TIMEZONE: 'Error evulating timezone. Please select manually',
    YOU_MUST_FILL: 'You must fill: ',
    IF_NOT_ADMIN_NOTICE:
      'If you are not the administrator you have to logout and register the user and the System under Signup.',
    MUST_HAVE_ADMIN_ACCESS: 'You must be the administrator to add System this way.',
    ADDRESS_FOUND: 'Address found: ',
    ACE_INFORMATION: 'ACE Information',
    MAIN_FUSE: 'Main fuse',
    MAIN_FUSE_SIZE: 'Main fuse rating',
    MAIN_FUSE_SIZE_INFO: 'This setting is moved to SYSTEM SETINGS -> SYSTEM CONFIGURATION',
    SOLAR_SYSTEM_INFORMATION: 'Solar Production Information',
    PEAK_POWER: 'PV peak power',
    PEAK_POWER_INFO: 'Rated Peak Power of the PV plant',
    EXTRA_INFORMATION: 'Electricity cost and extra',
    ELECTRICITY_COST: 'Cost [cost/kWh]',
    ELECTRICITY_COST_INFO: 'Estimated Electricity cost per kWh of used energy',
    REVENUE: 'Production Revenue [revenue/kWh]',
    REVENUE_INFO: 'Estimated price per produced kWh (if PV plant)',
    REVENUE_SOLD_TO_GRID: 'Revenue sold energy [Revenue/kWh]',
    REVENUE_SOLD_TO_GRID_INFO: 'The price per kWh you get when selling energy to the grid',
    REVENUE_PRODUCED_SOLAR: 'Revenue produced solar energy [Revenue/kWh]',
    REVENUE_PRODUCED_SOLAR_INFO: 'The price per kWh you get per produced kWh solar energy',
    CURRENCY: 'Currency',
    PROPERTY_DESIGNATION: 'Property desgination',
    PROPERTY_DESIGNATION_INFO: '',
    GRID_COMPANY_SYSTEM_ID: 'Electric grid company identification',
    GRID_COMPANY_SYSTEM_ID_INFO: '',
    UPDATE: 'Update',
    UP_TO_DATE: 'Informationen är den senaste',
    MD_MAX_LENGTH: 'Max number of allowed characters:',
    ERROR_OCCURRED: 'An error occurred, try again later or contact support.',
    INVALID_FORM: 'The form is invalid, please correct and try again',
    ERROR_STORE_COMMENT: "An error occurred and couldn't store the comment",
    NOT_FOUND_LOCATION: "Couldn't locate the address given, please go through the location and try again. ",
    USER: 'User',
    TIME_OF_UPDATE: 'Time when updated',
    CHANGES: 'Changes',
    TITLE: 'Title',
    COMMENTS: 'Comments',
    COMMENT: 'Comment',
    ADD_COMMENT: 'Add comment',
    REGISTERED_USERS: 'Registered users',
    REGISTERED_USERS_EXPLANATION: 'The following user has access to this System.',
    USER_ID: 'ID',
    EMAIL: 'Email',
    FIRST_NAME: 'First name',
    LAST_NAME: 'Last name',
    PHONE_NUMBER: 'Phone number',
    PERMISSION: 'Permission',
    PASSWORD: 'Password',
    CONFIRM_PASSWORD: 'Confirm password',
    NOT_VALID_FORM: 'The form is not valid',
    REGISTER: 'Register',
    PASSWORD_DONT_MATCH: 'Password do not match.',
    ADDED_BY: 'Added by',
    ADD_USER: 'Add user',
    ADD_EMAIL: 'Add the user email ',
    ADD_USER_EXPLANATION:
      'This email is not associated with any account. Register new user below. An email will be sent to the user with informations how the new user should log in. Please be aware that there been some bugs. We have trying to solve this. But for any reason you cannot add a user, please contact info@ferroamp.se and describe the problems.',
    REMOVE: 'Remove',
    ADMIN: 'Admin',
    VIEWER: 'Viewer',
    NOT_VALID_EMAIL: 'Not an valid email',
    CHOOSE_PERMISSION: 'Choose permission',
    CHANGE_PERMISSION: 'Change permission',
    PERMISSION_NOT_CHANGED: 'Permission were not changed',
    PERMISSION_CHANGE_ERROR: 'An error occured when the permissions were attempted to change',
    PERMISSION_UPDATED: 'The permission has not been changed for user: ',
    USER_ADDED: 'The user <USER> has been added',
    CLOSE: 'Close',
    ACCESS_DELETE: 'Do you want to remove access for this user:: <USER>?',
    ACCESS_DELETE_INFO:
      'If you remove the access for this user you can later add the user again just by typing the email in the add user field.',
    KEEP_USER: 'Keep this user',
    ACCESS_DELETE_FAILED: "The user couldn't be removed.",
    ACCESS_DELETE_SUCCESS: 'The user: <USER> is now removed and are not able to see this system anymore.',
    SYSTEM_TOPOLOGY: 'System topology',
    DELETE_UNIT: 'Delete unit',
    SERIAL_NUMBER: 'Serial number',
    TYPE: 'Type',
    PARTNO: 'Part number',
    ENERGY_HUB: 'EnergyHub',
    NAME: 'Namn',
    DESCRIPTION: 'Description',
    SOLAR_STRING: 'Solar strings',
    NUMBER_OF_PANELS: 'Number of Panels',
    POWER_PER_PANEL: 'WattPeak/Panel',
    BRAND: 'Panel Brand',
    EDIT: 'Edit',
    EDIT_EXPLANATION:
      'To change name (or other parameters) press the row for which unit you would like to edit. Then when you have filled the name press "Enter". To store the changes after editing you must press : "Update system topology"',
    ENERGY_STORAGE: 'Energy storage',
    DELETE_UNIT_CONFIRM: 'Do you really want to delete <TYPE> with serial number <ID>',
    DELETE_UNIT_INFO:
      'You will not be able to regret this deletion. If the unit still report data from the system it will reappear in this list, but custom settings as name description etc will be lost. ',
    CONFIRM_DELETE_UNIT: 'Yes, delete this unit',
    DONT_DELETE_UNIT: 'No, do not delete this unit',
    ERROR_DELETE_UNIT: 'Were not able to delete this unit, please try again later. ',
    UPDATE_TOPOLOGY: 'Update system topology',
    ERROR_OCCURRED_TOPOLOGY: 'An error occurred when trying to update topology',
    UPDATE_COMPLETED: 'Updated successfully',
    SYSTEM_CONFIGURATION: 'System configuration',
    SUCCESS: 'Success',
    FAILED: 'Failed',
    ACCEPT_CHANGES: 'Yes',
    DECLINE_CHANGES: 'No',
    ON_DECLINED_CHANGES: 'You did not make any changes',
    OPERATION_MODE: 'Operation mode',
    RUN_MODE: 'Running mode',
    GET_OPERATION_MODE: 'Get operation mode',
    OPERATION_MODE_RECEIVED: 'Operation mode received',
    GETTING_OPERATION_MODE: 'Getting the current operation mode from the Energyhub system, please wait',
    ENERGYHUB_RECEIVED_OPERATION_MODE_REQUEST: 'The Energyhub system has received the Run Mode request.',
    TRYING_TO_SET_OPERATION_MODE: 'Trying to change the settings: <TYPE> in the Energyhub system',
    CURRENT_OPERATION_MODE: 'Current operating mode ',
    MEASURING: 'Measuring',
    FAULT_MODE: 'Fault mode',
    MEASUREMENT_MODE: 'Measuring mode',
    UNDEFINED_MODE: 'Undefined mode, contact support',
    WARNING_RUN_MODE_DID_NOT_CHANGE:
      'Operations mode seems to not have change, please try again. If this warning occurs again please contact support at mail <a href="mailto:support@ferroamp.se">support@ferroamp.se</a> or phone <a href="tel:+46868433390">+46  (0)8 684 33 390</a>.',
    CONFIRM_OPERATION_MODE:
      'Are you sure you want to change operation mode from <span class="color-blue"><FROM></span> to <span class="color-blue"><TO></span>?',
    OPERATION_MODE_MUST_BE_STOPPED:
      "The system needs to be in <em>'Measurement mode'</em> to set the <em>operation settings</em>",
    GET_OPERATION_SETTINGS: 'Get operation settings',
    UPDATE_OPERATION_SETTINGS: 'Update operation settings',
    OPERATION_SETTINGS_TO_ENERGYHUB:
      'Sending the new settings to the Energyhub settings, please wait until response.',
    ENERGYHUB_RECEIVED_OPERATION_SETTINGS:
      'The Energyhub system has received the new setting and trying to configure them, please wait until confirmation is received',
    OPERATION_SETTINGS_RECEIVED: 'The operation settings are received',
    OPERATION_SETTINGS: 'Operation settings',
    OPERATION_SETTINGS_ERROR: 'An error occurred when the new operation settings were set, reason:<REASON>',
    OPERATION_SETTINGS_UPDATE_SUCCESS: 'The new settings <CONFIG> were successfully stored.',
    GETTING_OPERATION_SETTINGS:
      'Getting the latest operation settings from the Energyhub systemet, please wait',
    MODES: 'Modes',
    ACE: 'ACE',
    ACE_THRESHOLD: 'ACE Threshold',
    SOLAR: 'PV',
    AND: 'and',
    ONLY_MEASURING: 'Only measuring',
    SET_OPERATION_SETTINGS_NOT_ALLOWED: 'Operation settings cannot be changed right now.',
    OPERATION_SETTINGS_SYS_STATUS_ERROR:
      'The system is running with reduced functionality please contact support ( mail: <a href="mailto:support@ferroamp.se">support@ferroamp.se</a> or by phone: <a href="tel:+46868433390">+46 (0)8 684 33 390</a>).',
    CONFIRM_OPERATION_SETTINGS_CHANGES:
      'Do you want to set the following settings <span class="color-blue">ACE threshold: <ACE></span> and <span class="color-blue">Mode: <MODE></span>?',
    SYSTEM_CONFIG_NO_CHANGES:
      'No changes were made to the operation settings, therefore we do not send any new settings.',
    CT_SETTINGS: 'Current transformers',
    CT_RATIO: 'Current transformer ratio',
    DISPLAY_ACCESS: 'The Energyhub System',
    DISPLAY_ACCESS_RUNMODE: 'The energyhub system has sent an new <em>operation mode</em>: <TYPE>',
    DISPLAY_ACCESS_EMSCONFIG: 'The energy hub system has sent new <em>operation settings</em>',
    USER_JOIN_ROOM: 'The user <USER> joined the system configuration',
    RESPONSE_ACK:
      'The system has received the request and will try to resolve it, please  wait for response. ',
    RESPONSE_NAK: 'The request failed due too reason: <REASON>. Please try again or contact support. ',
    WAIT_UNTIL_RESULT: 'Please wait until settings are received form the Energyhub systemet',
    SYSTEM_SETTINGS_RESOLVE_ERROR:
      'The systems seem to have a problem with resolving your request, please try again or contact support',
    NEW_REQUEST: 'A new request of type <TYPE> has been requested.',
    ENERGYHUB_SYSTEM_RECEIVED_GET_REQUEST:
      'The energyhub system has recieved the request and is and trying to resolve it. ',
    SYSTEM_CONFIGURATION_OPERATION_MODE_INFO:
      '<h2>Operation Mode</h2> <p>The <em>Operation mode</em> defines the state of the EnergyHub system. The following modes are possible. </p> <ul> <li> <b>Measuring:</b>The system performs measurements only. Power electronics is disabled.</li> <li> <b>Running:</b>The system performs <em>PV production</em>, <em>phase balancing (ACE)</em>, <em>battery charging/discharging</em> and <em>measurements</em>. Power electronics is active. </li> <li><b>Fault:</b> The system has detected a fault. If this persists, please contact Ferroamp support <a href="mailto:support@ferroamp.se">(support@ferroamp.se)</a>. </li> </ul> <ul> <li><b>Start:</b> Send a command to set the system to <em>Running mode</em>.</li> <li><b>Stop:</b> Send a command to set the system to <em>Measuring mode</em>. The system must be in <em>Measuring mode</em> in order to change <em>Operation settings</em>.</li> </ul>',
    SYSTEM_CONFIGURATION_OPERATION_SETTINGS_INFO:
      '<h2>Operation Settings</h2> <p>The <em>Operation settings</em> define how the system works when in <em>Running mode</em>.</p> <p><b>Mode:</b> Different modes defines how the system should operate. There following modes are available:</p> <ul> <li><b>ACE:</b> Enables phase balancing (ACE). To run <em>ACE</em> the <em>ACE threshold</em> must be set.</li> <li><b>ACE & PV</b>: Enables both <em>ACE</em> and <em>PV production</em>.</li> <li><b>PV</b>: Enables PV production only</li> </ul> <p><b>ACE threshold:</b> Grid current threshold in Ampere when phase balancing should be activated.</p> <h4>Changing Operation settings</h4> <ul class="operation_change_settings_info"> <li><b>Stop the system</b> by pressing <em>"Stop"</em></li> <li><b>Make the requested changes</b> by altering the parameters in <em>Operation settings</em></li> <li><b>Update Operation settings</b> by pressing <em>"Update settings"</em></li> <li><b>Start the system</b>, set the operation mode back to <em>"Running Mode"</em></li> </ul>',
    SYSTEM_CONFIGURATION_ICON_INFO:
      '<h3>Icon explanation</h3><ul><li><i aria-hidden="true" class="fa title=" The EnergyHub System"></i> - The EnergyHub System</li><li><i aria-hidden="true" class="fa fa-user color-blue" title="Another user"></i> - Another active Portal User</li>',
    SYSTEM_STATUS: 'System status',
    LOG_ERROR: 'Error',
    LOG_INFO: 'Info',
    LOG_WARNING: 'Warning',
    LOG_SUCCESS: 'Success',
    CLOUD_API: 'API',
    CLOUD_IP_EPLANATION: 'Förklaring av API bla bla bla',
    API_KEY: 'API Key',
    GENERATE_API_KEY: 'Generate API Key',
    SAVE_API_SETTINGS: 'Save API Configurations',
    BIND_TO_IP: 'Bind API to IP',
    MY_IP: 'Use my IP',
    LIST_OF_CHANGES: 'List of changes',
    MAX_BIND_TO_FIVE_IP: 'Can only bind 5 IPs.',
    EXPORT_DATA: 'Export Data',
    EXPORT_DATA_INFO:
      'You can download at most 30 days in each request. NOTE that each days is approximately 17-20 MB at one second resolution',
    START_TIME: 'From date',
    RESOLUTION: 'Resolution',
    END_TIME: 'To date',
    GET_DATA: 'Get data',
    DATA_FORMAT: 'Data format',
    DATA_TYPE: 'Data type',
    SECOND: 'Second',
    SECONDS: 'Seconds',
    MINUTE: 'Minute',
    HOUR: 'Hour',
    ENERGY_STORAGE_OPTIMIZER: 'Energy storage optimizers',
    ENERGY_COUNTERS: 'Energy counters',
    SOLAR_STRING_OPTIMIZER: 'Solar string optimizers',
    AGGREGATED_ENERGY_STORAGE: 'Aggregated energy storage',
    UI_DATA_TITLE: 'UiData - Currents, voltages and power',
    MAX_ALLOWED_NUMBER_OF_DAYS: 'Max number that are allowed to be requested with these settings are: ',
    DAYS: 'days',
    WHICH_ARE_EXPORTED: 'which are exported are: : ',
    PLEASE_WAIT_DOWNLOAD:
      'Please wait for the download to start when pressing the button, can take up to several minutes depending on the resolution and time interval.',
    MISSING_DATA_EXPLANATION:
      'If there is three commas (,) and no timestamp for a row, this data has never been reported from the system',
    CONVERT_CSV_TO_XLSX: 'Convert csv to xlsx',
    OPEN_IN_EXCEL: 'Start by open the file in excel',
    NAVIGATE_TO_DATA_TEXT: 'Then navigate to "data/text to columns"',
    CONVERT_TO_EXCEL:
      'Then follow the instructions, be sure too click comma when choosing which is the delimiter. ',
    DATA_EXPLANATION: 'Data Explanation',
    SHOW: 'Show',
    LAST_UPDATED: 'Last updated',
    NONE: 'None',
    DISCHARGE: 'Discharge',
    CHARGE: 'Charge',
    THRESHOLD: 'Threshold',
    BATTERY_POWER_REF: 'Battery Power Reference',
    BATTERY_SOC: 'Battery State Of Charge',
    OFF: 'Off',
    EXPORT: 'Export',
    IMPORT: 'Import',
    LIMIT: 'Limit',
    BATTERY_POWER: 'Battery Power',
    LOWER_SOC_REFERENCE: 'Lower SoC Reference',
    UPPER_SOC_REFERENCE: 'Upper SoC Reference',
    GRID_THRESHOLD: 'Grid Threshold',
    POWER_REFERENCE: 'Power Reference',
    SET: 'Set',
    GET: 'Get',
    WIZARD: 'Wizard',
    BATTERY_WIZARD: 'Battery Wizard',
    STATUS: 'Status',
    MISSING_ENERGY_COUNTER_LINK: 'https://ferroamp.freshdesk.com/a/solutions/articles/47001219726?lang=en',
    WARNING_ACTIVATED_SCHEDULING: 'Scheduling is enabled',
    OPERATION_SETTINGS_CONFIG_HANDLED_BY_SCHEDULING:
      'Click on "Scheduling" to manage settings in the schedule. Click on "Temporary Settings" to make a temporary change. The override will be in effect until the next scheduled event.',
    GO_TO_SCHEDULING: 'Go to scheduling',
    TEMPORARY_OPERATION_SETTINGS: 'Temporary operation settings'
  },
  Alarms: {
    STATUS_TITLE: 'Alarm status',
    STATUS_OPEN_DOCUMENTATION: 'Open documentation',
    GENERAL_DOCUMENTATION_LINK: 'https://support.ferroamp.com/en/support/solutions/folders/47000787939',
    NOTIFY_ALL: 'Manage subscribers',
    ALARM_CODE: 'Alarm type',
    ALARM_PRODUCT_ID: 'Id',
    ALARM_STATUS: 'Status',
    ALARM_LAST_CHANGED: 'Last changed',
    ALARM_SUBSCRIBE: 'Subscribe',
    ALARM_DETAILS: 'Details',
    AFFECTED_SSOS: 'Affected SSOs',
    ALARM_INFO: 'Information',
    ALARM_RAISED_WHEN: 'Triggered',
    ALARM_RESOLVED_WHEN: 'Resolved',
    TOOLTIP_ALARM_INFO: 'Read more about this alarm',
    TOOLTIP_ALARM_ACTIVE: 'An error was detected',
    TOOLTIP_ALARM_INACTIVE: 'Everything is OK',
    TOOLTIP_ALARM_UNKNOWN: 'Unknown status',
    ALARM_LOG_TITLE: 'Alarm log',
    NOTIFICATIONS_TITLE: 'Notifications',
    WHO_DO_WE_CALL: 'Who should we notify?',
    SUBSCRIBABLE_ALARM_CODE_ALL: 'for all alarms',
    SUBSCRIBABLE_ALARM_CODE_SYSTEM_OFFLINE: 'System offline',
    SUBSCRIBABLE_ALARM_CODE_SSO_OFFLINE: 'SSO offline',
    SUBSCRIBERS: 'Subscribers for this alarm',
    SUBSCRIBED_TO_EVERYTHING: 'Subscribers for all alarms',
    ADD: 'Add',
    SUPPORT_LINKS: {
      SYSTEM_OFFLINE:
        'https://support.ferroamp.com/en/support/solutions/articles/47001257481-how-does-the-system-offline-alarm-work-',
      SSO_OFFLINE:
        'https://support.ferroamp.com/en/support/solutions/articles/47001257479-how-does-the-sso-offline-alarm-work-'
    },
    USER_ALREADY_ADDED: 'User or email is already added',
    NOT_VALID_EMAIL: 'Not an valid email',
    USER_ALREADY_ADDED_TO_ALL_ALARMS: 'User or email already subscribed to all alarms.'
  }
}
