import { EChartOption } from 'echarts'
import moment from 'moment-timezone'
import { DEFAULT_TIMEZONE } from '../../environments/environment'
import { isMobile, roundTo2Decimals } from '../general'
import { getFerroConfiguration } from '../general/get-ferroconfiguration'

export const echartsTooltip = (): EChartOption.Tooltip => {
  // Append to body exists as valid options but not in the types.
  return {
    trigger: 'axis',
    axisPointer: { type: 'shadow' }
    // appendToBody: true,
    // alwaysShowContent: isMobile(),
    // confine: isMobile()
  } as EChartOption.Tooltip
}

export const echartsToolbox = (): {
  // iconStyle: { color: string }
  emphasis: {
    iconStyle: {
      borderColor: string
    }
  }
  feature: {
    dataView: {
      show: true
      readOnly: true
      name: string
      title: string
      lang: string[]
      buttonColor: string
    }
    saveAsImage: { show: true; name: string; title: string }
    dataZoom: { title: { zoom: string; back: string } }
    restore: { title: string }
  }
} => {
  if (isMobile()) return
  const fc = getFerroConfiguration()
  const l = fc.language.SystemDashboard
  const l2 = fc.language.Views
  const timezone = fc.facility.timezone

  return {
    // iconStyle: {
    //     color: fc.colls.greenL
    // },
    emphasis: {
      iconStyle: {
        borderColor: fc.themeColor.primary.base
      }
    },
    feature: {
      dataView: {
        // @ts-expect-error This is not a part of the original ECharts. This is defined in @cloud/echarts
        exportTimeFormatter: echartsTimeFormatters(timezone).withTimezone,
        show: true,
        readOnly: true,
        title: l.EXPORT_DATA,
        name: l.EXPORT_DATA,
        lang: [l.DATA_VIEW, l.TURN_OFF, l.REFRESH],
        buttonColor: fc.graphsColors.redB
      },
      saveAsImage: { show: true, name: l.SAVE_IMAGE, title: l.SAVE_IMAGE },
      dataZoom: {
        title: { zoom: l2.ZOOM, back: l2.RESET_ZOOM }
      },
      restore: {
        title: l2.RESTORE_GRAPH
      }
    }
  }
}

type DateParseFunction = (date: Date) => string

export const echartsTimeFormatters = (
  timezone: string
): {
  year: DateParseFunction
  month: DateParseFunction
  weekNumber: DateParseFunction
  days: DateParseFunction
  hours: DateParseFunction
  minutes: DateParseFunction
  seconds: DateParseFunction
  onlyHours: DateParseFunction
  withTimezone: DateParseFunction
} => {
  const TZ = timezone || DEFAULT_TIMEZONE
  const nullChecker = (date: Date, dateString: string): string => {
    if (date === null) return ''
    else if (new Date(date).valueOf() < 2000) return ''
    else return dateString
  }
  const formatter = (date: Date, format?: string) => {
    let parsed: string

    if (TZ === 'UTC') {
      parsed = moment(date).format(format)
    } else {
      parsed = moment.tz(new Date(date), TZ).format(format)
    }
    return nullChecker(date, parsed)
  }

  return {
    year: (date: Date) => formatter(date, 'YYYY'),
    month: (date: Date) => formatter(date, 'YYYY-MM'),
    weekNumber: (date: Date) => formatter(date, 'YYYY-[W]W'),
    days: (date: Date) => formatter(date, 'YYYY-MM-DD'),
    hours: (date: Date) => formatter(date, 'YYYY-MM-DD \n\r HH:00'),
    minutes: (date: Date) => formatter(date, 'YYYY-MM-DD HH:mm'),
    seconds: (date: Date) => formatter(date, 'YYYY-MM-DD HH:mm:ss'),
    onlyHours: (date: Date) => formatter(date, 'HH'),
    withTimezone: (date: Date) => formatter(date)
  }
}

export const defaultEchartsYAxisEnergy = (ENERGY: string, ops?: EChartOption.YAxis): EChartOption.YAxis => {
  const name = `${ENERGY} [kWh]`
  return {
    type: 'value',
    name: isMobile() ? '' : name,
    axisLabel: isMobile() ? { formatter: '{value}' } : { formatter: '{value} kWh' },
    min: ops ? ops.min : null,
    max: ops ? ops.max : null
  }
}

export const defaultEchartsYAxisPower = (POWER: string, ops?: EChartOption.YAxis): EChartOption.YAxis => {
  const name = `${POWER} [kW]`
  return {
    type: 'value',
    name: isMobile() ? '' : name,
    axisLabel: isMobile() ? { formatter: '{value}' } : { formatter: '{value} kW' },
    min: ops ? ops.min : null,
    max: ops ? ops.max : null
  }
}

export const defaultEchartsGrid: EChartOption.Grid = {
  // top: "10%",
  // left: "10%",
  // right: "10%"
  //
}

const datazoomHandleProperties = {
  handleSize: '80%',
  handleStyle: {
    color: '#fff',
    shadowBlur: 3,
    shadowColor: 'rgba(0, 0, 0, 0.6)',
    shadowOffsetX: 2,
    shadowOffsetY: 2
  }
}
export const dataZoomHandleIconHorizontal =
  'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z'
export const dataZoomHandleIconVertical =
  'm 19.35094,22.04906 h 1.3 v -1.4 h -1.3 c -0.3,-4.9 -4.4,-8.8 -9.3999995,-8.8 -5,0 -9.10000005,3.9 -9.40000005,8.8 h -1.3 v 1.3 h 1.3 c 0.3,4.9 4.40000005,8.8 9.40000005,8.8 4.9999995,0.1 9.0999995,-3.8 9.3999995,-8.7 z m -12.4999995,2.6 v -6.6 h 1.4 v 6.6 z m 4.7999995,0 v -6.6 h 1.4 v 6.6 z'

export const dataZoomVerticalHandle = {
  dataZoomHandleIconVertical,
  ...datazoomHandleProperties
}
export const dataZoomHorizontalHandle = {
  handleIcon: dataZoomHandleIconHorizontal,
  ...datazoomHandleProperties
}

export const datazoomWhenIsModule: EChartOption.DataZoom[] = [
  {
    type: 'slider',
    start: 0,
    end: 100,
    showDetail: false,
    ...dataZoomHorizontalHandle
  },
  {
    type: 'inside',
    start: 0,
    end: 100
  }
]

export const defaultEchartsDataZoom: EChartOption.DataZoom[] = isMobile() ? datazoomWhenIsModule : null

/* EChartOption.Tooltip.Format ::::

$vars: (3) ["seriesName", "name", "value"]
axisDim: "x"
axisId: "Date and  Time0"
axisIndex: 0
axisType: "xAxis.time"
axisValue: 1554714060000
axisValueLabel: "11:01↵04-08"
borderColor: undefined
color: "#2f4554"
componentIndex: 1
componentSubType: "line"
componentType: "series"
data: {value: Array(2)}
dataIndex: 661
dataType: undefined
dimensionNames: (2) ["x", "y"]
encode: {x: Array(1), y: Array(1)}
marker: "<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#2f4554;"></span>"
name: ""
seriesId: "Norra taket, slinga 30"
seriesIndex: 1
seriesName: "Norra taket, slinga 3"
seriesType: "line"
value: (2) ["2019-04-08T09:01:00.000Z", 1266.0966666666668]


or:
axisDim: "x"
axisId: "Date and  Time0"
axisIndex: 0
axisType: "xAxis.category"
axisValue: "Sun Nov 01 2020 13:41:00 GMT+0100 (Central European Standard Time)"
axisValueLabel: "Sun Nov 01 2020 13:41:00 GMT+0100 (Central European Standard Time)"
borderColor: undefined
color: "rgb(114, 147, 203)"
componentIndex: 1
componentSubType: "line"
componentType: "series"
data: 158110
dataIndex: 3488
dataType: undefined
dimensionNames: (2) ["x", "y"]
encode: {x: Array(1), y: Array(1)}
marker: "<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:rgb(114, 147, 203);"></span>"
name: "Sun Nov 01 2020 13:41:00 GMT+0100 (Central European Standard Time)"
seriesId: "Consumption0"
seriesIndex: 1
seriesName: "Consumption"
seriesType: "line"
value: 158110
 */

export const tooltipDateFormatter = (
  type: 'onlyHours' | 'year' | 'month' | 'weekNumber' | 'days' | 'hours' | 'minutes' | 'seconds',
  timezone: string
): EChartOption.Tooltip.Formatter => {
  const formatter = echartsTimeFormatters(timezone)[type]
  return (value: EChartOption.Tooltip.Format[]) => {
    let text = `${formatter(new Date(value[0].axisValue))}<br>`
    value.forEach((val: EChartOption.Tooltip.Format) => {
      let yAxisValue = val.value
      try {
        if (typeof yAxisValue === 'number') {
          yAxisValue = roundTo2Decimals(yAxisValue)
        } else if (val && val.value && (val.value as number[]).length) {
          yAxisValue = roundTo2Decimals((val.value as number[])[(val.encode as { y: number[] })['y'][0]])
        } else {
          yAxisValue = '' as undefined as number
        }

        text += `${val.marker} ${val.seriesName}: <span style="float:right;margin-left:10px;">${yAxisValue}</span><br>`
      } catch (_e) {
        //
      }
    })

    return text
  }
}
