import { LanguageSystemSettings } from '@app/language/language.interface'
import { FerroConfiguration, FerroConfigurationName } from '@app/service'
import angular, { IComponentOptions, IPromise, IScope, ITimeoutService, material } from 'angular'

import { MappedEnergyhubList, SystemStateV2Service, SystemStateV2ServiceName } from './system-state.service'

import { echartsTimeFormatters } from '@lib/echarts'
import { EhubViewerController } from './ehub-viewer.controller'

import { captureSentryException } from '@app/config/sentry'
import { AmplitudeService, AmplitudeServiceName } from '@app/service/amplitude.service'
import { SystemConfigurationEventTypesEnum } from '@app/service/ems-config-v2-io.service'
import { RolesServices, RolesServicesName } from '@app/service/roles.services'
import {
  SystemStateCommandIoEvent,
  SystemStateCurrentStateResponse,
  SystemStateStateChangedIoEvent
} from '@app/service/system-state-v2-http.service'
import { Environment, OTHER_TRANSACTION_ONGOING_STRING } from '@environments/environment'
import { EmsConfNonMeasureDataObject, SysStateMeasurePayloadData } from '@ferroamp/system-configuration-lib'
import energyHubViewerTemplate from './ehub-viewer.template.html'
import templateUrl from './system-state.component.html'

let timer: IPromise<void>

class SystemStateV2 {
  listStorage: MappedEnergyhubList[]
  l: LanguageSystemSettings
  loading = true
  currentState: {
    cssClass: string
    mode: string
  }

  $s: IScope
  $t: ITimeoutService

  service: SystemStateV2Service
  updatedAt: Date
  facilityId: number

  isSystemRunning: boolean

  startOrStop: 'start' | 'stop'
  startBtnClass: 'md-warn' | 'md-primary'

  timestamp: string
  timezone: string

  $mdD: material.IDialogService
  amplitudeService: AmplitudeService

  error = ''

  status = ''
  statusClass = ''
  notAllowed: boolean
  onStateChange: (isSystemRunning: boolean) => void
  constructor(
    ferroConfiguration: FerroConfiguration,
    stateService: SystemStateV2Service,
    $scope: IScope,
    $mdDialog: material.IDialogService,
    $timeout: ITimeoutService,
    amplitudeService: AmplitudeService,
    roles: RolesServices
  ) {
    this.notAllowed = !roles.canUpdateFacilitySystemSettings()
    this.facilityId = Number(ferroConfiguration.facility.id)
    this.timezone = ferroConfiguration.facility.timezone
    this.l = ferroConfiguration.language.SystemSettings
    this.service = stateService
    this.$t = $timeout
    this.$s = $scope
    this.$mdD = $mdDialog
    this.amplitudeService = amplitudeService
  }

  async $onInit(): Promise<void> {
    if (!this.notAllowed) {
      this.service.init({
        facilityId: this.facilityId,
        stateObserver: this.stateObserver.bind(this),
        commandObserver: this.commandObserver.bind(this)
      })
    }
    const data = await this.service.getState().catch(e => {
      captureSentryException(e)
      this.error = this.l.ERROR_OCCURRED
    })

    if (!data) {
      this.error = this.l.NO_DATA
      return
    }

    if (data && data.status === 204) {
      this.error = this.l.NO_DATA
      return
    }

    const _data = data.data as SystemStateCurrentStateResponse

    if (_data) {
      const mode = _data.currentState
      const updatedAt = new Date(_data.updatedAt)
      this.resolveState(mode, updatedAt)
      this.storeList(_data.list)
    }
  }

  $onDestroy(): void {
    this.service.disconnect()
    this.$t.cancel(timer)
    timer = null
  }

  private async storeList(list: { id: string; state: string }[]) {
    const listAsObject: { [id: string]: string } = {}
    list.forEach(d => {
      listAsObject[d.id] = d.state
    })
    this.listStorage = await this.service.getEhubMapping(listAsObject)
  }

  startLoading(): void {
    this.loading = true
    timer = this.$t(() => {
      this.setStatus('TIMED OUT', 'fe-error')
    }, 30000)
  }

  stopLoading(): void {
    this.$s.$applyAsync(() => {
      this.loading = false
    })
    this.$t.cancel(timer)
  }

  setRunMode(): void {
    this.error = null
    if (this.notAllowed) return
    this.startLoading()
    if (this.isSystemRunning) {
      this.service.stopSystem()
      this.amplitudeService.logEvent('Stop System')
    } else {
      this.service.startSystem()
      this.amplitudeService.logEvent('Start System')
    }
  }

  private resolveState(currentState: number, updatedAt: Date) {
    this.$s.$applyAsync(() => {
      this.isSystemRunning = currentState === 1
      if (typeof this.onStateChange === 'function') this.onStateChange(this.isSystemRunning)
      this.timestamp = echartsTimeFormatters(this.timezone).seconds(updatedAt)
      this.startOrStop = this.isSystemRunning ? 'stop' : 'start'
      this.startBtnClass = this.isSystemRunning ? 'md-warn' : 'md-primary'
      this.loading = false
      this.updatedAt = updatedAt
      this.currentState = this.service.currentStateToString(currentState)
    })
    this.stopLoading()
  }

  stateObserver(newState: SystemStateStateChangedIoEvent): void {
    this.loading = false
    try {
      const data = newState.data
      const mode = data.generic.mode
      this.storeList(data.list).catch(captureSentryException)
      this.resolveState(mode, newState.transTs)
    } catch (e) {
      captureSentryException(e)
    }
  }

  setStatus(status: string, statusClass: string): void {
    this.$s.$applyAsync(() => {
      this.error = null
      this.status = status
      this.statusClass = statusClass
    })
  }

  commandObserver(event: SystemStateCommandIoEvent): void {
    switch (event.eventType) {
      case SystemConfigurationEventTypesEnum.SEND:
        if (event.applicationInstanceId.userId !== String(Environment.sub)) {
          this.setStatus('Another user initiated a transaction', 'fe-warn')
        }
        break
      case SystemConfigurationEventTypesEnum.DONE:
        this.setStatus(this.l.ENERGYHUB_RECEIVED_OPERATION_SETTINGS, 'fe-primary')
        break
      case SystemConfigurationEventTypesEnum.FAILED:
        if ((event.data as EmsConfNonMeasureDataObject).reason === OTHER_TRANSACTION_ONGOING_STRING) {
          this.setStatus('There is another transaction ongoing, please wait...', 'fe-warn')
        } else {
          this.setStatus(this.l.OPERATION_SETTINGS_ERROR.replace('<REASON>', 'Unknown'), 'fe-error')
        }
        this.stopLoading()
        break
      case SystemConfigurationEventTypesEnum.SUCCEEDED:
        this.setStatus(this.l.SUCCESS, 'fe-primary')
        if (event.command === 'get') {
          const __data = event.data as unknown as SysStateMeasurePayloadData
          this.resolveState(__data.generic.mode, new Date(event.transTs))
        }
        this.stopLoading()
        break
      default:
        this.setStatus(this.l.ERROR_OCCURRED, 'fe-error')
        this.stopLoading()
        captureSentryException(
          new Error(
            `Undefined ems config event Command: ${event.command} EventType: ${
              event.eventType
            },\n Info: ${JSON.stringify(event, null, 4)}`
          )
        )
    }
  }

  ehubStatus(): void {
    this.$mdD.show({
      locals: { ehubList: this.listStorage },
      controller: ['$mdDialog', 'ehubList', FerroConfigurationName, EhubViewerController],
      templateUrl: energyHubViewerTemplate,
      controllerAs: 'vm',
      parent: angular.element(document.body),
      clickOutsideToClose: true
    })
  }

  initiateGetState(): void {
    if (this.notAllowed) return
    this.error = null
    this.startLoading()
    this.service
      .initateGet()
      .then(() => {
        this.setStatus('Initiated get current state', 'fe-primary')
      })
      .catch(e => {
        captureSentryException(new Error(e.data))
        this.stopLoading()
        this.error = this.l.ERROR_OCCURRED
      })
  }
}

SystemStateV2.$inject = [
  FerroConfigurationName,
  SystemStateV2ServiceName,
  '$scope',
  '$mdDialog',
  '$timeout',
  AmplitudeServiceName,
  RolesServicesName
]

export const SystemStateV2Component: IComponentOptions = {
  controller: SystemStateV2,
  templateUrl,
  bindings: {
    loading: '=',
    onStateChange: '<'
  }
}
