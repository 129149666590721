import { ECharts, EChartOption } from 'echarts'
import { LanguageAnalaysis } from '../../../language/language.interface'
import { ElectricalComponentColors } from '../../../service'
import {
  defaultEchartsDataZoom,
  defaultEchartsYAxisPower,
  defaultEchartsYAxisEnergy,
  echartsOnResize,
  echartsTimeFormatters,
  echartsToolbox,
  echartsTooltip,
  tooltipDateFormatter,
  isMobile,
  echartsInitWrapper
} from '../../../../lib'
import { POWER_DATA_DIMENSIONS, processMainAggregateDataType } from '../../views/power-view'
import {
  SIMULATED_POWER_DIMENSION,
  SimulatedPowerDataSet,
  batterySimulationResolutionTypes,
  SIMULATED_ENERGY_DIMENSION
} from './battery-simulation.component'

import { extractGridData, extractRestData } from './battery-simulation.tools'

export async function createBatterySimulationCharts(
  l: LanguageAnalaysis,
  colors: ElectricalComponentColors,
  _data: processMainAggregateDataType,
  timezone: string
): Promise<{
  grid: ECharts
  rest: ECharts
}> {
  const tooltip = echartsTooltip()
  tooltip.formatter = tooltipDateFormatter('minutes', timezone)
  const data = _data.data

  const grid = await echartsInitWrapper('gridChart')
  const rest = await echartsInitWrapper('restChart')

  const energyyAxis: EChartOption.YAxis = {
    type: 'value',
    name: isMobile() ? '' : `${l.ENERGY} [kWh]`,
    axisLabel: isMobile() ? { formatter: '{value}' } : { formatter: '{value} kWh' }
  }
  const gridOptions: EChartOption = {
    legend: {
      data: [l.GRID_POWER]
    },
    dataset: {
      source: data,
      dimensions: POWER_DATA_DIMENSIONS
    },
    toolbox: echartsToolbox(),
    tooltip: tooltip,
    color: [colors.grid.line],
    yAxis: [defaultEchartsYAxisPower(l.POWER), energyyAxis],
    xAxis: {
      type: 'time',
      name: l.DATE_AND_TIME,
      axisLabel: {
        formatter: echartsTimeFormatters(timezone).minutes
      }
    },
    series: [
      {
        type: 'line',
        symbol: 'none',

        areaStyle: { opacity: 0.2 },
        name: l.GRID_POWER,
        encode: {
          x: 'ts',
          y: 'grid'
        }
      }
    ]
  }

  const tooltipRest = echartsTooltip()
  tooltipRest.formatter = tooltipDateFormatter('minutes', timezone)

  const restOptions: EChartOption = {
    legend: {
      data: [l.CONSUMPTION, l.BATTERY_POWER, l.SOLAR_PRODUCTION]
    },
    dataset: {
      dimensions: POWER_DATA_DIMENSIONS,
      source: data
    },
    color: [colors.consumption.line, colors.battery.line, colors.solar.line],
    toolbox: echartsToolbox(),
    tooltip: tooltipRest,
    yAxis: [defaultEchartsYAxisPower(l.POWER), energyyAxis],
    xAxis: {
      type: 'time',
      name: l.DATE_AND_TIME,
      axisLabel: {
        formatter: echartsTimeFormatters(timezone).minutes
      }
    },
    dataZoom: defaultEchartsDataZoom,
    series: [
      {
        type: 'line',
        symbol: 'none',
        areaStyle: { opacity: 0.2 },
        name: l.CONSUMPTION,
        encode: {
          x: 'ts',
          y: 'load'
        }
      },
      {
        type: 'line',
        areaStyle: { opacity: 0.2 },
        name: l.BATTERY_POWER,
        symbol: 'none',

        encode: {
          x: 'ts',
          y: 'battery'
        }
      },
      {
        areaStyle: { opacity: 0.2 },
        type: 'line',
        symbol: 'none',

        name: l.SOLAR_PRODUCTION,
        encode: {
          x: 'ts',
          y: 'solar'
        }
      }
    ]
  }
  rest.setOption(restOptions)
  grid.setOption(gridOptions)
  echartsOnResize(rest)
  echartsOnResize(grid)
  return {
    grid,
    rest
  }
}

export function attachedSimulatedDataPower(
  grid: ECharts,
  rest: ECharts,
  parsedData: SimulatedPowerDataSet,
  l: LanguageAnalaysis,
  colors: ElectricalComponentColors,
  type: batterySimulationResolutionTypes
): void {
  const gridOps = grid.getOption()
  const restOps = rest.getOption()

  const dimensions = type === 'power' ? SIMULATED_POWER_DIMENSION : SIMULATED_ENERGY_DIMENSION
  const gLegendData = [l.GRID_POWER, l.GRID_POWER_WITH_BATTERY, l.SIMULATED_BATTERY_STATUS]
  const gColors = [colors.grid.line, colors.selfConsumption.line, colors.warning.line]
  const gSeries = gridOps.series
  if (gSeries.length === 1) {
    gSeries[1] = {
      type: 'line',
      symbol: 'none',

      areaStyle: { opacity: 0.2 },
      name: gLegendData[1],
      encode: {
        x: 'ts',
        y: 'grid+SimulatedBattery'
      }
    }
    gSeries[2] = {
      type: 'line',
      symbol: 'none',
      name: gLegendData[2],
      encode: {
        x: 'ts',
        y: 'simulatedSoc'
      },
      yAxisIndex: 1
    }
  }

  grid.setOption({
    legend: {
      data: gLegendData
    },
    dataset: {
      source: parsedData,
      dimensions: dimensions
    },
    color: gColors,
    series: gSeries
  })

  const rLegendData = [
    l.CONSUMPTION,
    l.BATTERY_POWER,
    l.SOLAR_PRODUCTION,
    l.SIMULATED_BATTERY_POWER,
    l.SIMULATED_BATTERY_STATUS
  ]
  const rColors = [
    colors.consumption.line,
    colors.battery.line,
    colors.solar.line,
    colors.gridExport.line,
    colors.warning.line
  ]
  const rSeries = restOps.series
  rSeries[3] = {
    type: 'line',
    name: l.SIMULATED_BATTERY_POWER,
    encode: {
      x: 'ts',
      y: 'simulatedBatPower'
    },
    symbol: 'none',
    areaStyle: { opacity: 0.2 }
  }
  rSeries[4] = {
    type: 'line',
    name: l.SIMULATED_BATTERY_STATUS,
    symbol: 'none',
    encode: {
      x: 'ts',
      y: 'simulatedSoc'
    },
    yAxisIndex: 1
  }
  rest.setOption({
    legend: {
      data: rLegendData
    },
    dataset: {
      dimensions: dimensions,
      source: parsedData
    },
    color: rColors,
    series: rSeries
  })
}

export function attachedSimulatedDataEnergy(
  grid: ECharts,
  rest: ECharts,
  parsedData: SimulatedPowerDataSet,
  l: LanguageAnalaysis,
  colors: ElectricalComponentColors
): void {
  const gridOps = grid.getOption()
  const gridData = extractGridData(parsedData)
  const restData = extractRestData(parsedData)
  const gLegendData = [l.GRID_POWER, l.GRID_POWER_WITH_BATTERY, l.SIMULATED_BATTERY_STATUS]
  const gColors = [colors.grid.line, colors.selfConsumption.line, colors.warning.line]
  const gSeries = gridOps.series

  if (gSeries.length === 1) {
    gSeries[1] = {
      type: 'line',
      symbol: 'none',

      areaStyle: { opacity: 0.2 },
      name: gLegendData[1],
      encode: {
        x: 'ts',
        y: 'grid+SimulatedBattery'
      }
    }
    gSeries[2] = {
      type: 'line',
      symbol: 'none',
      name: gLegendData[2],
      encode: {
        x: 'ts',
        y: 'simulatedSoc'
      },
      yAxisIndex: 1
    }
  }

  grid.setOption({
    legend: {
      data: gLegendData
    },
    dataset: {
      source: gridData,
      dimensions: ['ts', 'load', 'grid+SimulatedBattery', 'simulatedSoc']
    },
    yAxis: [defaultEchartsYAxisPower(l.POWER), defaultEchartsYAxisEnergy(l.ENERGY)],

    color: gColors,
    series: [
      {
        type: 'line',
        areaStyle: { opacity: 0.2 },
        name: l.GRID_POWER,
        encode: {
          x: 'ts',
          y: 'load'
        }
      },
      {
        areaStyle: { opacity: 0.2 },
        type: 'line',
        name: l.GRID_POWER_WITH_BATTERY,
        encode: {
          x: 'ts',
          y: 'grid+SimulatedBattery'
        }
      },
      {
        type: 'line',
        yAxisIndex: 1,
        name: l.SIMULATED_BATTERY_STATUS,
        encode: {
          x: 'ts',
          y: 'simulatedSoc'
        }
      }
    ]
  })

  const rLegendData = [
    l.CONSUMPTION,
    l.SOLAR_PRODUCTION,
    l.BATTERY_POWER,
    l.SIMULATED_BATTERY_POWER,
    l.SIMULATED_BATTERY_STATUS
  ]
  const rColors = [
    colors.consumption.line,
    colors.battery.line,
    colors.solar.line,
    colors.gridExport.line,
    colors.warning.line
  ]

  rest.setOption({
    yAxis: [defaultEchartsYAxisPower(l.POWER), defaultEchartsYAxisEnergy(l.ENERGY)],

    legend: {
      data: rLegendData
    },
    dataset: {
      dimensions: [
        'ts',
        'load',
        'grid+SimulatedBattery',
        'batteryPower',
        'simulatedBatPower',
        'simulatedSoc'
      ],
      source: restData
    },
    color: rColors,
    series: [
      {
        type: 'line',
        areaStyle: { opacity: 0.2 },
        name: l.CONSUMPTION,
        encode: {
          x: 'ts',
          y: 'load'
        }
      },
      {
        areaStyle: { opacity: 0.2 },
        type: 'line',
        name: l.SOLAR_PRODUCTION,
        encode: {
          x: 'ts',
          y: 'grid+SimulatedBattery'
        }
      },
      {
        type: 'line',
        areaStyle: { opacity: 0.2 },
        name: l.BATTERY_POWER,
        encode: {
          x: 'ts',
          y: 'batteryPower'
        }
      },
      {
        type: 'line',
        name: l.SIMULATED_BATTERY_POWER,
        encode: {
          x: 'ts',
          y: 'simulatedBatPower'
        },
        symbol: 'none',
        areaStyle: { opacity: 0.2 }
      },
      {
        type: 'line',
        name: l.SIMULATED_BATTERY_STATUS,
        symbol: 'none',
        encode: {
          x: 'ts',
          y: 'simulatedSoc'
        },
        yAxisIndex: 1
      }
    ]
  })
}
