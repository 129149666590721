import moment from 'moment-timezone'
import { EnergyCounterAsNumbers, EnergyFirstLasteE } from '../../../../types'
import { PartialEnergyCounters } from '@app/graphql/graphql-energycounters.service'

function fixValues(value: number): number {
  const val = value === null ? null : Number(value)
  if (val < 0 || val === null) return null
  else if (val < 100000) {
    return Math.round((100 * val) / 1000) / 100
  } else return Math.round(val / 1000)
}

export type processedTotalEnergyDataArray = [Date, number, number, number, number, number][]

export interface ProcessedEnergyData {
  totalLoadEnergy: number
  totalGridEnergy: number
  totalSolarSelfConsumptionEnergy: number
  totalSoldToGridEnergy: number
  hasPve: boolean
  /** @description Date, solarEnergy, loadEnergy, gridEnergy, selfConumptionsPveEnergy, soldToGridEnergy */
  totalEnergyData: processedTotalEnergyDataArray
  perPhaseEnergyData: [Date, number, number, number, number, number, number][]
}

export type processedEnergyPies = {
  pve?: [number, number]
  loadPhases?: [number, number, number]
  gridPhases?: [number, number, number]
  pvVsLoad?: [number, number]
  hasPve?: boolean
  error?: string
}

export const sumEnergyCounters = (energyCounters: any[]) => {
  return energyCounters.reduce(
    (acc, cur) => ({
      lc1: acc.lc1 + cur.lc1,
      lc2: acc.lc2 + cur.lc2,
      lc3: acc.lc3 + cur.lc3,
      ec1: acc.ec1 + cur.ec1,
      ec2: acc.ec2 + cur.ec2,
      ec3: acc.ec3 + cur.ec3,
      ep1: acc.ep1 + cur.ep1,
      ep2: acc.ep2 + cur.ep2,
      ep3: acc.ep3 + cur.ep3,
      pve: acc.pve + cur.pve
    }),
    {
      lc1: 0,
      lc2: 0,
      lc3: 0,
      ec1: 0,
      ec2: 0,
      ec3: 0,
      ep1: 0,
      ep2: 0,
      ep3: 0,
      pve: 0
    }
  )
}

export function processEnergyPies(start: EnergyFirstLasteE, end: EnergyFirstLasteE): processedEnergyPies {
  let startDataCorrected, endDataCorrected
  if (start.first_e) {
    startDataCorrected = start.first_e
  } else {
    startDataCorrected = start.last_e
  }

  if (end.last_e) {
    endDataCorrected = end.last_e
  } else {
    endDataCorrected = end.first_e
  }
  if (!endDataCorrected || !startDataCorrected)
    return {
      error: 'Invalid samples'
    }

  const totL1 = endDataCorrected.lc1 - startDataCorrected.lc1
  const totL2 = endDataCorrected.lc2 - startDataCorrected.lc2
  const totL3 = endDataCorrected.lc3 - startDataCorrected.lc3

  const ep1 = endDataCorrected.ep1 - startDataCorrected.ep1
  const ep2 = endDataCorrected.ep2 - startDataCorrected.ep2
  const ep3 = endDataCorrected.ep3 - startDataCorrected.ep3

  const ec1 = endDataCorrected.ec1 - startDataCorrected.ec1
  const ec2 = endDataCorrected.ec2 - startDataCorrected.ec2
  const ec3 = endDataCorrected.ec3 - startDataCorrected.ec3

  const totPVE = endDataCorrected.pve - startDataCorrected.pve
  const totLOAD = totL1 + totL2 + totL3

  let totPVESold = ep1 + ep2 + ep3
  let totPVESelf = totPVE - (ep1 + ep2 + ep3)

  // Correction so not invalid data is shown
  totPVESold = totPVE <= 0 ? 0 : totPVESold
  totPVESelf = totPVESelf < 0 ? 0 : totPVESelf
  totPVESelf = totPVE <= 0 ? 0 : totPVESelf

  return {
    pve: [fixValues(totPVESelf), fixValues(totPVESold)],
    loadPhases: [fixValues(totL1), fixValues(totL2), fixValues(totL3)],
    gridPhases: [fixValues(ec1), fixValues(ec2), fixValues(ec3)],
    pvVsLoad: [fixValues(totLOAD), fixValues(totPVE)],
    hasPve: totPVE > 0
  }
}

export function processEnergyDataList(
  data: EnergyCounterAsNumbers[],
  isEnergybucket: boolean,
  timezone: string
): ProcessedEnergyData {
  let resultingArray: processedTotalEnergyDataArray = []
  const resultingArrayPerPhase: [Date, number, number, number, number, number, number][] = []
  let ec1 = 0
  let ec2 = 0
  let ec3 = 0
  let dOffset = 0
  if (isEnergybucket) {
    dOffset = -moment.tz(timezone).utcOffset() * 60000
  }

  let totPVE = 0
  let totalLoadEnergy = 0
  let totalGridEnergy = 0
  let totalSolarSelfConsumptionEnergy = 0
  let totalSoldToGridEnergy = 0

  for (let i = 0; i < data.length; i++) {
    const d = data[i]
    if (d && d.ts) {
      const pvev = isNaN(d.pve) ? 0 : d.pve
      const lc1 = isNaN(d.lc1) ? 0 : d.lc1
      const lc2 = isNaN(d.lc2) ? 0 : d.lc2
      const lc3 = isNaN(d.lc3) ? 0 : d.lc3
      const ec1v = isNaN(d.ec1) ? 0 : d.ec1
      const ec2v = isNaN(d.ec2) ? 0 : d.ec2
      const ec3v = isNaN(d.ec3) ? 0 : d.ec3
      const ep1 = isNaN(d.ep1) ? 0 : d.ep1
      const ep2 = isNaN(d.ep2) ? 0 : d.ep2
      const ep3 = isNaN(d.ep3) ? 0 : d.ep3

      ec1 = ec1v + ec1
      ec2 = ec2v + ec2
      ec3 = ec3v + ec3

      const pve = pvev
      totPVE = pve + totPVE
      const load = lc1 + lc2 + lc3
      const grid = ec1v + ec2v + ec3v
      const selfConumptionsPve = pvev - (ep1 + ep2 + ep3)
      const soldToGrid = ep1 + ep2 + ep3

      totalGridEnergy += grid
      totalLoadEnergy += load
      totalSoldToGridEnergy += soldToGrid
      totalSolarSelfConsumptionEnergy += selfConumptionsPve

      // ADDED DUE ENERGYBUCKET ADDS TIMEZONE OFFSET TO TIME.
      // MUST CONVERT BACK TO REAL TIMEOBJECT
      if (isEnergybucket) d.ts = new Date(new Date(d.ts).getTime() + dOffset)

      resultingArray.push([
        new Date(d.ts),
        fixValues(pve),
        fixValues(load),
        fixValues(grid),
        fixValues(selfConumptionsPve),
        fixValues(soldToGrid)
      ])
      resultingArrayPerPhase.push([
        new Date(d.ts),
        fixValues(lc1),
        fixValues(lc2),
        fixValues(lc3),
        fixValues(ec1v),
        fixValues(ec2v),
        fixValues(ec3v)
      ])
    }
  }

  // If no pve production set to zero
  if (totPVE <= 0) {
    resultingArray = resultingArray.map(d => {
      d[1] = 0
      d[4] = 0
      return d
    })
  }
  return {
    totalLoadEnergy: fixValues(totalLoadEnergy),
    totalGridEnergy: fixValues(totalGridEnergy),
    totalSolarSelfConsumptionEnergy: fixValues(totalSolarSelfConsumptionEnergy),
    totalSoldToGridEnergy: fixValues(totalSoldToGridEnergy),
    hasPve: totPVE <= 0,
    totalEnergyData: resultingArray,
    perPhaseEnergyData: resultingArrayPerPhase
  }
}

export interface ProcessedEnergyDataEchartDataResult {
  all: [Date, number, number, number, number, number][]
  allHeader: string[]
}

export interface ProcessedEchartEnergyData {
  totalLoadEnergy: number
  totalGridEnergy: number
  totalSolarSelfConsumptionEnergy: number
  totalSoldToGridEnergy: number
  hasPve: boolean
  totalEnergyData: ProcessedEnergyDataEchartDataResult
}

export const ECHARTS_ENERGY_DATASET_DIMENSIONS = ['ts', 'pve', 'load', 'grid', 'soldToGrid', 'selfCons']

export function processEchartsEnergyData(
  data: PartialEnergyCounters[],
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isEnergybucket: boolean,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  timezone: string
): ProcessedEchartEnergyData {
  const result: ProcessedEnergyDataEchartDataResult = {
    all: [],
    allHeader: ECHARTS_ENERGY_DATASET_DIMENSIONS
  }
  let ec1 = 0
  let ec2 = 0
  let ec3 = 0
  // let dOffset = 0;
  // if (isEnergybucket) {
  //     dOffset = -moment.tz(timezone).utcOffset() * 60000;
  // }
  let totPVE = 0
  let totalLoadEnergy = 0
  let totalGridEnergy = 0
  let totalSolarSelfConsumptionEnergy = 0
  let totalSoldToGridEnergy = 0
  for (let i = 0; i < data.length; i++) {
    const d = data[i]
    if (d && d.ts) {
      const pvev = isNaN(d.pve as number) ? 0 : (d.pve as number)
      const lc1 = isNaN(d.lc1 as number) ? 0 : (d.lc1 as number)
      const lc2 = isNaN(d.lc2 as number) ? 0 : (d.lc2 as number)
      const lc3 = isNaN(d.lc3 as number) ? 0 : (d.lc3 as number)
      const ec1v = isNaN(d.ec1 as number) ? 0 : (d.ec1 as number)
      const ec2v = isNaN(d.ec2 as number) ? 0 : (d.ec2 as number)
      const ec3v = isNaN(d.ec3 as number) ? 0 : (d.ec3 as number)
      const ep1 = isNaN(d.ep1 as number) ? 0 : (d.ep1 as number)
      const ep2 = isNaN(d.ep2 as number) ? 0 : (d.ep2 as number)
      const ep3 = isNaN(d.ep3 as number) ? 0 : (d.ep3 as number)

      ec1 = ec1v + ec1
      ec2 = ec2v + ec2
      ec3 = ec3v + ec3

      const pve = pvev
      totPVE = pve + totPVE
      const load = lc1 + lc2 + lc3
      const grid = ec1v + ec2v + ec3v
      const selfConumptionsPve = pvev - (ep1 + ep2 + ep3)
      const soldToGrid = ep1 + ep2 + ep3

      totalGridEnergy += grid
      totalLoadEnergy += load
      totalSoldToGridEnergy += soldToGrid
      totalSolarSelfConsumptionEnergy += selfConumptionsPve

      // ADDED DUE ENERGYBUCKET ADDS TIMEZONE OFFSET TO TIME.
      // MUST CONVERT BACK TO REAL TIMEOBJECT
      //   if (isEnergybucket) d.ts = new Date(new Date(d.ts).getTime() + dOffset);
      result.all.push([
        d.ts as Date,
        fixValues(pve),
        fixValues(load),
        fixValues(grid),
        fixValues(soldToGrid),
        fixValues(selfConumptionsPve)
      ])
    }
  }

  return {
    totalLoadEnergy: fixValues(totalLoadEnergy),
    totalGridEnergy: fixValues(totalGridEnergy),
    totalSolarSelfConsumptionEnergy: fixValues(totalSolarSelfConsumptionEnergy),
    totalSoldToGridEnergy: fixValues(totalSoldToGridEnergy),
    hasPve: totPVE >= 0,
    totalEnergyData: result
  }
}
