import { runQuery } from './graphql'
import { FacilityFeatures } from '@app/graphql/generated'

export const FeatureServiceName = 'FeatureService'
export class FeatureService {
  async getFeatures(facilityId: number): Promise<FacilityFeatures[]> {
    const res = await runQuery.getFacilityFeaturesQuery({
      variables: {
        facilityId
      },
      fetchPolicy: 'no-cache'
    })
    return res.data.facility?.features
  }
}
