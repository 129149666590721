import { ApolloClient } from '@apollo/client'
import { getSdk as opsGetSdk, getWatchSdk as opsGetWatchSdk } from './queries'
import { getSdk as streamsGetSdk } from './streams'
import { getSdk as subGetSdk } from './subscriptions'

// Export types and definitions
export * from './queries'
export {
  type BatteryModuleData,
  type EvseMeterValue,
  type PowerDataSubscription,
  type SsoData,
  type UiDataSubscription
} from './streams'
export { type SystemControlTopologyChange } from './subscriptions'

export const getAllSdk = (client: ApolloClient<unknown>) => {
  return {
    ...opsGetSdk(client),
    ...streamsGetSdk(client),
    ...subGetSdk(client)
  }
}

export const getAllWatchSdk = (client: ApolloClient<unknown>) => {
  return opsGetWatchSdk(client)
}
