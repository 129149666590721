import { Environment } from '@environments/environment'
import { ILocationService } from 'angular'
import EventEmitter from 'eventemitter3'
import { io, Socket } from 'socket.io-client'
import { getToken } from '../auth/keycloak'
export const SocketIoServiceName = 'socketIoService'
/**
 * We cannot use this service as an angularjs service, we want to reuse this class.
 */

export class SocketioService extends EventEmitter {
  private readonly protocol: string
  private readonly host: string
  private topic = ''
  private socket: Socket

  constructor($location: ILocationService) {
    super()
    this.protocol = $location.protocol()
    const port = $location.port()

    this.host = Environment.apiBase
    if (port !== 443) {
      this.host = `${this.host}:${port}`
    }
  }

  disconnect(): void {
    if (this.socket) {
      ;(this.socket as any).close()
      delete this.socket
      this.socket = null
    }
  }

  // eslint-disable-next-line
  async connect(wsNamespace: string, wsPath: string, query?: any): Promise<void> {
    this.topic = `/${wsNamespace}`

    if (this.socket) {
      // If socket exists we want to close the existing one and then create a new one.
      this.socket.close()
      delete this.socket
      this.socket = null
    }
    this.socket = io(this.host + this.topic, {
      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      reconnectionAttempts: Infinity,
      secure: true,
      path: wsPath,
      query: query,
      forceNew: true,
      auth: { token: getToken() },
      withCredentials: true
    })
    this.socket.on('connect', () => {
      this.emit('connect')
    })

    this.socket.on('disconnect', () => {
      this.emit('disconnect')
    })
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  send(event: string, ...args: unknown[]): void {
    this.socket.emit(event, args)
  }

  listen<ExpectedCallback = unknown[]>(event: string, callback: (result: ExpectedCallback) => void): void {
    this.socket.on(event, (args: ExpectedCallback) => callback(args))
  }
}
