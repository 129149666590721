// ###########################################################################
// THIS IS AUTOGENERATED BY GRUNTFILE, DO NOT CHANGE DIRECTLY IN THIS FILE!!!
//  Edit by go to "tools/theming/theme-colors.js"
//  Then run `pnpm run theme`
// ###########################################################################

// ###########################################################################
// THIS IS AUTOGENERATED, DO NOT CHANGE DIRECTLY IN THIS FILE!!!
//  Edit by go to "tools/theming/index.js"
// Then run `pnpm run theme`
// ###########################################################################
import { ColorObject, ThemeColorsInterface } from './color.service'
export const ThemeColors: ThemeColorsInterface = {
  primary: {
    '50': '#ffffff',
    '100': '#d6ebc8',
    '200': '#b7dd9f',
    '300': '#90ca6a',
    '400': '#7fc254',
    '500': '#6fb641',
    '600': '#619f39',
    '700': '#538931',
    '800': '#467229',
    '900': '#385c21',
    A100: '#fafef8',
    A200: '#bff39d',
    A400: '#87d753',
    A700: '#7bc64a',
    base: '#6fb641'
  },
  accent: {
    '50': '#8bbbe9',
    '100': '#4b95dd',
    '200': '#2679ca',
    '300': '#1b558d',
    '400': '#164574',
    '500': '#11365a',
    '600': '#0c2740',
    '700': '#071727',
    '800': '#02080d',
    '900': '#000000',
    A100: '#6cb6fe',
    A200: '#0784fd',
    A400: '#0d5091',
    A700: '#124373',
    base: '#11365a'
  },
  warn: {
    '50': '#fef2f2',
    '100': '#faaaad',
    '200': '#f6757a',
    '300': '#f23239',
    '400': '#f0161d',
    '500': '#d90e15',
    '600': '#bc0c12',
    '700': '#9f0a0f',
    '800': '#83080d',
    '900': '#66070a',
    A100: '#ffe7e8',
    A200: '#ff8185',
    A400: '#ff1b23',
    A700: '#f60b13',
    base: '#d90e15'
  },
  background: {
    '50': '#ffffff',
    '100': '#e0e0e0',
    '200': '#c4c4c4',
    '300': '#a1a1a1',
    '400': '#919191',
    '500': '#828282',
    '600': '#737373',
    '700': '#636363',
    '800': '#545454',
    '900': '#454545',
    A100: '#ffffff',
    A200: '#ddc0c0',
    A400: '#aa8d8d',
    A700: '#948989',
    base: 'rgb(130,130,130);'
  }
}
export const GraphColors: ColorObject = {
  default: {
    blue: {
      bar: 'rgb(57, 106, 177)',
      line: 'rgb(114, 147, 203)'
    },
    orange1: {
      bar: 'rgb(218, 124, 48)',
      line: 'rgb(225, 151, 78)'
    },
    orange2: {
      bar: 'rgb(232, 178, 11)',
      line: 'rgb(255, 190, 17)'
    },
    green: {
      bar: 'rgb(132, 186, 91)',
      line: 'rgb(62, 150, 81)'
    },
    red: {
      bar: 'rgb(211, 84, 86)',
      line: 'rgb(204, 37, 41)'
    },
    gray: {
      bar: 'rgb(126, 133, 133)',
      line: 'rgb(83, 81, 84)'
    },
    purple: {
      bar: 'rgb(144, 103, 167)',
      line: 'rgb(107, 76, 154)'
    },
    brown: {
      bar: 'rgb(171, 104, 87)',
      line: 'rgb(146, 36, 40)'
    },
    yellow: {
      bar: 'rgb(204, 194, 16)',
      line: 'rgb(240, 228, 66)'
    }
  },
  colorblind: {
    blue: {
      bar: 'rgb(0, 114, 178)',
      line: 'rgb(0, 114, 178)'
    },
    orange1: {
      bar: 'rgb(230, 159, 0)',
      line: 'rgb(230, 159, 0)'
    },
    orange2: {
      bar: 'rgb(204, 121, 167)',
      line: 'rgb(204, 121, 167)'
    },
    green: {
      bar: 'rgb(0, 158, 115)',
      line: 'rgb(0, 158, 115)'
    },
    red: {
      bar: 'rgb(213, 94, 0)',
      line: 'rgb(213, 94, 0)'
    },
    gray: {
      bar: 'rgb(126, 133, 133)',
      line: 'rgb(83, 81, 84)'
    },
    purple: {
      bar: 'rgb(86, 180, 233)',
      line: 'rgb(86, 180, 233)'
    },
    yellow: {
      bar: 'rgb(240, 228, 66)',
      line: 'rgb(240, 228, 66)'
    },
    brown: {
      bar: 'rgb(171, 104, 87)',
      line: 'rgb(146, 36, 40)'
    }
  },
  dcGrid: '#9FB649',
  acGrid: '#A35773'
}
export const THEMES = ['default', 'colorblind', 'dcGrid', 'acGrid']
