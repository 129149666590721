import { captureSentryException } from '@app/config/sentry'
import { DEFAULT_TIMEZONE } from '@environments/environment'

export const getBrowserTimeZone = () => {
  try {
    return Intl.DateTimeFormat().resolvedOptions().timeZone || DEFAULT_TIMEZONE
  } catch (err) {
    captureSentryException(err)
    return DEFAULT_TIMEZONE
  }
}
