import { material } from 'angular'
import LeftSidenavTemplateUrl from './left-sidenav.component.html'

import './left-sidenav.component.scss'

export const LeftSideNavName = 'left-sidenav-list'

export interface LeftSidenavSubsections {
  uiref: string
  name: string
  pro: boolean
}

export type LeftSideNavOptions = {
  uiref?: string
  dis?: boolean
  ref?: boolean
  name: string
  pro?: boolean
  icon?: string
  ishref?: boolean
  click?: boolean
  hide?: boolean
  subsections?: LeftSidenavSubsections[]
}

let id = 0

export { LeftSidenavTemplateUrl }

export class LeftSidenavTemplate {
  sidenav: material.ISidenavObject
  isHidden = false
  sections: LeftSideNavOptions[]
  title: string
  $md: material.ISidenavService
  id: number

  COMPONENT_ID: string

  constructor($mdSidenav: material.ISidenavService) {
    this.$md = $mdSidenav
    id++
    this.id = id
    this.COMPONENT_ID = `${LeftSideNavName}-${this.id}`
  }

  $onInit(): void {
    this.$md(this.COMPONENT_ID, true).then(instance => {
      this.sidenav = instance
    })
  }

  close() {
    if (this.sidenav) this.sidenav.close()
  }

  async toggle() {
    if (this.sidenav) {
      this.sidenav.toggle()
      // this.isHidden = !this.sidenav.isOpen()
      // if (this.isHidden) {
      //   this.sidenav.open()
      // } else {
      //   this.sidenav.close()
      // }
      // if (this.isHidden)
      //   document.getElementById('left-sidenav-toggle-btn').style.backgroundColor = 'rgb(255,0,0)'
      // else document.getElementById('left-sidenav-toggle-btn').style.backgroundColor = 'rgb(0,0,0)'
    }
  }
}
