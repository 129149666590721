import { IComponentOptions, IScope, ITimeoutService } from 'angular'

import {
  ElectricalComponentColors,
  FeatureFlagService,
  FeatureFlagServiceName,
  FerroConfiguration,
  LivedataService
} from '../../../service'
import { clearEchartsInstance, echartsOnResize, getId, loadCurrent, roundTo2Decimals } from '../../../../lib'
import { LanguageViews } from '../../../language/language.interface'

import './livedata-view.component.scss'
import { PortalLivedataParsedResultInterface } from '../../../../types'
import { genPreArray, setupLivedataCharts, updateLiveData } from './livedata-view.tools'
import echarts, { EChartOption, ECharts } from 'echarts'
import templateUrl from './livedata-view.component.html'
import { runQuery } from '@app/graphql/graphql'
import { ObservableSubscription } from '@apollo/client'
import { translateUiData } from '@app/system/dashboard/live-data-utils'

export type LiveDataGraphData = { data: (number | Date)[][] }[]

const dcBusMinMax = {
  max: 400,
  min: 340
}

class LivedataViewController {
  graphIsReady = false
  playingButton: 'Start' | 'Pause' = 'Pause'

  playing = true
  lengthOfTimeArray = 180

  TIME4LABEL: string[]
  POWERLABEL: string[]
  DCBUSLABEL: string[]

  graphGrid: ECharts
  graphLoad: ECharts
  graphEhub: ECharts
  graphPower: ECharts
  graphDcbus: ECharts
  graphVolt: ECharts
  chartTitle: string

  datasetLoad: LiveDataGraphData
  datasetGrid: LiveDataGraphData
  datasetEhub: LiveDataGraphData
  datasetPower: LiveDataGraphData
  datasetVolt: LiveDataGraphData
  datasetDcbus: LiveDataGraphData

  toACRMS = (value: number, precision = 2) => {
    return _.round(value / Math.SQRT2, precision)
  }

  sw = {
    grid: false,
    load: false,
    ehub: false,
    power: true,
    dcbus: false,
    volt: false
  }

  l: LanguageViews

  $timeout: ITimeoutService
  ferroConfiguration: FerroConfiguration
  service: LivedataService

  colors: ElectricalComponentColors

  private readonly featureFlagService: FeatureFlagService
  private uiDataSubscription: ObservableSubscription
  private powerDataSubscription: ObservableSubscription
  facilityId: number

  constructor(
    $scope: IScope,
    $timeout: ITimeoutService,
    liveDataService: LivedataService,
    ferroConfiguration: FerroConfiguration,
    featureFlagService: FeatureFlagService
  ) {
    this.l = ferroConfiguration.language.Views
    liveDataService.setUp(Number(ferroConfiguration.facility.id))
    this.$timeout = $timeout
    this.service = liveDataService
    this.featureFlagService = featureFlagService

    this.facilityId = Number(ferroConfiguration.facility.id)

    this.colors = ferroConfiguration.electricColors
    this.datasetGrid = genPreArray(4)
    this.datasetLoad = genPreArray(4)
    this.datasetEhub = genPreArray(4)
    this.datasetPower = genPreArray(5)
    this.datasetDcbus = genPreArray(3)
    this.datasetVolt = genPreArray(4)
  }

  $onInit(): void {
    const powerdata = runQuery.powerDataLiveDataViewSubscription({
      variables: {
        facilityId: this.facilityId.toString()
      }
    })

    this.powerDataSubscription = powerdata.subscribe(({ data }) => {
      if (!this.graphIsReady) return
      this.datasetPower = updateLiveData(data.newPowerData.timestamp, this.datasetPower, [
        roundTo2Decimals(data.newPowerData.gridPower),
        roundTo2Decimals(data.newPowerData.loadPower),
        roundTo2Decimals(data.newPowerData.pvPower),
        roundTo2Decimals(data.newPowerData.batteryPower)
      ])

      if (this.sw.power && this.playing) {
        this.graphPower.setOption({
          series: this.datasetPower as EChartOption.Series[]
        })
      }
    })

    const uidata = runQuery.uiDataLiveDataViewSubscription({
      variables: {
        facilityId: this.facilityId.toString()
      }
    })

    this.uiDataSubscription = uidata.subscribe(({ data }) => {
      if (!this.graphIsReady) return

      const dc = {
        dcPos: data.newUiData.uDCpos,
        dcNeg: data.newUiData.uDCneg
      }
      if (dc) {
        this.datasetDcbus = updateLiveData(data.newUiData.timestamp, this.datasetDcbus, [
          Math.abs(roundTo2Decimals(dc.dcPos)),
          Math.abs(roundTo2Decimals(dc.dcNeg))
        ])
        if (dc.dcPos > dcBusMinMax.max) dcBusMinMax.max = dc.dcPos + 10
        if (dc.dcPos < dcBusMinMax.min) dcBusMinMax.min = dc.dcPos - 10
        if (dc.dcNeg > dcBusMinMax.max) dcBusMinMax.max = dc.dcNeg + 10
        if (dc.dcNeg < dcBusMinMax.min) dcBusMinMax.min = dc.dcNeg - 10
      }
      const ui = translateUiData(data.newUiData)

      if (ui) {
        const l1 = loadCurrent(
          data.newUiData.iExtQ1,
          data.newUiData.iInvQ1,
          data.newUiData.iExtD1,
          data.newUiData.iInvD1
        )
        const l2 = loadCurrent(
          data.newUiData.iExtQ2,
          data.newUiData.iInvQ2,
          data.newUiData.iExtD2,
          data.newUiData.iInvD2
        )
        const l3 = loadCurrent(
          data.newUiData.iExtQ3,
          data.newUiData.iInvQ3,
          data.newUiData.iExtD3,
          data.newUiData.iInvD3
        )

        this.datasetLoad = updateLiveData(data.newUiData.timestamp, this.datasetLoad, [
          roundTo2Decimals(l1),
          roundTo2Decimals(l2),
          roundTo2Decimals(l3)
        ])
        this.datasetGrid = updateLiveData(data.newUiData.timestamp, this.datasetGrid, [
          roundTo2Decimals(ui.er1),
          roundTo2Decimals(ui.er2),
          roundTo2Decimals(ui.er3)
        ])
        this.datasetEhub = updateLiveData(data.newUiData.timestamp, this.datasetEhub, [
          roundTo2Decimals(ui.iq1),
          roundTo2Decimals(ui.iq2),
          roundTo2Decimals(ui.iq3)
        ])
        this.datasetVolt = updateLiveData(data.newUiData.timestamp, this.datasetVolt, [
          roundTo2Decimals(ui.u1),
          roundTo2Decimals(ui.u2),
          roundTo2Decimals(ui.u3)
        ])
      }
      if (!this.playing) return

      if (this.sw.grid)
        this.graphGrid.setOption({
          series: this.datasetGrid as EChartOption.Series[]
        })
      if (this.sw.load)
        this.graphLoad.setOption({
          series: this.datasetLoad as EChartOption.Series[]
        })
      if (this.sw.ehub)
        this.graphEhub.setOption({
          series: this.datasetEhub as EChartOption.Series[]
        })
      if (this.sw.dcbus)
        this.graphDcbus.setOption({
          series: this.datasetDcbus as EChartOption.Series[],
          yAxis: { max: dcBusMinMax.max, min: dcBusMinMax.min }
        })
      if (this.sw.volt)
        this.graphVolt.setOption({
          series: this.datasetVolt as EChartOption.Series[]
        })
    })

    this.$timeout(() => {
      const ops = setupLivedataCharts(this.l, this.colors, dcBusMinMax)
      this.graphGrid = echarts.init(getId('gridGraph') as HTMLDivElement)
      this.graphLoad = echarts.init(getId('loadGraph') as HTMLDivElement)
      this.graphEhub = echarts.init(getId('ehubGraph') as HTMLDivElement)
      this.graphPower = echarts.init(getId('powerGraph') as HTMLDivElement)
      this.graphDcbus = echarts.init(getId('dcbusGraph') as HTMLDivElement)
      this.graphVolt = echarts.init(getId('voltGraph') as HTMLDivElement)

      echartsOnResize(this.graphGrid)
      echartsOnResize(this.graphLoad)
      echartsOnResize(this.graphEhub)
      echartsOnResize(this.graphPower)
      echartsOnResize(this.graphDcbus)
      echartsOnResize(this.graphVolt)

      this.graphPower.setOption(ops.power)
      this.graphGrid.setOption(ops.gridCurrent)
      this.graphEhub.setOption(ops.ehubCurrent)
      this.graphLoad.setOption(ops.loadCurrent)
      this.graphVolt.setOption(ops.gridVoltage)
      this.graphDcbus.setOption(ops.dc)
      this.$timeout(() => {
        this.graphIsReady = true
      }, 400)
    }, 1000)
  }

  $onDestroy(): void {
    this.service.stop()
    if (this.graphGrid) clearEchartsInstance(this.graphGrid)
    if (this.graphLoad) clearEchartsInstance(this.graphLoad)
    if (this.graphEhub) clearEchartsInstance(this.graphEhub)
    if (this.graphPower) clearEchartsInstance(this.graphPower)
    if (this.graphDcbus) clearEchartsInstance(this.graphDcbus)
    if (this.graphVolt) clearEchartsInstance(this.graphVolt)
    this.graphIsReady = false
  }

  startStop(): void {
    this.playing = !this.playing
    if (this.playing) this.playingButton = 'Pause'
    else this.playingButton = 'Start'
  }

  dataLoader(data: PortalLivedataParsedResultInterface): void {
    if (!this.graphIsReady) return
    const ts = data.ts
    const ui = data.uiPowerData
    const dc = data.dcData

    if (dc) {
      this.datasetDcbus = updateLiveData(ts, this.datasetDcbus, [
        roundTo2Decimals(dc.dcPos),
        roundTo2Decimals(dc.dcNeg)
      ])
      if (dc.dcPos > dcBusMinMax.max) dcBusMinMax.max = dc.dcPos + 10
      if (dc.dcPos < dcBusMinMax.min) dcBusMinMax.min = dc.dcPos - 10
      if (dc.dcNeg > dcBusMinMax.max) dcBusMinMax.max = dc.dcNeg + 10
      if (dc.dcNeg < dcBusMinMax.min) dcBusMinMax.min = dc.dcNeg - 10
    }

    if (ui) {
      this.datasetLoad = updateLiveData(ts, this.datasetLoad, [
        roundTo2Decimals(ui.l1),
        roundTo2Decimals(ui.l2),
        roundTo2Decimals(ui.l3)
      ])
      this.datasetGrid = updateLiveData(ts, this.datasetGrid, [
        roundTo2Decimals(ui.er1),
        roundTo2Decimals(ui.er2),
        roundTo2Decimals(ui.er3)
      ])
      this.datasetEhub = updateLiveData(ts, this.datasetEhub, [
        roundTo2Decimals(ui.iq1),
        roundTo2Decimals(ui.iq2),
        roundTo2Decimals(ui.iq3)
      ])
      this.datasetVolt = updateLiveData(ts, this.datasetVolt, [
        roundTo2Decimals(ui.u1),
        roundTo2Decimals(ui.u2),
        roundTo2Decimals(ui.u3)
      ])
      this.datasetPower = updateLiveData(ts, this.datasetPower, [
        roundTo2Decimals(ui.gridPower),
        roundTo2Decimals(ui.loadPower),
        roundTo2Decimals(ui.pvPower),
        roundTo2Decimals(ui.batPower)
      ])
    }
    if (this.playing) {
      if (this.sw.power)
        this.graphPower.setOption({
          series: this.datasetPower as EChartOption.Series[]
        })
      if (this.sw.grid)
        this.graphGrid.setOption({
          series: this.datasetGrid as EChartOption.Series[]
        })
      if (this.sw.load)
        this.graphLoad.setOption({
          series: this.datasetLoad as EChartOption.Series[]
        })
      if (this.sw.ehub)
        this.graphEhub.setOption({
          series: this.datasetEhub as EChartOption.Series[]
        })
      if (this.sw.dcbus)
        this.graphDcbus.setOption({
          series: this.datasetDcbus as EChartOption.Series[],
          yAxis: { max: dcBusMinMax.max, min: dcBusMinMax.min }
        })
      if (this.sw.volt)
        this.graphVolt.setOption({
          series: this.datasetVolt as EChartOption.Series[]
        })
    }
  }

  switchChange(): void {
    this.$timeout(() => {
      if (this.graphIsReady) {
        this.graphGrid.resize()
        this.graphDcbus.resize()
        this.graphEhub.resize()
        this.graphLoad.resize()
        this.graphPower.resize()
        this.graphVolt.resize()
      }
    }, 1000)
  }
}

LivedataViewController.$inject = [
  '$scope',
  '$timeout',
  'liveDataService',
  'ferroConfiguration',
  FeatureFlagServiceName
]

export const LiveDataViewComponentName = 'liveDataViewComponent'

export const LiveDataViewComponent: IComponentOptions = {
  controller: LivedataViewController,
  templateUrl: templateUrl
}
