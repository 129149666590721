import { BatteryModuleData, PowerDataSubscription, SsoData, UiDataSubscription } from '@app/graphql/generated'

import { ObservableSubscription } from '@apollo/client'
import { runQuery } from '@app/graphql/graphql'
import { SQRT2 } from '@lib/general'
import throttle from 'lodash/throttle'
import {
  PortalLiveDataEsoModulesDataInterface,
  PortalLivedataParsedResultInterface,
  PortalLiveDataSsoModulesDataInterface
} from '../../../types'

export const translateUiData = (uiData: UiDataSubscription) => {
  return {
    eq1: uiData.iExtQ1 / SQRT2,
    eq2: uiData.iExtQ2 / SQRT2,
    eq3: uiData.iExtQ3 / SQRT2,
    eqn: uiData.iExtQN,
    iq1: uiData.iInvQ1 / SQRT2,
    iq2: uiData.iInvQ2 / SQRT2,
    iq3: uiData.iInvQ3 / SQRT2,
    iqn: uiData.iInvQN,
    lq1: uiData.iLoadQ1 / SQRT2,
    lq2: uiData.iLoadQ2 / SQRT2,
    lq3: uiData.iLoadQ3 / SQRT2,
    lqn: uiData.iLoadQN / SQRT2,
    u1: uiData.u1,
    u2: uiData.u2,
    u3: uiData.u3,
    er1: uiData.iExtRms1,
    er2: uiData.iExtRms2,
    er3: uiData.iExtRms3,
    ern: uiData.iExtRmsN
  }
}

export const translateToOldUiData = (
  uiData: UiDataSubscription | null,
  powerData: PowerDataSubscription | null
) => {
  if (!uiData || !powerData) return null

  return {
    ...translateUiData(uiData),
    batPower: powerData.batteryPower,
    ehubPower: powerData.ehubPower,
    gridPower: powerData.gridPower,
    gridSign: powerData.gridSign,
    loadPower: powerData.loadPower,
    pvPower: powerData.pvPower
  }
}

export const translateToOldSsoData = (
  ssoData: SsoData[] | null
): PortalLiveDataSsoModulesDataInterface | null => {
  if (!ssoData) return null

  return {
    data: ssoData.map(sso => ({
      id: sso.ssoId as string,
      power: sso.power / 1000,
      energy: sso.energy
    }))
  }
}

export const translateToOldEsoData = (
  esoData: BatteryModuleData[] | null
): PortalLiveDataEsoModulesDataInterface | null => {
  if (!esoData) return null

  return {
    data: esoData.map(eso => ({
      energyProd: 0,
      energyCons: 0,
      id: eso.esoId,
      power: eso.power,
      soc: eso.soc
    }))
  }
}

type LiveDataOptions = {
  facilityIds: number[]
}

export const createLiveDataSubscriber = ({ facilityIds }: LiveDataOptions) => {
  // GraphQL subscriptions
  let subscriptions: ObservableSubscription[] = []

  // Cached data
  const cache: {
    [key: number]: {
      cachedUiData: UiDataSubscription | null
      cachedPowerData: PowerDataSubscription | null
      cachedSsoData: SsoData[] | null
      cachedBatteryModuleData: BatteryModuleData[] | null
    }
  } = {}

  const subscribe = (onData: (data: PortalLivedataParsedResultInterface) => void) => {
    facilityIds.forEach(facilityId => {
      cache[facilityId] = {
        cachedUiData: null,
        cachedPowerData: null,
        cachedSsoData: null,
        cachedBatteryModuleData: null
      }

      // Batch updates together
      const updateLiveData = throttle(() => {
        if (subscriptions.length > 0) {
          cache[facilityId] = {
            facilityId,
            uiPowerData: translateToOldUiData(
              cache[facilityId].cachedUiData,
              cache[facilityId].cachedPowerData
            ),
            ssoModulesData: translateToOldSsoData(cache[facilityId].cachedSsoData),
            esoModulesData: translateToOldEsoData(cache[facilityId].cachedBatteryModuleData)
          } as any

          onData(cache[facilityId] as any)
        }
      }, 2000)

      // UI data
      const uiData = runQuery.onUiDataSubscription({
        variables: {
          facilityId: facilityId.toString()
        }
      })

      subscriptions.push(
        uiData.subscribe(event => {
          cache[facilityId].cachedUiData = event.data.newUiData as any
          updateLiveData()
        })
      )

      // Power data
      const powerData = runQuery.onPowerDataSubscription({
        variables: {
          facilityId: facilityId.toString()
        }
      })
      subscriptions.push(
        powerData.subscribe(event => {
          cache[facilityId].cachedPowerData = event.data.newPowerData as any
          updateLiveData()
        })
      )

      // SSO data
      const ssoData = runQuery.onSsoDataSubscription({
        variables: {
          facilityId: facilityId.toString()
        }
      })

      subscriptions.push(
        ssoData.subscribe(event => {
          cache[facilityId].cachedSsoData = event.data.newSsoData as any
        })
      )

      // Battery data
      const batteryData = runQuery.onBatteryModuleDataSubscription({
        variables: {
          facilityId: facilityId.toString()
        }
      })

      subscriptions.push(
        batteryData.subscribe(event => {
          cache[facilityId].cachedBatteryModuleData = event.data.newBatteryModuleData as any
        })
      )
    })
  }

  const unsubscribe = () => {
    // GraphQL unsubscribes
    subscriptions.forEach(subscription => {
      subscription.unsubscribe()
    })
    subscriptions = []
  }

  return { subscribe, unsubscribe }
}

export type LiveDataSubscriber = ReturnType<typeof createLiveDataSubscriber>
