import { GraphqlEnergyCounterService, GraphqlEnergyCounterServiceName } from '@app/graphql'
import { EnergyTimePeriod, PartialEnergyCounters } from '@app/graphql/graphql-energycounters.service'
import { IHttpResponse, IPromise } from 'angular'
import { DEFAULT_TIMEZONE } from '../../../../environments/environment'
import { FeatureFlagService, FeatureFlagServiceName } from '../../../service'
import { serviceClb } from '../../../service/types.service'
import { AnalysisHttpService, AnalysisHttpServiceName } from '../analysis-http.service'
import { _dailyTrendDataFix, DailyTrendFixedData } from './daily-trend.tools'

export type dailyTrendDataVizualizationType = 'std' | 'maxmin'

export const DailyTrendServiceName = 'dailyTrendService'

export class DailyTrendService {
  timezone = DEFAULT_TIMEZONE
  id: string
  facilityId: number
  canceller: IPromise<unknown>
  test = false
  type: dailyTrendDataVizualizationType

  aService: AnalysisHttpService
  energyCounterSerice: GraphqlEnergyCounterService
  featureFlagService: FeatureFlagService

  data: PartialEnergyCounters[]

  constructor(
    analysisHttpService: AnalysisHttpService,
    energyCounterService: GraphqlEnergyCounterService,
    featureFlagService: FeatureFlagService
  ) {
    this.aService = analysisHttpService
    this.energyCounterSerice = energyCounterService
    this.featureFlagService = featureFlagService
  }

  init(
    canceller: IPromise<unknown>,
    facilityId: number,
    timezone: string,
    type: dailyTrendDataVizualizationType
  ): void {
    this.canceller = canceller
    this.facilityId = facilityId
    this.timezone = timezone || DEFAULT_TIMEZONE
    this.type = type
  }

  stdOrMaxMin(type: dailyTrendDataVizualizationType, callback: (result: DailyTrendFixedData) => void): void {
    this.type = type
    callback(_dailyTrendDataFix(this.data, this.type, this.timezone))
  }

  getEnergyData(
    starttine: Date,
    endtime: Date,
    clb: serviceClb<IHttpResponse<string>, DailyTrendFixedData>
  ): void {
    this.data = []
    this.energyCounterSerice
      .getEnergyCounters(this.facilityId, EnergyTimePeriod.Hourly, starttine, endtime)
      .then(res => {
        this.data = res.map((ec: PartialEnergyCounters) => ({
          ...ec,
          timeDiff: 3600000
        }))

        const dailyTrendDataFix = _dailyTrendDataFix(this.data, this.type, this.timezone)

        clb(null, dailyTrendDataFix)
      })
      .catch(clb)
  }
}

DailyTrendService.$inject = [AnalysisHttpServiceName, GraphqlEnergyCounterServiceName, FeatureFlagServiceName]
