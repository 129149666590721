import moment from 'moment-timezone'
import { SimulatedPowerDataSet } from './battery-simulation.component'
import { maxDiff } from './constants'

export function extractGridData(parsedData: SimulatedPowerDataSet): [Date, number, number, number][] {
  return parsedData.map((pd: any) => [pd[0] as Date, pd[1] as number, pd[7] as number, pd[6] as number])
}

export function extractRestData(
  parsedData: SimulatedPowerDataSet
): [Date, number, number, number, number, number][] {
  return parsedData.map((pd: any) => [
    pd[0] as Date,
    pd[2] as number,
    pd[3] as number,
    pd[4] as number,
    pd[5] as number,
    pd[6] as number
  ])
}

export type TimePeriodOptions = {
  dateChanged: 'start' | 'end'
  startDate: Date
  endDate: Date
}

type TimePeriod = {
  start: Date
  end: Date
}

export const getNewTimePeriod = ({ dateChanged, startDate, endDate }: TimePeriodOptions): TimePeriod => {
  const startMoment = moment(startDate).startOf('day')
  const endMoment = moment(endDate).endOf('day')
  const diff = endMoment.diff(startMoment)

  if (diff < 0 || diff > maxDiff) {
    const today = moment().endOf('day')

    if (dateChanged === 'start') {
      const updatedEnd = startMoment.clone().add(6, 'days').endOf('day')
      return {
        start: startMoment.toDate(),
        end: updatedEnd.isAfter(today) ? today.toDate() : updatedEnd.toDate()
      }
    } else {
      return {
        start: endMoment.clone().subtract(6, 'days').startOf('day').toDate(),
        end: endMoment.toDate()
      }
    }
  }

  // No need to modify date
  return { start: startDate, end: endDate }
}

const dateOnlyFormat = 'YYYY-MM-DD'
export const adjustTimePeriodWithTimezone = (
  startDate: Date,
  endDate: Date,
  timezone: string
): TimePeriod => {
  return {
    start: moment.tz(moment(startDate).format(dateOnlyFormat), timezone).toDate(),
    end: moment.tz(moment(endDate).format(dateOnlyFormat), timezone).endOf('day').toDate()
  }
}
