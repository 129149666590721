import { FerroConfiguration, FerroConfigurationName } from '../service'
import { runQuery } from './graphql'

export const GraphqlPowerDataRetentionsServiceName = 'graphqlPowerDataRetentionsService'

export class GraphqlPowerDataRetentionsService {
  private uiData1SecondHistory: Date
  private powerData1SecondHistory: Date
  private powerData1MinuteHistory: Date
  private powerData1HourHistory: Date

  fc: FerroConfiguration

  constructor(ferroConfiguration: FerroConfiguration) {
    this.fc = ferroConfiguration
    this.getRetentions()
  }

  async getRetentions(): Promise<void> {
    const data = await runQuery.graphqlPowerdataDataRetentionsQuery({})
    const retentions = data.data.dataRetentions

    this.uiData1SecondHistory = new Date(retentions.uiData1SecondHistory)
    this.powerData1MinuteHistory = new Date(retentions.powerData1MinuteHistory)
    this.powerData1SecondHistory = new Date(retentions.powerData1SecondHistory)
  }

  getPowerData1MinuteMinDate(): Date {
    return this.powerData1MinuteHistory &&
      this.powerData1MinuteHistory.getTime() > this.fc.getMinDate().getTime()
      ? this.powerData1MinuteHistory
      : this.fc.getMinDate()
  }

  getPowerData1SecondMinDate(): Date {
    return this.powerData1SecondHistory &&
      this.powerData1SecondHistory.getTime() > this.fc.getMinDate().getTime()
      ? this.powerData1SecondHistory
      : this.fc.getMinDate()
  }

  getUiData1SecondMinDate(): Date {
    return this.uiData1SecondHistory && this.uiData1SecondHistory.getTime() > this.fc.getMinDate().getTime()
      ? this.uiData1SecondHistory
      : this.fc.getMinDate()
  }
}

GraphqlPowerDataRetentionsService.$inject = [FerroConfigurationName]
