// ###########################################################################
// THIS IS AUTOGENERATED, DO NOT CHANGE DIRECTLY IN THIS FILE!!!
//  Edit by go to "tools/theming/index.js"
// Then run `pnpm run theme`
// ###########################################################################
import { material } from 'angular'

export function ThemePalette($mdThemingProvider: material.IThemingProvider): void {
  $mdThemingProvider.definePalette('ferro-primary', {
    '50': 'ffffff',
    '100': 'd6ebc8',
    '200': 'b7dd9f',
    '300': '90ca6a',
    '400': '7fc254',
    '500': '6fb641',
    '600': '619f39',
    '700': '538931',
    '800': '467229',
    '900': '385c21',
    A100: 'fafef8',
    A200: 'bff39d',
    A400: '87d753',
    A700: '7bc64a',
    contrastDefaultColor: 'light',
    contrastDarkColors: ['50', '100', '200', '300', '400', '500', '600', 'A100', 'A200', 'A400', 'A700'],
    contrastLightColors: ['700', '800', '900']
  })
  $mdThemingProvider.definePalette('ferro-accent', {
    '50': '8bbbe9',
    '100': '4b95dd',
    '200': '2679ca',
    '300': '1b558d',
    '400': '164574',
    '500': '11365a',
    '600': '0c2740',
    '700': '071727',
    '800': '02080d',
    '900': '000000',
    A100: '6cb6fe',
    A200: '0784fd',
    A400: '0d5091',
    A700: '124373',
    contrastDefaultColor: 'light',
    contrastDarkColors: ['50', '100', 'A100'],
    contrastLightColors: ['200', '300', '400', '500', '600', '700', '800', '900', 'A200', 'A400', 'A700']
  })
  $mdThemingProvider.definePalette('ferro-warn', {
    '50': 'fef2f2',
    '100': 'faaaad',
    '200': 'f6757a',
    '300': 'f23239',
    '400': 'f0161d',
    '500': 'd90e15',
    '600': 'bc0c12',
    '700': '9f0a0f',
    '800': '83080d',
    '900': '66070a',
    A100: 'ffe7e8',
    A200: 'ff8185',
    A400: 'ff1b23',
    A700: 'f60b13',
    contrastDefaultColor: 'light',
    contrastDarkColors: ['50', '100', '200', 'A100', 'A200'],
    contrastLightColors: ['300', '400', '500', '600', '700', '800', '900', 'A400', 'A700']
  })
  $mdThemingProvider.definePalette('ferro-background', {
    '50': 'ffffff',
    '100': 'e0e0e0',
    '200': 'c4c4c4',
    '300': 'a1a1a1',
    '400': '919191',
    '500': '828282',
    '600': '737373',
    '700': '636363',
    '800': '545454',
    '900': '454545',
    A100: 'ffffff',
    A200: 'ddc0c0',
    A400: 'aa8d8d',
    A700: '948989',
    contrastDefaultColor: 'light',
    contrastDarkColors: ['50', '100', '200', '300', '400', '500', 'A100', 'A200', 'A400', 'A700'],
    contrastLightColors: ['600', '700', '800', '900']
  })
}
