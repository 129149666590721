import { StateProvider } from '@uirouter/angularjs'
import { PowershareDashboardComponentName } from './powershare-dashboard/powershare-dashboard.component'
import { AvailableViews } from '../app.routing'

export const PowershareDashboardState = 'powershare'

export function powerShareRoutingConfig($stateProvider: StateProvider): void {
  $stateProvider.state(PowershareDashboardState, {
    url: '/powershare/dashboard?id',
    views: {
      [AvailableViews.main]: PowershareDashboardComponentName
    }
  })
}

powerShareRoutingConfig.$inject = ['$stateProvider']
