import { IHttpResponse, IHttpService } from 'angular'
import { Environment } from '../../environments/environment'

import { newFacilityCommentResponse } from '../../types'
import { serviceClb } from './types.service'

interface FacilityComments {
  facility_id: number
  user_id: number
  email: string
  title: string
  text: string
  ts: Date
}
const BaseUri = Environment.endpoints.facilityData

export const FacilityDataHttpServiceName = 'facilityDataHttpService'

export type SuccessfulFacilityCreatedResponse = {
  facilityId: number
}

export class FacilityDataHttpService {
  $http: IHttpService

  constructor($http: IHttpService) {
    this.$http = $http
  }

  getFacilityComments(
    facilityId: number,
    callback: serviceClb<IHttpResponse<string>, FacilityComments[]>
  ): void {
    this.$http<FacilityComments[]>({
      method: 'GET',
      url: `${BaseUri}/getfacilitycomments?facility_id=${facilityId}`
    }).then(
      response => {
        callback(null, response.data)
      },
      response => {
        callback(response, null)
      }
    )
  }

  newFacilityComment(
    comment: FacilityComments,
    callback: serviceClb<IHttpResponse<string>, newFacilityCommentResponse>
  ): void {
    this.$http<newFacilityCommentResponse>({
      method: 'POST',
      url: `${BaseUri}/newfacilitycomment`,
      data: comment
    }).then(
      response => {
        callback(null, response.data)
      },
      response => {
        callback(response, null)
      }
    )
  }
}

FacilityDataHttpService.$inject = ['$http']
