import { ISystemEnergy } from '@app/graphql/generated'
import { EnergyCounterAsNumbers } from 'types'
import { energy2String } from '../../../lib/general/electrical-calculations'
import { CostObject } from './system-dashboard.interfaces'

export type EnergySummeriesObject = {
  tot?: { last_e: EnergyCounterAsNumbers }
  mon?: { last_e: EnergyCounterAsNumbers }
  tod?: { last_e: EnergyCounterAsNumbers }
}

export interface SolarEnergySummaryObjects {
  prod: string
  self: string
  sold: string
  selfsaved: string
  soldinc: string
}

export interface SolarEnergySummary {
  tot?: SolarEnergySummaryObjects
  tod?: SolarEnergySummaryObjects
  mon?: SolarEnergySummaryObjects
}

export interface LoadEnergySummaryObjects {
  load: string
  grid: string
  cost: string
}

export interface LoadEnergySummary {
  tot?: LoadEnergySummaryObjects
  mon?: LoadEnergySummaryObjects
  tod?: LoadEnergySummaryObjects
}

export function parseSolarEnergySummary(
  totalEnergy: Partial<ISystemEnergy>,
  energyThisMonth: Partial<ISystemEnergy>,
  energyToday: ISystemEnergy,
  costObject: CostObject
): SolarEnergySummary {
  const solarEnergy: SolarEnergySummary = {}
  const doTodayEnergy = !!energyToday
  const doThisMonthEnergy = !!energyThisMonth

  let monPve, monSold, monSelfPve, todPve, todSold, todSelfPve
  const totPve = totalEnergy.pvProduced
  const totSold = totalEnergy.gridExportL1 + totalEnergy.gridExportL2 + totalEnergy.gridExportL3

  const totSelfPve = totalEnergy.pvProduced - totSold

  if (doThisMonthEnergy) {
    monPve = totPve - energyThisMonth.pvProduced
    monSold =
      totSold - (energyThisMonth.gridExportL1 + energyThisMonth.gridExportL2 + energyThisMonth.gridExportL3)
    monSelfPve = monPve - monSold
  } else {
    monPve = monSold = monSelfPve = null
  }
  if (doTodayEnergy) {
    todPve = totPve - energyToday.pvProduced
    todSold = totSold - (energyToday.gridExportL1 + energyToday.gridExportL2 + energyToday.gridExportL3)
    todSelfPve = todPve - todSold
  } else {
    todPve = todSelfPve = todSelfPve = null
  }

  let totalSelfSaved = Math.round((totSelfPve * costObject.costElectricityKwh) / 1000)
  if (totalSelfSaved < 0) {
    totalSelfSaved = 0
  }
  let totalSoldInc = Math.round((totSold * costObject.revenueSoldToGrid) / 1000)
  if (totalSoldInc < 0) {
    totalSoldInc = 0
  }

  solarEnergy.tot = {
    prod: energy2String(totalEnergy.pvProduced),
    self: energy2String(totSelfPve),
    sold: energy2String(totSold),
    selfsaved: `${totalSelfSaved} ${costObject.currency}`,
    soldinc: `${totalSoldInc} ${costObject.currency}`
  }
  if (doThisMonthEnergy) {
    let monthSelfSave = Math.round((monSelfPve * costObject.costElectricityKwh) / 1000)
    if (monthSelfSave < 0) {
      monthSelfSave = 0
    }
    let monthSoldInc = Math.round((monSold * costObject.revenueSoldToGrid) / 1000)
    if (monthSoldInc < 0) {
      monthSoldInc = 0
    }

    solarEnergy.mon = {
      prod: energy2String(totalEnergy.pvProduced - energyThisMonth.pvProduced),
      self: energy2String(monSelfPve),
      sold: energy2String(monSold),
      selfsaved: `${monthSelfSave} ${costObject.currency}`,
      soldinc: `${monthSoldInc} ${costObject.currency}`
    }
  }
  if (doTodayEnergy) {
    let todaySelfSaved = Math.round((todSelfPve * costObject.costElectricityKwh) / 1000)

    if (todaySelfSaved < 0) {
      todaySelfSaved = 0
    }

    const todaySoldInc = Math.round((todSold * costObject.revenueSoldToGrid) / 1000)

    if (todaySoldInc < 0) {
      todaySelfSaved = 0
    }

    solarEnergy.tod = {
      prod: energy2String(totalEnergy.pvProduced - energyToday.pvProduced),
      self: energy2String(todSelfPve),
      sold: energy2String(todSold),
      selfsaved: `${todaySelfSaved} ${costObject.currency}`,
      soldinc: `${todaySoldInc} ${costObject.currency}`
    }
  }

  return solarEnergy
}

export function parseLoadEnergySummary(
  totalEnergy: Partial<ISystemEnergy>,
  energyThisMonth: ISystemEnergy,
  energyToday: ISystemEnergy,
  costObject: CostObject
): LoadEnergySummary {
  const doTodayEnergy = !!energyToday
  const doThisMonthEnergy = !!energyThisMonth

  let monLoad, monGrid, todLoad, todGrid

  const totLoad = totalEnergy.consumptionL1 + totalEnergy.consumptionL2 + totalEnergy.consumptionL3
  const totGrid = totalEnergy.gridImportL1 + totalEnergy.gridImportL2 + totalEnergy.gridImportL3

  if (doThisMonthEnergy) {
    monLoad =
      totLoad -
      (energyThisMonth.consumptionL1 + energyThisMonth.consumptionL2 + energyThisMonth.consumptionL3)
    monGrid =
      totGrid - (energyThisMonth.gridImportL1 + energyThisMonth.gridImportL2 + energyThisMonth.gridImportL3)
  }
  if (doTodayEnergy) {
    todLoad = totLoad - (energyToday.consumptionL1 + energyToday.consumptionL2 + energyToday.consumptionL3)
    todGrid = totGrid - (energyToday.gridImportL1 + energyToday.gridImportL2 + energyToday.gridImportL3)
  }

  const loadEnergy: LoadEnergySummary = {}

  loadEnergy.tot = {
    load: energy2String(totLoad),
    grid: energy2String(totGrid),
    cost: `${Math.round((totGrid * costObject.costElectricityKwh) / 1000)} ${costObject.currency}`
  }
  if (doThisMonthEnergy) {
    loadEnergy.mon = {
      load: energy2String(monLoad),
      grid: energy2String(monGrid),
      cost: `${Math.round((monGrid * costObject.costElectricityKwh) / 1000)} ${costObject.currency}`
    }
  }
  if (doTodayEnergy) {
    loadEnergy.tod = {
      load: energy2String(todLoad),
      grid: energy2String(todGrid),
      cost: `${Math.round((todGrid * costObject.costElectricityKwh) / 1000)} ${costObject.currency}`
    }
  }
  return loadEnergy
}

export interface EnergySummeriesData {
  todayProduction: Partial<ISystemEnergy>[]
  monthlyProduction: Partial<ISystemEnergy>[]
  totalProduction: Partial<ISystemEnergy>[]
}

// Required keys for summaries
const subtractableKeys: (keyof Partial<ISystemEnergy>)[] = [
  'pvProduced',
  'gridExport',
  'gridExportL1',
  'gridExportL2',
  'gridExportL3',

  'gridImport',
  'gridImportL1',
  'gridImportL2',
  'gridImportL3',

  'consumption',
  'consumptionL1',
  'consumptionL2',
  'consumptionL3'
]

const createRelativeEnergyCounter = (
  current: Partial<ISystemEnergy>,
  totalProduction: Partial<ISystemEnergy>
): ISystemEnergy => {
  const res: Partial<ISystemEnergy> = {
    ...current
  }
  Object.keys(current).map(key => {
    if (subtractableKeys.includes(key as keyof Partial<ISystemEnergy>)) {
      ;(res as any)[key] = (totalProduction as any)[key] - (current as any)[key]
    }
  })
  return res as ISystemEnergy
}

export function parseEnergySummeries(
  systemEnergyCounters: EnergySummeriesData,
  costObject: CostObject
): { solarEnergy: SolarEnergySummary; loadEnergy: LoadEnergySummary } {
  const [todayProduction] = systemEnergyCounters.todayProduction
  const [monthlyProduction] = systemEnergyCounters.monthlyProduction
  const [totalProduction] = systemEnergyCounters.totalProduction

  if (!totalProduction) {
    return {
      solarEnergy: null,
      loadEnergy: null
    }
  }
  const totalEnergy = totalProduction
  const energyThisMonth = monthlyProduction
    ? createRelativeEnergyCounter(monthlyProduction, totalProduction)
    : null
  const energyToday = todayProduction ? createRelativeEnergyCounter(todayProduction, totalProduction) : null

  const solarEnergy = parseSolarEnergySummary(totalEnergy, energyThisMonth, energyToday, costObject)

  const loadEnergy = parseLoadEnergySummary(totalEnergy, energyThisMonth, energyToday, costObject)
  return {
    solarEnergy,
    loadEnergy
  }
}
