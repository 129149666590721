import { DefaultEmsConfigSchedule } from '@app/graphql/generated'
import { LanguageScheduling } from '@app/language/language.interface'
import { FerroConfiguration, FerroConfigurationName } from '@app/service'
import { DEFAULT_TIMEZONE } from '@environments/environment'
import '@toast-ui/calendar'
import Calendar, { EventObject, TZDate } from '@toast-ui/calendar'
import '@toast-ui/calendar/dist/toastui-calendar.min.css'
import angular, { IOnChangesObject, IScope } from 'angular'
import ICAL from 'ical.js'
import moment from 'moment-timezone'
import templateUrl from './calendar-view.component.html'
import './calendar-view.component.scss'

import { NotificationService } from '@app/components'
import { SystemConfigurationGraphqlService, SystemConfigurationGraphqlServiceName } from '@app/graphql'
import { AmplitudeService, AmplitudeServiceName } from '@app/service/amplitude.service'
import { RolesServices, RolesServicesName } from '@app/service/roles.services'
import { StateService } from '@uirouter/core'
import { DateTime } from 'luxon'
import { systemSettingsStates } from '../../system-settings.routing.config'
import { EmsConfigScheduleParsed } from './ems-config-schedule.component'
import { toVCalendarInputs } from './i-calendar'

const padTime = (nr: number): string => {
  return nr < 10 ? '0' + nr : '' + nr
}

const template = (timezone: string) => ({
  timegridDisplayPrimaryTime({ time }: { time: TZDate }): string {
    const hour = time.getHours()
    const formattedHour = hour < 10 ? '0' + hour : hour
    return `${formattedHour}:00`
  },

  time(event: EventObject) {
    return `<span>${event.title}</span>`
  },

  popupDetailState: () => '',

  popupDetailDate: (event: EventObject): string => {
    const start = event.start
    const end = event.end
    const isSameDate = moment.tz(start, timezone).isSame(end, 'day')
    const endFormat = `${isSameDate ? '' : 'YYYY-MM-DD '} HH:mm`

    if (event.isAllDay) {
      return ''
    }

    const dateText = `${moment.tz(start, timezone).format('YYYY-MM-DD HH:mm')} - ${moment
      .tz(end, timezone)
      .format(endFormat)}`
    return dateText
  }
})

const calculateTop = (rect: DOMRect, mainRect: DOMRect, popUpRect: DOMRect) => {
  let top = rect.y + rect.height / 2 - mainRect.y
  if (top + popUpRect.height > 800) top = top - popUpRect.height

  return top + 'px'
}

const calculateLeft = (rect: DOMRect, mainRect: DOMRect) => {
  let left = rect.x - mainRect.x - rect.width
  if (left < 72) left = 72

  return left + 'px'
}

const createDefaultEvent = (
  language: LanguageScheduling,
  event: Omit<DefaultEmsConfigSchedule, 'facility'>
): EventObject => {
  const start = new Date(0)
  const end = moment().add(10, 'years').toDate()
  return {
    id: `default-${Math.random()} `,
    calendarId: '1',
    title: language.DEFAULT_SCHEDULING,
    category: 'allday',
    dueDateClass: '',
    isAllDay: true,
    start,
    end,
    backgroundColor: '#f97537',
    color: 'white',
    raw: event,
    isReadOnly: true // schedule is read-only,
  }
}

const createEvent = (
  event: ICAL.Event,
  start: Date,
  end: Date,
  bgColor: string,
  schedule: EmsConfigScheduleParsed
): EventObject => {
  let backgroundColor = bgColor
  let color = 'black'
  if (/Automatic Spot/g.test(event.summary)) {
    if (/Charging/g.test(event.summary)) {
      color = 'white'
      backgroundColor = '#943ADB'
    } else {
      backgroundColor = '#E767A4'
      color = 'white'
    }
  }

  return {
    id: schedule.id + Math.random(),
    state: undefined,
    recurrenceRule: schedule.rule?.toString(),
    calendarId: '1',
    title: event.summary,
    body: event.description,
    category: 'time',
    dueDateClass: '',
    start,
    end,
    backgroundColor,
    color,
    isReadOnly: false, // schedule is read-only,
    raw: schedule
  }
}

type CalendarEvents = {
  events?: EmsConfigScheduleParsed[]
  defaultEvent?: Omit<DefaultEmsConfigSchedule, 'facility'>
}

const colorPicker = (index: number) => {
  const colors = [
    'rgba(111, 182, 65,0.2)',
    'rgba(93, 179, 229, 0.2)',
    'rgba(217, 14, 21, 0.2)',
    'rgba(17, 54, 90, 0.2)',
    'rgba(249, 117, 55, 0.2)',
    'rgba(96, 79, 139, 0.2)',
    'rgba(17, 98, 91, 0.2)',
    'rgba(136, 203, 65, 0.2)',
    'rgba(68, 202, 232, 0.2)'
  ]
  if (index > colors.length) index = index % colors.length
  return colors[index]
}

type PopUpOptions = {
  id: string
  title: string
  description: string
  start: string
  end: string
  dates: string
  reccurrance: string
  raw: EmsConfigScheduleParsed
  isDefault: boolean
}

class CalendarViewController {
  title = 'Calendar view'
  private calendar: Calendar

  events: CalendarEvents
  private timezone: string
  l: LanguageScheduling
  dates: {
    from?: string
    to?: string
  } = {}
  popup: HTMLElement
  mainelement: HTMLElement
  tuiTimeElement: HTMLElement
  tuiTimeScrollEvent: (e: Event) => void
  popupOptions?: PopUpOptions = undefined
  pressEvent: (e: KeyboardEvent) => void
  clickEvent: (e: MouseEvent) => void
  notAllowed: boolean
  onChange?: () => void
  constructor(
    private fc: FerroConfiguration,
    private $scope: IScope,
    private $state: StateService,
    private roles: RolesServices,
    private amplitudeService: AmplitudeService,
    private notifications: NotificationService,
    private service: SystemConfigurationGraphqlService
  ) {
    this.notAllowed = !this.roles.canUpdateFacilitySystemSettings()

    this.l = fc.language.Scheduling
    this.title = this.l.CALENDAR_VIEW_TITLE
    this.timezone = fc.facility.timezone || DEFAULT_TIMEZONE
  }

  $onInit(): void {
    const id = document.getElementById('scheduling-calendar')
    const element = angular.element(id)
    if (element.length) {
      this.render(id)
    } else {
      setTimeout(() => {
        this.$onInit()
      }, 300)
    }
  }

  $onChanges(changes: IOnChangesObject): void {
    if (changes.events) this.parseEvents(changes.events.currentValue)
  }

  prev() {
    this.calendar.prev()
    this.onDateChanged()
  }
  next() {
    this.calendar.next()
    this.onDateChanged()
  }
  today() {
    this.calendar.today()
    this.onDateChanged()
  }

  private onDateChanged() {
    const start = this.calendar.getDateRangeStart()
    const end = this.calendar.getDateRangeEnd()
    this.dates.from = DateTime.fromJSDate(start.toDate(), { zone: this.fc.facility.timezone }).toFormat(
      'yyyy-MM-dd'
    )
    this.dates.to = DateTime.fromJSDate(end.toDate(), { zone: this.fc.facility.timezone }).toFormat(
      'yyyy-MM-dd'
    )
    this.parseEvents(this.events)
  }

  private removeClickHandler() {
    document.removeEventListener('click', this.clickEvent)

    this.tuiTimeElement?.removeEventListener('scroll', this.tuiTimeScrollEvent)
  }

  private addClickHandler() {
    document.addEventListener('click', this.clickEvent)
  }

  private setUpEventHandlers() {
    this.pressEvent = (e: KeyboardEvent) => {
      if (e.key === 'Escape' && this.popup) {
        e.preventDefault()
        this.popup.classList.toggle('show')
        this.removeClickHandler()
      }
    }
    this.clickEvent = (e: MouseEvent) => {
      if (this.popup && !this.popup.contains(e.target as HTMLElement)) {
        this.popup.classList.toggle('show')
        this.removeClickHandler()
      }
    }
    document.addEventListener('keydown', this.pressEvent)
    this.tuiTimeElement = document.getElementsByClassName('toastui-calendar-time')[0] as HTMLElement
    this.calendar.on('clickEvent', this.onEventClicked.bind(this))
  }

  private render(id: HTMLElement): void {
    const days = angular.copy(this.fc.language.Globally.dayshort)

    // document.removeEventListener('keydown', down)
    const sunday = days.pop()
    days.splice(0, 0, sunday)

    this.calendar = new Calendar(id, {
      isReadOnly: true,
      gridSelection: false,
      defaultView: 'week',
      timezone: {
        zones: [
          {
            timezoneName: this.timezone,
            displayLabel: this.timezone
          }
        ]
      },
      useDetailPopup: true,
      week: {
        startDayOfWeek: 1,
        dayNames: days as [],
        taskView: false,
        eventView: true,
        showTimezoneCollapseButton: true
      },
      usageStatistics: false,
      template: template(this.timezone),
      theme: {}
    })
    this.popup = document.getElementById('custom-calendar-popup')
    this.mainelement = document.getElementById('scheduling-calendar-parent')
    this.setUpEventHandlers()
    this.onDateChanged()
  }

  edit() {
    if (this.notAllowed) return
    if (this.popupOptions) {
      this.$state.go(systemSettingsStates.schedulingEdit, {
        scheduleId: this.popupOptions.id,
        isDefault: this.popupOptions.isDefault,
        isUpdating: true
      })
    }
  }

  async remove() {
    if (this.notAllowed || !this.popupOptions) return
    if (this.popupOptions.raw.default) return
    const iWantToRemove = await this.fc
      .confirm(
        `${this.l.REMOVE_EVENT} ${this.popupOptions.raw.event.summary}`,
        this.l.SURE_REMOVE_EVENT,
        this.l.YES_IM_SURE,
        this.l.CANCEL
      )
      .catch(() => {
        // this.notifications.onError(this.l.ERROR_OCCURED_PLEASE_TRY_AGAIN)
      })
    if (iWantToRemove) {
      this.amplitudeService.logEvent('Remove Ems Schedule')
      const result = await this.service
        .deleteEmsConfigSchedule(Number(this.fc.facility.id), this.popupOptions.id)
        .catch()
      const { success } = result.data.deleteEmsConfigSchedule
      if (success) {
        this.notifications.onSuccess(
          `${this.l.SUCCESS_FULLY_REMOVED_EVENT} ${this.popupOptions.raw.event.summary}`
        )

        if (this.onChange) this.onChange?.()
      } else {
        this.notifications.onError(this.l.FAILED_REMOVED_EVENT)
      }
    }
  }

  private onEventClicked({ event, nativeEvent }: { event: EventObject; nativeEvent: MouseEvent }) {
    this.tuiTimeElement?.removeEventListener('scroll', this.tuiTimeScrollEvent)

    this.$scope.$applyAsync(() => {
      this.popupOptions = {
        id: event.raw.id,
        dates: `${padTime(event.start.getHours())}:${padTime(event.start.getMinutes())} - ${padTime(event.end.getHours())}:${padTime(event.end.getMinutes())}`,
        reccurrance: event.raw.ruleAsString,
        title: event.title,
        description: event.body,
        start: event.start,
        end: event.end,
        raw: event.raw,
        isDefault: event.isAllday
      }
    })
    const main = this.mainelement.getBoundingClientRect()

    this.tuiTimeScrollEvent = () => {
      const rect = (nativeEvent.target as HTMLElement).getBoundingClientRect()
      this.popup.style.top = calculateTop(rect, main, this.popup.getBoundingClientRect())
    }

    const rect = (nativeEvent.target as HTMLElement).getBoundingClientRect()
    document
      .getElementsByClassName('toastui-calendar-time')[0]
      .addEventListener('scroll', this.tuiTimeScrollEvent)

    const popUpRect = this.popup.getBoundingClientRect()
    this.popup.style.top = calculateTop(rect, main, popUpRect)
    this.popup.style.left = calculateLeft(rect, main)
    this.popup.style.borderTop = '2px solid ' + event.backgroundColor
    this.popup.classList.toggle('show')

    setTimeout(() => {
      this.addClickHandler()
    }, 300)
  }

  parseEventsWithRecurranceRule(calendarEvents: CalendarEvents): EventObject[] {
    const { events, defaultEvent } = calendarEvents
    const startDate = this.calendar.getDateRangeStart().toDate()
    const endDate = this.calendar.getDateRangeEnd().toDate()

    const generatedEvents: EventObject = []
    for (let i = 0; i < events.length; i += 1) {
      const emsSchedule = events[i]

      if (!emsSchedule.default) {
        const parsed = toVCalendarInputs(emsSchedule.iCalendarEvent)
        const event = parsed.event
        event.description = event.description.replace(/\n|\r/g, '<br>')

        const start = event.startDate ? event.startDate.toJSDate() : null
        const end = event.endDate ? event.endDate.toJSDate() : null

        const count =
          parsed.rules?.between(
            DateTime.fromJSDate(startDate).minus({ days: 7 }).toJSDate(),
            DateTime.fromJSDate(endDate).plus({ days: 14 }).toJSDate()
          ) || []

        if (parsed.rules && count?.length > 1) {
          const byweekday = parsed.rules.options.byweekday
          if (
            byweekday.length &&
            start &&
            end &&
            (start.getTime() > startDate.getTime() || end.getTime() < endDate.getTime())
          ) {
            byweekday.forEach(day => {
              const useStart = moment(startDate)
                .day(day + 1)
                .hour(start.getHours())
                .minute(start.getMinutes())
                .seconds(0)
                .millisecond(0)
              const useEnd = moment(startDate)
                .day(day + 1)
                .hour(end.getHours())
                .minute(end.getMinutes())
                .seconds(0)
                .millisecond(0)

              if (useEnd.isBefore(useStart)) {
                useEnd.add(1, 'day')
              }
              generatedEvents.push(
                createEvent(
                  event,
                  useStart.clone().toDate(),
                  useEnd.clone().toDate(),
                  colorPicker(i),
                  emsSchedule
                )
              )
              if (day === 6) {
                generatedEvents.push(
                  createEvent(
                    event,
                    useStart.clone().subtract(1, 'week').toDate(),
                    useEnd.clone().subtract(1, 'week').toDate(),
                    colorPicker(i),
                    emsSchedule
                  )
                )
              }
            })
          }
        } else {
          generatedEvents.push(createEvent(event, start, end, colorPicker(i), emsSchedule))
        }
      }
    }

    if (defaultEvent) generatedEvents.push(createDefaultEvent(this.l, defaultEvent))
    return generatedEvents
  }

  $onDestroy() {
    document.removeEventListener('keydown', this.pressEvent)
    this.calendar.off('clickEvent', this.onEventClicked)
    this.removeClickHandler()
  }

  parseEvents(calendarEvents: CalendarEvents): void {
    if (this.calendar) {
      const { defaultEvent, events } = calendarEvents

      if (this.events && events?.length) {
        this.calendar.clear()
        this.calendar.createEvents(this.parseEventsWithRecurranceRule(calendarEvents))
        this.calendar.render()
      } else if (defaultEvent) {
        this.calendar.clear()
        this.calendar.createEvents([createDefaultEvent(this.l, defaultEvent)])
        this.calendar.render()
      }
    } else {
      setTimeout(() => {
        this.parseEvents(calendarEvents)
      }, 300)
    }
  }
}

CalendarViewController.$inject = [
  FerroConfigurationName,
  '$scope',
  '$state',
  RolesServicesName,
  AmplitudeServiceName,
  'notificationService',
  SystemConfigurationGraphqlServiceName
]

export const CalendarViewComponent = {
  templateUrl,
  controller: CalendarViewController,
  bindings: {
    events: '<',
    onChange: '<'
  }
}
