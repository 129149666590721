import { material } from 'angular'
import { ThemePalette } from './palette'

export function themePaletteConfig($mdThemingProvider: material.IThemingProvider): void {
  // $mdThemingProvider.setNonce();
  ThemePalette($mdThemingProvider)

  $mdThemingProvider
    .theme('default')
    .primaryPalette('ferro-primary')
    .accentPalette('ferro-accent')
    .warnPalette('ferro-warn')
    .backgroundPalette('ferro-background')
}

themePaletteConfig.$inject = ['$mdThemingProvider']
