import angular from 'angular'
import { AppServiceModule } from '../../service'
import { SystemDashboardComponent, SystemDashboardComponentName } from './system-dashboard.component'
import { SystemDashboardService, SystemDashboardServiceName } from './system-dashboard.service'
import { AVAILABLE_EXTERNAL_MODULES } from '../../../environments/environment'
import { SystemDashboardInformationComponent } from './system-information/system-dashboard-information.component'
import { SystemDashboardChartBoxComponent } from './chart-box/chart-box.component'
import { WeatherInformationComponent } from './weather-information/weather-information.component'
import { EvseOverviewComponent } from './evse-overview/evse-overview.component'
import { PowerFlowComponent } from './power-flow/power-flow.component'
import { EvseStatusComponent } from './evse-overview/evse-status.component'

export {
  SystemDashboardComponentName,
  SystemDashboardServiceName,
  SystemDashboardComponent,
  SystemDashboardService
}

export const AppSystemDashboardModule = angular
  .module('app.system.dashboard', [
    AppServiceModule,
    AVAILABLE_EXTERNAL_MODULES.uiRouter,
    AVAILABLE_EXTERNAL_MODULES.ngMaterial,
    AVAILABLE_EXTERNAL_MODULES.ngAnimate
  ])
  .component('systemDashboardInformation', SystemDashboardInformationComponent)
  .component('weatherInformation', WeatherInformationComponent)
  .component(SystemDashboardComponentName, SystemDashboardComponent)
  .component('systemDashboardChartBox', SystemDashboardChartBoxComponent)
  .service(SystemDashboardServiceName, SystemDashboardService)
  .component('evseOverviewComponent', EvseOverviewComponent)
  .component('powerFlowComponent', PowerFlowComponent)
  .component('evseStatusComponent', EvseStatusComponent).name
