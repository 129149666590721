import * as angular from 'angular'
import { AppServiceModule, FerroConfiguration, FerroConfigurationName, LanguageService } from '../service'

import { UnleashPortal, UnleashPortalName } from '@app/service/unleash-portal'
import { GraphqlPowerDataRetentionsService } from '../graphql/retention.queries'
import { LanguageServiceName } from '../service/language.service'
import { amplitudeHandler } from './amplitude-handler'

/**
 * @ngdoc overview
 * @name ferroUser.run:ferroUserBooterServices
 *
 * @description
 * We need this function to start the services to set the portals language
 */
function ferroUserBooterServices(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  languageServices: LanguageService,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ferroConfiguration: FerroConfiguration,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  graphqlPowerDataRetentionsService: GraphqlPowerDataRetentionsService,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  frontendFeatures: UnleashPortal
): void {}

ferroUserBooterServices.$inject = [
  LanguageServiceName,
  FerroConfigurationName,
  'graphqlPowerDataRetentionsService',
  UnleashPortalName
]

export const AppRun = angular
  .module('app.run', [AppServiceModule])
  .run(ferroUserBooterServices)
  .run(amplitudeHandler).name
