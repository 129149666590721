import { languagesAvailable } from '@app/service/language.service'

const us = {
  WAITING_FOR_CONNECTION: 'Waiting for connection to the system.',
  SENT_TO_EHUB: 'The request has been sent to the system',
  CONFIGURATION_BEEN_CHANGED: 'The configuration has been changed',
  SOMEONE_ELSE_CHANGED: 'The configuration has been changed from elsewhere',
  OTHER_TRANSACTION_ONGOING_STRING: 'There is already another transaction ongoing, please try again soon.'
}

const se: typeof us = {
  WAITING_FOR_CONNECTION: 'Väntar på kontakt med systemet.',
  SENT_TO_EHUB: 'Förfrågan har skickats till systemet.',
  CONFIGURATION_BEEN_CHANGED: 'Konfigureringen har ändrats',
  SOMEONE_ELSE_CHANGED: 'Konfigureringen har blivit ändrat från någon annanstans',
  OTHER_TRANSACTION_ONGOING_STRING: 'Konfigureringen håller redan på att ändras, var god försök snart igen.'
}

export const emsConfigLanguages: { [key in languagesAvailable]: typeof us } = {
  us,
  se
}
