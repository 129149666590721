import * as angular from 'angular'
import { AppServiceModule } from '../service'
import { AVAILABLE_EXTERNAL_MODULES } from '../../environments/environment'
import { CreateSystemComponent, CreateSystemComponentName } from './create-system/create-system.component'
import { globalSettingsRoutesConfig } from './global-settings-routes.config'

export const AppGlobalSettings = angular
  .module('app.global-settings', [AVAILABLE_EXTERNAL_MODULES.ngMaterial, AppServiceModule])
  .component(CreateSystemComponentName, CreateSystemComponent)
  .config(globalSettingsRoutesConfig).name
