import {
  IAttributes,
  IAugmentedJQuery,
  IDirective,
  IDirectiveFactory,
  INgModelController,
  IScope
} from 'angular'

/**
 *
 *
 * @example
 * ```html
 * <form name="form">
 *     <md-input-container>
 *         <label> high</label>
 *   <input ng-model="$ctrl.inputs.high" name="high">
 *       </md-input-container>
 *   <md-input-container class="flex-50">
 *        <label>Low</label>
 *        <input type="number" ng-model="$ctrl.inputs.low" name="low" less-than="$ctrl.inputs.high">
 *        <div ng-messages="form.low.$error">
 *          <div ng-message="less-than">Low value must be lower than high value</div>
 *        </div>
 *   </md-input-container>
 *  </form>
 * ```
 */
export class LessThanFormDirective implements IDirective {
  restrict = 'A'
  require = 'ngModel'

  link(scope: IScope, element: IAugmentedJQuery, attrs: IAttributes, ctl: INgModelController): void {
    ctl.$validators.lessThan = modelValue => {
      const isValid = modelValue > scope.$eval(attrs.lessThan)
      ctl.$setValidity('less-than', isValid)
      return isValid
    }
  }

  static factory(): IDirectiveFactory {
    return () => new LessThanFormDirective()
  }
}

/**
 *
 *
 * @example
 * ```html
 * <form name="form">
 *     <md-input-container>
 *         <label>Low</label>
 *   <input ng-model="$ctrl.inputs.low" name="low">
 *       </md-input-container>
 *   <md-input-container class="flex-50">
 *        <label>High</label>
 *        <input type="number" ng-model="$ctrl.inputs.high" name="high" more-than="$ctrl.inputs.low">
 *        <div ng-messages="form.high.$error">
 *          <div ng-message="more-than">High value value must be more than low value</div>
 *        </div>
 *   </md-input-container>
 *  </form>
 * ```
 */
export class MoreThanFormDirective implements IDirective {
  restrict = 'A'
  require = 'ngModel'

  link(scope: IScope, element: IAugmentedJQuery, attrs: IAttributes, ctl: INgModelController): void {
    ctl.$validators.moreThan = modelValue => {
      const isValid = modelValue < scope.$eval(attrs.moreThan)
      ctl.$setValidity('more-than', isValid)
      return isValid
    }
  }

  static factory(): IDirectiveFactory {
    return () => new MoreThanFormDirective()
  }
}

export class MoreThanOrEqualFormDirective implements IDirective {
  restrict = 'A'
  require = 'ngModel'

  link(scope: IScope, element: IAugmentedJQuery, attrs: IAttributes, ctl: INgModelController): void {
    ctl.$validators.moreOrEqual = modelValue => {
      const attrValue = scope.$eval(attrs.moreOrEqual)
      const isValid = modelValue >= attrValue
      ctl.$setValidity('more-or-equal', isValid)
      return isValid
    }
    scope.$watch(attrs.moreOrEqual, () => {
      ctl.$validate()
    })
  }

  static factory(): IDirectiveFactory {
    return () => new MoreThanOrEqualFormDirective()
  }
}

export class LessThanOrEqualFormDirective implements IDirective {
  restrict = 'A'
  require = 'ngModel'

  link(scope: IScope, element: IAugmentedJQuery, attrs: IAttributes, ctl: INgModelController): void {
    ctl.$validators.lessOrEqual = modelValue => {
      const attrValue = scope.$eval(attrs.lessOrEqual)
      const isValid = modelValue <= attrValue
      ctl.$setValidity('less-or-equal', isValid)
      return isValid
    }

    scope.$watch(attrs.lessOrEqual, () => {
      ctl.$validate()
    })
  }

  static factory(): IDirectiveFactory {
    return () => new LessThanOrEqualFormDirective()
  }
}
