import { LanguageHeader } from '../../language/language.interface'
import {
  FeatureFlagService,
  FeatureFlagServiceName,
  FerroConfiguration,
  FerroConfigurationName
} from '../../service'

import './planned-maintenance.component.scss'

import template from './planned-maintenance.component.html'

class PlannedMaintenanceController {
  l: LanguageHeader
  featureFlagService: FeatureFlagService
  hasActiveIncident = false

  constructor(ferroConfiguration: FerroConfiguration, featureFlagService: FeatureFlagService) {
    this.l = ferroConfiguration.language.Header
    this.featureFlagService = featureFlagService
  }

  $onInit(): void {
    this.hasActiveIncident = this.featureFlagService.onGoingPlannedMaintenance()
  }
}

PlannedMaintenanceController.$inject = [FerroConfigurationName, FeatureFlagServiceName]

export const PlannedMaintenanceComponent = {
  controller: PlannedMaintenanceController,
  templateUrl: template
}
