import { tooOneDecimal } from '../general'

export interface UpperLowerSchematicLimits {
  lowerLimit: number
  upperLimit: number
}

export interface PowerSchematicValues {
  gridPower: number
  loadPower: number
  pvPower: number
  ehubPower: number
  batPower: number
}

// eslint-disable-next-line
export type Selected = any

export const dotHidden = 'dot-hidden'

// FUNCTIONS
/**
 *
 */
export function roundValue(value: number): number | null {
  return value === null ? null : tooOneDecimal(value)
}

export function direction(value: number): number {
  const val = tooOneDecimal(value)
  if (val < 0) {
    return -1
  }
  if (val > 0) {
    return 1
  }
  return 0
}

export function roundValues(values: PowerSchematicValues): PowerSchematicValues {
  return {
    pvPower: roundValue(values.pvPower),
    batPower: roundValue(values.batPower),
    gridPower: roundValue(values.gridPower),
    loadPower: roundValue(values.loadPower),
    ehubPower: roundValue(values.ehubPower)
  }
}

export function genPowerText(val: number, limits?: UpperLowerSchematicLimits): string {
  const value = Math.abs(val)
  const lims: UpperLowerSchematicLimits = limits || {
    lowerLimit: 0,
    upperLimit: 0
  }
  const upperLimit = lims.upperLimit ? lims.upperLimit : 0
  const lowerLimit = lims.lowerLimit ? lims.lowerLimit : 0

  let rtn

  if (value < upperLimit && value > lowerLimit) {
    rtn = 0
  } else if (isNaN(value)) {
    rtn = 0
  } else {
    rtn = Math.round(value * 10) / 10
  }
  return `${rtn} kW`
}

/**
 * return 0 if not a valid number according to specified limits else returns the value
 */
export function isValidValue(value: number, limits: UpperLowerSchematicLimits): number {
  if (value < limits.upperLimit && value > limits.lowerLimit) {
    return 0
  }
  return value as number
}

export function calculateMicrogridPower(pvPower: number, batPower: number, energyhubPower: number): number {
  return energyhubPower - (pvPower + batPower)
}
