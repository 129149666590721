import { IHttpPromise, IHttpService } from 'angular'

import { Environment } from '@environments/environment'
import { EmsConfParametersPayload, EmsParam } from '@ferroamp/system-configuration-lib'
import batteryWizardList from '@app/system/settings/system-configuration/battery-wizard/battery-wizard.json'
import { emsConfigFormToEmsParam } from '@app/graphql/system-configuration/system-configuration.parsers'

export interface EmsConfigCollectionDocuments {
  _id: number
  emsConfig: EmsConfParametersPayload
}

const ops = Environment.emsConfigV2Options
const endpoint = ops.endpoint

export const EmsConfigV2HttpServiceName = 'emsConfigV2HttpService'

export interface BatteryWizardObject {
  Name: string
  Charge: number
  Discharge: number
  Low: number
  High: number
}

export class EmsConfigV2HttpService {
  $http: IHttpService

  constructor($http: IHttpService) {
    this.$http = $http
  }

  /**
   *
   * @param facilityId
   */
  public getCurrentEmsConfig(facilityId: number): IHttpPromise<EmsConfigCollectionDocuments> {
    return this.$http<EmsConfigCollectionDocuments>({
      method: 'GET',
      url: `${endpoint}/current/${facilityId}`
    })
  }

  /**
   * Should respond 201 as accepted if accepted the request
   */
  public initateGetEmsConfig(facilityId: number): IHttpPromise<string> {
    return this.$http<string>({
      method: 'POST',
      url: `${endpoint}/commands/get/${facilityId}`
    })
  }

  /**
   *  Should respond 201 as accepted if accepted the request
   */
  public initiateSetEmsConfig(facilityId: number, emsParams: EmsParam): IHttpPromise<string> {
    const config = emsConfigFormToEmsParam(emsParams)
    return this.$http<string>({
      method: 'POST',
      url: `${endpoint}/commands/set/${facilityId}`,
      data: { payload: config }
    })
  }

  public getBatteryWizardList(): IHttpPromise<BatteryWizardObject[]> {
    const response = this.$http<BatteryWizardObject[]>({
      method: 'GET',
      url: `${batteryWizardList}`
    })
    return response
  }
}

EmsConfigV2HttpService.$inject = ['$http']
