import { Powershare } from '@app/graphql/generated'
import { runQuery } from '@app/graphql/graphql'
import { LanguageSystemlist } from '@app/language/language.interface'
import { FerroConfiguration, FerroConfigurationName } from '@app/service'
import { StateService } from '@uirouter/angularjs'
import { IComponentOptions, IOnChangesObject, IScope } from 'angular'
import { PowershareDashboardState } from '../powershare/powershare-routing.config'
import template from './powershare-list.component.html'

export class PowershareListController {
  powershareHref: string
  clusterError = ''
  clusters: Partial<Powershare>[] = []
  private l: LanguageSystemlist
  limitOptions = [10, 25, 50]
  listCluster = {
    order: 'name',
    limit: 10,
    page: 1,
    labels: {
      page: '',
      of: '',
      rowsPerPage: ''
    }
  }
  $s: IScope
  $state: StateService
  hasCluster = false
  constructor($s: IScope, $state: StateService, ferroConfiguration: FerroConfiguration) {
    this.$s = $s
    this.l = ferroConfiguration.language.SystemList
    this.$state = $state
    this.listCluster.labels = {
      page: this.l.LIST_PAGE,
      of: this.l.LIST_OF,
      rowsPerPage: this.l.ROWS_PER_PAGE
    }
    this.powershareHref = $state
      .href(PowershareDashboardState, {}, { inherit: false, absolute: false })
      .split('?')[0]
  }
  $onChanges(_changed: IOnChangesObject): void {
    this.listCluster.page = 1
  }

  $onInit() {
    runQuery.getPowershareListQuery({}).then(result => {
      if (result.error || result.errors) {
        this.clusterError = this.l.ERROR_FETCHING_MULTISYSTEMS
      } else {
        this.clusters = [...(result?.data?.powershares || [])].map(({ id, name }) => {
          return { id, name }
        })
        this.hasCluster = this.clusters.length > 0
      }
    })
  }

  /**
   * When clickin an row in MulstiSystemList this function will route to correct mulstisystem
   */
  goToCluster(clusterId: number): void {
    this.$state.go(PowershareDashboardState, { id: clusterId })
  }
}

PowershareListController.$inject = ['$scope', '$state', FerroConfigurationName]

export const PowershareListComponent: IComponentOptions = {
  controller: PowershareListController,
  templateUrl: template,
  bindings: {
    filter: '<'
  }
}
