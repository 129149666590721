import { ApolloQueryResult } from '@apollo/client'
import { POWER_DATA_TYPE } from '../system/views/power-view'
import { GRAPHQL_ERROR_NO_DATA } from './errors'
import { GetAggregatedPowerdataQuery } from './generated'
import { runQuery } from './graphql'
import { splitQueryIntoDailyChunks } from './split-into-chunks'
export const PowerDataGraphqlServiceName = 'powerDataGraphQlService'

export const parseAggregatedPowerData = (
  promises: Promise<ApolloQueryResult<GetAggregatedPowerdataQuery>>[]
): Promise<POWER_DATA_TYPE> => {
  return new Promise((resolve, reject) => {
    Promise.all(promises)
      .then(apolloResults => {
        let dataResult: POWER_DATA_TYPE = []

        apolloResults.forEach(res => {
          if (res.data?.facility?.measurements?.power) {
            dataResult = dataResult.concat(
              res.data.facility.measurements.power.map(d => {
                return [
                  d.timestamp,
                  d.gridPower / 1000,
                  d.loadPower / 1000,
                  d.pvPower / 1000,
                  d.batteryPower / 1000
                ]
              })
            )
          }
        })
        if (!dataResult.length) GRAPHQL_ERROR_NO_DATA()
        resolve(dataResult)
      })
      .catch(reject)
  })
}

export class PowerDataGraphqlService {
  getPowerData1m(starttime: Date, endtime: Date, facilityId: number): Promise<POWER_DATA_TYPE> {
    const chunks = splitQueryIntoDailyChunks(starttime, endtime, facilityId)
    const promises: Promise<ApolloQueryResult<GetAggregatedPowerdataQuery>>[] = []
    chunks.forEach(chunk => {
      promises.push(runQuery.getAggregatedPowerdataQuery(chunk))
    })
    return parseAggregatedPowerData(promises)
  }

  async getPowerData(starttime: Date, endtime: Date, facilityId: number): Promise<POWER_DATA_TYPE> {
    const data = await runQuery.getPowerdataQuery({
      variables: {
        facilityId: facilityId,
        starttime: starttime,
        endtime: endtime
      }
    })

    // graphqlOnError(data, "powerdata");

    return data.data?.facility?.measurements?.power.map(values => {
      return [
        values.timestamp,
        values.gridPower / 1000,
        values.loadPower / 1000,
        values.pvPower / 1000,
        values.batteryPower / 1000
      ]
    })
  }

  async getSolarPower(starttime: Date, endtime: Date, facilityId: number) {
    return runQuery.getAggregatedSolarPowerQuery({
      variables: {
        facilityId: facilityId,
        starttime: starttime,
        endtime: endtime
      }
    })
  }
}
