import { IOnChangesObject, IScope } from 'angular'
import moment from 'moment-timezone'

import './time-picker.component.scss'

class TimePicker {
  id = `timePicker${Math.random().toString().split('.')[1]}`

  dateTime: Date

  instance: any
  minuteStep: string
  timeZone: string
  label: string

  $s: IScope
  constructor($s: IScope) {
    this.$s = $s
  }

  $onInit(): void {
    this.sync(0)
  }

  private sync(iterate: number) {
    if (!this.dateTime && iterate > 2)
      this.dateTime = moment().minute(0).hour(0).millisecond(0).second(0).toDate()
    const id = document.getElementById(this.id)
    if (id && this.dateTime) {
      this.render(id)
    } else {
      setTimeout(() => {
        this.sync(iterate + 1)
      }, 300)
    }
  }

  $onChanges(change: IOnChangesObject): void {
    if (change.date && change.date.currentValue && this.instance) {
      this.instance.setTime(this.dateTime.getHours(), this.dateTime.getMinutes())
    }
  }

  render(id: HTMLElement): void {
    this.instance = new tui.TimePicker(id, {
      initialHour: this.dateTime ? this.dateTime.getHours() : null,
      initialMinute: this.dateTime ? this.dateTime.getMinutes() : null,
      inputType: 'selectbox',
      showMeridiem: false,
      minuteStep: Number(this.minuteStep)
    })
    this.instance.on('change', (event: any) => {
      this.$s.$applyAsync(() => {
        this.dateTime = moment.tz(this.dateTime, this.timeZone).hour(event.hour).minute(event.minute).toDate()
      })
    })
  }
}

TimePicker.$inject = ['$scope']

export const TimePickerComponent = {
  template: `
    <md-input-container class="time-picker-container">
        <label>{{::$ctrl.label}}</label>
        <div  ng-attr-id="{{::$ctrl.id}}"></div>
    </md-input-container>`,
  controller: TimePicker,
  bindings: {
    dateTime: '=',
    minuteStep: '@',
    label: '@',
    timeZone: '<'
  }
}
