import { Environment } from '@environments/environment'
import { keycloak } from './keycloak'

export const isReadAllFacilityAdmin = () => {
  return keycloak.hasRealmRole(Environment.roles.readAllFacilitiesAdmin)
}

export const isWriteAllFacilityAdmin = () => {
  return keycloak.hasRealmRole(Environment.roles.writeAllFacilitiesAdmin)
}
