import { StateService } from '@uirouter/angularjs'
import angular, { IComponentOptions, IDeferred, IQService, IScope, ITimeoutService, material } from 'angular'
import moment from 'moment-timezone'
import { LanguageAnalaysis } from '../../../language/language.interface'
import {
  AmplitudeService,
  ElectricalComponentColors,
  FeatureFlagService,
  FeatureFlagServiceName,
  FerroConfiguration,
  FerroConfigurationName
} from '../../../service'
import { graphDialogTemplateUrl } from '../graph-popup-dialog-template/graph-dialog.controller.template'
import { FuseAnalysisDialogController } from './fuse-analysis-dialog.controller'

import { ECharts } from 'echarts'
import { clearEchartsInstance, roundTo2Decimals } from '../../../../lib'
import { parseHourDate } from '../../../filters/parse-hour-date.filter'
import { AnalysisHttpService, AnalysisHttpServiceName, ParsedStoredAnalysis } from '../analysis-http.service'
import { createFuseChart } from './fuse-analysis.chart'
import { FuseAnalysisService, FuseAnalysisServiceName } from './fuse-analysis.service'

import { DEFAULT_TIMEZONE } from '../../../../environments/environment'
import {
  GraphqlPowerDataRetentionsService,
  GraphqlPowerDataRetentionsServiceName
} from '../../../graphql/retention.queries'
import { UidataGraphqlServiceName } from '../../../graphql/uidata-graphql.service'
import templateUrl from './fuse-analysis.component.html'

import { AmplitudeServiceName } from '@app/service/amplitude.service'
import whitepaper from '../../../../assets/files/whitepapers/fuseanlaysis_whitepaper_A_se.pdf'
import { MdbAnalysisLoadEventDetectionResults, MdbAnalysisResultsId } from '../@types/load-event-detection'

type TimestampData = { x: number; y: number; t: Date }

export class FuseAnalysisController {
  loading = false
  whitepaperUrl = whitepaper
  l: LanguageAnalaysis
  error = ''
  graphTitle = ''
  ifCurrentJobsText = ''
  okeyToStartNew = true
  // timestampsarr: Date[] = []
  starttime: Date
  endtime: Date

  maxDate: Date
  minDate: Date
  dontShowGraphs = true
  errorList = ''
  fcService: FerroConfiguration
  service: FuseAnalysisService
  $scope: IScope
  $s: StateService
  $t: ITimeoutService
  dialog: material.IDialogService

  analysisJobs: ParsedStoredAnalysis[]
  aService: AnalysisHttpService

  c: IDeferred<unknown>
  colors: ElectricalComponentColors

  timestampInformation = ''
  timestampColor: { color: string }
  timestampsError = ''
  timestamps: TimestampData[]

  charts: {
    chartNoAce: ECharts
    chartAce: ECharts
  }

  isHistoricalPerSecondDataEnabled: boolean

  timestampMinDate: Date
  onResultsId: (resultsId: MdbAnalysisResultsId) => void
  amplitude: AmplitudeService

  constructor(
    $scope: IScope,
    $mdDialog: material.IDialogService,
    ferroConfiguration: FerroConfiguration,
    fuseAnalysisService: FuseAnalysisService,
    $q: IQService,
    $state: StateService,
    analysisHttpService: AnalysisHttpService,
    $timeout: ITimeoutService,
    graphqlPowerDataRetentionsService: GraphqlPowerDataRetentionsService,
    features: FeatureFlagService,
    amplitude: AmplitudeService
  ) {
    this.amplitude = amplitude
    this.isHistoricalPerSecondDataEnabled = features.isHistoricalPerSecondDataEnabled()
    this.$t = $timeout
    this.$s = $state
    this.timestampMinDate = graphqlPowerDataRetentionsService.getUiData1SecondMinDate()
    this.$scope = $scope
    this.fcService = ferroConfiguration
    this.service = fuseAnalysisService
    this.dialog = $mdDialog
    this.aService = analysisHttpService
    this.colors = ferroConfiguration.electricColors
    const TIMEZONE = ferroConfiguration.facility.timezone || DEFAULT_TIMEZONE

    function fixDate(date: Date, end?: boolean) {
      const d = moment.tz(date, TIMEZONE)
      return end ? d.endOf('day').toDate() : d.startOf('day').toDate()
    }

    this.c = $q.defer()
    this.endtime = fixDate(new Date(), true)
    this.starttime = fixDate(new Date(new Date().setDate(new Date().getDate() - 7))) // start 1 week ago;;
    this.l = ferroConfiguration.language.SystemAnalysis
    this.maxDate = ferroConfiguration.getMaxDate()
    this.minDate = ferroConfiguration.getMinDate()

    this.onResultsId = (resultsId: MdbAnalysisResultsId): void => {
      this.amplitude.logEvent('Get Fuse Analysis From Stored')
      this.getJobs(resultsId)
    }
  }

  $onDestroy(): void {
    this.c.resolve()
    if (this.charts) {
      clearEchartsInstance(this.charts.chartAce)
      clearEchartsInstance(this.charts.chartNoAce)
    }
  }

  $onInit(): void {
    const ferroConfiguration = this.fcService
    const fuseAnalysisService = this.service
    fuseAnalysisService.init(
      '',
      Number(ferroConfiguration.facility.id),
      ferroConfiguration.facility.timezone,
      this.c.promise
    )

    if (this.$s.params.resultsId) this.getJobs(this.$s.params.resultsId)

    this.aService.getCurrentLoadEventJobs(Number(ferroConfiguration.facility.id), jobs => {
      this.error = ''
      if (jobs && jobs.length) {
        const starttime = parseHourDate(ferroConfiguration)(jobs[0].starttime)
        const endtime = parseHourDate(ferroConfiguration)(jobs[0].endtime)
        const timestamp = parseHourDate(ferroConfiguration)(jobs[0].timestamp)
        this.ifCurrentJobsText = this.l.ANALYSIS_JOB_IS_RUNNING.replace('<STARTTIME>', starttime)
          .replace('<ENDTIME>', endtime)
          .replace('<TIMESTAMP>', timestamp)
        this.okeyToStartNew = false
      }
    })
  }

  createNewFuseJob(): void {
    this.error = ''

    const starttime = this.starttime
    const endtime = this.endtime
    const startDaysFromToday = moment().endOf('day').diff(starttime, 'days')
    const endDaysFromToday = moment().endOf('day').diff(endtime, 'days')

    this.amplitude.logEvent('Create New Fuse Analysis', {
      startDaysFromToday,
      endDaysFromToday
    })
    this.service.createJob(starttime, endtime, error => {
      if (error) {
        this.error = this.l.ERROR_START_ANALYSIS
      } else {
        this.okeyToStartNew = false
        this.ifCurrentJobsText = this.l.ANALYS_JOB_CREATED
      }
    })
  }

  // eslint-disable-next-line
  bubbleOnClickFunction(params: any): void {
    this.amplitude.logEvent('Fuse Analysis Clicked On Bubble')

    const tList = params.data[3]
    const x = params.data[0]
    const y = params.data[1]
    if (tList && tList.length) {
      this.timestampColor = { color: params.color }
      this.timestampInformation = params.seriesName
      const timestamp: TimestampData[] = []
      tList.forEach((ts: number) => {
        if (ts) {
          const t = typeof ts === 'number' ? new Date(ts * 1000) : new Date(ts)
          if (t > this.timestampMinDate) {
            timestamp.push({
              x: roundTo2Decimals(x),
              y: roundTo2Decimals(y),
              t: t
            })
          }
        }
      })
      this.timestamps = timestamp

      if (!timestamp.length) {
        this.timestampsError = `${this.l.NO_AVAILABLE_DATA_BEFORE_DATE} ${this.timestampMinDate}`
      }
    } else {
      this.timestampsError = `${this.l.TIMESTAMP}: ${this.l.NO_RESULT}`
    }
    this.$scope.$digest()
  }

  plotData(data: MdbAnalysisLoadEventDetectionResults): void {
    this.dontShowGraphs = false
    this.service.getFuseCurves(async (_error, fuseCurves) => {
      const chargs = await createFuseChart(data, this.colors, this.l, fuseCurves)
      this.loading = false

      chargs.chartNoAce.on('click', this.bubbleOnClickFunction.bind(this))
      chargs.chartAce.on('click', this.bubbleOnClickFunction.bind(this))
      this.$t(() => {
        chargs.chartNoAce.resize()
        chargs.chartAce.resize()
      }, 300)

      this.charts = chargs
    })
  }

  getJobs(resultsid: MdbAnalysisResultsId): void {
    this.loading = true

    this.graphTitle = this.l.FUSE_ANALYSIS
    this.service.getJob(resultsid, (error, fuseData) => {
      if (error) {
        this.error = this.l.ERROR_OCCURRED
      } else {
        this.plotData(fuseData)
      }
    })
  }

  show(ev: MouseEvent, timestamp: number, duration: number, magnitude: number): void {
    const daysFromToday = moment().endOf('day').diff(timestamp, 'days')

    this.amplitude.logEvent('Fuse Analysis Popup Clicked', {
      daysFromToday
    })

    this.dialog.show({
      locals: {
        timestamp: timestamp,
        duration: duration,
        magnitude: magnitude
      },
      controller: [
        '$mdDialog',
        UidataGraphqlServiceName,
        FerroConfigurationName,
        '$scope',
        'timestamp',
        'duration',
        'magnitude',
        FuseAnalysisDialogController
      ],
      controllerAs: 'vm',
      templateUrl: graphDialogTemplateUrl,
      parent: angular.element(document.body),
      targetEvent: ev,
      clickOutsideToClose: true
    })
  }
}

FuseAnalysisController.$inject = [
  '$scope',
  '$mdDialog',
  FerroConfigurationName,
  FuseAnalysisServiceName,
  '$q',
  '$state',
  AnalysisHttpServiceName,
  '$timeout',
  GraphqlPowerDataRetentionsServiceName,
  FeatureFlagServiceName,
  AmplitudeServiceName
]

export const FuseAnalysisComponentName = 'fuseAnalysisComponent'

export const FuseAnalysisComponent: IComponentOptions = {
  controller: FuseAnalysisController,
  templateUrl: templateUrl
}
