import { LanguageGlobally, LanguageSystemdashboard } from '../../../language/language.interface'
import { ElectricalComponentColors } from '../../../service'
import {
  defaultEchartsDataZoom,
  defaultEchartsGrid,
  defaultEchartsYAxisEnergy,
  defaultEchartsYAxisPower,
  echartsInitWrapper,
  echartsNoDataTitleOptions,
  echartsOnResize,
  echartsTimeFormatters,
  echartsToolbox,
  echartsTooltip,
  roundTo2Decimals,
  shadeColor,
  tooltipDateFormatter
} from '../../../../lib'
import {
  ECHARTS_ENERGY_DATASET_DIMENSIONS,
  ProcessedEchartEnergyData
} from '../../views/energy-view/energy-view.tools'
import { EChartOption, ECharts } from 'echarts'
import moment from 'moment-timezone'
import { captureSentryException } from '../../../config/sentry'
import { EnergyCounterAsNumbers } from 'types'

export async function createYearlyEnergyGraph(
  data: EnergyCounterAsNumbers[],
  l: LanguageSystemdashboard,
  colors: ElectricalComponentColors,
  timezone: string,
  error?: string
): Promise<ECharts> {
  if (!data && !error) {
    error = l.NO_DATA_FOUND
  } else if (!error && !data.length) {
    error = l.NO_DATA_FOUND
  }
  let labels
  if (!error) {
    labels = data.map(d => {
      const date = moment.tz(new Date(d.ts), 'UTC')
      return date.format('YYYY')
    })
  }

  let max = 0

  const pveData = []
  const loadData = []
  const gridData = []

  const year = await echartsInitWrapper('energyPerYear')
  const ops: EChartOption = {
    grid: defaultEchartsGrid,
    color: [colors.solar.bar, colors.consumption.bar, colors.selfConsumption.bar],
    toolbox: echartsToolbox(),
    tooltip: echartsTooltip(),
    legend: {
      data: [l.SOLAR_ENERGY, l.CONSUMPTION_ENERGY, l.EXPORTED_ENERGY_TO_GRID]
    },
    yAxis: defaultEchartsYAxisEnergy(l.ENERGY),
    xAxis: [
      {
        type: 'category',
        data: labels,
        name: l.PER_YEAR
      }
    ]
  }

  if (!error) {
    for (let i = 0; i < data.length; i++) {
      const d = data[i]
      let pve = roundTo2Decimals((d.pve < 0 ? 0 : d.pve) / 1000)
      if (pve > max) {
        max = pve
      }
      pve = pve < 0 ? null : pve
      pveData.push(pve)

      let load = roundTo2Decimals((d.lc1 + d.lc2 + d.lc3) / 1000)
      if (load > max) {
        max = load
      }
      load = load < 0 ? null : load
      loadData.push(load)

      let grid = roundTo2Decimals((d.ep1 + d.ep2 + d.ep3) / 1000)
      if (grid > max) {
        max = grid
      }
      grid = grid < 0 ? null : grid
      gridData.push(grid)
    }

    ops.series = [
      { name: l.SOLAR_ENERGY, type: 'bar', yAxisIndex: 0, data: pveData },
      {
        name: l.CONSUMPTION_ENERGY,
        type: 'bar',
        yAxisIndex: 0,
        data: loadData
      },
      {
        name: l.EXPORTED_ENERGY_TO_GRID,
        type: 'bar',
        yAxisIndex: 0,
        data: gridData
      }
    ]
    ops.xAxis = [
      {
        type: 'category',
        data: labels,
        name: l.PER_YEAR
      }
    ]
  } else {
    ops.title = echartsNoDataTitleOptions(error)
  }

  try {
    year.setOption(ops)
    echartsOnResize(year)
  } catch (e) {
    captureSentryException(e)
  }
  return year
}

export async function createMonthlyEnergyGraph(
  data: EnergyCounterAsNumbers[],
  l: LanguageSystemdashboard,
  lg: LanguageGlobally,
  colors: ElectricalComponentColors,
  timezone: string,
  error?: string
): Promise<ECharts> {
  const labels = lg.monshort
  const pve: { [key: string]: number[] } = {}
  const loads: { [key: string]: number[] } = {}
  let max = 0

  const solarLabel = l.SOLAR_ENERGY
  const consumptionLabel = l.CONSUMPTION_ENERGY

  let yearsArr = []

  const monthChart = await echartsInitWrapper('EnergyPerMonth')
  const ops: EChartOption = {
    grid: defaultEchartsGrid,
    toolbox: echartsToolbox(),
    tooltip: echartsTooltip(),
    xAxis: [
      {
        type: 'category',
        data: labels,
        name: l.MONTH
      }
    ],
    yAxis: defaultEchartsYAxisEnergy(l.ENERGY)
  }

  if (!error) {
    const data_length = data.length

    for (let i = 0; i < data_length; i++) {
      const d = data[i]
      if (d.ts) {
        pve[moment(d.ts).year()] = new Array(12)
        loads[moment(d.ts).year()] = new Array(12)
      }
    }
    for (let j = 0; j < data_length; j++) {
      const d2 = data[j]
      if (d2.ts) {
        const ts = new Date(d2.ts)

        const year = moment.tz(ts, 'UTC').year()
        const month = moment.tz(ts, 'UTC').month()

        const checker = yearsArr.indexOf(year) + 1
        if (!checker) {
          yearsArr.push(year)
        }

        let pveD = roundTo2Decimals((d2.pve < 0 ? 0 : d2.pve) / 1000)
        let loadD = roundTo2Decimals((d2.lc1 + d2.lc2 + d2.lc3) / 1000)
        if (pveD > max) {
          max = pveD
        }
        if (loadD > max) {
          max = loadD
        }
        pveD = pveD < 0 ? null : pveD
        loadD = loadD < 0 ? null : loadD
        pve[year][month] = pveD
        loads[year][month] = loadD
      }
    }

    yearsArr = yearsArr.sort()
    const length_of_year = yearsArr.length

    const series = []

    const legend = []
    for (let k = 0; k < length_of_year; k++) {
      const year2 = yearsArr[k]

      const solarTitle = `${solarLabel} ${year2}`
      const consumptionTitle = `${consumptionLabel} ${year2}`
      legend.push(solarTitle, consumptionTitle)
      series.push({
        name: solarTitle,
        type: 'bar',
        yAxisIndex: 0,
        data: pve[year2.toString()]
      })

      series.push({
        name: consumptionTitle,
        type: 'bar',
        yAxisIndex: 0,
        data: loads[year2.toString()]
      })
    }

    const fixedColors = []
    for (let i = 0; i < series.length / 2; i++) {
      fixedColors.push(shadeColor(colors.solar.bar, 20 * i))
      fixedColors.push(shadeColor(colors.consumption.bar, 20 * i))
    }

    ops.legend = {
      data: legend
    }
    ops.series = series
    ops.color = fixedColors
  } else {
    ops.title = echartsNoDataTitleOptions(error)
  }

  monthChart.setOption(ops)
  echartsOnResize(monthChart)
  return monthChart
}

export function createEquartzGraph(
  equartzChart: ECharts,
  l: LanguageSystemdashboard,
  colors: ElectricalComponentColors,
  timezone: string
): void {
  const labels = l.AVERAGE_POWER_LABELS
  // const energy = data.totalEnergyData;

  const cl = [
    colors.solar.line,
    colors.consumption.line,
    colors.grid.line,
    colors.selfConsumption.line,
    colors.gridExport.line
  ]

  const tooltip = echartsTooltip()
  tooltip.formatter = tooltipDateFormatter('minutes', timezone)
  const ops: EChartOption = {
    color: cl,
    toolbox: echartsToolbox(),
    tooltip: tooltip,
    legend: {
      data: [labels[1], labels[2], labels[3], labels[4], labels[5]]
    },

    dataset: {
      source: [],
      dimensions: ECHARTS_ENERGY_DATASET_DIMENSIONS
    },
    xAxis: {
      type: 'time',
      name: labels[0],
      axisLabel: {
        formatter: echartsTimeFormatters(timezone).hours
      }
    },
    yAxis: defaultEchartsYAxisPower(l.POWER),
    grid: defaultEchartsGrid,
    dataZoom: defaultEchartsDataZoom,
    series: [
      {
        type: 'line',
        areaStyle: { color: colors.solar.line },
        name: labels[1],
        encode: {
          x: 'ts',
          y: 'pve'
        }
      },
      {
        type: 'line',
        areaStyle: { color: colors.consumption.line },
        name: labels[2],
        encode: {
          x: 'ts',
          y: 'load'
        }
      },
      {
        type: 'line',
        areaStyle: { color: colors.grid.line },
        name: labels[3],
        encode: {
          x: 'ts',
          y: 'grid'
        }
      },
      {
        type: 'line',
        areaStyle: { color: colors.selfConsumption.line },
        name: labels[4],
        encode: {
          x: 'ts',
          y: 'selfCons'
        }
      },
      {
        type: 'line',
        areaStyle: { color: colors.gridExport.line },
        name: labels[5],
        encode: {
          x: 'ts',
          y: 'soldToGrid'
        }
      }
    ]
  }
  equartzChart.setOption(ops)
  echartsOnResize(equartzChart)
}

export function createDailyChart(
  chart: ECharts,
  l: LanguageSystemdashboard,
  colors: ElectricalComponentColors,
  timezone: string
): (__data: ProcessedEchartEnergyData) => void {
  const legendData = [l.SOLAR_ENERGY, l.CONSUMPTION_ENERGY]
  const cl = [colors.solar.line, colors.consumption.line]
  const tooltip = echartsTooltip()
  tooltip.formatter = tooltipDateFormatter('onlyHours', timezone)

  const ops: EChartOption = {
    color: cl,
    toolbox: echartsToolbox(),
    tooltip: tooltip,
    legend: {
      data: legendData
    },
    dataset: {
      source: [],
      dimensions: ECHARTS_ENERGY_DATASET_DIMENSIONS
    },
    xAxis: {
      type: 'category',
      boundaryGap: true,
      name: l.HOUR,
      axisLabel: {
        formatter: echartsTimeFormatters(timezone).onlyHours
      }
    },
    yAxis: defaultEchartsYAxisEnergy(l.ENERGY),
    grid: defaultEchartsGrid,
    dataZoom: defaultEchartsDataZoom,
    series: [
      {
        type: 'bar',
        name: legendData[0],
        encode: {
          x: 'ts',
          y: 'pve'
        }
      },
      {
        type: 'bar',
        name: legendData[1],
        encode: {
          x: 'ts',
          y: 'load'
        }
      }
    ]
  }

  echartsOnResize(chart)

  const formatTime = (data: [Date, number, number, number, number, number]) => {
    const time = echartsTimeFormatters(timezone).withTimezone(data[0])
    return [time, ...data.slice(1)]
  }

  chart.setOption(ops)
  return (__data: ProcessedEchartEnergyData, error?: string): void => {
    if (error) {
      chart.setOption({
        title: echartsNoDataTitleOptions(error),
        dataset: {
          source: []
        }
      })
    } else {
      chart.setOption({
        dataset: {
          source: __data.totalEnergyData.all.map(formatTime)
        }
      })
    }
  }
}
