import { Environment } from '../../environments/environment'
import Keycloak from 'keycloak-js'

export const keycloak = new Keycloak({
  url: Environment.keycloakUrl,
  realm: Environment.keycloakRealm,
  clientId: Environment.keycloakClient
})

export const getToken = () => {
  return `Bearer ${keycloak.token}`
}

export const getHeaders = (headers?: any) => {
  return {
    headers: {
      ...headers,
      authorization: getToken()
    }
  }
}
