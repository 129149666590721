import yr30Svg from '../../../assets/yrweathersymbols/30.svg'
import yr11Svg from '../../../assets/yrweathersymbols/11.svg'
import yr24dSvg from '../../../assets/yrweathersymbols/24d.svg'
import yr46Svg from '../../../assets/yrweathersymbols/46.svg'
import yr09Svg from '../../../assets/yrweathersymbols/09.svg'
import yr40dSvg from '../../../assets/yrweathersymbols/40d.svg'
import yr05dSvg from '../../../assets/yrweathersymbols/05d.svg'
import yr10Svg from '../../../assets/yrweathersymbols/10.svg'
import yr47Svg from '../../../assets/yrweathersymbols/47.svg'
import yr41dSvg from '../../../assets/yrweathersymbols/41d.svg'
import yr49Svg from '../../../assets/yrweathersymbols/49.svg'
import yr13Svg from '../../../assets/yrweathersymbols/13.svg'
import yr50Svg from '../../../assets/yrweathersymbols/50.svg'
import yr12Svg from '../../../assets/yrweathersymbols/12.svg'
import yr48Svg from '../../../assets/yrweathersymbols/48.svg'
import yr44dSvg from '../../../assets/yrweathersymbols/44d.svg'
import yr08dSvg from '../../../assets/yrweathersymbols/08d.svg'
import yr45dSvg from '../../../assets/yrweathersymbols/45d.svg'
import yr15Svg from '../../../assets/yrweathersymbols/15.svg'
import yr01dSvg from '../../../assets/yrweathersymbols/01d.svg'
import yr02dSvg from '../../../assets/yrweathersymbols/02d.svg'
import yr03dSvg from '../../../assets/yrweathersymbols/03d.svg'
import yr04Svg from '../../../assets/yrweathersymbols/04.svg'

export const yrWeatherIconMapping: { [k: number]: string } = {
  200: yr30Svg,
  201: yr11Svg,
  202: yr11Svg,
  210: yr24dSvg,
  211: yr11Svg,
  212: yr11Svg,
  221: yr11Svg,
  230: yr11Svg,
  231: yr11Svg,
  232: yr11Svg,
  300: yr46Svg,
  301: yr46Svg,
  302: yr09Svg,
  310: yr46Svg,
  311: yr46Svg,
  312: yr09Svg,
  313: yr40dSvg,
  314: yr05dSvg,
  321: yr40dSvg,
  500: yr46Svg,
  501: yr09Svg,
  502: yr10Svg,
  503: yr10Svg,
  504: yr10Svg,
  511: yr47Svg,
  520: yr40dSvg,
  521: yr05dSvg,
  522: yr41dSvg,
  531: yr41dSvg,
  600: yr49Svg,
  601: yr13Svg,
  602: yr50Svg,
  611: yr12Svg,
  612: yr48Svg,
  615: yr47Svg,
  616: yr12Svg,
  620: yr44dSvg,
  621: yr08dSvg,
  622: yr45dSvg,
  701: yr15Svg,
  711: yr15Svg,
  721: yr15Svg,
  731: yr15Svg,
  741: yr15Svg,
  751: yr15Svg,
  761: yr15Svg,
  762: yr15Svg,
  771: yr15Svg,
  781: yr15Svg,
  800: yr01dSvg,
  801: yr02dSvg,
  802: yr03dSvg,
  803: yr03dSvg,
  804: yr04Svg,
  900: yr11Svg,
  901: yr11Svg,
  902: yr11Svg,
  903: yr50Svg,
  904: yr01dSvg,
  905: yr11Svg,
  906: yr49Svg,
  951: yr15Svg,
  952: yr15Svg,
  953: yr15Svg,
  954: yr15Svg,
  955: yr15Svg,
  956: yr15Svg,
  957: yr15Svg,
  958: yr15Svg,
  959: yr15Svg,
  960: yr15Svg,
  961: yr15Svg,
  962: yr15Svg
}
