import { IHttpService, IPromise } from 'angular'
import { DEFAULT_TIMEZONE, Environment } from '../../../environments/environment'

import { EnergyCounterAsNumbers } from '../../../types'
import { LanguageSystemdashboard } from '../../language/language.interface'
import { FerroConfiguration, FerroConfigurationName } from '../../service'
import { serviceClb } from '../../service/types.service'
import { SystemDashboardLayout } from './system-dashboard.interfaces'

export type MdbMetaDashboardLayoutCollectionName = 'dashboardlayout'

/**
 * The identification of the dashboard layout that is stored is
 * [[FacilityId]] + ':' + [[UserId]]
 *
 * So when a user enter a facility dashboard we query "Facility"+ "thisUser" to get the dashboard layout.
 *
 */
export type MdbMetaDashboardLayoutReference = string // FacilityId & UserId;

export interface MdbMetaDashboardLayoutSample {
  gridpower: boolean
  loadpower: boolean
  pvpower: boolean
  batpower: boolean
  batsoc: boolean
  lC123: boolean
  connection: boolean
  aceschematic: boolean
  ace: boolean
  pvsummary: boolean
  loadsummary: boolean
  peryeargraph: boolean
  permonthgraph: boolean
  pvstringgraph: boolean
  esomodulegraph: boolean
}

/**
 * This collection lets the user store a specific configuration of dashboard for a specific facility.
 *
 *
 */
export interface MdbMetaDashboardlayout {
  _id: MdbMetaDashboardLayoutReference
  layout: MdbMetaDashboardLayoutSample
}

export interface SystemConfigParsed {
  aceRef: number
  mode: string
  show: boolean
  runMode: {
    mode: string
    _class: string
  }
}

export type EnergySummeriesObject = {
  tot?: { last_e: EnergyCounterAsNumbers }
  mon?: { last_e: EnergyCounterAsNumbers }
  tod?: { last_e: EnergyCounterAsNumbers }
}

export const SystemDashboardServiceName = 'systemDashboardService'

export class SystemDashboardService {
  id = ''
  facilityId: number
  timezone = DEFAULT_TIMEZONE

  canceller: IPromise<unknown>

  http: IHttpService

  l: LanguageSystemdashboard

  constructor(http: IHttpService, ferroConfiguration: FerroConfiguration) {
    this.http = http
    this.l = ferroConfiguration.language.SystemDashboard
  }

  init(facilityId: number, timezone: string, canceller: IPromise<unknown>): void {
    this.facilityId = facilityId
    this.timezone = timezone ? timezone : DEFAULT_TIMEZONE
    this.canceller = canceller
  }

  layout(done: serviceClb<string, SystemDashboardLayout>): void {
    this.http<MdbMetaDashboardlayout>({
      method: 'GET',
      url: Environment.endpoints.fns + '/dashboardlayout',
      params: {
        fid: this.facilityId
      },
      timeout: this.canceller
    }).then(
      response => {
        done(null, response.data.layout as unknown as SystemDashboardLayout)
      },
      response => {
        if (response.status === 404) {
          done(null, null)
        } else {
          done('Error with layout, please contact admin')
        }
      }
    )
  }

  storeLayout(layout: SystemDashboardLayout, done: serviceClb<string, string>): void {
    this.http({
      method: 'POST',
      url: Environment.endpoints.fns + '/dashboardlayout',
      data: {
        fid: this.facilityId,
        id: this.id,
        layout: layout
      },
      timeout: this.canceller
    }).then(
      () => {
        done(null, 'stored')
      },
      response => {
        if (response.status === 403 && response.data === 'Not for Admin users!') {
          done('Not for Admin users!', null)
        } else {
          done('failed', null)
        }
      }
    )
  }
}

SystemDashboardService.$inject = ['$http', FerroConfigurationName]
