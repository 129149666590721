import { svg } from 'd3-fetch'
import { select, Selection } from 'd3-selection'
import { LanguageSystemdashboard } from '../../../app/language/language.interface'
import { LiveDataPowerUiInterface } from '../../../types'
import '../schematic.scss'

import ehubFront from '../../../assets/images/ehub_front.png'
import currentSchemaSvg from './current-schematic-v2.svg'

export interface ExtendedLiveDataUiPowerData extends LiveDataPowerUiInterface {
  loadPower: number
  lq1: number
  lq2: number
  lq3: number

  lqn: number
  eqn: number
  iqn: number
}

const translations = [
  'translate3d(331px,510px,0)',
  'translate3d(271px,510px,0)',
  'translate3d(211px,510px,0)',
  'translate3d(101px,601px,0)',
  'translate3d(101px,661px,0)',
  'translate3d(101px,721px,0)',
  'translate3d(551px,601px,0)',
  'translate3d(551px,661px,0)',
  'translate3d(551px,721px,0)'
]

export type CurrentSchematicData = {
  iq1: number
  iq2: number
  iq3: number
  eq1: number
  eq2: number
  eq3: number
  lq1: number
  lq2: number
  lq3: number
  iqn: number
  eqn: number
  lqn: number
}

function fixData(d: number) {
  let value: number | string = Math.round(10 * d) / 10
  value = value % 1 === 0 ? `${value}.0` : value
  return `${value} A`
}

export class CurrentSchematicService {
  id: string
  url = currentSchemaSvg
  ready = false
  svg: Selection<HTMLElement, unknown, HTMLElement, unknown>
  xml: HTMLElement

  MainFuse: Selection<SVGTextElement, unknown, HTMLElement, unknown>

  private i1: SVGTextElement
  private i2: SVGTextElement
  private i3: SVGTextElement
  private in: SVGTextElement
  private l1: SVGTextElement
  private l2: SVGTextElement
  private l3: SVGTextElement
  private ln: SVGTextElement
  private e1: SVGTextElement
  private e2: SVGTextElement
  private e3: SVGTextElement
  private en: SVGTextElement

  // eslint-disable-next-line
  arrowCache: any = {}
  arrows: SVGPolygonElement[]

  constructor(id: string) {
    this.id = id

    svg(this.url)
      .then(svg => {
        this.xml = svg.documentElement
        this.addSchematic()
      })
      .catch()
  }

  private addSchematic() {
    const id = document.getElementById(this.id)
    if (id) {
      this.svg = select(id.appendChild(this.xml))
      this.svg.select('#ehubFront').attr('xlink:href', ehubFront)
      this.setSvgs()
    } else {
      setTimeout(() => {
        this.addSchematic()
      }, 300)
    }
  }

  private setSvgs() {
    this.arrows = this.svg
      .select('#arrows-layer')
      .selectAll<SVGPolygonElement, HTMLElement>('polygon')
      .nodes()
    // this.aceActiveText = d3.select('#aceSchematic #ACEActiveText');
    const texts = <SVGTextElement[]>(
      this.svg.select('#text-layer').selectAll<SVGTextElement, HTMLElement>('text').nodes()
    )
    this.MainFuse = this.svg.select<SVGTextElement>('#mainFuse').text('set mainfuse')

    // TEXT BOXES
    this.i1 = texts[0]
    this.i2 = texts[1]
    this.i3 = texts[2]
    this.in = texts[3]
    this.e1 = texts[4]
    this.e2 = texts[5]
    this.e3 = texts[6]
    this.en = texts[7]
    this.l1 = texts[8]
    this.l2 = texts[9]
    this.l3 = texts[10]
    this.ln = texts[11]
    this.ready = true
  }

  public update(data: LiveDataPowerUiInterface): void {
    if (!this.ready) {
      return
    }

    if (Math.abs(data.eq1) < 0.3) {
      data.eq1 = 0
    }
    if (Math.abs(data.eq2) < 0.3) {
      data.eq2 = 0
    }
    if (Math.abs(data.eq3) < 0.3) {
      data.eq3 = 0
    }

    this.i1.textContent = fixData(data.iq1)
    this.i2.textContent = fixData(data.iq2)
    this.i3.textContent = fixData(data.iq3)
    this.in.textContent = fixData(data.iqn)
    this.e1.textContent = fixData(data.eq1)
    this.e2.textContent = fixData(data.eq2)
    this.e3.textContent = fixData(data.eq3)
    this.en.textContent = fixData(data.eqn)
    this.l1.textContent = fixData(data.lq1)
    this.l2.textContent = fixData(data.lq2)
    this.l3.textContent = fixData(data.lq3)
    this.ln.textContent = fixData(data.lqn)
    this.rotateArrow(data)
  }

  public setLanguage(language: LanguageSystemdashboard): void {
    if (this.ready) {
      this.svg.select('#mainFuseText').text(language.MAIN_FUSE)
      this.svg.select('#gridTitle').text(language.GRID)
      this.svg.select('#consumptionTitle').text(language.CONSUMPTION)
    } else {
      setTimeout(() => {
        this.setLanguage(language)
      }, 500)
    }
  }

  private rotateArrow(data: LiveDataPowerUiInterface): void {
    this.arrows.forEach((polygon: SVGPolygonElement, index: number) => {
      const id = polygon.id.replace('a', 'q')

      let val = +data[id]
      if (index < 4 && val < 0.3 && val > 0.3) {
        val = 0
      }

      let cache = this.arrowCache[index]
      if (isNaN(cache)) cache = 0

      if (val > 0 && cache <= 0) {
        polygon.style.visibility = 'visible'
        polygon.style.transform = `${translations[index]}rotate(180deg)`
      } else if (val === 0 && cache !== 0) {
        polygon.style.visibility = 'hidden'
      } else if (val < 0 && cache >= 0) {
        polygon.style.transform = ''
        polygon.style.visibility = 'visible'
      } else if (isNaN(val as number)) {
        polygon.style.transform = ''
        polygon.style.visibility = 'hidden'
      }

      this.arrowCache[index] = val
    })
  }

  public setMainFuse(mainFuse: number | string): void {
    if (this.ready) {
      this.MainFuse.text(`${mainFuse} A`)
    } else {
      setTimeout(() => {
        this.setMainFuse(mainFuse)
      }, 500)
    }
  }
}
