import { IPromise } from 'angular'
import { UidataMonitorDataResult, UiDataQueryTypes } from '../../../../types'
import {
  PowerDataGraphqlService,
  PowerDataGraphqlServiceName
} from '../../../graphql/power-data-graphql.service'
import { UidataGraphqlService, UidataGraphqlServiceName } from '../../../graphql/uidata-graphql.service'
import { LanguageViews } from '../../../language/language.interface'
import { FerroConfiguration, FerroConfigurationName } from '../../../service'
import {
  POWER_DATA_TYPE,
  processDataQuery,
  processedDataQuery,
  processMainAggregateDataType
} from './power-view.tools'

export const PowerViewServiceName = 'powerViewService'

export class PowerViewService {
  canceller: IPromise<unknown>
  facilityId: number

  uiDataGraphQL: UidataGraphqlService
  pdGraphQl: PowerDataGraphqlService
  l: LanguageViews

  constructor(
    ferroConfiguration: FerroConfiguration,
    powerDataGraphQlService: PowerDataGraphqlService,
    uidataGraphQlService: UidataGraphqlService
  ) {
    this.pdGraphQl = powerDataGraphQlService
    this.uiDataGraphQL = uidataGraphQlService
    this.l = ferroConfiguration.language.Views
  }

  init(facilityId: number, canceller: IPromise<unknown>): void {
    this.facilityId = facilityId
    this.canceller = canceller
  }

  async getUiData(starttime: Date, endtime: Date, type: UiDataQueryTypes): Promise<processedDataQuery> {
    const result = await this.uiDataGraphQL.getQueryData(starttime, endtime, type, this.facilityId)

    return processDataQuery(result as unknown as UidataMonitorDataResult, type, this.l)
  }

  async getAggregateData(
    starttime: Date,
    endtime: Date,
    resolution: number
  ): Promise<processMainAggregateDataType> {
    const data = (await this.pdGraphQl.getPowerData1m(starttime, endtime, this.facilityId)) as POWER_DATA_TYPE

    return {
      data,
      startDate: starttime,
      endDate: endtime,
      resolution: resolution
    }
  }

  async getPowerData1Second(starttime: Date, endtime: Date): Promise<processMainAggregateDataType> {
    const data = await this.pdGraphQl.getPowerData(starttime, endtime, this.facilityId)
    return {
      data,
      startDate: starttime,
      endDate: endtime,
      resolution: 1
    }
  }
}

PowerViewService.$inject = [FerroConfigurationName, PowerDataGraphqlServiceName, UidataGraphqlServiceName]
