import { Environment } from '../../environments/environment'
import { SocketioService } from './socketio.service'

import { ILocationService } from 'angular'

import {
  DestructedApplicationInstanceId,
  EmsConfCommandTypes,
  EmsConfNonMeasureDataObject,
  EmsParam,
  SystemConfigurationEventTypes
} from '@ferroamp/system-configuration-lib'

/**
 * @TODO SOMEHOW ERROR OCCURRED IF I IMPORTED THIS from system-configuration-lib
 */
export enum SystemConfigurationEventTypesEnum {
  SUCCEEDED = 'succeeded',
  DONE = 'done',
  FAILED = 'failed',
  SEND = 'send',
  STATECHANGED = 'statechanged',
  EMSCONFCHANGED = 'emsconfchanged'
}

export interface EmsConfigWebsocketCommandEvents {
  eventType: SystemConfigurationEventTypes
  command: EmsConfCommandTypes
  data: EmsParam | EmsConfNonMeasureDataObject
  applicationInstanceId: DestructedApplicationInstanceId
  transTs: Date
  facilityId: number
}

export interface EmsConfigWebsocketEmsConfChangedEvent {
  eventType: SystemConfigurationEventTypes
  command: EmsConfCommandTypes
  data: EmsParam
  applicationInstanceId: DestructedApplicationInstanceId
  transTs: Date
  facilityId: number
}

export const EmsConfigV2IoServiceName = 'emsConfigV2IoService'

const options = Environment.emsConfigV2Options

export type emsConfChangedObserver = (event: EmsConfigWebsocketEmsConfChangedEvent) => void
export type emsConfCommandObserver = (event: EmsConfigWebsocketCommandEvents) => void

export class EmsConfigV2IoService {
  private service: SocketioService
  private facilityId: number

  constructor($location: ILocationService) {
    this.service = new SocketioService($location)
  }

  init(
    facilityId: number,
    emsConfChangedObserver: emsConfChangedObserver,
    commandObserver: emsConfCommandObserver,
    onConnected: () => void
  ): void {
    this.facilityId = facilityId
    this.service.on('connect', () => {
      onConnected()
    })
    this.service.connect(options.wsNamespace, options.wsPath, {
      facilityId: facilityId
    })
    this.service.listen<EmsConfigWebsocketCommandEvents>(options.commandEvent, commandObserver)
    this.service.listen<EmsConfigWebsocketEmsConfChangedEvent>(
      options.emsConfChangedEvent,
      emsConfChangedObserver
    )
  }

  disconnect(): void {
    this.service.disconnect()
  }
}

EmsConfigV2IoService.$inject = ['$location', '$cookies']
