import { IHttpResponse, IHttpService, IPromise } from 'angular'
import { ElectricalComponentColors, FerroConfiguration, FerroConfigurationName } from '../../../service'
import {
  AnalysisHttpService,
  AnalysisHttpServiceName,
  CreateLoadEventResponse,
  ParsedStoredAnalysis,
  PendingData
} from '../analysis-http.service'
import { MdbAnalysisResultsId } from '../@types/load-event-detection'
import { serviceClb } from '../../../service/types.service'
import { ParsedLoadSignatureData, parseLoadSignatureData } from './load-signature.tools'
import { LanguageAnalaysis } from '../../../language/language.interface'

export const LoadSignatureServiceName = 'loadSignatureService'

export class LoadSignatureService {
  facilityId: number
  canceller: IPromise<unknown>
  id: string

  aService: AnalysisHttpService
  $http: IHttpService

  l: LanguageAnalaysis

  colors: ElectricalComponentColors

  constructor(ferroConfiguration: FerroConfiguration, analysisService: AnalysisHttpService) {
    this.aService = analysisService
    this.colors = ferroConfiguration.electricColors
  }

  init(
    id: string,
    facilityId: number,
    canceller: IPromise<unknown>,
    l: LanguageAnalaysis,
    callback: serviceClb<string, ParsedStoredAnalysis[]>
  ): void {
    this.id = id
    this.l = l
    this.facilityId = facilityId
    this.canceller = canceller
    this.aService.init(canceller)
    this.aService.getStoredLoadEventDetection(facilityId, callback)
  }

  createJob(
    starttime: Date,
    endtime: Date,
    callback: serviceClb<IHttpResponse<string>, CreateLoadEventResponse>
  ): void {
    this.aService.createJob(starttime, endtime, this.facilityId, callback)
  }

  getJob(
    resultsid: MdbAnalysisResultsId,
    callback: serviceClb<IHttpResponse<string> | PendingData, ParsedLoadSignatureData>
  ): void {
    this.aService.getLoadEventJob(resultsid, this.facilityId, 'signature', (error, data) => {
      if (error) {
        callback(error)
      } else {
        callback(null, parseLoadSignatureData(data, this.colors, this.l))
      }
    })
  }
}

LoadSignatureService.$inject = [FerroConfigurationName, AnalysisHttpServiceName]
