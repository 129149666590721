import { IHttpService } from 'angular'

import { FerroConfiguration } from './ferro-configuration.service'
import { LanguageService } from './language.service'

const swedish = {
  title: 'Uppdatering av använderavtal',
  text:
    '<p>I och med de nya lagändringar (Läs mer om GDPR på <a target="_blank"  href="https://www.datainspektionen.se/lagar--regler/dataskyddsforordningen/">' +
    'datainspektionen</a>) ser vi över våra användarvillkor.' +
    ' Du hittar vårt användaravtal här: <a href="/terms-and-conditions/user-agreement" target="_blank">Användaravtal</a>.' +
    '</p><p> Vi ber dig att acceptera användaravtalet. För att fortsätta använda denna ' +
    ' tjänsten (Portalen) krävs att du accepterar avtalet.' +
    ' För mer information kontakta Ferroamp (mail <a href="mailto:support@ferroamp.se">support@ferroamp.se</a> eller telefon:<a href="tel:+46868433390">+46 (0)8 684 333 90</a></p>',
  accept: 'Acceptera'
}
const english = {
  title: "Update of Ferroamp's user agreement",
  text:
    '<p>We have updated our user agreement with new information. <a href="/terms-and-conditions/user-agreement" target="_blank">Read the new user agreement here</a> . Please accept the new changes before continue.' +
    ' It is required to accept the agreement to be able to use this service (The Portal).</p> <p>For information ' +
    'please contact Ferroamp, by mail:  <a href="mailto:support@ferroamp.se">support@ferroamp.se</a> or phone:<a href="tel:+46868433390">+46 (0)8 684 333 90</a>',
  accept: 'I accept'
}

export const GdprServiceName = 'gdprService'

export class GdprService {
  options: unknown

  private $http: IHttpService
  private ferroConfiguration: FerroConfiguration
  private languageServices: LanguageService

  constructor($http: IHttpService, ferroConfiguration: FerroConfiguration, languageService: LanguageService) {
    this.$http = $http
    this.ferroConfiguration = ferroConfiguration
    this.languageServices = languageService
  }

  public run(): void {
    // this.getStatus();
  }

  private confirm(): void {
    let lang: { title: string; text: string; accept: string }
    if (this.languageServices.language === 'se') {
      lang = swedish
    } else {
      lang = english
    }

    this.ferroConfiguration
      .confirm(lang.title, lang.text, lang.accept, null, null, document.body)
      .then(() => {
        this.status(1)
      })
  }

  private status(status: number): void {
    this.$http({
      method: 'POST',
      url: `/user/confirmation/gdpr?status=${status}`
    }).then()
  }

  getStatus(): void {
    this.$http({
      method: 'GET',
      url: '/user/confirmation/gdpr'
    }).then(
      () => {
        // nothing to do here, they are confirmed
      },
      () => {
        this.confirm()
      }
    )
  }
}

GdprService.$inject = ['$http', 'ferroConfiguration', 'languageService']
