import { PartialEnergyCounters } from '@app/graphql/graphql-energycounters.service'
import * as moment from 'moment-timezone'
import { roundTo2Decimals } from '../../../../lib'
import { dailyTrendDataVizualizationType } from './daily-trend.service'

export type MaximumDataArraysObject = { val: number; ts: Date }

export interface CalculatedMaximumResult {
  aicA: MaximumDataArraysObject[]
  aic1A: MaximumDataArraysObject[]
  aic2A: MaximumDataArraysObject[]
  aic3A: MaximumDataArraysObject[]
}

type DataFormat = { ts: Date; val: number }

export interface PowerLevelData {
  hours: number[]
  power: number[]
}

export interface CountPowerLevelsResult {
  data: PowerLevelData
  max: number
}

function get10HighestMaxValues(maxValues: CalculatedMaximumResult): CalculatedMaximumResult {
  let aicA = maxValues.aicA
  let aic1A = maxValues.aic1A
  let aic2A = maxValues.aic2A
  let aic3A = maxValues.aic3A

  function _sortValues(a: DataFormat, b: DataFormat) {
    return b.val - a.val
  }

  aicA = aicA.sort(_sortValues).slice(0, 10)
  aic1A = aic1A.sort(_sortValues).slice(0, 10)
  aic2A = aic2A.sort(_sortValues).slice(0, 10)
  aic3A = aic3A.sort(_sortValues).slice(0, 10)
  return {
    aicA: aicA,
    aic1A: aic1A,
    aic2A: aic2A,
    aic3A: aic3A
  }
}

function parsePowerLevelsAccumulated(powerLevelsAccumulated: { [power: number]: number }): PowerLevelData {
  const powerLevels: PowerLevelData = {
    hours: [],
    power: []
  }
  Object.keys(powerLevelsAccumulated).forEach(pow => {
    const power = Number(pow)
    const hours = powerLevelsAccumulated[power]
    powerLevels.power.push(power)
    powerLevels.hours.push(hours)
  })
  return powerLevels
}

export function _maxMinGet(values: number[]): {
  min: number
  avg: number
  max: number
} {
  let max = -Infinity
  let min = Infinity

  if (values && values.length) {
    const avg = _average(values)
    values.forEach(d => {
      if (d > max) {
        max = d
      }
      if (d < min) {
        min = d
      }
    })
    return { min, avg, max }
  }
  return { min: null, avg: null, max: null }
}

/**
 *
 * @param adata
 * @return {number}
 */
export function _average(adata: number[]): number {
  const sum = adata.reduce((sum, value) => {
    return sum + value
  }, 0)

  return roundTo2Decimals(sum / adata.length)
}

export function _standardDeviation(values: number[]): number {
  const avg = _average(values)

  const squareDiffs = values.map(value => {
    const diff = value - avg
    return diff * diff
  })

  const avgSquareDiff = _average(squareDiffs)

  return roundTo2Decimals(Math.sqrt(avgSquareDiff))
}

export interface DailyTrendFixedMainData {
  hours: number[]
  weekEnds: number[]
  weekEndsErrorData: number[][]
  weekDays: number[]
  weekDaysErrorData: number[][]
}

export interface DailyTrendPhaseData {
  hours: number[]
  phase1: number[]
  phase2: number[]
  phase3: number[]
}

export interface DailyTrendFixedData {
  powerLevels: PowerLevelData
  main: DailyTrendFixedMainData
  weekDayPerPhase: DailyTrendPhaseData
  weekEndPerPhase: DailyTrendPhaseData
  maxData: CalculatedMaximumResult
}

/**
 * Input is EnergyCounteresAsNumber which has unit of wH. We want to scale this data into kW.
 *
 * Which means that we have to divid by 1000 to get "kilo" and also divide by 1 hour to get "watts".
 *
 *
 * In the function we calculate 3 things ( just no to have same loop three times),
 * * powerLevels (hours/kW)
 * * maxValues ( 10 highest mean power)
 * * DailyTrend (24 hours mean values)
 *
 *
 * Below "wD" stands for WeekDays
 * "wE" stand for weekEnds
 */
export function _dailyTrendDataFix(
  data: PartialEnergyCounters[],
  type: dailyTrendDataVizualizationType,
  timezone: string
): DailyTrendFixedData {
  const weekDays = []
  const weekEnds = []
  const wDL1 = []
  const wDL2 = []
  const wDL3 = []
  const wEL1 = []
  const wEL2 = []
  const wEL3 = []
  for (let i = 0; i < 24; i++) {
    weekDays.push([])
    weekEnds.push([])
    wDL1.push([])
    wDL2.push([])
    wDL3.push([])
    wEL1.push([])
    wEL2.push([])
    wEL3.push([])
  }
  /**
   *
   *
   * Hashmap :
   * { Power : #hours }
   *  to be used for creating PowerLevels in form
   *  {
   *      hours: [],
   *      power: []
   *  }
   *  By doing object.keys.forEach.
   */
  const powerLevelsAccumulated: { [_: number]: number } = {}

  const maxData: CalculatedMaximumResult = {
    aicA: [],
    aic1A: [],
    aic2A: [],
    aic3A: []
  }

  for (let k = 0; k < data.length; k++) {
    const current = data[k]
    /**
     * Data is delivered as relative energy/hour. And we want the data in averagePower/hour which
     * means that we have to divide by one ~hour ( <- timeDiff between samples). The data has unit [wH] we want to scale it to [kW], dividing by 1000
     *
     * current.timeDiff is in hour-[ms]. Example timeDiff=3600000.
     * By dividing by 3600 we get 3600000/3600 = 1000 = divider. So `averagePower/divider` [w]/1000 -> [kW]
     */
    if (current.ts && current) {
      const divider = (current.timeDiff as number) / 3600
      const ts = new Date(current.ts)
      const averagePowerPhase1 = (current.lc1 as number) / divider
      const averagePowerPhase2 = (current.lc2 as number) / divider
      const averagePowerPhase3 = (current.lc3 as number) / divider
      let averageTotalPower: number = null
      if (averagePowerPhase1 && averagePowerPhase2 && averagePowerPhase3) {
        averageTotalPower = averagePowerPhase1 + averagePowerPhase2 + averagePowerPhase3
      }
      const hour = moment.tz(ts, timezone).hours()
      const day = moment.tz(ts, timezone).day()

      // Populated max data lists
      maxData.aicA.push({
        val: averageTotalPower,
        ts: ts
      })
      maxData.aic1A.push({
        val: averagePowerPhase1,
        ts: ts
      })
      maxData.aic2A.push({
        val: averagePowerPhase2,
        ts: ts
      })
      maxData.aic3A.push({
        val: averagePowerPhase3,
        ts: ts
      })

      //
      const roundedAverageTotalPower = Math.round(averageTotalPower)
      if (powerLevelsAccumulated[roundedAverageTotalPower]) {
        powerLevelsAccumulated[roundedAverageTotalPower] += 1
      } else {
        powerLevelsAccumulated[roundedAverageTotalPower] = 1
      }

      if (averageTotalPower) {
        if (day === 1 || day === 2 || day === 3 || day === 4 || day === 5) {
          weekDays[hour].push(averageTotalPower)
          wDL1[hour].push(averagePowerPhase1)
          wDL2[hour].push(averagePowerPhase2)
          wDL3[hour].push(averagePowerPhase3)
        } else {
          weekEnds[hour].push(averageTotalPower)
          wEL1[hour].push(averagePowerPhase1)
          wEL2[hour].push(averagePowerPhase2)
          wEL3[hour].push(averagePowerPhase3)
        }
      }
    }
  }

  const PlotArray: DailyTrendFixedMainData = {
    hours: [],
    weekDays: [],
    weekDaysErrorData: [],
    weekEnds: [],
    weekEndsErrorData: []
  }
  const wDLPArray: DailyTrendPhaseData = {
    hours: [],
    phase1: [],
    phase2: [],
    phase3: []
  }
  const wELPArray: DailyTrendPhaseData = {
    hours: [],
    phase1: [],
    phase2: [],
    phase3: []
  }

  for (let k = 0; k < 24; k++) {
    let wD // wD --> WeekDays
    let wDe // wD e --> WeekDays Errorbars (STD or MinMax)
    let wE // wE --> WeekEnds
    let wEe

    const wDL1H = _average(wDL1[k])
    const wDL2H = _average(wDL2[k])
    const wDL3H = _average(wDL3[k])
    const wEL1H = _average(wEL1[k])
    const wEL2H = _average(wEL2[k])
    const wEL3H = _average(wEL3[k])
    if (type === 'std') {
      wD = _average(weekDays[k])
      const stdD = _standardDeviation(weekDays[k])
      wDe = [k, wD - stdD, wD + stdD]
      wE = _average(weekEnds[k])
      const stdE = _standardDeviation(weekEnds[k])
      wEe = [k, wE - stdE, wE + stdE]
    } else if (type === 'maxmin') {
      const wdd = _maxMinGet(weekDays[k])
      wD = wdd.avg
      wDe = [k, wdd.min, wdd.max]
      const weeke = _maxMinGet(weekEnds[k])
      wE = weeke.avg
      wEe = [k, weeke.min, weeke.max]
    }
    PlotArray.hours.push(k)
    PlotArray.weekEnds.push(wE)
    PlotArray.weekEndsErrorData.push(wEe)
    PlotArray.weekDays.push(wD)
    PlotArray.weekDaysErrorData.push(wDe)
    wDLPArray.hours.push(k)
    wDLPArray.phase1.push(wDL1H)
    wDLPArray.phase2.push(wDL2H)
    wDLPArray.phase3.push(wDL3H)
    wELPArray.hours.push(k)
    wELPArray.phase1.push(wEL1H)
    wELPArray.phase2.push(wEL2H)
    wELPArray.phase3.push(wEL3H)
  }

  return {
    powerLevels: parsePowerLevelsAccumulated(powerLevelsAccumulated),
    maxData: get10HighestMaxValues(maxData),
    main: PlotArray,
    weekDayPerPhase: wDLPArray,
    weekEndPerPhase: wELPArray
  }
}
