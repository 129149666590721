import { AlarmCode, AlarmSubscriber, FacilityUser, SubscriptionType } from '@app/graphql/generated'
import { LanguageAlarms } from '@app/language/language.interface'
import { AmplitudeService, FerroConfiguration, FerroConfigurationName } from '@app/service'
import { AmplitudeServiceName } from '@app/service/amplitude.service'
import { RolesServices, RolesServicesName } from '@app/service/roles.services'
import { validateEmail } from '@lib/general'
import { IComponentOptions, IScope, element, material } from 'angular'
import { addAlarmSubscriber, getAlarmSubscriptions, removeAlarmSubscriber } from './alarm-data'
import templateUrl from './alarms-add-user.component.html'
import './alarms-add-user.component.scss'
import { translateCode, translateSubscribeCode } from './alarms.lib'

export class AlarmsAddUserController {
  l: LanguageAlarms
  $scope: IScope
  translateCode = translateCode
  translateSubscribeCode = translateSubscribeCode
  $md: material.IDialogService
  existingSubscriptions: AlarmSubscriber[] = []
  existingSubscriptionsToEverything: AlarmSubscriber[] = []
  facilityId: number
  facilityUsers: FacilityUser[] = []
  error: string | null
  loading = true
  searchText = ''
  selectedItem: FacilityUser | null = null
  amplitudeService: AmplitudeService

  subscribeToAlarmCode: AlarmCode
  notAllowed: boolean
  constructor(
    $scope: IScope,
    $mdDialog: material.IDialogService,
    ferroConfiguration: FerroConfiguration,
    amplitudeService: AmplitudeService,
    roles: RolesServices
  ) {
    this.l = ferroConfiguration.language.Alarms
    this.$scope = $scope
    this.$md = $mdDialog
    this.facilityId = Number(ferroConfiguration.facility.id)
    this.amplitudeService = amplitudeService
    amplitudeService.logEvent('Open Subscriptions', {
      alarmCode: this.subscribeToAlarmCode
    })
    this.notAllowed = !roles.canUpdateFacilitySystemSettings()
  }

  $onInit(): void {
    this.loading = true
    getAlarmSubscriptions(this.facilityId, this.subscribeToAlarmCode)
      .then(alarm => {
        this.existingSubscriptionsToEverything = alarm.data.facility.alarm.subscribedToAll.filter(
          a => a.code == AlarmCode.All
        ) as AlarmSubscriber[]

        this.existingSubscriptions = alarm.data.facility.alarm.subscribers.filter(
          a => a.code == this.subscribeToAlarmCode
        ) as AlarmSubscriber[]

        this.facilityUsers = alarm.data.facility.users as unknown as FacilityUser[]
      })
      .catch(err => {
        this.facilityUsers = []
        this.existingSubscriptions = []
        this.existingSubscriptionsToEverything = []
        throw err
      })
      .finally(() => this.applyAsync(false))
  }

  removeUser(who: AlarmSubscriber) {
    this.loading = true
    removeAlarmSubscriber(who.id)
      .then(res => {
        if (res.data.removeAlarmSubscriber.success) {
          this.existingSubscriptions = this.existingSubscriptions.filter(sub => sub.id != who.id)
        }

        this.$scope.$applyAsync(() => {
          this.amplitudeService.logEvent('Remove Subscription', {
            sendWith: who.sendWith,
            alarmCode: this.subscribeToAlarmCode
          })
        })
      })
      .finally(() => (this.loading = false))
  }

  addUser(selectedUser?: FacilityUser, email?: string) {
    this.error = null

    if (this.existingSubscriptionsToEverything.map(this.getUserRepresentation.bind(this)).includes(email)) {
      this.error = this.l.USER_ALREADY_ADDED_TO_ALL_ALARMS
      return
    }
    if (this.existingSubscriptions.map(this.getUserRepresentation.bind(this)).includes(email)) {
      this.error = this.l.USER_ALREADY_ADDED
      return
    }

    const userId = selectedUser?.id || null
    if (!email && !userId) return
    if (email && !validateEmail(email)) {
      this.error = this.l.NOT_VALID_EMAIL
      return
    }

    const { to, sendWith } = userId
      ? { to: userId.toString(), sendWith: SubscriptionType.UserEmail }
      : { to: email, sendWith: SubscriptionType.Email }

    this.loading = true
    addAlarmSubscriber(this.facilityId, this.subscribeToAlarmCode, to, sendWith)
      .then(res => {
        this.$scope.$applyAsync(() => {
          this.searchText = ''
          this.selectedItem = null

          if (res.data.addAlarmSubscriber.success) {
            this.existingSubscriptions.push(res.data.addAlarmSubscriber.alarmSubscriber as AlarmSubscriber)
          }

          this.amplitudeService.logEvent('Add Subscription', {
            sendWith,
            alarmCode: this.subscribeToAlarmCode
          })
        })
      })
      .finally(() => this.applyAsync(false))
  }

  querySearch(query: string) {
    const existingUsersIds = this.existingSubscriptions
      .filter(s => s.sendWith === SubscriptionType.UserEmail)
      .map(s => s.to)

    const matchesUser = ({ email, name }: FacilityUser) => {
      return (
        email.toLocaleLowerCase().includes(query.toLowerCase()) ||
        name.toLocaleLowerCase().includes(query.toLowerCase())
      )
    }

    return this.facilityUsers.filter(u => !existingUsersIds.includes(u.id)).filter(matchesUser)
  }

  selectedItemChange() {
    this.error = null
  }

  applyAsync(loading: boolean) {
    this.$scope.$applyAsync(() => {
      this.loading = loading
    })
  }

  getUserRepresentation(subscription: AlarmSubscriber) {
    return subscription.displayName
  }

  maybeSubmit(keyEvent: any) {
    const ENTER_KEY = 13
    if (keyEvent.which === ENTER_KEY) {
      this.addUser(this.selectedItem, this.searchText)
    }
  }

  close() {
    this.$md.cancel()
  }
}

AlarmsAddUserController.$inject = [FerroConfigurationName, '$scope', RolesServicesName]

export const AlarmsAddUserComponentName = 'alarmsAddUserComponent'
export const AlarmsAddUserComponent: IComponentOptions = {
  controller: AlarmsAddUserController,
  templateUrl
}

export const SubscriptionView = (
  $event: any,
  $mdDialog: material.IDialogService,
  subscribeToAlarmCode: AlarmCode
) => {
  $mdDialog.show({
    controller: [
      '$scope',
      '$mdDialog',
      'ferroConfiguration',
      AmplitudeServiceName,
      RolesServicesName,
      AlarmsAddUserController
    ],
    locals: {
      subscribeToAlarmCode
    },
    bindToController: true,
    controllerAs: 'addUserCtrl',
    templateUrl: templateUrl,
    parent: element(document.body),
    targetEvent: $event,
    clickOutsideToClose: true,
    fullscreen: true
  })
}
