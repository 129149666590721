import { ObservableSubscription } from '@apollo/client'
import { EvseMeterValue, UiFlowChartComponent } from '@app/graphql/generated'
import { runQuery } from '@app/graphql/graphql'
import { IComponentOptions } from 'angular'
import { PowerSchematicEvseService, PowerSchematicService } from '../../../../lib/schematics'
import { LiveDataPowerUiInterface } from '../../../../types'
import { GraphqlDashboardService, GraphqlDashboardServiceName } from '../../../graphql'
import { LanguageSystemdashboard } from '../../../language/language.interface'
import {
  FerroConfiguration,
  FerroConfigurationName,
  LivedataService,
  LiveDataServiceName
} from '../../../service'
import templateUrl from './power-flow.component.html'

class PowerFlowController {
  l: LanguageSystemdashboard
  subscription: any

  powerFlow: PowerSchematicService | PowerSchematicEvseService
  fc: FerroConfiguration
  graphqlDashboardService: GraphqlDashboardService

  legacyLiveData: LivedataService

  private powerDataSubscription: ObservableSubscription

  constructor(
    ferroConfiguration: FerroConfiguration,
    livedata: LivedataService,
    graphqlDashboardService: GraphqlDashboardService
  ) {
    this.legacyLiveData = livedata
    this.l = ferroConfiguration.language.SystemDashboard
    this.graphqlDashboardService = graphqlDashboardService
    this.fc = ferroConfiguration
  }

  $onInit(): void {
    const powerData = runQuery.onPowerDataSubscription({
      variables: {
        facilityId: Number(this.fc.facility.id).toString()
      }
    })
    this.powerDataSubscription = powerData.subscribe(
      ({ data }) => {
        const updateData = {
          pvPower: data.newPowerData.pvPower,
          batPower: data.newPowerData.batteryPower,
          loadPower: data.newPowerData.loadPower,
          gridPower: data.newPowerData.gridPower,
          ehubPower: data.newPowerData.ehubPower
        }

        if (updateData.batPower == null) delete updateData.batPower
        if (updateData.pvPower == null) delete updateData.pvPower

        this.update(updateData as LiveDataPowerUiInterface)
      },
      _error => {}
    )

    runQuery
      .getUiComponentsForPortalQuery({
        variables: { facilityId: Number(this.fc.facility.id) }
      })
      .then(({ data }) => {
        const withEvse = data.facility.uiComponents.flowChart.enabledComponents.includes(
          UiFlowChartComponent.Evse
        )

        if (withEvse) {
          this.withEsve(data.facility.uiComponents.flowChart.enabledComponents)
        } else {
          this.withoutEsve()
        }
      })
  }

  $onDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe()
    if (this.powerDataSubscription) this.powerDataSubscription.unsubscribe()
  }

  update(data: LiveDataPowerUiInterface): void {
    if (this.powerFlow) {
      this.powerFlow.update({
        pvPower: data.pvPower,
        batPower: data.batPower,
        loadPower: data.loadPower,
        gridPower: data.gridPower,
        ehubPower: data.ehubPower
      })
    }
  }

  withoutEsve(): void {
    this.powerFlow = new PowerSchematicService('powerSchematicId', this.l)
  }

  withEsve(enabledComponents: UiFlowChartComponent[]): void {
    this.powerFlow = new PowerSchematicEvseService('powerSchematicId', this.l, enabledComponents)
    setTimeout(() => {
      document.getElementById('powerSchematicId').style.margin = '10px'
    }, 100)
    this.activateEvse()
  }

  activateEvse(): void {
    const observer = runQuery.onEvseMetervalueSubscription({
      variables: { facilityId: Number(this.fc.facility.id).toString() }
    })

    observer.subscribe(({ data }) => {
      ;(this.powerFlow as PowerSchematicEvseService).onEsve(data.newEvseMeterValue as EvseMeterValue)
    })
  }

  setBattery(stateOfCharge?: number, ratedCapacity?: number): void {
    if (stateOfCharge && ratedCapacity) {
      setTimeout(() => {
        this.powerFlow.updateBatteryStatus({
          soc: stateOfCharge,
          RC: ratedCapacity
        })
      }, 1500)
    }
  }
}

PowerFlowController.$inject = [FerroConfigurationName, LiveDataServiceName, GraphqlDashboardServiceName]

export const PowerFlowComponent: IComponentOptions = {
  templateUrl,
  controller: PowerFlowController
}
