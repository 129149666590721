import * as angular from 'angular'
import { AVAILABLE_EXTERNAL_MODULES } from '../../environments/environment'
import { httpConfig } from './http-correlation-id-interceptor'
import { localeConfig } from './locale.config'
import { logLevelConfig } from './log-level.config'
import { materialConfig } from './material-config'
import { themePaletteConfig } from './theme.palette'

export const AppConfig = angular
  .module('AppConfig', [AVAILABLE_EXTERNAL_MODULES.ngMaterial, AVAILABLE_EXTERNAL_MODULES.uiRouter])
  .config(httpConfig)
  .config(logLevelConfig)
  .config(themePaletteConfig)
  .config(materialConfig)
  .config(localeConfig).name
