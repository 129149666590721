import * as angular from 'angular'
import { AVAILABLE_EXTERNAL_MODULES } from '../../environments/environment'
import { AppServiceModule } from '../service'
import { AlarmImpactComponent } from './alarm-impact/alarm-impact.component'
import { BetaLogo } from './beta-logo/beta-logo'
import { DashboardBlockComponent } from './dashboard-block/dashboard-block.component'
import { FerroImageComponent } from './ferro-image/ferro-image.component'
import { HeaderComponent } from './header/header.component'
import { IncidentAlertComponent } from './incident-alert/incident-alert.component'
import { InfoBoxComponent } from './info-box/info-box.component'
import { NotificationService } from './notifications/notifications'
import { PlannedMaintenanceComponent } from './planned-maintenance/planned-maintenance.component'
import { TimePickerComponent } from './time-picker/time-picker.component'
import { ViewBoxComponent } from './view-box/view-box.component'
import { ViewControllerSidenavComponent } from './view-controller-sidenav/view-controller-sidenav.component'
import { ViewHeaderComponent } from './view-header/view-header.component'

export { BetaLogo, NotificationService, TimePickerComponent }

export const AppComponentModule = angular
  .module('app.components', [
    AVAILABLE_EXTERNAL_MODULES.ngMaterial,
    AppServiceModule,
    AVAILABLE_EXTERNAL_MODULES.uiRouter
  ])
  .component('headerComponent', HeaderComponent)
  .component('viewControlSidenavComponent', ViewControllerSidenavComponent)
  .component('viewHeader', ViewHeaderComponent)
  .component('infoBox', InfoBoxComponent)
  .component('dashboardBlock', DashboardBlockComponent)
  .component('ferroImage', FerroImageComponent)
  .component('viewBox', ViewBoxComponent)
  .component('betaLogo', BetaLogo)
  .component('timePicker', TimePickerComponent)
  .component('alarmImpact', AlarmImpactComponent)
  .component('incidentAlert', IncidentAlertComponent)
  .component('plannedMaintenanceAlert', PlannedMaintenanceComponent)
  .service('notificationService', NotificationService).name
