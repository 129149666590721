import { ILocationService } from 'angular'
import { TransitionService } from '@uirouter/angularjs'
import { AmplitudeServiceName, AmplitudeService } from '@app/service/amplitude.service'

export const amplitudeHandler = (
  $location: ILocationService,
  $transitions: TransitionService,
  amplitudeService: AmplitudeService
) => {
  $transitions.onSuccess({}, onSuccess)

  function onSuccess() {
    amplitudeService.logEvent('View Page', { path: $location.path() })
  }
}

amplitudeHandler.$inject = ['$location', '$transitions', AmplitudeServiceName]
