import { IScope, material } from 'angular'
import { EChartOption, ECharts } from 'echarts'
import {
  clearEchartsInstance,
  dataZoomHorizontalHandle,
  dataZoomVerticalHandle,
  echartsInitWrapper,
  echartsOnResize,
  echartsTimeFormatters,
  echartsToolbox,
  echartsTooltip,
  tooltipDateFormatter
} from '../../../../lib'
import { UiDataQueryTypes } from '../../../../types'
import { GRAPHQL_ERRORS } from '../../../graphql/errors'
import { LanguageViews } from '../../../language/language.interface'
import { ElectricalComponentColors, FerroConfiguration } from '../../../service'
import { createPowerViewChart } from './power-view.chart'
import { PowerViewService } from './power-view.service'
import {
  generateTitleAndLabels,
  powerViewTitleAndLabels,
  processedDataQuery,
  processMainAggregateDataType
} from './power-view.tools'

let y = ''
let chart: ECharts

function colorChooser(type: UiDataQueryTypes | 'main', colors: ElectricalComponentColors): string[] {
  if (type === 'main') {
    return [colors.grid.line, colors.consumption.line, colors.solar.line, colors.battery.line]
  } else if (type === 'udc') {
    return [colors.grid.line, colors.neutral.line]
  } else {
    return [colors.phase1.line, colors.phase2.line, colors.phase3.line, colors.neutral.line]
  }
}

export class PowerViewSecondsDialogController {
  error: string
  loading = true

  type: UiDataQueryTypes | 'main'
  starttime: Date
  endtime: Date

  service: PowerViewService

  $md: material.IDialogService
  l: LanguageViews

  title: string
  timezone: string
  colors: ElectricalComponentColors
  titles: powerViewTitleAndLabels

  $s: IScope

  constructor(
    $mdDialog: material.IDialogService,
    ferroConfiguration: FerroConfiguration,
    powerViewService: PowerViewService,
    $scope: IScope,
    type: UiDataQueryTypes | 'main',
    starttime: Date,
    endtime: Date
  ) {
    this.service = powerViewService
    this.type = type
    this.l = ferroConfiguration.language.Views
    this.starttime = starttime
    this.endtime = endtime
    this.timezone = ferroConfiguration.facility.timezone
    this.$md = $mdDialog
    this.colors = ferroConfiguration.electricColors
    this.$s = $scope
    this.titles = generateTitleAndLabels(this.l)
    this.title = this.titles[type].t
    y = this.titles[type].y
  }

  async plot(data: processedDataQuery): Promise<void> {
    chart = await echartsInitWrapper('chartArea')
    const tooltip = echartsTooltip()
    tooltip.formatter = tooltipDateFormatter('seconds', this.timezone)
    const ops: EChartOption = {
      legend: {
        data: data.legendData
      },
      tooltip: tooltip,
      dataset: data.dataset,
      color: colorChooser(this.type, this.colors),
      yAxis: {
        type: 'value',
        name: `${y}`,
        axisLabel: { formatter: `{value} [${y}]` },
        max: this.type === 'udc' ? 420 : null,
        min: this.type === 'udc' ? 360 : null
      },
      dataZoom: [
        {
          type: 'slider',
          start: 0,
          end: 100,
          showDetail: false,
          ...dataZoomHorizontalHandle
        },
        {
          type: 'slider',
          start: 0,
          end: 100,
          yAxisIndex: 0,
          showDetail: false,
          ...dataZoomVerticalHandle
        }
      ],
      xAxis: {
        type: 'time',
        name: this.l.DATE_AND_TIME,
        axisLabel: {
          formatter: echartsTimeFormatters(this.timezone).seconds
        }
      },
      toolbox: echartsToolbox(),
      series: data.series
    }

    echartsOnResize(chart)
    chart.setOption(ops)
    this.stopLoading()
  }

  stopLoading(): void {
    this.$s.$applyAsync(() => {
      this.loading = false
    })
  }

  onError(error: Error): void {
    this.$s.$applyAsync(() => {
      if (error.message === GRAPHQL_ERRORS.NO_DATA) this.error = this.l.NO_DATA_FOUND
      else this.error = this.l.ERROR_OCCURRED
    })
    this.stopLoading()
  }

  async getMonitorData(): Promise<void> {
    try {
      const data = await this.service.getUiData(this.starttime, this.endtime, this.type as UiDataQueryTypes)
      this.plot(data)
    } catch (error) {
      this.onError(error)
    }
  }

  async getMain(): Promise<void> {
    const data = (await this.service.getPowerData1Second(this.starttime, this.endtime).catch(error => {
      this.onError(error)
    })) as processMainAggregateDataType
    const ops = createPowerViewChart(this.titles, this.colors, this.l, this.timezone, 'seconds')
    chart = await echartsInitWrapper('chartArea')
    echartsOnResize(chart)
    chart.setOption(ops)
    chart.setOption({
      dataset: {
        source: data.data
      }
    })
    this.stopLoading()
  }

  $onInit(): void {
    if (this.type === 'main') {
      this.getMain()
    } else {
      this.getMonitorData()
    }
  }

  $onDestroy(): void {
    clearEchartsInstance(chart)
    y = ''
    if (chart) chart.dispose()
    chart = null
  }

  cancel(): void {
    this.$md.cancel()
  }
}
