/*
    Might be needed if there are system-topology payloads which contains null / undefined values
*/

export const removeNullAndUndefined = (obj: unknown): unknown => {
  return JSON.parse(
    JSON.stringify(obj, (key, value) => {
      return value === null ? undefined : value
    })
  )
}
