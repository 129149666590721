import { IComponentOptions, IDeferred, IIntervalService, IPromise, IQService, ITimeoutService } from 'angular'
import { EChartOption } from 'echarts'
import { DEFAULT_CURRENCY, DEFAULT_TIMEZONE } from '../../../environments/environment'
import {
  LiveDataPowerUiInterface,
  LivedataSysData,
  PortalLiveDataEsoModulesDataInterface,
  PortalLivedataParsedResultInterface
} from '../../../types'
import { LanguageSystemdashboard } from '../../language/language.interface'
import {
  ElectricalComponentColors,
  FacilityTopologyHttpV1Service,
  FacilityTopologyHttpV1ServiceName,
  FerroConfiguration,
  FerroConfigurationName
} from '../../service'
import { EnergySummeriesData, parseEnergySummeries } from './parse-energy-summeries'
import {
  autoFillDefinedSSOS,
  doBatteryModuleData,
  doPvStringData,
  mapsHandler,
  uiPowerDataGaugesHandler,
  updateSchematics
} from './system-dashboard.handler'
import { SystemDashboardService, SystemDashboardServiceName } from './system-dashboard.service'
import { createGauges, CreateGaugesOptions, SystemDashboardGauges } from './system-dashboard.tools'

import './system-dashboard.component.scss'
import { CostObject, SystemDashboardCharts, SystemDashboardLayout } from './system-dashboard.interfaces'

import moment from 'moment'

import { GraphqlDashboardService, GraphqlDashboardServiceName } from '../../graphql'

import { clearEchartsInstance, echartsInitWrapper } from '../../../lib'
import { captureSentryException } from '../../config/sentry'
import { createLiveDataSubscriber, LiveDataSubscriber } from './live-data-utils'
import templateUrl from './system-dashboard.component.html?inline'

import { ObservableSubscription } from '@apollo/client'
import { GetOneFacilityQuery, UiFlowChartComponent } from '@app/graphql/generated'
import { runQuery, runWatchQuery } from '@app/graphql/graphql'
import { UnleashPortal, UnleashPortalName } from '@app/service/unleash-portal'
import { MdbMetaFacilitySystemTopologyESOS, MdbMetaFacilitySystemTopologySSOS } from 'types/topology'

let ifNoSsoOrEsoLiveDataTimeout: IPromise<void> = null
let ifMqttConnectionIsAvailable = false
let waitForReadinessCheck: IPromise<unknown>
let createBatModuleData: (__data: PortalLiveDataEsoModulesDataInterface) => {
  series: EChartOption.Series[]
  xAxis: EChartOption.XAxis
}

export class SystemDashboardController {
  ssoMapping: { [key: string]: MdbMetaFacilitySystemTopologySSOS }
  esoMapping: { [key: string]: MdbMetaFacilitySystemTopologyESOS }

  dashboardLayout: SystemDashboardLayout = {
    gridpower: true,
    loadpower: true,
    pvpower: true,
    batpower: true,
    batsoc: true,
    lC123: true,
    connection: true,
    aceSchematic: true,
    powerSchematic: true,
    pvsummary: true,
    loadsummary: true,
    pvstringgraph: true,
    esomodulegraph: true,
    ace: false,
    currentWeather: true,
    energyGraphs: true,
    map: true,
    evseOverview: false
  }

  error = ''

  facility: GetOneFacilityQuery['facility']
  facilityId: number
  timezone: string

  l: LanguageSystemdashboard

  hasLocationParams: boolean

  layoutController = false
  layoutStatus = ''
  variables = {
    nrMesDone: false
  }
  ifBatteryModules = true
  ifPvStrings = true
  ifMqttConnectionIsAvailable = true
  thisMonth: string

  summary: {
    p: unknown
    l: unknown
  } = { p: null, l: null }
  ratedCapacity: string

  showMapFeature = true
  private readonly graphqlDashboardService: GraphqlDashboardService
  private readonly dService: SystemDashboardService
  private readonly fcService: FerroConfiguration
  private readonly $t: ITimeoutService
  private readonly ftService: FacilityTopologyHttpV1Service
  private readonly canceller: IDeferred<unknown>
  private readonly $interval: IIntervalService
  private liveData: LiveDataSubscriber

  colors: ElectricalComponentColors
  equartzerror = ''
  costObject: CostObject

  stateOfCharge: number

  gauges: SystemDashboardGauges = {}
  // @TODO MUST BE DESTROYED ONCE $onDSTROY
  charts: SystemDashboardCharts = {}

  graphqlWatchQuery: ObservableSubscription

  watchQuerySetVariables: any

  constructor(
    $interval: IIntervalService,
    $timeout: ITimeoutService,
    $q: IQService,
    systemDashboardService: SystemDashboardService,
    ferroConfiguration: FerroConfiguration,
    facilityTopologyHttpV1Service: FacilityTopologyHttpV1Service,
    graphqlDashboardService: GraphqlDashboardService,
    private features: UnleashPortal
  ) {
    this.$interval = $interval
    const canceller = $q.defer()
    this.canceller = canceller
    this.ftService = facilityTopologyHttpV1Service
    this.dService = systemDashboardService
    this.fcService = ferroConfiguration
    this.$t = $timeout
    this.colors = ferroConfiguration.electricColors
    this.graphqlDashboardService = graphqlDashboardService
    if (!this.colors) {
      captureSentryException(new Error(`No electrical component colors available?! ${this.colors}. `))
    }

    const FACILITY = ferroConfiguration.facility
    this.facilityId = Number(FACILITY.id)
    systemDashboardService.init(this.facilityId, FACILITY.timezone, canceller.promise)

    this.facility = FACILITY
    this.l = ferroConfiguration.language.SystemDashboard

    this.timezone = FACILITY.timezone ? FACILITY.timezone : DEFAULT_TIMEZONE

    this.thisMonth = ferroConfiguration.language.Globally.monthlon[new Date().getMonth()]

    ifNoSsoOrEsoLiveDataTimeout = $timeout(() => {
      if (!ifMqttConnectionIsAvailable && this.ssoMapping) {
        this.ifMqttConnectionIsAvailable = false
        autoFillDefinedSSOS(this.ssoMapping)
      }
    }, 10000)

    this.showMapFeature = features.showMap()
  }

  $onInit(): void {
    this.init()
  }

  async init(): Promise<void> {
    if (document.getElementById('gridPower')) {
      const FACILITY = this.facility

      const handleNewLiveData = (data: PortalLivedataParsedResultInterface) => {
        this.liveDataHandler(data)
      }

      this.liveData = createLiveDataSubscriber({
        facilityIds: [this.facilityId]
      })

      this.liveData.subscribe(handleNewLiveData)

      this.ftService.getData(this.facilityId, (error, data) => {
        if (!error && data && typeof data === 'object') {
          this.ssoMapping = data.ssos
          this.esoMapping = data.esos
        }
      })

      const misc = this.facility.misc

      this.costObject = {
        currency: misc?.currencyCode ? String(misc?.currencyCode) : DEFAULT_CURRENCY,
        costElectricityKwh: misc?.costElectricityKWh ? misc?.costElectricityKWh : 1,
        revenueSoldToGrid: misc?.revenueSoldToGridkWh ? misc?.revenueSoldToGridkWh : 1,
        revenueProducedPv: 0,
        revenue: 0
      }

      const Longitude = Number(this.facility?.location?.longitude)
      const Latitude = Number(this.facility?.location?.latitude)
      if (this.showMapFeature) {
        const map = mapsHandler(Longitude, Latitude, this.facility.name)
        if (map) {
          this.hasLocationParams = true
          this.$t(() => {
            map.invalidateSize()
          }, 300)
        }
      }

      runQuery
        .getUiComponentsForPortalQuery({
          variables: { facilityId: this.facilityId }
        })
        .then(({ data }) => {
          const enabledComponents = data.facility.uiComponents.flowChart.enabledComponents

          this.dashboardLayout.evseOverview = enabledComponents.includes(UiFlowChartComponent.Evse)

          this.dashboardLayout.batpower = enabledComponents.includes(UiFlowChartComponent.Battery)

          this.dashboardLayout.batsoc = enabledComponents.includes(UiFlowChartComponent.Battery)

          if (enabledComponents.includes(UiFlowChartComponent.Battery)) this.onHasBattery()

          const gaugeObject: CreateGaugesOptions = {
            gauges: data.facility.uiComponents.gauges,
            facility: this.facility,
            language: this.fcService.language.Globally,
            dashboardLanguage: this.l,
            colors: this.fcService.electricColors
          }

          this.gauges = createGauges(gaugeObject)
          if (
            this.stateOfCharge &&
            this.dashboardLayout.batsoc &&
            this.gauges &&
            this.gauges.liquidBatterySoc
          ) {
            this.gauges.liquidBatterySoc.update(this.stateOfCharge)
          }
        })
      this.dService.layout((error, layout) => {
        if (error) {
          // this.layoutStatus = this.l.LAYOUT_FAILED
        } else if (layout) {
          this.dashboardLayout = layout
          if (!this.showMapFeature) {
            this.dashboardLayout.map = false
          }
        }
      })

      this.graphqlDashboardService.energyCounterSummary(Number(FACILITY.id)).then(value => {
        const data = value.data.facility.measurements
        this.createEnergySummary(data)
      })
    } else {
      waitForReadinessCheck = this.$t(() => {
        this.init()
      }, 10)
    }
  }

  onHasBattery() {
    const watchQuery = runWatchQuery.getBatteryStateForDashboardQuery({
      pollInterval: 0, // Use setInterval
      fetchPolicy: 'network-only',
      variables: {
        facilityId: this.facilityId
      }
    })

    this.watchQuerySetVariables = this.$interval(() => {
      if (!document.hidden) {
        watchQuery.setVariables({
          facilityId: this.facilityId,
          timeRage: {
            gte: moment().add(-2, 'minutes').toDate(),
            lte: new Date()
          }
        })
      }
    }, 60_000)

    this.graphqlWatchQuery = watchQuery.subscribe(({ data }) => {
      const [batteryState] = data.facility.measurements.energyStorage

      if (batteryState) {
        const toPortalBatteryState = {
          rC: batteryState.rC || 0,
          soh: batteryState.soh || 0,
          soc: batteryState.soc || 0,
          ts: batteryState.ts
        }

        const { rC, soc } = toPortalBatteryState
        this.ratedCapacity = `${(rC / 1000).toPrecision(3)} kWh`
        this.stateOfCharge = Math.round(soc)
        if (this.gauges && this.gauges.liquidBatterySoc) {
          this.gauges.liquidBatterySoc.update(Math.round(soc))
        }
      }
    })
  }

  $onDestroy(): void {
    if (ifNoSsoOrEsoLiveDataTimeout) this.$t.cancel(ifNoSsoOrEsoLiveDataTimeout)
    this.canceller.resolve()

    if (this.charts.ssoChart) clearEchartsInstance(this.charts.ssoChart)
    if (this.charts.esoChart) clearEchartsInstance(this.charts.esoChart)
    this.charts.ssoChart = null
    this.charts.esoChart = null

    if (waitForReadinessCheck) this.$t.cancel(waitForReadinessCheck)
    createBatModuleData = null

    if (this.liveData) {
      this.liveData.unsubscribe()
    }
  }

  showLayoutConfig(): void {
    this.layoutController = !this.layoutController
  }

  storeDashboardLayout(): void {
    this.dService.storeLayout(this.dashboardLayout, error => {
      this.layoutStatus = null
      if (error) {
        this.layoutStatus = this.l.LAYOUT_FAILED
      } else {
        this.layoutStatus = this.l.LAYOUT_SAVED
      }
      setTimeout(() => {
        this.layoutStatus = null
      }, 2000)
    })
  }

  /**
   * On New Data distribute the corresponding subobject for the right handler
   * @param  {Object} data [description]
   */

  async liveDataHandler(data: PortalLivedataParsedResultInterface): Promise<void> {
    ifMqttConnectionIsAvailable = true
    if (data.sysData) {
      this.batterySocHandler(data.sysData)
    }
    if (this.dashboardLayout.esomodulegraph && data.esoModulesData && !this.charts.esoChart) {
      this.charts.esoChart = await echartsInitWrapper('batteryModuleChart')
      createBatModuleData = doBatteryModuleData(
        this.charts.esoChart,
        data.esoModulesData,
        this.esoMapping,
        this.l,
        this.colors
      )
    } else if (this.dashboardLayout.esomodulegraph && data.esoModulesData && createBatModuleData) {
      const __data = createBatModuleData(data.esoModulesData)
      this.charts.esoChart.setOption(__data)
    }

    const showPvStringGraph = this.dashboardLayout.pvstringgraph && data.ssoModulesData

    if (showPvStringGraph) {
      if (!this.charts.ssoChart) {
        this.charts.ssoChart = await echartsInitWrapper('pvStringsChart')
      }

      doPvStringData(this.charts.ssoChart, data.ssoModulesData, this.ssoMapping, this.l, this.colors)
    }

    if (data.uiPowerData) {
      this.powerCalculations(data.uiPowerData)
    }
  }

  batterySocHandler(sysd: LivedataSysData): void {
    this.stateOfCharge = sysd.soc ? sysd.soc : Math.round(this.stateOfCharge)
    this.ratedCapacity = sysd.ratedCapacity
      ? `${(sysd.ratedCapacity / 1000).toPrecision(3)} kWh`
      : this.ratedCapacity
    if (
      this.gauges &&
      this.gauges.powerSchematic &&
      this.dashboardLayout.powerSchematic &&
      sysd.soc &&
      sysd.ratedCapacity
    ) {
      this.gauges.powerSchematic.updateBatteryStatus({
        soc: this.stateOfCharge,
        ratedCapacity: sysd.ratedCapacity
      })
    }

    if (this.gauges && this.gauges.liquidBatterySoc && this.dashboardLayout.batsoc) {
      this.gauges.liquidBatterySoc.update(this.stateOfCharge)
    }
  }

  powerCalculations(data: LiveDataPowerUiInterface): void {
    updateSchematics(data, this.dashboardLayout, this.gauges)
    uiPowerDataGaugesHandler(data, this.gauges, this.dashboardLayout)
  }

  createEnergySummary(data: EnergySummeriesData): void {
    const result = parseEnergySummeries(data, this.costObject)
    if (result) {
      this.summary.p = result.solarEnergy
      this.summary.l = result.loadEnergy
    }
  }
}

SystemDashboardController.$inject = [
  '$interval',
  '$timeout',
  '$q',
  SystemDashboardServiceName,
  FerroConfigurationName,
  FacilityTopologyHttpV1ServiceName,
  GraphqlDashboardServiceName,
  UnleashPortalName
]

export const SystemDashboardComponentName = 'systemDashboardComponent'

export const SystemDashboardComponent: IComponentOptions = {
  controller: SystemDashboardController,
  template: templateUrl
}
