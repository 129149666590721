import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { keycloak } from '@app/auth/keycloak'
import { Environment } from '@environments/environment'
import { createClient } from 'graphql-ws'

const graphqlBaseUri = Environment.graphqlUri

const websocketUri = graphqlBaseUri || document.location.host
const protocol = /^https.*/.test(websocketUri) ? 'wss' : 'ws'
const url = `${websocketUri.replace('https', protocol)}/subscriptions`

export const wsLink = new GraphQLWsLink(
  createClient({
    url,
    connectionParams: () => ({
      authToken: keycloak.token
    })
  })
)
