import { ECharts, EChartOption } from 'echarts'
import { echartsInitWrapper, echartsNoDataTitleOptions } from '../../../../lib'

export type EnergyViewPiesData = { value: number; name: string }[]

export function createPieChartSeries(data: EnergyViewPiesData): EChartOption.Series[] {
  return data.map(serie => {
    return {
      value: serie.value,
      name: serie.name
    }
  })
}

export async function createPieChart(
  id: string,
  data: EnergyViewPiesData,
  _colors: string[],
  error?: string
): Promise<ECharts> {
  const pie = await echartsInitWrapper(id)
  const title = echartsNoDataTitleOptions(error)

  const options: EChartOption = {
    title,
    legend: {
      data: data.map(data => data.name)
    },
    tooltip: {
      trigger: 'item',
      formatter: '{b} : {c} [kWh] ({d}%)', // {a} <br/>
      position: 'bottom'
    },
    color: _colors,
    series: [
      {
        label: {
          show: false
        },
        radius: '70%',
        z: 50,
        type: 'pie',
        data: error ? null : createPieChartSeries(data)
      }
    ]
  }
  pie.setOption(options)
  return pie
}
