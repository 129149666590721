import angular from 'angular'
import { ExportDataComponent, ExportDataComponentName } from './export-data'
import {
  SystemInformationComponent,
  SystemInformationComponentName,
  SystemInformationChangesComponent,
  SystemInformationCommentsComponent
} from './system-information'
import {
  SystemUserAccessComponent,
  SystemUserAccessComponentName
} from './system-user-access/system-user-access.component'
import {
  SystemTopologyV1Component,
  SystemTopologyV1ComponentName
} from './system-topology/system-topology.v1.component'
import { AppSystemSettingsConfigurationModule } from './system-configuration'
import { AVAILABLE_EXTERNAL_MODULES } from '../../../environments/environment'
import { AppComponentModule } from '../../components'
import { AppServiceModule } from '../../service'
import { systemSettingsRouterConfig } from './system-settings.routing.config'
import {
  AlarmsComponent,
  AlarmsComponentName,
  AlarmsLogComponent,
  AlarmsLogComponentName,
  AlarmsTableComponent,
  AlarmsTableComponentName,
  AlarmsAddUserComponent,
  AlarmsAddUserComponentName
} from './alarms'

export {
  ExportDataComponentName,
  ExportDataComponent,
  SystemUserAccessComponent,
  SystemUserAccessComponentName,
  SystemTopologyV1ComponentName,
  SystemInformationComponentName,
  SystemTopologyV1Component,
  SystemInformationComponent
}

export const AppSystemSettingsModule = angular
  .module('app.system.settings', [
    AVAILABLE_EXTERNAL_MODULES.mdDataTable,
    AVAILABLE_EXTERNAL_MODULES.ngMaterial,
    AVAILABLE_EXTERNAL_MODULES.uiRouter,
    AppComponentModule,
    AppServiceModule,
    AppSystemSettingsConfigurationModule
  ])
  .config(systemSettingsRouterConfig)
  .component(ExportDataComponentName, ExportDataComponent)
  .component(SystemTopologyV1ComponentName, SystemTopologyV1Component)
  .component(SystemInformationComponentName, SystemInformationComponent)
  .component(SystemUserAccessComponentName, SystemUserAccessComponent)

  .component('systemInformationChanges', SystemInformationChangesComponent)
  .component('systemInformationComments', SystemInformationCommentsComponent)
  .component(AlarmsComponentName, AlarmsComponent)
  .component(AlarmsLogComponentName, AlarmsLogComponent)
  .component(AlarmsAddUserComponentName, AlarmsAddUserComponent)
  .component(AlarmsTableComponentName, AlarmsTableComponent).name
