import { ElectricalComponentColors } from '../../app/service'

export const RGBA_RE = /^rgba?\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})(?:,\s*([01](?:\.\d+)?))?\)$/

/**
 * Helper for toRGB_ which parses strings of the form:
 * rgb(123, 45, 67)
 * rgba(123, 45, 67, 0.5)
 * @return parsed {r,g,b,a?} tuple or null.
 */
export function parseRGBA(rgbStr: string): RGBA_INT_COLOR_STRING {
  const bits = RGBA_RE.exec(rgbStr)
  if (!bits) return null
  const r = parseInt(bits[1], 10).toString()
  const g = parseInt(bits[2], 10).toString()
  const b = parseInt(bits[3], 10).toString()
  if (bits[4]) {
    return { r: r, g: g, b: b, a: parseFloat(bits[4]).toString() }
  } else {
    return { r: r, g: g, b: b }
  }
}

export function toRGB_(colorStr: string): RGBA_INT_COLOR_STRING {
  // Strategy: First try to parse colorStr directly. This is fast & avoids DOM
  // manipulation.  If that fails (e.g. for named colors like 'red'), then
  // create a hidden DOM element and parse its computed color.
  const rgb = parseRGBA(colorStr)
  if (rgb) return rgb

  const div = document.createElement('div')
  div.style.backgroundColor = colorStr
  div.style.visibility = 'hidden'
  document.body.appendChild(div)
  const rgbStr = window.getComputedStyle(div, null).backgroundColor
  document.body.removeChild(div)
  return parseRGBA(rgbStr)
}

export type RGBA_INT_COLOR_STRING = {
  r: string
  g: string
  b: string
  a?: string
}

// export type RGBA_INT_COLOR_NUMBER = { r: number, g: number, b: number, a?: number };

export function shadeColor(color: string, percent: number): string {
  const clr = parseRGBA(color)
  let R = parseInt(clr.r, 10)
  let G = parseInt(clr.g, 10)
  let B = parseInt(clr.b, 10)

  R = parseInt(((R * (100 + percent)) / 100).toString())
  G = parseInt(((G * (100 + percent)) / 100).toString())
  B = parseInt(((B * (100 + percent)) / 100).toString())

  R = R < 255 ? R : 255
  G = G < 255 ? G : 255
  B = B < 255 ? B : 255

  const RR = R.toString(16).length === 1 ? `0${R.toString(16)}` : R.toString(16)
  const GG = G.toString(16).length === 1 ? `0${G.toString(16)}` : G.toString(16)
  const BB = B.toString(16).length === 1 ? `0${B.toString(16)}` : B.toString(16)

  return `#${RR}${GG}${BB}`
}

export function lightenColor(colorStr: string): string {
  const color = toRGB_(colorStr)
  return `rgba(${color.r},${color.g},${color.b}, 0.5)`
}

/**
 * If for example we have more units than there are colors availble we have to reduce colors and mix them upp to match number of units.
 */
export function _reduceColors(
  itemLength: number,
  _colors: ElectricalComponentColors,
  skipKey: string,
  skipColor: string
): string[] {
  const arr = []
  const keys = Object.keys(_colors)
  const keysLength = keys.length
  for (let i = 0; i < itemLength * 1.2; i++) {
    let color = ''
    let key = 'solar'
    if (i >= keysLength * 2) {
      key = keys[i / 2 - keysLength] as 'solar'
      color = shadeColor(_colors[key as 'solar'].line, -60)
    } else if (i >= keys.length) {
      key = keys[i - keysLength] as 'solar'
      color = shadeColor(_colors[key as 'solar'].line, -30)
    } else {
      key = keys[i] as 'solar'
      color = _colors[key as 'solar'].line
    }
    if (key !== skipKey && color !== skipColor) {
      arr.push(color)
    } else {
      itemLength += 1
    }
  }
  return arr
}
