import { IComponentOptions, IScope } from 'angular'
import { FerroConfiguration, FerroConfigurationName } from '@app/service'
import { LanguageAlarms } from '@app/language/language.interface'
import templateUrl from './alarms-log.component.html'
import { translateCode } from './alarms.lib'
import { AlarmLog } from '@app/graphql/generated'
import { getLogs } from './alarm-data'

class AlarmsLogController {
  l: LanguageAlarms

  /* table-pagination options */
  ops = {
    order: 'cleared',
    limit: 10,
    limitOptions: [10, 25, 50],
    page: 1,
    labels: {
      page: '',
      of: '',
      rowsPerPage: ''
    }
  }
  log: AlarmLog[]
  loading = true

  translateCode = translateCode

  constructor(ferroConfiguration: FerroConfiguration, $scope: IScope) {
    this.l = ferroConfiguration.language.Alarms

    const systemListLanguage = ferroConfiguration.language.SystemList
    this.ops.labels = {
      page: systemListLanguage.LIST_PAGE,
      of: systemListLanguage.LIST_OF,
      rowsPerPage: systemListLanguage.ROWS_PER_PAGE
    }
    getLogs(Number(ferroConfiguration.facility.id), 0, 100).then(query => {
      $scope.$applyAsync(() => {
        this.log = query.data.facility.alarm.logs as AlarmLog[]
        this.loading = false
      })
    })
  }
}

AlarmsLogController.$inject = [FerroConfigurationName, '$scope']

export const AlarmsLogComponentName = 'alarmsLogComponent'
export const AlarmsLogComponent: IComponentOptions = {
  controller: AlarmsLogController,
  templateUrl
}
