import {
  EnergyDatePeriod,
  EnergyDateTimePeriod,
  EnergyDataSeriesType,
  SystemEnergyDate,
  SystemEnergyDateTime,
  EnergyPeriodInput
} from '@app/graphql/generated'
import { runQuery } from './graphql'
import { EnergyCounterAsNumbers } from '../../types/energy'

export const GraphqlEnergyCounterServiceName = 'graphqlEnergyCounterService'

export type PartialEnergyCounters = Omit<
  EnergyCounterAsNumbers,
  'lp1' | 'lp2' | 'lp3' | 'ip1' | 'ip2' | 'ip3'
>

export const EnergyTimePeriod = {
  ...EnergyDatePeriod,
  ...EnergyDateTimePeriod
}

/**
 * To avoid too much disruption in the portal we supply this function to translate
 * the new output to the old (ec1, ip1, lp1 etc) output.
 */
export const translateToLegacyOutput = ({
  timeperiodStartDay,
  timeperiodStart,
  gridImportL1,
  gridImportL2,
  gridImportL3,
  gridExportL1,
  gridExportL2,
  gridExportL3,
  consumptionL1,
  consumptionL2,
  consumptionL3,
  pvProduced
}: Partial<SystemEnergyDate & SystemEnergyDateTime>): PartialEnergyCounters => ({
  ts: timeperiodStart || timeperiodStartDay,
  ec1: gridImportL1,
  ec2: gridImportL2,
  ec3: gridImportL3,
  ep1: gridExportL1,
  ep2: gridExportL2,
  ep3: gridExportL3,
  lc1: consumptionL1,
  lc2: consumptionL2,
  lc3: consumptionL3,
  pve: pvProduced
})

export const isDateRange = (resolution: EnergyDatePeriod) =>
  [
    EnergyDatePeriod.Daily,
    EnergyDatePeriod.Monthly,
    EnergyDatePeriod.Weekly,
    EnergyDatePeriod.Yearly
  ].includes(resolution)

export class GraphqlEnergyCounterService {
  async getEnergyCounters(
    facilityId: number,
    resolution: EnergyDateTimePeriod | EnergyDatePeriod,
    startTime: Date,
    endTime: Date,
    dataSeriesType?: EnergyDataSeriesType
  ): Promise<PartialEnergyCounters[]> {
    const isDate = isDateRange(resolution as any)

    const period: EnergyPeriodInput = {}
    if (isDate) {
      period.date = {
        range: {
          gte: startTime,
          lte: endTime
        },
        resolution: resolution as EnergyDatePeriod
      }
    } else {
      period.dateTime = {
        range: {
          gte: startTime,
          lte: endTime
        },
        resolution: resolution as EnergyDateTimePeriod
      }
    }
    const variables = {
      facilityId,
      period,
      dataSeriesType
    }
    const res = await runQuery.getEnergyCountersQuery({ variables: variables })
    if (!res.data?.facility?.measurements?.energy || res.error || res.errors) {
      throw new Error('Unable to fetch energy counters')
    }
    return res.data.facility.measurements.energy.map(e =>
      translateToLegacyOutput(e as unknown as Partial<SystemEnergyDate & SystemEnergyDateTime>)
    )
  }

  async getAccumulatedCounters(
    facilityId: number,
    resolution: EnergyDateTimePeriod,
    startTime: Date,
    endTime: Date
  ): Promise<PartialEnergyCounters[]> {
    const period: EnergyPeriodInput = {
      dateTime: {
        resolution: resolution,
        range: { gte: startTime, lte: endTime }
      }
    }
    const res = await runQuery.getEnergyCountersQuery({
      variables: {
        facilityId,
        period: period,
        dataSeriesType: EnergyDataSeriesType.Accumulated
      }
    })
    if (!res.data?.facility || res.error || res.errors) {
      throw new Error('Unable to fetch energy counters')
    }
    return res.data.facility.measurements.energy.map(e =>
      translateToLegacyOutput(e as unknown as Partial<SystemEnergyDate & SystemEnergyDateTime>)
    )
  }
}
