import * as angular from 'angular'
import {
  LessThanFormDirective,
  LessThanOrEqualFormDirective,
  MoreThanFormDirective,
  MoreThanOrEqualFormDirective
} from './form-validation'

export const AppDirectives = angular
  .module('app.directives', [])
  .directive('lessThan', LessThanFormDirective.factory())
  .directive('moreThan', MoreThanFormDirective.factory())
  .directive('moreOrEqual', MoreThanOrEqualFormDirective.factory())
  .directive('lessOrEqual', LessThanOrEqualFormDirective.factory()).name
