import angular from 'angular'
import { AppServiceModule } from '../../service'
import {
  BatterySimulationService,
  BatterySimulationServiceName
} from './battery-simulations/battery-simulation.service'
import {
  BatterySimulationComponentName,
  BatterySimulationComponent
} from './battery-simulations/battery-simulation.component'
import { DailyTrendService, DailyTrendServiceName } from './daily-trend/daily-trend.service'
import { DailyTrendCompnentName, DailyTrendComponent } from './daily-trend/daily-trend.component'
import { FuseAnalysisService, FuseAnalysisServiceName } from './fuse-analysis/fuse-analysis.service'
import { FuseAnalysisComponent, FuseAnalysisComponentName } from './fuse-analysis/fuse-analysis.component'
import { AnalysisHttpService, AnalysisHttpServiceName } from './analysis-http.service'
import {
  StoredAnalysisComponent,
  StoredAnalysisComponentName
} from './stored-analysis/stored-analysis.component'
import { LoadSignatureService, LoadSignatureServiceName } from './load-signature/load-signature.service'
import { LoadSignatureComponent, LoadSignatureComponentName } from './load-signature/load-signature.component'
import { AVAILABLE_EXTERNAL_MODULES } from '../../../environments/environment'
import { systemAnalysisRouting } from './system-analysis.routing'

export {
  AnalysisHttpServiceName,
  DailyTrendServiceName,
  BatterySimulationComponentName,
  BatterySimulationServiceName,
  DailyTrendCompnentName,
  StoredAnalysisComponentName,
  FuseAnalysisServiceName
}

export const AppSystemAnalysisModule = angular
  .module('app.system.analysis', [
    AVAILABLE_EXTERNAL_MODULES.ngMaterial,
    AppServiceModule,
    AVAILABLE_EXTERNAL_MODULES.uiRouter
  ])
  .config(systemAnalysisRouting)
  .service(AnalysisHttpServiceName, AnalysisHttpService)
  //    .component(AceEventsComponentName, AceEventsComponent)
  .service(BatterySimulationServiceName, BatterySimulationService)
  .component(BatterySimulationComponentName, BatterySimulationComponent)
  .service(DailyTrendServiceName, DailyTrendService)
  .component(DailyTrendCompnentName, DailyTrendComponent)
  .service(FuseAnalysisServiceName, FuseAnalysisService)
  .component(FuseAnalysisComponentName, FuseAnalysisComponent)
  .component(StoredAnalysisComponentName, StoredAnalysisComponent)
  .service(LoadSignatureServiceName, LoadSignatureService)
  .component(LoadSignatureComponentName, LoadSignatureComponent).name
