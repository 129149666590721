import { ElectricalComponentColors } from '../../../service/color.service'
import { LanguageAnalaysis } from '../../../language/language.interface'
import { EChartOption, ECharts } from 'echarts'
import { echartsInitWrapper, echartsOnResize, echartsToolbox } from '../../../../lib/echarts'
import { roundTo2Decimals } from '../../../../lib/general'
import { MdbAnalysisLoadEventDetectionResults } from '../@types/load-event-detection'

export type fuseData = (number | number[] | Date[] | Date)[][]

/**
 * These dots are made in the module ./create-fuse-curves.js#createDots
 * The colors are fixed for now
 */
// const dot1 = 'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAeElEQVQoU2NkIBIwEqmOYTAptJke4MD0n3E/mts/YrjRblrAfQYGRgVkhf8ZGA6iKLSbGtDAwMhYjx4S/xj/O8IVOvQHCPxjY7jPwMgogKLw///GQ9kbGuAKQW5j/MdYwMjI4M/AwPDxPwPDhf+M/xuOZG44ANIIAAC7H2edKLuBAAAAAElFTkSuQmCC';
// const dot2 = 'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAjUlEQVQoU2NkgIL78wMEFBM3fIDx0WlGkMDD6X4N//8z5P/nZFLEpZjx0TTfgP+MjOuhJkyUz9xUgM1UxofTfTcwMDD6wyTZGJgUJTM3PMCw+uF0v//Igv8Z/h9QyNzsSFjh//8HFbI2O2BRSKTVj6f7OPxjYNpP0DPg4JnmW/CfkbHhPweTAs7gITbAAZtzPAsorVDDAAAAAElFTkSuQmCC';
// const dot3 = 'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAcElEQVQoU9XO0RFAMBAE0N3hnw60ooQL6QOVoI+MiQooRScxIgxSgfu7nTd7R4TRoksH14AUHzlnCY7GmvVYeUERyROmG4Dsyk6PbrJmuOERVqJbEv0Thnb1gh6reiNYfPAcQf8rsXxbIxidDcEf4A68DxrwUmvR3AAAAABJRU5ErkJggg=='

function createData(data: MdbAnalysisLoadEventDetectionResults): {
  aceL1Data: fuseData
  aceL2Data: fuseData
  aceL3Data: fuseData
  noAceL1Data: fuseData
  noAceL2Data: fuseData
  noAceL3Data: fuseData
  yMax: number
  yMin: number
  xMax: number
} {
  const bins = data.data.scatter.bins
  const events = data.data.scatter.events
  const timestamp = data.data.scatter.timestamps
  const xValues = bins.duration
  const yValues = bins.magnitude

  const aceL1 = events.acel1
  const aceL2 = events.acel2
  const aceL3 = events.acel3
  const noAceL1 = events.noacel1
  const noAceL2 = events.noacel2
  const noAceL3 = events.noacel3

  const tsAceL1 = timestamp.acel1
  const tsAceL2 = timestamp.acel2
  const tsAceL3 = timestamp.acel3
  const tsNoAceL1 = timestamp.noacel1
  const tsNoAceL2 = timestamp.noacel2
  const tsNoAceL3 = timestamp.noacel3
  tsAceL1.pop()
  tsAceL2.pop()
  tsAceL3.pop()
  tsNoAceL1.pop()
  tsNoAceL2.pop()
  tsNoAceL3.pop()

  const noAceL1Data: fuseData = []
  const noAceL2Data: fuseData = []
  const noAceL3Data: fuseData = []
  const aceL1Data: fuseData = []
  const aceL2Data: fuseData = []
  const aceL3Data: fuseData = []
  xValues.forEach((x, xi) => {
    yValues.forEach((y, yi) => {
      if (noAceL1[xi] && noAceL1[xi][yi]) {
        noAceL1Data.push([x, y, noAceL1[xi][yi], tsNoAceL1[xi][yi]])
      }
      if (noAceL2[xi] && noAceL2[xi][yi]) {
        noAceL2Data.push([x, y, noAceL2[xi][yi], tsNoAceL2[xi][yi]])
      }
      if (noAceL3[xi] && noAceL3[xi][yi]) {
        noAceL3Data.push([x, y, noAceL3[xi][yi], tsNoAceL3[xi][yi]])
      }
      if (aceL1[xi] && aceL1[xi][yi]) {
        aceL1Data.push([x, y, aceL1[xi][yi], tsAceL1[xi][yi]])
      }
      if (aceL2[xi] && aceL2[xi][yi]) {
        aceL2Data.push([x, y, aceL2[xi][yi], tsAceL2[xi][yi]])
      }
      if (aceL3[xi] && aceL3[xi][yi]) {
        aceL3Data.push([x, y, aceL3[xi][yi], tsAceL3[xi][yi]])
      }
    })
  })

  const yMax = yValues[yValues.length - 1] as unknown as number
  const yMin = yValues[0] as unknown as number
  const xMax = xValues[xValues.length - 2] as unknown as number
  return {
    yMax,
    xMax,
    yMin,
    aceL1Data,
    aceL2Data,
    aceL3Data,
    noAceL1Data,
    noAceL2Data,
    noAceL3Data
  }
}

function fixFuseCurves(yMax: number, fuseCurves: EChartOption.SeriesLine[]) {
  const resulting: EChartOption.Series[] = []
  let foundFirstAbove = false
  fuseCurves.forEach(serie => {
    serie.symbol = 'none'
    const nr = Number(serie.name.replace('A', ''))
    if (nr > yMax) {
      if (!foundFirstAbove) {
        resulting.push(serie)
        foundFirstAbove = true
      }
    } else {
      resulting.push(serie)
    }
  })
  return resulting
}

export async function createFuseChart(
  data: MdbAnalysisLoadEventDetectionResults,
  colors: ElectricalComponentColors,
  l: LanguageAnalaysis,
  fuseCurves?: EChartOption.SeriesLine[]
): Promise<{
  chartNoAce: ECharts
  chartAce: ECharts
}> {
  const _data = createData(data)
  let curves: EChartOption.Series[]
  if (fuseCurves) {
    curves = fixFuseCurves(_data.yMax, fuseCurves)
  }

  const chartNoAce = await echartsInitWrapper('mainGraph')
  const chartAce = await echartsInitWrapper('secondGraph')
  const legend = [l.PHASE_1, l.PHASE_2, l.PHASE_3].concat(curves.map(fc => fc.name))

  const ops: EChartOption = {
    tooltip: {
      trigger: 'item',
      axisPointer: {
        type: 'cross'
      },

      formatter: (params: EChartOption.Tooltip.Format) => {
        return `${params.marker} ${params.seriesName} <br>
${l.DURATION}: ${roundTo2Decimals(params.data[0])} [${l.SECONDS}]<br>
${l.MAGNITUDE}: ${roundTo2Decimals(params.data[1])} [Arms] <br>
<i>( Click me to get timestamps )</i>
`
      }
    },
    toolbox: echartsToolbox(),
    dataZoom: [
      {
        show: true,
        start: 0,
        end: 100
      },
      {
        show: true,
        yAxisIndex: 0,
        filterMode: 'empty',
        showDataShadow: null,
        left: '93%'
      }
    ],
    legend: {
      data: legend
    },
    yAxis: {
      type: 'value',
      name: 'Arms',
      max: _data.yMax + 20,
      min: 0
    },
    xAxis: {
      type: 'log',
      name: l.DURATION,
      min: 9,
      max: Math.round(_data.xMax + 500)
    }
  }

  const defaultOptions = {
    type: 'scatter',
    dimensions: ['x', 'y'],
    symbolSize: 10
  }

  let noAceSeries: EChartOption.Series[] = [
    {
      name: l.PHASE_1,
      data: _data.noAceL1Data as number[][],
      itemStyle: {
        color: colors.phase1.line,
        opacity: 0.4
      },
      ...defaultOptions
    },
    {
      name: l.PHASE_2,
      data: _data.noAceL2Data as number[][],
      itemStyle: {
        color: colors.phase2.line,
        opacity: 0.4
      },
      ...defaultOptions
    },
    {
      name: l.PHASE_3,
      data: _data.noAceL3Data as number[][],
      itemStyle: {
        color: colors.phase3.line,
        opacity: 0.4
      },
      ...defaultOptions
    }
  ] as EChartOption.Series[]

  let aceSeries: EChartOption.Series[] = [
    {
      name: l.PHASE_1,
      data: _data.aceL1Data as number[][],
      itemStyle: {
        color: colors.phase1.line,
        opacity: 0.4
      },
      ...defaultOptions
    },
    {
      name: l.PHASE_2,
      data: _data.aceL2Data as number[][],
      itemStyle: {
        color: colors.phase2.line,
        opacity: 0.4
      },
      ...defaultOptions
    },
    {
      name: l.PHASE_3,
      data: _data.aceL3Data as number[][],
      itemStyle: {
        color: colors.phase3.line,
        opacity: 0.4
      },
      ...defaultOptions
    }
  ] as EChartOption.Series[]

  if (curves) {
    aceSeries = aceSeries.concat(curves)
    noAceSeries = noAceSeries.concat(curves)
  }

  const noAceOps = angular.copy(ops)
  noAceOps.series = noAceSeries
  chartNoAce.setOption(noAceOps)
  ops.series = aceSeries
  chartAce.setOption(ops)

  echartsOnResize(chartNoAce)
  return {
    chartAce,
    chartNoAce
  }
}
