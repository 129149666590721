import * as moment from 'moment-timezone'
import { DEFAULT_TIMEZONE } from '../../environments/environment'
import { FerroConfiguration } from '../service'

/**
 * @ngdoc filter
 * @name ferroUser.filter:ferroDateToLocaleFilter
 * @function
 * @example
 *<!-- Usage in html -->
 * <p> Now Time is {{Date | ferroDateToLocaleFilter }} in locale time format. </p>
 * <!-- Returns -->
 * <p> Now Time is 2017-11-15 17:05 in locale time format. </p>
 *
 */
export function ferroDateToLocaleFilter(ferroConfiguration: FerroConfiguration) {
  return function (date: Date): string {
    let timezone = DEFAULT_TIMEZONE
    if (ferroConfiguration.facility && ferroConfiguration.facility.timezone) {
      timezone = ferroConfiguration.facility.timezone
    }
    if (date && moment.isDate(new Date(date))) {
      return moment.tz(new Date(date), timezone).format('YYYY-MM-DD HH:mm')
    } else {
      return ''
    }
  }
}

ferroDateToLocaleFilter.$inject = ['ferroConfiguration']
