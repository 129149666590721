import { easeBounce } from 'd3-ease'
import { select, Selection } from 'd3-selection'
import { arc, Arc, DefaultArcObject } from 'd3-shape'
import { LanguageGlobally } from '../../../app/language/language.interface'
import { elementExists, tooOneDecimal } from '../../general'
import './gauge-needles.scss'

export interface NeedleUpdateOptions {
  max: number
  min?: number
  usingReversedSign?: boolean
}

export interface NeedleOptions {
  /** the id of the HtmlElement */
  id: string
  max: number
  min: number
  language: LanguageGlobally
  colorPos: string
  colorNeg?: string
  value: number
  twoDirection?: boolean
  unit: string
}

/*
 Utility methods
 */
const percToDeg = (perc: number) => perc * 360
const degToRad = (deg: number) => (deg * Math.PI) / 180
const percToRad = (perc: number) => degToRad(percToDeg(perc))

let IMPORT = 'Import'
let EXPORT = 'Export'

function twoDirection(
  NeedleOptions: NeedleOptions,
  chart: Selection<Element, unknown, Element, undefined>,
  id: string
) {
  if (id === 'batPower') {
    EXPORT = NeedleOptions.language.charge
    IMPORT = NeedleOptions.language.discharge
  } else {
    IMPORT = NeedleOptions.language.IMPORT
    EXPORT = NeedleOptions.language.EXPORT
  }

  // THESE LABELS INDICATTE EXPORT AND IMPORT
  //     exportLabel =
  chart
    .append('text')
    .attr('class', 'arc chart-text-unit')
    .attr('fill', '#b3b3b3')
    .attr('stroke', 'none')
    .attr('x', '-57')
    .attr('y', '25')
    .append('tspan')
    .attr('class', id)
    .text(EXPORT)
  //     importLabel =
  chart
    .append('text')
    .attr('class', 'arc chart-text-unit')
    .attr('fill', '#b3b3b3')
    .attr('stroke', 'none')
    .attr('x', '57')
    .attr('y', '25')
    .append('tspan')
    .attr('class', id)
    .text(IMPORT)
}

export class Needle {
  el: Selection<SVGPathElement, unknown, HTMLElement, undefined>
  len: number
  radius: number
  max: number
  min: number
  value: number
  valueOffset: number
  divider: number
  perc: number
  usingReversedSign: boolean
  id: string
  // eslint-disable-next-line
  tspanValue: Selection<SVGGElement, unknown, HTMLElement, any>
  // eslint-disable-next-line
  textMax: Selection<SVGGElement, unknown, HTMLElement, any>
  // eslint-disable-next-line
  textMin: Selection<SVGGElement, unknown, HTMLElement, any>
  oldValue: number
  repaintGauge: (perc: number) => void

  // eslint-disable-next-line
  constructor(
    element: Selection<SVGPathElement, unknown, HTMLElement, undefined>,
    needleOptions: NeedleOptions,
    // eslint-disable-next-line
    width: number,
    valueOffset: number,
    divider: number,
    tspanValue: Selection<SVGGElement, unknown, HTMLElement, any>,
    // eslint-disable-next-line
    textMmax: Selection<SVGGElement, unknown, HTMLElement, any>,
    textMin: Selection<SVGGElement, unknown, HTMLElement, any>,
    repaintGauge: (perc: number) => void
  ) {
    this.el = element
    this.len = width / 3
    this.radius = this.len / 6
    this.max = needleOptions.max
    this.min = needleOptions.min
    this.value = needleOptions.value
    this.valueOffset = valueOffset
    this.divider = divider
    this.usingReversedSign = false
    this.id = needleOptions.id
    this.tspanValue = tspanValue
    this.textMax = textMmax
    this.textMin = textMin
    this.repaintGauge = repaintGauge
  }

  recalcPointerPos(perc: number): string {
    const thetaRad = percToRad(perc / 2)
    const centerX = 0
    const centerY = 0
    const topX = centerX - this.len * Math.cos(thetaRad)
    const topY = centerY - this.len * Math.sin(thetaRad)
    const leftX = centerX - this.radius * Math.cos(thetaRad - Math.PI / 2)
    const leftY = centerY - this.radius * Math.sin(thetaRad - Math.PI / 2)
    const rightX = centerX - this.radius * Math.cos(thetaRad + Math.PI / 2)
    const rightY = centerY - this.radius * Math.sin(thetaRad + Math.PI / 2)
    return `M ${leftX} ${leftY} L ${topX} ${topY} L  ${rightX} ${rightY}`
  }

  render(): Selection<SVGPathElement, unknown, HTMLElement, undefined> {
    // const p = path();
    this.el.append('circle').attr('class', 'needle-center').attr('cx', 0).attr('cy', 0).attr('r', this.radius)

    // const needlePath = path()
    // ;
    return this.el.append('path').attr('class', 'needle').attr('d', this.recalcPointerPos.call(this, 0))
  }

  updateOptions(opts: NeedleUpdateOptions): void {
    opts.min = Number(opts.min)
    opts.max = Number(opts.max)
    if (this.min < 0 && opts.min >= 0) {
      return
    }

    if (this.min > 0 && opts.min <= 0) {
      return
    }

    this.max = opts.max ? Number(Number(opts.max).toPrecision(3)) : this.max
    this.min = opts.min ? Number(Number(opts.min).toPrecision(3)) : this.min

    const ao = opts.usingReversedSign

    this.usingReversedSign = ao

    if (this.min === 0 && this.max > 0) {
      this.textMax.text(ao ? `-${this.max}` : this.max)
      this.textMin.text(this.min)
    } else {
      this.textMax.text(this.max)
      this.textMin.text(this.min)
    }

    this.valueOffset = 0
    this.divider = Number(this.max)

    if (this.min < 0) {
      this.divider = Number(this.max) + -Number(this.min)
      this.valueOffset = -Number(this.min)
    }
  }

  moveTo(newValue: number): void {
    const oldValue = this.perc || 0

    this.value = Math.round(100 * Number(Number(newValue).toPrecision(3))) / 100

    this.tspanValue.text(`${this.usingReversedSign ? -this.value : this.value}`)
    this.oldValue = oldValue
    if (this.perc > 1) this.perc = 1
    let change = false
    if (newValue > this.max) {
      this.max = newValue
      this.textMax.text(tooOneDecimal(this.max))
      change = true
    }
    if (newValue < this.min) {
      this.textMax.text(tooOneDecimal(this.min))
      change = true
    }
    if (change) {
      this.divider = this.max + -this.min
    }

    this.perc = (newValue + this.valueOffset) / this.divider

    // by changing the delay and duration we decrease cpu usage...

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this
    this.el
      .transition()
      .delay(50)
      .ease(easeBounce)
      .duration(100)
      .select('.needle')
      .tween(
        'progress',
        () =>
          function (percentOfPercent) {
            const progress = self.oldValue + percentOfPercent * (self.perc - self.oldValue)
            self.repaintGauge(progress)
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            return select(this).attr('d', self.recalcPointerPos.call(self, progress))
          }
      )
  }
}

export function gaugeNeedle(needleOptions: NeedleOptions): Needle {
  const colorPos = needleOptions.colorPos
  const colorNeg = needleOptions.colorNeg

  const max = Number(needleOptions.max)
  const min = Number(needleOptions.min)
  let { value } = needleOptions
  let valueOffset = 0
  let divider = needleOptions.max
  const { id } = needleOptions
  elementExists(id)

  const el = select(`#${id}`)

  if (min < 0) {
    divider = max + -min
    valueOffset = -min
    value += valueOffset
  }
  // const percent = 0.65;
  // const numSections = 1;
  // const sectionPerc = 1 / numSections / 2;
  let padRad = 0.025
  const chartInset = 10
  // Orientation of gauge:
  const totalPercent = 0.75

  const margin = {
    top: 0,
    right: 10,
    bottom: 0,
    left: 10
  }

  const width = 180
  const height = 160
  const radius = Math.min(width, height) / 2
  const barWidth = (40 * width) / 300

  // Create SVG element
  const svg = el
    .append('svg')
    .attr('width', width + margin.left + margin.right)
    .attr('height', height + margin.top + margin.bottom)

  let arc1: Arc<unknown, DefaultArcObject> = null
  let arc2: Arc<unknown, DefaultArcObject> = null
  let arc3: Arc<unknown, DefaultArcObject> = null
  let arc4: Arc<unknown, DefaultArcObject>
  let arcStartRad
  let arcEndRad

  // Add layer for the panel
  const chart = svg.append('g').attr('transform', 'translate(95, 100)')
  if (min < 0) {
    chart.append('path').attr('class', 'arc chart-filled-neg').style('fill', colorNeg)
    chart.append('path').attr('class', 'arc chart-empty-neg')
    chart.append('path').attr('class', 'arc chart-filled-pos').style('fill', colorPos)
    chart.append('path').attr('class', 'arc chart-empty-pos')

    arc4 = arc()
      .outerRadius(radius - chartInset)
      .innerRadius(radius - chartInset - barWidth)
    arc3 = arc()
      .outerRadius(radius - chartInset)
      .innerRadius(radius - chartInset - barWidth)
    arc2 = arc()
      .outerRadius(radius - chartInset)
      .innerRadius(radius - chartInset - barWidth)
    arc1 = arc()
      .outerRadius(radius - chartInset)
      .innerRadius(radius - chartInset - barWidth)
  } else {
    chart.append('path').attr('class', 'arc chart-filled').style('fill', colorPos)
    chart.append('path').attr('class', 'arc chart-empty')

    arc2 = arc()
      .outerRadius(radius - chartInset)
      .innerRadius(radius - chartInset - barWidth)
    arc1 = arc()
      .outerRadius(radius - chartInset)
      .innerRadius(radius - chartInset - barWidth)
  }

  if (needleOptions.twoDirection) {
    twoDirection(needleOptions, chart, id)
  }

  const textValue = chart
    .append('text')
    .attr('class', 'arc chart-text')
    .attr('fill', '#010101')
    .attr('stroke', 'none')
    .attr('x', '0')
    .attr('y', '40')

  const tspanValue = textValue.append('tspan').attr('class', id).text('0.0')

  chart
    .append('text')
    .attr('class', 'arc chart-text-unit')
    .attr('fill', '#b3b3b3')
    .attr('stroke', 'none')
    .attr('x', '0')
    .attr('y', '53')
    .append('tspan')
    .attr('class', id)
    .text(needleOptions.unit)

  const textMin = chart
    .append('text')
    .attr('class', 'arc chart-text-unit')
    .attr('fill', '#b3b3b3')
    .attr('stroke', 'none')
    .attr('x', '-57')
    .attr('y', '10')
    .append('tspan')
    .attr('class', id)
    .text(Number(needleOptions.min).toPrecision(3))

  const textMax = chart
    .append('text')
    .attr('class', 'arc chart-text-unit')
    .attr('fill', '#b3b3b3')
    .attr('stroke', 'none')
    .attr('x', '57')
    .attr('y', '10')

  const textMmax = textMax.append('tspan').attr('class', id).text(Number(needleOptions.max).toPrecision(3))

  function repaintGauge(perc: number) {
    let nextStart = totalPercent
    padRad = 0.025

    if (min < 0) {
      arcStartRad = percToRad(nextStart)
      arcEndRad = arcStartRad + percToRad(perc / 2)
      nextStart += perc / 2

      arc1.startAngle(arcStartRad).endAngle(arcEndRad)

      arcStartRad = percToRad(nextStart)
      arcEndRad = arcStartRad + percToRad(0)

      if (perc > 0.5) padRad = 0

      arc2.startAngle(arcStartRad + padRad).endAngle(Math.PI * 2)

      arcStartRad = arcEndRad
      if (perc < 0.5) {
        arcEndRad = 0
      } else {
        arcEndRad = percToRad((perc - 0.5) / 2)
      }

      arc3.startAngle(0).endAngle(arcEndRad)

      if (perc > 0.5) {
        padRad = 0.025
        arcEndRad = percToRad((perc - 0.5) / 2)
      } else {
        padRad = 0
        arcEndRad = 0
      }
      arc4.startAngle(arcEndRad + padRad).endAngle(Math.PI / 2)
    } else {
      arcStartRad = percToRad(nextStart)
      arcEndRad = arcStartRad + percToRad(perc / 2)
      nextStart += perc / 2
      arc1.startAngle(arcStartRad).endAngle(arcEndRad)
      arcStartRad = percToRad(nextStart)
      arcEndRad = arcStartRad + percToRad((1 - perc) / 2)
      arc2.startAngle(arcStartRad + padRad).endAngle(arcEndRad)
    }

    if (min < 0) {
      chart.select('.chart-empty-neg').attr('d', arc1)
      chart.select('.chart-filled-neg').attr('d', arc2)
      chart.select('.chart-filled-pos').attr('d', arc3)
      chart.select('.chart-empty-pos').attr('d', arc4)
    } else {
      chart.select('.chart-filled').attr('d', arc1)
      chart.select('.chart-empty').attr('d', arc2)
    }
  }

  needleOptions.value = value
  const needle = new Needle(
    chart as Selection<SVGPathElement, unknown, HTMLElement, undefined>,
    needleOptions,
    width,
    valueOffset,
    divider,
    tspanValue,
    textMmax,
    textMin,
    repaintGauge
  )
  needle.render()
  needle.moveTo(0)
  return needle
}
