import { IComponentOptions, IDeferred, IQService, IScope } from 'angular'
import echarts, { EChartOption, ECharts } from 'echarts'
import moment from 'moment-timezone'
import { DEFAULT_TIMEZONE } from '../../../../environments/environment'
import { clearEchartsInstance, echartsOnResize, getId } from '../../../../lib'
import { SSOAveragePower, SsoMapping } from '../../../../types/sso'
import { ViewBoxNextPreviousOptions } from '../../../components/view-box/view-box.component'
import { LanguageViews } from '../../../language/language.interface'
import {
  AmplitudeService,
  ElectricalComponentColors,
  FacilityTopologyHttpV1Service,
  FacilityTopologyHttpV1ServiceName,
  FerroConfiguration,
  FerroConfigurationName
} from '../../../service'
import {
  createLegendData,
  createNewSSOSeries,
  createSso1mAverageChartNew,
  createSsoAveragePowerChartOptions,
  createSsoViewSeries,
  createTotalPower,
  totalPowerSeries
} from './sso-view.chart'
import { SsoViewService, SsoViewServiceName } from './sso-view.service'

import { PowerDataGraphqlService, PowerDataGraphqlServiceName } from '@app/graphql'
import { PowerData } from '@app/graphql/generated'
import { runQuery } from '@app/graphql/graphql'
import { AmplitudeServiceName } from '@app/service/amplitude.service'
import templateUrl from './sso-view.component.html'

class SsoViewController {
  chart: ECharts
  totalChart: ECharts
  canceller: IDeferred<unknown>
  dateWindow: (number | Date)[] = []
  ssoMapping: SsoMapping = {}
  l: LanguageViews
  timezone: string
  maxDate: Date
  minDate: Date

  dateQuery: Date

  graphTitle: string

  isDataAvailable = true

  colors: string[]
  eColors: ElectricalComponentColors
  error = ''
  errorTotal = ''
  loading = true
  dateChanger: ViewBoxNextPreviousOptions
  showPrevious = true
  showNext = false
  activeDecision = false

  totalTitle: string
  constructor(
    $q: IQService,
    private ssoViewService: SsoViewService,
    private ferroConfiguration: FerroConfiguration,
    private facilityTopologyHttpV1Service: FacilityTopologyHttpV1Service,
    private $scope: IScope,
    private amplitudeService: AmplitudeService,
    private powerdataService: PowerDataGraphqlService
  ) {
    this.canceller = $q.defer()
    this.l = ferroConfiguration.language.Views
    this.timezone = ferroConfiguration.facility.timezone || DEFAULT_TIMEZONE
    this.maxDate = ferroConfiguration.getMaxDate()
    this.minDate = ferroConfiguration.getMinDate()
    this.totalTitle = `${this.l.TOTAL} ${this.l.SOLAR_PRODUCTION.toLowerCase()}`
    // variables
    this.dateQuery = moment.tz(new Date(), this.timezone).startOf('day').toDate()
    this.eColors = ferroConfiguration.electricColors

    this.dateChanger = {
      onPrevious: this.dayBefore.bind(this),
      onNext: this.dayAfter.bind(this),
      enabled: true,
      nextLabel: this.l.NEXT_DAY,
      previousLabel: this.l.PREVIOUS_DAY,
      showPrevious: this.showPrevious,
      showNext: this.showNext
    }

    ssoViewService.init(
      Number(ferroConfiguration.facility.id),
      ferroConfiguration.facility.timezone,
      this.canceller.promise
    )
  }

  $onInit(): void {
    this.facilityTopologyHttpV1Service.getData(this.ssoViewService.facilityId, (error, data) => {
      if (error) {
        //
      } else {
        this.ssoMapping = data ? data.ssos : {}
      }
      this.change()
    })
  }

  $onDestroy(): void {
    if (this.chart) {
      clearEchartsInstance(this.chart)
    }
    if (this.totalChart) clearEchartsInstance(this.totalChart)

    this.totalChart = null
    this.chart = null
    this.canceller.resolve()
  }

  change(): void {
    this.loading = true
    const date = moment.tz(this.dateQuery, this.timezone)
    const starttime = date.startOf('day').toDate()
    const endtime = date.endOf('day').toDate()
    const isToday = date.isSame(moment.tz(this.timezone), 'day') || date.valueOf() > new Date().valueOf()
    this.dateChanger.showNext = !isToday
    this.getDataNew(starttime, endtime)

    const daysFromToday = moment.tz(this.timezone).endOf('day').diff(date, 'days')

    this.amplitudeService.logEvent('Get SSO Data', {
      daysFromToday,
      activeDecision: this.activeDecision
    })
  }

  timeChange(): void {
    const date = moment.tz(this.dateQuery, this.timezone)
    const isToday = date.isSame(moment.tz(this.timezone), 'day') || date.valueOf() > new Date().valueOf()
    this.dateChanger.showNext = !isToday
    this.activeDecision = true
  }

  dayAfter(): void {
    this.dateQuery = moment.tz(this.dateQuery, this.timezone).add(1, 'day').toDate()
    this.timeChange()
    this.change()
  }

  dayBefore(): void {
    this.dateQuery = moment.tz(this.dateQuery, this.timezone).subtract(1, 'day').toDate()
    this.timeChange()
    this.change()
  }

  async total(starttime: Date, endtime: Date) {
    const { errors, data } = await this.powerdataService.getSolarPower(
      starttime,
      endtime,
      Number(this.ferroConfiguration.facility.id)
    )

    if (errors) {
      this.errorTotal = this.l.ERROR_OCCURRED
      return
    }

    if (!data) {
      this.errorTotal = this.l.NO_DATA_FOUND
      return
    }

    const totalData = data?.facility?.measurements?.power

    if (!totalData || (totalData && !totalData.length)) {
      this.errorTotal = this.l.NO_DATA_FOUND
      return
    }

    this.totalPlotter(totalData)
  }

  async getDataNew(starttime: Date, endtime: Date) {
    const { errors, data } = await runQuery.getSsosPowerQuery({
      variables: {
        facilityId: Number(this.ferroConfiguration.facility.id),
        timeRange: { gte: starttime, lte: endtime }
      }
    })

    if (errors) {
      this.error = this.l.NO_DATA_FOUND
      return
    }

    if (!data || (data && !data?.facility?.ssos)) {
      this.error = this.l.NO_DATA_FOUND
    }

    if (!this.chart) {
      this.chart = echarts.init(getId('ssoChart') as HTMLDivElement)
      echartsOnResize(this.chart)
      const options = createSso1mAverageChartNew(data, this.l, this.timezone, this.dateQuery)

      this.chart.setOption(options)
    } else {
      const series = createNewSSOSeries(data?.facility?.ssos)
      this.chart.setOption({
        series,
        legend: {
          data: createLegendData(data)
        },
        xAxis: {
          min: this.dateQuery.getTime(),
          max: this.dateChanger.showNext
            ? moment.tz(this.dateQuery, this.timezone).endOf('day').toDate().getTime()
            : 'dataMax'
        }
      })
    }
    await this.total(starttime, endtime)
    this.$scope.$applyAsync(() => {
      this.loading = false
    })
  }

  private getLabels(data: SSOAveragePower[]): SSOAveragePower[] {
    let mappedData: SSOAveragePower[] = []

    if (this.ssoMapping && data) {
      mappedData = data.map(d => {
        if (this.ssoMapping[d.id] && this.ssoMapping[d.id].name) {
          d['id'] = this.ssoMapping[d.id].name
        }
        return d
      })
    } else {
      mappedData = data
    }

    return mappedData.sort((a, b) => (a.id > b.id ? 1 : -1))
  }

  plotter(data: SSOAveragePower[]): void {
    const dataWithLabels: SSOAveragePower[] = this.getLabels(data)
    if (!this.chart) {
      this.chart = echarts.init(getId('ssoChart') as HTMLDivElement)
      echartsOnResize(this.chart)

      const ops = createSsoAveragePowerChartOptions(dataWithLabels, this.l, this.timezone)
      this.chart.setOption(ops)
    } else {
      const dataSeries = createSsoViewSeries(dataWithLabels)
      const data2Update = dataSeries.map(serie => {
        return { data: serie.data }
      }) as EChartOption.Series[]
      this.chart.setOption({
        series: data2Update
      })
    }
  }

  totalPlotter(data: Partial<PowerData>[]) {
    if (!this.totalChart) {
      this.totalChart = echarts.init(getId('ssoChartTotal') as HTMLDivElement)
      const ops = createTotalPower(data, this.l, this.timezone, this.eColors, this.totalTitle)
      echartsOnResize(this.totalChart)
      this.totalChart.setOption(ops)
    } else {
      const series = totalPowerSeries(data, this.totalTitle)
      this.totalChart.setOption({
        series
      })
    }
  }
}

SsoViewController.$inject = [
  '$q',
  SsoViewServiceName,
  FerroConfigurationName,
  FacilityTopologyHttpV1ServiceName,
  '$scope',
  AmplitudeServiceName,
  PowerDataGraphqlServiceName
]

export const SsoViewComponentName = 'ssoViewComponent'

export const SsoViewComponent: IComponentOptions = {
  controller: SsoViewController,
  templateUrl: templateUrl
}
