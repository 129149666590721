import { BatterySimulationOptions } from './battery-simulation.component'

export type simulatedNettoData = [Date, number, number, number][]
export type simulatedPowerData = [Date, number, number, number, number, number][]

export type PowerDataType = [Date, number, number, number, number]
export type EnergyDataType = [Date, number, number, number, number]

export function batterySimulation(
  data: PowerDataType[] | EnergyDataType[],
  options: BatterySimulationOptions,
  callback: (error: string, result: { power: number[]; energy: number[] }) => void
): void {
  const type = options.type
  const resolution = options.resolution
  const batSize = Number(options.bsize)
  const StartingValue = Number(options.batteryStartCharge) / 100
  const maxStore = Number(options.maxStored) / 100
  const minStore = Number(options.minStored) / 100
  let EnergyInBattery = maxStore * batSize * StartingValue
  const maximumChargeLevelOfBatterySize = Number(options.maximumChargeLevelOfBatterySize)

  const whenToChargeBattery = Number(options.chargeBatteryThres)
  const whenToDisChargeBattery = Number(options.dischargeBatteryThres)

  if (whenToChargeBattery > whenToDisChargeBattery) {
    callback('Discharge must be above charge levels of batteries.', null)
    return
  }
  if (!data) {
    callback("Couldn't find any data to simulate from", null)
    return
  }
  if (!data.length) {
    callback("Couldn't find any data to simulate from", null)
    return
  }

  function randomizeBatteryStoragePower(
    gridPower: number,
    kk: number,
    resolution: number
  ): { power: number; energy: number } {
    let delta
    /**
     *   [BatteryPower [kW] , Battery Energy [kWh]]
     */
    let res: { power: number; energy: number }

    const divider = resolution / 3600

    if (kk === 0) {
      res = { power: 0, energy: EnergyInBattery }
    } else if (gridPower < whenToChargeBattery) {
      if (EnergyInBattery < batSize * maxStore) {
        delta = whenToChargeBattery - gridPower
        if (delta > maximumChargeLevelOfBatterySize) {
          EnergyInBattery = EnergyInBattery + maximumChargeLevelOfBatterySize * divider
          res = {
            power: maximumChargeLevelOfBatterySize,
            energy: EnergyInBattery
          }
        } else {
          // iif dleta is less than maximum charge of battery
          EnergyInBattery = EnergyInBattery + delta * divider
          res = { power: delta, energy: EnergyInBattery }
        }
      } else {
        res = { power: 0, energy: EnergyInBattery }
      }
    } else if (gridPower >= whenToDisChargeBattery) {
      if (EnergyInBattery > batSize * minStore) {
        delta = gridPower - whenToDisChargeBattery
        if (delta > maximumChargeLevelOfBatterySize) {
          EnergyInBattery = EnergyInBattery - maximumChargeLevelOfBatterySize * divider
          res = {
            power: -maximumChargeLevelOfBatterySize,
            energy: EnergyInBattery
          }
        } else {
          EnergyInBattery = EnergyInBattery - delta * divider
          res = { power: -delta, energy: EnergyInBattery }
        }
      } else {
        res = { power: 0, energy: EnergyInBattery }
      }
    } else res = { power: 0, energy: EnergyInBattery }
    /*
     * Do nothing
     */

    /**
     * @return [BatteryEffect in kW, EnergyInBattery in joule]
     */
    return res
  }

  function randomizeBatteryStorageEnergy(
    gridPower: number,
    kk: number,
    resolution: number
  ): { power: number; energy: number } {
    let delta
    let chargeWithInstead
    let dischargeWithInstead

    // res contains = [BatteryPower [kW] , Battery Energy [kWh]]
    let res: {
      power: number
      energy: number
    }
    // x[kW] * 1 [s] =  x /3600 [kWh]
    const multiplyer = resolution / 3600

    if (kk === 0) {
      res = { power: 0, energy: EnergyInBattery }
    } else if (gridPower < whenToChargeBattery) {
      if (EnergyInBattery < batSize * maxStore) {
        delta = whenToChargeBattery - gridPower
        if (delta > maximumChargeLevelOfBatterySize) {
          // Delta * muliplyer = average charging Power * 3600 seconds = x kWh that will be charged during our
          if (maximumChargeLevelOfBatterySize * multiplyer > batSize * maxStore - EnergyInBattery) {
            chargeWithInstead = (batSize * maxStore - EnergyInBattery) / multiplyer
            EnergyInBattery = EnergyInBattery + chargeWithInstead * multiplyer
            res = { power: chargeWithInstead, energy: EnergyInBattery }
          } else {
            EnergyInBattery = EnergyInBattery + maximumChargeLevelOfBatterySize * multiplyer
            res = {
              power: maximumChargeLevelOfBatterySize,
              energy: EnergyInBattery
            }
          }
        } else {
          if (delta * multiplyer > batSize * maxStore - EnergyInBattery) {
            chargeWithInstead = (batSize * maxStore - EnergyInBattery) / multiplyer
            EnergyInBattery = EnergyInBattery + chargeWithInstead * multiplyer
            res = { power: chargeWithInstead, energy: EnergyInBattery }
          } else {
            EnergyInBattery = EnergyInBattery + delta * multiplyer
            res = { power: delta, energy: EnergyInBattery }
          }
        }
      } else {
        res = { power: 0, energy: EnergyInBattery }
      }
    } else if (gridPower >= whenToDisChargeBattery) {
      if (EnergyInBattery > batSize * minStore) {
        //
        delta = gridPower - whenToDisChargeBattery

        if (delta > maximumChargeLevelOfBatterySize) {
          if (maximumChargeLevelOfBatterySize * multiplyer > EnergyInBattery - batSize * minStore) {
            dischargeWithInstead = (EnergyInBattery - batSize * minStore) / multiplyer
            EnergyInBattery = EnergyInBattery - dischargeWithInstead * multiplyer
            res = { power: -dischargeWithInstead, energy: EnergyInBattery }
          } else {
            EnergyInBattery = EnergyInBattery - maximumChargeLevelOfBatterySize * multiplyer
            res = {
              power: -maximumChargeLevelOfBatterySize,
              energy: EnergyInBattery
            }
          }
        } else {
          if (delta * multiplyer > EnergyInBattery - batSize * minStore) {
            dischargeWithInstead = (EnergyInBattery - batSize * minStore) / multiplyer
            EnergyInBattery = EnergyInBattery - dischargeWithInstead * multiplyer
            res = { power: -dischargeWithInstead, energy: EnergyInBattery }
          } else {
            EnergyInBattery = EnergyInBattery - delta * multiplyer
            res = { power: -delta, energy: EnergyInBattery }
          }
        }
      } else {
        res = { power: 0, energy: EnergyInBattery }
      }
    } else res = { power: 0, energy: EnergyInBattery }
    /*
     * Do nothing
     */

    /**
     * @return [BatteryEffect in kW, EnergyInBattery in kWh]
     */
    return res
  }

  const result: { power: number[]; energy: number[] } = {
    power: [],
    energy: []
  }

  let randomizeBatteryStorageToUse: (
    gridPower: number,
    kk: number,
    resolution: number
  ) => { power: number; energy: number }

  if (type === 'power') {
    randomizeBatteryStorageToUse = randomizeBatteryStoragePower
  } else {
    randomizeBatteryStorageToUse = randomizeBatteryStorageEnergy
  }

  data.forEach((dataPoint: any, index: number) => {
    const res = randomizeBatteryStorageToUse(dataPoint[1], index, resolution)
    result.power.push(res.power)
    result.energy.push(res.energy)
  })

  callback(null, result)
}
