import { GetOneFacilityQuery, GetSystemListQuery } from '@app/graphql/generated'
import * as angular from 'angular'
import { IPromise } from 'angular'
import * as moment from 'moment-timezone'

import { DEFAULT_TIMEZONE, Environment } from '../../environments/environment'
import { versionParser } from '../../lib/general/version-parser'
import { LanguageInterface } from '../language/language.interface'
import {
  ColorService,
  ElectricalComponentColors,
  GraphColorsFlatten,
  graphColorsThemes,
  ThemeColorsInterface
} from './color.service'
import { ThemeColors } from './color.service/colors'
import { languagesAvailable } from './language.service'

const defaultColorMode: graphColorsThemes = 'default'

export type FerroConfigurationPreparedPhasesColors = [string, string, string, string]

export class FerroConfiguration {
  private $mdDialog: angular.material.IDialogService
  version = Environment.version

  breakpoints = {
    xs: 576,
    sm: 768,
    md: 992,
    lg: 1280
  }

  userEmail = Environment.userEmail
  myIp = Environment.ip

  public facilityIdList: number[] = []

  facility: GetOneFacilityQuery['facility']
  facilities: GetSystemListQuery['facilitiesConnection']['edges']
  allFacupdated = false
  // facDataUpdates = null;

  totalPowerNow = 0
  totalConsumptionNow = 0

  allFacilityFilter = ''
  nowLang: languagesAvailable
  language: LanguageInterface
  facilityTimeReference = new Date()

  isDemo = false

  colorService: ColorService

  colormode: graphColorsThemes
  graphsColors: GraphColorsFlatten
  phaseLineColors: FerroConfigurationPreparedPhasesColors
  phaseBarColors: FerroConfigurationPreparedPhasesColors
  // colors: FerroConfigurationPreparedColorsObject;
  themeColor: ThemeColorsInterface = ThemeColors
  electricColors: ElectricalComponentColors

  constructor($mdDialog: angular.material.IDialogService, colorService: ColorService) {
    this.$mdDialog = $mdDialog
    this.colorService = colorService

    this.setColorMode(Environment.colorMode)
  }

  setFacilityTimeReference(): void {
    this.facilityTimeReference = new Date()
  }

  setColorMode(mode: graphColorsThemes): void {
    switch (mode) {
      case defaultColorMode:
        this.colormode = defaultColorMode
        break
      case 'colorblind':
        this.colormode = 'colorblind'
        break
      default:
        this.colormode = defaultColorMode
    }

    this.electricColors = this.colorService.getElectricComponentColors(this.colormode)
    this.graphsColors = this.colorService.getGraphColors(this.colormode)
    const _c = this.graphsColors
    this.phaseBarColors = [_c.greenB, _c.orange1B, _c.grayB, _c.blueB]
    this.phaseLineColors = [_c.greenL, _c.orange1L, _c.grayL, _c.blueL]
  }

  setFacility(facility: GetOneFacilityQuery['facility']): void {
    this.facility = facility
  }

  setAllFacilityFilter(filter: string): void {
    this.allFacilityFilter = filter
  }

  setLanguage(language: LanguageInterface): void {
    this.language = language
  }

  /**
   *
   * @ngdoc method
   * @name ferroConfiguration.alert
   * @methodOf  ferroUser.ferroConfiguration
   * @requires $mdDialog
   *
   * @param {String} title
   * @param {String} message either stringed or html
   *
   * @description
   * A general alert method for alerting from anywhere
   */

  alert(title: string, message: string): IPromise<unknown> {
    return this.$mdDialog.show(
      this.$mdDialog
        .alert()
        .parent(angular.element(document.querySelector('body')))
        .clickOutsideToClose(true)
        .title(title)
        .htmlContent(message)
        .ariaLabel('___ALERT___')
        .ok('Ok')
    )
  }

  confirm(
    title: string,
    message: string,
    accept: string,
    decline?: string,
    $event?: MouseEvent,
    parent?: Element
  ): IPromise<unknown> {
    const dialog = this.$mdDialog.confirm().title(title).htmlContent(message).ok(accept)
    if (decline) dialog.cancel(decline)
    if ($event) dialog.targetEvent($event)
    if (parent) dialog.parent(parent)
    return this.$mdDialog.show(dialog)
  }

  /**
   *  @param options         title, text, ok, cancel, initialValue
   */

  prompt(options: {
    title: string
    text: string
    initialValue: string
    cancel: string
    ok: string
  }): IPromise<unknown> {
    const len = Object.keys(options).length
    if (len < 5 || len < 4) {
      return
    } else {
      const confirm = this.$mdDialog
        .prompt()
        .title(options.title)
        .textContent(options.text)
        .placeholder('')
        .ariaLabel('ferro-prompt')
        .initialValue(options.initialValue)
        .required(true)
        .ok(options.ok)
        .cancel(options.cancel)

      return this.$mdDialog.show(confirm)
    }
  }

  public getMinDate(): Date {
    let date = new Date(2014, 0, 1)
    let timezone

    if (this.facility?.installedOn) {
      date = this.facility.installedOn
      timezone = this.facility.timezone || DEFAULT_TIMEZONE
    }

    return moment.tz(date, timezone).startOf('day').toDate()
  }

  /**
   * @ngdoc method
   * @name getMaxDate
   * @methodOf ferroUser.ferroConfiguration
   * @description
   * Used to for getting the md-max-date in md-datepicker
   *
   *
   * @return {Date}
   */

  getMaxDate(): Date {
    const timezone = this.facility.timezone || DEFAULT_TIMEZONE
    return moment.tz(new Date(), timezone).endOf('day').toDate()
  }

  facilityHasEms2(): boolean {
    if (this.facility && this.facility?.info?.version) {
      const major = versionParser(this.facility?.info?.version).major
      return major !== null && major >= 2
    } else {
      return false
    }
  }
}

export const FerroConfigurationName = 'ferroConfiguration'

FerroConfiguration.$inject = ['$mdDialog', 'colorService']
