import * as angular from 'angular'
import { SystemListComponent, systemListComponentName } from './system-list.component'
import { AppServiceModule } from '../service'
import { AVAILABLE_EXTERNAL_MODULES } from '../../environments/environment'
import { AppComponentModule } from '../components'
import { PowershareListComponent } from './powershare-list.component'

export const AppSystemList = angular
  .module('app.system-list', [
    AppServiceModule,
    AVAILABLE_EXTERNAL_MODULES.ngCookies,
    AVAILABLE_EXTERNAL_MODULES.ngMaterial,
    AVAILABLE_EXTERNAL_MODULES.mdDataTable,
    AppServiceModule,
    AppComponentModule
  ])
  .component(systemListComponentName, SystemListComponent)
  .component('powershareList', PowershareListComponent).name
