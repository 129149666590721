import { LanguageHeader } from '../../language/language.interface'
import {
  FeatureFlagService,
  FeatureFlagServiceName,
  FerroConfiguration,
  FerroConfigurationName
} from '../../service'

import './incident-alert.component.scss'

import template from './incident-alert.component.html'

class IncidentAlertController {
  l: LanguageHeader
  featureFlagService: FeatureFlagService
  hasActiveIncident = false

  constructor(ferroConfiguration: FerroConfiguration, featureFlagService: FeatureFlagService) {
    this.l = ferroConfiguration.language.Header
    this.featureFlagService = featureFlagService
  }

  $onInit(): void {
    this.hasActiveIncident = this.featureFlagService.hasOngoingIncident()
  }
}

IncidentAlertController.$inject = [FerroConfigurationName, FeatureFlagServiceName]

export const IncidentAlertComponent = {
  controller: IncidentAlertController,
  templateUrl: template
}
