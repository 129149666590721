import { LanguageSystemSettings } from '../../../../language/language.interface'

import { SystemStateCurrentStateResponse } from '@app/service/system-state-v2-http.service'
import { copy, IHttpPromise } from 'angular'
import {
  EhubHttpService,
  EhubHttpServiceName,
  FerroConfiguration,
  FerroConfigurationName,
  SystemStateV2HttpService,
  SystemStateV2HttpServiceName
} from '../../../../service'
import { EhubTranslateMacAddressRespond } from '../../../../service/ehub-http.service'
import {
  SystemStateIoCommandObserver,
  SystemStateIoObserverMethod,
  SystemStateV2IoService,
  SystemStateV2IoServiceName
} from '../../../../service/system-state-v2-io.service'

export interface SystemStateInitOptions {
  facilityId: number
  stateObserver: SystemStateIoObserverMethod
  commandObserver: SystemStateIoCommandObserver
}

export interface MappedEnergyhubList extends EhubTranslateMacAddressRespond {
  state: string
  stateNumber: number
  printableState: { mode: string; cssClass: string }
}

export interface ParsedSystemState {
  mode: string
  cssClass: string
}

export const SystemStateV2ServiceName = 'systemStateV2Service'

export class SystemStateV2Service {
  l: LanguageSystemSettings
  facilityId: number
  http: SystemStateV2HttpService
  io: SystemStateV2IoService

  ehub: EhubHttpService

  constructor(
    ferroConfiguration: FerroConfiguration,
    http: SystemStateV2HttpService,
    io: SystemStateV2IoService,
    ehub: EhubHttpService
  ) {
    this.facilityId = null
    this.l = ferroConfiguration.language.SystemSettings
    this.http = http
    this.io = io
    this.ehub = ehub
  }

  init(options: SystemStateInitOptions): void {
    this.facilityId = options.facilityId
    this.io.init(this.facilityId, options.stateObserver, options.commandObserver)
  }

  currentStateToString(mode: number): { mode: string; cssClass: string } {
    const states = {
      '0': {
        mode: this.l.MEASUREMENT_MODE,
        cssClass: 'fe-warn'
      },
      '1': {
        mode: this.l.RUN_MODE,
        cssClass: 'fe-primary'
      },
      '8': {
        mode: this.l.FAULT_MODE,
        cssClass: 'fe-error'
      },
      undefined: {
        mode: this.l.UNDEFINED_MODE,
        cssClass: 'fe-error'
      }
    }

    return states[String(mode) as '0']
  }

  startSystem(): IHttpPromise<string> {
    return this.http.commandStart(this.facilityId)
  }

  stopSystem(): IHttpPromise<string> {
    return this.http.commandStop(this.facilityId)
  }

  initateGet(): IHttpPromise<string> {
    return this.http.commandGet(this.facilityId)
  }

  disconnect(): void {
    this.io.disconnect()
  }

  async getState(): Promise<IHttpPromise<SystemStateCurrentStateResponse>> {
    return this.http.getCurrentSystemState(this.facilityId)
  }

  async getEhubMapping(input: { [id: string]: string }): Promise<MappedEnergyhubList[]> {
    const data = await this.ehub.translateMacAddress(Object.keys(input))

    const mapped: MappedEnergyhubList[] = copy(data.data) as unknown as MappedEnergyhubList[]
    mapped.forEach(m => {
      m.state = input[m.id]
      try {
        m.stateNumber = Number(m.state.split('x')[1].split('')[0])
      } catch (_e) {
        //
      }
      m.printableState = this.currentStateToString(m.stateNumber)
    })

    return mapped
  }
}

SystemStateV2Service.$inject = [
  FerroConfigurationName,
  SystemStateV2HttpServiceName,
  SystemStateV2IoServiceName,
  EhubHttpServiceName
]
