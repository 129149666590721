import angular from 'angular'
import { AppServiceModule } from '../service'
import { AppSystemAnalysisModule } from './analysis'
import { AppSystemDashboardModule } from './dashboard'
import { AppSystemSettingsModule } from './settings'
import { systemRoutingConfig } from './system-routing.config'
import { SystemSideBarComponent, SystemSidebarComponentName } from './system-sidebar.component'
import { SystemComponent, SystemComponentName } from './system.component'
import { AppSystemViewModule } from './views'

export const AppSystemModule = angular
  .module('app.system', [
    AppSystemViewModule,
    AppSystemDashboardModule,
    AppSystemSettingsModule,
    AppSystemAnalysisModule,
    AppServiceModule
  ])
  .component(SystemSidebarComponentName, SystemSideBarComponent)
  .component(SystemComponentName, SystemComponent)
  .config(systemRoutingConfig).name
