import moment from 'moment-timezone'
import { IScope, material } from 'angular'
import { GraphDialogControllerTemplate } from '../graph-popup-dialog-template/graph-dialog.controller.template'
import { LanguageAnalaysis } from '../../../language/language.interface'
import { ElectricalComponentColors, FerroConfiguration } from '../../../service'
import { EChartOption, ECharts } from 'echarts'
import { DEFAULT_TIMEZONE } from '../../../../environments/environment'
import {
  clearEchartsInstance,
  echartsInitWrapper,
  echartsTimeFormatters,
  echartsToolbox,
  echartsTooltip,
  roundTo2Decimals,
  SQRT2,
  tooltipDateFormatter
} from '../../../../lib'
import { UidataGraphqlService } from '../../../graphql/uidata-graphql.service'
import { GRAPHQL_ERRORS } from '../../../graphql/errors'
import { runQuery } from '@app/graphql/graphql'

let graph1: ECharts, graph2: ECharts, graph3: ECharts

type dataFormat = [Date, number, number, number][]

export async function createChartPopUpCharts(
  id: string,
  data: dataFormat,
  l: LanguageAnalaysis,
  config: dialogConfig
): Promise<ECharts> {
  const chart = await echartsInitWrapper(id)

  const tooltip = echartsTooltip()

  tooltip.formatter = tooltipDateFormatter('seconds', config.timezone)

  const ops: EChartOption = {
    legend: {
      data: ['L1', 'L2', 'L3']
    },
    toolbox: echartsToolbox(),
    tooltip: tooltip,
    dataset: {
      source: data,
      dimensions: ['ts', '1', '2', '3']
    },
    yAxis: {
      type: 'value',
      name: `Arms`,
      axisLabel: { formatter: '{value} Arms' }
    },
    xAxis: {
      type: 'time',
      name: l.DATE_AND_TIME,
      axisLabel: {
        formatter: echartsTimeFormatters(config.timezone).seconds
      }
    },
    series: [
      {
        type: 'line',
        name: 'L1',
        itemStyle: {
          color: config.colors.phase1.line
        },
        encode: {
          x: 'ts',
          y: '1'
        }
      },
      {
        type: 'line',
        name: 'L2',
        itemStyle: {
          color: config.colors.phase2.line
        },
        encode: {
          x: 'ts',
          y: '2'
        }
      },
      {
        type: 'line',
        name: 'L3',
        itemStyle: {
          color: config.colors.phase3.line
        },
        encode: {
          x: 'ts',
          y: '3'
        },
        markArea: {
          silent: false,
          itemStyle: {
            color: config.colors.warning.bar,
            opacity: 0.5
          },
          data: [
            [
              {
                name: l.EVENTS,
                xAxis: config.timestamp.valueOf()
              },
              {
                xAxis: config.timestamp.valueOf() + config.duration * 1000
              }
            ]
          ]
        }
      } as EChartOption.SeriesLine
    ]
  }

  chart.setOption(ops)
  return chart
}

type dialogConfig = {
  timestamp: Date
  magnitude: number
  duration: number
  timezone: string
  colors: ElectricalComponentColors
}

export class FuseAnalysisDialogController extends GraphDialogControllerTemplate {
  l: LanguageAnalaysis

  uService: UidataGraphqlService
  fService: FerroConfiguration
  config: {
    timestamp: Date
    magnitude: number
    duration: number
    timezone: string
    colors: ElectricalComponentColors
  }

  constructor(
    $mdDialog: material.IDialogService,
    uidataGraphqlService: UidataGraphqlService,
    ferroConfiguration: FerroConfiguration,
    $scope: IScope,
    timestamp: Date,
    duration: number,
    magnitude: number
  ) {
    super($mdDialog, $scope)
    this.uService = uidataGraphqlService
    this.fService = ferroConfiguration
    this.config = {
      magnitude,
      timestamp,
      duration,
      timezone: ferroConfiguration.facility.timezone || DEFAULT_TIMEZONE,
      colors: ferroConfiguration.electricColors
    }
    this.l = ferroConfiguration.language.SystemAnalysis
    const formatTs = echartsTimeFormatters(this.config.timezone)

    this.dialogTitle = `${formatTs.seconds(timestamp)} ${this.l.DURATION.toLowerCase()} ${Math.round(
      duration
    )} ${this.l.SECONDS.toLowerCase()} ${this.l.MAGNITUDE} ${magnitude} Arms`
    this.graphTwoExists = true
    this.graphOneExists = true
    this.graphThreeExists = true
    this.graphTitle1 = this.l.TOTAL_GRID_CURRENT
    this.graphTitle2 = this.l.ACTIVE_GRID_CURRENTS
    this.graphTitle3 = this.l.REACTIVE_GRID_CURRENTS
  }

  $onInit(): void {
    const timestamp = this.config.timestamp
    const duration = this.config.duration
    const starttime = moment(timestamp).subtract(10, 'minutes').toDate()
    const endtime = moment(timestamp)
      .add(duration + 10 * 60, 'seconds')
      .toDate()

    runQuery
      .getFuseAnalysisDialogDataQuery({
        variables: {
          starttime: starttime,
          endtime: endtime,
          facilityId: Number(this.fService.facility.id)
        }
      })
      .then(data => {
        const eq: dataFormat = []
        const er: dataFormat = []
        const ed: dataFormat = []
        let ts
        if (data.error || data.errors) {
          const error = data.error || data.errors[0]
          if (error.message === GRAPHQL_ERRORS.NO_DATA) this.error = this.l.NO_DATA_FOUND
          else this.error = this.l.ERROR_OCCURRED
          return
        }
        const gridData = data?.data?.facility?.measurements?.gridData

        if (!gridData) {
          this.error = this.l.NO_DATA_FOUND
          return
        }

        ;(gridData || []).forEach(d => {
          ts = null
          if (d && d.timestamp) {
            ts = d.timestamp

            eq.push([
              ts,
              roundTo2Decimals(d.iExtQ1 / SQRT2),
              roundTo2Decimals(d.iExtQ2 / SQRT2),
              roundTo2Decimals(d.iExtQ3 / SQRT2)
            ])

            ed.push([
              ts,
              roundTo2Decimals(d.iExtD1 / SQRT2),
              roundTo2Decimals(d.iExtD2 / SQRT2),
              roundTo2Decimals(d.iExtD3 / SQRT2)
            ])
            er.push([
              ts,
              roundTo2Decimals(d.iExtRms1),
              roundTo2Decimals(d.iExtRms2),
              roundTo2Decimals(d.iExtRms3)
            ])
          }
        })
        this.createGraphs(er, eq, ed)
      })
      .catch(error => {
        if (error.message === GRAPHQL_ERRORS.NO_DATA) this.error = this.l.NO_DATA_FOUND
        else this.error = this.l.ERROR_OCCURRED
      })
  }

  createGraphs(er: dataFormat, eq: dataFormat, ed: dataFormat): void {
    createChartPopUpCharts('graphArea1', er, this.l, this.config)
    createChartPopUpCharts('graphArea2', eq, this.l, this.config)
    createChartPopUpCharts('graphArea3', ed, this.l, this.config)
    this.stopLoading()
  }

  $onDestroy(): void {
    this.exit()
  }

  cancel(): void {
    this.exit()
  }

  exit(): void {
    clearEchartsInstance(graph1)
    graph1 = null
    clearEchartsInstance(graph2)
    graph2 = null
    clearEchartsInstance(graph3)
    graph3 = null
    this.dialog.hide()
  }
}
