import { IHttpResponse, IHttpService } from 'angular'
import currenciesUrl from '../../assets/json/currency.json'
import timezoneUrl from '../../assets/json/timezones.json'
import { DEFAULT_TIMEZONE, Environment } from '../../environments/environment'
import { serviceClb } from './types.service'

export type timezonesListData = {
  tz: string
  nrtz: string
}[]

export type currenciesListData = {
  country: string
  currency: string
  currencycode: string
}[]

export type geoLocationResponse = {
  lat: number
  lng: number
  formatted_address: string
  tz: string
}
export type geoLocationRequest = {
  city: string
  country: string
  address: string
}

export const StaticDataHttpServiceName = 'staticDataHttpService'

export class StaticDataHttpService {
  private $http: IHttpService

  constructor($http: IHttpService) {
    this.$http = $http
  }

  getTimezones(callback: (timezones: timezonesListData) => void): void {
    this.$http<timezonesListData>({
      method: 'GET',
      url: timezoneUrl as unknown as string
    }).then(response => {
      callback(response.data)
    })
  }

  getCurrencies(callback: (currenciesLIst: currenciesListData) => void): void {
    this.$http<currenciesListData>({
      method: 'GET',
      url: currenciesUrl as unknown as string
    }).then(response => {
      callback(response.data)
    })
  }

  getGeoLocations(
    geoLocationRequest: geoLocationRequest,
    callback: serviceClb<IHttpResponse<string>, geoLocationResponse>
  ): void {
    this.$http<geoLocationResponse>({
      method: 'POST',
      url: Environment.endpoints.fns + '/geoLocations',
      data: geoLocationRequest
    }).then(
      response => {
        const d = response.data
        callback(null, {
          lng: d.lng,
          lat: d.lat,
          formatted_address: d.formatted_address,
          tz: d.tz ? d.tz : DEFAULT_TIMEZONE
        })
      },
      response => {
        callback(response, null)
      }
    )
  }
}

StaticDataHttpService.$inject = ['$http']
