import { StateService } from '@uirouter/angularjs'
import { FerroConfiguration, FerroConfigurationName } from '../service'
import { getFacilityInfo } from './system-list.data'

import { ferroFilter } from '@app/filters/ferro-filter.filter'
import {
  FacilityOrderByWithRelationInput,
  GetSystemListQuery,
  QueryMode,
  SortOrder
} from '@app/graphql/generated'
import { runQuery } from '@app/graphql/graphql'
import angular, { IComponentOptions, IPromise, IQService, IScope } from 'angular'
import moment from 'moment'
import { LanguageSystemlist } from '../language/language.interface'
import { SystemStates } from '../system/system-routing.config'
import template from './system-list.component.html'
import './system-list.component.scss'

const COOKIE_NAME = 'intro_text'
type F = GetSystemListQuery['facilitiesConnection']['edges'][0]

export const mapFacilitiesIds = (facility: F): string => {
  return facility.fid
}

interface SystemListFacility extends F {
  nowEffect?: {
    prod?: number
    cons?: number
    bp?: number
    bpe?: number
    lastupdate?: Date
  }
  lastupdate?: Date
  con?: {
    timestamp: Date
    type: string
    status: string
  }
}

const facilityFilter = ferroFilter()

class SystemListController {
  error = ''
  searchText = ''

  predicate = 'name'
  reverse = false
  totalPowerNow = ''
  totalConsumptionNow: number = null
  totalBatteryPower: number = null
  totalSolarPower: number = null
  totalBatteryStatus: number = null
  totalLoadPower: number = null

  hasCluster = false

  SHOW_INTRODUCTION = false
  INTRODUCTION_TEXT = ''

  // Pagination
  limitOptions = [10, 25, 50]
  listQuery = {
    order: 'name',
    limit: 25,
    page: 1,
    labels: {
      page: '',
      of: '',
      rowsPerPage: ''
    }
  }

  private l: LanguageSystemlist

  private $cookies: angular.cookies.ICookiesService
  private $state: StateService
  private ferroConfiguration: FerroConfiguration
  totalCount: number
  $s: IScope
  facDataUpdates: string
  facilities: SystemListFacility[]
  facilityHref: string
  $q: IQService
  promise: IPromise<unknown>
  orderBy: undefined | FacilityOrderByWithRelationInput
  onPaginate: (page: number, limit: number) => void
  onReorder: (order: string) => void

  abortController?: AbortController
  constructor(
    $state: StateService,
    ferroConfiguration: FerroConfiguration,
    $cookies: angular.cookies.ICookiesService,
    $s: IScope,
    $q: IQService
  ) {
    this.ferroConfiguration = ferroConfiguration
    this.$state = $state
    this.$cookies = $cookies
    this.$q = $q
    this.l = ferroConfiguration.language.SystemList

    this.$s = $s

    const labels = {
      page: this.l.LIST_PAGE,
      of: this.l.LIST_OF,
      rowsPerPage: this.l.ROWS_PER_PAGE
    }
    this.listQuery.labels = labels
    this.searchText = ferroConfiguration.allFacilityFilter

    this.facilityHref = $state
      .href(SystemStates.dashboard, {}, { inherit: false, absolute: false })
      .split('?')[0]

    if ($state.params.error) this.onStateErrors()

    // Initialization

    // other code is moved to transitionHook
    this.checkAllFacData()

    this.onReorder = (order: string) => {
      const o = order.split('-')
      const orderBy = o.length === 1 ? o[0] : o[1]
      const ascDesc = o.length === 1 ? SortOrder.Asc : SortOrder.Desc

      let newOrder

      if (orderBy === 'connectivity') {
        newOrder = { connectivity: { lastSeenAt: ascDesc } }
      } else {
        newOrder = {
          [orderBy]: ascDesc
        }
      }
      this.orderBy = newOrder
      this.getData()
    }

    this.onPaginate = (page: number, limit: number) => {
      this.listQuery = {
        ...this.listQuery,
        page,
        limit
      }
      this.getData()
    }
  }

  onStateErrors(): void {
    this.ferroConfiguration.alert('Error', this.$state.params.error)
  }

  $onInit() {
    this.getData()
  }

  private async getData() {
    if (this.abortController) this.abortController.abort()

    this.abortController = new AbortController()

    const deferred = this.$q.defer()
    this.$s.$applyAsync(() => {
      this.promise = deferred.promise
    })
    const numericValue = Number(this.searchText) || null
    const query = await runQuery
      .getSystemListQuery({
        context: {
          fetchOptions: { signal: this.abortController.signal }
        },

        variables: {
          orderBy: this.orderBy,
          skip: this.listQuery.limit * (this.listQuery.page - 1),
          take: this.listQuery.limit,

          where: {
            OR: [
              {
                name: {
                  contains: this.searchText,
                  mode: QueryMode.Insensitive
                }
              },
              {
                ...(numericValue
                  ? {
                      id: {
                        gte: numericValue
                      }
                    }
                  : {})
              }
            ]
          }
        }
      })
      .catch(error => {
        if (error.message === 'Aborted') {
          // Ignore aborted
        }
      })

    this.$s.$applyAsync(() => {
      deferred.resolve()

      if (query) {
        this.facilities = angular.copy(query?.data?.facilitiesConnection?.edges) || []
        this.totalCount = query?.data?.facilitiesConnection?.totalCount || 0
      }
    })
  }

  hideIntro(type: string): void {
    if (type === 'hide') {
      this.SHOW_INTRODUCTION = false
      this.$cookies.put(COOKIE_NAME, '1', {
        secure: true,
        samesite: 'strict',
        expires: moment().add(1, 'year').toDate()
      })
    } else {
      this.SHOW_INTRODUCTION = true
      this.$cookies.remove(COOKIE_NAME)
    }
  }

  storeSearchFilter(): void {
    this.listQuery.page = 1
    this.ferroConfiguration.setAllFacilityFilter(this.searchText)
    this.getData()
  }

  checkAllFacData(): void {
    this.ferroConfiguration.setFacilityTimeReference()
    this.facDataUpdates = this.ferroConfiguration.facilityTimeReference.toLocaleTimeString()
    this.facilities = this.ferroConfiguration.facilities
  }

  getFacilityData(fac: SystemListFacility): void {
    getFacilityInfo(fac.fid).then(({ data }) => {
      const timestamps: Date[] = []
      const powerdata = data?.facility?.measurements?.latestPower
      const connectivity = data?.facility?.connectivity

      fac.nowEffect = {}

      if (powerdata) {
        timestamps.push(powerdata.timestamp)
        fac.nowEffect.prod = Math.round(powerdata.pvPower / 100) / 10
        fac.nowEffect.cons = Math.round(powerdata.loadPower / 100) / 10
        fac.nowEffect.bp = Math.round(powerdata.batteryPower / 100) / 10
        fac.nowEffect.bpe = 0
      }

      const batteryAggregatedList = data?.facility?.measurements?.energyStorage
      if (batteryAggregatedList && batteryAggregatedList?.length) {
        const [batteryAggregated] = batteryAggregatedList
        timestamps.push(batteryAggregated.timestamp)
        const bpe = (batteryAggregated.soh * batteryAggregated.soc * batteryAggregated.ratedCapacity) / 10000
        fac.nowEffect.bpe = Math.round(bpe / 100) / 10
      }

      const connectionStatus = this.generateConnectionStatus(connectivity || {})

      if (connectionStatus.type === 'signal_wifi_off' || connectionStatus.type === 'offline_bolt') {
        fac.nowEffect = undefined
      }

      this.$s.$applyAsync(() => {
        fac.con = connectionStatus
      })
    })
  }

  generateConnectionStatus({ isOnline, lastSeenAt }: { isOnline?: boolean; lastSeenAt?: Date }): {
    status: string
    type: string
    timestamp: Date | null
  } {
    const ret = {
      timestamp: lastSeenAt,
      type: '',
      status: '',
      class: ''
    }

    if (this.ferroConfiguration.isDemo) {
      ret.type = 'wifi'
      ret.status = this.l.ONLINE
      return ret
    }

    if (isOnline) {
      ret.class = 'md-primary'
      ret.type = 'signal_wifi_4_bar'
      ret.status = this.l.ONLINE
    } else if (!isOnline && lastSeenAt) {
      ret.type = 'signal_wifi_off'
      ret.class = 'md-warn'
      ret.status = this.l.OFFLINE
    } else {
      ret.type = 'offline_bolt'
      ret.status = this.l.NOT_STARTED
      ret.class = 'md-warn'
      ret.timestamp = null
    }
    return ret
  }

  private sumProperty(map: (f: SystemListFacility) => number) {
    return (
      Math.round(
        facilityFilter(this.facilities || [], this.searchText)
          .map(map)
          .reduce((total, cur) => total + cur, 0) * 100
      ) / 100
    )
  }

  sumLoadPower() {
    return this.sumProperty(f => f.nowEffect?.cons || 0)
  }

  sumSolarPower() {
    return this.sumProperty(f => f.nowEffect?.prod || 0)
  }

  sumBatteryPower() {
    return this.sumProperty(f => f.nowEffect?.bp || 0)
  }

  sumBatteryStateOfCharge() {
    return this.sumProperty(f => f.nowEffect?.bpe || 0)
  }
}

SystemListController.$inject = ['$state', FerroConfigurationName, '$cookies', '$scope', '$q']

export const SystemListComponent: IComponentOptions = {
  controller: SystemListController,
  templateUrl: template
}

export const systemListComponentName = 'systemListComponent'
