import angular, { IComponentOptions, IFormController, IScope, ITimeoutService } from 'angular'
import { validateEmail } from '../../../../lib'
import { LanguageSystemSettings } from '../../../language/language.interface'
import { FerroConfiguration, FerroConfigurationName } from '../../../service'

import { FacilityUser, FacilityUserRoles } from '@app/graphql/generated'
import { runQuery } from '@app/graphql/graphql'
import { RolesServices, RolesServicesName } from '@app/service/roles.services'
import templateUrl from './system-user-access.component.html'

type AddNewUser = {
  permission: number
  permissionString: string
  facility_id: number | null
  email: string
  uid: string
  firstname?: string
  lastname?: string
  phonenr?: string
}

class SystemUserAccessController {
  $scope: IScope
  facilityId: number
  userAccess: FacilityUser[] = []
  newPermission: 'Admin' | 'Viewer' = 'Viewer'
  usera = {
    permissions: [
      {
        value: 0,
        name: 'Admin'
      },
      {
        value: 1,
        name: 'Viewer'
      }
    ]
  }

  disableBtn = false
  newUser: AddNewUser = {
    permission: 1,
    permissionString: 'Viewer',
    facility_id: null,
    email: null,
    uid: null
  }
  haveaccount = true
  l: LanguageSystemSettings
  $timeout: ITimeoutService
  fcService: FerroConfiguration

  addusererror = ''
  addusererror2 = ''
  userOk = ''
  okMessage = ''
  okMessage2 = ''
  errorMessage = ''
  error = ''
  error1 = ''

  $mdDialog: angular.material.IDialogService

  notAllowed: boolean
  constructor(
    $scope: IScope,
    ferroConfiguration: FerroConfiguration,
    $mdDialog: angular.material.IDialogService,
    $timeout: ITimeoutService,
    roles: RolesServices
  ) {
    this.notAllowed = !roles.canUpdateFacilitySystemSettings()
    this.$scope = $scope
    this.facilityId = Number(ferroConfiguration.facility.id)
    this.newUser.facility_id = this.facilityId
    this.l = ferroConfiguration.language.SystemSettings
    this.$timeout = $timeout
    this.$mdDialog = $mdDialog
    this.fcService = ferroConfiguration
  }

  $onInit(): void {
    this.getUsers()
  }

  getUsers(): void {
    runQuery
      .getUsersForFacilityQuery({
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
        variables: {
          facilityId: this.facilityId
        }
      })
      .then(response => {
        const data = response?.data
        if (!data?.facility?.users?.length) {
          this.error = 'Failed to fetch users'
          this.userAccess = []
        }

        this.$scope.$applyAsync(() => {
          this.userAccess = data?.facility?.users?.map(u => ({
            ...u
          })) as FacilityUser[]
        })
      })
  }

  checkIfUserExistAndAdd(): void {
    if (this.notAllowed) return
    this.disableBtn = true
    this.addusererror = ''
    this.addusererror2 = ''

    const email = this.newUser.email
    let exists = false
    this.userAccess.forEach(user => {
      if (email === user.email) {
        exists = true
      }
    })
    if (exists) {
      this.addusererror = `${email} exists.`
      this.disableBtn = false
      return
    }

    if (!validateEmail(this.newUser.email)) {
      this.addusererror2 = this.l.NOT_VALID_EMAIL
    } else if (typeof this.newUser.permission === 'undefined') {
      this.addusererror2 = this.l.CHOOSE_PERMISSION
    } else {
      this.newUser['facility_id'] = this.facilityId

      runQuery
        .addExistingUserToFacilityMutation({
          variables: {
            email: this.newUser.email,
            relation:
              this.newUser.permission == 0
                ? FacilityUserRoles.FacilityAdmin
                : FacilityUserRoles.FacilityReader,
            facilityId: this.newUser.facility_id.toString()
          }
        })
        .then(({ data }) => {
          this.disableBtn = false
          this.$scope.$applyAsync(() => {
            const { addExistingUserToFacility } = data
            if (addExistingUserToFacility.success) {
              const user = addExistingUserToFacility.user as FacilityUser
              this.userAccess = [...this.userAccess, user]
              this.newUser.email = ''
              this.newUser.permission = 1
            } else {
              if (addExistingUserToFacility.errors.some(r => r.__typename == 'UserWithEmailNotFound')) {
                this.haveaccount = false
              } else {
                this.addusererror = addExistingUserToFacility.message
              }
            }
          })
        })
    }
  }

  addNewUserWithFacilityPermissions(form: IFormController): void {
    if (this.notAllowed) return
    this.addusererror = ''
    this.addusererror2 = ''
    if (!validateEmail(this.newUser.email)) {
      this.addusererror2 = this.l.NOT_VALID_EMAIL
    } else if (!this.newUser.firstname || !this.newUser.lastname || !form.$valid) {
      this.addusererror2 = this.l.NOT_VALID_FORM
    } else if (typeof this.newUser.permission === 'undefined' || isNaN(this.newUser.permission as number)) {
      this.addusererror2 = this.l.CHOOSE_PERMISSION
    } else {
      this.newUser['facility_id'] = this.facilityId

      runQuery
        .addNewUserToFacilityMutation({
          variables: {
            facilityId: this.facilityId.toString(),
            relation:
              this.newUser.permission == 0
                ? FacilityUserRoles.FacilityAdmin
                : FacilityUserRoles.FacilityReader,
            email: this.newUser.email,
            firstName: this.newUser.firstname,
            lastName: this.newUser.lastname,
            phoneNumber: this.newUser.phonenr
          }
        })
        .then(({ data }) => {
          this.$scope.$applyAsync(() => {
            if (data.addNewUserToFacility.success) {
              const user = data.addNewUserToFacility.user as FacilityUser
              this.haveaccount = true

              this.userOk = this.l.USER_ADDED.replace('<USER>', user.email)
              this.userAccess = [...this.userAccess, user]
              this.newUser.email = ''
              this.emptyAllResponseText()
            } else {
              this.addusererror = `${this.l.ERROR_OCCURRED} (${data.addNewUserToFacility.message})`
            }
          })
        })
    }
  }

  emptyAllResponseText(): void {
    this.$timeout(() => {
      this.userOk = ''
      this.error = ''
      this.okMessage2 = ''
      this.error1 = ''
      this.addusererror = ''
      this.addusererror2 = ''
    }, 5000)
  }

  translateRelation(user: FacilityUser) {
    return user.relation == FacilityUserRoles.FacilityAdmin ? this.l.ADMIN : this.l.VIEWER
  }

  updateUserPermissions(event: MouseEvent, user: FacilityUser): void {
    if (this.notAllowed) return
    this.error1 = ''
    this.okMessage2 = ''
    const userDialog = this.createDialog(user)

    const options: angular.material.IDialogOptions = {
      targetEvent: event,
      locals: {
        parent: this
      },
      controller: angular.noop,
      controllerAs: 'fuACDialog',
      template: userDialog,
      bindToController: true
    }

    this.$mdDialog.show(options).then((permission: 'Admin' | 'Viewer') => {
      let newPermission = null
      if (permission === 'Admin') {
        newPermission = FacilityUserRoles.FacilityAdmin
      } else if (permission === 'Viewer') {
        newPermission = FacilityUserRoles.FacilityReader
      } else {
        this.error1 = `${this.l.PERMISSION_CHANGE_ERROR} ${permission}`
        return
      }

      if (user.relation === newPermission) {
        this.okMessage2 = this.l.PERMISSION_NOT_CHANGED
        return
      }

      runQuery
        .updateUserFacilityRelationMutation({
          variables: {
            facilityId: this.facilityId.toString(),
            userId: user.id,
            relation: newPermission
          }
        })
        .then(({ data }) => {
          if (data.updateUserFacilityRelation.success) {
            this.okMessage2 = this.l.PERMISSION_UPDATED + user.email
            this.getUsers()
            this.emptyAllResponseText()
          } else {
            this.error1 = this.l.PERMISSION_CHANGE_ERROR
          }
        })
    })
  }

  exit(): void {
    this.$mdDialog.cancel()
  }

  close(permission: string): void {
    this.$mdDialog.hide(permission)
  }

  removeUserAccess(event: MouseEvent, user: FacilityUser): void {
    if (this.notAllowed) return
    this.okMessage = ''
    this.error1 = ''
    this.errorMessage = ''

    this.fcService
      .confirm(
        this.l.ACCESS_DELETE.replace('<USER>', user.email),
        this.l.ACCESS_DELETE_INFO,
        this.l.REMOVE,
        this.l.KEEP_USER,
        event
      )
      .then(() => {
        runQuery
          .removeUserFromFacilityMutation({
            variables: {
              userId: user.id,
              facilityId: this.facilityId.toString()
            }
          })
          .then(({ data }) => {
            if (data.removeUserFacilityRelation.success) {
              this.okMessage2 = this.l.ACCESS_DELETE_SUCCESS.replace('<USER>', user.email)
              this.userAccess = null
              this.emptyAllResponseText()
              this.getUsers()
            } else {
              this.error1 = this.l.ACCESS_DELETE_FAILED
              return
            }
          })
      })
  }

  createDialog(user: FacilityUser): string {
    return `
<md-dialog aria-label="options dialog">
  <md-dialog-content layout-padding>
    <h2 class="md-title"> ${this.l.CHANGE_PERMISSION}: ${user.email}</h2>
    <md-select ng-model="fuACDialog.parent.new_permission">
      <md-option value="Viewer" ng-selected="${
        user.relation === FacilityUserRoles.FacilityReader
      }">Viewer</md-option>
      <md-option value="Admin" ng-selected="${
        user.relation === FacilityUserRoles.FacilityAdmin
      }">Admin</md-option>
    </md-select>
  </md-dialog-content>
  <md-dialog-actions>
    <span flex></span>
    <md-button ng-click="fuACDialog.parent.exit()"> ${this.l.CLOSE}</md-button>
    <md-button ng-click="fuACDialog.parent.close(fuACDialog.parent.new_permission)"> ${
      this.l.CHANGE_PERMISSION
    }</md-button>
  </md-dialog-actions>
</md-dialog>`
  }
}

SystemUserAccessController.$inject = [
  '$scope',
  FerroConfigurationName,
  '$mdDialog',
  '$timeout',
  RolesServicesName
]

export const SystemUserAccessComponentName = 'systemUserAccessComponent'

export const SystemUserAccessComponent: IComponentOptions = {
  controller: SystemUserAccessController,
  templateUrl: templateUrl
}
