import {
  AlarmCode,
  MutationAddAlarmSubscriberArgs,
  SubscriptionType,
  MutationRemoveAlarmSubscriberArgs
} from '@app/graphql/generated'
import { runQuery } from '@app/graphql/graphql'

export const getAlarms = (facilityId: number) => {
  return runQuery.getAlarmsQuery({
    variables: {
      facilityId
      //  excludeDefault: true
    },
    fetchPolicy: 'no-cache'
  })
}

export const getAlarmSubscriptions = (facilityId: number, alarmCode: AlarmCode = AlarmCode.All) => {
  return runQuery.getAlarmSubscriptionsQuery({
    variables: {
      facilityId,
      alarmCode
      // excludeDefault: true
    },
    fetchPolicy: 'no-cache'
  })
}

export const getLogs = (facilityId: number, offset: number, limit: number) => {
  return runQuery.getAlarmLogsQuery({
    variables: {
      facilityId,
      offset,
      limit
      // excludeDefault: true
    },
    fetchPolicy: 'no-cache'
  })
}

export const getAlarmAndLog = (facilityId: number, offset: number, limit: number) => {
  return runQuery.getAlarmAndAlarmLogsQuery({
    variables: {
      facilityId,
      offset,
      limit
      // excludeDefault: true
    },
    fetchPolicy: 'no-cache'
  })
}

export const addAlarmSubscriber = (
  facilityId: number,
  code: AlarmCode,
  to: string,
  sendWith: SubscriptionType
) => {
  const variables: MutationAddAlarmSubscriberArgs['data'] = {
    facilityId,
    code,
    to,
    sendWith
  }

  return runQuery.addAlarmSubscriberMutation({
    variables: variables
  })
}

export const removeAlarmSubscriber = (id: string) => {
  const variables: MutationRemoveAlarmSubscriberArgs['data'] = {
    id
  }

  return runQuery.removeAlarmSubscriberMutation({
    variables: variables
  })
}
