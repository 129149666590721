import * as angular from 'angular'

import Long from 'long'

export const SQRT2 = Math.sqrt(2)
export const HOURSECONDS = 3600
export const MILLISECONDS = 1000
export const LONGHOUR = Long.fromNumber(HOURSECONDS * MILLISECONDS, true)

/**
 * @description can take swedish written number with decimals and turn into a normal number....
 * @param nr
 */
export function toNumber(nr: string | number): number {
  if (!isNaN(nr as number)) {
    return Number(nr)
  } else {
    nr = (nr as string).replace(/,/g, '.')
    if (isNaN(nr as unknown as number)) {
      return NaN
    } else {
      return Number(nr)
    }
  }
}

//
// /** @deprecated */
// export function hop(object: any, string: string): boolean {
//     if (typeof object === 'undefined') {
//         return false;
//     } else if (object === null) {
//         return false;
//     } else if (typeof object[string] === 'undefined') {
//         return false;
//     } else if (object[string] === null) {
//         return false;
//     } else {
//         return !!object[string];
//     }
// }

// /** @deprecated */
// export function otherHop(that: unknown, inner: string, last: unknown): unknown {
//     if (!that) {
//         return last[inner];
//     }
//
//     const check = typeof that[inner] !== 'undefined';
//     let go: unknown;
//
//     if (check) {
//         go = that[inner];
//     } else {
//         go = last[inner];
//     }
//
//     if (go === null) {
//         go = last[inner];
//     }
//     last[inner] = go;
//     return go;
// }

export function btoa(p: string): string {
  return window.btoa(p)
}

export function tooOneDecimal(d: number): number {
  d = Number(d)
  if (d < 1000) {
    return Math.round(10 * d) / 10
  } else {
    return Math.round(d)
  }
}

export function roundTo2Decimals(d: number): number {
  d = Number(d)
  if (d < 1000) {
    return Math.round(100 * d) / 100
  } else {
    return Math.round(d)
  }
}

export function twoDecimals(nr: number): number {
  if (isNaN(nr) || nr === null) {
    return null
  }
  return Number(Number(nr).toPrecision(3))
}

/**
 * @name longFromString
 * @param {String} str Numerical String of type Long
 * @return {null|Number} in unit wH energy
 */
export const longFromString = (str: string): number => {
  if (typeof str === 'string') {
    return Long.fromString(str, true).divide(LONGHOUR).toNumber()
  } else {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return long(str)
  }
}

export const long = (Input: { high: number; low: number } | number[] | string): number => {
  if (Input === undefined || Input === null || !Input) {
    return null
  } else if (angular.isArray(Input)) {
    return new Long(Input[1], Input[0], true).divide(LONGHOUR).toNumber()
  } else if (typeof Input === 'string') {
    return longFromString(Input)
  } else {
    return new Long(Input.low, Input.high, true).divide(LONGHOUR).toNumber()
  }
}
