import { UiDataQueryTypes } from '../../types'
import { runQuery } from './graphql'
import { ApolloQueryResult } from '@apollo/client'
import { splitDatetime } from './split-into-chunks'
import { graphqlOnError } from './errors'
import { GridData, Query } from '@app/graphql/generated'

export function getGraphqlQuery(type: UiDataQueryTypes | UiDataQueryTypes[]) {
  if (typeof type === 'string') {
    switch (type) {
      case 'iq': // EnergyHub Active Current
        return runQuery.getGridDataEhubActiveCurrentQuery
      case 'ir': //EnergyHub Total current
        return runQuery.getGridDataEhubTotalCurrentQuery
      case 'id': // EnergyHub Reactive Current
        return runQuery.getGridDataEhubReactiveCurrentQuery
      case 'eq': // Grid Active Current
        return runQuery.getGridDataGridActiveCurrentQuery
      case 'er': // Grid total current
        return runQuery.getGridDataGridTotalCurrentsQuery
      case 'ed': // Grid reactive current
        return runQuery.getGridDataGridReactiveCurrentQuery
      case 'udc': // DC voltage
        return runQuery.getGridDataDcVoltageQuery
      case 'u': // Grid voltage
        return runQuery.getGridDataGridVoltageQuery
    }
  }
}

export const UidataGraphqlServiceName = 'uidataGraphQlService'

export const parseUiData = (promises: Promise<ApolloQueryResult<Query>>[]): Promise<Partial<GridData>[]> => {
  return new Promise((resolve, reject) => {
    Promise.all(promises)
      .then(apolloResults => {
        let dataResult: Partial<GridData>[] = []

        apolloResults.forEach(res => {
          graphqlOnError(res, 'gridData')
          dataResult = dataResult.concat(res.data.facility.measurements.gridData)
        })

        resolve(dataResult)
      })
      .catch(reason => {
        reject(reason)
      })
  })
}

const runQueryRandom = (starttime: Date, endtime: Date, method: any, facilityId: number) => {
  const querySize = 3600 // 1 hour chunks

  return splitDatetime(starttime, endtime, querySize).map(daterange => {
    return method({
      variables: {
        facilityId: facilityId,
        starttime: daterange.start,
        endtime: daterange.end
      }
    })
  })
}

export class UidataGraphqlService {
  async getQueryData(
    starttime: Date,
    endtime: Date,
    type: UiDataQueryTypes,
    facilityId: number
  ): Promise<Partial<GridData>[]> {
    const method = getGraphqlQuery(type as UiDataQueryTypes)
    const queries = runQueryRandom(starttime, endtime, method, facilityId)
    return parseUiData(queries)
  }
}
