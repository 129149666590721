import { IComponentOptions, IScope } from 'angular'
import { versionParser } from '../../../../lib/general/version-parser'
import { LanguageSystemSettings } from '../../../language/language.interface'
import {
  FacilityTopologyHttpV1Service,
  FacilityTopologyHttpV1ServiceName,
  FerroConfiguration,
  FerroConfigurationName,
  SystemStateV2HttpService,
  SystemStateV2HttpServiceName
} from '../../../service'

import { RolesServices, RolesServicesName } from '@app/service/roles.services'
import { MappedEnergyhubList, SystemStateV2Service } from '../system-configuration'
import { SystemStateV2ServiceName } from '../system-configuration/system-state'
import templateUrl from './system-topology.v1.component.html'

type unitType = 'esos' | 'ssos'

type ssoModel = {
  [k: string]: number | string
  /* productId*/
  sso: string
  name: string
  nrofpanels: number
  wpperpanel: number
  paneltype: string
  description: string
}
type esoModel = {
  [k: string]: number | string

  /* productId*/
  eso: string
  name: string
  description: string
}
type ehubModel = {
  s_no: number
  DeviceType: number
  partno: string
  CreatedOn: Date
  type: string
}

interface MappedEnergyhubListWithDeviceType extends MappedEnergyhubList {
  type?: string
}

class SystemTopologyV1Controller {
  error = ''
  updateError = ''
  okUpdate = ''
  esoListSort = 'eso'
  ssoListSort = 'sso'
  hasSSOS = false
  hasESOS = false

  facilityId: number

  facilityTopologyHttpV1Service: FacilityTopologyHttpV1Service
  ferroConfiguration: FerroConfiguration
  $s: IScope
  l: LanguageSystemSettings
  $mdEditDialog: any

  ssoList: ssoModel[]

  esoList: esoModel[]
  ehubList: ehubModel[]
  ehubV2List: MappedEnergyhubListWithDeviceType[]
  hasEms2: boolean

  sysStateHttpService: SystemStateV2HttpService
  statev2: SystemStateV2Service
  notAllowed: boolean

  constructor(
    facilityTopologyHttpV1Service: FacilityTopologyHttpV1Service,
    ferroConfiguration: FerroConfiguration,
    sysStateHttpService: SystemStateV2HttpService,
    stateV2Service: SystemStateV2Service,

    $mdEditDialog: any,
    $scope: IScope,
    roles: RolesServices
  ) {
    this.notAllowed = !roles.canUpdateFacilitySystemSettings()
    this.$s = $scope
    this.sysStateHttpService = sysStateHttpService
    this.facilityId = Number(ferroConfiguration.facility.id)
    this.facilityTopologyHttpV1Service = facilityTopologyHttpV1Service
    this.l = ferroConfiguration.language.SystemSettings
    this.ferroConfiguration = ferroConfiguration
    this.$mdEditDialog = $mdEditDialog
    this.statev2 = stateV2Service

    this.hasEms2 = versionParser(ferroConfiguration.facility.info?.version).major >= 2
  }

  $onInit(): void {
    if (this.hasEms2) {
      this.onEms2()
    }

    // initialation
    this.facilityTopologyHttpV1Service.getTopology(this.facilityId, (error, data) => {
      if (error || !data || !Object.keys(data).length) {
        this.error = this.l.ERROR_OCCURRED
        return
      }
      if (data.ehubs && !this.hasEms2) {
        this.ehubList = data.ehubs.map(ehub => {
          return Object.assign({ type: this._deviceTypeMapper(ehub.DeviceType) }, ehub)
        }) as any
      }

      if (data.ssos) {
        this.hasSSOS = true
        const ssoProductIds = Object.keys(data.ssos)
        this.ssoList = ssoProductIds.map(d => {
          return {
            sso: d,
            name: data.ssos[d].name,
            nrofpanels: data.ssos[d].nrofpanels,
            wpperpanel: data.ssos[d].wpperpanel,
            paneltype: data.ssos[d].paneltype,
            description: data.ssos[d].description
          }
        })
      }
      if (data.esos) {
        this.hasESOS = true
        const esoProductIds = Object.keys(data.esos)
        this.esoList = esoProductIds.map(d => {
          return {
            eso: d,
            name: data.esos[d].name,
            description: data.esos[d].description
          }
        })
      }
    })
  }

  async onEms2(): Promise<void> {
    const result = await this.sysStateHttpService.getCurrentSystemState(this.facilityId)
    const listAsObject: { [id: string]: string } = {}
    result.data.list.forEach(d => {
      listAsObject[d.id] = d.state
    })
    const list = (await this.statev2.getEhubMapping(listAsObject)) as MappedEnergyhubListWithDeviceType[]

    list.forEach(unit => {
      unit.type = this._deviceTypeMapper(unit.DeviceType)
    })
    this.$s.$applyAsync(() => {
      this.ehubV2List = list
    })
  }

  _removeUnitFromList(unitId: string, type: unitType): void {
    const listType = type === 'ssos' ? 'ssoList' : 'esoList'

    for (const i in this[listType]) {
      if (this[listType][i]) {
        if (
          (this[listType][i] as esoModel).eso === unitId ||
          (this[listType][i] as ssoModel).sso === unitId
        ) {
          this[listType].splice(Number(i), 1)
          return
        }
      }
    }
  }

  removeUnit(unitId: string, type: unitType): void {
    if (this.notAllowed) return
    const options = {
      unit_id: unitId,
      type: type,
      facility_id: this.facilityId
    }
    this.facilityTopologyHttpV1Service.removeUnit(options, error => {
      if (error) {
        this.error = this.l.ERROR_DELETE_UNIT
      } else {
        this._removeUnitFromList(unitId, type)
      }
    })
  }

  deleteESO($event: MouseEvent, esoId: string): void {
    if (this.notAllowed) return
    this.confirmDeletion($event, esoId, 'esos', del => {
      if (del) {
        this.removeUnit(esoId, 'esos')
      }
    })
  }

  deleteSSO($event: MouseEvent, ssoId: string): void {
    if (this.notAllowed) return
    this.confirmDeletion($event, ssoId, 'ssos', del => {
      if (del) {
        this.removeUnit(ssoId, 'ssos')
      }
    })
  }

  editSSO(event: MouseEvent, sso: ssoModel, type: string): void {
    if (this.notAllowed) return
    event.stopPropagation() // in case autoselect is enabled
    this.$mdEditDialog
      .small({
        modelValue: sso[type],
        placeholder: '',
        save: (input: { $modelValue: string | number }) => {
          sso[type] = input.$modelValue
        },
        targetEvent: event,
        title: `${this.l.EDIT} ${type}`
      })
      .then((ctrl: any) => {
        const input = ctrl.getInput()
        input.$viewChangeListeners.push(() => {
          input.$setValidity('test', input.$modelValue !== 'test')
        })
      })
  }

  editESO(event: MouseEvent, eso: esoModel, type: string): void {
    if (this.notAllowed) return
    event.stopPropagation() // in case autoselect is enabled
    this.$mdEditDialog
      .small({
        modelValue: eso[type],
        placeholder: '',
        save: function (input: { $modelValue: string | number }) {
          eso[type] = input.$modelValue
        },
        targetEvent: event,
        title: `${this.l.EDIT} ${type}`
      })
      .then((ctrl: any) => {
        const input = ctrl.getInput()
        input.$viewChangeListeners.push(() => {
          input.$setValidity('test', input.$modelValue !== 'test')
        })
      })
  }

  _deviceTypeMapper(deviceType: number): string {
    switch (deviceType) {
      case 0:
        return 'EnergyHub Wall'
      case 1:
        return 'EnergyHub XL'
      case 2:
        return 'Display'
      default:
        return 'Undefined'
    }
  }

  updateInformation(): void {
    if (this.notAllowed) return
    this.updateError = ''
    const data = {
      esos: this.esoList,
      ssos: this.ssoList,
      facility_id: this.facilityId
    }
    this.facilityTopologyHttpV1Service.update(data, err => {
      if (err) {
        this.updateError = this.l.ERROR_OCCURRED_TOPOLOGY
      } else {
        this.okUpdate = this.l.UPDATE_COMPLETED
      }
    })
  }

  confirmDeletion($event: MouseEvent, unitId: string, type: unitType, callback: (ok: boolean) => void): void {
    if (this.notAllowed) return
    // Appending dialog to document.body to cover sidenav in docs app
    this.ferroConfiguration
      .confirm(
        this.l.DELETE_UNIT_CONFIRM.replace('<TYPE>', type).replace('<ID>', unitId),
        this.l.DELETE_UNIT_INFO,
        this.l.CONFIRM_DELETE_UNIT,
        this.l.DONT_DELETE_UNIT,
        $event
      )
      .then(
        () => callback(true),
        () => callback(false)
      )
  }
}

SystemTopologyV1Controller.$inject = [
  FacilityTopologyHttpV1ServiceName,
  FerroConfigurationName,
  SystemStateV2HttpServiceName,
  SystemStateV2ServiceName,
  '$mdEditDialog',
  '$scope',
  RolesServicesName
]

export const SystemTopologyV1ComponentName = 'systemTopologyV1Component'

export const SystemTopologyV1Component: IComponentOptions = {
  controller: SystemTopologyV1Controller,
  templateUrl
}
