/**
 *
 * @description Creates an info symbol with hover effects. To display certain information
 * @example
 * <example module="ferroUser">
 * <file name="index.html">
 * <div ng-init="{ Text : 'test text <br> test'}"></div>
 * <info-box tt-text="{{Text}}"></info-box>
 * </file>
 * </example>
 **/
import { IComponentOptions, IComponentController } from 'angular'

class InfoBoxController implements IComponentController {
  text: string[]
  ttText: string

  constructor() {
    if (this.ttText && typeof this.ttText === 'string') {
      this.text = this.ttText.split('<br>')
    }
  }
}

export const InfoBoxComponentName = 'infoBox'
export const InfoBoxComponent: IComponentOptions = {
  controller: InfoBoxController,
  bindings: {
    ttText: '@'
  }
}
