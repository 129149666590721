import {
  omitTypename,
  removeNullAndUndefined,
  SystemConfigurationGraphqlService,
  SystemConfigurationGraphqlServiceName
} from '@app/graphql'
import {
  GetOneFacilityQuery,
  SystemControlTopologyChange,
  SystemControlTopologyConfiguration
} from '@app/graphql/generated'
import { LanguageSystemSettings } from '@app/language/language.interface'
import { FerroConfiguration, FerroConfigurationName } from '@app/service'
import { echartsTimeFormatters } from '@lib/echarts/options'
import angular, { IComponentOptions, IPromise, IScope, ITimeoutService } from 'angular'

import { ObservableSubscription } from '@apollo/client'
import { runQuery } from '@app/graphql/graphql'
import { AmplitudeService, AmplitudeServiceName } from '@app/service/amplitude.service'
import { RolesServices, RolesServicesName } from '@app/service/roles.services'
import templateUrl from './system-control-topology.component.html'
let previousRunning: boolean

class SystemControlTopologyController {
  l: LanguageSystemSettings
  service: SystemConfigurationGraphqlService
  subscription: ObservableSubscription
  hasTopo = true
  loading = true
  title = 'Control Topology'
  timestamp: string
  facility: GetOneFacilityQuery['facility']
  fuseCurrentRating: number
  topology: Partial<SystemControlTopologyConfiguration>
  $scope: IScope
  status: {
    class: string
    message: string
  }
  amplitudeService: AmplitudeService

  canNotUpdate = true
  isRunning: boolean
  timeout: ITimeoutService
  timer: IPromise<void>
  notAllowed: boolean
  constructor(
    ferroConfiguration: FerroConfiguration,
    service: SystemConfigurationGraphqlService,
    $scope: IScope,
    $timeout: angular.ITimeoutService,
    amplitudeService: AmplitudeService,
    roles: RolesServices
  ) {
    this.notAllowed = !roles.canUpdateFacilitySystemSettings()
    this.$scope = $scope
    this.timeout = $timeout
    this.l = ferroConfiguration.language.SystemSettings
    this.facility = ferroConfiguration.facility
    this.service = service
    this.amplitudeService = amplitudeService
  }

  $onInit(): void {
    if (!this.notAllowed) {
      this.subscription = runQuery
        .newSystemControlTopologyChangeSubscription({
          variables: {
            facilityId: `${this.facility.id}`
          }
        })
        .subscribe(data => {
          this.statusHandler(data.data.newSystemControlTopologyChange)
        })
    }

    this.initiateGet()
    this.checkIfSystemIsRunning()
  }

  private setStatus(classed: string, message: string): void {
    this.$scope.$applyAsync(() => {
      this.status = {
        class: classed,
        message
      }
    })
  }

  checkIfSystemIsRunning(): void {
    // we have to make sure that the system is not running, and that the flag is acctually defined.
    // Its defined in the system-state-configuration controller.
    previousRunning = this.isRunning
    this.canNotUpdate = !!this.isRunning || typeof this.isRunning !== 'boolean'
  }

  $doCheck(): void {
    if (!angular.equals(this.isRunning, previousRunning)) {
      this.checkIfSystemIsRunning()
    }
  }

  $onDestroy(): void {
    previousRunning = undefined
    if (this.subscription && this.subscription.unsubscribe) this.subscription.unsubscribe()
  }

  initiateSet(/*formData: IFormController*/): void {
    if (this.notAllowed) return
    const copyTopology = angular.copy(this.topology)
    copyTopology.data.grid[0].param.fuseCurrentRating = this.fuseCurrentRating
    this.loading = true
    this.amplitudeService.logEvent('Update Fuse Rating', {
      fuseCurrentRating: this.fuseCurrentRating
    })
    this.service
      .setSystemControlTopology(
        Number(this.facility.id),
        removeNullAndUndefined(omitTypename(copyTopology.data))
      )
      .then(() => {
        this.setStatus('fe-success', this.l.SENT_TO_ENERGY_HUB)

        this.timer = this.timeout(() => {
          this.loading = false
          this.setStatus('fe-error', this.l.REQUEST_TO_ENERGYHUB_TIMEDOUT)
        }, 30000)
      })
      .catch(() => {
        // handle error
      })
  }

  initiateGet(): void {
    if (this.notAllowed) return

    this.service
      .getSystemControlTopology(Number(this.facility.id))
      .then(data => {
        this.$scope.$applyAsync(() => {
          this.topology = data.data.facility.systemControlTopologyConfiguration
          this.fuseCurrentRating = this.topology.data.grid[0].param.fuseCurrentRating
          this.timestamp = echartsTimeFormatters(this.facility.timezone).seconds(this.topology.transTs)
          this.loading = false
        })
      })
      .catch(() => {
        // handle error
      })
  }

  private statusHandler(data: SystemControlTopologyChange): void {
    const responses = {
      SystemControlTopologySucceeded: {
        class: 'fe-success',
        message: this.l.SUCCESS
      },
      SystemControlTopologyFailed: {
        class: 'fe-error',
        message: this.l.FAILED
      },
      SystemControlTopologyDone: {
        class: 'fe-success',
        message: this.l.RESPONSE_ACK
      }
    }

    this.$scope.$applyAsync(() => {
      this.status = responses[data.__typename]
    })

    if (data.__typename === 'SystemControlTopologySucceeded') {
      this.$scope.$applyAsync(() => {
        this.timeout.cancel(this.timer)
        this.initiateGet()
      })
    }
  }
}

SystemControlTopologyController.$inject = [
  FerroConfigurationName,
  SystemConfigurationGraphqlServiceName,
  '$scope',
  '$timeout',
  AmplitudeServiceName,
  RolesServicesName
]

export const SystemControlTopologyComponent: IComponentOptions = {
  templateUrl,
  controller: SystemControlTopologyController,
  bindings: {
    loading: '=',
    isRunning: '='
  }
}
