import { Ng1StateDeclaration, StateProvider } from '@uirouter/angularjs'

import { CreateSystemComponentName } from './create-system/create-system.component'

export const GlobalSettingsStates = {
  main: 'settings',
  generalSettings: 'settings.generalSettings',
  userSettings: 'settings.userSettings',
  createSystem: 'settings.createSystem'
}

const mainState: Ng1StateDeclaration = {
  url: '/settings',
  views: {
    ['main']: {
      template: '<div class="ferro-container fcontchild" ui-view></div>'
    }
  },
  redirectTo: GlobalSettingsStates.generalSettings
}

export function globalSettingsRoutesConfig($stateProvider: StateProvider): void {
  $stateProvider
    .state(GlobalSettingsStates.main, mainState)

    .state(GlobalSettingsStates.createSystem, {
      url: '/create-system',
      component: CreateSystemComponentName
    })
}

globalSettingsRoutesConfig.$inject = ['$stateProvider']
