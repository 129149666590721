import { IComponentOptions } from 'angular'
import { LanguageSystemdashboard } from '../../../language/language.interface'
import { FerroConfiguration, FerroConfigurationName } from '../../../service'
import {
  OpenWeatherMapService,
  OpenWeatherMapServiceName,
  OpenWeatherMapWeatherDataType
} from '../../../service/open-weather-map.service'
import templateUrl from './weather-information.component.html'

export interface CurrentWeather {
  icon: number
  main: string
  desc: string
  sunrise: Date
  sunset: Date
  temp: number
  wind_speed: string
  wind_deg: string
}

const windDirectionMapping = [
  'N',
  'NNE',
  'NE',
  'ENE',
  'E',
  'ESE',
  'SE',
  'SSE',
  'S',
  'SSW',
  'SW',
  'WSW',
  'W',
  'WNW',
  'NW',
  'NNW',
  'N'
]

export function getWindDirection(WindDegree: number): string {
  if (isNaN(WindDegree)) {
    return 'Not a valid number'
  }
  const index = Math.round((WindDegree % 360) / 22.5) + 1
  return windDirectionMapping[index]
}

export function ParseCurrentWeather(data: OpenWeatherMapWeatherDataType): CurrentWeather {
  try {
    const w = data.weather[0]
    const id = w.id

    const weatherDescription = w.description

    return {
      icon: id,
      // first letter upper case
      main: weatherDescription.charAt(0).toUpperCase() + weatherDescription.slice(1),
      desc: w.description,
      sunrise: new Date(data.sys.sunrise * 1000),
      sunset: new Date(data.sys.sunset * 1000),
      temp: Math.round(data.main.temp),
      wind_speed: `${Math.round(data.wind.speed)} m/s`,
      wind_deg: getWindDirection(data.wind.deg)
    }
  } catch (_e) {
    return null
  }
}

export class WeatherInformationController {
  ws: OpenWeatherMapService
  fc: FerroConfiguration

  currentWeather: CurrentWeather
  l: LanguageSystemdashboard

  constructor(weatherService: OpenWeatherMapService, ferroConfiguration: FerroConfiguration) {
    this.ws = weatherService
    this.fc = ferroConfiguration
    this.l = ferroConfiguration.language.SystemDashboard
  }

  $onInit(): void {
    this.retriveWeatherData()
    // If the user keeps the tab open in a window, we update the weather once an hour.
    setInterval(() => {
      this.retriveWeatherData()
    }, 3600000 * 6)
  }

  retriveWeatherData(): void {
    const Facility = this.fc.facility
    const latitude = Number(Facility?.location?.latitude)
    const longitude = Number(Facility?.location?.longitude)
    if (!Number.isNaN(latitude) && !Number.isNaN(longitude)) {
      this.ws
        .getWeatherData(latitude, longitude)
        .then(response => {
          const data = response.data
          this.currentWeather = ParseCurrentWeather(data)
        })
        .catch()
    }
  }
}

WeatherInformationController.$inject = [OpenWeatherMapServiceName, FerroConfigurationName]

export const WeatherInformationComponent: IComponentOptions = {
  controller: WeatherInformationController,
  templateUrl
}
