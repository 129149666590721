import { EmsParam } from '@ferroamp/system-configuration-lib'
import { StateService } from '@uirouter/angularjs'
import { systemSettingsStates } from '../../system-settings.routing.config'
import { FerroConfigurationName, FerroConfiguration } from '@app/service'
import { LanguageScheduling } from '@app/language/language.interface'
class EmsConfigSchedulerButton {
  $state: StateService
  emsParam: EmsParam
  l: LanguageScheduling
  constructor($state: StateService, ferroConf: FerroConfiguration) {
    this.$state = $state
    this.l = ferroConf.language.Scheduling
  }

  scheduling(): void {
    this.$state.go(systemSettingsStates.scheduling, {
      emsParam: this.emsParam
    })
  }
}

EmsConfigSchedulerButton.$inject = ['$state', FerroConfigurationName]

export const EmsConfigSchedulingOpener = {
  template: `
    <div style="position:relative">
        <md-button class="md-raised" ng-click="$ctrl.scheduling()" ng-bind="::$ctrl.l.SCHEDULING">
        </md-button>
    </div>
    `,
  controller: EmsConfigSchedulerButton
}
