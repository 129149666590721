import { svg } from 'd3-fetch'
import { BaseType, select, Selection } from 'd3-selection'
import { LanguageSystemdashboard } from '../../../app/language/language.interface'
import ehubFront from '../../../assets/images/ehub_front.png'
import { LivedataSysData } from '../../../types'
import { getId } from '../../general'
import '../schematic.scss'
import { genPowerText, PowerSchematicValues, roundValues } from '../schematic.tools'
import template from './power-schematic-evse.svg'

import { EvseMeterValue, UiFlowChartComponent } from '@app/graphql/generated'
import './power-flow-esve.scss'

const CONNECTOR_REVERSE_ANIMATION = 'connector-animation-reverse'
const CONNECTOR_ANIMATION = 'connector-animation'
const DISABLED = 'disabled'
const DISABLE_TEXT = 'disabled-text'

function disableComponent(component: PowerFlowComponent) {
  if (component) {
    component.connector.classed(DISABLED, true)
    component.circle.classed(DISABLED, true)
    component.electron.classed(DISABLED, true)
    component.power.remove()
    component.title.classed(DISABLE_TEXT, true)
    component.icon.classed(DISABLED, true)
  }
}

function setValue(component: PowerFlowComponent, value: number, reverse?: boolean, extra?: string) {
  if (component) {
    component.power.text(genPowerText(value))
    if (Math.abs(value) > 0) {
      component.connector.classed(DISABLED, false)

      if (reverse) {
        component.connector.classed(CONNECTOR_REVERSE_ANIMATION, true)
        component.connector.classed(CONNECTOR_ANIMATION, false)
      } else {
        component.connector.classed(CONNECTOR_ANIMATION, true)
        component.connector.classed(CONNECTOR_REVERSE_ANIMATION, false)
      }
    } else {
      component.connector.classed(CONNECTOR_ANIMATION, false)
      component.connector.classed(CONNECTOR_REVERSE_ANIMATION, false)
      component.connector.classed(DISABLED, true)
    }

    if (extra !== undefined && !component.extra.empty()) {
      const currentText = component.extra?.text()
      if (currentText !== extra) {
        component.extra.text(extra)
      }
    }
  }
}

function modifyValues(
  component: PowerFlowComponent,
  value: number,
  direction = 1,
  connectorText?: string,
  connectorReverseText?: string
) {
  if (direction * value > 0) {
    setValue(component, value, false, connectorText)
  } else if (direction * value < 0) {
    setValue(component, value, true, connectorReverseText)
  } else {
    setValue(component, value, false, '')
  }
}

export interface PowerConnector {
  direction: 0 | 1
  directionCache: 0 | 1
}

export interface PowerFlowComponent {
  powerStore: number
  power: Selection<HTMLElement, undefined, undefined, undefined>
  extra?: Selection<HTMLElement, undefined, undefined, undefined>
  connector: Selection<HTMLElement, undefined, BaseType, undefined>
  title: Selection<HTMLElement, undefined, undefined, undefined>
  electron: Selection<HTMLElement, undefined, undefined, undefined>
  circle?: Selection<HTMLElement, undefined, undefined, undefined>
  icon: Selection<HTMLElement, undefined, undefined, undefined>
}

export class PowerSchematicEvseService {
  id: string
  url = template
  language: LanguageSystemdashboard
  components: UiFlowChartComponent[]

  svg: Selection<HTMLElement, undefined, undefined, undefined>

  esve: PowerFlowComponent
  grid: PowerFlowComponent
  load: PowerFlowComponent
  pv: PowerFlowComponent
  battery: PowerFlowComponent
  ehub: PowerFlowComponent

  evsekW = 0

  constructor(id: string, language: LanguageSystemdashboard, enabledComponents: UiFlowChartComponent[]) {
    this.id = id
    this.language = language
    this.components = enabledComponents

    svg(template)
      .then(xml => {
        this.activate(xml)
      })
      .catch(error => {
        throw error
      })
  }

  updateBatteryStatus(_data: LivedataSysData): void {
    // eslint-disable-next-line no-console
  }

  update(_data: PowerSchematicValues): void {
    const data = roundValues(_data)
    modifyValues(this.pv, data.pvPower)
    modifyValues(this.load, data.loadPower - this.evsekW, -1, this.language.EXTERNAL_PRODUCTION, '')
    modifyValues(this.ehub, data.ehubPower, 1, this.language.IMPORTING, this.language.EXPORTING)
    modifyValues(this.grid, data.gridPower, 1, this.language.IMPORTING, this.language.EXPORTING)
    modifyValues(this.battery, data.batPower, 1, this.language.DISCHARGING, this.language.CHARGING)
  }

  activate(xml: Document): void {
    const id = getId(this.id)
    if (id) {
      this.svg = select(id.appendChild(xml.documentElement))
      this.addEhubImage()
      this.activateComponents()
    }
  }

  private addEhubImage(): void {
    this.svg.select('#EhubImage').attr('href', ehubFront)
  }

  activateComponents(): void {
    const haveComponent = (component: UiFlowChartComponent) => this.components.includes(component)

    if (haveComponent(UiFlowChartComponent.Grid)) {
      this.grid = this.getComponent('gridLayer', this.language.GRID)
    }
    if (haveComponent(UiFlowChartComponent.Consumption)) {
      this.load = this.getComponent('loadLayer', this.language.CONSUMPTION)
    }
    if (haveComponent(UiFlowChartComponent.Ehub)) {
      this.ehub = this.getComponent('EhubLayer')
    }

    if (haveComponent(UiFlowChartComponent.Evse)) {
      this.esve = this.getComponent('evLayer', this.language.EV_CHARGER)
    } else {
      this.deleteComponent('evLayer')
    }

    if (haveComponent(UiFlowChartComponent.Solar)) {
      this.pv = this.getComponent('pvLayer', this.language.SOLAR)
    } else {
      this.disableComponent(this.getComponent('pvLayer'))
    }

    if (haveComponent(UiFlowChartComponent.Battery)) {
      this.battery = this.getComponent('batteryLayer', this.language.ENERGY_STORAGE)
    } else {
      this.disableComponent(this.getComponent('batteryLayer'))
    }
  }

  public onEsve(data: EvseMeterValue): void {
    const { powerActive } = data
    const scaledkW = powerActive / 1000
    this.evsekW = scaledkW
    setValue(this.esve, scaledkW, true)
  }

  getComponent(id: string, title?: string): PowerFlowComponent | undefined {
    const component = this.svg.select(`#${id}`)
    if (!component) return

    const componentProps: PowerFlowComponent = {
      title: component.select('.schematic_title-text'),
      power: component.select('.schematic_power-text'),
      extra: component.select('.schematic_explain-text'),
      powerStore: 0,
      connector: component.selectAll('.connector'),
      electron: component.select('.dot'),
      circle: component.select('.circle'),
      icon: component.select('.icon')
    }

    componentProps.extra.text('')
    if (title) componentProps.title.text(title)
    return componentProps
  }

  deleteComponent(id: string): void {
    this.svg.select(`#${id}`).remove()
  }

  /**
   * When there is no pv nor battery,
   */
  modifyViewBox(): void {
    //this.svg.attr("viewBox", "-10 60 489.4802 562.92332");
  }

  /**
   * If the component does'nt exist we want to grayout the component
   */
  disableComponent(component: PowerFlowComponent): void {
    disableComponent(component)
  }
}
