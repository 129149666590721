import { StateService } from '@uirouter/angularjs'
import { IComponentOptions } from 'angular'
import { FerroConfiguration, FerroConfigurationName } from '../../service'
import { SystemStates } from '../../system/system-routing.config'
import { PowerShareEnergyObject } from '../powershare-dashboard/parse-energycounters'

import templateUrl from './powershare-block.component.html'

class PowershareBlockController {
  energyData: PowerShareEnergyObject

  facilityId: number

  isDemo: boolean
  name: string

  s: StateService
  onLoaded: (f: number, id: string) => void

  constructor(ferroConfiguration: FerroConfiguration, $state: StateService) {
    if (ferroConfiguration.isDemo) {
      this.isDemo = true
    }
    this.facilityId = Number(this.facilityId)
    this.s = $state
  }

  $onInit(): void {
    this.onLoaded(this.facilityId, `facility${this.facilityId}`)
  }

  goTo(): void {
    if (!this.isDemo) this.s.go(SystemStates.dashboard, { id: Number(this.facilityId) })
  }
}

PowershareBlockController.$inject = [FerroConfigurationName, '$state']
export const PowerShareBlockComponentName = 'powershareBlockComponent'

export const PowerShareBlockComponent: IComponentOptions = {
  controller: PowershareBlockController,
  templateUrl,
  bindings: {
    energyData: '<',
    facilityId: '@',
    feName: '@',
    onLoaded: '<'
  }
}
