export interface EmsConfigV2Language {
  ADVANCED: string
  DEFAULT: string
  MODE: string
  SELF_CONSUMPTION: string
  PEAK_SHAVING: string
  MODE_INPUT: string
  DEFAULT_MODE_INFO: string
  SELF_CONSUMPTION_MODE_INFO: string
  PEAK_SHAVING_MODE_INFO: string
  ADVANCED_INFO: string
  BAT_WIZARD: string
  BAT_WIZARD_TT: string

  PV: string
  PV_TT: string
  ACE: string
  ACE_INPUT_LABEL: string
  ACE_TT: string

  G_POWER: string
  G_LIM_EXPORT: string
  G_LIM_IMPORT: string
  G_IMPORT_THRESHOLD: string
  G_EXPORT_THRESHOLD: string
  G_D_LIM_IMPORT_TT: string
  G_S_LIM_IMPORT_TT: string
  G_P_LIM_IMPORT_TT: string
  G_D_LIM_EXPORT_TT: string
  G_S_LIM_EXPORT_TT: string
  G_P_LIM_EXPORT_TT: string
  G_D_IMP_THRES_TT: string
  G_P_IMP_THRES_TT: string
  G_S_IMP_THRES_TT: string
  G_D_EXP_THRES_TT: string
  G_P_EXP_THRES_TT: string
  G_S_EXP_THRES_TT: string

  BAT_POWER: string
  DISCHARGE: string
  CHARGE: string
  OFF: string
  BAT_POWER_REF: string
  BAT_D_POWER_REF_TT: string
  BAT_CHARGE_REF: string
  BAT_S_CHARGE_REF_TT: string
  BAT_P_CHARGE_REF_TT: string

  BAT_DISCHARGE_REF: string
  BAT_P_DISCHARGE_REF_TT: string
  BAT_S_DISCHARGE_REF_TT: string

  BAT_SOC: string
  BAT_SOC_LOW_REF: string
  BAT_SOC_LOW_REF_TT: string
  BAT_SOC_UPP_REF: string
  BAT_SOC_UPP_REF_TT: string

  BAT_TYPE: string
  SAVE: string
  CANCEL: string

  VAL_BAT_SOC_BETWEEN: string
  VAL_BAT_SOC_UP_GT_LW: string
  VAL_GRID_UP_GT_LW: string

  FORM_INVALID: string

  VAL_MUST_BE_HIGHER_THAN: string
  VAL_MUST_BE_LOWER_THAN: string

  REQUIRED: string

  SUCCESSFULLY_UPDATED_DEFAULT_ON_SYSTEM: string
  FAILED_TO_UPDATE_DEFAULT_ON_SYSTEM: string
  WAIT_FOR_CONFIG_TO_BE_APPLIED_ON_SYSTEM: string
  SYSTEM_HAS_RECIEVED_CHANGE_REQUEST: string
  OTHER_TRANSACTION_ONGOING_STRING: string
}

export const en: EmsConfigV2Language = {
  MODE: 'Operation mode',
  ADVANCED: 'Advanced',
  DEFAULT: 'Manual',
  PEAK_SHAVING: 'Peak Shaving',
  SELF_CONSUMPTION: 'Self-Consumption',
  MODE_INPUT: 'Choose a mode to configure operating settings.',
  DEFAULT_MODE_INFO: `
  <p>This mode is used if no battery is connected to the system or to initiate charging or discharging with a steady output. Specify the power level at which you want the battery to charge or discharge. </p>

  <p><b>Example Use Case:</b> Schedule battery charging at night when electricity prices are low. </p>

  <p><b>About the Setting:</b> The battery level limits (SoC) in this mode are 10–100%. The battery charges from both self-generated solar energy and the grid.</p>
       `,
  SELF_CONSUMPTION_MODE_INFO: `
    <p>This mode is used to increase self-consumption of solar energy. When the solar panels produce more electricity than is consumed in the building, the excess energy is stored in the battery rather than being sold to the grid. The battery discharges when consumption exceeds solar production. </p>
    <p><b>Example Use Case:</b> Common default setting during summer.</p>
    <p><b>About the Setting:</b> The battery level limits (SoC) in this mode are 10–100%. The battery only charges from self-generated solar energy. </p>
    `,
  PEAK_SHAVING_MODE_INFO: `
  <p>This mode is used for reducing power peaks. The battery discharges when the import of electricity from the grid exceeds a set threshold. Configure the level at which the battery begins discharging (Grid Import Threshold) to reduce peaks and the level at which the battery starts charging (Grid Export Threshold).</p>
  <p><b>Example Use Case:</b> If the grid fee is based on peak power usage, this setting lowers costs by reducing peak loads. </p>
  <p><b>About the Setting:</b> The battery level limits (SoC) in this mode are 10–100%. The battery charges from both self-generated solar energy and the grid.</p>
`,
  ADVANCED_INFO: `
  <p>Here, you can configure advanced operating settings.</p>

  <p><b>Example Use Cases:</b> Integrated EV charging via OCPP, integration with other systems, selling ancillary services etc.</p>

  <p><b>About the Setting:</b></p>
  <ul>
    <li>
      The “Limit Import for EV Charging and PowerShare” button is primarily used for EV charging via OCPP. When activated, EV charging is reduced when grid power reaches the configured maximum threshold.
    </li>
    <li>
      The “Limit Export of Solar Production” button is used to reduce solar energy production. When activated, production is throttled if electricity export reaches the configured grid export threshold. The grid export threshold should be set to 0  to avoid exporting to the grid.
    </li>
    <li>
      Note: You are responsible for the battery's functionality if you adjust the battery power or SoC levels outside the manufacturer’s recommendations. A battery that remains discharged below 10% for an extended period risk being damaged.
    </li>
  </ul>
  `,
  BAT_WIZARD: 'Battery Wizard',
  BAT_WIZARD_TT:
    'The wizard tool can be used to auto-fill recommended values for battery Power and SoC, based on installed battery system. ',

  PV: 'Solarproduction (PV)',
  PV_TT: 'Enable PV strings',
  ACE: 'Phasebalancing (ACE)',
  ACE_INPUT_LABEL: 'Current Threshold',
  ACE_TT: 'Enable current equalization (ACE), when current in any Mains phase exceeds the ACE threshold.',

  G_POWER: 'Grid Power',
  // GRID DEFAULT LIMIT

  /**
   * G_D Grid Default
   * G_S Grid self consumption
   * G_P Grid Peakshaving
   */
  G_LIM_EXPORT: 'Limit Export of Solar Production',
  G_D_LIM_EXPORT_TT:
    'If enabled, the system is not allowed to export power to the grid, below the Export Threshold',
  G_S_LIM_EXPORT_TT:
    'If enabled, the system is not allowed to export power to the grid, below the Export Threshold',
  G_P_LIM_EXPORT_TT:
    'If enabled, the system is not allowed to export power to the grid, below the Charge Threshold',

  G_LIM_IMPORT: 'Limit Import for EV Charging and PowerShare',
  G_D_LIM_IMPORT_TT:
    'If enabled, the system is not allowed to import power from the grid, above the Import Threshold',
  G_S_LIM_IMPORT_TT:
    'If enabled, the system is not allowed to import power from the grid, above the Import Threshold',
  G_P_LIM_IMPORT_TT:
    'If enabled, the system is not allowed to import power from the grid, above the Discharge Threshold',

  G_IMPORT_THRESHOLD: 'Grid Import Threshold',
  G_D_IMP_THRES_TT:
    'Threshold on grid power, above which the system is not allowed import power from the grid. ',
  G_S_IMP_THRES_TT: 'When the power from the grid exceeds this value, the battery starts discharging.',
  G_P_IMP_THRES_TT: 'When the power from the grid exceeds this value, the battery starts discharging.',
  G_EXPORT_THRESHOLD: 'Grid Export Threshold',
  G_S_EXP_THRES_TT: 'When the power from the grid falls below this value, the battery starts charging.',
  G_D_EXP_THRES_TT: 'When the power from the grid falls below this value, the battery starts charging.',
  G_P_EXP_THRES_TT: 'When the export to the power grid exceeds this value, the battery starts charging.',

  BAT_POWER: 'Battery Power',
  CHARGE: 'Charge',
  DISCHARGE: 'Discharge',
  OFF: 'Off',

  BAT_POWER_REF: 'Power Reference',
  BAT_D_POWER_REF_TT:
    'Constant power reference for the battery, as long as the SoC is within the upper and lower limits',

  BAT_CHARGE_REF: 'Charge Reference',
  BAT_S_CHARGE_REF_TT:
    'Maximum battery power, up to which charging battery from PV is is prioritized, if grid power is below the Export threshold',
  BAT_P_CHARGE_REF_TT:
    'Maximum battery power, up to which battery may be charged from grid, if grid power is below the Charge Threshold',
  BAT_DISCHARGE_REF: 'Discharge Reference',
  BAT_P_DISCHARGE_REF_TT:
    'Maximum battery power, up to which battery may be discharged into grid, if grid power is above the Discharge Threshold',
  BAT_S_DISCHARGE_REF_TT:
    'Maximum battery power, up to which battery may be discharged into grid, if grid power is above the Import Threshold',

  BAT_SOC: 'Battery State-of-Charge (SoC)',
  BAT_SOC_LOW_REF: 'Lower Reference',
  BAT_SOC_LOW_REF_TT: 'Battery state-of-charge (SoC), below which it is not allowed to discharge battery',
  BAT_SOC_UPP_REF: 'Upper Reference',
  BAT_SOC_UPP_REF_TT: 'Battery state-of-charge (SoC), above which it is not allowed to charge battery',

  BAT_TYPE: 'Battery',
  SAVE: 'Save',
  CANCEL: 'Cancel',

  VAL_BAT_SOC_BETWEEN: 'Battery SoC must be between 0 and 100',
  VAL_BAT_SOC_UP_GT_LW: 'Upper SoC reference must be greater than lower SoC reference',
  VAL_GRID_UP_GT_LW: 'Grid upper threshold must be greater than lower threshold',

  FORM_INVALID: 'Form is invalid, please check your inputs and try again. ',
  VAL_MUST_BE_HIGHER_THAN: 'Value must higher than: ',
  VAL_MUST_BE_LOWER_THAN: 'Value must be lower than: ',

  REQUIRED: 'This is a required field. Set to 0 (zero) if nothing else applies.',
  SUCCESSFULLY_UPDATED_DEFAULT_ON_SYSTEM: 'Successfully updated the settings on the system',
  FAILED_TO_UPDATE_DEFAULT_ON_SYSTEM:
    'The schedule has been saved but could not be applied to the system at this time. Cause:',
  WAIT_FOR_CONFIG_TO_BE_APPLIED_ON_SYSTEM: 'Waiting for the settings to be applied on the system',
  SYSTEM_HAS_RECIEVED_CHANGE_REQUEST: 'The system has recieved the change request and is processing it',
  OTHER_TRANSACTION_ONGOING_STRING: 'There is already another transaction ongoing, please try again soon.'
}

export const se: EmsConfigV2Language = {
  MODE: 'Driftläge',
  ADVANCED: 'Avancerad ',
  PEAK_SHAVING: 'Kapa effekttoppar',
  DEFAULT: 'Manuell',
  SELF_CONSUMPTION: 'Egenanvändning',
  MODE_INPUT: 'Välj ett läge för att börja ställa in driftsinställningarna:',

  ADVANCED_INFO: `
  <p>
  Här gör du avancerade driftinställningar.
  </p>
  <p><b>
  Exempel på användning:
  </b>
  Integrerad elbilsladdning via OCPP, integrationer med andra system, försäljning av stödtjänster etc.   </p>
  <p><b>
  Om inställningen:
  </b> </p>
  <ul>
  <li>
  Knappen “Begränsa import vid elbilsladdning och PowerShare” används framför allt vid elbilsladdning via OCPP. När knappen är aktiverad styrs elbilsladdningen ner när elnätsimporten når det inställda gränsvärdet.
  </li>
  <li>
  Knappen “Begränsa export av solproduktion” används för att styra ner solelproduktion. När knappen är aktiverad styrs produktion ner om exporten av el når det inställda gränsvärdet för elnätsexport. Gränsvärdet för elnätsexport ska då vara 0 för att undvika export till elnät.
  </li>
  <li>
  Notera att du själv är ansvarig för batteriets funktionalitet om du ändrar batterieffekt eller batterinivåerna (SoC) från tillverkarens rekommendationer. Ett batteri som under längre tid laddas ur till under 10% riskerar att skadas.
  </li>
 </ul>
`,
  DEFAULT_MODE_INFO: `
 <p>
 Detta driftläge används om inget batteri är anslutet till systemet eller för att starta i- eller urladdning med jämn effekt. Ange med vilken effekt du vill att batteriet ska ladda i eller ur med.

 </p>
  <p><b>
  Exempel på användning:   </b> Schemalägg laddning av batteriet på natten då elpriset är lågt.
  </p>
<p><b>
Om inställningen:</b>
  Gränserna för batterinivån (SoC) är i detta driftläge 10-100%. Batteriet tillåts ladda både från egen elproduktion och med el från elnätet.
</p>
  `,
  SELF_CONSUMPTION_MODE_INFO: `
  <p>
    Detta driftläge används för att öka egenanvändningen av solel. När solcellerna producerar mer el än vad som används i fastigheten lagras den i första hand i batteriet istället för att säljas till elnätet. Batteriet laddas ur när förbrukningen överstiger solproduktionen.
  </p>

  <p><b>Exempel på användning: </b> Vanlig grundinställning på sommaren.  </p>

  <p><b>Om inställningen:</b>
  Gränserna för batterinivån (SoC) är i detta driftläge 10-100%. Batteriet laddas enbart med egen elproduktion.
  </p>
`,
  PEAK_SHAVING_MODE_INFO: `
  <p>
  Detta driftläge används för att kapa effekttoppar. Det betyder att batterier börjar ladda ur när importen av el från elnätet går över en viss nivå. Ställ in vid vilken nivå batteriet ska börja ladda ur (Gränsvärde elnätsimport) för att kapa toppar och under vilken nivå batteriet ska börja ladda i (Gränsvärde elnätsexport).
</p>

<p><b>  Exempel på användning:</b>
Om elnätsavgiften har en effekttariff håller man nere priset genom att kapa effekttoppar.
</p>

<p><b>  Om inställningen:</b>
  Gränserna för batterinivån (SoC) är i detta driftläge 10-100%. Batteriet tillåts ladda både från egen elproduktion och med el från elnätet.
</p>
  `,

  PV: 'Solproduktion (PV)',
  PV_TT: 'Aktivera Solsträngar',

  ACE: 'Fasbalansering (ACE)',
  ACE_INPUT_LABEL: 'Ström-tröskelvärde',
  ACE_TT: 'Aktivera fasbalanseringen (ACE), när ström i någon fas överskrider fasbalanseringströskeln.',

  G_POWER: 'Näteffekt',
  G_LIM_EXPORT: 'Begränsa export av solproduktion',
  G_LIM_IMPORT: 'Begränsa import vid elbilsladdning och PowerShare',
  G_D_LIM_IMPORT_TT: 'Systemet får inte mata in effekt från elnätet ovanför importtröskeln',
  G_D_LIM_EXPORT_TT: 'Systemet får inte mata ut effekt på elnätet under exporttröskeln',
  G_S_LIM_IMPORT_TT: 'Systemet får inte dra in effekt från elnätet ovanför importtröskeln',
  G_S_LIM_EXPORT_TT: 'Systemet får inte mata ut effekt på elnätet under exporttröskeln',
  G_P_LIM_IMPORT_TT: 'Systemet får inte dra in effekt från elnätet ovanför urladdnings-tröskeln',
  G_P_LIM_EXPORT_TT: 'Systemet får inte mata ut effekt på elnätet under laddnings-tröskeln',

  G_IMPORT_THRESHOLD: 'Gränsvärde elnätsimport',
  G_D_IMP_THRES_TT: 'Näteffekttröskeln över vilket systemet inte får mata in kraft från elnätet.',
  G_S_IMP_THRES_TT: 'När näteffekten från elnätet överskrider detta värde börjar batteriet laddas ur',
  G_P_IMP_THRES_TT: 'När näteffekten från elnätet överskrider detta värde börjar batteriet laddas ur',

  G_EXPORT_THRESHOLD: 'Gränsvärde elnätsexport',
  G_D_EXP_THRES_TT: 'Näteffekttröskeln under vilket systemet inte får mata ut kraft på elnätet.',
  G_S_EXP_THRES_TT: 'När näteffekten från elnätet understiger detta värde börjar batteriet laddas i',
  G_P_EXP_THRES_TT: 'När näteffekten från elnätet understiger detta värde börjar batteriet laddas i',

  BAT_POWER: 'Batterieffekt',
  BAT_POWER_REF: 'Effekt-referens',
  BAT_D_POWER_REF_TT:
    'Konstant effektreferens för batteriet, så länge ladningsnivå ligger inom de övre och nedre gränserna',

  BAT_CHARGE_REF: 'Laddningseffekt-referens',
  BAT_S_CHARGE_REF_TT:
    'Maximalt batterieffekt upp till vilken laddning av batterier från solceller prioriteras, om näteffekt är lägre än export-tröskeln',
  BAT_P_CHARGE_REF_TT:
    'Maximalt batterieffekt upp till vilken laddning av batteri från nätet är tillåten, om näteffekt är lägre än laddnings-tröskeln',
  BAT_DISCHARGE_REF: 'Urladdningseffekt-referens',
  BAT_S_DISCHARGE_REF_TT:
    'Maximalt batterieffekt upp till vilken urladdning av batterier till nätet är tillåten, om näteffekt är över import-tröskeln',
  BAT_P_DISCHARGE_REF_TT:
    'Maximalt batterieffekt upp till vilken urladdning av batteri till nätet är tillåten, om näteffekt är över urladdnings-tröskeln',

  DISCHARGE: 'Laddar ur',
  CHARGE: 'Ladda',
  OFF: 'Av',

  BAT_SOC: 'Batterinivå (SoC)',
  BAT_SOC_LOW_REF: 'Nedre gräns',
  BAT_SOC_LOW_REF_TT: 'Batteriets laddningsnivå under vilket batteriet inte får laddas ur',
  BAT_SOC_UPP_REF: 'Övre gräns',
  BAT_SOC_UPP_REF_TT: 'Batteriets laddningsnivå över vilket batteriet inte får laddas upp',

  BAT_WIZARD: 'Batteri Wizard',
  BAT_WIZARD_TT:
    'Wizard-verktyget kan användas för att automatiskt fylla rekommenderade värden för batterieffekt och ladningsstatus, baserat på installerat batterisystem.',

  BAT_TYPE: 'Batteri',
  SAVE: 'Spara',
  CANCEL: 'Avbryt',

  VAL_BAT_SOC_BETWEEN: 'Batteri-nivåer måste sättas mellan 0 och 100%. ',
  VAL_BAT_SOC_UP_GT_LW: 'Övre gränsvärde för SoC-referens måste vara större än Nedre gränsvärdet.',
  VAL_GRID_UP_GT_LW:
    'Import-/urladdnings- nättröskelvärde måste vara större än Export-/Urladdnings- nättröskelvärdet',
  FORM_INVALID: 'Formulär är ej giltigt, kontroller värdena och försök igen',

  VAL_MUST_BE_HIGHER_THAN: 'Värdet måste vara högre än: ',
  VAL_MUST_BE_LOWER_THAN: 'Värdet måste vara lägre än: ',

  REQUIRED: 'Detta fält måste vara ifyll, sätt 0 (noll) om inget annat passar.',

  FAILED_TO_UPDATE_DEFAULT_ON_SYSTEM:
    'Schemat har sparats men kunde inte appliceras på systemet just nu. Orsak:  ',
  SUCCESSFULLY_UPDATED_DEFAULT_ON_SYSTEM: 'Lyckades att sätta standard-schemat på systemet',
  WAIT_FOR_CONFIG_TO_BE_APPLIED_ON_SYSTEM: 'Väntar på att inställningarna ska appliceras på systemet',
  SYSTEM_HAS_RECIEVED_CHANGE_REQUEST: 'Systemet har tagit emot ändringsbegäran och behandlar den',
  OTHER_TRANSACTION_ONGOING_STRING: 'Konfigureringen håller redan på att ändras, var god försök snart igen.'
}
