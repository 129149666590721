/* eslint-disable */
import moment from 'moment-timezone'
import { ApolloQueryResult } from '@apollo/client/core'
import { runQuery } from './graphql'
import {
  DatetimeRangeQuery,
  EnergyDateTimePeriod,
  EnergyDatePeriod,
  DateRangeQuery,
  SystemEnergyDate,
  SystemEnergyDateTime
} from '@app/graphql/generated'
import { PartialEnergyCounters, isDateRange, translateToLegacyOutput } from './graphql-energycounters.service'
import { GetDashboardEnergyCounterChartDayQuery, GetDashboardEnergyCounterQuery } from './generated'
export const GraphqlDashboardServiceName = 'graphqlDashboardService'

export class GraphqlDashboardService {
  energyCounterSummary(facilityId: number): Promise<ApolloQueryResult<GetDashboardEnergyCounterQuery>> {
    const today: DateRangeQuery = {
      gte: moment().utc().startOf('day').toDate(),
      lt: moment().utc().startOf('day').add(1, 'days').toDate()
    }

    const currentMonth: DateRangeQuery = {
      gte: moment().utc().startOf('month').toDate(),
      lt: moment().utc().endOf('month').add(1, 'days').startOf('month').toDate()
    }

    const currentYear: DateRangeQuery = {
      gte: moment().utc().startOf('year').toDate(),
      lt: moment().utc().endOf('year').add(1, 'days').startOf('year').toDate()
    }

    return runQuery.getDashboardEnergyCounterQuery({
      variables: {
        facilityId,
        today,
        currentMonth,
        currentYear
      }
    })
  }

  async energyCounterChart(
    facilityId: number,
    range: DatetimeRangeQuery,
    resolution: EnergyDateTimePeriod | EnergyDatePeriod
  ): Promise<PartialEnergyCounters[]> {
    if (isDateRange(resolution as EnergyDatePeriod)) {
      const res = await runQuery.getDashboardEnergyCounterChartDayQuery({
        variables: {
          facilityId,
          range,
          resolution: resolution as EnergyDatePeriod
        }
      })
      if (!res.data?.facility || res.error || res.errors) {
        throw new Error('Unable to fetch energy counters')
      }
      return res.data.facility.measurements.energy.map(e =>
        translateToLegacyOutput(e as unknown as Partial<SystemEnergyDate & SystemEnergyDateTime>)
      )
    } else {
      const res = await runQuery.getDashboardEnergyCounterChartQuery({
        variables: {
          facilityId,
          range,
          resolution: resolution as EnergyDateTimePeriod
        }
      })
      if (!res.data?.facility || res.error || res.errors) {
        throw new Error('Unable to fetch energy counters')
      }
      return res.data.facility.measurements.energy.map(e =>
        translateToLegacyOutput(e as unknown as Partial<SystemEnergyDate & SystemEnergyDateTime>)
      )
    }
  }
  energyCounterDaily(
    facilityId: number,
    range: DatetimeRangeQuery
  ): Promise<ApolloQueryResult<GetDashboardEnergyCounterChartDayQuery>> {
    return runQuery.getDashboardEnergyCounterChartDayQuery({
      variables: {
        facilityId,
        range,
        resolution: EnergyDatePeriod.Daily
      }
    })
  }
}
