import { Environment } from '../../environments/environment'
import { IHttpPromise, IHttpService } from 'angular'
import { languagesAvailable, LanguageService, LanguageServiceName } from './language.service'
/*
 *  Example response:
 * {
  'coord': {'lon': 16.49, 'lat': 59.4},
  'weather': [{'id': 801, 'main': 'Clouds', 'description': 'few clouds', 'icon': '02d'}],
  'base': 'stations',
  'main': {'temp': 283.15, 'pressure': 1007, 'humidity': 79, 'temp_min': 283.15, 'temp_max': 283.15},
  'visibility': 10000,
  'wind': {'speed': 5.1, 'deg': 340},
  'clouds': {'all': 20},
  'dt': 1538572800,
  'sys': {'type': 1, 'id': 5413, 'message': 0.0343, 'country': 'SE', 'sunrise': 1538543081, 'sunset': 1538583595},
  'id': 2667847,
  'name': 'Torshalla',
  'cod': 200
}

 */

export type OpenWeatherMapWeatherDataType = {
  id: number
  name: string
  cod: string
  dt: Date | number
  timezone: number
  coord: {
    lon: number // Longitude
    lat: number // Latitude
  }
  weather: {
    id: number
    main: string
    description: string
    icon: string
  }[]
  base: string
  main: {
    temp: number
    pressure: number
    humidity: number
    temp_min: number
    temp_max: number
  }

  visability: number
  wind: {
    speed: number
    deg: number
  }
  clouds: {
    all: number
  }
  sys: {
    type: number
    id: number
    message: number
    country: string
    sunrise: number
    sunset: number
  }
}

export const OpenWeatherMapServiceName = 'openWeatherMapService'

export class OpenWeatherMapService {
  private API_KEY = Environment.OpenWeatherMapApiKey

  private $http: IHttpService

  private url = 'https://api.openweathermap.org/data/2.5/weather'

  private lang: languagesAvailable

  constructor($http: IHttpService, languageService: LanguageService) {
    this.lang = languageService.language
    this.$http = $http
  }

  getWeatherData(latitude: number, longitude: number): IHttpPromise<OpenWeatherMapWeatherDataType> {
    return this.$http.get<OpenWeatherMapWeatherDataType>(this.url, {
      params: {
        APPID: this.API_KEY,
        // round 2 1 decimals due not give openweathermap exact position of customer but instead the "City" of the customer
        lat: Math.round(10 * latitude) / 10,
        lon: Math.round(10 * longitude) / 10,
        lang: this.lang === 'us' ? 'en' : 'se',
        units: 'metric'
      }
    })
  }
}

OpenWeatherMapService.$inject = ['$http', LanguageServiceName]
