import { StateService } from '@uirouter/angularjs'
import {
  copy,
  IComponentController,
  IComponentOptions,
  IDeferred,
  IQService,
  IScope,
  ITimeoutService
} from 'angular'
import {
  energy2String,
  PowershareBatterySchematic,
  PowershareSchematic,
  PowershareSolarSchematic
} from '../../../lib'
import { PowerSchematicValues } from '../../../lib/schematics/schematic.tools'
import { PortalLivedataParsedResultInterface, SystemData } from '../../../types'
import { LanguageSystemdashboard } from '../../language/language.interface'
import { FerroConfiguration, FerroConfigurationName, LivedataService } from '../../service'
import { getPowershareEnergyCounters } from '../data'
import './powershare-dashboard.component.scss'
let totalPVPOWER: { [k: number]: { power: number } } = {}
let totalBATTERY: { [k: number]: { power: number } } = {}

import { GetOnePowershareQuery } from '@app/graphql/generated'
import { runQuery } from '@app/graphql/graphql'
import { createLiveDataSubscriber, LiveDataSubscriber } from '@app/system/dashboard/live-data-utils'
import {
  // fixOldEnergyData,
  PowerShareEnergyObject
} from './parse-energycounters'
import templateUrl from './powershare-dashboard.component.html'

let facilityEnergies: { [k: string]: PowerShareEnergyObject } = {}

type PowershareFacility = {
  __typename?: 'Facility'
  id: string
  updatedAt: any
  name: string
} & { energydata?: PowerShareEnergyObject }

export class PowershareDashboardController implements IComponentController {
  clusterName: string
  clusterId: number
  cluster: GetOnePowershareQuery['powershare']
  facilities: PowershareFacility[] = []
  l: LanguageSystemdashboard

  isDemo = false
  hasSolar = false
  hasBattery = false

  powershareSolarSchematic: PowershareSolarSchematic
  powershareBatterySchematic: PowershareBatterySchematic
  schematics: {
    [key: string]: PowershareSchematic
  } = {}

  error = ''

  // totalBATTERY:number[] = [];
  // totalPVPOWER = [];
  totalSolarEnergy = ''
  // facilityEnergies = [];

  liveDataService: LivedataService
  $timeout: ITimeoutService
  $s: IScope
  canceller: IDeferred<unknown>
  // features: FeatureFlagService;
  onBlockLoaded: (facilityId: number, id: string) => void
  private liveData: LiveDataSubscriber

  constructor(
    $state: StateService,
    ferroConfiguration: FerroConfiguration,
    $timeout: ITimeoutService,
    $q: IQService,
    $scope: IScope
    // features: FeatureFlagService
  ) {
    // this.features = features;
    this.$s = $scope
    this.$timeout = $timeout
    this.canceller = $q.defer()
    this.l = ferroConfiguration.language.SystemDashboard
    this.isDemo = ferroConfiguration.isDemo

    this.clusterId = $state.params.id

    if (!this.clusterId) {
      $state.go('systemList')
      return
    }

    this.onBlockLoaded = (facilityId: number, id: string): void => {
      this.schematics[facilityId.toString()] = new PowershareSchematic(
        id,
        ferroConfiguration.language.SystemDashboard
      )
    }
  }

  async $onInit(): Promise<void> {
    const powershare = await runQuery.getOnePowershareQuery({
      variables: {
        where: {
          id: {
            equals: Number(this.clusterId)
          }
        }
      },
      fetchPolicy: 'network-only'
    })

    if (powershare.error || powershare.errors) {
      this.error = this.l.ERROR_OCCURRED
    } else {
      this.cluster = powershare.data?.powershare
      this.activate(this.cluster)
    }
  }

  $onDestroy(): void {
    this.liveDataService.stop()
    this.cluster = null
    facilityEnergies = {}
    this.schematics = {}
    this.canceller.resolve()
    totalBATTERY = {}
    totalPVPOWER = {}
  }

  liveDataHandler(data: PortalLivedataParsedResultInterface): void {
    if (!data.uiPowerData) return

    const facilityId = data.facilityId
    if (!this.schematics[facilityId.toString()]) return
    this.schematics[facilityId.toString()].update(data.uiPowerData as PowerSchematicValues)
    // eslint-disable-next-line
    if (data.uiPowerData.hasOwnProperty('batPower')) {
      if (!this.hasBattery) {
        this.hasBattery = true
        this.$s.$digest()
      }
      totalBATTERY[facilityId] = {
        power: data.uiPowerData.batPower
      }
      let batteryPower = 0
      for (const k in totalBATTERY) {
        if (totalBATTERY[k]) {
          const a = totalBATTERY[k].power
          batteryPower += isNaN(a) ? 0 : a
        }
      }

      if (this.powershareBatterySchematic) {
        this.powershareBatterySchematic.update(batteryPower)
      }
    }
    // eslint-disable-next-line
    if (data.uiPowerData.hasOwnProperty('pvPower')) {
      if (!this.hasSolar) {
        this.hasSolar = true
        this.$s.$digest()
      }
      totalPVPOWER[facilityId] = {
        power: data.uiPowerData.pvPower
      }
      let pvPower = 0
      for (const i in totalPVPOWER) {
        if (totalPVPOWER[i]) {
          const b = totalPVPOWER[i].power
          pvPower += isNaN(b) ? 0 : b
        }
      }
      if (this.powershareSolarSchematic) {
        this.powershareSolarSchematic.update(pvPower)
      }
    }

    if (data.sysData) {
      const ssd = data.sysData
      if (ssd.soc && ssd.soh && ssd.ratedCapacity) {
        const sysd: SystemData = {
          ts: data.ts,
          rC: data.sysData.ratedCapacity,
          soc: data.sysData.soc,
          soh: data.sysData.soh
        }
        this.powershareBatterySchematic.updateBatteryStatus(sysd)
      }
    }
  }

  async activate(powershare: GetOnePowershareQuery['powershare']): Promise<void> {
    this.clusterName = powershare.name
    const facilities = copy(powershare?.facilities)?.sort((n, p) => n.name.localeCompare(p.name))

    let index = 0

    facilities?.forEach(d => {
      // moment subtracting one hour to make sure get energy
      // @TODO add backend to make it possible to get latest energy
      getPowershareEnergyCounters(Number(d.id)).then(energyData => {
        if (energyData) {
          facilityEnergies[`facility_${d.id}`] = energyData
          this.facilities.push({ ...d, energydata: energyData })
        }
        index++
        if (index === powershare.facilities?.length) {
          this.further()
        }
      })
    })
  }

  further(): void {
    this.$timeout(() => {
      const ids = this.facilities.map(c => Number(c.id))

      this.liveData = createLiveDataSubscriber({
        facilityIds: ids
      })

      this.liveData.subscribe(data => this.liveDataHandler(data))

      //this.liveDataService.start((data) => {
      //  this.liveDataHandler(data);
      //});

      let solarEnergy = 0
      Object.keys(facilityEnergies).forEach(index => {
        if (facilityEnergies[index].solar) {
          solarEnergy += facilityEnergies[index].solarRaw
        }
      })
      this.$s.$applyAsync(() => {
        if (solarEnergy === 0) this.totalSolarEnergy = this.l.NO_DATA_FOUND
        else this.totalSolarEnergy = energy2String(Number(solarEnergy))
      })
    }, 1000)

    this.powershareBatterySchematic = new PowershareBatterySchematic('ClusterTotalBattery', this.l)
    this.powershareSolarSchematic = new PowershareSolarSchematic('ClusterTotalSolar')
  }
}

PowershareDashboardController.$inject = ['$state', FerroConfigurationName, '$timeout', '$q', '$scope']

export const PowershareDashboardComponentName = 'powershareDashboardComponent'

export const PowerShareDashboardComponent: IComponentOptions = {
  templateUrl,
  controller: PowershareDashboardController
}
