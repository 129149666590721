import { FeatureFlagServiceName } from '../../../service'
import { IHttpResponse, IPromise } from 'angular'
import { serviceClb } from '../../../service/types.service'
import {
  processEchartsEnergyData,
  ProcessedEchartEnergyData,
  processedEnergyPies,
  sumEnergyCounters
} from './energy-view.tools'
import { EnergyBucketTypes } from '../../../../types'
import { GraphqlEnergyCounterService, GraphqlEnergyCounterServiceName } from '@app/graphql'
import { FeatureFlagService } from '@app/service/feature-flag.service'
import { EnergyDateTimePeriod, EnergyDatePeriod } from '@app/graphql/generated'
import { EnergyTimePeriod } from '@app/graphql/graphql-energycounters.service'
import moment from 'moment-timezone'
export const EnergyViewServiceName = 'energyViewService'

function fixValues(value: number): number {
  const val = value === null ? null : Number(value)
  if (val < 0 || val === null) return null
  else if (val < 100000) {
    return Math.round((100 * val) / 1000) / 100
  } else return Math.round(val / 1000)
}

const translateResolution = (type: EnergyBucketTypes): EnergyDatePeriod => {
  switch (type) {
    case 'day':
      return EnergyDatePeriod.Daily
    case 'week':
      return EnergyDatePeriod.Weekly
    case 'month':
      return EnergyDatePeriod.Monthly
    case 'year':
      return EnergyDatePeriod.Yearly
    default:
      throw new Error(`Unknown resolution: ${type}`)
  }
}

export class EnergyViewService {
  facilityId: number
  canceller: IPromise<unknown>
  timezone: string
  // V2
  energyCounterService: GraphqlEnergyCounterService

  featureFlagService: FeatureFlagService

  constructor(energyCounterService: GraphqlEnergyCounterService, featureFlagService: FeatureFlagService) {
    this.energyCounterService = energyCounterService
    this.featureFlagService = featureFlagService
  }

  init(facilityId: number, timezone: string, canceller: IPromise<unknown>): void {
    this.facilityId = facilityId
    this.timezone = timezone
    this.canceller = canceller
  }

  /**
   *
   * @param starttime
   * @param endtime
   * @param done
   */

  getPies(
    starttime: Date,
    endtime: Date,
    done: serviceClb<IHttpResponse<string>, processedEnergyPies>
  ): void {
    this.energyCounterService
      .getEnergyCounters(
        this.facilityId,
        EnergyDatePeriod.Daily,
        moment.tz(starttime, this.timezone).add(moment.tz(this.timezone).utcOffset(), 'minute').toDate(),
        moment.tz(endtime, this.timezone).add(moment.tz(this.timezone).utcOffset(), 'minute').toDate()
      )
      .then(res => {
        return sumEnergyCounters(res)
      })
      .then(({ ec1, ec2, ec3, ep1, ep2, ep3, lc1, lc2, lc3, pve }: any) => {
        const totLOAD = lc1 + lc2 + lc3

        let totPVESold = ep1 + ep2 + ep3
        let totPVESelf = pve - (ep1 + ep2 + ep3)

        // Correction so not invalid data is shown
        totPVESold = pve <= 0 ? 0 : totPVESold
        totPVESelf = totPVESelf < 0 ? 0 : totPVESelf
        totPVESelf = pve <= 0 ? 0 : totPVESelf

        done(null, {
          pve: [fixValues(totPVESelf), fixValues(totPVESold)],
          loadPhases: [fixValues(lc1), fixValues(lc2), fixValues(lc3)],
          gridPhases: [fixValues(ec1), fixValues(ec2), fixValues(ec3)],
          pvVsLoad: [fixValues(totLOAD), fixValues(pve)],
          hasPve: pve > 0
        })
      })
  }

  getEquartz(
    starttime: Date,
    endtime: Date,
    callback: serviceClb<IHttpResponse<string>, ProcessedEchartEnergyData>
  ): void {
    this.energyCounterService
      .getEnergyCounters(this.facilityId, EnergyDateTimePeriod.QuarterHour, starttime, endtime)
      .then(res => {
        callback(null, processEchartsEnergyData(res, false, this.timezone))
      })
  }

  getAccumulated(
    starttime: Date,
    endtime: Date,
    callback: serviceClb<IHttpResponse<string>, ProcessedEchartEnergyData>
  ): void {
    this.energyCounterService
      .getAccumulatedCounters(this.facilityId, EnergyTimePeriod.Hourly, starttime, endtime)
      .then(res => {
        callback(null, processEchartsEnergyData(res, false, this.timezone))
      })
  }

  getDataHourly(
    starttime: Date,
    endtime: Date,
    callback: serviceClb<IHttpResponse<string>, ProcessedEchartEnergyData>
  ): void {
    this.energyCounterService
      .getEnergyCounters(this.facilityId, EnergyDateTimePeriod.Hourly, starttime, endtime)
      .then(res => {
        callback(null, processEchartsEnergyData(res, false, this.timezone))
      })
  }

  getLowerResolution(
    starttime: Date,
    endtime: Date,
    type: EnergyBucketTypes,
    callback: serviceClb<IHttpResponse<string>, ProcessedEchartEnergyData>
  ): void {
    this.energyCounterService
      .getEnergyCounters(
        this.facilityId,
        translateResolution(type),
        moment.tz(starttime, this.timezone).add(moment.tz(this.timezone).utcOffset(), 'minute').toDate(),
        moment.tz(endtime, this.timezone).add(moment.tz(this.timezone).utcOffset(), 'minute').toDate()
      )
      .then(res => {
        callback(null, processEchartsEnergyData(res, false, this.timezone))
      })
  }
}

EnergyViewService.$inject = [GraphqlEnergyCounterServiceName, FeatureFlagServiceName]
