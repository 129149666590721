import * as angular from 'angular'

/**
 * @ngdoc filter
 * @name ferroUser.filter:ferroFilter
 * @function
 * @description
 * First filter ever made by Viktor =) Used for filter in system.list
 */
export function ferroFilter() {
  return function (input: any[], search: string): any[] {
    if (!input) {
      return input
    }
    if (!search) {
      return input
    }
    const expected = `${search}`.toLowerCase()
    const result: any[] = []
    let keepGoing = true
    angular.forEach(input, value => {
      keepGoing = true

      angular.forEach(value, vl => {
        const actual = `${vl}`.toLowerCase()
        if (actual.indexOf(expected) !== -1 && keepGoing) {
          keepGoing = false
          result.push(value)
        }
      })
    })
    return result
  }
}
