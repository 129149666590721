import { ILogProvider, auto } from 'angular'

const _debugStyling = 'color: green;font-size: large;'

export function logLevelConfig($logProvider: ILogProvider, $provide: auto.IProvideService): void {
  $logProvider.debugEnabled(true)

  $provide.decorator('$log', [
    '$delegate',
    ($delegate: any) => {
      $delegate.debug = (...args: any[]) => {
        const _args = _formatDebug(args)
        // eslint-disable-next-line no-console,prefer-spread
        console.debug.apply(console, _args)
      }
      return $delegate
    }
  ])
}

logLevelConfig.$inject = ['$logProvider', '$provide']

function _formatDebug(...args: unknown[]) {
  const _args = Array.prototype.slice.call(args[0])
  _args.splice(0, 0, '%cDebug:')
  _args.splice(1, 0, _debugStyling)

  return _args
}
