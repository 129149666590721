import { ApolloClient, QueryOptions, SubscriptionOptions, MutationOptions } from '@apollo/client';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
  FacilityID: { input: any; output: any; }
  _FieldSet: { input: any; output: any; }
};

export type BatteryModuleData = LiveDataMessage & {
  __typename?: 'BatteryModuleData';
  energyConsumed: Scalars['Float']['output'];
  energyProduced: Scalars['Float']['output'];
  esoId: Scalars['ID']['output'];
  facilityId: Scalars['FacilityID']['output'];
  faultCodes?: Maybe<Scalars['Int']['output']>;
  i: Scalars['Float']['output'];
  mode: Scalars['Int']['output'];
  power: Scalars['Float']['output'];
  soc: Scalars['Float']['output'];
  temperature: Scalars['Float']['output'];
  timestamp: Scalars['DateTime']['output'];
  u: Scalars['Float']['output'];
};

/** A list of measurement values received from a EVSE terminal */
export type EvseMeterValue = LiveDataMessage & {
  __typename?: 'EvseMeterValue';
  /** The connector id of the EVSE (0 is for the whole charge_point) */
  connectorId?: Maybe<Scalars['Int']['output']>;
  /** Instantaneous current flow to EV (on phase L1). (A) */
  currentL1?: Maybe<Scalars['Float']['output']>;
  /** Instantaneous current flow to EV (on phase L2). (A) */
  currentL2?: Maybe<Scalars['Float']['output']>;
  /** Instantaneous current flow to EV (on phase L3). (A) */
  currentL3?: Maybe<Scalars['Float']['output']>;
  /** Energy imported by EV (Wh) */
  energyActiveMeter?: Maybe<Scalars['Float']['output']>;
  /** Facility ID of Energy Hub */
  facilityId: Scalars['FacilityID']['output'];
  /** Instantaneous active power imported by EV. (W) */
  powerActive?: Maybe<Scalars['Float']['output']>;
  /** Terminal ID of EVSE as defined in System Control Topology */
  terminalId: Scalars['String']['output'];
  /** Timestamp OCPP message was received or sent */
  timestamp: Scalars['DateTime']['output'];
};

export type LiveDataMessage = {
  /** Facility ID of Energy Hub */
  facilityId: Scalars['FacilityID']['output'];
  timestamp: Scalars['DateTime']['output'];
};

export type PowerDataSubscription = LiveDataMessage & {
  __typename?: 'PowerDataSubscription';
  batteryPower?: Maybe<Scalars['Float']['output']>;
  ehubPower: Scalars['Float']['output'];
  facilityId: Scalars['FacilityID']['output'];
  gridPower: Scalars['Float']['output'];
  gridSign: Scalars['Float']['output'];
  loadPower: Scalars['Float']['output'];
  pvPower?: Maybe<Scalars['Float']['output']>;
  timestamp: Scalars['DateTime']['output'];
};

export type Query = {
  __typename?: 'Query';
  hello: Scalars['String']['output'];
};

export type SsoData = LiveDataMessage & {
  __typename?: 'SsoData';
  energy?: Maybe<Scalars['Float']['output']>;
  facilityId: Scalars['FacilityID']['output'];
  faultCodes?: Maybe<Scalars['Int']['output']>;
  i: Scalars['Float']['output'];
  power?: Maybe<Scalars['Float']['output']>;
  relayStatus: Scalars['Int']['output'];
  ssoId?: Maybe<Scalars['ID']['output']>;
  temperature: Scalars['Float']['output'];
  timestamp: Scalars['DateTime']['output'];
  u: Scalars['Float']['output'];
};

export type Subscription = {
  __typename?: 'Subscription';
  newBatteryModuleData: Array<BatteryModuleData>;
  newEvseMeterValue: EvseMeterValue;
  newPowerData: PowerDataSubscription;
  newSsoData: Array<SsoData>;
  newSystemData: SystemDataSubscription;
  newUiData: UiDataSubscription;
};


export type SubscriptionNewBatteryModuleDataArgs = {
  facilityId: Scalars['FacilityID']['input'];
};


export type SubscriptionNewEvseMeterValueArgs = {
  facilityId: Scalars['FacilityID']['input'];
};


export type SubscriptionNewPowerDataArgs = {
  facilityId: Scalars['FacilityID']['input'];
};


export type SubscriptionNewSsoDataArgs = {
  facilityId: Scalars['FacilityID']['input'];
};


export type SubscriptionNewSystemDataArgs = {
  facilityId: Scalars['FacilityID']['input'];
};


export type SubscriptionNewUiDataArgs = {
  facilityId: Scalars['FacilityID']['input'];
};

export type SystemDataSubscription = LiveDataMessage & {
  __typename?: 'SystemDataSubscription';
  facilityId: Scalars['FacilityID']['output'];
  ratedCapacity?: Maybe<Scalars['Float']['output']>;
  soc?: Maybe<Scalars['Float']['output']>;
  soh?: Maybe<Scalars['Float']['output']>;
  timestamp: Scalars['DateTime']['output'];
};

export type UiDataSubscription = LiveDataMessage & {
  __typename?: 'UiDataSubscription';
  /** Facility ID of Energy Hub */
  facilityId: Scalars['FacilityID']['output'];
  /** i = Current, Ext = External, D=Reactive Current, 1=Phase 1. This current is measured as Peak Reactive External Current on Phase 1. Where External Current, can be seen as "GridCurrent", ss the current measured by the Current Transformers as the Main Fuse */
  iExtD1: Scalars['Float']['output'];
  /** i = Current, Ext = External, D=Reactive Current, 2=Phase 2. This current is measured as Peak Reactive External Current on Phase 2. Where External Current, can be seen as "GridCurrent", ss the current measured by the Current Transformers as the Main Fuse */
  iExtD2: Scalars['Float']['output'];
  /** i = Current, Ext = External, D=Reactive Current, 3=Phase 3. This current is measured as Peak Reactive External Current on Phase 3. Where External Current, can be seen as "GridCurrent", ss the current measured by the Current Transformers as the Main Fuse */
  iExtD3: Scalars['Float']['output'];
  /** i = Current, Ext = External, Q=Active Current, 1=Phase 1. This current is measured as Peak Active Current External Current on Phase 1. Where External Current, can be seen as "GridCurrent", ss the current measured by the Current Transformers as the Main Fuse */
  iExtQ1: Scalars['Float']['output'];
  /** i = Current, Ext = External, Q=Active Current, 2=Phase 2. This current is measured as Peak Active Current External Current on Phase 2. Where External Current, can be seen as "GridCurrent", ss the current measured by the Current Transformers as the Main Fuse */
  iExtQ2: Scalars['Float']['output'];
  /** i = Current, Ext = External, Q=Active Current, 3=Phase 3. This current is measured as Peak Active Current External Current on Phase 3. Where External Current, can be seen as "GridCurrent", ss the current measured by the Current Transformers as the Main Fuse */
  iExtQ3: Scalars['Float']['output'];
  iExtQN: Scalars['Float']['output'];
  /** i = Current, Ext = External, RMS=Root Mean Square, 1=Phase 1. This current is measured as Total External Current on Phase 1. Where External Current, can be seen as "GridCurrent", ss the current measured by the Current Transformers as the Main Fuse */
  iExtRms1: Scalars['Float']['output'];
  /** i = Current, Ext = External, RMS=Root Mean Square, 2=Phase 2. This current is measured as Total External Current on Phase 2. Where External Current, can be seen as "GridCurrent", ss the current measured by the Current Transformers as the Main Fuse */
  iExtRms2: Scalars['Float']['output'];
  /** i = Current, Ext = External, RMS=Root Mean Square, 3=Phase 3. This current is measured as Total External Current on Phase 3. Where External Current, can be seen as "GridCurrent", ss the current measured by the Current Transformers as the Main Fuse */
  iExtRms3: Scalars['Float']['output'];
  iExtRmsN: Scalars['Float']['output'];
  /** i = Current, Inv = Inverter, D=Reactive Current, 1=Phase 1. This current is measured as Peak Reactive Current on Phase 1. */
  iInvD1: Scalars['Float']['output'];
  /** i = Current, Inv = Inverter, D=Reactive Current, 2=Phase 2. This current is measured as Peak Reactive Current on Phase 2. */
  iInvD2: Scalars['Float']['output'];
  /** i = Current, Inv = Inverter, D=Reactive Current, 3=Phase 3. This current is measured as Peak Reactive Current on Phase 3. */
  iInvD3: Scalars['Float']['output'];
  /** i = Current, Inv = Inverter, Q=Active Current, 1=Phase 1. This current is measured as  Peak Active Current on Phase 1. */
  iInvQ1: Scalars['Float']['output'];
  /** i = Current, Inv = Inverter, Q=Active Current, 2=Phase 2. This current is measured as Peak Active Current on Phase 2. */
  iInvQ2: Scalars['Float']['output'];
  /** i = Current, Inv = Inverter, Q=Active Current, 3=Phase 3. This current is measured as Peak Active Current on Phase 3. */
  iInvQ3: Scalars['Float']['output'];
  iInvQN: Scalars['Float']['output'];
  iLoadQ1: Scalars['Float']['output'];
  iLoadQ2: Scalars['Float']['output'];
  iLoadQ3: Scalars['Float']['output'];
  iLoadQN: Scalars['Float']['output'];
  /** Timestamp of data, extracted from message_id */
  timestamp: Scalars['DateTime']['output'];
  /** Voltage of Grid Phase 1 */
  u1: Scalars['Float']['output'];
  /** Voltage of Grid Phase 2 */
  u2: Scalars['Float']['output'];
  /** Voltage of Grid Phase 3 */
  u3: Scalars['Float']['output'];
  /** Voltage of Negative conductor in Ferroamps DC Nanogrid */
  uDCneg: Scalars['Float']['output'];
  /** Voltage of Positive conductor in Ferroamps DC Nanogrid */
  uDCpos: Scalars['Float']['output'];
};

export type OnEvseMetervalueSubscriptionVariables = Exact<{
  facilityId: Scalars['FacilityID']['input'];
}>;


export type OnEvseMetervalueSubscription = { __typename?: 'Subscription', newEvseMeterValue: { __typename?: 'EvseMeterValue', terminalId: string, timestamp: any, powerActive?: number | null, energyActiveMeter?: number | null, currentL1?: number | null, currentL2?: number | null, currentL3?: number | null } };

export type OnUiDataSubscriptionVariables = Exact<{
  facilityId: Scalars['FacilityID']['input'];
}>;


export type OnUiDataSubscription = { __typename?: 'Subscription', newUiData: { __typename?: 'UiDataSubscription', facilityId: any, iExtQ1: number, iExtQ2: number, iExtQ3: number, iExtQN: number, iExtRms1: number, iExtRms2: number, iExtRms3: number, iExtRmsN: number, iInvQ1: number, iInvQ2: number, iInvQ3: number, iInvQN: number, iLoadQ1: number, iLoadQ2: number, iLoadQ3: number, iLoadQN: number, timestamp: any, u1: number, u2: number, u3: number, uDCneg: number, uDCpos: number } };

export type OnPowerDataSubscriptionVariables = Exact<{
  facilityId: Scalars['FacilityID']['input'];
}>;


export type OnPowerDataSubscription = { __typename?: 'Subscription', newPowerData: { __typename?: 'PowerDataSubscription', batteryPower?: number | null, ehubPower: number, facilityId: any, gridPower: number, gridSign: number, loadPower: number, pvPower?: number | null, timestamp: any } };

export type UiDataLiveDataViewSubscriptionVariables = Exact<{
  facilityId: Scalars['FacilityID']['input'];
}>;


export type UiDataLiveDataViewSubscription = { __typename?: 'Subscription', newUiData: { __typename?: 'UiDataSubscription', facilityId: any, iExtQ1: number, iExtQ2: number, iExtQ3: number, iExtQN: number, iExtRms1: number, iExtRms2: number, iExtRms3: number, iExtRmsN: number, iInvQ1: number, iInvQ2: number, iInvQ3: number, iInvQN: number, iLoadQ1: number, iLoadQ2: number, iLoadQ3: number, iLoadQN: number, timestamp: any, iInvD1: number, iInvD2: number, iInvD3: number, iExtD1: number, iExtD2: number, iExtD3: number, u1: number, u2: number, u3: number, uDCneg: number, uDCpos: number } };

export type PowerDataLiveDataViewSubscriptionVariables = Exact<{
  facilityId: Scalars['FacilityID']['input'];
}>;


export type PowerDataLiveDataViewSubscription = { __typename?: 'Subscription', newPowerData: { __typename?: 'PowerDataSubscription', batteryPower?: number | null, ehubPower: number, facilityId: any, gridPower: number, gridSign: number, loadPower: number, pvPower?: number | null, timestamp: any } };

export type OnSsoDataSubscriptionVariables = Exact<{
  facilityId: Scalars['FacilityID']['input'];
}>;


export type OnSsoDataSubscription = { __typename?: 'Subscription', newSsoData: Array<{ __typename?: 'SsoData', ssoId?: string | null, power?: number | null, energy?: number | null }> };

export type OnBatteryModuleDataSubscriptionVariables = Exact<{
  facilityId: Scalars['FacilityID']['input'];
}>;


export type OnBatteryModuleDataSubscription = { __typename?: 'Subscription', newBatteryModuleData: Array<{ __typename?: 'BatteryModuleData', esoId: string, power: number, soc: number }> };


export const OnEvseMetervalueDocument = gql`
    subscription OnEvseMetervalue($facilityId: FacilityID!) {
  newEvseMeterValue(facilityId: $facilityId) {
    terminalId
    timestamp
    powerActive
    energyActiveMeter
    currentL1
    currentL2
    currentL3
  }
}
    `;
export const OnUiDataDocument = gql`
    subscription OnUiData($facilityId: FacilityID!) {
  newUiData(facilityId: $facilityId) {
    facilityId
    iExtQ1
    iExtQ2
    iExtQ3
    iExtQN
    iExtRms1
    iExtRms2
    iExtRms3
    iExtRmsN
    iInvQ1
    iInvQ2
    iInvQ3
    iInvQN
    iLoadQ1
    iLoadQ2
    iLoadQ3
    iLoadQN
    timestamp
    u1
    u2
    u3
    uDCneg
    uDCpos
  }
}
    `;
export const OnPowerDataDocument = gql`
    subscription OnPowerData($facilityId: FacilityID!) {
  newPowerData(facilityId: $facilityId) {
    batteryPower
    ehubPower
    facilityId
    gridPower
    gridSign
    loadPower
    pvPower
    timestamp
  }
}
    `;
export const UiDataLiveDataViewDocument = gql`
    subscription UiDataLiveDataView($facilityId: FacilityID!) {
  newUiData(facilityId: $facilityId) {
    facilityId
    iExtQ1
    iExtQ2
    iExtQ3
    iExtQN
    iExtRms1
    iExtRms2
    iExtRms3
    iExtRmsN
    iInvQ1
    iInvQ2
    iInvQ3
    iInvQN
    iLoadQ1
    iLoadQ2
    iLoadQ3
    iLoadQN
    timestamp
    iInvD1
    iInvD2
    iInvD3
    iExtD1
    iExtD2
    iExtD3
    u1
    u2
    u3
    uDCneg
    uDCpos
  }
}
    `;
export const PowerDataLiveDataViewDocument = gql`
    subscription PowerDataLiveDataView($facilityId: FacilityID!) {
  newPowerData(facilityId: $facilityId) {
    batteryPower
    ehubPower
    facilityId
    gridPower
    gridSign
    loadPower
    pvPower
    timestamp
  }
}
    `;
export const OnSsoDataDocument = gql`
    subscription OnSsoData($facilityId: FacilityID!) {
  newSsoData(facilityId: $facilityId) {
    ssoId
    power
    energy
  }
}
    `;
export const OnBatteryModuleDataDocument = gql`
    subscription OnBatteryModuleData($facilityId: FacilityID!) {
  newBatteryModuleData(facilityId: $facilityId) {
    esoId
    power
    soc
  }
}
    `;
export const getSdk = (client: ApolloClient<any>) => ({
      onEvseMetervalueSubscription(options: Partial<SubscriptionOptions<OnEvseMetervalueSubscriptionVariables, OnEvseMetervalueSubscription>>) {
          return client.subscribe<OnEvseMetervalueSubscription, OnEvseMetervalueSubscriptionVariables>({...options, query: OnEvseMetervalueDocument})
      },
onUiDataSubscription(options: Partial<SubscriptionOptions<OnUiDataSubscriptionVariables, OnUiDataSubscription>>) {
          return client.subscribe<OnUiDataSubscription, OnUiDataSubscriptionVariables>({...options, query: OnUiDataDocument})
      },
onPowerDataSubscription(options: Partial<SubscriptionOptions<OnPowerDataSubscriptionVariables, OnPowerDataSubscription>>) {
          return client.subscribe<OnPowerDataSubscription, OnPowerDataSubscriptionVariables>({...options, query: OnPowerDataDocument})
      },
uiDataLiveDataViewSubscription(options: Partial<SubscriptionOptions<UiDataLiveDataViewSubscriptionVariables, UiDataLiveDataViewSubscription>>) {
          return client.subscribe<UiDataLiveDataViewSubscription, UiDataLiveDataViewSubscriptionVariables>({...options, query: UiDataLiveDataViewDocument})
      },
powerDataLiveDataViewSubscription(options: Partial<SubscriptionOptions<PowerDataLiveDataViewSubscriptionVariables, PowerDataLiveDataViewSubscription>>) {
          return client.subscribe<PowerDataLiveDataViewSubscription, PowerDataLiveDataViewSubscriptionVariables>({...options, query: PowerDataLiveDataViewDocument})
      },
onSsoDataSubscription(options: Partial<SubscriptionOptions<OnSsoDataSubscriptionVariables, OnSsoDataSubscription>>) {
          return client.subscribe<OnSsoDataSubscription, OnSsoDataSubscriptionVariables>({...options, query: OnSsoDataDocument})
      },
onBatteryModuleDataSubscription(options: Partial<SubscriptionOptions<OnBatteryModuleDataSubscriptionVariables, OnBatteryModuleDataSubscription>>) {
          return client.subscribe<OnBatteryModuleDataSubscription, OnBatteryModuleDataSubscriptionVariables>({...options, query: OnBatteryModuleDataDocument})
      }
    });
    export type SdkType = ReturnType<typeof getSdk>
