import { Environment } from '@environments/environment'
import { UnleashClient } from 'unleash-proxy-client'

export class UnleashPortal {
  private unleash: UnleashClient

  constructor() {
    this.unleash = new UnleashClient({
      url: Environment.fronteendUnleashUrl,
      clientKey: 'oom0Dah9vee8uTie9uMied8iephah6',
      appName: Environment.unleashAppName
    })

    this.start()
  }

  private async start() {
    try {
      await this.unleash.start()
    } catch {
      setTimeout(() => {
        this.start()
      }, 10000)
    }
  }

  showMap() {
    return this.unleash.isEnabled('show-map')
  }
}

export const UnleashPortalName = 'features'
