import angular, { IHttpInterceptor, IHttpProvider } from 'angular'
import { getToken, keycloak } from './keycloak'
export { keycloak }

const ignoreAuth = ['api.openweathermap.org']

const shouldIgnore = (url: string) => {
  let ignoreIt = false
  ignoreAuth.map(ignore => {
    if (url.includes(ignore)) {
      ignoreIt = true
    }
  })
  return ignoreIt
}

const httpAuthFactory = () => {
  return {
    request: async config => {
      if (shouldIgnore(config.url)) return config
      config.headers['Authorization'] = getToken()
      return config
    }
  } as IHttpInterceptor
}

const httpAuthConfig = ($httpProvider: IHttpProvider) => {
  $httpProvider.interceptors.push('httpAuthFactory')
}

httpAuthConfig.$inject = ['$httpProvider']
export const AppAuth = angular
  .module('app.auth', [])
  .factory('httpAuthFactory', httpAuthFactory)
  .config(httpAuthConfig).name
