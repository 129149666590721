import { ApolloQueryResult } from '@apollo/client'
import { Query } from './generated'

export enum GRAPHQL_ERRORS {
  NO_DATA = 'NO_DATA',
  GENERAL_ERROR = 'ERROR'
}

export const GRAPHQL_ERROR_NO_DATA = (): void => {
  throw new Error(GRAPHQL_ERRORS.NO_DATA)
}
export const GRAPHQL_ERROR_GENERAL_ERROR = (): void => {
  throw new Error(GRAPHQL_ERRORS.GENERAL_ERROR)
}

export const graphqlOnError = (queryResult: ApolloQueryResult<Query>, key: 'gridData' | 'power'): void => {
  if (queryResult.error || queryResult.errors) {
    GRAPHQL_ERROR_GENERAL_ERROR()
  }
  if (!queryResult.data?.facility?.measurements?.[key].length) GRAPHQL_ERROR_NO_DATA()
}
