import '@uirouter/angularjs'
import angular from 'angular'
import 'angular-animate'
import 'angular-aria'
import 'angular-cookies'
import 'angular-material'
import 'angular-material-data-table'
import 'angular-material-data-table/dist/md-data-table.css'
import 'angular-material/angular-material.css'
import 'angular-messages'
import 'angular-sanitize'
import 'long'
import { AVAILABLE_EXTERNAL_MODULES, Environment } from '../environments/environment'
import { appRouterConfig } from './app.routing'
import { AppAuth, keycloak } from './auth/auth'
import { bootstrap } from './bootstrap'
import { AppComponentModule } from './components'
import { AppConfig } from './config'
import { InitateSentry } from './config/sentry'
import { AppDirectives } from './directives/directives.module'
import { AppFilters } from './filters/app.filters'
import { AppGlobalSettings } from './global-settings'
import { AppGraphQl } from './graphql'
import { AppPowerShareModule } from './powershare'
import { AppRun } from './run'
import { AppSystemModule } from './system'
import { AppSystemList } from './system-list'

InitateSentry()

const modules = [
  AVAILABLE_EXTERNAL_MODULES.uiRouter,
  AVAILABLE_EXTERNAL_MODULES.ngCookies,
  AVAILABLE_EXTERNAL_MODULES.ngSanitize,
  AVAILABLE_EXTERNAL_MODULES.ngMaterial,
  AVAILABLE_EXTERNAL_MODULES.ngAnimate,
  AVAILABLE_EXTERNAL_MODULES.ngMessages,
  AVAILABLE_EXTERNAL_MODULES.mdDataTable,
  AppAuth,
  AppConfig,
  AppRun,
  AppFilters,
  AppComponentModule,
  AppGraphQl,
  AppPowerShareModule,
  AppSystemList,
  AppGlobalSettings,
  AppSystemModule,
  AppDirectives
]

;(async () => {
  if (DEVELOPMENT) {
    const authorized = await keycloak.init({
      checkLoginIframe: Environment.isProd,
      onLoad: 'login-required',
      pkceMethod: 'S256'
    })
    if (authorized) {
      await bootstrap()
      angular.module('app', modules).config(appRouterConfig)
      angular.bootstrap(document, ['app'])
    } else {
      keycloak.login()
    }
  } else {
    const authorized = await keycloak.init({
      onLoad: 'login-required',
      pkceMethod: 'S256'
    })
    if (authorized) {
      await bootstrap()
      angular.module('app', modules).config(appRouterConfig)
      angular.bootstrap(document, ['app'])
    } else {
      keycloak.login()
    }
  }
})()
