import * as moment from 'moment-timezone'
import { DEFAULT_TIMEZONE } from '../../environments/environment'
import { FerroConfiguration } from '../service'

export function parseHourDate(ferroConfiguration: FerroConfiguration) {
  return function (date: Date): string {
    let timezone = DEFAULT_TIMEZONE
    if (ferroConfiguration.facility && ferroConfiguration.facility.timezone) {
      timezone = ferroConfiguration.facility.timezone
    }
    if (moment.isDate(new Date(date))) {
      return moment.tz(new Date(date), timezone).format('YYYY-MM-DD HH:00')
    } else {
      return 'Invalid Date'
    }
  }
}

parseHourDate.$inject = ['ferroConfiguration']
