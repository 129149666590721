import { GetFacilityHistoryQuery } from '@app/graphql/generated'
import { runQuery } from '@app/graphql/graphql'
import angular, { IComponentOptions, IScope, copy, material } from 'angular'
import { LanguageSystemSettings } from '../../../../language/language.interface'
import { FerroConfiguration, FerroConfigurationName } from '../../../../service'
import templateUrl from './system-information-changes.component.html'
import dialogTemplateUrl from './system-information.dialog.component.html'
import { SystemInformationDialogController } from './system-information.dialog.controller'

export type SystemInformationChangesList = (GetFacilityHistoryQuery['facility']['history'][0] & {
  email: string
})[]

class SystemInformationChangesController {
  changeList: SystemInformationChangesList
  changeListOrder = 'createdAt'
  dialog: material.IDialogService

  facilityId: number

  l: LanguageSystemSettings

  $scope: IScope
  constructor($scope: IScope, dialog: material.IDialogService, ferroConfiguration: FerroConfiguration) {
    this.$scope = $scope
    this.dialog = dialog
    this.facilityId = Number(ferroConfiguration.facility.id)
    this.l = ferroConfiguration.language.SystemSettings
  }

  async $onInit() {
    // @TODO UPDATE
    const res = await runQuery.getFacilityHistoryQuery({
      variables: {
        facilityId: this.facilityId
      }
    })
    const userMap: { [_: string]: string } = {}
    res?.data?.facility?.users?.map(({ id, email }) => {
      userMap[id] = email
    })
    const history = res?.data?.facility?.history
      .map(h => {
        let email = userMap[h.userId]
        if (/ferroamp/g.test(email)) {
          email = 'Ferroamp Support'
        }
        return {
          ...h,
          email
        }
      })
      .filter(({ email }) => email)

    this.$scope.$applyAsync(() => {
      this.changeList = copy(history)
    })
  }

  seeChanges(event: MouseEvent, changes: SystemInformationChangesList): void {
    this.dialog.show({
      locals: {
        changeData: changes
      },
      controller: [FerroConfigurationName, 'changeData', SystemInformationDialogController],
      controllerAs: 'fmdia',
      templateUrl: dialogTemplateUrl,
      parent: angular.element(document.body),
      targetEvent: event,
      clickOutsideToClose: true
    })
  }
}

SystemInformationChangesController.$inject = ['$scope', '$mdDialog', FerroConfigurationName]

export const SystemInformationChangesComponent: IComponentOptions = {
  controller: SystemInformationChangesController,
  templateUrl
}
