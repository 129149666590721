import { LanguageInterface } from './language.interface'

export const SwedishLanguage: LanguageInterface = {
  Globally: {
    monthlon: [
      'Januari',
      'Februari',
      'Mars',
      'April',
      'Maj',
      'Juni',
      'Juli',
      'Augusti',
      'September',
      'Oktober',
      'November',
      'December'
    ],
    monshort: ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],
    daylong: ['Måndag', 'Tisdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lördag', 'Söndag'],
    dayshort: ['M', 'T', 'O', 'T', 'F', 'L', 'S'],
    firstDateOfWeek: 1,

    engcountInfo:
      'Denna funktion kräver senaste mjukvaran i EnergyHub vilket inkluderar energiräknare. (Solenergi funkar oavsett)',
    engcountInfo2: 'Om du inte har det ber vi dig kontakta Ferroamp för uppgradering',
    discharge: 'Laddar ur',
    charge: 'Laddar',
    NOT_AVAILABLE: 'Ej tillgänglig',
    EXPORT: 'Exportera',
    IMPORT: 'Importera'
  },

  Scheduling: {
    TODAY: 'Idag',
    SCHEDULING: 'Schemaläggning',
    INITIATION:
      'För att initiera schemat måste du först skapa ett standard-schema. Skapa standard-schemat genom att klicka på knappen nedan, vilket skapar standard-schemat från dina nuvarande driftsinställningar. Du kommer att kunna ändra standard-schemat senare.',
    DEFAULT_INITIATION:
      'När du har skapat standard-schemat kommer du att kunna skapa nya schemaläggningsevenemang som kommer att köras istället för standard-schemat.',
    USE_CURRENT_OPERATION_AS_DEFAULT: 'Använd nuvarande driftsinställningar som standard-schema',
    EDIT_DEFAULT: 'Redigera standard-schema',
    EVENTS: 'Händelser',
    CREATE_NEW_SCHEDULE: 'Skapa ett nytt schema',
    GO_TO_DOCUMENTATION: 'Gå till dokumentation',
    LAST_APPLIED: 'Senast tillämpad',
    STARTTIME: 'Starttid',
    ENDTIME: 'Sluttid',
    EDIT: 'Redigera',
    REMOVE: 'Ta bort',
    READ_ONLY_CALENDAR:
      'För närvarande är detta en endast läs-vy, för att visualisera de veckovisa schemaläggningarna.',
    DEFAULT_SCHEDULING: 'Standard-schemaläggning',
    SCHEDULING_EVENT: 'Schemaläggningsevent',
    SETTINGS_INVALID: 'Driftsinställningarna är ogiltiga, vänligen rätta till och försök igen',
    PLEASE_ADD_SUMMARY: 'Schemaläggningsinställningarna, vänligen lägg till en sammanfattning',
    MUST_CHOOSEN_ONE_DAY: 'Du måste välja minst 1 dag',
    START_END_NOT_SAME: 'Start och slut kan inte vara samma',
    CREATE_A_NEW: 'skapa',
    UPDATE_THE: 'uppdaterade',
    UPDATED_THE_DEFAULT_APPLIED_NEXT:
      'Lyckades uppdaterade standard-schemat och kommer appliceras nästa gång inga andra scheman är aktiva',
    UPDATED_THE_DEFAULT: 'uppdaterade standard',
    SUCCESSFULLY: 'Lyckades att {VALUE} eventet',
    FAILED_TO: 'Misslyckades med att {VALUE} eventet',
    MODIFY_DEFAULT_SCHEDULE: 'Ändra standard-schemat',
    CREATE_SCHEDULE_INFO:
      'Skapa schemat genom att fylla i under vilka timmar det ska inträffa och vilka dagar.',
    SUMMARY: 'Sammanfattning',
    SUMMARY_IS_REQUIRED: 'Sammanfattning är obligatorisk',
    DESCRIPTION: 'Beskrivning',
    THE_TIME_INTERVAL: 'Tidsintervall',
    IF_END_IS_LESS_INFO: 'Om slut-tiden är tidigare än start-tiden, antar vi att det ska sluta nästa dag.',
    DURING_WHICH_DAYS: 'Under vilka dagar vill du att dessa händelser ska inträffa?',
    DURING_WHICH_DAYS_CHOOSE: 'Du kan välja en eller flera, men minst en.',
    OPERATION_SETTINGS: 'Driftsinställningar',
    OPERATION_SETTINGS_FOR_THE_EVENT: 'Ställ in driftsinställningarna för det schemalagda eventet',
    STORE: 'Spara',
    CALENDAR_VIEW_TITLE: 'Kalender',
    CONFIRM_ENABLE: 'Bekräftelse om att aktivera schemaläggning',
    CONFIRM_INFO:
      'Vänligen läs igenom dokumentationen på <a href="{SUPPORT_LINK}" target="_blank">support.ferroamp.com</a> och se till att du förstår innan du börjar med någon schemaläggning.',
    CONFIRM_UNDERSTAND: 'Jag förstår och vill prova.',
    CANCEL: 'Avbryt',
    ERROR_OCCURED_PLEASE_TRY_AGAIN: 'Ett fel inträffade, vänligen försök igen senare.',
    SUCCESS_FULLY_SET_DEFAULT_SCHEDULE: 'Standard-schemat har satts framgångsrikt',
    FAILED_REMOVED_EVENT: 'Misslyckades med att ta bort event, vänligen försök igen senare.',
    SUCCESS_FULLY_REMOVED_EVENT: 'Event borttaget framgångsrikt:',
    REMOVE_EVENT: 'Ta bort event:',
    SURE_REMOVE_EVENT: 'Är du säker på att du vill ta bort detta event?',
    YES_IM_SURE: 'Ja, jag är säker.',
    SHORT_SUMMARY: 'Kort sammanfattning',
    DETAILED_DESCRIPTION: 'Detaljerad beskrivning',
    DEFAULT_SCHEMA_INFO:
      "När ingen vald schemahändelse är aktiv återgår systemet automatiskt till standardschemat. För att ändra detta schema, klicka på 'Redigera standardschema'.",
    FOR_MORE_INFORMATION_SCHEDULING: 'För mer information klicka här',
    SAVE_AND_APPLY: 'Spara och tillämpa'
  },
  Header: {
    CHANGE_SETTINGS: 'ANVÄNDARINSTÄLLNINGAR',
    CHANGE_PASSWORD: 'BYT LÖSENORD',
    ADD_SYSTEM: 'Lägg till system',
    SYSTEMS: 'SYSTEM',
    SETTINGS: 'MITT KONTO',
    CONTACT: 'KONTAKT',
    HELP: 'Hjälp',
    INCIDENT_ALERT:
      'Vi upplever för närvarande driftstörningar i vissa av våra tjänster. Du kan läsa mer <a target="_blank" href="https://support.ferroamp.com/sv-SE/support/solutions/47000526402">här</a>.',
    PLANNED_MAINTENANCE_ALERT:
      'Kommande planerat underhåll.  <a target="_blank" href="https://support.ferroamp.com/sv-SE/support/solutions/47000526402"> Läs mer på denna sida</a> '
  },

  Comments: {
    LEAVE_A_COMMENT: 'Kommentarer',
    HELLO: 'Hej',
    INFO:
      'Här kan du kommentera om vad som är bra eller dåligt med vår portal. Om du har några frågor i' +
      ' kommentarsfältet svarar vi på de genom den mail som är registrerad för ditt konto',
    TITLE: 'Titel',
    COMMENT: 'Kommentarer',
    GREETINGS: 'Vänligen Ferroamp',
    VISIT: 'Besök Ferroamp.com',
    CLOSE: 'Stäng',
    SEND_COMMENT: 'Skicka kommentar',
    THANK_YOU: 'Tack så mycket!',
    THANKS_TEXT:
      'Vi uppskattar alla kommentarer och tankar om våra produkter och tjänster. Vi återkommer' +
      ' till dig om det fanns några frågor i kommentaren.'
  },

  SystemList: {
    FILTER: 'Filtrera',
    UPDATED: 'Systemstatus',
    LIST_PAGE: 'Sida:',
    LIST_OF: 'av',
    ROWS_PER_PAGE: 'Rader per sida:',
    UPDATE_INFO_META:
      'Systeminformationen behöver updateras. Du kommer bli omdirigerad till inställningarna vid' + ' klick',
    UPDATE_INFO_OK: 'Allting är uppdaterat',
    SYSTEM: 'System',
    CONSUMPTION_POWER: 'Förbrukning [kW]',
    PRODUCTION_POWER: 'Produktion [kW]',
    CONNECTION: 'Anslutning',
    STORAGE_POWER: 'Energilagring [kW]',
    STORAGE_STATUS: 'Laddningsstatus [kWh]',
    TOTAL: 'Totalt',
    MULTISYSTEM: 'Multisystems',
    ID: 'ID',
    NOT_STARTED: ' Verkar inte ha startat',
    OFFLINE: ' Offline',
    ONLINE: ' Online',
    WARNING: ' Varning!',
    ERROR_FETCHING_SYSTEMS: 'Ett fel inträffade när systemen skulle hämtas.',
    ERROR_FETCHING_MULTISYSTEMS: 'Ett fel inträffade när multisystem försökte hämtas.',
    HIDE: 'Göm',
    SHOW: 'Visa'
  },

  SystemDashboard: {
    EV_CURRENTS: 'Ström per fas till elbilsladdaren',
    EV_CHARGER: 'Elbilsladdare',
    EV_OVERVIEW: 'Översikt av elbilsladdning',
    ONLINE: 'Online',
    OFFLINE: 'Offline',

    TOTAL_EV_ENERGY: 'Total mängd energi som laddat elbilen',
    TIMESTAMP: 'Tidsstämpel',
    MONTH: 'Månad',
    SSO: 'SSO',
    HOUR: 'Timme',
    NO_LOCATION_PARAMS_EXPLAINATION:
      'För att kunna använda denna funktionalitet krävs att Longitude och Latitude är satta i Systeminställningarna',
    ENABLED: 'Aktiverad',
    DISABLED: 'Avaktiverad',
    UNAVAILABLE: 'Inte tillgängligt',
    ENERGY_STORAGE: 'Energilager',
    TURN_OFF: 'Stäng av',
    DATA_VIEW: 'Data-vy',
    REFRESH: 'Uppdatera',
    EXPORT_DATA: 'Exportera data',
    IMPORTING: 'Importerar',
    EXPORTING: 'Exporterar',
    IDLE: 'Mätningsläge',
    DASHBOARD: 'Översikt',
    DISCHARGING: 'Laddar ur',
    CHARGING: 'Laddar',
    SAVE_IMAGE: 'Spara Bild',
    GRID_POWER: 'Näteffekt',
    GRID_POWER_INFO: 'Den effekt som mäts vid huvudsäkringen',
    CONSUMPTION_POWER: 'Förbrukning',
    CONSUMPTION_POWER_INFO: 'Den effekt som ditt system förbrukar',
    SOLAR_PRODUCTION: 'Solelproduktion',
    SOLAR_PRODUCTION_INFO: 'Effekt producerad av solpanelerna',
    BATTERY_POWER: 'Batterieffekt',
    BATTERY_POWER_INFO: 'Effekt från eller till batterierna.',
    BATTERY_SOC: 'Batteri Laddningsstatus',
    CURRENT_FLOW: 'Strömflöde',
    CURRENT_FLOW_INFO:
      'Illustrationen visar aktiva strömkomponenter. De faktiska strömmarna i systemet och genom' +
      ' <br> huvdsäkringen kan vara högre pga reaktiva strömmar. Se "Effektvy" för total ström.',

    POWER_FLOW: 'Effektflöde',
    POWER_FLOW_INFO: 'Här visas det effektflöde som sker i ditt systemet just nu.',

    GRID_CURRENTS: 'Nätströmmar',
    GRID_CURRENTS_INFO: 'Ström per fas uppmätt vid huvudsäkringen.',

    PRODUCTION_SUMMARY: 'Produktionssummering',
    CONSUMPTION_SUMMARY: 'Förbrukningssummering',
    TODAY: 'Idag',
    TOTAL: 'Totalt',
    SELF_CONSUMPTION: 'Egenförbrukning',
    ESTIMATED_INCOME: 'Uppskattad Inkomst',
    EXPORTED_TO_GRID: 'Exporterat till nätet',
    ESTIMATED_SAVING: 'Uppskattad besparing',
    ESTIMATED_COST: 'Uppskattad kostnad:',
    IMPORTED_FROM_GRID: 'Importerat från nät',
    TIMEZONE: 'Tidszon',
    SYSTEM_INFORMATION: 'Systeminformation',
    ADDRESS: 'Adress',
    CITY: 'Stad',
    COUNTRY: 'Land',
    PEAK_POWER: 'Toppeffekt',
    INSTALLED: 'Installerad',
    CONTACT: 'Kontakt',
    NO_SETTINGS_FOUND: 'Inga inställningar funna',
    GO_TO_SYSTEM_CONFIGURATION: 'Gå till systemkonfigurationen',
    GO_TO_SYSTEM_INFORMATION: 'Gå till systeminformationen',
    WEATHER_INFORMATION: 'Väderinformation',
    TEMPERATURE: 'Temperatur',
    SUNRISE: 'Soluppgång',
    SUNSET: 'Solnedgång',
    WIND_SPEED: 'Vindhastighet',
    WIND_DIRECTION: 'Vindriktning',
    SAVE_LAYOUT: 'Spara layout',
    LAYOUT_SAVED: 'Layout sparad.',
    LAYOUT_FAILED: 'Layout kunde inte sparas.',
    PER_YEAR: 'Per år',
    SOLAR_ENERGY: 'Solenergi',
    CONSUMPTION_ENERGY: 'Förbrukad energi',
    EXPORTED_ENERGY_TO_GRID: 'Exporterat till nät',
    PER_MONTH: 'Per månad',
    AVERAGE_POWER: 'Medeleffekt',
    AVERAGE_POWER_LABELS: [
      'Datum',
      'Solel',
      'Förbrukning',
      'Importerat nät',
      'Egenförbrukning av solel',
      'Exporterat nät'
    ],
    DAY: 'Dag',
    WEEK: 'Vecka',
    SOLAR_STRINGS: 'Solpanelssträngar',
    POWER: 'Effekt',
    ENERGY: 'Energi',
    SOLAR_STRING_INFO:
      'Denna graf visar nuvarande status hos solpanelsträngararna. <br> Effekten är den effekt' +
      ' som varje individuell sträng producerar nu. <br> Energi är den totala energi som strängarna har producerat sedan de installerades.',

    BATTERY_MODULES: 'Batterimoduler',
    BATTERY_MODULES_INFO: 'Nuvarande effekt från varje batteri.',
    SELLING: 'Säljer',
    BUYING: 'Köper',
    SOLAR: 'Sol',
    BATTERY: 'Batteri',
    RATED_CAPACITY: 'Nominell kapacitet',
    STATE_OF_CHARGE: 'Laddningsstatus',
    CONSUMPTION: 'Förbrukning',
    GRID: 'Nät',
    EXTERNAL_PRODUCTION: 'Ext. produktion',
    MAIN_FUSE: 'Huvudsäkring',
    DC_MICROGRID: 'DC Nät',
    DC_NANOGRID: 'DC Nanogrid',
    LOCATION: 'Plats',

    NO_DATA_FOUND: 'Ingen data funnen för tidsperioden, prova andra datum.',
    ERROR_OCCURRED: 'Ett fel inträffade. Försök med andra datum.',

    SETTINGS: 'Inställningar',
    OPERATION_SETTINGS: 'Driftinställningar',
    OPERATION_MODE: 'Driftläge',
    ACE_THRESHOLD: 'ACE-tröskelvärde',
    CHART_BOX: 'Grafer'
  },

  SystemSidebar: {
    DASHBOARD: 'Översikt',
    POWER_VIEW: 'Effektvy',
    ANALYSIS: 'Analys',
    LIVEDATA: 'Realtidsvy',
    SYSTEM_SETTINGS: 'SystemInställningar',
    ENERGY_VIEW: 'Energivy',
    SSO_VIEW: 'Solsträngsvy',
    ESO_VIEW: 'Eso Vy',
    EXPORT_TITLE: 'Exportera data',
    EXPORT_TOOLTIP: 'Ska bli implementerat',
    PRO_INFORMATION:
      'Funktioner för avancerad systemanalys av professionella användare. Klicka på "PRO" för mer information',
    ANCILLARY_SERVICE_VIEW: 'Ancillary service'
  },

  Views: {
    DOWNLOAD: 'Laddar ner som',
    RESTORE_GRAPH: 'Återställ chart',
    ZOOM: 'Zoom',
    ENERGY: 'Energi',
    POWER: 'Effekt',
    POWER_VIEW: 'Effektvy',
    VIEW_CONTROL: 'Vy-kontroll',
    SAVE_IMAGE: 'Spara Bild',
    EXPORT_IMAGE_FILENAME: 'Välj bildnamn',
    EXPORT_IMAGE_DEFAULT: 'Använd: graph<Datum>.png',
    EXPORT_IMAGE_OWN_FILENAME: 'Välj eget namn',
    EXPORT_IMAGE_INFO:
      'Skriv filnamnet du vill ha på filen, om inget anges kommer graph<Datum>.png att användas.',

    PREVIOUS_WEEK: 'Föregående vecka',
    NEXT_WEEK: 'Nästa vecka',
    PREVIOUS_DAY: 'Föregående dag',
    NEXT_DAY: 'Nästa dag',

    SELECT_DATA_TO_DISPLAY: 'Välj data som ska visas',
    CHOOSE_PERIOD: 'Välj period',
    DATE: 'Datum',
    CUSTOMIZATION: 'Anpassningar',
    HIGHLIGHT_CLOSEST: 'Markera närmaste',
    LOCK_Y_AXIS: 'Lås y-axeln',
    LOWER: 'Nedre',
    UPPER: 'Övre',
    RESET_ZOOM: 'Återställ zoom',
    EXPORT_DATA: 'Exportera data',
    EXPORT_DATA_FILENAME: 'Välj filnamn',
    EXPORT_DATA_DEFAULT: 'Välj data.csv',
    EXPORT: 'Exportera',
    EXPORT_DATA_INFO: 'Skriv filnamnet du vill ha på filen, om inget anges kommer data.csv att användas.',
    CANCEL: 'Avbryt',

    CLOSE_SIDENAV: 'Stäng sidmeny',
    POWER_VIEW_INFORMATION:
      '<h4>Information</h4><p><b>Zooma</b> genom att markera och dra. Dra <b>sidleds</b>' +
      ' genom att hålla in skift.' +
      ' Dubbelklicka för att <b>zooma ut</b>.</p> ',

    PRODUCTION_CONSUMPTION: 'Produktion och förbrukning',
    CONSUMPTION_PHASE_CURRENTS: 'Förbrukning fasströmmar',
    SOLAR_PRODUCTION: 'Solproduktion',
    GRID_REACTIVE_CURRENT: 'Elnät reaktiv ström',
    GRID_ACTIVE_CURRENT: 'Elnät aktiv ström',
    GRID_CURRENT: 'Elnät total ström',
    ENERGYHUB_CURRENT: 'EnergyHub total ström ',
    ENERGYHUB_ACTIVE_CURRENT: 'EnergyHub aktiv ström',
    ENERGYHUB_REACTIVE_CURRENT: 'EnergyHub reaktiv ström',
    GRID_VOLTAGE: 'Elnätspänning',
    DC_GRID_VOLTAGE: 'DC spänning',
    BATTERY_STATUS: 'Batteristatus',
    DATE_AND_TIME: 'Datum och Tid',
    CHANGE_PERIOD: 'Ändra period',
    RATED_CAPACITY: 'Nominell kapacitet',
    STATE_OF_CHARGE: 'Laddningsstatus',

    BATTERY: 'Batteri',
    CONSUMPTION: 'Förbrukning',
    CONSUMPTION_CURRENTS: 'Förbrukningsströmmar',
    ELECTRICAL_GRID: 'Elnät',
    PHASE_1: 'Fas 1',
    PHASE_2: 'Fas 2',
    PHASE_3: 'Fas 3',
    NEUTRAL: 'Neutral',
    DC_NEGATIVE: 'DC Negativ',
    DC_POSITIVE: 'DC Positiv',

    CHOICE_INVALID: 'Valet kunde ej genomföras',
    ERROR_OCCURRED: 'Ett fel inträffade, försök med andra datum.',
    NOT_DEFINED_SEARCH: 'Ej definierad sökning. Välj annan.',
    NO_DATA_FOUND: 'Ingen data funnen för tidsperioden.',
    INVALID_WEEK_PARAM: 'Odefinierad vecko-parameter',
    CHANGED_ENDTIME_TO: 'Ändrade sluttid till ',
    CHANGED_STARTTIME_TO: 'Ändrade starttid till ',
    AUTOSET_ENDTIME: 'Ändrar sluttid till en vecka mer än starttid',
    AUTOSET_STARTTIME: 'Ändrar starttid till en vecka mindre än sluttid',
    ENDTIME_GREATER_STARTTIME: 'Sluttid måste vara efter starttid',
    MAXIMUM_TWO_WEEKS: 'Kan inte begära mer än två veckor.',

    ENERGY_VIEW: 'Energivy',
    MONTHLY: 'Månader',
    MONTH: 'Månad',
    WEEKLY: 'Veckor',
    WEEK: 'Vecka',
    DAILY: 'Dagar',
    DAY: 'Dag',
    HOURLY: 'Timmar',
    HOUR: 'Timme',
    EQUARTZ: '15 Minuter',
    CHART_TYPE: 'Diagramtyp',
    BAR_GRAPH: 'Stapeldiagram',
    LINE_GRAPH: 'Linjediagram',
    DATA_FORMAT: 'Dataformat',
    ACCUMULATED: 'Ackumulerat',
    RELATIVE: 'Relativt',
    ENERGY_VIEW_GRAPH_TITLE_1: 'Total energi',
    ENERGY_VIEW_GRAPH_TITLE_2: 'Förbrukning per fas',
    IMPORTED_FROM_GRID: 'Importerat från nät',
    EXPORTED_TO_GRID: 'Exporterat till nät',
    SELF_CONSUMPTION_OF_SOLAR: 'Egenförbrukning av solel',
    DISTRIBUTION_OVER_TIME_PERIOD: 'Fördelning över tidsperioden',
    WITH_PIES_CHARTS: 'med cirkeldiagrammen',
    WHEN_MONTH_ATLEAST_THREE_MONTH: 'Vid upplösning "månader" måste minst tre månader väljas',
    SHOW_PERCENT: 'Visa procent',
    SHOW_KWH: 'Visa kWh',
    TOTAL: 'Total',

    SSO_VIEW: 'Solsträngsvy',
    PER_SOLAR_STRING: 'Solproduktion per pv-sträng',
    NO_AVAILABLE_SSOS: 'Det verkar inte finnas några tillgängliga solsträngsoptimerare i detta system',
    ESO_VIEW: 'ESO-vy',
    LIVE_DATA: 'Realtidsvy'
  },

  SystemAnalysis: {
    NO_AVAILABLE_DATA_BEFORE_DATE: 'Denna data-typ finns inte tillgänglig före datum: ',
    DATE_AND_TIME: 'Datum och Tid',
    LOAD_SIGNATURE_RESULT_PENDING: 'Jobbet håller på att bearbetas',
    ANALYSIS: 'Analys',
    VIEW_CONTOL: 'Vy-kontroll',
    CHOOSE_ANALYSIS_TOOLS: 'Välj analysverktyg',
    SAVED_ANALYSIS: 'Sparade analyser',
    FUSE_ANALYSIS: 'Säkringsanalys',
    FUSE_ANALYSIS_INFO:
      'Starta en ny analys genom att välja tidsintervall och klicka på starta.' +
      'Observera att man endast kan köra en analys för en anläggning i taget.',

    FUSE_ANALYSIS_INFO_2:
      '<p><b class="color-blue">Observera: </b>Säkringsanalysen ger en bild av' +
      ' strömförbrukningen på de tre faserna uppdelad i' +
      ' händelser under den aktuella mätperioden, dessa händelser jämförs med kurvor för olika säkringars utlösningskarakteristik.' +
      ' Säkringskurvorna gäller dock för en enskild isolerad händelse. Vid komplexa förbrukningsmönster med en hög ' +
      'baslast eller flera efter varandra följande strömpulser kan denna jämförelse felaktigt tolkas som att ' +
      'en låg säkring kan vara tillräcklig. Komplettera därför alltid den automatiska analysen med att titta på de' +
      ' uppmätta strömmarna. Se' +
      ' instruktion:</p>',

    LOAD_SIGNATURE: 'Förbrukningsprofil',
    DAILY_PROFILE: 'Dygnsprofil',
    POWER_ANALYSIS: 'Effektanalys',
    ACE_EVENTS: 'ACE Events',
    ACE_EVENTS_INFO:
      'Genom att dubbel-klicka en kolumn i grafen kommer en dialog med nätströmmar och EnergyHub-strömmar att visas för att åskådliggöra fasbalansering (ACE). ' +
      ' <p> Uppmärksamma att EnergyHubströmmarna nedan är <em>Aktiva effektivvärden</em> och nätströmmarna är totala (absolutbelopp av reaktiv och aktiva) effektivvärden. Säkringar är specificierade efter totala effektivvärden. </p>',

    ACE_EVENT_TITLE: 'Antalet ACE-händelser och beräknad förflyttning av energi',
    SUMMARY: 'Summering',
    DURATION_ACTIVE_ACE: 'Varaktighet som ACE var aktivt',
    MEAN_ENERGY_PER_HOUR: 'Genomsnittlig förflyttning av energi per timme',
    TOTAL_ENERGY_TRANSFERRED: 'Total flyttad energi med ACE',
    BATTERY_SIMULATION: 'Batterisimulering',
    SAVE: 'Spara',
    SELECT_DATE_RANGE: 'Välj datuminterval',
    RESET_ZOOM: 'Återställ Zoom',
    GROUPED: 'Grupperade',
    STACKED: 'Staplade',
    REACTIVE_POWER: 'Reaktiv Effekt',
    ACTIVE_POWER: 'Aktiv Effek',
    GET_DATA: 'Hämta data',
    SAVE_IMAGE: 'Spara Bild',
    END_TIME: 'Sluttid',
    TIME_OF_ANALYSIS: 'Tid då analys skapades',
    START_ANALYSIS: 'Starta analys',
    OPEN: 'Öppna analys',
    NO_ONE: 'Ingen!',
    CHOOSE_ONE: 'Välj en!',
    TYPE_OF_ANALYSIS: 'Typ av analys',
    EXECUTION_TIME: 'Exekveringstid',
    LIKE_TO_DELETE_ANALYSIS: 'Vill du ta bort denna analysen?',
    DELETE: 'Ta bort',
    KEEP: 'Behåll',
    ERROR_USER_MAP: 'Fel vid hämtning av email',

    START_TIME: 'Starttid',
    WITHOUT_ACE: 'Utan fasbalansering',
    WITH_ACE: 'Med fasbalansering',
    TIMESTAMP: 'Tidsstämpel',
    AVERAGE_POWER: 'Medeleffekt',
    PHASE_1: 'Fas 1',
    PHASE_2: 'Fas 2',
    PHASE_3: 'Fas 3',
    POWER: 'Effekt',
    TYPE: 'Typ',
    DATE: 'Datum',
    COMMENTS: 'Kommentarer',

    USER: 'Användare',
    EVENTS: 'Händelser ',
    OCCURRANCES: 'Händelser ',
    DATE_TIME: 'Datum och tid',
    MAGNITUDE: 'magnitud',

    MEAN_CURRENT_RMS: 'Medelström [A<sub>rms</sub>]',
    DURATION: 'Varaktighet',
    MEASURED: 'Uppmätta',
    MEAN_CONSUMPTION_ACTIVE: 'Medelförbrukning [kW]',
    MEAN_CONUSMPTION_REACTIVE: 'Medelförbrukning [VAr]',
    HOUR: 'Timme',
    BASE_CONSUMPTION: 'Basförbrukning',
    AVERAGE_CONSUMPTION: 'Medelförbrukning',
    DAILY_PROFILE_WEEKDAYS: 'Dygnsprofil per fas för veckodagar',
    DAILY_PROFILE_WEEKENDS: 'Dygnsprofil per fas för helger',
    DAILY_PROFILE_AVERAGE_POWER_PER_OCCURRANCES: 'Medeleffekt per timme',
    DAILY_PROFILE_AVERAGE_POWER_INFO:
      'Denna grafen visar hur många timmar en viss' + ' medeleffekt, över en timme, förekommer.',
    WEEKDAYS: 'Veckodagar',
    WEEKENDS: 'Helger',
    HOUR_OF_DAY: 'Timme på dygnet',
    MAX_MEAN_THREE_PHASE_POWER: '10 största medeleffekterna för trefas',
    MAX_MEAN_PER_PHASE_POWER: '10 största medeleffekterna för fas',
    NUMBER_OF_HOURS: 'Antal timmar',
    CONSUMPTION_ACTIVE: 'Förbrukning [kW]',
    CONSUMPTION_REACTIVE: 'Förbrukning [VAr]',
    TEMPERATURE: 'Temperatur &#8451;',
    WEEKEND_MARKED_INFO:
      'De grå staplarna markerar helger. Genom att klicka på en punkt i toppen av stapeln' +
      ' visas strömmarna för' +
      ' denna dag.',
    POWER_ANALYSIS_INFO: 'Effektanalysen visar medeleffekten för den uppmätta förbrukning.',

    GRID_AVERAGE_POWER: 'Medelnäteffekt',
    CONSUMPTION: 'Förbrukning',
    BATTERY_POWER: 'Batterieffekt',
    SOLAR_PRODUCTION: 'Solproduktion',
    SIMULATED_BATTERY_POWER: 'Simulerad batterieffekt',
    SIMULATED_BATTERY_STATUS: 'Simulerad laddningsstatus',
    GRID_POWER_NO_BATTERY: 'Näteffekt utan batteri',
    GRID_POWER_WITH_BATTERY: 'Näteffekt med batteri',
    SELF_CONSUMPTION: 'Egenförbrukning',
    GRID_POWER: 'Näteffekt',
    UPPER_LIMIT: 'Övre gräns',
    LOWER_LIMIT: 'Nedre gräns',
    ENERGY: 'Energi',
    SECONDS: 'Sekunder',
    MINUTES: 'Minuter',
    RESOLUTION: 'Upplösning',
    ENERGY_QUARTERS: 'Energi per kvart',
    ENERGY_HOURS: 'Energi per timme',
    SIMULATION_OF_BATTERIES: 'Simulering av batterier',
    SIMULATION_OF_BATTERIES_INFO:
      'OBSERVERA! Denna simulering visar hur ett batteri kan förbättra lastprofilen. För en korrekt dimensionering ' +
      'av ett system krävs att fler faktorer beaktas såsom olika tillämpningar, batteriets livslängd, verkningsgrad, responstid mm.',
    ENERGY_AS_MEAN_POWER: 'Notera att när typ Energi är valt kommer datan att skalas om till medeleffekt.',
    RATED_CAPACITY: 'Nominell kapacitet',
    CHARGE_THRESHOLD: 'Laddningströskel',
    DISCHARGE_THRESHOLD: 'Urladdningströskel',
    MAX_POWER: 'Maximal effekt',
    MAX_C_RATE: 'Max C-rate',
    BATTERY_STATUS_START: 'Batteriets status vid start [% av nominell kapacitet]',
    BATTERY_MAX_STORED: 'Övre gräns [% av nominell kapacitet]',
    BATTERY_MIN_STORED: 'Undre gräns [% av nominell kapacitet]',
    SIMULATE_BATTERY: 'Simulera batteri',
    TOTAL_GRID_CURRENT: 'Total nätströmmar',
    ACTIVE_GRID_CURRENTS: 'Aktiva nätströmmar',
    REACTIVE_GRID_CURRENTS: 'Reaktiva nätströmmar',
    ENERGYHUB_ACTIVE_CURRENT: 'Energyhub aktiv ström',

    STARTTIME_NOT_GREATER_ENDTIME: 'Starttid kan inte vara mer än sluttid',
    NO_MORE_THAN_TWO_WEEKS_1_MIN: 'Kan inte hämta mer än två veckor i effektläge och upplösning 1 min',
    NO_MORE_THAN_24_HOURS_1_SEC: 'Kan inte hämta mer än 24 timmar i effektläge och upplösning 1 sekund.',
    ERROR_OCCURRED: 'Ett fel inträffade, prova andra datum. Om det inte fungerar kontakta support.',
    ERROR_OCCURRED_LIST: 'Ett fel inträffade när listan skulle hämtas',
    NO_RESULT: 'Inget resultat',
    ERROR_START_ANALYSIS:
      'Fel inträffade när analys skulle startas. Prova med andra tidsintervall, senare' +
      ' eller kontakta support',

    ANALYS_JOB_CREATED:
      'Ett analys-jobb har påbörjats. Analysen tar en stund att genomföra, var god att' +
      ' återkomma hit senare.',
    NO_GENERATED_ANALYSIS: 'Ingen genererad analys funnen',
    ANALYSIS_JOB_IS_RUNNING:
      'Det är en säkring- och förbrukningsprofilsanalys som körs just nu med följande' +
      ' parametrar: starttid: ' +
      '<STARTTIME>, sluttid: <ENDTIME>. Analysen startades: <TIMESTAMP>' +
      ' En ny analys kan inte startas för än den nu körande analysen är klar.',
    CANNOT_REMOVE_AS_DEMO: 'Inloggad som demo kan man inte ta bort analyser.',
    NO_DATA_FOUND: 'Ingen data funnen',
    ANALYSIS_SUCCESS: 'Analys lyckad',
    ANALYSIS_ERROR: 'Analys kunde inte genomföras.'
  },

  GlobalSettingsSidebar: {
    USERNAME: 'Användarnamn',
    OVERVIEW: 'Inställningar',
    USER_INFO: 'Användarinformation',
    CHANGE_PASSWORD: 'Byt användarlösenord',
    ADD_SYSTEM: 'Lägg till system',
    CREATE_POWERSHARE: 'Skapa PowerShare'
  },

  GlobalSettings: {
    // GLOBAL SETTINGS
    GLOBAL_SETTINGS: 'Global inställningar',
    COLOR_PALETTE: 'Färgschema',
    COLOR_PALETTE_INFO: 'Notera att ändringar av färgschema endast påverkar grafer.',
    DEFAULT: 'Standard',
    COLOR_BLIND: 'Färgblind-vänligt',
    COLOR_MODE_CHANGED: 'Färgschemat har ändrats',
    LANGUAGE_SETTINGS: 'Språkinställningar',
    // USER INFORMATION
    USER_INFORMATION: 'Användarinformation',
    USER_IDENTIFICATION: 'Användar-ID',
    FIRST_NAME: 'Förnamn',
    LAST_NAME: 'Efternamn',
    PHONE_NUMBER: 'Telefonnummer',
    PHONE_NUMBER_INFO: 'Telefonnummer är valfritt men rekommenderas för snabbare support',
    UPDATE_USER_INFORMATION: 'Uppdatera informationen',
    USER_INFORMATION_UPDATED: 'Användarinformationen är uppdaterad',
    CREATED: 'Skapad',
    EMAIL: 'Email',
    // CHANGE PASSWORD
    CHANGE_PASSWORD: 'Byt lösenord',
    NEW_PASSWORD: 'Nytt lösenord',
    CHANGE_PASSWORD_INFO:
      'Lösenordet måste vara mellan 8 och 100 tecken långt, innehålla minst 1 siffra, 1' +
      ' stor bokstav och 1 liten bokstav.',
    CONFIRM_NEW_PASSWORD: 'Upprepa nytt lösenord',
    EXISTING_PASSWORD: 'Befintligt lösenord',
    FORM_INVALID: 'Formulär är inte giltigt',

    PASSWORD_ERROR_MIN: 'Minst åtta (8) tecken',
    PASSWORD_ERROR_MAX: 'Max hundra (100) tecken',
    REQUIRED_FIELD: 'Obligatoriskt fält',
    PATTERN_MATCH_ERROR:
      'Felaktig format, måste innehålla minst en siffra, en stor bokstav och en liten bokstav',
    PASSWORD_VALID_CHARACTERS: 'Lösenordet får endast innehålla följande tecken: a-ö , A-Ö , 0-9, $@$!%*?&',
    PASSWORD_MATCH: 'Lösenord matchar ej varandra',
    PASSWORD_TYPE: 'Lösenord verkar inte stämma överrens med kraven.',
    WRONG_EXISTING_PASSWORD: 'Felaktigt befintligt lösenord',
    ERROR_OCCURRED: 'Något gick fel, var god kontakta support',
    PASSWORD_CHANGED: 'Lösenord ändrat',

    // ADD NEW FACILITY
    ADD_NEW_SYSTEM: 'Lägg till system',
    DEVICE_IDENTIFICATION: 'Systemidentifikation',
    DEVICE_IDENTIFICATION_INFO:
      'Huvudenhets identifikation fås i samband vid köp av en energyhub. Denna står' +
      ' inte på energyhubben utan fås på papper eller via mail från ferroamp.',
    DEVICE_IDENTIFICATION_LENGTH: 'Måste vara exakt  4x8 tecken långt',
    DEVICE_IDENTIFICATION_PATTERN: 'Får endast innehålla A-F och 0-9.',
    SYSTEM_DISPLAY_NAME: 'Systemets namn',
    SYSTEM_DISPLAY_NAME_INFO: 'Vad ska systemet heta, till exempel: "BRF Ferroamp hus 11"',
    LOCATION_INFO: 'Adress, Land, Stad, Longitud, Latitud och Tidszon är där systemet befinner sig.',
    ADDRESS: 'Adress',
    CITY: 'Stad',
    COUNTRY: 'Land',
    LATITUDE: 'Latitud',
    LONGITUDE: 'Longitud',
    CONTACT_PERSON: 'Kontaktpersons email',
    TIMEZONE: 'Tidszon',
    TIMEZONE_ERROR: 'Fel vid hämtning av tidszonen, var god ange själv.',
    TIMEZONE_HINT: 'Krävs för att kunna visa grafer korrekt. Kan EJ ändras i efterhand.',
    AGREEMENT_INFO:
      'Läs vårat avtal angående ägarrättigheter till systemet. Klicka på länken för att bli visad' +
      ' till avtalet.',
    AGREEMENT:
      'Genom att registrera en systemets accepterar du <a target="_blank" href="/terms-and-' +
      'conditions/user-agreement">ferroamps användaravtal</a>',
    GET_LOCATION: 'Hämta plats',
    GET_LOCATION_INFO:
      'Om du inte vet din tidszon, latitud och longitud klicka på knappen för att hämta informationen' +
      '  För att detta ska fungera krävs att du åtminstonde fyllt i adress, stad och land',
    PLEASE_FILL: 'Fyll i: ',
    FOUND: 'Found',
    ADD_SYSTEM_SUCCESS:
      'Nytt system skapat. Glöm inte att starta om Energyhubben innan du gör några konfigurationsändringar.',
    NEW_SYSTEM_LINK: 'Du kommer bli omdirigerad till anläggningens översikt.',

    CreateOneFacilityNoExistingSingleBoardComputerError:
      'Felaktig registrerings-nyckel då den inte tillhör en befintlig EnergyHub.',
    CreateOneFacilitySingleBoardComputerTakenError:
      'Registreringsnyckeln är redan använd för att skapa en anläggning',
    CreateOneFacilityInvalidLifeCycleStageError:
      'EnergyHub är inte tillgänglig då den är i ett felaktig stadium',
    CreateOneFacilityPendingFacilityError:
      'Du har för många pågående installationer, avsluta några innan du forsätter denna',
    InvalidTimezoneError: 'Tidszonen är inte korrekt. Var god välj en annan och försök igen'
  },

  SystemSettings: {
    ENERGY_BIDDING_ZONE: 'Elområde',
    ENERGY_BIDDING_ZONE_HINT: 'Elområde krävs för att kunna används spot-pris-optimering eller delta i fcr.',
    PAGE_WILL_BE_RELOADED: 'Sidan kommer laddas om för att applicera förändringarna',
    ALARMS: 'Larm',
    ALARMS_LOG: 'Larmhistorik',
    SYSTEM_PARAMETERS_UPDATE_UNAVAILABLE_STATE_RUNNING:
      "Systemparametrar kan inte uppdateras när systemets driftstatus är i körläge. Ändra driftläge genom att trycka på 'STOPP'",
    SYSTEM_PARAMETERS: 'Systemparametrar',
    REQUEST_TO_ENERGYHUB_TIMEDOUT: 'Misslyckades. Energyhub-systemet svarade inte i tid.',
    SENT_TO_ENERGY_HUB: 'Förfrågan har skickats till energyhub-systemet, väntar på svar.',
    LOWER: 'Undre',
    UPPER: 'Övre',
    REFERENCE: 'Referens',
    CURRENT_THRESHOLD: 'Ström-tröskelvärde',
    GRID_POWER: 'Näteffekt',
    DISABLED: 'Inaktiverad',
    ENABLED: 'Aktiverad',
    NO_DATA: 'Ingen data funnen',
    SYSTEM_SETTINGS: 'SYSTEMINSTÄLLNINGAR',
    VIEW_CONTROL: 'Vy-kontroll',
    SYSTEM_INFORMATION: 'Systeminformation',
    SYSTEM_ID: 'System-id',
    SYSTEM_ID_INFO: 'Automatisk genererad systemidentifikation',
    SAVE_IMAGE: 'Spara bild',

    SYSTEM_NAME: 'Systemnamn',
    SYSTEM_NAME_INFO: 'Namnet på systemet',
    CONTACT_PERSON: 'Kontaktperson',
    INSTALLED: 'Installerad',
    SYSTEM_INSTALLED: 'Då systemet installerades',

    SYSTEM_INSTALLED_INFO: 'Systemets installationsdatum',
    INFORMATION_LAST_UPDATED_BY: 'Informationen är senast uppdaterad av',
    INFORMATION_LAST_UPDATED_BY_INFO: 'Användare som senast uppdaterade Systeminformation',

    MODIFIED_DATE: 'Senast uppdaterad tid',
    LOCATION_INFORMATION: 'Platsinformation',
    AT_THE_ENERGYHUB: ' vid Energyhub',

    ADDRESS: 'Adress',
    CITY: 'Stad',
    COUNTRY: 'Land',
    TIMEZONE: 'Tidszon',
    LONGITUDE: 'Longitud',
    LATITUDE: 'Latitud',
    SYSTEM_LOCATION_HINT:
      'Om du inte vet din tidszon, latitud och longitud klicka på knappen för att hämta' +
      ' informationen' +
      ' För att detta ska fungera krävs att du åtminstonde fyllt i adress, stad och land,',
    GET_SYSTEM_LOCATION: 'Hämta plats',

    ERROR_GET_TIMEZONE: 'Fel vid hämtning av tidszonen, var god ange själv.',
    YOU_MUST_FILL: 'Du måste fylla i',
    IF_NOT_ADMIN_NOTICE:
      'Om du inte är eller ska vara systemets måste du logga ut och skapa ett konto under' + ' vid signup',
    MUST_HAVE_ADMIN_ACCESS:
      'Du måste ha administratörsrättigheter av systemet för att lägga till på detta sätt.',
    ADDRESS_FOUND: 'Funnen adress: ',

    ACE_INFORMATION: 'ACE Information',
    MAIN_FUSE: 'Huvudsäkring',
    MAIN_FUSE_SIZE: 'Storlek på huvudsäkringen',
    MAIN_FUSE_SIZE_INFO: 'Denna inställning har flyttats till SYSTEMINSTÄLLNINGAR -> SYSTEMKONFIGURATION.',
    SOLAR_SYSTEM_INFORMATION: 'Solsystemsinformation',
    PEAK_POWER: 'PV toppeffekt',
    PEAK_POWER_INFO: 'Märkt Toppeffekt för solsystemet i kW',

    EXTRA_INFORMATION: 'Information om elkostnad och extra',
    ELECTRICITY_COST: 'Kostnad elektricitet [Kostnad/kWh]',
    ELECTRICITY_COST_INFO: 'Uppskattad kostnad för elektricitet per kWh',
    REVENUE: 'Intäkt [Intänkt/kWh]',
    REVENUE_INFO: 'Uppskattat pris för producerad elektricitet [Intäkt/kWh]',
    REVENUE_SOLD_TO_GRID: 'Intäkt Såld El [Intäkt/kWh]',
    REVENUE_SOLD_TO_GRID_INFO: 'Den intäkt du får per såld kWh till nätet',
    REVENUE_PRODUCED_SOLAR: 'Intäkt producerad solel [Intäkt/kWh]',
    REVENUE_PRODUCED_SOLAR_INFO: 'Den intäkt du får per producerad solel [kWh], till exempel elcertifikat',

    CURRENCY: 'Valuta',
    PROPERTY_DESIGNATION: 'Fastighetsbeteckning',
    PROPERTY_DESIGNATION_INFO: '',
    GRID_COMPANY_SYSTEM_ID: 'Elnätbolagets anläggnings-ID',
    GRID_COMPANY_SYSTEM_ID_INFO: 'Elnätbolagets anläggnings-ID',

    UPDATE: 'Uppdatera',
    UP_TO_DATE: 'Informationen är den senaste',

    MD_MAX_LENGTH: 'Max antal tillåtna tecken: ',
    ERROR_OCCURRED: 'Ett fel har inträffat, prova igen senare eller kontakta support',
    INVALID_FORM: 'Formuläret är inte giltigt, kontrollera svaren och försök igen',
    ERROR_STORE_COMMENT: 'Ett fel har inträffade och kunde inte spara kommentaren',
    NOT_FOUND_LOCATION: 'Kunde inte lokalisera platser, kontrollera adress, stad och land.',

    USER: 'Användare',
    TIME_OF_UPDATE: 'Tidpunkt för uppdatering',
    CHANGES: 'Förändringar',
    TITLE: 'Titel',
    COMMENTS: 'Kommentarer',
    COMMENT: 'Kommentar',
    ADD_COMMENT: 'Lägg till kommentar',

    /*
         USER ACCESS

         */

    //        title: "Användare",
    REGISTERED_USERS: 'Registrerade användare',
    REGISTERED_USERS_EXPLANATION: 'Följande användare har tillgång till detta system.',
    USER_ID: 'ID',
    EMAIL: 'Email',
    FIRST_NAME: 'Förnamn',
    LAST_NAME: 'Efternamn',
    PHONE_NUMBER: 'Telefonnummer',
    PERMISSION: 'Rättigheter',
    NOT_VALID_FORM: 'Formuläret är inte giltigt',
    PASSWORD: 'Lösenord',
    CONFIRM_PASSWORD: 'Bekräfta lösenord',

    REGISTER: 'Registrera',
    PASSWORD_DONT_MATCH: 'Lösenorden matchar ej varandra.',
    ADDED_BY: 'Tillagd av',
    ADD_USER: 'Lägg till användare',
    ADD_EMAIL: 'Ange emailadress till användaren. ',
    ADD_USER_EXPLANATION:
      'Använderen har ännu inget konto hos Ferroamp. Registrera en ny användare' +
      ' nedan. Ett mail kommer' +
      ' sändas till användaren med information om hur man loggar in. Se till att emailadressen verkligen tillhör användaren.' +
      ' Det är kännt att det funnits buggar när man lagt till användare. Dessa ska vara löste nu, men om det nu inte skulle fungera,' +
      'Kontakta då info@ferroamp.se och beskriv problemet',
    REMOVE: 'Ta bort',
    ADMIN: 'Administratör',
    VIEWER: 'Viewer',
    NOT_VALID_EMAIL: 'Ej giltig email',
    CHOOSE_PERMISSION: 'Välj rättigheter',
    CHANGE_PERMISSION: 'Ändra rättigheter',
    PERMISSION_NOT_CHANGED: 'Rättigheter har inte ändrats',

    PERMISSION_CHANGE_ERROR: 'Ett fel uppstod när rättigheterna skulle ändras',
    PERMISSION_UPDATED: 'Rättigheterna har uppdateats för användare: ',
    USER_ADDED: 'Användaren <USER> har lagts till',
    CLOSE: 'Stäng',
    ACCESS_DELETE: 'Vill du ta bort följande användare från detta system: <USER>?',
    ACCESS_DELETE_INFO:
      'Om du tar bort användaren kan du lägga till denne igen genom att endast lägga till' + ' emailen',
    KEEP_USER: 'Behåll användaren',
    ACCESS_DELETE_FAILED: 'Användaren kunde inte tas bort.',
    ACCESS_DELETE_SUCCESS: 'Användaren <USER> är nu borttagen och kan inte se detta systemet längre.',

    /*
         SYSTEM TOPOLOGY
         */
    SYSTEM_TOPOLOGY: 'Systemtopologi',
    DELETE_UNIT: 'Ta bort Enhet',
    SERIAL_NUMBER: 'Serienummer',
    NAME: 'Namn',
    DESCRIPTION: 'Beskrivning',
    TYPE: 'Typ',
    PARTNO: 'Artikelnummer',
    ENERGY_HUB: 'EnergyHub',
    SOLAR_STRING: 'Solsträngar',
    NUMBER_OF_PANELS: 'Antal Paneler',
    POWER_PER_PANEL: 'WattPeak/Panel',
    BRAND: 'Panel Brand',
    EDIT: 'Ändra',
    EDIT_EXPLANATION:
      'För att ändra namn (eller andra parametrar) klick då på den raden för vilken enhet' +
      ' du vill ändra namn, det kommer då visas en dialog där du kan fylla i namnet. För att behålla namnet ' +
      'tryck efter att du fyllt i valt namn: "Enter". För att sedan spara ändringarna klicka "Uppdatera systemtopologin"',
    ENERGY_STORAGE: 'Energilager',
    DELETE_UNIT_CONFIRM: 'Vill du verkligen ta bort <TYPE> med Serienummer <ID>',
    DELETE_UNIT_INFO:
      'Du kommer inte kunna ångra att ta bort enheten, om den befinner sig i systemet och' +
      ' rapporterar data kommer den dyka upp i listan igen, dock utan namn och annan information.',
    CONFIRM_DELETE_UNIT: 'Ja, ta bort enhet',
    DONT_DELETE_UNIT: 'Nej, ta inte bort enheten',
    ERROR_DELETE_UNIT: 'Kunde inte ta bort eneheten just nu. Fel uppstod. ',
    UPDATE_TOPOLOGY: 'Uppdatera systemtopologin',
    ERROR_OCCURRED_TOPOLOGY: 'Det uppstod ett fel när vid försök att uppdatera systemtopologin',
    UPDATE_COMPLETED: 'Uppdatering genomförd.',

    /*
         EMS CONFIG
         NOTE THAT CHANGES TO THIS TEXT SHOULD BE DONE IN system_configuration.language files and then copied
         */

    SYSTEM_CONFIGURATION: 'Systemkonfiguration',

    SUCCESS: 'Lyckades',
    FAILED: 'Misslyckades',
    ACCEPT_CHANGES: 'Ja',
    DECLINE_CHANGES: 'Nej',
    ON_DECLINED_CHANGES: 'Du gjorde inga förändringar.',
    // OPERATION MODE
    OPERATION_MODE: 'Driftläge',
    RUN_MODE: 'Körläge',
    GET_OPERATION_MODE: 'Hämta driftläge',
    CURRENT_OPERATION_MODE: 'Nuvarande driftläge',
    OPERATION_MODE_RECEIVED: 'Driftläge mottaget',
    MEASURING: 'Measuring',
    FAULT_MODE: 'Felläge',
    MEASUREMENT_MODE: 'Mätningsläge',
    UNDEFINED_MODE: 'Odefinierat läge, kontakta support',
    ENERGYHUB_RECEIVED_OPERATION_MODE_REQUEST: 'Energyhub-system har mottagit Driftlägesförfrågan.',
    GETTING_OPERATION_MODE: 'Hämter driftläge från Energyhub-systemet, var god vänta.',
    TRYING_TO_SET_OPERATION_MODE: 'Försöker att ändra inställningar: <TYPE> i systemet',
    WARNING_RUN_MODE_DID_NOT_CHANGE:
      'Driftläge verkar inte ha ändrats. Försök att ändra igen, om detta' +
      ' meddelande återkommer var då god att kontakta support på mail <a' +
      ' href="mailto:support@ferroamp.se">support@ferroamp.se</a> eller telefon <a href="tel:+46868433390">+46' +
      ' (0)8 684 33 390</a>.',
    CONFIRM_OPERATION_MODE:
      'Är du säkert på att du vill ändra driftläge från <span' +
      ' class="color-blue"><FROM></span> till <span class="color-blue"><TO></span>?',

    // OPERATION SETTINGS
    GET_OPERATION_SETTINGS: 'Hämta Driftinst.',
    UPDATE_OPERATION_SETTINGS: 'Uppdatera Driftinst.',
    OPERATION_SETTINGS_TO_ENERGYHUB:
      'Skickar driftinställningar till Energyhub-systemet. Var god invänta svar.',
    ENERGYHUB_RECEIVED_OPERATION_SETTINGS:
      'Energyhub-systemet har mottagit driftinställningarna och försöker att applicera dem, invänta svar.',
    OPERATION_SETTINGS_ERROR:
      'Ett fel inträffade när inställningarna försökte att ställas in i Energyhub-systemet, orsak: <REASON>',
    OPERATION_SETTINGS_UPDATE_SUCCESS: 'De nya inställningarna <CONFIG> har lyckats att ställas in',
    GETTING_OPERATION_SETTINGS: 'Hämtar de senaste driftinställningarna i Energyhub-systemet, var god vänta.',
    OPERATION_SETTINGS: 'Driftinställningar',
    ACE: 'ACE',
    ACE_THRESHOLD: 'ACE-tröskelvärde',
    SOLAR: 'PV',
    AND: 'och',
    SETTING: 'Inställningar',
    OPERATION_MODE_MUST_BE_STOPPED: 'Systemet måste stoppas för att kunna ställa in Driftinställningarna',
    SET_OPERATION_SETTINGS_NOT_ALLOWED: 'Driftinställningarna kan inte ändras just nu.',
    OPERATION_SETTINGS_SYS_STATUS_ERROR:
      'Systemet kör med begränsad funktionalitet, kontakta support ( mail: <a' +
      ' href="mailto:support@ferroamp.se">support@ferroamp.se</a> eller telefon: </a' +
      ' href="tel:+46868433390">+46 (0)8 684 33 390</a>).' +
      '  för hjälp',
    SYSTEM_STATUS: 'Systemstatus',

    ONLY_MEASURING: 'Endast mätning',
    MODES: 'Mod',
    CONFIRM_OPERATION_SETTINGS_CHANGES:
      'Är du säker på att du vill ändra inställningarna till:  <span' +
      ' class="color-blue"> ACE-tröskelvärde: <ACE></span> och <span class="color-blue">Läge: <MODE></span>?',

    SYSTEM_CONFIG_NO_CHANGES: 'Inga förändringar gjorda, skippar att uppdatera inställningarna',

    // CT
    CT_SETTINGS: 'Strömtransformatorer',
    CT_RATIO: 'Omsättningstal för strömtransformatorer',

    //DISPLAY_ACCESS
    DISPLAY_ACCESS: 'Energyhub-systemet',
    DISPLAY_ACCESS_RUNMODE: 'Energyhub-systemet har skickat ett nytt driftläge : <TYPE>',
    DISPLAY_ACCESS_EMSCONFIG: 'Energyhub-systemet har skickat nya driftinställningar.',

    //META AND RESPONSES
    RESPONSE_ACK: 'Förfrågan har mottagits och systemet kommer behandla den, invänta svar.',
    RESPONSE_NAK:
      'Förfrågan har misslyckats. På grund av orsak : <REASON>. Vor god försök igen eller kontakta support.',

    NEW_REQUEST: 'En ny förfrågan av typ <TYPE> har begärts.',
    WAIT_UNTIL_RESULT: 'Vänta till resultatet är mottaget',

    ENERGYHUB_SYSTEM_RECEIVED_GET_REQUEST: 'Systemet har mottagit förfrågan och bearbetar den nu.',
    OPERATION_SETTINGS_RECEIVED: 'Driftinställningarna har mottagits',
    SYSTEM_SETTINGS_RESOLVE_ERROR:
      'Energyhub-systemen verkar ha problem med att lösa din förfrågan, var god försök igen eller kontakta support',
    USER_JOIN_ROOM: 'Användare: <USER>, har börjat titta på system-konfigurationen',
    SYSTEM_CONFIGURATION_OPERATION_MODE_INFO:
      '<h2>Driftläge</h2> <p>Driftläge definierar EnergyHub-systemets tillstånd.' +
      ' Följande driftlägen är möjliga:</p> <ul> <li><b>Mätning:</b> Systemet befinner sig i ett läge där systemet' +
      ' endast utför mätningar. Kraftelektroniken är inaktiv. </li> <li><b>Kör:</b> Systemet kör <em>fasbalansering</em>,' +
      ' <em>solelproduktion</em> och/eller <em>laddning/urladdning av batterier</em>. Kraftelektroniken är aktiv. </li>' +
      ' <li><b>Fel:</b> Systemet har detekterat ett fel. Om felläge kvarstår, kontakta Ferroamps support:' +
      '<a href="mailto:support@ferroamp.se">support@ferroamp.se</a>.</li> </ul> <ul> <li> <b>Start:' +
      '</b> Skickar kommande till systemet för att gå till <em>Körläge</em>.</li> <li><b>Stopp:</b>' +
      ' Skickar kommando till systemet för att gå till <em>"Mätningsläge"</em>. Systemet måste vara i' +
      ' <em>"Mätningsläge"</em> för att kunna ändra <em>Driftinställningar</em>.</li> </ul>',
    SYSTEM_CONFIGURATION_OPERATION_SETTINGS_INFO:
      '<h2>Driftinställningar</h2> <p>Driftinställningarna beskriver' +
      ' hur systemet fungerar när <em>"Körläge"</em> är aktivt.</p> <p><b>Mod:</b> Olike moder definierar hur' +
      ' systemet ska köras. Följande moder är tillgängliga:</p> ' +
      '<ul> <li><b>ACE:</b> Systemet kör endast fasbalansering (ACE). Detta kräver att ACE-tröskelvärde är' +
      ' definierat. </li> <li><b>ACE &amp; PV</b> : Systemet kör både <em>ACE</em> och <em>solelproduktion</em>' +
      '</li> <li><b> PV:</b> Systemet kör endast <em>solelproduktion</em></li> </ul> ' +
      '<h4>För att ändra driftinställningar:</h4> <ul class="operation_change_settings_info"> ' +
      '<li><b>Stoppa systemet</b> genom att trycka på <em>"Stopp"</em></li> <li><b>Gör önskade ändringar</b> ' +
      'genom att ändra parametrar i <em>driftinställningarna</em></li> <li><b>Uppdatera driftinställningarna</b> ' +
      'genom att trycka på <em>"Uppdatera driftinställningar</em></li> <li><b>Starta systemet</b> gå tillbaks till' +
      ' <em>Körläge</em> genom att trycka på <em>"Start"</em></li></ul>',

    SYSTEM_CONFIGURATION_ICON_INFO:
      '<h3>Ikon-förklaringar</h3><ul><li><i aria-hidden="true" class=' +
      '"fa fa-globe fe-success" title="EnergyHub-systemet"></i> - EnergyHub-systemet</li><li><i aria-hidden="true" class="fa fa-user color-blue"' +
      ' title="En annan användare"></i> - En annan användare</li>',

    LOG_ERROR: 'Fel',
    LOG_INFO: 'Info',
    LOG_WARNING: 'Varning',
    LOG_SUCCESS: 'Success',

    /*
         CLOUD API
         */
    CLOUD_API: 'API',
    CLOUD_IP_EPLANATION: 'Förklaring av API bla bla bla',

    API_KEY: 'API Nyckel',
    GENERATE_API_KEY: 'Generera',
    SAVE_API_SETTINGS: 'Spara API-inställningar',
    BIND_TO_IP: 'Bind API till IP',
    MY_IP: 'Använd mitt IP',
    LIST_OF_CHANGES: 'Lista över ändringar gjorda',
    MAX_BIND_TO_FIVE_IP: 'Kan endast binda 5 IP.',

    /*
         EXPORT DATA
         */
    EXPORT_DATA: 'Export Data',
    START_TIME: 'Från datum',
    RESOLUTION: 'Upplösning',
    END_TIME: 'Till datum',
    GET_DATA: 'Hämta Data',
    DATA_FORMAT: 'Dataformat',
    DATA_TYPE: 'Datatyp',
    EXPORT_DATA_INFO:
      'Man kan max ladda ner 30 dagar i taget. Observera att varje dag är ungefär 17-20 MB vid' +
      ' sekundupplöst data',
    SECOND: 'Sekund',
    SECONDS: 'Sekunder',
    MINUTE: 'Minut',
    HOUR: 'Timme',
    ENERGY_STORAGE_OPTIMIZER: 'Energilagrings-optimerare',
    ENERGY_COUNTERS: 'Energiräknare',
    SOLAR_STRING_OPTIMIZER: 'Solsträngsoptimerare',
    AGGREGATED_ENERGY_STORAGE: 'Aggregerat energilager',
    UI_DATA_TITLE: 'UiData - Strömmar, spänningar och effekter',
    MAX_ALLOWED_NUMBER_OF_DAYS: 'Maximalt antal dagar som får förfrågas med dessa inställningar är',
    DAYS: 'dagar',
    WHICH_ARE_EXPORTED: 'som kommer att exporteras är: ',
    PLEASE_WAIT_DOWNLOAD:
      'Var god att vänta till att nerladdning har börjat, det kan ta upp till flera minuter' +
      ' beroende på upplösning och tidsintervall',
    //"Please wait for the download to start</b> when pressing the button, can take up to several" +
    //"                    minutes depending on the resolution and time interval"

    MISSING_DATA_EXPLANATION:
      'Om det bara är kommatecken (,) i en rad beror detta på att data saknas för denna' + ' tid.',
    //If there is three commas (,) and no timestamp for a row, this data has never been reported from the
    //system
    CONVERT_CSV_TO_XLSX: 'Konvertera csv till xlsx-format',
    OPEN_IN_EXCEL: 'Öppna dokumentet i excel',
    NAVIGATE_TO_DATA_TEXT: 'Navigera till "Text till kolumnet" under "Data" i menyn',
    CONVERT_TO_EXCEL: 'Följ instruktionerna, se till och välja komma(,) som avgränsare',
    DATA_EXPLANATION: 'Förklaring av dataparametrar',
    SHOW: 'Visa',
    LAST_UPDATED: 'Senast updaterad',
    NONE: 'None',
    DISCHARGE: 'Ladda ur',
    CHARGE: 'Ladda',
    THRESHOLD: 'Tröskelvärde',
    BATTERY_POWER_REF: 'Batteri effekt-referens',
    BATTERY_SOC: 'Batteri laddningsstatus (SOC) ',
    OFF: 'Av',
    EXPORT: 'Export',
    IMPORT: 'Import',
    LIMIT: 'Begränsa',
    BATTERY_POWER: 'Batterieffekt',
    LOWER_SOC_REFERENCE: 'Lägre laddningsstatusreferens',
    UPPER_SOC_REFERENCE: 'Övre laddningsstatusreferens',
    GRID_THRESHOLD: 'Nät-tröskelvärde',
    POWER_REFERENCE: 'Effekt-referens',
    SET: 'Konfigurera',
    GET: 'Hämta',
    WIZARD: 'Wizard',
    BATTERY_WIZARD: 'Batteri Wizard',
    STATUS: 'Status',
    MISSING_ENERGY_COUNTER_LINK: 'https://ferroamp.freshdesk.com/a/solutions/articles/47001219726?lang=sv-SE',
    WARNING_ACTIVATED_SCHEDULING: 'Schemaläggning är aktiverat',
    OPERATION_SETTINGS_CONFIG_HANDLED_BY_SCHEDULING:
      'Klicka på ”Schemaläggning” för att hantera inställningar i schemat. Klicka på "Tillfälliga inställningar" för att göra en tillfällig ändring. Den tillfälliga ändringen kommer att gälla fram till nästa schemalagda händelse.',
    TEMPORARY_OPERATION_SETTINGS: 'Tillfälliga driftinställningar',
    GO_TO_SCHEDULING: 'Gå till schemaläggning'
  },
  Alarms: {
    STATUS_TITLE: 'Larmstatus',
    STATUS_OPEN_DOCUMENTATION: 'Öppna dokumentationen',
    GENERAL_DOCUMENTATION_LINK: 'https://support.ferroamp.com/sv-SE/support/solutions/folders/47000787939',
    NOTIFY_ALL: 'Ändra prenumeranter för alla alarm',
    ALARM_CODE: 'Typ av larm',
    ALARM_PRODUCT_ID: 'Id',
    ALARM_STATUS: 'Status',
    ALARM_LAST_CHANGED: 'Senast ändrad',
    ALARM_SUBSCRIBE: 'Prenumerera',
    ALARM_DETAILS: 'Detaljerad info',
    AFFECTED_SSOS: 'Påverkade SSOer',
    ALARM_INFO: 'Information',
    ALARM_RAISED_WHEN: 'Utlöste',
    ALARM_RESOLVED_WHEN: 'Återställdes',
    TOOLTIP_ALARM_INFO: 'Läs mer om detta larmet',
    TOOLTIP_ALARM_ACTIVE: 'Ett fel har upptäckts',
    TOOLTIP_ALARM_INACTIVE: 'Allt fungerar som det ska',
    TOOLTIP_ALARM_UNKNOWN: 'Okänd status',
    ALARM_LOG_TITLE: 'Larmhistorik',
    NOTIFICATIONS_TITLE: 'Notifieringar',
    WHO_DO_WE_CALL: 'Vem ska vi notifiera?',
    SUBSCRIBABLE_ALARM_CODE_ALL: 'vid alla larm',
    SUBSCRIBABLE_ALARM_CODE_SYSTEM_OFFLINE: 'System offline',
    SUBSCRIBABLE_ALARM_CODE_SSO_OFFLINE: 'SSO offline',
    SUBSCRIBERS: 'Prenumeranter för detta larm',
    SUBSCRIBED_TO_EVERYTHING: 'Prenumeranter för alla larm',
    ADD: 'Lägg till',
    SUPPORT_LINKS: {
      SYSTEM_OFFLINE:
        'https://support.ferroamp.com/sv-SE/support/solutions/articles/47001257481-hur-fungerar-system-offline-larmet-',
      SSO_OFFLINE:
        'https://support.ferroamp.com/sv-SE/support/solutions/articles/47001257479-hur-fungerar-sso-offline-larmet-'
    },
    USER_ALREADY_ADDED: 'Användaren eller email är redan tillagd',
    NOT_VALID_EMAIL: 'Inte en giltig email-address',
    USER_ALREADY_ADDED_TO_ALL_ALARMS: 'Användaren eller emailen prenumererar redan på alla alarm'
  }
}
