import { ApolloQueryResult } from '@apollo/client'
import { GetPowershareDashboardEnergycountersQuery } from '@app/graphql/generated'
import { energy2String } from '../../../lib/general'
import { EnergyFirstLasteE } from '../../../types'

export interface PowerShareEnergyObject {
  gridExported: string
  gridImported: string
  inverterImported: string
  inverterExported: string
  solar: string
  solarRaw: number
}
export const fixOldEnergyData = (energyData: EnergyFirstLasteE): PowerShareEnergyObject => {
  const le = energyData.last_e
  const inverterImported = le.ic1 + le.ic2 + le.ic3
  const inverterExported = le.ip1 + le.ip2 + le.ip3
  const gridImported = le.ec1 + le.ec2 + le.ec3
  const gridExported = le.ep1 + le.ep2 + le.ep3
  const solar = Number(le.pve)
  return {
    gridExported: energy2String(gridExported),
    gridImported: energy2String(gridImported),
    inverterImported: energy2String(inverterImported),
    inverterExported: energy2String(inverterExported),
    solar: energy2String(solar),
    solarRaw: isNaN(solar) ? 0 : solar
  }
}

export const fixNewEnergyData = (
  energy?: ApolloQueryResult<GetPowershareDashboardEnergycountersQuery>['data']['facility']['measurements']['totalProduction'][0]
): PowerShareEnergyObject => {
  const {
    gridImportL1,
    gridImportL2,
    gridImportL3,
    gridExportL1,
    gridExportL2,
    gridExportL3,
    pvProduced,
    inverterImportL1,
    inverterImportL2,
    inverterImportL3,
    inverterExportL1,
    inverterExportL2,
    inverterExportL3
  } = energy || {}

  return {
    gridImported: energy2String(gridImportL1 + gridImportL2 + gridImportL3),
    gridExported: energy2String(gridExportL1 + gridExportL2 + gridExportL3),
    solar: energy2String(pvProduced),
    inverterExported: energy2String(inverterExportL1 + inverterExportL2 + inverterExportL3),
    inverterImported: energy2String(inverterImportL1 + inverterImportL2 + inverterImportL3),
    solarRaw: isNaN(pvProduced) ? 0 : pvProduced
  }
}
