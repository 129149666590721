import { ElectricalComponentColors } from '../../service'
import {
  gaugeNeedle,
  PowerSchematicService,
  CurrentSchematicService,
  Needle,
  liquidFillGaugeDefaultSettings,
  loadLiquidFillGauge,
  liquidFillGaugeUpdateFunction
} from '../../../lib'
import { LanguageGlobally, LanguageSystemdashboard } from '../../language/language.interface'
import {
  UiGaugeUnion,
  UiBatteryPowerGauge,
  UiGridCurrentGauge,
  UiConsumptionPowerGauge,
  UiGridPowerGauge,
  UiSolarPowerGauge,
  GetOneFacilityQuery
} from '@app/graphql/generated'

export interface CreateGaugesOptions {
  gauges: Array<UiGaugeUnion>
  facility: GetOneFacilityQuery['facility']
  language: LanguageGlobally
  colors: ElectricalComponentColors
  dashboardLanguage: LanguageSystemdashboard
}

interface Gauges {
  batModulePower?: Needle
  pvPowerGauge?: Needle
  loadOne?: Needle
  loadThree?: Needle
  loadTwo?: Needle
  loadPowerGauge?: Needle
  gridPowerGauge?: Needle
}

export interface SystemDashboardGauges {
  liquidBatterySoc?: liquidFillGaugeUpdateFunction
  batModulePower?: Needle
  pvPowerGauge?: Needle
  loadOne?: Needle
  loadThree?: Needle
  loadTwo?: Needle
  loadPowerGauge?: Needle
  gridPowerGauge?: Needle
  powerSchematic?: PowerSchematicService
  aceSchematic?: CurrentSchematicService
}

const createGridPowerGauge = (
  gaugeOptions: UiGridPowerGauge,
  language: LanguageGlobally,
  colors: ElectricalComponentColors
) => {
  return gaugeNeedle({
    ...gaugeOptions,

    id: 'gridPower',
    value: 0,
    colorPos: colors.grid.line,
    twoDirection: true,
    language
  })
}

const createConsumptionPowerGauge = (
  gaugeOptions: UiConsumptionPowerGauge,
  language: LanguageGlobally,
  colors: ElectricalComponentColors
): Needle => {
  return gaugeNeedle({
    ...gaugeOptions,
    id: 'loadPower',
    value: 0,
    colorPos: colors.consumption.line,
    language
  })
}
/////
const createGridCurrentGauges = (
  gaugeOptions: UiGridCurrentGauge,
  language: LanguageGlobally,
  colors: ElectricalComponentColors
): { loadOne: Needle; loadTwo: Needle; loadThree: Needle } => {
  const loadOne = gaugeNeedle({
    ...gaugeOptions,
    id: 'loadOne',
    value: 0,
    colorNeg: colors.phase1.bar,
    colorPos: colors.phase1.bar,
    language
  })

  const loadTwo = gaugeNeedle({
    ...gaugeOptions,
    id: 'loadTwo',
    value: 0,
    colorNeg: colors.phase2.bar,
    colorPos: colors.phase2.bar,
    language
  })

  const loadThree = gaugeNeedle({
    ...gaugeOptions,
    id: 'loadThree',
    value: 0,
    colorNeg: colors.phase3.bar,
    colorPos: colors.phase3.bar,
    language
  })

  return { loadOne, loadTwo, loadThree }
}

const createSolarPowerGauge = (
  gaugeOptions: UiSolarPowerGauge,
  language: LanguageGlobally,
  colors: ElectricalComponentColors
): Needle => {
  return gaugeNeedle({
    ...gaugeOptions,
    id: 'pvPower',
    value: 0,
    language,
    colorPos: colors.solar.line
  })
}
const createBatteryPowerGauge = (
  gaugeOptions: UiBatteryPowerGauge,
  language: LanguageGlobally,
  colors: ElectricalComponentColors
): Needle => {
  return gaugeNeedle({
    ...gaugeOptions,
    id: 'batPower',
    twoDirection: true,
    value: 0,
    colorPos: colors.battery.line,
    language
  })
}

export function createGauges({
  colors,
  language,
  gauges,
  dashboardLanguage
}: CreateGaugesOptions): SystemDashboardGauges {
  const aceSchematic = new CurrentSchematicService('aceSchematicId')
  aceSchematic.setLanguage(dashboardLanguage)

  const gaugesToShow = gauges.map((gauge: UiGaugeUnion) => {
    switch (gauge.__typename) {
      case 'UIBatteryPowerGauge':
        return {
          batModulePower: createBatteryPowerGauge(gauge, language, colors)
        }
      case 'UIConsumptionPowerGauge':
        return {
          loadPowerGauge: createConsumptionPowerGauge(gauge, language, colors)
        }
      case 'UIGridCurrentGauge':
        aceSchematic.setMainFuse(gauge.max)
        return createGridCurrentGauges(gauge, language, colors)
      case 'UIGridPowerGauge':
        return {
          gridPowerGauge: createGridPowerGauge(gauge, language, colors)
        }
      case 'UISolarPowerGauge':
        return {
          pvPowerGauge: createSolarPowerGauge(gauge, language, colors)
        }
    }
  })

  const batterySocOptions = liquidFillGaugeDefaultSettings()
  batterySocOptions.circleColor = colors.battery.line
  batterySocOptions.waveColor = colors.battery.line
  batterySocOptions.textColor = colors.battery.line
  const batterySOC = loadLiquidFillGauge('batterySOC', 0, batterySocOptions)

  const returnGauges = Object.assign({}, ...gaugesToShow) as Gauges

  return {
    ...returnGauges,

    liquidBatterySoc: batterySOC,
    aceSchematic
  }
}
