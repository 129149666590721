import { IComponentOptions, material } from 'angular'
import { LanguageAlarms } from '@app/language/language.interface'
import { AmplitudeService, FerroConfiguration, FerroConfigurationName } from '@app/service'
import { toSubscribableAlarmCode, translateCode } from './alarms.lib'
import templateUrl from './alarms.component.html'
import { AmplitudeServiceName } from '@app/service/amplitude.service'
import { SubscriptionView } from './alarms-add-user.component'

class AlarmsController {
  l: LanguageAlarms
  translateCode = translateCode
  $mdDialog: material.IDialogService
  page = 'alarm'
  constructor(
    ferroConfiguration: FerroConfiguration,
    $mdDialog: material.IDialogService,
    amplitudeService: AmplitudeService
  ) {
    this.l = ferroConfiguration.language.Alarms
    this.$mdDialog = $mdDialog
    amplitudeService.logEvent('View Alarms')
  }

  showWhoToNotify(ev: any, to: string) {
    SubscriptionView(ev, this.$mdDialog, toSubscribableAlarmCode(to))
  }
}

AlarmsController.$inject = [FerroConfigurationName, '$mdDialog', AmplitudeServiceName]

export const AlarmsComponentName = 'alarmsComponent'
export const AlarmsComponent: IComponentOptions = {
  controller: AlarmsController,
  templateUrl
}
