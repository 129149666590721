import { IHttpPromise, IHttpService } from 'angular'
import { Environment } from '../../environments/environment'
import { EhubHttpService, EhubHttpServiceName, EhubTranslateMacAddressRespond } from './ehub-http.service'

import {
  DestructedApplicationInstanceId,
  SysStateMeasurePayloadData,
  SysStateNonMeasurePayloadData,
  SystemConfigurationEventTypes,
  SystemConfigurationEventTypesEnum,
  SysStateCommandTypes,
  SysStateMeasuredPayloadDataList
} from '@ferroamp/system-configuration-lib'

export interface SystemStateCurrentStateResponse {
  currentState?: number // 0 | 1 | 8;
  updatedAt: Date
  list: SysStateMeasuredPayloadDataList
}
export interface SystemStateStateChangedIoEvent {
  command: SysStateCommandTypes
  data: SysStateMeasurePayloadData
  applicationInstanceId: DestructedApplicationInstanceId
  transTs: Date
  facilityId: number
  eventType: SystemConfigurationEventTypesEnum.STATECHANGED
}

export interface SystemStateCommandIoEvent {
  eventType: SystemConfigurationEventTypes
  command: SysStateCommandTypes
  data: SysStateNonMeasurePayloadData
  applicationInstanceId: DestructedApplicationInstanceId
  transTs: Date
  facilityId: number
}

const endpoint = Environment.systemStateV2Options.endpoint
export const SystemStateV2HttpServiceName = 'systemStateV2HttpService'

export interface ParsedStateLilstWithEhubMapping extends EhubTranslateMacAddressRespond {
  state: string
}

export class SystemStateV2HttpService {
  private $http: IHttpService
  private eService: EhubHttpService

  constructor($http: IHttpService, eService: EhubHttpService) {
    this.$http = $http
    this.eService = eService
  }

  public getCurrentSystemState(facilityId: number): IHttpPromise<SystemStateCurrentStateResponse> {
    return this.$http<SystemStateCurrentStateResponse>({
      method: 'GET',
      url: `${endpoint}/current/${facilityId}`
    })
  }

  public async getCurrentSystemStateWithEhubMapping(facilityId: number): Promise<{
    data: number
    list: EhubTranslateMacAddressRespond[]
    transTs: Date
  }> {
    const result = await this.getCurrentSystemState(facilityId)
    const currentState = result.data
    const macAddressList = currentState.list.map(state => state.id)
    const list = await this.eService.translateMacAddress(macAddressList)
    return {
      data: currentState.currentState,
      transTs: currentState.updatedAt,
      list: list.data
    }
  }

  /**
   *
   */
  public commandStart(facilityId: number): IHttpPromise<string> {
    return this.$http({
      method: 'POST',
      url: `${endpoint}/commands/start/${facilityId}`
    })
  }

  public commandStop(facilityId: number): IHttpPromise<string> {
    return this.$http({
      method: 'POST',
      url: `${endpoint}/commands/stop/${facilityId}`
    })
  }

  public commandGet(facilityId: number): IHttpPromise<string> {
    return this.$http({
      method: 'POST',
      url: `${endpoint}/commands/get/${facilityId}`
    })
  }
}

SystemStateV2HttpService.$inject = ['$http', EhubHttpServiceName]
