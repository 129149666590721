import type { Language } from 'rrule/dist/esm/nlp/i18n'
import { RRule } from 'rrule'
import moment from 'moment'
import { SwedishLanguage } from './swedish'
import { capitalize } from 'lodash'

const dayNames = SwedishLanguage.Globally.daylong

const sunday = dayNames.pop()
dayNames.splice(0, 0, sunday)
export const SWEDISH: Language = {
  dayNames: dayNames,
  monthNames: SwedishLanguage.Globally.monthlon,
  tokens: {}
}
const swedishWords: { [_: string]: string } = {
  every: 'varje',
  until: 'till',
  day: 'dag',
  days: 'dagar',
  week: 'vecka',
  weeks: 'veckor',
  on: 'på',
  in: 'om',
  'on the': 'på den',
  for: 'för',
  and: 'och',
  or: 'eller',
  at: 'på',
  last: 'senaste',
  '(~ approximate)': '(~ ungefär)',
  times: 'tider',
  time: 'tid',
  minutes: 'minuter',
  hours: 'timmar',
  weekdays: 'veckodagar',
  weekday: 'veckodag',
  months: 'månader',
  month: 'månad',
  years: 'åren',
  year: 'år'
}
const getText = (id: string) => {
  return swedishWords[id]
}
const dateFormat = (year: number, month: string, day: number) =>
  moment().date(day).year(year).month(month).format('LL')

export const SwedishRRuleToText = ({ rule }: { rule: RRule }) => {
  return rule ? capitalize(rule.toText(getText, SWEDISH, dateFormat)) : ''
}
