import { IComponentOptions, IDeferred, IQService, IScope, copy } from 'angular'
import moment from 'moment-timezone'
import {
  AmplitudeService,
  ElectricalComponentColors,
  FerroConfiguration,
  FerroConfigurationName
} from '../../../service'
import { LanguageAnalaysis } from '../../../language/language.interface'
import { adjustTimePeriodWithTimezone, getNewTimePeriod } from './battery-simulation.tools'
import {
  batterySimulationEnergyResolution,
  batterySimulationPowerResolution,
  BatterySimulationService,
  BatterySimulationServiceName
} from './battery-simulation.service'
import { ECharts } from 'echarts'
import { processMainAggregateDataType } from '../../views/power-view'
import templateUrl from './battery-simulation.component.html'
import {
  attachedSimulatedDataEnergy,
  attachedSimulatedDataPower,
  createBatterySimulationCharts
} from './battery-simulation.chart'
import {
  clearEchartsInstance,
  echartsShowLoadingOptions,
  EchartsShowLoadingType,
  tooltipDateFormatter
} from '../../../../lib'
import { batterySimulation, PowerDataType, EnergyDataType } from './battery-simulation.calculations'

import {
  ECHARTS_ENERGY_DATASET_DIMENSIONS,
  ProcessedEchartEnergyData
} from '../../views/energy-view/energy-view.tools'
import {
  GraphqlPowerDataRetentionsService,
  GraphqlPowerDataRetentionsServiceName
} from '../../../graphql/retention.queries'
import { oneDayInMilliseconds } from './constants'
import { AmplitudeServiceName } from '@app/service/amplitude.service'

export type batterySimulationMainGraphData = [Date, number][]
export type batterySimulationSecondGraphData = [Date, number, number, number][]

let restChart: ECharts
let gridChart: ECharts

export interface BatterySimulationOptions {
  bsize: number
  minStored: number
  maxStored: number
  maximumChargeLevelOfBatterySize: number
  chargeBatteryThres: number
  dischargeBatteryThres: number
  batteryStartCharge: number
  resolution: number
  resolutionType: string
  type: string
}

export interface BatteryCalculationOptions {
  useCurrent: boolean
  energyOffset: number
  maxPower: number
  highestEnergyOffset: number
  lowestEnergyOffset: number
  isCalculated: boolean
  uppThreshold: number
  lowThreshold: number
}

export type SIMULATED_POWER_DIMENSION_TYPE = [
  'ts',
  'grid',
  'load',
  'solar',
  'battery',
  'simulatedBatPower',
  'simulatedSoc',
  'grid+SimulatedBattery'
]
export const SIMULATED_POWER_DIMENSION: SIMULATED_POWER_DIMENSION_TYPE = [
  'ts',
  'grid',
  'load',
  'solar',
  'battery',
  'simulatedBatPower',
  'simulatedSoc',
  'grid+SimulatedBattery'
]
export type SimulatedPowerDataSet = [Date, number, number, number, number, number, number][]

export type SIMULATED_ENERGY_DIMENSION_TYPE = [
  'ts',
  'pve',
  'load',
  'grid',
  'soldToGrid',
  'selfCons',
  'simulatedBatPower',
  'simulatedSoc',
  'grid+SimulatedBattery'
]
export const SIMULATED_ENERGY_DIMENSION: SIMULATED_ENERGY_DIMENSION_TYPE = [
  'ts',
  'pve',
  'load',
  'grid',
  'soldToGrid',
  'selfCons',
  'simulatedBatPower',
  'simulatedSoc',
  'grid+SimulatedBattery'
]

export type batterySimulationResolutionTypes = 'power' | 'energy'

enum BatterySimulationResolutionTypes {
  Power = 'power',
  Energy = 'energy'
}

class BatterySimulationController {
  error = ''
  batterysimerror = ''
  l: LanguageAnalaysis
  $s: IScope

  bat1 = true
  cons1 = true
  pv1 = true
  simBp1 = true
  simStatus1 = true

  simStatus2 = true
  net2 = true
  net1 = true

  disEnergy = true
  hideBatCalc = true

  hideBatterySimulation = false

  type: BatterySimulationResolutionTypes = BatterySimulationResolutionTypes.Power

  endDate = moment().toDate()
  startDate = moment().subtract(6, 'days').toDate()

  maxDate: Date
  minDate: Date

  loading = true

  ifSecondaryGraphReady = true
  ifMainGraphReady = true

  powerResolution: batterySimulationPowerResolution = 'minute'
  energyResolution: batterySimulationEnergyResolution = 'hour'
  resolution: batterySimulationResolutionTypes = 'power'

  secondaryGraphTitle: string
  mainGraphTitle: string

  bat: BatterySimulationOptions = {
    bsize: 7.2,
    minStored: 10,
    maxStored: 90,
    maximumChargeLevelOfBatterySize: Number((0.8 * 7.2).toPrecision(3)),
    chargeBatteryThres: 0,
    dischargeBatteryThres: 0,
    batteryStartCharge: 100,
    resolution: 0,
    resolutionType: '',
    type: ''
  }

  timezone: string
  colors: ElectricalComponentColors
  batcalc: BatteryCalculationOptions

  service: BatterySimulationService
  fc: FerroConfiguration

  canceller: IDeferred<unknown>

  powertDataset: PowerDataType[]
  energyDataset: EnergyDataType[]
  restChartDataset: any[]

  powerDataMinDate: Date
  amplitude: AmplitudeService
  activeDecision = false
  constructor(
    $scope: IScope,
    batterySimulationService: BatterySimulationService,
    ferroConfiguration: FerroConfiguration,
    $q: IQService,
    graphqlPowerDataRetentionsService: GraphqlPowerDataRetentionsService,
    amplitude: AmplitudeService
  ) {
    this.amplitude = amplitude
    this.$s = $scope
    this.fc = ferroConfiguration
    this.canceller = $q.defer()
    this.l = ferroConfiguration.language.SystemAnalysis
    this.colors = ferroConfiguration.electricColors
    batterySimulationService.init(
      Number(ferroConfiguration.facility.id),
      ferroConfiguration.facility.timezone,
      this.canceller.promise
    )
    this.service = batterySimulationService
    this.timezone = ferroConfiguration.facility.timezone || 'Europe/Stockholm'
    this.maxDate = this.fc.getMaxDate()
    this.powerDataMinDate = graphqlPowerDataRetentionsService.getPowerData1MinuteMinDate()
    this.minDate = this.powerDataMinDate
  }

  $onInit(): void {
    this.getData(true)
  }

  $onDestroy(): void {
    clearEchartsInstance(restChart)
    restChart.dispose()
    restChart = null
    clearEchartsInstance(gridChart)
    gridChart.dispose()
    gridChart = null
    this.canceller.resolve()
  }

  onDateChange(dateChanged: 'start' | 'end'): void {
    const { start, end } = getNewTimePeriod({
      dateChanged,
      startDate: this.startDate,
      endDate: this.endDate
    })

    this.startDate = start
    this.endDate = end
  }

  activeCharts(): Array<ECharts> {
    return [gridChart, restChart].filter(c => c)
  }

  clearChart(): void {
    this.activeCharts().forEach((chart: ECharts) => chart.clear())

    gridChart = null
    restChart = null
  }

  stopLoading(): void {
    this.$s.$applyAsync(() => {
      this.loading = false
    })

    if (gridChart) gridChart.hideLoading()
    if (restChart) restChart.hideLoading()
  }

  getData(first?: boolean): void {
    this.activeCharts().forEach(chart => {
      chart.showLoading(EchartsShowLoadingType, echartsShowLoadingOptions())
    })

    if (!first) {
      this.activeDecision = true
    }

    this.error = ''
    this.loading = true

    const { start: starttime, end: endtime } = adjustTimePeriodWithTimezone(
      this.startDate,
      this.endDate,
      this.timezone
    )

    if (starttime > endtime) {
      this.error = this.l.STARTTIME_NOT_GREATER_ENDTIME
      return
    }

    const daysFromToday = moment().endOf('day').diff(starttime, 'days')
    this.amplitude.logEvent('Get Simulate Battery Data', {
      activeDecision: this.activeDecision,
      daysFromToday,
      simulationType: this.type
    })

    switch (this.type) {
      case BatterySimulationResolutionTypes.Power: {
        const twoWeeksInMilliseconds = oneDayInMilliseconds * 2 * 7
        if (endtime.getTime() - starttime.getTime() > twoWeeksInMilliseconds) {
          this.error = this.l.NO_MORE_THAN_TWO_WEEKS_1_MIN
          return
        }
        this.service
          .getDataPower(starttime, endtime, 'minute')
          .then(data => {
            this.clearChart()
            this.plotterPower(data)
          })
          .catch(() => {
            this.error = this.l.ERROR_OCCURRED
            this.stopLoading()
          })
        break
      }
      case BatterySimulationResolutionTypes.Energy: {
        this.service.getDataEnergy(starttime, endtime, 'hour', (error, data) => {
          if (error) {
            this.error = this.l.ERROR_OCCURRED
            this.stopLoading()
            return
          }
          this.clearChart()
          this.plotterEnergy(data)
        })

        break
      }
    }
  }

  async plotterEnergy(data: ProcessedEchartEnergyData): Promise<void> {
    this.secondaryGraphTitle = this.l.ENERGY
    this.mainGraphTitle = this.l.ENERGY
    const gridData: any = []
    const restChartData: any = []
    const divider = 1
    this.energyDataset = []
    data.totalEnergyData.all.forEach(d => {
      gridData.push([d[0], (d[3] - d[5]) * divider])
      restChartData.push([d[0], d[2] * divider, d[1] * divider, d[4] * divider])
      this.energyDataset.push([d[0], (d[3] - d[5]) * divider, d[2] * divider, d[1] * divider, d[4] * divider])
    })

    if (!gridChart || !restChart) {
      const charts = await createBatterySimulationCharts(
        this.l,
        this.colors,
        { data: [] } as processMainAggregateDataType,
        this.timezone
      )
      gridChart = charts.grid
      restChart = charts.rest
    }

    const op = {
      tooltip: {
        formatter: tooltipDateFormatter('hours', this.timezone)
      },
      dataset: {
        source: gridData,
        dimensions: ECHARTS_ENERGY_DATASET_DIMENSIONS.slice(0, 1)
      }
    }

    gridChart.setOption(op)

    restChart.setOption({
      tooltip: {
        formatter: tooltipDateFormatter('hours', this.timezone)
      },
      dataset: {
        source: restChartData,
        dimensions: ['ts', 'load', 'solar', 'battery']
      },
      series: [
        {
          type: 'line',
          areaStyle: { opacity: 0.2 },
          name: this.l.CONSUMPTION,
          encode: {
            x: 'ts',
            y: 'load'
          }
        },
        {
          areaStyle: { opacity: 0.2 },
          type: 'line',
          name: this.l.SOLAR_PRODUCTION,
          encode: {
            x: 'ts',
            y: 'solar'
          }
        },
        {
          type: 'line',
          areaStyle: { opacity: 0.2 },
          name: this.l.BATTERY_POWER,
          encode: {
            x: 'ts',
            y: 'battery'
          }
        }
      ]
    })

    this.stopLoading()
  }

  async plotterPower(data: processMainAggregateDataType): Promise<void> {
    this.secondaryGraphTitle = this.l.POWER
    this.mainGraphTitle = this.l.POWER
    this.powertDataset = data.data
    if (!gridChart || !restChart) {
      const charts = await createBatterySimulationCharts(this.l, this.colors, data, this.timezone)
      gridChart = charts.grid
      restChart = charts.rest
    } else {
      const __data = data.data
      gridChart.setOption({
        tooltip: {
          formatter: tooltipDateFormatter('minutes', this.timezone)
        },
        dataset: {
          source: __data,
          dimensions: SIMULATED_POWER_DIMENSION
        },
        legend: {
          data: [this.l.GRID_POWER]
        }
      })

      restChart.setOption({
        tooltip: {
          formatter: tooltipDateFormatter('minutes', this.timezone)
        },
        dataset: {
          source: __data,
          dimensions: SIMULATED_POWER_DIMENSION
        },
        legend: {
          data: [this.l.CONSUMPTION, this.l.BATTERY_POWER, this.l.SOLAR_PRODUCTION]
        },
        series: [
          {
            type: 'line',
            areaStyle: { opacity: 0.2 },
            name: this.l.CONSUMPTION,
            encode: {
              x: 'ts',
              y: 'load'
            }
          },
          {
            type: 'line',
            areaStyle: { opacity: 0.2 },
            name: this.l.BATTERY_POWER,
            encode: {
              x: 'ts',
              y: 'battery'
            }
          },
          {
            areaStyle: { opacity: 0.2 },
            type: 'line',
            name: this.l.SOLAR_PRODUCTION,
            encode: {
              x: 'ts',
              y: 'solar'
            }
          }
        ]
      })
    }
    this.stopLoading()
  }

  simulateBattery(): void {
    gridChart.showLoading(EchartsShowLoadingType, echartsShowLoadingOptions())
    restChart.showLoading(EchartsShowLoadingType, echartsShowLoadingOptions())
    this.batterysimerror = ''
    this.loading = true

    this.bat.type = this.type

    this.bat.resolutionType = this.type === 'power' ? this.powerResolution : this.energyResolution

    const resolutionTimes = {
      second: 1,
      minute: 60,
      quarter: (60 * 60) / 4,
      hour: 60 * 60
    }

    if (this.type === BatterySimulationResolutionTypes.Power) {
      if (this.powerResolution === 'minute') {
        this.bat.resolution = resolutionTimes.minute
      } else {
        this.bat.resolution = resolutionTimes.second
      }
    } else {
      if (this.energyResolution === 'equartz') {
        this.bat.resolution = resolutionTimes.quarter
      } else {
        this.bat.resolution = resolutionTimes.hour
      }
    }
    this.amplitude.logEvent('Simulate Battery', {
      activeDecision: this.activeDecision,

      simulationType: this.type
    })

    let data: PowerDataType[] | EnergyDataType[]

    if (this.type == BatterySimulationResolutionTypes.Power) {
      data = copy(this.powertDataset)
    } else {
      data = copy(this.energyDataset)
    }

    batterySimulation(data, this.bat, (error, result) => {
      gridChart.hideLoading()
      restChart.hideLoading()
      this.loading = false

      if (error) {
        this.batterysimerror = error
        return
      }
      const parsedData: SimulatedPowerDataSet = (data as Array<PowerDataType | EnergyDataType>).map(
        (dataPoint: any, index: number) => {
          dataPoint.push(result.power[index])
          dataPoint.push(result.energy[index])
          dataPoint.push(result.power[index] + dataPoint[1])
          return dataPoint
        }
      ) as unknown as SimulatedPowerDataSet

      if (this.type == 'power') {
        attachedSimulatedDataPower(gridChart, restChart, parsedData, this.l, this.colors, this.type)
      } else {
        attachedSimulatedDataEnergy(gridChart, restChart, parsedData, this.l, this.colors)
      }
    })
  }
}

BatterySimulationController.$inject = [
  '$scope',
  BatterySimulationServiceName,
  FerroConfigurationName,
  '$q',
  GraphqlPowerDataRetentionsServiceName,
  AmplitudeServiceName
]

export const BatterySimulationComponentName = 'batterySimulationComponent'

export const BatterySimulationComponent: IComponentOptions = {
  controller: BatterySimulationController,
  templateUrl
}
