import { captureSentryException } from '@app/config/sentry'
import { SystemConfigurationGraphqlService, SystemConfigurationGraphqlServiceName } from '@app/graphql'
import { LanguageSystemSettings } from '@app/language/language.interface'
import {
  AmplitudeService,
  EmsConfigV2IoService,
  EmsConfigV2IoServiceName,
  FerroConfiguration,
  FerroConfigurationName,
  LanguageService
} from '@app/service'
import { AmplitudeServiceName } from '@app/service/amplitude.service'
import { EmsConfigV2HttpService, EmsConfigV2HttpServiceName } from '@app/service/ems-config-v2-http.service'
import {
  EmsConfigWebsocketCommandEvents,
  EmsConfigWebsocketEmsConfChangedEvent,
  SystemConfigurationEventTypesEnum
} from '@app/service/ems-config-v2-io.service'
import { languagesAvailable, LanguageServiceName } from '@app/service/language.service'
import { RolesServices, RolesServicesName } from '@app/service/roles.services'
import { Environment, OTHER_TRANSACTION_ONGOING_STRING } from '@environments/environment'
import { EmsConfNonMeasureDataObject, EmsParam } from '@ferroamp/system-configuration-lib'
import { echartsTimeFormatters } from '@lib/echarts'
import { StateService } from '@uirouter/core'
import angular, {
  IComponentOptions,
  IFormController,
  IHttpResponse,
  IPromise,
  IScope,
  ITimeoutService,
  material
} from 'angular'
import { systemSettingsStates } from '../../system-settings.routing.config'
import { BatteryWizardController, batteryWizardTemplate } from '../battery-wizard/battery-wizard.controller'
import { EmsConfigV2Language, en, se } from '../ems-config-form/ems-config.language'
import { emsConfigLanguages } from './ems-config-languages-internal'
import templateUrl from './ems-config.component.html'
import { emsConfigV2FrontendValidation } from './ems-config.validation'
import { reasonParser } from './reasons'

let timer: IPromise<void>

class EmsConfigV2Controller {
  error: string

  l: EmsConfigV2Language
  l2: LanguageSystemSettings
  io: EmsConfigV2IoService
  http: EmsConfigV2HttpService

  loading = true

  facilityId: number
  timezone: string
  timestamp: string

  status = ''
  statusClass = ''

  batteryPower: 'Charge' | 'Off' | 'Discharge' = 'Off'

  $s: IScope
  $mdD: material.IDialogService
  private $t: ITimeoutService
  hasEms = false
  config: EmsParam

  form: IFormController = null
  service: SystemConfigurationGraphqlService

  amplitude: AmplitudeService
  notAllowed: boolean
  private internalLanguage: (typeof emsConfigLanguages)['us']
  private currentLanguage: languagesAvailable
  state: StateService
  hasSchedulingActive = true
  ignoreSchedules = false
  constructor(
    ferroConfiguration: FerroConfiguration,
    emsConfigV2HttpService: EmsConfigV2HttpService,
    emsConfigV2IoService: EmsConfigV2IoService,
    $scope: IScope,
    $mdDialog: material.IDialogService,
    $timeout: ITimeoutService,
    languageService: LanguageService,
    amplitude: AmplitudeService,
    roles: RolesServices,
    service: SystemConfigurationGraphqlService,
    state: StateService
  ) {
    this.internalLanguage = emsConfigLanguages[languageService.language]
    this.amplitude = amplitude
    this.notAllowed = !roles.canUpdateFacilitySystemSettings()
    this.http = emsConfigV2HttpService
    this.$s = $scope
    this.$t = $timeout
    this.l = languageService.language === 'us' ? en : se
    this.l2 = ferroConfiguration.language.SystemSettings
    this.io = emsConfigV2IoService
    this.$mdD = $mdDialog
    this.facilityId = Number(ferroConfiguration.facility.id)
    this.currentLanguage = languageService.language

    this.service = service
    this.state = state
  }

  $onDestroy(): void {
    this.io.disconnect()
    if (timer) this.$t.cancel(timer)
    timer = null
  }

  goToScheduling(): void {
    this.state.go(systemSettingsStates.scheduling)
  }

  setIgnoreSchedules() {
    this.ignoreSchedules = true
    this.afterInit()
  }

  async $onInit(): Promise<void> {
    const res = await this.service.getEmsConfigSchedules(this.facilityId)

    const schedules = res?.data?.facility
    const hasDefaultSchedule = !!schedules?.defaultEmsConfigSchedule
    const hasOtherSchedules = schedules?.emsConfigSchedules?.length || 0

    if (hasDefaultSchedule || hasOtherSchedules) {
      this.hasSchedulingActive = true
    } else {
      this.hasSchedulingActive = false
      this.afterInit()
    }
  }

  async afterInit() {
    this.setStatus(this.internalLanguage.WAITING_FOR_CONNECTION, 'fe-warn')
    this.io.init(this.facilityId, this.onEmsConfChanged.bind(this), this.onCommand.bind(this), async () => {
      this.setStatus(null, null)
      const data = await this.http.getCurrentEmsConfig(this.facilityId).catch(captureSentryException)
      if (data && data.status === 204) {
        this.error = this.l2.NO_DATA
        this.hasEms = false
      } else if (data && data.data && data.data.emsConfig) {
        this.hasEms = true
        this.onTimestamp(new Date(data.data.emsConfig.header.transTs))
        this.onConfig(data.data.emsConfig.data)
      } else {
        this.error = 'Doesnt have ems 2.0'
        this.hasEms = false
      }
    })
  }

  private setStatus(status: string, statusClass: string): void {
    this.$s.$applyAsync(() => {
      this.error = null
      this.status = status
      this.statusClass = statusClass
    })
  }

  private onTimestamp(ts: Date): void {
    this.$s.$applyAsync(() => {
      this.timestamp = echartsTimeFormatters(this.timezone).seconds(ts)
    })
  }

  private onConfig(emsParams: EmsParam): void {
    this.$s.$applyAsync(() => {
      emsParams.grid.ace.mode = (emsParams.grid.ace.mode === 1) as unknown as 0 | 1
      emsParams.pv.mode = (emsParams.pv.mode === 1) as unknown as 0 | 1
      this.config = emsParams
    })
  }

  private onEmsConfChanged(data: EmsConfigWebsocketEmsConfChangedEvent): void {
    this.onConfig(data.data as EmsParam)
    this.onTimestamp(data.transTs)
    this.setStatus(this.internalLanguage.CONFIGURATION_BEEN_CHANGED, 'fe-primary')
  }

  private onCommand(data: EmsConfigWebsocketCommandEvents) {
    switch (data.eventType) {
      case SystemConfigurationEventTypesEnum.SEND:
        if (data.applicationInstanceId.userId !== Environment.sub) {
          this.amplitude.logEvent('Ems Config Experience Another User Initiated')
          this.setStatus(this.internalLanguage.SOMEONE_ELSE_CHANGED, 'fe-warn')
        } else {
          this.setStatus(this.internalLanguage.SENT_TO_EHUB, 'fe-primary')
        }
        break
      case SystemConfigurationEventTypesEnum.DONE:
        this.setStatus(this.l2.ENERGYHUB_RECEIVED_OPERATION_SETTINGS, 'fe-primary')
        break
      case SystemConfigurationEventTypesEnum.FAILED: {
        this.stopLoading()
        const reason = (data.data as EmsConfNonMeasureDataObject).reason
        if (reason === OTHER_TRANSACTION_ONGOING_STRING) {
          this.setStatus(this.internalLanguage.OTHER_TRANSACTION_ONGOING_STRING, 'fe-warn')
        } else {
          const parsedReason = reasonParser({
            reason,
            language: this.currentLanguage
          })
          this.setStatus(parsedReason, 'fe-error')
        }
        break
      }
      case SystemConfigurationEventTypesEnum.SUCCEEDED:
        this.stopLoading()
        this.setStatus(this.l2.SUCCESS, 'fe-primary')
        if (data.command === 'get') {
          this.onConfig(data.data as EmsParam)
          this.onTimestamp(data.transTs)
        }
        break
      default:
        this.stopLoading()
        this.setStatus(this.l2.ERROR_OCCURRED, 'fe-error')
        captureSentryException(
          new Error(
            `Undefined ems config event Command: ${data.command} EventType: ${
              data.eventType
            },\n Info: ${JSON.stringify(data, null, 4)}`
          )
        )
    }
  }

  wizard(): void {
    this.amplitude.logEvent('Using Battery Wizard On Ems Config')

    this.$mdD
      .show({
        locals: {
          config: this.config
        },
        controller: [
          '$mdDialog',
          'config',
          EmsConfigV2HttpServiceName,
          FerroConfigurationName,
          LanguageServiceName,
          BatteryWizardController
        ],
        templateUrl: batteryWizardTemplate,
        controllerAs: 'vm',
        parent: angular.element(document.body),
        clickOutsideToClose: true
      })
      .then((config: EmsParam) => {
        this.$s.$applyAsync(() => {
          this.config = config
        })
      })
  }

  private stopLoading(): void {
    this.$s.$applyAsync(() => {
      this.loading = false
    })
    if (timer) this.$t.cancel(timer)
  }

  // private activateTimer(): void {
  //     timer = this.$t(() => {
  //         this.error = this.l2.OPERATION_SETTINGS_ERROR.replace('<REASON>', 'TIMED OUT');
  //     }, 45000)
  // }

  async initiateSet(): Promise<void> {
    this.amplitude.logEvent('Set Ems Config')

    if (this.loading) return

    if (!this.form.$valid) {
      this.form.$setSubmitted()
      this.error = this.l.FORM_INVALID
      return
    }

    this.loading = true
    this.error = ''
    const validationResult = emsConfigV2FrontendValidation(this.config, this.l)
    if (validationResult) {
      this.error = validationResult
      this.stopLoading()
      return
    }

    const invalidOptions = (data: IHttpResponse<string>) => {
      data = data || ({} as unknown as IHttpResponse<string>)
      this.stopLoading()
      this.setStatus('Invalid options', 'fe-warn')
      captureSentryException(`Invalid options were sent to backend
                data: ${data.data}
                status: ${data.status}
                statusText: ${data.statusText}
            `)
    }

    const data = await this.http
      .initiateSetEmsConfig(this.facilityId, this.config)
      .catch((e: IHttpResponse<string>) => {
        this.amplitude.logEvent('EmsConfigStartingSetFailed')

        if (e.status === 405) {
          this.stopLoading()
          this.setStatus('Not allowed', 'fe-warn')
        } else if (e.status === 400) {
          invalidOptions(e)
        } else {
          captureSentryException(new Error(JSON.stringify(e)))
          this.stopLoading()
          this.setStatus(this.l2.ERROR_OCCURRED, 'fe-error')
        }
      })

    if (data && data.status === 201) {
      this.setStatus('Waiting for response from energyhub', 'fe-accent')
    } else if (data && data.status !== 500) {
      invalidOptions(data)
    } else {
      this.setStatus(this.l2.ERROR_OCCURRED, 'fe-warn')
      captureSentryException(new Error(`Unknown Response from emsconfig ${JSON.stringify(data)}`))
    }
  }

  async initiateGet(): Promise<void> {
    this.amplitude.logEvent('Get Ems Config')

    if (this.loading) return
    this.loading = true
    const data = await this.http.initateGetEmsConfig(this.facilityId).catch((e: IHttpResponse<string>) => {
      this.amplitude.logEvent('EmsConfigStartingGetSequenceFailed')

      if (e.status === 405) {
        this.setStatus('Not allowed', 'fe-warn')
      } else {
        captureSentryException(new Error(JSON.stringify(e)))
        this.setStatus(this.l2.ERROR_OCCURRED, 'fe-error')
      }
    })
    if (data && data.status === 201) {
      this.setStatus('Waiting for response from energyhub', 'fe-accent')
    } else {
      this.setStatus('Unknown result', 'fe-warn')
      captureSentryException(
        new Error(
          `Unknown Response from emsconfig; Status: ${
            data ? data.status : ''
          }, Message: ${data ? data.data : ''} `
        )
      )
    }
  }
}

EmsConfigV2Controller.$inject = [
  FerroConfigurationName,
  EmsConfigV2HttpServiceName,
  EmsConfigV2IoServiceName,
  '$scope',
  '$mdDialog',
  '$timeout',
  LanguageServiceName,
  AmplitudeServiceName,
  RolesServicesName,
  SystemConfigurationGraphqlServiceName,
  '$state'
]

export const EmsConfigV2Component: IComponentOptions = {
  controller: EmsConfigV2Controller,
  templateUrl,
  bindings: {
    loading: '='
  }
}
