import { IPromise } from 'angular'
import { DEFAULT_TIMEZONE } from '../../../../environments/environment'

import { SSOAveragePower } from '../../../../types/sso'

function sortPvStrings(a: SSOAveragePower, b: SSOAveragePower): number {
  return Number(a.id) - Number(b.id)
}

export type processedAveragePowerSsoDataFormat = (Date | number)[][]
export type processedAveragePowerSsoData = {
  labels: string[]
  data: processedAveragePowerSsoDataFormat
  maxVal: number
}
export const SsoViewServiceName = 'ssoViewService'

export class SsoViewService {
  facilityId: number
  timezone: string
  canceller: IPromise<unknown>
  constructor() {}
  init(facilityId: number, timezone: string, canceller: IPromise<unknown>): void {
    this.facilityId = facilityId
    this.timezone = timezone ? timezone : DEFAULT_TIMEZONE
    this.canceller = canceller
  }

  fixPvStringData(data: SSOAveragePower[]): processedAveragePowerSsoData {
    data = data.sort(sortPvStrings)
    const dataArray = []
    const numberOfSSos = data.length

    if (!data.length) {
      return {
        labels: [],
        data: null,
        maxVal: 0
      }
    }

    const length = data[0].power.length

    let maxValue = 0

    for (let j = 0; j < length; j++) {
      const arr: (Date | number)[] = [new Date(data[0].power[j].ts)]
      let p = 0
      for (let i = 0; i < numberOfSSos; i++) {
        let p2 = null
        if (data[i]) {
          if (data[i].power[j]) {
            p2 = data[i].power[j].p / 1000
          }
        }
        if (p2 > maxValue) maxValue = p2
        p = p + p2
        arr.push(p2)
      }
      arr.push(p)
      dataArray.push(arr)
    }

    const labels = ['Date']

    for (let k = 0; k < numberOfSSos; k++) {
      labels.push(data[k].id)
    }
    labels.push('Total Power')

    return {
      labels: labels,
      data: dataArray,
      maxVal: maxValue
    }
  }
}
