import { copy } from 'angular'
import {
  DefaultEmsConfigSchedule,
  DefaultEmsConfigScheduleInput,
  EmsConfig,
  EmsConfigInput,
  EmsConfigSchedule,
  EmsConfigScheduleInput
} from '@app/graphql/generated'
import { EmsParam } from '@ferroamp/system-configuration-lib'
import { omitTypename, removeNullAndUndefined } from '..'
import { EmsConfigScheduleParsed } from '@app/system/settings/system-configuration/ems-config-scheduler/ems-config-schedule.component'

/**
 *  In the ui we use md-switch etc which uses boolean instead of integers.
 *  The emsParams has a few stuff that want integers instead of boolean, we fix this here.
 */
export const emsConfigFormToEmsParam = (
  emsParams: EmsConfig | EmsParam | EmsConfigInput
): EmsConfig | EmsParam | EmsConfigInput => {
  const config = copy(emsParams)
  config.grid.ace.mode = Number(config.grid.ace.mode) as 0 | 1
  config.pv.mode = Number(config.pv.mode) as 0 | 1
  return config
}

export const prepareEmsConfigScheduleForMutation = (
  inSmsSchedule:
    | DefaultEmsConfigSchedule
    | DefaultEmsConfigScheduleInput
    | EmsConfigSchedule
    | EmsConfigScheduleInput
    | EmsConfigScheduleParsed,
  isDefault?: boolean
): DefaultEmsConfigScheduleInput | EmsConfigScheduleInput => {
  let emsSchedule: EmsConfigScheduleInput = copy(inSmsSchedule) as EmsConfigScheduleInput
  emsSchedule.emsParam = emsConfigFormToEmsParam(emsSchedule.emsParam) as EmsConfig
  emsSchedule = omitTypename(emsSchedule) as EmsConfigScheduleInput
  emsSchedule = removeNullAndUndefined(emsSchedule) as EmsConfigScheduleInput

  const emsConfigInput: Partial<EmsConfigScheduleInput> = {
    emsParam: emsSchedule.emsParam
  }

  if (!isDefault) {
    emsConfigInput.iCalendarEvent = emsSchedule.iCalendarEvent
  }

  return emsConfigInput as EmsConfigScheduleInput | DefaultEmsConfigScheduleInput
}
