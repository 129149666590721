import { captureSentryException } from '@app/config/sentry'
import { FeatureService, FeatureServiceName } from '@app/graphql/features.service'
import { FacilityFeatures } from '@app/graphql/generated'
import { LanguageSystemSettings } from '@app/language/language.interface'
import { FerroConfiguration, FerroConfigurationName } from '@app/service'
import { IComponentOptions } from 'angular'
import './system-configration.component.scss'
import templateUrl from './system-configuration.component.html'
class Controller {
  l: LanguageSystemSettings
  loading: boolean
  isSystemRunning = true
  hasSystemTopologyConfiguration = false
  onSystemRunning: (running: boolean) => void
  constructor(
    private ferroConfiguration: FerroConfiguration,
    private osVersionFeatuers: FeatureService
  ) {
    this.l = ferroConfiguration.language.SystemSettings
    this.onSystemRunning = (running: boolean) => {
      // eslint-disable-next-line
      this.isSystemRunning = running
    }
  }

  async $onInit() {
    const features = await this.osVersionFeatuers
      .getFeatures(Number(this.ferroConfiguration.facility.id))
      .catch(captureSentryException)
    if (features && features.length) {
      this.hasSystemTopologyConfiguration = !!features.includes(FacilityFeatures.SystemControlTopology)
    }
  }
}

Controller.$inject = [FerroConfigurationName, FeatureServiceName]

export const SystemConfigurationComponentName = 'systemConfiguration'

export const SystemConfigurationComponent: IComponentOptions = {
  templateUrl,
  controller: Controller
}
