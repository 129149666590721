import { LanguageSystemSettings, LanguageViews } from '@app/language/language.interface'
import { FerroConfiguration, FerroConfigurationName } from '@app/service'
import { LanguageService, LanguageServiceName } from '@app/service/language.service'
import { IComponentOptions } from 'angular'
import templateUrl from './export-data.component.html'
import enImage from './export_en.png'
import seImage from './export_se.png'
import './export-data.component.scss'
import { StateService } from '@uirouter/core'
import { SystemStates } from '../../system-routing.config'

const se = [
  'Energiräknarexport finns nu under',
  `För att exportera, välj tidsrymd och upplösning, 
och klicka sedan på CSV-export-knappen i övre högra hörnet.`
]

const en = [
  'Export of energy data has been moved to the',
  `To export, select the desired timeframe and resolution, 
then click the export to CSV button at the top right corner.`
]

class ExportDataController {
  text: string[]
  l: LanguageSystemSettings
  l2: LanguageViews
  $state: StateService
  imgSource: string
  constructor(languageService: LanguageService, ferro: FerroConfiguration, $state: StateService) {
    this.text = languageService.language == 'se' ? se : en
    this.l = ferro.language.SystemSettings
    this.l2 = ferro.language.Views
    this.$state = $state
    this.imgSource = languageService.language == 'se' ? seImage : enImage
  }

  goto() {
    this.$state.go(SystemStates.energyView)
  }
}

ExportDataController.$inject = [LanguageServiceName, FerroConfigurationName, '$state']

export const ExportDataComponentName = 'exportDataComponent'

export const ExportDataComponent: IComponentOptions = {
  controller: ExportDataController,
  templateUrl: templateUrl
}
