import { IScope, material } from 'angular'
import moment from 'moment-timezone'
import { LanguageAnalaysis } from '../../../language/language.interface'
import { ElectricalComponentColors, FerroConfiguration } from '../../../service'
import { GraphDialogControllerTemplate } from '../graph-popup-dialog-template/graph-dialog.controller.template'
import { UidataGridRmsCurrentQuery } from '../../../../types'
import { EChartOption, ECharts } from 'echarts'
import {
  clearEchartsInstance,
  defaultEchartsDataZoom,
  echartsToolbox,
  echartsTooltip,
  echartsTimeFormatters,
  tooltipDateFormatter,
  echartsInitWrapper
} from '../../../../lib'
import { UidataGraphqlService } from '../../../graphql/uidata-graphql.service'
import { GRAPHQL_ERRORS } from '../../../graphql/errors'

type DataFormat = [Date, number, number, number][]

export class DailyTrendDialogController extends GraphDialogControllerTemplate {
  l: LanguageAnalaysis
  facilityId: number
  data: UidataGraphqlService
  private graph: ECharts
  private colors: ElectricalComponentColors

  graphThreeExists = false
  graphTwoExists = false

  timezone: string
  timestamp: Date

  constructor(
    $mdDialog: material.IDialogService,
    uidataGraphqlService: UidataGraphqlService,
    ferroConfiguration: FerroConfiguration,
    $scope: IScope,
    timestamp: Date
  ) {
    super($mdDialog, $scope)
    this.data = uidataGraphqlService
    this.timestamp = timestamp
    this.timezone = ferroConfiguration.facility.timezone
    this.colors = ferroConfiguration.electricColors
    this.l = ferroConfiguration.language.SystemAnalysis
    this.facilityId = Number(ferroConfiguration.facility.id)
    this.dialogTitle = `${moment(timestamp).add(1, 'hour').format('YYYY-MM-DD HH:mm')}    +- 60 min`
    this.graphTitle1 = this.l.TOTAL_GRID_CURRENT
  }

  $onInit(): void {
    this.getData(this.timestamp)
  }

  $onDestroy(): void {
    clearEchartsInstance(this.graph)
  }

  cancel(): void {
    this.dialog.cancel()
    this.destroy()
  }

  private destroy(): void {
    clearEchartsInstance(this.graph)
  }

  private async doTheGraph(eq: DataFormat): Promise<void> {
    const tooltip = echartsTooltip()

    tooltip.formatter = tooltipDateFormatter('seconds', this.timezone)

    this.graph = await echartsInitWrapper(this.graphTwoId)
    const ops: EChartOption = {
      legend: {
        data: ['L1', 'L2', 'L3']
      },

      toolbox: echartsToolbox(),
      tooltip: tooltip,
      dataZoom: defaultEchartsDataZoom,
      dataset: {
        source: eq,
        dimensions: ['ts', '1', '2', '3']
      },

      yAxis: {
        type: 'value',
        name: `Arms`,
        axisLabel: { formatter: '{value} Arms' }
      },
      xAxis: {
        type: 'time',
        name: this.l.DATE_AND_TIME,
        axisLabel: {
          formatter: echartsTimeFormatters(this.timezone).seconds
        }
      },
      series: [
        {
          type: 'line',
          name: 'L1',
          itemStyle: {
            color: this.colors.phase1.line
          },
          encode: {
            x: 'ts',
            y: '1'
          }
        },
        {
          type: 'line',
          name: 'L2',
          itemStyle: {
            color: this.colors.phase2.line
          },
          encode: {
            x: 'ts',
            y: '2'
          }
        },
        {
          type: 'line',
          name: 'L3',
          itemStyle: {
            color: this.colors.phase3.line
          },
          encode: {
            x: 'ts',
            y: '3'
          }
        }
      ]
    }
    this.graph.setOption(ops)
    this.stopLoading()
  }

  private getData(timestamp: Date): void {
    this.data
      .getQueryData(timestamp, moment(timestamp).add(2, 'hours').toDate(), 'er', this.facilityId)
      .then(data => {
        let lastts: Date
        let ts
        const eq: DataFormat = []
        ;(data as UidataGridRmsCurrentQuery[]).forEach(d => {
          if (d.timestamp) {
            ts = new Date(d.timestamp)
            if (ts !== lastts) {
              eq.push([ts, d.iExtRms1, d.iExtRms2, d.iExtRms3])
              lastts = ts
            }
          }
        })
        this.doTheGraph(eq)
      })
      .catch(error => {
        if (error.message === GRAPHQL_ERRORS.NO_DATA) this.error = this.l.NO_DATA_FOUND
        else this.error = this.l.ERROR_OCCURRED
      })
  }
}
