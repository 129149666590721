import { IComponentOptions, ITranscludeFunction } from 'angular'

import './view-box.component.scss'

import templateUrl from './view-box.component.html'

export interface ViewBoxNextPreviousOptions {
  enabled: boolean
  onPrevious: () => void
  onNext: () => void
  showPrevious: boolean
  showNext: boolean
  nextLabel: string
  previousLabel: string
}

class ViewBoxController {
  loading: boolean
  loadingExists = false
  feTitle: string
  actions: boolean
  $transclude: ITranscludeFunction
  dateChanger: ViewBoxNextPreviousOptions
  initChart: () => void

  constructor($transclude: ITranscludeFunction) {
    this.$transclude = $transclude
  }

  $onInit(): void {
    this.actions = this.$transclude.isSlotFilled('actions')
    if (this.initChart) this.initChart()
    if (this.loading !== undefined) this.loadingExists = true
  }

  // $onChanges(): void {
  //     if (this.loading !== undefined) this.loadingExists = true;
  // }
}

ViewBoxController.$inject = ['$transclude']

export const ViewBoxComponent: IComponentOptions = {
  controller: ViewBoxController,
  templateUrl,
  bindings: {
    feTitle: '<',
    chartId: '@',
    feLoading: '<',
    errorMsg: '<',
    feClass: ' @',
    dateChanger: '<',
    initChart: '&'
  },
  transclude: {
    content: '?viewBoxContent',
    toolbar: '?viewBoxToolbar',
    actions: '?viewBoxActions'
  }
}
