import { ApolloQueryResult } from '@apollo/client'
import { runQuery } from '../graphql/graphql'
import moment from 'moment-timezone'
import { FacilityListInfoQuery } from '@app/graphql/generated'

export const getFacilityInfo = (
  facilityId: number | string
): Promise<ApolloQueryResult<FacilityListInfoQuery>> => {
  const q = {
    gte: moment().add('-20', 'minutes').toDate(),
    lte: new Date()
  }

  return runQuery.facilityListInfoQuery({
    variables: {
      facilityId: +facilityId,
      ...q
    }
  })
}
