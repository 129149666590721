import moment from 'moment-timezone'

import { QueryOptions } from '@apollo/client'
import { GetAggregatedPowerdataQuery, GetAggregatedPowerdataQueryVariables } from './generated'

export function splitQueryIntoDailyChunks(
  starttime: Date,
  endtime: Date,
  facilityId: number
): Partial<QueryOptions<GetAggregatedPowerdataQueryVariables, GetAggregatedPowerdataQuery>>[] {
  const starttimeUTC = moment.tz(starttime, 'UTC')
  const endtimeUTC = moment.tz(endtime, 'UTC')
  const timer = moment.tz(starttime, 'UTC').startOf('day')

  const createQuer = (
    _starttime: Date,
    _endtime: Date
  ): Partial<QueryOptions<GetAggregatedPowerdataQueryVariables, GetAggregatedPowerdataQuery>> => {
    return {
      variables: {
        facilityId: facilityId,
        starttime: _starttime,
        endtime: _endtime
      },
      errorPolicy: 'all'
    }
  }

  const dates = []

  while (timer.isSameOrBefore(endtimeUTC.clone().startOf('day'))) {
    dates.push(timer.clone())
    timer.add(1, 'day')
  }

  if (dates.length == 1) {
    return [createQuer(starttimeUTC.toDate(), endtimeUTC.toDate())]
  }

  return dates.map((date, i) => {
    if (i == 0) {
      return createQuer(starttimeUTC.toDate(), date.endOf('day').toDate())
    }

    if (i == dates.length - 1) {
      return createQuer(date.startOf('day').toDate(), endtimeUTC.toDate())
    }

    return createQuer(date.startOf('day').toDate(), date.endOf('day').toDate())
  })
}

type DatetimeRange = {
  start: Date
  end: Date
}

export function splitDatetime(start: Date, end: Date, bucketSizeInSeconds = 3600): DatetimeRange[] {
  const epochStart = Math.floor(start.getTime() / 1000)
  const epochEnd = Math.ceil(end.getTime() / 1000)
  const diff = epochEnd - epochStart
  const chunks = Math.ceil(diff / bucketSizeInSeconds)
  const timeChunks: DatetimeRange[] = []

  for (let i = 0; i < chunks; i++) {
    const startTime = new Date((epochStart + i * bucketSizeInSeconds) * 1000)
    const endTime = new Date((epochStart + bucketSizeInSeconds + i * bucketSizeInSeconds) * 1000)

    timeChunks.push({
      start: startTime,
      end: endTime > end ? end : endTime
    })
  }

  return timeChunks
}
