import { SocketioService } from './socketio.service'
import { Environment } from '../../environments/environment'
import { ILocationService } from 'angular'
import { SystemStateCommandIoEvent, SystemStateStateChangedIoEvent } from './system-state-v2-http.service'

export const SystemStateV2IoServiceName = 'systemStateV2IoService'

const options = Environment.systemStateV2Options
export type SystemStateIoObserverMethod = (state: SystemStateStateChangedIoEvent) => void
export type SystemStateIoCommandObserver = (state: SystemStateCommandIoEvent) => void

export class SystemStateV2IoService {
  private service: SocketioService
  private facilityId: number

  constructor($location: ILocationService) {
    this.service = new SocketioService($location)
  }

  init(
    facilityId: number,
    observer: SystemStateIoObserverMethod,
    commandObserver: SystemStateIoCommandObserver
  ): void {
    this.facilityId = facilityId
    this.service.connect(options.wsNamespace, options.wsPath, {
      facilityId: facilityId
    })
    this.service.listen(options.wsEvent.command, commandObserver)
    this.service.listen(options.wsEvent.statechanged, observer)
  }

  disconnect(): void {
    this.facilityId = null
    this.service.disconnect()
  }
}

SystemStateV2IoService.$inject = ['$location']
