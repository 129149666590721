import { IHttpResponse, IHttpService } from 'angular'
import { Environment } from '../../environments/environment'
import { serviceClb } from './types.service'
import {
  FacilityTopologyHttpV1ServiceGetOneDataResponse,
  TopologyV1GetManyResponseInterface,
  TopologyV1GetTopologyResponseInterface,
  TopologyV1GetTotalsResponseInterface,
  TopologyV1UpdateUnitsInterface
} from '../../types/topology'

const topologyV1Endpont = Environment.endpoints.topologyV1

export interface FacilityTopologyHttpV1ServiceRemoveUnitRequest {
  unit_id: string
  facility_id: number
  type: 'ssos' | 'esos'
}

export const FacilityTopologyHttpV1ServiceName = 'facilityTopologyHttpV1Service'

export class FacilityTopologyHttpV1Service {
  private $http: IHttpService

  constructor($http: IHttpService) {
    this.$http = $http
  }

  /**
   *  @TODO this response returns TOO much data for the SystemList, We need another endpoints for systemList DAta
   */
  getData(
    facilityId: number,
    done: serviceClb<Error, FacilityTopologyHttpV1ServiceGetOneDataResponse>
  ): void {
    this.$http<FacilityTopologyHttpV1ServiceGetOneDataResponse>({
      method: 'GET',
      url: `${topologyV1Endpont}/get?facility_id=${facilityId}`
    }).then(
      success => {
        done(null, success.data)
      },
      error => {
        done(error.data, null)
      }
    )
  }

  update(data: TopologyV1UpdateUnitsInterface, done: serviceClb<Error, string>): void {
    this.$http<string>({
      method: 'POST',
      url: `${topologyV1Endpont}/update`,
      data: data
    }).then(
      success => {
        done(null, success.data)
      },
      error => {
        done(error.data, null)
      }
    )
  }

  removeUnit(
    unitInfo: FacilityTopologyHttpV1ServiceRemoveUnitRequest,
    callback: serviceClb<string, IHttpResponse<string>>
  ): void {
    this.$http<string>({
      method: 'POST',
      url: `${topologyV1Endpont}/remove_unit`,
      data: unitInfo
    }).then(
      success => {
        callback(null, success)
      },
      error => {
        callback(error.data, null)
      }
    )
  }

  getTopology(
    facilityId: number,
    callback: serviceClb<string, TopologyV1GetTopologyResponseInterface>
  ): void {
    this.$http<TopologyV1GetTopologyResponseInterface>({
      method: 'GET',
      url: `${topologyV1Endpont}/get_topology?f=${facilityId}`
    }).then(
      success => {
        callback(null, success.data)
      },
      error => {
        callback(error.data, null)
      }
    )
  }

  getMany(
    fids: number[],
    callback: serviceClb<IHttpResponse<string>, TopologyV1GetManyResponseInterface>
  ): void {
    this.$http<TopologyV1GetManyResponseInterface>({
      method: 'POST',
      url: `${topologyV1Endpont}/get_many`,
      data: {
        fids: fids
      }
    }).then(
      response => {
        callback(null, response.data)
      },
      response => {
        callback(response, null)
      }
    )
  }

  getTotals(
    fids: number[],
    callback: serviceClb<IHttpResponse<string>, TopologyV1GetTotalsResponseInterface>
  ): void {
    this.$http<TopologyV1GetTotalsResponseInterface>({
      method: 'POST',
      url: `${topologyV1Endpont}/get_totals`,
      data: {
        fids: fids
      }
    }).then(
      response => {
        callback(null, response.data)
      },
      response => {
        callback(response, null)
      }
    )
  }
}

FacilityTopologyHttpV1Service.$inject = ['$http']
