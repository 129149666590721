import { IWindowService } from 'angular'
import { Environment } from '../../environments/environment'
import { EnglishLanguage } from '../language/english'
import { SwedishLanguage } from '../language/swedish'
import { FerroConfiguration } from './ferro-configuration.service'

export type availableLanguage = { val: string; l: string; icon: string }[]

export type languagesAvailable = 'se' | 'us'

export const LanguageServiceName = 'languageService'

export class LanguageService {
  browserLanguage = navigator.language // || navigator.userLanguage;
  language: languagesAvailable = Environment.language
  availableLanguages = [
    {
      val: 'se',
      l: 'SE',
      // Icon should match ferroimage flagIconMapping
      icon: 'se'
    },
    {
      val: 'us',
      l: 'US',
      // Icon should match ferroimage flagIconMapping
      icon: 'us'
    }
  ]

  private $window: IWindowService
  private ferroConfiguration: FerroConfiguration

  constructor($window: IWindowService, ferroConfiguration: FerroConfiguration) {
    this.$window = $window
    this.ferroConfiguration = ferroConfiguration
    if (!this.language) {
      this.useBrowserLanguage()
    } else {
      if (!this.language) {
        this.useBrowserLanguage()
      } else if (this.language === 'se') {
        this.isSwedish()
      } else if (this.language === 'us') {
        this.isEnglish()
      } else {
        this.useBrowserLanguage()
      }
    }
  }

  storeLanguage(_lang: languagesAvailable, _done: () => void): void {}

  /**
   * @function ChangeLanguage
   * @param {String} lang options : swe | eng
   */
  ChangeLanguage(lang: languagesAvailable): void {
    if (lang === 'se' && this.ferroConfiguration.nowLang !== 'se') {
      this.storeLanguage('se', () => {
        this.$window.location.reload()
      })
    } else if (lang === 'us' && this.ferroConfiguration.nowLang !== 'us') {
      this.storeLanguage('us', () => {
        this.$window.location.reload()
      })
    }
  }

  isSwedish(): void {
    this.ferroConfiguration.setLanguage(SwedishLanguage)
    this.language = 'se'
  }

  isEnglish(): void {
    this.language = 'us'
    this.ferroConfiguration.setLanguage(EnglishLanguage)
  }

  useBrowserLanguage(): void {
    if (this.browserLanguage === 'sv') this.isSwedish()
    else this.isEnglish()
  }
}

LanguageService.$inject = ['$window', 'ferroConfiguration']
