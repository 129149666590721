import angular, { IComponentOptions, IDeferred, IQService, IScope } from 'angular'
import { ECharts } from 'echarts'
import moment from 'moment-timezone'
import { clearEchartsInstance, roundTo2Decimals, zeropad } from '../../../../lib'
import { LanguageAnalaysis } from '../../../language/language.interface'
import {
  AmplitudeService,
  ElectricalComponentColors,
  FeatureFlagService,
  FeatureFlagServiceName,
  FerroConfiguration,
  FerroConfigurationName
} from '../../../service'
import { graphDialogTemplateUrl } from '../graph-popup-dialog-template/graph-dialog.controller.template'
import { DailyTrendDialogController } from './daily-trend-dialog.controller'
import {
  createDailyTrendChart,
  createPerPhaseChart,
  createPowerLevelsChart,
  updateDailyTrendChart,
  updatePhaseChart,
  updatePowerLevelChart
} from './daily-trend.charts'
import {
  dailyTrendDataVizualizationType,
  DailyTrendService,
  DailyTrendServiceName
} from './daily-trend.service'
import { DailyTrendFixedData, MaximumDataArraysObject, PowerLevelData } from './daily-trend.tools'

import { AmplitudeServiceName } from '@app/service/amplitude.service'
import { parseHourDate } from '../../../filters/parse-hour-date.filter'
import {
  GraphqlPowerDataRetentionsService,
  GraphqlPowerDataRetentionsServiceName
} from '../../../graphql/retention.queries'
import { UidataGraphqlServiceName } from '../../../graphql/uidata-graphql.service'
import templateUrl from './daily-trend.component.html'

// type DataFormat = number[][];

class DailyTrendController {
  loading = true
  l: LanguageAnalaysis

  dialog: angular.material.IDialogService
  colors: ElectricalComponentColors
  phaseBarColors: string[]

  starttime: Date
  endtime: Date

  minDate: Date
  maxDate: Date

  aicA: MaximumDataArraysObject[]
  aic1A: MaximumDataArraysObject[]
  aic2A: MaximumDataArraysObject[]
  aic3A: MaximumDataArraysObject[]

  maxMinSTD: dailyTrendDataVizualizationType = 'maxmin'

  showWE: boolean
  showWD: boolean
  error = ''

  PhaseWeekEndGraph: ECharts
  PhaseWeekdaysGraph: ECharts // = new FerroGraph<DataFormat>('PhaseWeekdaysGraph');
  dailyTrendGraph: ECharts // = new FerroGraph<DataFormat>('analyticsGraphArea');
  graphOfPowerCount: ECharts // = new FerroGraph<DataFormat>('graphOfPowerCount');

  service: DailyTrendService
  canceller: IDeferred<unknown>

  minSecondResolutionData: Date
  fc: FerroConfiguration
  features: FeatureFlagService
  amplitude: AmplitudeService

  activeDecision = false
  asAmpere: boolean = true

  constructor(
    $scope: IScope,
    ferroConfiguration: FerroConfiguration,
    dailyTrendService: DailyTrendService,
    $q: IQService,
    $mdDialog: angular.material.IDialogService,
    graphqlPowerDataRetentionsService: GraphqlPowerDataRetentionsService,
    features: FeatureFlagService,
    amplitude: AmplitudeService
  ) {
    this.amplitude = amplitude
    this.features = features
    this.l = ferroConfiguration.language.SystemAnalysis
    this.dialog = $mdDialog
    this.fc = ferroConfiguration
    this.canceller = $q.defer()
    const TIMEZONE = ferroConfiguration.facility.timezone || 'Europe/Stockholm'
    this.phaseBarColors = ferroConfiguration.phaseBarColors
    this.service = dailyTrendService
    this.minSecondResolutionData = graphqlPowerDataRetentionsService.getUiData1SecondMinDate()
    // Initiate dailyTrendService.
    dailyTrendService.init(
      this.canceller.promise,
      Number(ferroConfiguration.facility.id),
      ferroConfiguration.facility.timezone,
      this.maxMinSTD
    )

    this.colors = ferroConfiguration.electricColors
    this.aicA = []
    this.aic1A = []
    this.aic2A = []
    this.aic3A = []
    this.showWE = true
    this.showWD = true

    this.maxDate = ferroConfiguration.getMaxDate()
    this.minDate = ferroConfiguration.getMinDate()

    function fixDate(date: Date, end?: boolean) {
      const d = moment.tz(date, TIMEZONE)
      return end ? d.endOf('day').toDate() : d.startOf('day').toDate()
    }

    let _starttime = new Date(new Date().setDate(-30))
    _starttime = this.minDate.getTime() > _starttime.getTime() ? this.minDate : _starttime
    this.starttime = fixDate(_starttime) // start 1 week ago;
    this.endtime = fixDate(new Date(), true)
  }

  $onInit(): void {
    this.initiate(true)
  }

  $onDestroy(): void {
    clearEchartsInstance(this.PhaseWeekEndGraph)
    this.PhaseWeekEndGraph = null
    clearEchartsInstance(this.PhaseWeekdaysGraph)
    this.PhaseWeekdaysGraph = null
    clearEchartsInstance(this.dailyTrendGraph)
    this.dailyTrendGraph = null
    clearEchartsInstance(this.graphOfPowerCount)
    this.graphOfPowerCount = null
    this.canceller.resolve()
  }

  maxMinStdChoice(): void {
    this.loading = true
    this.service.stdOrMaxMin(this.maxMinSTD, data => {
      this.createGraphs(data)
      this.loading = false
    })
  }

  initiate(first?: boolean): void {
    this.error = ''
    if (!first) {
      this.activeDecision = true
    }

    const start = new Date(this.starttime).getTime()
    const end = new Date(this.endtime).getTime()

    const daysFromToday = moment().endOf('day').diff(start, 'days')

    this.amplitude.logEvent('Get Daily Trend', {
      activeDecision: this.activeDecision,
      daysFromToday
    })

    if (end <= start) {
      this.error = this.l.STARTTIME_NOT_GREATER_ENDTIME
      return
    }

    this.loading = true

    this.service.getEnergyData(this.starttime, this.endtime, (error, data) => {
      if (error) {
        const status = error.status
        if (status === 204 || status === 404) {
          this.error = this.l.NO_DATA_FOUND
        } else {
          this.error = this.l.ERROR_OCCURRED
        }
      } else if (!data) {
        this.error = this.l.NO_DATA_FOUND
      } else {
        this.createGraphs(data)
        const mD = data.maxData
        this.aicA = mD.aicA
        this.aic1A = mD.aic1A
        this.aic2A = mD.aic2A
        this.aic3A = mD.aic3A
        this.renderPowerLevels(data.powerLevels)
        this.loading = false
      }
    })
  }

  async updatePhaseGraphs(arr: DailyTrendFixedData) {
    if (!this.PhaseWeekdaysGraph) {
      this.PhaseWeekdaysGraph = await createPerPhaseChart(
        'PhaseWeekdaysGraph',
        arr.weekDayPerPhase,
        this.l,
        this.colors,
        this.asAmpere
      )
    } else {
      updatePhaseChart(this.PhaseWeekdaysGraph, arr.weekDayPerPhase, this.l, this.asAmpere)
    }

    if (!this.PhaseWeekEndGraph) {
      this.PhaseWeekEndGraph = await createPerPhaseChart(
        'PhaseWeekEndGraph',
        arr.weekEndPerPhase,
        this.l,
        this.colors,
        this.asAmpere
      )
    } else {
      updatePhaseChart(this.PhaseWeekEndGraph, arr.weekEndPerPhase, this.l, this.asAmpere)
    }
  }

  async createGraphs(arr: DailyTrendFixedData): Promise<void> {
    if (!this.dailyTrendGraph) {
      this.dailyTrendGraph = await createDailyTrendChart(arr, this.l, this.colors, this.maxMinSTD)
    } else {
      updateDailyTrendChart(this.dailyTrendGraph, arr, this.maxMinSTD, this.l)
    }

    this.updatePhaseGraphs(arr)
  }

  async renderPowerLevels(data: PowerLevelData): Promise<void> {
    if (!this.graphOfPowerCount) {
      this.graphOfPowerCount = await createPowerLevelsChart('graphOfPowerCount', data, this.l, this.colors)
    } else {
      updatePowerLevelChart(this.graphOfPowerCount, data)
    }
  }

  fixVal(value: number): number {
    return roundTo2Decimals(value)
  }

  fixTS(time: Date): string {
    return `${new Date(time).toLocaleDateString()} ${zeropad(new Date(time).getHours())}:00`
  }

  popup(ev: MouseEvent, timestamp: Date): void {
    const daysFromToday = moment().endOf('day').diff(timestamp, 'days')

    this.amplitude.logEvent('Daily Trend Pop Up Clicked', {
      daysFromToday,
      activeDecision: this.activeDecision
    })
    if (!this.features.isHistoricalPerSecondDataEnabled()) {
      return
    }

    if (timestamp && timestamp < this.minSecondResolutionData) {
      this.fc.alert(
        this.l.NO_DATA_FOUND,
        `${this.l.NO_AVAILABLE_DATA_BEFORE_DATE} ${parseHourDate(this.fc)(this.minSecondResolutionData)}`
      )
      return
    }

    this.dialog.show({
      locals: {
        timestamp: timestamp
      },
      controller: [
        '$mdDialog',
        UidataGraphqlServiceName,
        FerroConfigurationName,
        '$scope',
        'timestamp',
        DailyTrendDialogController
      ],
      templateUrl: graphDialogTemplateUrl,
      parent: angular.element(document.body),
      targetEvent: ev,
      clickOutsideToClose: true,
      controllerAs: 'vm'
    })
  }
}

DailyTrendController.$inject = [
  '$scope',
  FerroConfigurationName,
  DailyTrendServiceName,
  '$q',
  '$mdDialog',
  GraphqlPowerDataRetentionsServiceName,
  FeatureFlagServiceName,
  AmplitudeServiceName
]

export const DailyTrendCompnentName = 'dailyTrendComponent'

export const DailyTrendComponent: IComponentOptions = {
  templateUrl: templateUrl,
  controller: DailyTrendController
}
