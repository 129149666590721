import { LanguageAlarms } from '@app/language/language.interface'
import { FerroConfiguration, FerroConfigurationName } from '@app/service'
import { IComponentOptions, IScope, material } from 'angular'
import { AlarmCodeRow, toSubscribableAlarmCode, translateCode, groupAlarmRows } from './alarms.lib'
import templateUrl from './alarms-table.component.html'
import { Alarm, AlarmCode, AlarmEntry } from '@app/graphql/generated'
import { SubscriptionView } from './alarms-add-user.component'
import { getAlarms } from './alarm-data'
import { AlarmDetails } from './alarm-details.component'

class AlarmsTableController {
  alarmRows: AlarmCodeRow[]
  l: LanguageAlarms
  translateCode = translateCode

  loading = true
  $mdDialog: material.IDialogService
  constructor(ferroConfiguration: FerroConfiguration, $scope: IScope, $mdDialog: material.IDialogService) {
    this.$mdDialog = $mdDialog
    this.l = ferroConfiguration.language.Alarms
    getAlarms(Number(ferroConfiguration.facility.id))
      .then(query => {
        $scope.$applyAsync(() => {
          this.alarmRows = groupAlarmRows(query.data.facility.alarm.entries as AlarmEntry[])
          this.loading = false
        })
      })
      .catch(console.error)
  }

  showWhoToNotify(ev: any, subscribeToAlarmCode: AlarmCode) {
    SubscriptionView(ev, this.$mdDialog, toSubscribableAlarmCode(subscribeToAlarmCode))
  }

  showDetails($event: any, alarmCode: AlarmCode, children: Alarm[]) {
    AlarmDetails($event, this.$mdDialog, alarmCode, children)
  }

  supportLink(alarmCode: Exclude<AlarmCode, AlarmCode.All>) {
    return this.l.SUPPORT_LINKS[alarmCode]
  }
}

AlarmsTableController.$inject = [FerroConfigurationName, '$scope', '$mdDialog']

export const AlarmsTableComponentName = 'alarmsTable'
export const AlarmsTableComponent: IComponentOptions = {
  controller: AlarmsTableController,
  templateUrl
}
