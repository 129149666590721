import { xml } from 'd3-fetch'
import { select } from 'd3-selection'
import { Selected } from '../schematic.tools'

import solarPowershareSchematicUrl from './powershare-solar-schematic.svg'

function genPowerText(pvValue: number) {
  const value = Math.abs(pvValue)
  if (value < 10) {
    return `${Math.round(value * 10) / 10} kW`
  }
  return `${Math.round(value)} kW`
}

export class PowershareSolarSchematic {
  private id: string
  private url = solarPowershareSchematicUrl
  private isReady = false
  private svg: Selected

  private pvTitle: Selected
  private pvConnector: Selected
  private pvPower: Selected
  private dot: Selected

  constructor(id: string) {
    this.id = id
    xml(this.url).then(xml => {
      this.svg = select(document.getElementById(this.id).appendChild(xml.documentElement))
      this.start()
    })
  }

  private start(): void {
    this.pvTitle = select('#PvTitle')
    this.pvConnector = select('#pvPowerConnector')
    this.pvPower = select('#PVPowerText')
    this.dot = select('#pvDcmicrogridDot')
    this.isReady = true
  }

  public update(pvPower: number): void {
    if (!this.isReady) {
      return
    }
    if (pvPower > 0) {
      this.dot.classed('dot-hidden', false)
    } else {
      this.dot.classed('dot-hidden', true)
    }
    this.pvPower.text(genPowerText(pvPower))
  }
}
