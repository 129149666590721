import { IComponentOptions, IDeferred, IQService, IScope } from 'angular'
import { LanguageViews } from '../../../language/language.interface'
import { EnergyViewService, EnergyViewServiceName } from './energy-view.service'
import { ElectricalComponentColors, FerroConfiguration, FerroConfigurationName } from '../../../service'

import { processedEnergyPies } from './energy-view.tools'
import { ECharts } from 'echarts'
import { createPieChart, createPieChartSeries, EnergyViewPiesData } from './energy-view-pies.chart'

import templateUrl from './energy-view-pies.component.html'
import { clearEchartsInstance } from '../../../../lib'

import './energy-view-pies.component.scss'

let pvePie: ECharts
let pvVsLoadPie: ECharts
let loadPhasesPie: ECharts
let gridPhasesPie: ECharts

class EnergyViewPiesController {
  show = {
    pve: true,
    pveLoad: true,
    grid: true,
    load: true
  }

  pieError: string

  l: LanguageViews
  service: EnergyViewService
  $scope: IScope

  startTime: Date
  endTime: Date

  piePercent: boolean
  percentOrEnergy = 'Energy'
  colors: ElectricalComponentColors
  canceller: IDeferred<unknown>

  constructor(ferroConfiguration: FerroConfiguration, energyViewService: EnergyViewService, $q: IQService) {
    this.l = ferroConfiguration.language.Views
    this.service = energyViewService
    this.colors = ferroConfiguration.electricColors
    this.canceller = $q.defer()
    this.percentOrEnergy = this.l.SHOW_PERCENT
    energyViewService.init(
      Number(ferroConfiguration.facility.id),
      ferroConfiguration.facility.timezone,
      this.canceller.promise
    )
  }

  $onChanges(): void {
    this.getData()
  }

  $onDestroy(): void {
    clearEchartsInstance(pvePie)
    clearEchartsInstance(pvVsLoadPie)
    clearEchartsInstance(loadPhasesPie)
    clearEchartsInstance(gridPhasesPie)

    pvePie = null
    pvVsLoadPie = null
    loadPhasesPie = null
    gridPhasesPie = null
    this.canceller.resolve()
  }

  private async _printPie(
    id: string,
    data: EnergyViewPiesData,
    _colors: string[],
    pie?: ECharts,
    error?: string
  ): Promise<ECharts> {
    if (!pie) pie = await createPieChart(id, data, _colors, error)
    else if (!error) pie.setOption({ series: [{ data: createPieChartSeries(data) }] })
    return pie
  }

  async doPies(data: processedEnergyPies): Promise<void> {
    const colors = this.colors
    // some days we have solar some days we do not..
    if (data.hasPve) {
      this.show.pve = true
      this.show.pveLoad = true
      const pvePieData: EnergyViewPiesData = [
        {
          name: this.l.SELF_CONSUMPTION_OF_SOLAR,
          value: data.pve[0]
        },
        {
          name: this.l.EXPORTED_TO_GRID,
          value: data.pve[1]
        }
      ]
      pvePie = await this._printPie(
        'pie_PVE',
        pvePieData,
        [colors.selfConsumption.line, colors.gridExport.line],
        pvePie
      )

      if (data.pvVsLoad) {
        pvVsLoadPie = await this._printPie(
          'pie_PV_Load',
          [
            {
              name: `${this.l.TOTAL} ${this.l.CONSUMPTION.toLowerCase()}`,
              value: data.pvVsLoad[0]
            },
            {
              name: `${this.l.TOTAL} ${this.l.SOLAR_PRODUCTION.toLowerCase()}`,
              value: data.pvVsLoad[1]
            }
          ],
          [colors.consumption.line, colors.solar.line],
          pvVsLoadPie
        )
      }
    } else {
      this.show.pveLoad = false
      this.show.pve = false
    }

    if (data.gridPhases && data.gridPhases.length && data.gridPhases[0]) {
      this.show.grid = true
      const __data = data.loadPhases

      gridPhasesPie = await this._printPie(
        'pie_LoadPhase',
        [
          {
            name: `${this.l.CONSUMPTION} ${this.l.PHASE_1.toLowerCase()}`,
            value: __data[0]
          },
          {
            name: `${this.l.CONSUMPTION} ${this.l.PHASE_2.toLowerCase()}`,
            value: __data[1]
          },
          {
            name: `${this.l.CONSUMPTION} ${this.l.PHASE_3.toLowerCase()}`,
            value: __data[2]
          }
        ],
        [colors.phase1.line, colors.phase2.line, colors.phase3.line],
        gridPhasesPie
      )
    } else {
      this.show.grid = false
    }
    if (data.loadPhases && data.loadPhases.length && data.loadPhases[0]) {
      this.show.load = true
      const __data = data.gridPhases

      loadPhasesPie = await this._printPie(
        'pie_GridPhase',
        [
          {
            name: `${this.l.IMPORTED_FROM_GRID} ${this.l.PHASE_1.toLowerCase()}`,
            value: __data[0]
          },
          {
            name: `${this.l.IMPORTED_FROM_GRID} ${this.l.PHASE_2.toLowerCase()}`,
            value: __data[1]
          },
          {
            name: `${this.l.IMPORTED_FROM_GRID} ${this.l.PHASE_3.toLowerCase()}`,
            value: __data[2]
          }
        ],
        [colors.phase1.line, colors.phase2.line, colors.phase3.line],
        loadPhasesPie
      )
    } else {
      this.show.load = false
    }
  }

  private getData() {
    const correctedEndtime =
      new Date(this.endTime).getTime() > new Date().getTime()
        ? new Date(new Date().getTime() - 3600000)
        : this.endTime

    const defaultError = `${this.l.ERROR_OCCURRED} ${this.l.WITH_PIES_CHARTS}`
    this.service.getPies(this.startTime, correctedEndtime, (error, data) => {
      if (error) {
        if (error.status === 204) {
          this.pieError = this.l.NO_DATA_FOUND
        } else this.pieError = defaultError
      } else if (data.error) {
        this.pieError = defaultError
      } else {
        this.doPies(data)
      }
    })
  }
}

EnergyViewPiesController.$inject = [FerroConfigurationName, EnergyViewServiceName, '$q']

export const EnergyViewPiesComponentName = 'energyViewPiesComponent'

export const EnergyViewPiesComponent: IComponentOptions = {
  controller: EnergyViewPiesController,
  templateUrl: templateUrl,
  bindings: {
    startTime: '<',
    endTime: '<',
    changeDates: '<'
  }
}
