import { IHttpPromise, IHttpService } from 'angular'
import { Environment } from '../../environments/environment'

const endpoint = Environment.endpoints.ehub

export interface EhubTranslateMacAddressRespond {
  id: string
  s_no: string | number
  partno: string
  DeviceType: number
  CreatedOn: Date
}

export const EhubHttpServiceName = 'ehubHttpService'

export class EhubHttpService {
  private $http: IHttpService

  constructor($http: IHttpService) {
    this.$http = $http
  }

  translateMacAddress(macAddress: string[]): IHttpPromise<EhubTranslateMacAddressRespond[]> {
    return this.$http<EhubTranslateMacAddressRespond[]>({
      method: 'POST',
      url: `${endpoint}/translate`,
      data: macAddress
    })
  }
}

EhubHttpService.$inject = ['$http']
