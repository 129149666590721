import angular, { IComponentOptions } from 'angular'
import { LanguageViews } from '../../language/language.interface'
import { FerroConfiguration, FerroConfigurationName } from '../../service'

import './view-controller-sidenav.component.scss'

let id = 0
const VIEW_CONTROL_SIDENAV_COMPONENT_ID = 'view-control'

let EXPORT_VIEW_CONTROL_SIDENAV_COMPONENT_ID: string

export { EXPORT_VIEW_CONTROL_SIDENAV_COMPONENT_ID }

import templateUrl from './view-controller-sidenav.component.html'

class ViewControllerSidenavController {
  $mdSidenav: angular.material.ISidenavService
  sidenav: angular.material.ISidenavObject
  l: LanguageViews
  id: number

  viewControlId: string

  constructor($mdSidenav: angular.material.ISidenavService, ferroConfiguration: FerroConfiguration) {
    this.l = ferroConfiguration.language.Views
    this.$mdSidenav = $mdSidenav
    id++
    this.id = id
    this.viewControlId = `${VIEW_CONTROL_SIDENAV_COMPONENT_ID}-${id}`
    EXPORT_VIEW_CONTROL_SIDENAV_COMPONENT_ID = this.viewControlId
  }

  $onInit(): void {
    this.$mdSidenav(this.viewControlId, true)
      .then(sidenav => {
        this.sidenav = sidenav
      })
      .catch(() => {})
  }

  public toggle(): void {
    if (this.sidenav) this.sidenav.toggle()
  }

  public close(): void {
    if (this.sidenav) this.sidenav.close()
  }
}

ViewControllerSidenavController.$inject = ['$mdSidenav', FerroConfigurationName]

export const ViewControllerSidenavComponent: IComponentOptions = {
  controller: ViewControllerSidenavController,
  templateUrl: templateUrl,
  transclude: true
}
