import { ApolloError } from '@apollo/client'
import {
  HookResult,
  Ng1StateDeclaration,
  StateProvider,
  Transition,
  TransitionService,
  UrlRouterProvider
} from '@uirouter/angularjs'
import { ILocationProvider } from 'angular'
import { NotificationService } from './components'
import { GlobalSettingsStates } from './global-settings/global-settings-routes.config'
import { runQuery } from './graphql/graphql'
import { systemListComponentName } from './system-list/system-list.component'
import { SystemStates } from './system/system-routing.config'

export const SystemListState = 'systemList'

export const AvailableViews = {
  main: 'main',
  sidenav: 'sidenav'
}

const SystemListStateConfig: Ng1StateDeclaration = {
  url: '/system-list',
  views: {
    [AvailableViews.main]: systemListComponentName
  },
  params: {
    error: ''
  }
}

const autoRedirectSystemDashboardHook = (transition: Transition): HookResult => {
  const notification = transition.injector().get('notificationService') as NotificationService
  const $state = transition.router.stateService
  return new Promise(resolve => {
    runQuery
      .getAvailableFacilitiesQuery({})
      .then(response => {
        if (response.error) {
          notification.onError('Failed to fetch systems')
          resolve()
        } else {
          const { facilitiesConnection } = response.data

          const totalCountFacilities = facilitiesConnection?.totalCount

          if (totalCountFacilities > 1) {
            resolve()
          }

          if (totalCountFacilities === 1) {
            runQuery.getSystemListQuery({}).then(facilitiesResponse => {
              const { facilitiesConnection } = facilitiesResponse.data
              resolve($state.target(SystemStates.dashboard, { id: facilitiesConnection.edges[0].fid }))
            })
          }

          if (totalCountFacilities === 0) {
            resolve($state.target(GlobalSettingsStates.createSystem))
          }
        }
      })
      .catch((_e: ApolloError) => {
        notification.onError('Failed to fetch systems')
      })
  })
}

export function appRouterConfig(
  $locationProvider: ILocationProvider,
  $stateProvider: StateProvider,
  $urlRouterProvider: UrlRouterProvider,
  $transitionsProvider: TransitionService
): void {
  $locationProvider.html5Mode(true)
  $urlRouterProvider.otherwise(SystemListStateConfig.url)
  $urlRouterProvider.when('', ``)
  /**
   * @name stateProvider
   */

  $transitionsProvider.onBefore({ to: SystemListState }, autoRedirectSystemDashboardHook)

  $stateProvider.state(SystemListState, SystemListStateConfig).state('404', {
    url: '/404',
    views: {
      [AvailableViews.main]: {
        template: `<div class="text-center">
  <h1>Not found</h1>
  <h2>status: 404</h2>
</div>
`
      }
    },
    params: {}
  })
}

appRouterConfig.$inject = [
  '$locationProvider',
  '$stateProvider',
  '$urlRouterProvider',
  '$transitionsProvider'
]
