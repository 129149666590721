import angular from 'angular'
import { AppServiceModule } from '../../service'
import {
  PowerViewBatteryComponent,
  PowerViewService,
  PowerViewComponent,
  PowerViewComponentName,
  PowerViewbatteryComponentName,
  PowerViewServiceName
} from './power-view'
import { LiveDataViewComponent, LiveDataViewComponentName } from './live-data-view'
import {
  EnergyViewComponent,
  EnergyViewComponentName,
  EnergyViewPiesComponent,
  EnergyViewPiesComponentName,
  EnergyViewService,
  EnergyViewServiceName
} from './energy-view'

import { SsoViewComponent, SsoViewComponentName, SsoViewService, SsoViewServiceName } from './sso-view'

export {
  LiveDataViewComponent,
  LiveDataViewComponentName,
  PowerViewBatteryComponent,
  PowerViewService,
  PowerViewComponent,
  PowerViewComponentName,
  PowerViewbatteryComponentName,
  PowerViewServiceName,
  EnergyViewComponentName,
  EnergyViewService,
  EnergyViewServiceName,
  SsoViewComponent,
  SsoViewComponentName,
  SsoViewService
}

export const AppSystemViewModule = angular
  .module('app.system.views', [AppServiceModule])
  .component(PowerViewComponentName, PowerViewComponent)
  .component(PowerViewbatteryComponentName, PowerViewBatteryComponent)
  .component(LiveDataViewComponentName, LiveDataViewComponent)
  .component(EnergyViewComponentName, EnergyViewComponent)
  .component(EnergyViewPiesComponentName, EnergyViewPiesComponent)
  .component(SsoViewComponentName, SsoViewComponent)
  .service(SsoViewServiceName, SsoViewService)
  .service(PowerViewServiceName, PowerViewService)
  .service(EnergyViewServiceName, EnergyViewService).name
