import * as angular from 'angular'
import { ferroFilter } from './ferro-filter.filter'
import { numer22decs } from './number-2-2-decimals.filter'
import { parseDate } from './parse-date.filter'
import { ferroDateToLocaleFilter } from './ferro-date-to-local.filter'
import { parseHourDate } from './parse-hour-date.filter'
import { ferroDateToLocaleTime } from './ferro-date-to-local-time.filter'
import { AppServiceModule } from '../service'

export const AppFilters = angular
  .module('app.filters', [AppServiceModule])
  .filter('ferroFilter', ferroFilter)
  .filter('ferroDateToLocaleFilter', ferroDateToLocaleFilter)
  .filter('ferroDateToLocaleTimeFilter', ferroDateToLocaleTime)
  .filter('ferroNumber22Decimals', numer22decs)
  .filter('round2', numer22decs)
  .filter('parseDate', parseDate)
  .filter('parseHourDate', parseHourDate).name
