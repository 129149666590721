import { DefaultEmsConfigSchedule, EmsConfigSchedule } from '@app/graphql/generated'

export const shouldSaveAndApplyDefault = ({
  def,
  events
}: {
  def?: Omit<DefaultEmsConfigSchedule, 'facility'>
  events?: EmsConfigSchedule[]
}) => {
  if (!def) return false
  if (def && !events?.length) return true

  const all = [{ ...def, default: true }, ...events]

  const lastApplied = all
    ?.sort((a, b) => {
      return (
        (a?.lastApplied ? new Date(a?.lastApplied).getTime() : 0) -
        (b?.lastApplied ? new Date(b?.lastApplied).getTime() : 0)
      )
    })
    ?.pop()

  return !!lastApplied?.default
}
