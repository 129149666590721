import { LanguageAlarms } from '@app/language/language.interface'
import { FerroConfiguration } from '@app/service'
import { Alarm, AlarmCode } from '@app/graphql/generated'
import { element, material } from 'angular'
import templateUrl from './alarm-details.component.html'
import { translateCode } from './alarms.lib'
import './alarm-details.component.scss'

class AlarmMetaController {
  l: LanguageAlarms
  md: material.IDialogService
  title: string
  alarmTitle: string
  alarmCode: AlarmCode

  constructor(ferroConfiguration: FerroConfiguration, $mdDialog: material.IDialogService) {
    this.l = ferroConfiguration.language.Alarms
    this.md = $mdDialog
    this.title = this.l.AFFECTED_SSOS
  }

  $onInit() {
    this.alarmTitle = (this.l as any)[translateCode(this.alarmCode)]
  }

  close() {
    this.md.cancel()
  }
}

export const AlarmDetails = (
  $event: any,
  $mdDialog: material.IDialogService,
  alarmCode: AlarmCode,
  alarmRows: Alarm[]
) => {
  $mdDialog.show({
    controller: ['ferroConfiguration', '$mdDialog', AlarmMetaController],
    locals: {
      alarmCode,
      alarmRows
    },
    bindToController: true,
    controllerAs: '$ctrl',
    templateUrl,
    parent: element(document.body),
    targetEvent: $event,
    clickOutsideToClose: true,
    fullscreen: true
  })
}
