import { SSOAveragePower } from '../../../../types/sso'
import { EChartOption } from 'echarts'
import {
  defaultEchartsDataZoom,
  defaultEchartsYAxisPower,
  echartsTimeFormatters,
  echartsToolbox,
  isMobile,
  echartsTooltip,
  tooltipDateFormatter
} from '../../../../lib'
import { LanguageViews } from '../../../language/language.interface'
import { ElectricalComponentColors } from '../../../service'
import { PowerData, SsoProduct } from '@app/graphql/generated'
import { GetSsosPowerQuery } from '@app/graphql/generated'

const grid = isMobile()
  ? null
  : {
      right: '250px',
      left: '100px'
    }

export const totalPowerSeries = (totalData: Partial<PowerData>[], name: string): EChartOption.Series[] => {
  return [
    {
      data: totalData?.map(values => [values.timestamp, values.pvPower / 1000]) as unknown as number[],

      name: name,
      type: 'line',
      symbol: 'none'
    }
  ]
}

export function createTotalPower(
  data: Partial<PowerData>[],
  l: LanguageViews,
  timezone: string,
  eColors: ElectricalComponentColors,
  title: string
): EChartOption {
  const formatter = echartsTimeFormatters(timezone).minutes
  const tooltip = echartsTooltip()
  tooltip.formatter = tooltipDateFormatter('minutes', timezone)
  const totalSeries = totalPowerSeries(data, title)

  const legend = isMobile()
    ? null
    : ({
        top: '10%',
        type: 'scroll',
        align: 'left',
        left: 'right',
        orient: 'vertical',
        data: [title]
      } as EChartOption.Legend)

  return {
    toolbox: echartsToolbox(),
    tooltip: tooltip,
    legend,
    grid: grid,
    color: [eColors.solar.line],
    yAxis: [defaultEchartsYAxisPower(l.POWER)],
    dataZoom: defaultEchartsDataZoom,
    xAxis: {
      type: 'time',
      name: l.DATE_AND_TIME,
      nameLocation: 'middle',
      nameTextStyle: {
        padding: [5, 0, 0, 0]
      },
      axisLabel: {
        formatter: (val: Date /* index */) => {
          return formatter(val)
        }
      }
    },
    series: totalSeries
  }
}

export function createSsoViewSeries(data: SSOAveragePower[]): EChartOption.Series[] {
  const total: { [ts: string]: number } = {}
  const series: EChartOption.Series[] = []
  for (let i = 0; i < data.length; i++) {
    series.push({
      data: data[i].power.map(d => {
        if (d.ts && !total[d.ts.toString()]) total[d.ts.toString()] = d.p / 1000
        else if (d.ts) total[d.ts.toString()] += d.p / 1000
        return {
          value: [d.ts, d.p / 1000] as unknown as number[]
        }
      }),
      name: data[i].id,
      type: 'line',
      symbol: 'none'
    })
  }
  return series
}

export function createSsoAveragePowerChartOptions(
  data: SSOAveragePower[],
  l: LanguageViews,
  timezone: string
): EChartOption {
  const legend = data.map(d => d.id.toString())
  const formatter = echartsTimeFormatters(timezone).minutes
  const tooltip = echartsTooltip()
  tooltip.formatter = tooltipDateFormatter('minutes', timezone)

  const series = createSsoViewSeries(data)
  const legendOps = isMobile()
    ? null
    : ({
        top: '10%',
        type: 'scroll',
        align: 'left',
        left: 'right',
        orient: 'vertical',
        data: legend
      } as EChartOption.Legend)

  return {
    toolbox: echartsToolbox(),
    tooltip: tooltip,
    legend: legendOps,
    grid: grid,
    yAxis: [defaultEchartsYAxisPower(l.POWER)],
    dataZoom: defaultEchartsDataZoom,

    xAxis: {
      type: 'time',
      name: l.DATE_AND_TIME,
      nameLocation: 'middle',
      nameTextStyle: {
        padding: [5, 0, 0, 0]
      },
      axisLabel: {
        formatter: (val: Date /* index */) => {
          return formatter(val)
        }
      }
    },
    series: series
  }
}

export const createNewSSOSeries = (data: Partial<SsoProduct>[]) => {
  return data.map(({ displayName, id, power }) => {
    return {
      name: displayName || id,
      type: 'line',
      symbol: 'none',
      data: power.map(data => [data.timestamp as unknown as string, data.power / 1000])
    } as EChartOption.Series
  })
}

export const createLegendData = (data: GetSsosPowerQuery) =>
  data?.facility?.ssos.map(({ displayName, id }) => displayName || id)

export const createSso1mAverageChartNew = (
  queryData: GetSsosPowerQuery,
  language: LanguageViews,
  timezone: string,
  startdate: Date
) => {
  const data = queryData.facility?.ssos
  const options = createSsoAveragePowerChartOptions([], language, timezone)

  if (options?.legend?.data) {
    options.legend.data = createLegendData(queryData)
  }
  // Creating of chart will always be "TODAY" which mean we only need to set "min"
  if (options?.xAxis) {
    ;(options.xAxis as EChartOption.XAxis).min = startdate.getTime()
  }
  const series = createNewSSOSeries(data)
  options.series = series
  return options
}
