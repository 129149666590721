import { EmsParam } from '@ferroamp/system-configuration-lib'
import { copy } from 'angular'

// export const defaultDefault: EmsParam = {
//   mode: 1,
//   battery: {
//     socRef: {
//       high: 100,
//       low: 10
//     },
//     powerRef: {
//       charge: 0,
//       discharge: 0
//     }
//   },
//   pv: {
//     mode: 1
//   },
//   grid: {
//     thresholds: {
//       high: 0,
//       low: 0
//     },
//     limitExport: false,
//     limitImport: false,
//     ace: {
//       mode: 1,
//       threshold: 0
//     }
//   }
// }

// export const defaultPeakShaving = {
//   mode: 2,
//   battery: {
//     socRef: {
//       high: 100,
//       low: 10
//     },
//     powerRef: {
//       charge: 100001,
//       discharge: 100001
//     }
//   },
//   pv: {
//     mode: 1
//   },
//   grid: {
//     thresholds: {
//       high: 0,
//       low: 0
//     },
//     limitExport: false,
//     limitImport: false,
//     ace: {
//       mode: 1,
//       threshold: NaN
//     }
//   }
// }

// export const defaultSelfConsumption = {
//   mode: 3,
//   battery: {
//     socRef: {
//       high: 100,
//       low: 10
//     },
//     powerRef: {
//       charge: 100001,
//       discharge: 100001
//     }
//   },
//   pv: {
//     mode: 1
//   },
//   grid: {
//     thresholds: {
//       high: 0,
//       low: 0
//     },
//     limitExport: false,
//     limitImport: false,
//     ace: {
//       mode: 1,
//       threshold: NaN
//     }
//   }
// }

export const isAdvanced = (current: EmsParam) => {
  const {
    mode,
    battery: { socRef, powerRef },
    grid: { thresholds, limitExport, limitImport }
  } = current

  if (socRef.low !== 10 || socRef.high !== 100) return true
  if (mode !== 2 && (thresholds.high !== 0 || thresholds.low !== 0)) return true

  if (current.mode !== 1) {
    if (powerRef.charge !== 100001 || powerRef.discharge !== 100001) return true
  }

  if (limitExport || limitImport) return true

  return false
}

export const setToNotAdvanced = (inEmsParams: EmsParam, mode: 1 | 2 | 3, isInitial?: boolean) => {
  const emsParams = copy(inEmsParams)
  emsParams.battery.socRef.high = 100
  emsParams.battery.socRef.low = 10

  if (!isInitial) {
    emsParams.grid.thresholds.high = 0
    emsParams.grid.thresholds.low = 0
  }

  emsParams.grid.limitExport = false
  emsParams.grid.limitImport = false

  if (mode !== 1) {
    emsParams.battery.powerRef.charge = 100001
    emsParams.battery.powerRef.discharge = 100001
  } else if (!isInitial) {
    emsParams.battery.powerRef.charge = 0
    emsParams.battery.powerRef.discharge = 0
  }

  return emsParams
}
