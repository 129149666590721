/**
 *  The version parser takes a semver version and parse it into major,minor,patch,
 *
 */

// taken from https://semver.org/#is-there-a-suggested-regular-expression-regex-to-check-a-semver-string
const semverRegExp =
  /^(0|[1-9]\d*)\.(0|[1-9]\d*)\.(0|[1-9]\d*)(?:-((?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*)(?:\.(?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*))*))?(?:\+([0-9a-zA-Z-]+(?:\.[0-9a-zA-Z-]+)*))?$/

export interface ParsedVersions {
  version: string
  major: number
  minor: number
  patch: number
  prerelease: string
  meta: string
}

export function versionParser(version: string): ParsedVersions {
  if (!semverRegExp.test(version)) {
    return {
      major: null,
      version: version,
      minor: null,
      patch: null,
      prerelease: null,
      meta: null
    }
  }

  const split = version.split(/\+/g)
  const splitPreRelease = split[0].split('-')
  const majorMinorPatch = splitPreRelease.shift().split('.')
  const meta = split[1]
  const preRelease = splitPreRelease.join('-')
  const Major = majorMinorPatch[0]
  const Minor = majorMinorPatch[1]
  const Patch = majorMinorPatch[2]

  const filterEmpty = (str: string): boolean => str !== ''

  return {
    version: [[Major, Minor, Patch].join('.'), preRelease].filter(filterEmpty).join('-'),
    major: Number(Major),
    minor: Number(Minor),
    patch: Number(Patch),
    prerelease: preRelease,
    meta: meta
  }
}
