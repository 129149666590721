import AmplitudeJS from 'amplitude-js'
import { Environment } from '../../environments/environment'

export const AmplitudeServiceName = 'amplitudeService'

const isProduction = () => {
  const host = window.location.host
  return host === 'portal.ferroamp.com' || host === 'portal.eu.prod.ferroamp.com'
}

export class AmplitudeService {
  private instance: AmplitudeJS.AmplitudeClient

  constructor() {
    this.instance = AmplitudeJS.getInstance()

    if (isProduction()) {
      this.instance.init('acb0f662bb912f7922cd9600d4d0bbb3', Environment.sub)
    } else {
      this.instance.init('34ab60cd621486dc7bfef47b86e58f8a', Environment.sub)
    }

    this.instance.setUserProperties({
      isFerroampEmployee: Environment.userEmail.includes('@ferroamp'),
      isFirstLineSupport: Environment.userEmail.includes('@h1')
    })
  }

  logEvent(eventName: string, eventProps?: any) {
    this.instance.logEvent(eventName, eventProps)
  }

  setUserProperties(props: any) {
    this.instance.setUserProperties(props)
  }
}
