import { ApolloClient, gql, HttpLink, InMemoryCache, split } from '@apollo/client/core'
import { setContext } from '@apollo/client/link/context'
import { getMainDefinition } from '@apollo/client/utilities'
import fetch from 'cross-fetch'
import { Environment } from '../../environments/environment'

import { getHeaders, getToken, keycloak } from '@app/auth/keycloak'
import { getAllSdk, getAllWatchSdk } from './generated'
import { GraphqlSSELink } from './sse-link'
import { wsLink } from './ws-link'

const graphqlBaseUri = Environment.graphqlUri || ''
const httpLink = new HttpLink({ uri: `${graphqlBaseUri}/graphql`, fetch })

const authLink = setContext(async (_, { headers }) => {
  return getHeaders(headers)
})

keycloak.onTokenExpired = () => {
  keycloak.updateToken(-1)
}

const sseLink = new GraphqlSSELink({
  url: `${graphqlBaseUri}/graphql/stream`,
  retryAttempts: 100,
  fetchFn: window.fetch || fetch,
  headers: async () => {
    return {
      Authorization: getToken()
    }
  }
})

// The split function takes three parameters:
//
// * A function that's called for each operation to execute
// * The Link to use for an operation if the function returns a "truthy" value
// * The Link to use for an operation if the function returns a "falsy" value
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
  },
  split(
    ({ operationName }) => {
      return operationName == 'NewSystemControlTopologyChange'
    },
    wsLink,
    sseLink
  ),
  httpLink
)

const client = new ApolloClient({
  connectToDevTools: !Environment.isProd,
  link: authLink.concat(splitLink),
  credentials: 'include',
  cache: new InMemoryCache()
})

const runQuery = getAllSdk(client)
const runWatchQuery = getAllWatchSdk(client)

export { client, gql, runQuery, runWatchQuery }
