import { AlarmCode, AlarmEntry, AlarmStatus } from '@app/graphql/generated'
import startCase from 'lodash/startCase'
import camelCase from 'lodash/camelCase'

const alarmCodeMapping: Partial<Record<AlarmCode, string>> = {
  [AlarmCode.SystemOffline]: 'SUBSCRIBABLE_ALARM_CODE_SYSTEM_OFFLINE',
  [AlarmCode.SsoOffline]: 'SUBSCRIBABLE_ALARM_CODE_SSO_OFFLINE'
}

const subscribeCodeMapping: Record<AlarmCode, string> = {
  [AlarmCode.SystemOffline]: 'SUBSCRIBABLE_ALARM_CODE_SYSTEM_OFFLINE',
  [AlarmCode.SsoOffline]: 'SUBSCRIBABLE_ALARM_CODE_SSO_OFFLINE',
  [AlarmCode.All]: 'SUBSCRIBABLE_ALARM_CODE_ALL'
}

export const translateSubscribeCode = (code: AlarmCode): string => subscribeCodeMapping[code]

export const translateCode = (code: AlarmCode): string => {
  return alarmCodeMapping[code] || 'unknown'
}

export const toSubscribableAlarmCode = (str: AlarmCode | string) => {
  return AlarmCode[startCase(camelCase(str)).replace(/ /g, '') as unknown as 'All']
}

export type AlarmCodeRow = {
  code: AlarmCode
  status: AlarmStatus
  lastChangedAt: Date
  children: Omit<AlarmEntry, 'facility'>[]
}

const byLastChanged = (a: AlarmEntry, b: AlarmEntry) => {
  return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
}

export const groupAlarmRows = (alarms: Omit<AlarmEntry, 'facility'>[]): AlarmCodeRow[] => {
  const uniqueAlarmCodes: AlarmCode[] = Array.from(new Set(alarms.map(a => a.code))).sort()

  return uniqueAlarmCodes.map(code => {
    const allAlarmsForCode = alarms.filter(a => a.code === code).sort(byLastChanged)

    const lastActiveAlarm = allAlarmsForCode.find(a => a.status === AlarmStatus.Active)

    return {
      code,
      status: lastActiveAlarm?.status || AlarmStatus.Inactive,
      lastChangedAt: allAlarmsForCode[0].updatedAt,
      children: allAlarmsForCode
    }
  })
}
