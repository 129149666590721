import * as angular from 'angular'

export function de(p: string): string {
  p = p.replace(/\s/g, '')
  return window.atob(p)
}

export function getId(id: string): HTMLElement {
  return document.getElementById(id)
}

export function angularElement(id: string): JQLite {
  return angular.element(getId(id))
}

export function elementExists(id: string): void {
  const element = document.getElementById(id)
  if (!(typeof element != 'undefined' && element != null))
    throw new Error(`No element with id ${id} doesn't exists!`)
}

export function waitForElementId(id: string): Promise<HTMLElement> {
  return new Promise(resolve => {
    let index = 0
    const exists = () => {
      index++
      const element = document.getElementById(id)

      if (element) {
        resolve(element)
      } else {
        if (index >= 40) throw new Error(`Element wasnt found with id: ${id}`)
        setTimeout(() => {
          exists()
        }, 20)
      }
    }
    exists()
  })
}
