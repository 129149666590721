import { GraphColors, ThemeColors } from './colors'

export type themeColorsKeys = 'primary' | 'warn' | 'background' | 'accent'

export type ThemeColorsInterface = {
  [key in themeColorsKeys]: {
    50: string
    100: string
    200: string
    300: string
    400: string
    500: string
    600: string
    700: string
    800: string
    900: string
    A100: string
    A200: string
    A400: string
    A700: string
    base: string
  }
}

export type graphColorKeys =
  | 'blue'
  | 'orange1'
  | 'orange2'
  | 'green'
  | 'red'
  | 'gray'
  | 'purple'
  | 'yellow'
  | 'brown'
export type graphColorKeysFlatten =
  | 'blueL'
  | 'blueB'
  | 'orange1L'
  | 'orange1B'
  | 'orange2L'
  | 'orange2B'
  | 'greenL'
  | 'greenB'
  | 'redL'
  | 'redB'
  | 'grayL'
  | 'grayB'
  | 'purpleL'
  | 'purpleB'
  | 'yellowL'
  | 'yellowB'
  | 'brownL'
  | 'brownB'

interface BarLineColor {
  bar: string
  line: string
}

export type GraphColors = {
  [key in graphColorKeys]: BarLineColor
}
export type GraphColorsFlatten = {
  [key in graphColorKeysFlatten]: string
}

export interface ColorObject {
  colorblind: GraphColors
  default: GraphColors
  dcGrid: string
  acGrid: string
}

export type colorThemes = 'colorblind' | 'default' | 'dcGrid' | 'acGrid'
export type graphColorsThemes = 'default' | 'colorblind'

export const ColorServiceName = 'colorService'

export interface ElectricalComponentColors {
  battery: BarLineColor
  solar: BarLineColor
  consumption: BarLineColor
  grid: BarLineColor
  selfConsumption: BarLineColor
  gridExport: BarLineColor
  phase1: BarLineColor
  phase2: BarLineColor
  phase3: BarLineColor
  neutral: BarLineColor

  warning: BarLineColor
}

export class ColorService {
  themes: colorThemes[] = ['default', 'colorblind', 'dcGrid', 'acGrid']
  colors: ColorObject = GraphColors

  graphColors: ColorObject = GraphColors
  themeColors: ThemeColorsInterface = ThemeColors

  mapGraphColors(graphColorObject: GraphColors): GraphColorsFlatten {
    if (typeof graphColorObject !== 'object' || graphColorObject === null) {
      throw new Error('Unknown graphColors Theme')
    } else {
      const graphColors: { [k: string]: string } = {} as GraphColorsFlatten
      Object.keys(graphColorObject).forEach((key: graphColorKeys) => {
        const colors = graphColorObject[key]
        graphColors[`${key}L`] = colors.line
        graphColors[`${key}B`] = colors.bar
      })
      return graphColors as GraphColorsFlatten
    }
  }

  getGraphColors(theme: graphColorsThemes): GraphColorsFlatten {
    return this.mapGraphColors(this.colors[theme] as GraphColors)
  }

  getLineColors(_theme: graphColorsThemes): string[] {
    const theme = this.colors[_theme]
    if (!theme) {
      throw new Error(`Undefined  theme ${_theme}`)
    } else {
      return Object.keys(theme).map((key: graphColorKeys) => {
        return theme[key].line
      })
    }
  }

  getBarColors(_theme: graphColorsThemes): string[] {
    const theme = this.colors[_theme]
    if (!theme) {
      throw new Error(`Undefined  theme ${_theme}`)
    } else {
      return Object.keys(theme).map((key: graphColorKeys) => {
        return theme[key].bar
      })
    }
  }

  getThemeColor(): ThemeColorsInterface {
    return this.themeColors
  }

  getColor(map: colorThemes, key: graphColorKeys, variant: 'line' | 'bar'): string {
    variant = variant || ('base' as unknown as 'bar')
    try {
      return (this.colors[map] as GraphColors)[key][variant]
    } catch {
      throw new Error('Undefined Colors')
    }
  }

  getElectricComponentColors(theme: graphColorsThemes): ElectricalComponentColors {
    const idColors = this.colors[theme] as GraphColors
    if (!idColors) throw new Error(`Invalid theme ${theme}. `)

    // _c.greenB, _c.orange1B, _c.grayB, _c.blueB
    return {
      battery: idColors.green,
      consumption: idColors.blue,
      grid: idColors.gray,
      solar: idColors.yellow,
      selfConsumption: idColors.orange2,
      gridExport: idColors.purple,

      phase1: idColors.green,
      phase2: idColors.orange1,
      phase3: idColors.gray,
      neutral: idColors.blue,

      warning: idColors.red
    }
  }

  get(): ColorObject {
    return this.colors
  }
}
