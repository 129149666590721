/**
 *
 * @ngdoc filter
 * @name ferroUser.filter:numer22decs
 * @function
 * Return number as 2 decimal string representation
 *
 */
export function numer22decs(): (nr: number) => void {
  return function (nr: number): number | string {
    if (isNaN(nr)) {
      return 'Invalid Number'
    } else {
      return Math.round(nr * 100) / 100
    }
  }
}
