import { copy, material } from 'angular'
import { EmsParam } from '@ferroamp/system-configuration-lib'
import { BatteryWizardObject, EmsConfigV2HttpService } from '@app/service/ems-config-v2-http.service'
import { FerroConfiguration, LanguageService } from '@app/service'
import batteryWizardTemplate from './battery-wizard.template.html'
import { EmsConfigV2Language, en, se } from '../ems-config-form/ems-config.language'

export { batteryWizardTemplate }

export class BatteryWizardController {
  config: EmsParam
  dialog: material.IDialogService
  l: EmsConfigV2Language
  batterySettings: {
    Name: string
    Charge: number
    Discharge: number
    Low: number
    High: number
  }
  list: BatteryWizardObject[]
  service: EmsConfigV2HttpService

  constructor(
    $mdDialog: material.IDialogService,
    config: EmsParam,
    emsConfigV2HttpService: EmsConfigV2HttpService,
    ferroConfiguration: FerroConfiguration,
    languageService: LanguageService
  ) {
    this.config = copy(config)
    this.service = emsConfigV2HttpService
    this.dialog = $mdDialog
    this.l = languageService.language === 'us' ? en : se
  }

  async $onInit(): Promise<void> {
    const data = await this.service.getBatteryWizardList()
    this.list = copy(data.data)
  }

  apply(): void {
    this.dialog.hide(this.config)
  }

  discard(): void {
    this.dialog.cancel('Discarded')
  }

  batteryChanged(): void {
    if (this.batterySettings) {
      this.config.battery.powerRef.charge = this.batterySettings.Charge
      this.config.battery.powerRef.discharge = this.batterySettings.Discharge
      this.config.battery.socRef.low = this.batterySettings.Low
      this.config.battery.socRef.high = this.batterySettings.High
    }
  }
}
