import { ApolloQueryResult, FetchResult } from '@apollo/client'
import { NotificationService } from '@app/components'
import { SystemConfigurationGraphqlService, SystemConfigurationGraphqlServiceName } from '@app/graphql'
import {
  DefaultEmsConfigSchedule,
  EmsConfig,
  EmsConfigSchedule,
  Mutation,
  Query
} from '@app/graphql/generated'
import {
  FerroConfiguration,
  FerroConfigurationName,
  LanguageService,
  LanguageServiceName
} from '@app/service'
import { AmplitudeService, AmplitudeServiceName } from '@app/service/amplitude.service'
import type { languagesAvailable } from '@app/service/language.service'
import { RolesServices, RolesServicesName } from '@app/service/roles.services'
import { IScope, material } from 'angular'
import { capitalize } from 'lodash'
import { RRule } from 'rrule'
// import { emsSchedulePickerDialog } from './ems-config-schedule-picker.component'
import { LanguageScheduling } from '@app/language/language.interface'
import { SwedishRRuleToText } from '@app/language/rrule-language'
import { StateService } from '@uirouter/core'
import { DateTime } from 'luxon'
import { systemSettingsStates } from '../../system-settings.routing.config'
import templateUrl from './ems-config-schedule.component.html'
import './ems-config-schedule.component.scss'
import { resolveSupportLink } from './ems-schedule-picker.tools'
import { shouldSaveAndApplyDefault } from './helpers'
import { toVCalendarInputs } from './i-calendar'

export interface EmsConfigScheduleParsed extends EmsConfigSchedule {
  event?: any
  rule?: RRule
  ruleAsString?: string
}

const toEmsScheduleParsed = (schedule: EmsConfigSchedule, language: languagesAvailable, timezone: string) => {
  try {
    const calendar = toVCalendarInputs(schedule.iCalendarEvent)
    const startDate = calendar.event.startDate.toJSDate()
    ;(schedule as EmsConfigScheduleParsed).event = calendar.event
    ;(schedule as EmsConfigScheduleParsed).rule = calendar.rules
    ;(schedule as EmsConfigScheduleParsed).ruleAsString = calendar.rules
      ? language === 'se'
        ? SwedishRRuleToText({ rule: calendar.rules })
        : capitalize(calendar.rules.toText())
      : DateTime.fromJSDate(startDate, { zone: timezone }).toISODate()
    return schedule
  } catch (_error) {
    return null
  }
}

class EmsConfigScheduleController {
  private fc: FerroConfiguration
  private $md: material.IDialogService
  private service: SystemConfigurationGraphqlService
  private notifications: NotificationService
  private $s: IScope
  private amplitudeService: AmplitudeService
  title = 'Scheduling'
  default: Omit<DefaultEmsConfigSchedule, 'facility'>
  schedules: EmsConfigSchedule[] = []
  hasDefault = false
  events: EmsConfigSchedule[] = []
  calendarEvents: {
    events?: EmsConfigSchedule[]
    defaultEvent?: Omit<DefaultEmsConfigSchedule, 'facility'>
  } = {}
  loading = true
  baseEmsParam: Omit<EmsConfig, 'facility'>
  notAllowed: boolean

  l: LanguageScheduling
  $state: StateService
  supportLink: string
  language: languagesAvailable
  calendarChanged: () => void
  constructor(
    ferroConf: FerroConfiguration,
    md: material.IDialogService,
    service: SystemConfigurationGraphqlService,
    notifications: NotificationService,
    $scope: IScope,
    languageService: LanguageService,
    amplitudeService: AmplitudeService,
    roles: RolesServices,
    $state: StateService
  ) {
    this.title = ferroConf.language.Scheduling.SCHEDULING
    this.notAllowed = !roles.canUpdateFacilitySystemSettings()
    this.fc = ferroConf
    this.$md = md
    this.service = service
    this.notifications = notifications
    this.$s = $scope
    this.language = languageService.language
    this.supportLink = resolveSupportLink(languageService.language)
    this.amplitudeService = amplitudeService
    this.l = ferroConf.language.Scheduling
    this.$state = $state

    this.calendarChanged = () => {
      this.render()
    }
  }

  async $onInit(): Promise<void> {
    this.render()
  }

  private async render() {
    this.$s.$applyAsync(() => {
      this.events = []
      this.default = null
      this.schedules = []
      this.calendarEvents = {}

      this.loading = true
    })
    const emsConfig = await this.service.getEmsConfig(Number(this.fc.facility.id))
    if (emsConfig) this.baseEmsParam = emsConfig.data.facility.emsConfig
    if (!this.baseEmsParam) {
      this.fc.alert(
        'This system cannot use scheduling, please consult support.',
        'The system require version 2.0 or greater, and applied operation settings'
      )
      return
    }

    this.service
      .getEmsConfigSchedules(Number(this.fc.facility.id))
      .catch(() => {
        // this.notifications.onError('Error occurred retrieving schedules');
        this.loading = false
      })
      .then((config: ApolloQueryResult<Query>) => {
        if (!config) return this.$s.$applyAsync()
        if (config.error) {
          return this.$s.$applyAsync()
        }

        const emsSchedules = config.data.facility.emsConfigSchedules
        this.default = config.data.facility.defaultEmsConfigSchedule
        this.hasDefault = !!this.default

        if (emsSchedules && emsSchedules.length) {
          emsSchedules.forEach(schedules => {
            const schedule = toEmsScheduleParsed(schedules, this.language, this.fc.facility.timezone)
            if (schedule) {
              this.schedules.push(schedule)
            }
          })
          this.events = emsSchedules
        }
        shouldSaveAndApplyDefault({
          def: this.default,
          events: this.events
        })

        this.$s.$applyAsync(() => {
          this.calendarEvents = {
            events: this.events,
            defaultEvent: this.default
          }
          this.loading = false
        })
      })
  }

  edit($event: MouseEvent, schedule?: EmsConfigSchedule, isDefault?: boolean): void {
    if (this.notAllowed) return

    this.$state.go(systemSettingsStates.schedulingEdit, {
      scheduleId: schedule ? schedule.id : isDefault ? this.default?.id : undefined,
      isDefault: !!isDefault,
      isUpdating: !!schedule
    })
  }

  async remove(schedule: EmsConfigScheduleParsed): Promise<void> {
    const iWantToRemove = await this.fc
      .confirm(
        `${this.l.REMOVE_EVENT} ${schedule.event.summary}`,
        this.l.SURE_REMOVE_EVENT,
        this.l.YES_IM_SURE,
        this.l.CANCEL
      )
      .catch(() => {
        // this.notifications.onError(this.l.ERROR_OCCURED_PLEASE_TRY_AGAIN)
      })
    if (iWantToRemove) {
      this.loading = true
      this.amplitudeService.logEvent('Remove Ems Schedule')
      const result = await this.service
        .deleteEmsConfigSchedule(Number(this.fc.facility.id), schedule.id)
        .catch()
      const { success } = result.data.deleteEmsConfigSchedule
      if (success) {
        this.notifications.onSuccess(`${this.l.SUCCESS_FULLY_REMOVED_EVENT} ${schedule.event.summary}`)
        this.render()
      } else {
        this.notifications.onError(this.l.FAILED_REMOVED_EVENT)
      }
    }
  }

  createFirstDefaultFromEmsConfig(): void {
    this.fc
      .confirm(
        this.l.CONFIRM_ENABLE,
        this.l.CONFIRM_INFO.replace('{SUPPORT_LINK}', this.supportLink),
        this.l.CONFIRM_UNDERSTAND,
        this.l.CANCEL
      )

      .then(async () => {
        this.$s.$applyAsync(() => (this.loading = true))
        const defaultSchedule = (await this.service
          .setDefaultEmsConfigSchedule(Number(this.fc.facility.id), {
            emsParam: this.baseEmsParam
          })
          .catch(_error => {
            this.notifications.onError(this.l.ERROR_OCCURED_PLEASE_TRY_AGAIN)
          })) as FetchResult<Mutation>
        if (defaultSchedule) {
          this.notifications.onSuccess(this.l.SUCCESS_FULLY_SET_DEFAULT_SCHEDULE)
          this.render()
          this.amplitudeService.logEvent('Activate EMS Scheduling')
          this.amplitudeService.setUserProperties({
            hasActivatedEmsScheduling: true
          })
        }
      })
      .catch(() => {
        return
      })
  }
}

EmsConfigScheduleController.$inject = [
  FerroConfigurationName,
  '$mdDialog',
  SystemConfigurationGraphqlServiceName,
  'notificationService',
  '$scope',
  LanguageServiceName,
  AmplitudeServiceName,
  RolesServicesName,
  '$state'
]

export const EmsConfigScheduleComponentName = 'emsScheduling'
export const EmsConfigScheduleComponent = {
  templateUrl,
  controller: EmsConfigScheduleController
}
