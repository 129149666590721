import angular from 'angular'
import { LanguageSystemSettings } from '../../../../language/language.interface'
import { FerroConfiguration } from '../../../../service'
import { SystemInformationChangesList } from './system-information-changes.component'

export interface MdbMetaFacilityMetainfoChangelist {
  _id: string
  /* @deprecated  */
  user?: string
  userid: number
  updateTime: number
  old: any
  new: any
}

type all = string | number | Date | boolean

export class SystemInformationDialogController {
  $mdDialog: angular.material.IDialogService
  l: LanguageSystemSettings
  changes: { before?: all; after?: all; name: string }[]
  changeTime: string
  user: string
  noChangesFound = false

  constructor(ferroConfiguration: FerroConfiguration, changeData: SystemInformationChangesList[0]) {
    this.l = ferroConfiguration.language.SystemSettings
    const mapping: { [k: string]: string } = {
      'facilities.name': this.l.NAME,
      'facilities.installedOn': this.l.SYSTEM_INSTALLED,
      'facilities.timezone': this.l.TIMEZONE,
      'facility_locations.address': this.l.ADDRESS,
      'facility_locations.city': this.l.CITY,
      'facility_locations.country': this.l.COUNTRY,
      'facility_locations.latitude': this.l.LATITUDE,
      'facility_locations.longitude': this.l.LONGITUDE,
      'facility_locations.electrical_grid_id': this.l.GRID_COMPANY_SYSTEM_ID,
      'facility_locations.property_designation': this.l.PROPERTY_DESIGNATION,
      'facility_capacities.installed_pv': this.l.PEAK_POWER,
      'facility_miscs.cost_electricity_kwh': `${this.l.ELECTRICITY_COST} ${this.l.ELECTRICITY_COST_INFO}`,
      'facility_miscs.revenue_sold_to_grid_kwh': this.l.REVENUE_SOLD_TO_GRID,
      'facility_miscs.currency_code': this.l.CURRENCY
    }

    this.changes = (changeData.changes as { after?: string; before?: string; path: string }[])
      .map(({ path, after, before }) => {
        return {
          before,
          after,
          name: mapping[path]
        }
      })
      .filter(({ name }) => name)
    // this.user = changeData.userId;
    this.changeTime = `${new Date(changeData.createdAt).toLocaleDateString()} ${new Date(
      changeData.createdAt
    ).toLocaleTimeString()}`
  }

  cancel(): void {
    this.$mdDialog.hide()
  }
}
