import angular, { ILocationService } from 'angular'
import EventEmitter from 'eventemitter3'
import { twoDecimals } from '../../lib'
import {
  LiveDataPowerUiInterface,
  LivedataSSODataInterface,
  PortalLiveDataEsoModulesDataInterface,
  PortalLivedataParsedResultInterface,
  PortalLiveDataSsoModulesDataInterface,
  PortalLivedataWebsocketInterface
} from '../../types'
import { SocketioService } from './socketio.service'

const LiveDataOptions: any = {}

function correctDecimals(value: number): number {
  if (value < 1000) {
    return Math.round(100 * value) / 100
  } else {
    return Math.round(value)
  }
}

type LivedataEsoDataInterface = any

const livedataObject = {
  rememberPVpower: undefined as number,
  rememberLoad: 0,
  rememberbatPower: undefined as number,
  u1: 0,
  u2: 0,
  u3: 0,
  eq1: 0,
  eq2: 0,
  eq3: 0,
  ed1: 0,
  ed2: 0,
  ed3: 0,
  er1: 0,
  er2: 0,
  er3: 0,

  iq1: 0,
  iq2: 0,
  iq3: 0,
  id1: 0,
  id2: 0,
  id3: 0,
  ir1: 0,
  ir2: 0,
  ir3: 0,

  lq1: 0,
  lq2: 0,
  lq3: 0,
  ln: 0,

  dcNeg: 0,
  dcPos: 0,

  esoModules: {},
  ssoModules: {}
}

export const LiveDataServiceName = 'liveDataService'

export class LivedataService extends EventEmitter {
  id = ''
  clusterEnable = false
  // eslint-disable-next-line
  holder: any = {}
  socketService: SocketioService
  facilityId: number | number[]

  constructor($location: ILocationService) {
    super()
    this.socketService = new SocketioService($location)
  }

  setUp(facilityId: number | number[]): void {
    this.facilityId = facilityId

    // Ugly hack to be able to use the same api when subscribing to new live data
    if (Array.isArray(facilityId) && facilityId.length === 1) {
      this.facilityId = facilityId[0]
    }

    if (typeof this.facilityId === 'object' && this.facilityId.length) {
      this.clusterEnable = true
      this.facilityId.forEach(_facilityId => {
        this.holder[_facilityId] = angular.copy(livedataObject)
      })
    } else if (typeof this.facilityId === 'number') {
      this.holder[this.facilityId] = angular.copy(livedataObject)
    }
  }

  /**
   *  added the options to listen to several facilities at the same time,
   *   this is couse cluster mode.
   *
   * *NOTE* That we use `forceNew: true` for liveData.
   */
  start(callback: (result: PortalLivedataParsedResultInterface) => void): void {
    this.socketService.connect(LiveDataOptions.wsNamespace, LiveDataOptions.wsPath)

    if (this.clusterEnable) {
      ;(this.facilityId as number[]).forEach(id => {
        this.socketService.on('connect', () => {
          this.socketService.send(LiveDataOptions.wsEventInitiator, id)
        })

        this.socketService.listen<PortalLivedataWebsocketInterface>(id.toString(), data => {
          this.onData(data, id, processedData => {
            if (!document.hidden) {
              callback(processedData)
              this.emit('data', processedData)
            }
          })
        })
      })
    } else {
      this.socketService.on('connect', () => {
        this.socketService.send(LiveDataOptions.wsEventInitiator, this.facilityId)
      })

      this.socketService.listen<PortalLivedataWebsocketInterface>(this.facilityId.toString(), data => {
        this.onData(data, this.facilityId as number, processedData => {
          if (!document.hidden) {
            callback(processedData)
            this.emit('data', processedData)
          }
        })
      })
    }
  }

  stop(): void {
    this.holder = {}
    this.clusterEnable = false
    this.socketService.disconnect()
  }

  /**
   *
   */

  private onData(
    data: PortalLivedataWebsocketInterface,
    facilityId: number,
    callback: (result: PortalLivedataParsedResultInterface) => void
  ): void {
    if (!data) {
      return
    }
    const ts = new Date(data.ts)
    if (!ts) {
      return
    }

    const uiPowerData = this.uiPowerCache(data.uiPowerData, facilityId)
    this.sysDataCache(data, facilityId)

    if (!this.clusterEnable) {
      const pvStringData = data.ssoModulesData
      const esoModuleData = data.esoModulesData
      const esoModulesData = this.esoCache(esoModuleData, facilityId)
      const ssoModulesData = this.cacheSso(pvStringData, facilityId)
      callback({
        uiPowerData: data.uiPowerData,
        esoModulesData: esoModulesData,
        ssoModulesData: ssoModulesData,
        sysData: this.holder[facilityId].sysData,
        facilityId: facilityId,
        dcData: data.dcData,
        ts: ts
      })
    } else {
      callback({
        ts: ts,
        facilityId: facilityId,
        dcData: data.dcData,
        uiPowerData: uiPowerData,
        sysData: this.holder[facilityId].sysData
      })
    }
  }

  private sysDataCache(data: PortalLivedataWebsocketInterface, facilityId: number): void {
    if (data.sysData) {
      if (!this.holder[facilityId].sysData) {
        this.holder[facilityId].sysData = {}
      }
      Object.assign(this.holder[facilityId].sysData, data)
    }
  }

  private uiPowerCache(uiPowerData: LiveDataPowerUiInterface, facilityId: number): LiveDataPowerUiInterface {
    if (uiPowerData.pvPower) {
      this.holder[facilityId].rememberPVpower = uiPowerData.pvPower
    } else if (typeof this.holder[facilityId].rememberPVpower !== 'undefined') {
      uiPowerData.pvPower = this.holder[facilityId].rememberPVpower
    }

    if (uiPowerData.batPower) {
      this.holder[facilityId].rememberbatPower = uiPowerData.batPower
    } else if (typeof this.holder[facilityId].rememberbatPower !== 'undefined') {
      uiPowerData.batPower = this.holder[facilityId].rememberbatPower
    }
    return uiPowerData
  }

  /**
     *
     id: SerialNumber;
     power: number;
     energyProd: number;
     energyCons: number;
     soc?: number;
     * @param data
     * @param serialNumber
     * @return {{min: number, batteryData: [], max: number}}
     */

  private esoCache(
    data: LivedataEsoDataInterface[],
    serialNumber: number
  ): PortalLiveDataEsoModulesDataInterface {
    // updat
    if (!data) {
      return
    } else if (!data.length) {
      return
    }

    const esoLength = data.length
    for (let i = 0; i < esoLength; i++) {
      const d = data[i]
      this.holder[serialNumber].esoModules[d.id] = {
        power: d.power,
        energyProd: d.energyProd,
        energyCons: d.energyCons,
        soc: d.soc
      }
    }

    const batteryLabels = Object.keys(this.holder[serialNumber].esoModules)
    const batteryData: LivedataEsoDataInterface[] = []
    let maxBatteryNowPower = 0
    let minBatteryPower = 0

    const batteryLabelsLength = batteryLabels.length

    for (let j = 0; j < batteryLabelsLength; j++) {
      const label = batteryLabels[j]
      const mod = this.holder[serialNumber].esoModules[label]
      const bbb = twoDecimals(this.holder[serialNumber].esoModules[label].power)

      maxBatteryNowPower = Math.ceil(bbb * 1.1)
      minBatteryPower = Math.round(bbb * 1.1)
      if (bbb > maxBatteryNowPower) {
        maxBatteryNowPower = bbb
      }

      if (bbb < minBatteryPower) {
        minBatteryPower = bbb
      }

      batteryData.push({
        id: label,
        power: bbb,
        energyProd: mod.energyProd,
        energyCons: mod.energyCons,
        soc: this.holder[serialNumber].esoModules[label].soc
      })
    }

    return {
      data: batteryData
    }
  }

  private cacheSso(
    data: LivedataSSODataInterface[],
    facilityId: number
  ): PortalLiveDataSsoModulesDataInterface {
    if (!data) {
      return
    } else if (!data.length) {
      return
    }

    const ssoLength = data.length
    for (let i = 0; i < ssoLength; i++) {
      const d = data[i]
      this.holder[facilityId].ssoModules[d.id] = {
        power: d.power < 0 ? 0 : d.power,
        energy: d.energy
      }
    }

    let maxPVPower = 1
    const pvLabels = Object.keys(this.holder[facilityId].ssoModules)
    let maxPVEnergy = 0

    // maxPVPower more than max due some space in the graphs
    const ssoData = pvLabels.map(d => {
      const ppp = this.holder[facilityId].ssoModules[d].power / 1000
      if (ppp > maxPVPower) {
        maxPVPower = Math.ceil(ppp * 1.1)
      }

      let eng = this.holder[facilityId].ssoModules[d].energy

      eng = correctDecimals(eng)
      eng = eng < 0 ? 0 : eng
      if (eng > maxPVEnergy) {
        maxPVEnergy = eng
      }

      return {
        id: d,
        power: correctDecimals(ppp),
        energy: eng
      }
    })

    return {
      data: ssoData
    }
  }
}

LivedataService.$inject = ['$location']
