import { ApolloClient, QueryOptions, SubscriptionOptions, MutationOptions } from '@apollo/client';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
  _FieldSet: { input: any; output: any; }
};

export type Query = {
  __typename?: 'Query';
  _noop?: Maybe<Scalars['Int']['output']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  newSystemControlTopologyChange: SystemControlTopologyChange;
};


export type SubscriptionNewSystemControlTopologyChangeArgs = {
  facilityId?: InputMaybe<Scalars['ID']['input']>;
  systemId?: InputMaybe<Scalars['String']['input']>;
};

export type SystemControlTopologyChange = SystemControlTopologyDone | SystemControlTopologyFailed | SystemControlTopologySucceeded;

export type SystemControlTopologyDone = {
  __typename?: 'SystemControlTopologyDone';
  status?: Maybe<Scalars['String']['output']>;
};

export type SystemControlTopologyFailed = {
  __typename?: 'SystemControlTopologyFailed';
  reason?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type SystemControlTopologySucceeded = {
  __typename?: 'SystemControlTopologySucceeded';
  status?: Maybe<Scalars['String']['output']>;
};

export type NewSystemControlTopologyChangeSubscriptionVariables = Exact<{
  facilityId: Scalars['ID']['input'];
}>;


export type NewSystemControlTopologyChangeSubscription = { __typename?: 'Subscription', newSystemControlTopologyChange: { __typename: 'SystemControlTopologyDone', status?: string | null } | { __typename: 'SystemControlTopologyFailed', reason?: string | null, status?: string | null } | { __typename: 'SystemControlTopologySucceeded', status?: string | null } };


export const NewSystemControlTopologyChangeDocument = gql`
    subscription NewSystemControlTopologyChange($facilityId: ID!) {
  newSystemControlTopologyChange(facilityId: $facilityId) {
    __typename
    ... on SystemControlTopologyFailed {
      __typename
      reason
      status
    }
    ... on SystemControlTopologySucceeded {
      __typename
      status
    }
    ... on SystemControlTopologyDone {
      status
    }
  }
}
    `;
export const getSdk = (client: ApolloClient<any>) => ({
      newSystemControlTopologyChangeSubscription(options: Partial<SubscriptionOptions<NewSystemControlTopologyChangeSubscriptionVariables, NewSystemControlTopologyChangeSubscription>>) {
          return client.subscribe<NewSystemControlTopologyChangeSubscription, NewSystemControlTopologyChangeSubscriptionVariables>({...options, query: NewSystemControlTopologyChangeDocument})
      }
    });
    export type SdkType = ReturnType<typeof getSdk>
