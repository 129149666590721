import * as angular from 'angular'
import {
  PowerShareDashboardComponent,
  PowershareDashboardComponentName
} from './powershare-dashboard/powershare-dashboard.component'
import { powerShareRoutingConfig } from './powershare-routing.config'
import { AVAILABLE_EXTERNAL_MODULES } from '../../environments/environment'
import {
  PowerShareBlockComponent,
  PowerShareBlockComponentName
} from './powershare-block/powershare-block.component'
import { AppServiceModule } from '../service'

export const AppPowerShareModule = angular
  .module('app.powershare', [
    AVAILABLE_EXTERNAL_MODULES.ngAnimate,
    AVAILABLE_EXTERNAL_MODULES.uiRouter,
    AppServiceModule
  ])
  .config(powerShareRoutingConfig)
  .component(PowerShareBlockComponentName, PowerShareBlockComponent)
  .component(PowershareDashboardComponentName, PowerShareDashboardComponent).name
