import {
  LivedataEsoDataInterface,
  LiveDataPowerUiInterface,
  LivedataSSODataInterface,
  PortalLiveDataEsoModulesDataInterface,
  PortalLiveDataSsoModulesDataInterface
} from '../../../types'
import {
  long,
  roundTo2Decimals,
  echartsToolbox,
  echartsTooltip,
  Needle,
  echartsOnResize,
  defaultEchartsYAxisPower,
  defaultEchartsGrid,
  defaultEchartsYAxisEnergy,
  tooOneDecimal
} from '../../../lib'
import { SystemDashboardGauges } from './system-dashboard.tools'
import { SystemDashboardLayout } from './system-dashboard.interfaces'
import { Environment } from '../../../environments/environment'

import { EChartOption, ECharts } from 'echarts'
import { LanguageSystemdashboard } from '../../language/language.interface'
import { ElectricalComponentColors } from '../../service'
import { SsoMapping } from '../../../types/sso'
import { captureSentryException } from '../../config/sentry'
import { Map } from 'leaflet'
import { MdbMetaFacilitySystemTopologyESOS, MdbMetaFacilitySystemTopologySSOS } from 'types/topology'

function sortSSOOrESO(
  a: LivedataEsoDataInterface | LivedataSSODataInterface,
  b: LivedataEsoDataInterface | LivedataSSODataInterface
) {
  if (a.id < b.id) {
    return -1
  } else if (a.id > b.id) {
    return 1
  } else {
    return 0
  }
}

export function updateGaugesValues(
  gaugeHolder: Needle,
  value: number,
  twoSided: boolean,
  changeSignDirection?: boolean
): void {
  if (!gaugeHolder) return
  gaugeHolder.max = Number(gaugeHolder.max)
  gaugeHolder.min = Number(gaugeHolder.min)

  if (gaugeHolder.max < value) {
    gaugeHolder.updateOptions({
      min: twoSided ? -Math.round(value * 1.5) : 0,
      max: Math.round(value * 1.5),
      usingReversedSign: changeSignDirection
    })
  } else if (gaugeHolder.min > value) {
    gaugeHolder.updateOptions({
      min: twoSided ? Math.round(value * 1.5) : 0,
      max: twoSided ? -Math.round(value * 1.5) : gaugeHolder.max,
      usingReversedSign: changeSignDirection
    })
  }
  gaugeHolder.moveTo(roundTo2Decimals(value))
}

export function updateSchematics(
  data: LiveDataPowerUiInterface,
  dashboardLayout: SystemDashboardLayout,
  schematics: SystemDashboardGauges
): void {
  if (schematics && schematics.aceSchematic && dashboardLayout.aceSchematic) {
    schematics.aceSchematic.update(data)
  }
}

export function uiPowerDataGaugesHandler(
  data: LiveDataPowerUiInterface,
  gauges: SystemDashboardGauges,
  dashboardLayout: SystemDashboardLayout
): void {
  if (gauges.gridPowerGauge && data.gridPower && dashboardLayout.gridpower) {
    updateGaugesValues(gauges.gridPowerGauge, data.gridPower, true, false)
  }
  if (gauges.loadPowerGauge && data.loadPower && dashboardLayout.loadpower) {
    if (+data.loadpower > gauges.loadPowerGauge.max) {
      gauges.loadPowerGauge.updateOptions({
        max: data.loadPower * 1.5
      })
    }
    gauges.loadPowerGauge.moveTo(roundTo2Decimals(data.loadPower < 0 ? 0 : data.loadPower))
  }
  if (gauges.loadOne && data.er1 && dashboardLayout.lC123) {
    updateGaugesValues(gauges.loadOne, data.eq1, true)
  }
  if (gauges.loadTwo && data.er2 && dashboardLayout.lC123) {
    updateGaugesValues(gauges.loadTwo, data.eq2, true)
  }
  if (gauges.loadThree && data.er3 && dashboardLayout.lC123) {
    updateGaugesValues(gauges.loadThree, data.eq3, true)
  }
  if (gauges.pvPowerGauge && data.pvPower && dashboardLayout.pvpower) {
    if (+data.pvpower > gauges.pvPowerGauge.max) {
      gauges.pvPowerGauge.updateOptions({
        max: data.pvPower * 1.5
      })
    }
    gauges.pvPowerGauge.moveTo(roundTo2Decimals(data.pvPower < 0 ? 0 : data.pvPower))
  }
  if (gauges.batModulePower && data.batPower && dashboardLayout.batpower) {
    updateGaugesValues(gauges.batModulePower, data.batPower, true)
  }
}

export function mapsHandler(Longitude: number, Latitude: number, displayName: string): Map {
  if (
    (isNaN(Longitude) && isNaN(Latitude)) ||
    Longitude === null ||
    Latitude === null ||
    Latitude === 0 ||
    Longitude === 0
  ) {
    return null
  } else {
    const LATITUDE_LONGITUDE = window.L.latLng(Number(Latitude), Number(Longitude))
    try {
      const dashboardMap = window.L.map('mapSystemLocation').setView(LATITUDE_LONGITUDE, 8)
      window.L.tileLayer(
        'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}',
        {
          attribution:
            'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
          maxZoom: 18,
          id: 'mapbox/streets-v11',
          accessToken: Environment.mapboxApiKey
        }
      ).addTo(dashboardMap)
      window.L.marker(LATITUDE_LONGITUDE, { title: displayName }).addTo(dashboardMap)
      return dashboardMap
    } catch (e) {
      console.error(e)
      captureSentryException(e)
    }
  }
}

/**
 * Initialize the live eso graph, and return function to update it on live data
 *
 */
export function doBatteryModuleData(
  batteryModuleChart: ECharts,
  data: PortalLiveDataEsoModulesDataInterface,
  esoMapping: { [key: string]: MdbMetaFacilitySystemTopologyESOS },
  l: LanguageSystemdashboard,
  colors: ElectricalComponentColors
): (data: PortalLiveDataEsoModulesDataInterface) => {
  series: EChartOption.Series[]
  xAxis: EChartOption.XAxis
} {
  echartsOnResize(batteryModuleChart)

  const powerTitle = `${l.POWER} [kW]`
  const socTitle = `${l.STATE_OF_CHARGE} [%]`
  const legend = [powerTitle, socTitle]
  /**
   * All BatModules do not arrive at the same time, we have to cache them locally and populate the chart with all units!
   */
  const units: { [key: string]: { power: number; soc: number } } = {}

  function createData(__data: PortalLiveDataEsoModulesDataInterface): {
    series: EChartOption.Series[]
    xAxis: EChartOption.XAxis
  } {
    const batPower: number[] = []
    const batLabels: string[] = []
    const batSoc: number[] = []

    if (esoMapping) {
      __data.data.map(d => {
        if (esoMapping[d.id]) {
          const name = esoMapping[d.id].name
          let id
          if (name) {
            id = name
          } else {
            id = d.id
          }
          return {
            id: id,
            power: d.power,
            energy: d.energy,
            soc: d.soc
          }
        } else {
          return d
        }
      })
    }
    __data.data.sort(sortSSOOrESO)

    __data.data.forEach(d => {
      units[d.id] = {
        power: tooOneDecimal(d.power),
        soc: tooOneDecimal(d.soc)
      }
    })

    const keys = Object.keys(units)
    /**
     * Always want them in the same order in the chart
     */
    keys.sort()
    keys.forEach(name => {
      batLabels.push(name)
      batPower.push(units[name].power)
      batSoc.push(units[name].soc)
    })

    const series = [
      { name: powerTitle, type: 'bar', yAxisIndex: 0, data: batPower },
      { name: socTitle, type: 'bar', yAxisIndex: 1, data: batSoc }
    ]
    const xAxis: EChartOption.XAxis = {
      type: 'category',
      data: batLabels,
      name: 'ESO'
    }
    return {
      series,
      xAxis
    }
  }

  const __data = createData(data)

  const ops: EChartOption = {
    grid: defaultEchartsGrid,
    color: [colors.battery.line, colors.battery.bar],
    tooltip: echartsTooltip(),
    toolbox: echartsToolbox(),
    legend: {
      data: legend
    },
    xAxis: __data.xAxis,
    yAxis: [
      defaultEchartsYAxisPower(l.POWER),
      {
        type: 'value',
        name: socTitle,
        min: 0,
        max: 100,
        position: 'right',
        axisLabel: {
          formatter: '{value} %'
        }
      }
    ],
    series: __data.series
  }

  batteryModuleChart.setOption(ops)
  return createData
}

export function autoFillDefinedSSOS(ssoMapping: SsoMapping): void {
  if (!ssoMapping) {
    return
  }
  const keys = Object.keys(ssoMapping)
  const len = keys.length
  if (len) {
    const data: {
      ssoData: { power: number; id: string; energy: number }[]
      maxPVPower: number
      maxPVEnergy: number
    } = {
      ssoData: [],
      maxPVPower: 0,
      maxPVEnergy: 0
    }
    for (let i = 0; i < len; i++) {
      const sso = ssoMapping[keys[i]]
      const last = sso.last
      let energy = last.eAccumulated

      energy = long(energy as unknown as string) / 1000

      data.maxPVEnergy = data.maxPVEnergy < energy ? energy : data.maxPVEnergy
      data.ssoData.push({
        id: sso.name ? sso.name : (last.pvStringId as string),
        energy: Math.round(energy),
        power: 0
      })
    }
  }
}

/**
 * Initialize the live sso graph, and return function to update it on live data
 *
 */
export function doPvStringData(
  pvStringsChart: ECharts,
  liveSsoData: PortalLiveDataSsoModulesDataInterface,
  ssoMapping: { [key: string]: MdbMetaFacilitySystemTopologySSOS },
  l: LanguageSystemdashboard,
  colors: ElectricalComponentColors
): void {
  echartsOnResize(pvStringsChart)
  liveSsoData.data.sort(sortSSOOrESO)

  const powerTitle = `${l.POWER} [kW]`
  const energyTitle = `${l.ENERGY} [kWh]`
  const legend = [powerTitle, energyTitle]

  const names = liveSsoData.data.map(d => (ssoMapping ? ssoMapping[d.id]?.name : null) || d.id)
  const powerSeries = liveSsoData.data.map(d => roundTo2Decimals(d.power))
  const energySeries = liveSsoData.data.map(d => d.energy)

  const series = [
    { name: powerTitle, type: 'bar', yAxisIndex: 0, data: powerSeries },
    { name: energyTitle, type: 'bar', yAxisIndex: 1, data: energySeries }
  ]

  const xAxis: EChartOption.XAxis = {
    type: 'category',
    data: names,
    name: l.SSO
  }

  const ops: EChartOption = {
    grid: defaultEchartsGrid,
    tooltip: echartsTooltip(),
    color: [colors.solar.line, colors.selfConsumption.line],
    toolbox: echartsToolbox(),
    xAxis: xAxis,
    legend: {
      data: legend
    },
    yAxis: [defaultEchartsYAxisPower(l.POWER), defaultEchartsYAxisEnergy(l.ENERGY)],
    series: series
  }

  pvStringsChart.setOption(ops)
}
