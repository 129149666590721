import { EmsParam } from '@ferroamp/system-configuration-lib'
import angular, { IFormController, IOnChangesObject, IScope } from 'angular'
import templateUrl from './ems-config-form.component.html'
import { LanguageService } from '@app/service'
import { LanguageServiceName } from '@app/service/language.service'
import { se, en, EmsConfigV2Language } from './ems-config.language'

import './ems-config-form.component.scss'
import { isAdvanced, setToNotAdvanced } from './advanced'

export const EmsConfigMode = [
  {
    name: 'Default',
    mode: 1,
    info: '',
    active: false
  },
  {
    name: 'Peak Shaving',
    mode: 2,
    active: false,
    info: ''
  },
  {
    name: 'Self Consumption',
    mode: 3,
    active: false,
    info: ''
  }
]

interface FormScope extends IScope {
  emsConfigForm: IFormController
}

class EmsConfigFormController {
  modeTitle: string
  modeInfo: string
  advancedModeTitle: string
  advancedModeInfo: string
  disabled = false
  advanced = false
  private $s: FormScope
  l: EmsConfigV2Language
  isDefault = false
  isPeakShaving = false
  isMaximizeSelf = false
  batteryPower: 'Charge' | 'Off' | 'Discharge' = 'Off'
  config: EmsParam
  modeList: typeof EmsConfigMode = []
  modeCache: number

  emsConfigForm: IFormController

  configIsSet = false
  emsParams: EmsParam

  formFound = false
  emsForm: IFormController

  constructor($scope: FormScope, languageService: LanguageService) {
    this.$s = $scope
    this.l = languageService.language === 'us' ? en : se
    this.onEmsParams(this.config)
    this.modeList = EmsConfigMode.map(mode => {
      if (mode.mode === 1) {
        mode.name = this.l.DEFAULT
        mode.info = this.l.DEFAULT_MODE_INFO
      } else if (mode.mode === 2) {
        mode.name = this.l.PEAK_SHAVING
        mode.info = this.l.PEAK_SHAVING_MODE_INFO
      } else {
        mode.name = this.l.SELF_CONSUMPTION
        mode.info = this.l.SELF_CONSUMPTION_MODE_INFO
      }
      return mode
    })
  }

  private getModeInfo(mode: 1 | 2 | 3) {
    return this.modeList.find(choice => choice.mode === mode)
  }

  $onChanges(changes: IOnChangesObject): void {
    if (changes.config && changes.config.currentValue) {
      this.onEmsParams(changes.config.currentValue)
    }
  }

  onEmsParams(inConfig: EmsParam): void {
    const config = angular.copy(inConfig)
    if (!config) return
    this.$s.$applyAsync(() => {
      this.advanced = isAdvanced(inConfig)

      if (config?.grid?.ace) {
        config.grid.ace.mode = (config.grid.ace.mode === 1 ||
          (config.grid.ace.mode as unknown) === true) as unknown as 0 | 1
      }
      if (config?.pv) {
        config.pv.mode = (config.pv.mode === 1 || (config.pv.mode as unknown) === true) as unknown as 0 | 1
      }
      if (config.mode === 1) {
        if (config?.battery?.powerRef && config.battery.powerRef.charge > 0) {
          this.batteryPower = 'Charge'
        } else if (config?.battery?.powerRef && config.battery.powerRef.discharge > 0) {
          this.batteryPower = 'Discharge'
        } else {
          this.batteryPower = 'Off'
        }
      }

      this.config = angular.copy(config)
      this.configIsSet = true
      this.setMode(this.config.mode, this.advanced, true)
    })
  }

  setAdvanced() {
    this.setMode(this.config.mode, true)
  }

  $onInit(): void {
    this.onEmsParams(this.config)
  }

  $doCheck(): void {
    if (!this.formFound && this.$s.emsConfigForm) {
      this.formFound = true
      this.emsForm = this.$s.emsConfigForm
    }
    if (this.config && this.config.mode !== this.modeCache) {
      if (!this.configIsSet) this.onEmsParams(this.config)
    }
  }

  setMode(mode: 1 | 2 | 3, advanced: boolean, isInitial?: boolean): void {
    this.$s.$applyAsync(() => {
      this.config.mode = mode
      this.advanced = advanced
      this.modeCache = angular.copy(mode)
      this.isDefault = mode === 1
      this.isPeakShaving = mode === 2
      this.isMaximizeSelf = mode === 3
      this.modeList = this.modeList.map(mo => {
        mo.active = mo.mode === mode && !this.advanced
        return mo
      })

      const modeInfo = this.getModeInfo(mode)
      if (this.advanced) {
        this.modeTitle = this.l.ADVANCED
        this.modeInfo = this.l.ADVANCED_INFO
        this.advancedModeInfo = modeInfo.info
        this.advancedModeTitle = modeInfo.name
      } else {
        this.modeTitle = modeInfo.name
        this.modeInfo = modeInfo.info
      }

      if (!advanced) {
        this.config = setToNotAdvanced(this.config, mode, isInitial)
      } else if (!isInitial) {
        this.config.battery.powerRef.charge = 0
        this.config.battery.powerRef.discharge = 0
      }
      this.batteryPowerModeChanged()
    })
  }

  batteryPowerModeChanged(): void {
    if (this.isDefault && this.config) {
      switch (this.batteryPower) {
        case 'Off':
          this.config.battery.powerRef.charge = 0
          this.config.battery.powerRef.discharge = 0
          break
        case 'Charge':
          this.config.battery.powerRef.discharge = 0
          break
        case 'Discharge':
          this.config.battery.powerRef.charge = 0
          break
        default:
          console.warn('Unknown batteryPower mode')
      }
    }
  }
}

EmsConfigFormController.$inject = ['$scope', LanguageServiceName]

export const emsConfigFormComponent = {
  controller: EmsConfigFormController,
  templateUrl,
  bindings: {
    config: '=emsParams',
    emsForm: '=?',
    advanced: '=?',
    disabled: '=formDisabled'
  }
}
