export type DownloadCsvOptions = {
  header: string[]
  data: any[]
  fileName: string
}

const openAsDownload = (content: string, fileName: string) => {
  const encodedUri = encodeURI(content)
  const link = document.createElement('a')
  if (typeof link.download === 'string') {
    link.href = encodedUri
    link.download = fileName

    //Firefox requires the link to be in the body
    document.body.appendChild(link)
    //simulate click
    link.click()

    //remove the link when done
    document.body.removeChild(link)
  } else {
    const encodedUri = encodeURI(content)
    window.open(encodedUri)
  }
}

export const energyViewDownloadCsv = ({ header, data, fileName }: DownloadCsvOptions) => {
  const csvContent = `data:text/csv;charset=utf-8,${header.join(',')}\n`

  return openAsDownload(csvContent + data.map(e => e.join(',')).join('\n'), fileName)
}
