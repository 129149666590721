import { Environment } from '@environments/environment'
import { keycloak } from './auth/keycloak'

export const bootstrap = async () => {
  const user = (await keycloak.loadUserInfo()) as any
  Environment.userEmail = user.email
  Environment.accountName = user.name
  // Environment.userId = user.legacy_id;
  Environment.sub = user.sub
  Environment.language = user.locale == 'sv' ? 'se' : user.locale
  if (user.color_palette) {
    Environment.colorMode = user.color_palette
  }
}
