import { StateService } from '@uirouter/angularjs'
import { IComponentOptions, IDeferred, IQService, IScope } from 'angular'
import { ECharts } from 'echarts'
import moment from 'moment-timezone'
import { clearEchartsInstance, echartsInitWrapper } from '../../../../lib'
import { LanguageAnalaysis } from '../../../language/language.interface'
import { FerroConfiguration, FerroConfigurationName } from '../../../service'
import { MdbAnalysisResultsId } from '../@types/load-event-detection'
import {
  AnalysisHttpService,
  AnalysisHttpServiceName,
  ParsedStoredAnalysis,
  PendingData
} from '../analysis-http.service'
import { LoadSignatureService, LoadSignatureServiceName } from './load-signature.service'
import { getLoadSignatureChartOptions, ParsedLoadSignatureData } from './load-signature.tools'

import templateUrl from './load-signature.component.html'

let mainGraph: ECharts
let secondGraph: ECharts

class LoadSignatureController {
  errorList = ''

  loading = false
  showGraphs = false
  error = ''
  starttime: Date
  endtime: Date
  maxDate: Date
  minDate: Date
  analysisJobs: ParsedStoredAnalysis[]

  l: LanguageAnalaysis
  okeyToStartNew: boolean
  ifJobIsRunning = ''

  service: LoadSignatureService
  aService: AnalysisHttpService
  fcService: FerroConfiguration
  timezone: string
  facilityId: number
  $s: StateService
  $scope: IScope

  graphTitle = ''
  graphTitle2 = ''

  canceller: IDeferred<unknown>
  onResultsId: (resultsId: MdbAnalysisResultsId) => void

  constructor(
    ferroConfiguration: FerroConfiguration,
    loadSignatureService: LoadSignatureService,
    $q: IQService,
    $state: StateService,
    analysisHttpService: AnalysisHttpService,
    $scope: IScope
  ) {
    this.$scope = $scope
    this.$s = $state
    this.fcService = ferroConfiguration
    this.service = loadSignatureService
    this.aService = analysisHttpService

    this.l = ferroConfiguration.language.SystemAnalysis
    this.facilityId = Number(ferroConfiguration.facility.id)

    this.canceller = $q.defer()
    this.timezone = ferroConfiguration.facility.timezone || 'Europe/Stockholm'

    this.loading = false
    this.showGraphs = false
    this.error = ''
    this.starttime = this.fixDate(new Date(new Date().setDate(new Date().getDate() - 7))) // start 1 week ago;
    this.endtime = this.fixDate(new Date(), true)

    this.maxDate = ferroConfiguration.getMaxDate()
    this.minDate = ferroConfiguration.getMinDate()

    this.onResultsId = (resultsId: MdbAnalysisResultsId): void => {
      this.loadProfileGetJob(resultsId)
    }
  }

  $onInit(): void {
    this.aService.getCurrentLoadEventJobs(Number(this.fcService.facility.id), jobs => {
      if (jobs.length) {
        const starttime = this._formatDate(jobs[0].starttime)
        const endtime = this._formatDate(jobs[0].endtime)
        const timestamp = this._formatDate(jobs[0].timestamp)
        this.ifJobIsRunning = this.l.ANALYSIS_JOB_IS_RUNNING.replace('<STARTTIME>', starttime)
          .replace('<ENDTIME>', endtime)
          .replace('<TIMESTAMP>', timestamp)
        this.okeyToStartNew = false
      }
    })

    this.service.init(
      '',
      Number(this.fcService.facility.id),
      this.canceller.promise,
      this.l,
      (err, savedJobs) => {
        if (err) {
          if (err === 'NO_DATA') {
            this.errorList = this.l.NO_GENERATED_ANALYSIS
          } else this.errorList = this.l.ERROR_OCCURRED
        } else {
          const jobs: ParsedStoredAnalysis[] = []
          savedJobs.forEach(d => {
            d.status = d.jobstatus === 0 ? this.l.ANALYSIS_SUCCESS : this.l.ANALYSIS_ERROR

            if (d.histtype === 'signature' || d.histtype === 'signscatt') {
              jobs.push(d)
            }
          })
          this.analysisJobs = jobs
        }
      }
    )

    if (this.$s.params.resultsid) {
      this.loadProfileGetJob(this.$s.params.resultsid)
    }
  }

  $onDestroy(): void {
    this.canceller.resolve()
    if (mainGraph) clearEchartsInstance(mainGraph)
    if (secondGraph) clearEchartsInstance(secondGraph)
    mainGraph = null
    secondGraph = null
  }

  openThisAnalysis(jobsId: MdbAnalysisResultsId): void {
    this.loadProfileGetJob(jobsId)
  }

  _formatDate(date: Date): string {
    return moment.tz(date, this.timezone).format('YYYY-MM-DD HH:mm')
  }

  fixDate(date: Date, end?: boolean): Date {
    const d = moment.tz(date, this.timezone)
    return end ? d.endOf('day').toDate() : d.startOf('day').toDate()
  }

  createNewJob(): void {
    this.error = ''
    const starttime = this.starttime
    const endtime = this.endtime
    this.aService.createJob(starttime, endtime, this.facilityId, error => {
      if (error) {
        this.error = this.l.ERROR_START_ANALYSIS
      } else {
        this.okeyToStartNew = false
        this.ifJobIsRunning = this.l.ANALYS_JOB_CREATED
      }
    })
  }

  loadProfileGetJob(resultsid: MdbAnalysisResultsId): void {
    this.loading = true
    this.error = ''
    this.showGraphs = true
    this.service.getJob(resultsid, (error, result) => {
      if (error && (error as PendingData).processingstatus === 'PENDING') {
        this.error = this.l.LOAD_SIGNATURE_RESULT_PENDING
      } else if (error) {
        this.error = this.l.ERROR_OCCURRED
        return
      }
      this.plotData(result)
    })
  }

  async plotData(data: ParsedLoadSignatureData): Promise<void> {
    if (!mainGraph) mainGraph = await echartsInitWrapper('mainGraph')
    //   if (!secondGraph) secondGraph = await echartsInitWrapper('secondGraph')
    const ops = getLoadSignatureChartOptions(data, this.l)
    mainGraph.setOption(ops.optionsMain)
    // mainGraph.setOption(ops.optionsSecond);
    this.$scope.$applyAsync(() => {
      this.loading = false
    })
  }
}

LoadSignatureController.$inject = [
  FerroConfigurationName,
  LoadSignatureServiceName,
  '$q',
  '$state',
  AnalysisHttpServiceName,
  '$scope'
]

export const LoadSignatureComponentName = 'loadSignatureComponent'

export const LoadSignatureComponent: IComponentOptions = {
  templateUrl,
  controller: LoadSignatureController
}
