import { runQuery } from '@app/graphql/graphql'
import {
  FerroConfiguration,
  FerroConfigurationName,
  LanguageService,
  LanguageServiceName
} from '@app/service'
import { IComponentOptions } from 'angular'
import templateUrl from './system.component.html'
import './system.component.scss'

const en = {
  maintenanceTitle: 'Maintenance Mode',
  maintenanceDescription:
    'Your system is currently in maintenance mode. Do not turn it off! More information <a href="https://support.ferroamp.com/en/support/solutions/articles/47001272961" target="_blank">here</a>',

  offlineTitle: 'Offline',
  offlineDescription:
    'Follow this <a href="https://ferroamp.com/en/user-guide/#Felsokning" target="_blank">troubleshooting guide</a> if you see this condition on your system.'
}

const se: typeof en = {
  maintenanceTitle: 'Underhållsläge',
  maintenanceDescription:
    'Ditt system är för närvarande i underhållsläge. Stäng ej av det! Mer information <a href="https://support.ferroamp.com/sv-SE/support/solutions/articles/47001272961" target="_blank">här</a>',

  offlineTitle: 'Offline',
  offlineDescription:
    'Följ denna <a href="https://ferroamp.com/anvandarguide/#Felsokning" target="_blank">felsökningsguide</a> om du ser detta tillstånd på ditt system.'
}

class SystemController {
  ongoingMaintenance = false

  isOffline = false
  timeout: any
  l: typeof en
  constructor(
    private fc: FerroConfiguration,
    private language: LanguageService
  ) {
    this.l = language.language === 'se' ? se : en
  }

  async $onInit() {
    this.fetch()
  }

  $onDestroy() {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
  }

  async fetch() {
    const res = await runQuery.facilityMaintenanceQuery({
      variables: {
        id: Number(this.fc?.facility?.id)
      }
    })
    const data = res?.data?.facility

    this.isOffline = !data?.connectivity?.isOnline
    this.ongoingMaintenance = data?.maintenance?.ongoingSystemUpgrade

    this.timeout = setTimeout(() => {
      this.fetch()
    }, 60000)
  }
}

SystemController.$inject = [FerroConfigurationName, LanguageServiceName]

export const SystemComponentName = 'systemComponentName'
export const SystemComponent: IComponentOptions = {
  controller: SystemController,
  templateUrl
}
