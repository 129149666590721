import { IComponentOptions, IOnChangesObject, IScope, ITimeoutService } from 'angular'
import echarts, { ECharts } from 'echarts'
import {
  clearEchartsInstance,
  echartsOnResize,
  echartsShowLoadingOptions,
  EchartsShowLoadingType,
  getId
} from '../../../../lib'
import { LanguageViews } from '../../../language/language.interface'
import {
  FeatureFlagService,
  FeatureFlagServiceName,
  FerroConfiguration,
  FerroConfigurationName
} from '../../../service'
import {
  batteryDataToDataset,
  batteryYAxis,
  createPowerViewBatteryChart,
  createSeries
} from './power-view.chart'
import { PowerViewService, PowerViewServiceName } from './power-view.service'

import { GetBessesStateofChargeQuery, UiFlowChartComponent } from '@app/graphql/generated'
import { runQuery } from '@app/graphql/graphql'
import templateUrl from './power-view.battery.component.html'

class PowerViewBatteryController {
  batteryResizeTimeout: ITimeoutService
  chart: ECharts
  featureFlagService: FeatureFlagService
  powerViewService: PowerViewService
  ferroConfiguration: FerroConfiguration

  loading = true

  l: LanguageViews

  unit: 'percent' | 'kwh' = 'percent'
  ratedcapacitynotice: string
  ratedCapacity: number
  stateOfHealth: number

  colors: string[]
  batteryExists = false

  starttime: Date
  endtime: Date
  timezone: string
  private data: GetBessesStateofChargeQuery['facility']
  zoomValues: {
    start: any
    stop: any
  }

  $s: IScope

  constructor(
    powerViewService: PowerViewService,
    ferroConfiguration: FerroConfiguration,
    featureFlagService: FeatureFlagService,
    $scope: IScope
  ) {
    this.featureFlagService = featureFlagService
    this.powerViewService = powerViewService
    this.ferroConfiguration = ferroConfiguration
    this.l = ferroConfiguration.language.Views

    this.colors = [ferroConfiguration.electricColors.battery.line, '#943ADB', '#E767A4']

    this.timezone = ferroConfiguration.facility.timezone
    this.$s = $scope
  }

  $onInit(): void {
    runQuery
      .getUiComponentsForPortalQuery({
        variables: {
          facilityId: +this.ferroConfiguration.facility.id
        }
      })
      .then(result => {
        this.batteryExists = result.data?.facility?.uiComponents?.flowChart?.enabledComponents.includes(
          UiFlowChartComponent.Battery
        )
        if (this.batteryExists) this.getBatteryData(this.starttime, this.endtime)
      })
  }

  $onDestroy(): void {
    if (this.chart) {
      clearEchartsInstance(this.chart)
    }
    if (this.batteryResizeTimeout) {
      this.batteryResizeTimeout.cancel()
    }
  }

  $onChanges(changed: IOnChangesObject): void {
    if (this.chart) {
      if (Object.keys(changed)[0] === 'zoomValues') {
        this.chart.dispatchAction({
          type: 'dataZoom',
          start: this.zoomValues.start || 0,
          end: this.zoomValues.stop || 100
        })
      } else {
        this.getBatteryData(this.starttime, this.endtime)
      }
    }
  }

  getBatteryData(startDate: Date, endDate: Date): void {
    this.loading = true

    runQuery
      .getBessesStateofChargeQuery({
        variables: {
          id: Number(this.ferroConfiguration.facility.id),
          gte: startDate,
          lte: endDate
        }
      })
      .then(({ loading, data }) => {
        this.loading = loading
        if (this.batteryExists) {
          // const result = processBatteryEnergyData(data?.facility, this.unit === 'percent')
          this.data = data?.facility
          this.renderBatteryEnergy(this.data)
        }
      })

    if (this.chart) {
      this.chart.showLoading(EchartsShowLoadingType, echartsShowLoadingOptions())
    }
  }

  renderBatteryEnergy(data: GetBessesStateofChargeQuery['facility']): void {
    // this.ratedCapacity = data.ratedCapacity / 1000
    // this.stateOfHealth = data.stateOfHealth > 100 ? 100 : data.stateOfHealth

    if (!this.chart) {
      this.chart = echarts.init(getId('batteryEnergyGraphContainer') as HTMLDivElement)
      this.chart.setOption(createPowerViewBatteryChart(data, this.colors, this.l, this.timezone, this.unit))
      echartsOnResize(this.chart)
    } else {
      this.chart.setOption({
        legend: {
          data: data?.batteries?.filter(bat => bat?.log?.history?.length)?.map(({ productId }) => productId)
        },
        dataset: batteryDataToDataset(data),
        series: createSeries(data)
      })
    }
    this.$s.$applyAsync(() => {
      this.loading = false
      this.chart.hideLoading()
    })
  }

  unitChange(): void {
    const yAxis = batteryYAxis(this.unit, this.l)
    // const data = processBatteryEnergyData(this.data, this.unit === 'percent')
    this.chart.setOption({
      yAxis: yAxis,
      dataset: {
        source: batteryDataToDataset(this.data)
      }
    })
  }
}

PowerViewBatteryController.$inject = [
  PowerViewServiceName,
  FerroConfigurationName,
  FeatureFlagServiceName,
  '$scope'
]

export const PowerViewbatteryComponentName = 'powerViewBatteryComponent'

export const PowerViewBatteryComponent: IComponentOptions = {
  controller: PowerViewBatteryController,
  templateUrl: templateUrl,
  bindings: {
    starttime: '<',
    endtime: '<',
    zoomValues: '<'
  }
}
