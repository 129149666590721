import { IComponentOptions } from 'angular'
import { yrWeatherIconMapping } from './yr-image-mapping'
import consumptionSymbol from '../../../assets/images/svgs/consumption-symbol.svg'
import ehubSymbol from '../../../lib/schematics/symbols/ehub-symbol.svg'
import gridSymbol from '../../../lib/schematics/symbols/grid-symbol.svg'
import evseSymbol from '../../../lib/schematics/symbols/evse-symbol.svg'
// import * as  powerSchematic from '../../../assets/images/svgs/ehub-symbol.svg';
import solarSymbol from '../../../assets/images/svgs/solar-symbol.svg'
import { flagIconMapping } from './flag-icon-mapping'
/**
 * @example
 * <ferro-image image-src="solarSymbol"></ferro-image>
 * // or weather image
 * <ferro-image image-src="200"  weather-icon="true"></ferro-image>
 * <!-- Will render -->
 * <img src="/images/svgs/solarSymbol_4.1.11.svg">
 */

export type imageMappingNames =
  | 'aceSchematic'
  | 'batteryTotalCluster'
  | 'clusterSchematic'
  | 'consumptionSymbol'
  | 'ehubSymbol'
  | 'gridSymbol'
  | 'evseSymbol'
  | 'powerSchematic'
  | 'solarSymbol'
  | 'solarTotalCluster'

const imageMap: { [k: string]: string } = {
  consumptionSymbol: consumptionSymbol,
  ehubSymbol: ehubSymbol,
  gridSymbol: gridSymbol,
  solarSymbol: solarSymbol,
  evseSymbol: evseSymbol
}

class FerroImageController {
  imageSrc: string
  weatherIcon?: string
  src?: string
  flagIcon?: string

  $onInit(): void {
    if (this.weatherIcon) {
      if (!yrWeatherIconMapping[Number(this.imageSrc)]) {
        throw new Error(
          `Invalid yr weather icon ${this.imageSrc}, Possible options are: ${JSON.stringify(
            Object.keys(yrWeatherIconMapping),
            null,
            4
          )}`
        )
      }
      this.src = yrWeatherIconMapping[Number(this.imageSrc)]
    } else if (this.flagIcon) {
      if (!flagIconMapping[this.imageSrc]) {
        throw new Error(
          `Invalid flag icon ${this.imageSrc}, Possible options are: ${JSON.stringify(
            Object.keys(flagIconMapping),
            null,
            4
          )}`
        )
      }
      this.src = flagIconMapping[this.imageSrc]
    } else {
      if (!imageMap[this.imageSrc]) {
        throw new Error(
          `Invalid ferroImage ${this.imageSrc} Possible options are: ${JSON.stringify(
            Object.keys(imageMap),
            null,
            4
          )}`
        )
      }
      this.src = imageMap[this.imageSrc]
    }
  }

  // private set(imageSrc: string) {
  //     this.$attrs.set('src',imageSrc);
  // }
}

export const FerroImageComponent: IComponentOptions = {
  controller: FerroImageController,
  template: '<img ng-src="{{$ctrl.src}}" alt="{{::$ctrl.imageSrc}}" class="img-responsive">',
  bindings: {
    imageSrc: '@',
    weatherIcon: '@',
    flagIcon: '@'
  }
}
