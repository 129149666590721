import { EChartOption, ECharts } from 'echarts'
import {
  defaultEchartsDataZoom,
  defaultEchartsYAxisPower,
  echartsInitWrapper,
  echartsToolbox,
  echartsTooltip
} from '../../../../lib/echarts'
import { roundTo2Decimals } from '../../../../lib/general'
import { LanguageAnalaysis } from '../../../language/language.interface'
import { ElectricalComponentColors } from '../../../service/color.service'
import { dailyTrendDataVizualizationType } from './daily-trend.service'
import { DailyTrendFixedData, DailyTrendPhaseData, PowerLevelData } from './daily-trend.tools'

function tooltipFormatter(params: any, valuePrefix?: string, customValue?: number | string) {
  return ` ${params.marker} ${params.seriesName}: ${valuePrefix || ''} ${customValue || params.value} [kW]`
}

function createDailyTrendTooltip(type: dailyTrendDataVizualizationType) {
  return (params: any /*ticket*/) => {
    let xAxisValue
    let weekDay: any
    let weekEnd: any
    let weekDayError: any
    let weekEndError: any
    if (params && params.length) {
      params.forEach((param: any) => {
        if (param && param.axisId && param.axisValue) {
          xAxisValue = `${param.axisId.replace('0', '')}: ${param.axisValue}`
        }

        if (param) {
          if (param.seriesIndex === 0) {
            weekDay = param
          } else if (param.seriesIndex === 1) {
            weekEnd = param
          } else if (param.seriesIndex === 2) {
            weekDayError = param
          } else if (param.seriesIndex === 3) {
            weekEndError = param
          }
        }
      })

      let errorPrefix = ''
      let weekEndErrorValue = ''
      let weekDayErrorValue = ''
      if (type === 'std') {
        errorPrefix = '&plusmn;'
        if (weekEndError) {
          const wEEv = weekEndError.value
          weekEndErrorValue = `${roundTo2Decimals(wEEv[2] - wEEv[1])}`
        }
        if (weekDayError)
          weekDayErrorValue = `${roundTo2Decimals(weekDayError.value[2] - weekDayError.value[1])}`
      } else {
        if (weekDayError)
          weekDayErrorValue = `Min: ${roundTo2Decimals(
            weekDayError.value[1]
          )}, Max ${roundTo2Decimals(weekDayError.value[2])})`
        if (weekEndError)
          weekEndErrorValue = `Min: ${roundTo2Decimals(
            weekEndError.value[1]
          )}, Max ${roundTo2Decimals(weekEndError.value[2])})`
      }

      const breaker = '<br>'

      const weekDayString = weekDay ? `${tooltipFormatter(weekDay)}${breaker}` : ''
      const weekEndString = weekEnd ? `${tooltipFormatter(weekEnd)}${breaker}` : ''
      const weekDayErrorString = weekDayError
        ? `${tooltipFormatter(weekDayError, errorPrefix, weekDayErrorValue)}${breaker}`
        : ''
      const weekEndErrorString = weekEndError
        ? `${tooltipFormatter(weekEndError, errorPrefix, weekEndErrorValue)}${breaker}`
        : ''
      return `
          ${xAxisValue}<br>
          ${weekDayString} 
          ${weekEndString}
          ${weekDayErrorString}
          ${weekEndErrorString}
        `
    }
    return ''
  }
}

// Reference: https://github.com/apache/incubator-echarts/issues/4954#issuecomment-713721742
function renderItem(
  errorGroup: number,
  numErrorGroups: number,
  params: EChartOption.SeriesCustom.RenderItemParams,
  api: EChartOption.SeriesCustom.RenderItemApi
) {
  const xValue = api.value(0)
  const highPoint = api.coord([xValue, api.value(1)])
  const lowPoint = api.coord([xValue, api.value(2)])
  const categoryWidth = api.size([1, 0])[0]
  const barGap = categoryWidth * 0.05
  const barWidth = categoryWidth / numErrorGroups - barGap
  highPoint[0] = highPoint[0] - barWidth / 2 + errorGroup * barWidth
  lowPoint[0] = highPoint[0]

  const errorBarWidth = categoryWidth * 0.05

  const style = api.style({
    stroke: api.visual('color'),
    fill: null
  })

  return {
    type: 'group',
    children: [
      {
        type: 'line',
        shape: {
          x1: highPoint[0] - errorBarWidth,
          y1: highPoint[1],
          x2: highPoint[0] + errorBarWidth,
          y2: highPoint[1]
        },
        style: style
      },
      {
        type: 'line',
        shape: {
          x1: highPoint[0],
          y1: highPoint[1],
          x2: lowPoint[0],
          y2: lowPoint[1]
        },
        style: style
      },
      {
        type: 'line',
        shape: {
          x1: lowPoint[0] - errorBarWidth,
          y1: lowPoint[1],
          x2: lowPoint[0] + errorBarWidth,
          y2: lowPoint[1]
        },
        style: style
      }
    ]
  }
}

function createLegendItems(type: dailyTrendDataVizualizationType, l: LanguageAnalaysis) {
  const StdMaxMinMinTitle = type === 'std' ? 'STD' : 'Max Min'

  const ErrorWeekDaysName = `${l.WEEKDAYS} ${StdMaxMinMinTitle}`
  const ErrorWeekEndsName = `${l.WEEKENDS} ${StdMaxMinMinTitle}`
  return {
    ErrorWeekEndsName,
    ErrorWeekDaysName
  }
}

export async function createDailyTrendChart(
  data: DailyTrendFixedData,
  l: LanguageAnalaysis,
  colors: ElectricalComponentColors,
  type: dailyTrendDataVizualizationType
): Promise<ECharts> {
  const charts = await echartsInitWrapper('analyticsGraphArea')
  const legendItems = createLegendItems(type, l)
  const ops: EChartOption = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      },
      formatter: createDailyTrendTooltip(type)
    },
    legend: {
      data: [l.WEEKDAYS, l.WEEKENDS, legendItems.ErrorWeekDaysName, legendItems.ErrorWeekEndsName]
    },
    toolbox: echartsToolbox(),
    dataZoom: defaultEchartsDataZoom,
    xAxis: {
      name: l.HOUR_OF_DAY,
      data: data.main.hours
    },
    yAxis: defaultEchartsYAxisPower(l.POWER),
    series: [
      {
        type: 'bar',
        name: l.WEEKDAYS,
        data: data.main.weekDays,
        itemStyle: {
          color: colors.gridExport.bar
        }
      },
      {
        type: 'bar',
        name: l.WEEKENDS,
        data: data.main.weekEnds,
        itemStyle: {
          color: colors.selfConsumption.bar
        }
      },
      {
        name: legendItems.ErrorWeekDaysName,
        type: 'custom',
        itemStyle: {
          normal: {
            borderWidth: 1.5
          }
        },
        renderItem: (params, api) => renderItem(0, 2, params, api),
        encode: {
          x: 0,
          y: [1, 2]
        },
        data: data.main.weekDaysErrorData,
        z: 100
      },
      {
        type: 'custom',
        name: legendItems.ErrorWeekEndsName,
        itemStyle: {
          normal: {
            borderWidth: 1.5
          }
        },
        renderItem: (params, api) => renderItem(1, 2, params, api),
        encode: {
          x: 0,
          y: [1, 2]
        },
        data: data.main.weekEndsErrorData,
        z: 100
      }
    ]
  }

  charts.setOption(ops)
  return charts
}

export function updateDailyTrendChart(
  chart: ECharts,
  data: DailyTrendFixedData,
  type: dailyTrendDataVizualizationType,
  l: LanguageAnalaysis
): void {
  const legendItems = createLegendItems(type, l)
  const ops: EChartOption = {
    legend: {
      data: [l.WEEKDAYS, l.WEEKENDS, legendItems.ErrorWeekDaysName, legendItems.ErrorWeekEndsName]
    },
    tooltip: {
      formatter: createDailyTrendTooltip(type)
    },

    series: [
      {
        data: data.main.weekDays
      },
      {
        data: data.main.weekEnds
      },
      {
        name: legendItems.ErrorWeekDaysName,
        data: data.main.weekDaysErrorData
      },
      {
        name: legendItems.ErrorWeekEndsName,
        data: data.main.weekEndsErrorData
      }
    ]
  }
  chart.setOption(ops)
}

const convertToAmpere = (data: number[], asAmpere?: boolean): number[] => {
  return asAmpere ? data.map(entry => Math.round((entry * 1000 * 100) / 235) / 100) : data
}
export async function createPerPhaseChart(
  id: string,
  data: DailyTrendPhaseData,
  l: LanguageAnalaysis,
  colors: ElectricalComponentColors,
  asAmpere?: boolean
): Promise<ECharts> {
  const yAxis = defaultEchartsYAxisPower(l.POWER)
  if (asAmpere) {
    yAxis.name = 'A'
    yAxis.axisLabel = { formatter: '{value} A' }
  }

  const chart = await echartsInitWrapper(id)
  const ops: EChartOption = {
    legend: {
      data: [l.PHASE_1, l.PHASE_2, l.PHASE_3]
    },
    tooltip: echartsTooltip(),
    yAxis: yAxis,
    xAxis: {
      name: l.HOUR_OF_DAY,
      data: data.hours
    },
    dataZoom: defaultEchartsDataZoom,

    toolbox: echartsToolbox(),
    series: [
      {
        type: 'bar',
        name: l.PHASE_1,
        data: convertToAmpere(data.phase1, asAmpere),
        itemStyle: {
          color: colors.phase1.bar
        }
      },
      {
        type: 'bar',
        name: l.PHASE_2,
        data: convertToAmpere(data.phase2, asAmpere),
        itemStyle: {
          color: colors.phase2.bar
        }
      },
      {
        type: 'bar',
        name: l.PHASE_3,
        data: convertToAmpere(data.phase3, asAmpere),
        itemStyle: {
          color: colors.phase3.bar
        }
      }
    ]
  }

  chart.setOption(ops)
  return chart
}

export function updatePhaseChart(
  chart: ECharts,
  data: DailyTrendPhaseData,
  l: LanguageAnalaysis,
  asAmpere?: boolean
): void {
  const yAxis = defaultEchartsYAxisPower(l.POWER)
  if (asAmpere) {
    yAxis.name = 'A'
    yAxis.axisLabel = { formatter: '{value} A' }
  }

  chart.setOption({
    yAxis,

    series: [
      { data: convertToAmpere(data.phase1, asAmpere) },
      { data: convertToAmpere(data.phase2, asAmpere) },
      { data: convertToAmpere(data.phase3, asAmpere) }
    ]
  })
}

export async function createPowerLevelsChart(
  id: string,
  data: PowerLevelData,
  l: LanguageAnalaysis,
  colors: ElectricalComponentColors
): Promise<ECharts> {
  const chart = await echartsInitWrapper(id)
  const ops = {
    legend: {
      data: [l.NUMBER_OF_HOURS]
    },
    tooltip: echartsTooltip(),
    toolbox: echartsToolbox(),
    yAxis: {
      name: l.NUMBER_OF_HOURS
    },
    dataZoom: defaultEchartsDataZoom,
    xAxis: {
      data: data.power,
      name: `${l.POWER} [KW]`
    },
    series: [
      {
        type: 'bar',
        name: l.NUMBER_OF_HOURS,
        data: data.hours,
        itemStyle: {
          color: colors.consumption.bar
        }
      }
    ]
  }
  chart.setOption(ops)
  return chart
}

export function updatePowerLevelChart(chart: ECharts, data: PowerLevelData): void {
  chart.setOption({
    xAxis: {
      data: data.power
    },
    series: [
      {
        data: data.hours
      }
    ]
  })
}
