import { IComponentOptions, IScope } from 'angular'

import { FacilityComments } from '../../../../../types'
import { LanguageSystemSettings } from '../../../../language/language.interface'
import {
  FacilityDataHttpService,
  FacilityDataHttpServiceName,
  FerroConfiguration,
  FerroConfigurationName
} from '../../../../service'
import './system-information-comments.component.scss'

import { FacilityCommentPriority } from '@app/graphql/generated'
import { runQuery } from '@app/graphql/graphql'
import templateUrl from './system-information-comments.component.html'

class SystemInformationCommentsController {
  service: FacilityDataHttpService
  facilityId: number
  l: LanguageSystemSettings

  commentError: string
  showaddcomment = false
  comments: Partial<FacilityComments>[]

  newComment: FacilityComments
  $scope: IScope
  constructor(
    facilityDataHttpService: FacilityDataHttpService,
    ferroConfiguration: FerroConfiguration,
    $scope: IScope
  ) {
    this.$scope = $scope
    this.service = facilityDataHttpService
    this.facilityId = Number(ferroConfiguration.facility.id)
    this.l = ferroConfiguration.language.SystemSettings
  }

  async $onInit() {
    await this.loadComments()
  }

  private async loadComments() {
    const response = await runQuery
      .getFacilityCommentsQuery({ variables: { id: this.facilityId } })

      .catch()
    const comments = response?.data?.facility?.comments?.active?.map(comment => {
      const { priority } = comment
      let cssClasses = ''
      if (priority === FacilityCommentPriority.High) cssClasses = 'comment-priority-high'

      const newComment: Partial<FacilityComments> = {
        text: comment.text,
        title: comment.title,
        ts: comment.createdAt,
        email: /ferroamp/g.test(comment?.author?.email) ? 'Ferroamp Support' : comment?.author?.email,
        cssClasses,
        priority: priority
      }

      return newComment
    })

    const highComments = comments.filter(com => com.priority === FacilityCommentPriority.High)
    const notHighComments = comments.filter(com => com.priority !== FacilityCommentPriority.High)

    this.$scope.$applyAsync(() => {
      this.comments = [...highComments, ...notHighComments]
    })
  }

  async newFacilityComments() {
    const response = await runQuery.createOneFacilityCommentMutation({
      variables: {
        facilityId: this.facilityId,
        text: this.newComment.text,
        title: this.newComment.title
      }
    })

    const data = response.data?.createOneFacilityComment
    if (data?.success) {
      this.loadComments()
    } else {
      this.commentError = this.l.ERROR_STORE_COMMENT
    }
  }
}

SystemInformationCommentsController.$inject = [FacilityDataHttpServiceName, FerroConfigurationName, '$scope']

export const SystemInformationCommentsComponent: IComponentOptions = {
  controller: SystemInformationCommentsController,
  templateUrl
}
