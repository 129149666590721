import { IHttpProvider, IQService, IHttpInterceptor } from 'angular'
import { captureSentryException } from './sentry'

const ignoredStatusCodes = [404, 401]

const correlationIdInterceptor = ($q: IQService): IHttpInterceptor => {
  return {
    responseError: rejection => {
      const headers = rejection.headers()
      const correlationId: string = headers['x-correlation-id'] || 'Missing'
      const shouldReportRejection = !ignoredStatusCodes.includes(rejection.status)
      const path = rejection.config.url || 'Missing'

      if (correlationId && shouldReportRejection) {
        captureSentryException(new Error(`Request failed: ${path}`), {
          extra: { correlationId, path }
        })
      }

      return $q.reject(rejection)
    }
  }
}

export const httpConfig = ($httpProvider: IHttpProvider) => {
  $httpProvider.interceptors.push(['$q', correlationIdInterceptor])
}

httpConfig.$inject = ['$httpProvider']
