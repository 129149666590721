import { DateRangeQuery } from '@app/graphql/generated'
import { PowerShareEnergyObject, fixNewEnergyData } from './powershare-dashboard/parse-energycounters'
import moment from 'moment'
import { runQuery } from '../graphql/graphql'

export const getPowershareEnergyCounters = async (facilityId: number): Promise<PowerShareEnergyObject> => {
  const currentYear: DateRangeQuery = {
    gte: moment().utc().startOf('year').toDate(),
    lt: moment().utc().endOf('year').add(1, 'days').startOf('year').toDate()
  }

  const res = await runQuery.getPowershareDashboardEnergycountersQuery({
    variables: {
      currentYear,
      facilityId
    }
  })

  const [totalProduction] = res?.data?.facility?.measurements?.totalProduction || []
  return fixNewEnergyData(totalProduction)
}
