import { runQuery } from '@app/graphql/graphql'
import { FeatureFlagService, FeatureFlagServiceName } from '@app/service/feature-flag.service'
import {
  HookResult,
  Ng1StateDeclaration,
  StateParams,
  StateProvider,
  Transition,
  TransitionService
} from '@uirouter/angularjs'
import { TargetState } from '@uirouter/core/lib/state/targetState'
import { IQService } from 'angular'
import { SystemListState } from '../app.routing'
import { captureSentryException } from '../config/sentry'
import { FerroConfiguration } from '../service'
import { SystemDashboardComponentName } from './dashboard'
import { SystemSidebarComponentName } from './system-sidebar.component'
import { SystemComponentName } from './system.component'
import {
  EnergyViewComponentName,
  LiveDataViewComponentName,
  PowerViewComponentName,
  SsoViewComponentName
} from './views'

export interface SystemStateParamsInterface {
  id: number
}

export const SystemStates = {
  main: 'system',
  dashboard: 'system.dashboard',

  view: 'system.view',
  powerView: 'system.view.powerView',
  energyView: 'system.view.energyView',
  liveData: 'system.view.liveData',
  ssoView: 'system.view.ssoView',
  esoView: 'system.view.esoView',
  ancillaryServiceView: 'system.view.ancillaryServiceView'
}

function enterSystemTransitionsHook(transition: Transition): HookResult {
  const ferroConfiguration = transition.injector().get('ferroConfiguration') as FerroConfiguration
  const featureFlagService = transition.injector().get(FeatureFlagServiceName) as FeatureFlagService
  const stateParams = transition.injector().get('$stateParams') as StateParams
  const facilityId = stateParams.id
  const $q = transition.injector().get('$q') as IQService
  const defer = $q.defer<TargetState>()
  const state = transition.router.stateService
  // const systemStateParams: SystemStateParamsInterface = {id: facilityId};
  const promisedFeatureFlags = featureFlagService.start(facilityId)

  const systemListTarget = state.target(SystemListState, {
    error: `Error transition to facility ${facilityId}`
  })
  // const systemInformationTarget = state.target(systemSettingsStates.systemInformation, systemStateParams)

  // const systemDashboardTarget = state.target(SystemStates.dashboard);
  const facility = ferroConfiguration.facility
  if (facility && Number(facility.id) === facilityId) {
    return defer.resolve()
  } else if (!isNaN(facilityId)) {
    runQuery
      .getOneFacilityQuery({
        variables: {
          facilityId: Number(facilityId)
        }
      })
      .then(response => {
        if (response?.data?.facility?.id) {
          ferroConfiguration.setFacility(response.data.facility)
          defer.resolve()
        } else {
          defer.resolve(systemListTarget as any)
        }
      })
      .catch(_error => {
        console.error(_error)
        defer.resolve(systemListTarget as any)
      })
  } else {
    captureSentryException(
      new Error(`
            Undefined transitions to facility ${facilityId}, which makes impossible to transitions to facility.
            Check asap!
        `)
    )
    defer.resolve(systemListTarget as any)
  }

  return Promise.all([promisedFeatureFlags, defer.promise as Promise<TargetState>]).then(
    ([, target]) => target
  ) as any
}

const SystemState: Ng1StateDeclaration = {
  url: '/system?id',
  views: {
    sidenav: SystemSidebarComponentName,
    main: SystemComponentName
  },
  redirectTo: SystemStates.dashboard
}

export function systemRoutingConfig(
  $stateProvider: StateProvider,
  $transitionsProvider: TransitionService
): void {
  $transitionsProvider.onBefore({ to: `${SystemStates.main}.**` }, enterSystemTransitionsHook)

  $stateProvider.state(SystemStates.main, SystemState).state(SystemStates.dashboard, {
    url: '/dashboard',
    component: SystemDashboardComponentName
  })

  $stateProvider
    .state(SystemStates.view, {
      url: '/view',
      redirectTo: SystemStates.powerView
    })
    .state(SystemStates.powerView, {
      url: '/power',
      component: PowerViewComponentName
    })
    .state(SystemStates.energyView, {
      url: '/energy',
      component: EnergyViewComponentName
    })
    .state(SystemStates.liveData, {
      url: '/live-data',
      component: LiveDataViewComponentName
    })
    .state(SystemStates.ssoView, {
      url: '/sso',
      component: SsoViewComponentName
    })
}

systemRoutingConfig.$inject = ['$stateProvider', '$transitionsProvider']
