import angular from 'angular'
import { emsConfigFormComponent, EmsConfigMode, EmsConfigV2Component } from './ems-config'
import {
  EmsConfigScheduleComponent,
  EmsConfigScheduleComponentName
} from './ems-config-scheduler/ems-config-schedule.component'
import {
  SystemConfigurationComponent,
  SystemConfigurationComponentName
} from './system-configuration.component'
import { SystemControlTopologyComponent } from './system-control-topology/system-control-topology.component'
import { SystemStateV2Component, SystemStateV2ServiceName, SystemStateV2Service } from './system-state'
// import { emsConfigSchedulePickerComponentName, emsConfigSchedulePickerComponent } from './ems-config-scheduler/ems-config-schedule-picker.component';
import { EmsConfigSchedulingOpener } from './ems-config-scheduler/ems-config-scheduler.button'
import { CalendarViewComponent } from './ems-config-scheduler/calendar-view.component'
import {
  EmsSchedulePickerComponent,
  EmsSchedulePickerComponentName
} from './ems-config-scheduler/ems-config-schedule-picker.component'
import { SpotPriceOptionsComponent } from './spot-price/spotprice'

export * from './system-configuration.component'
export * from './ems-config/ems-config.component'
export * from './system-state/system-state.component'
export * from './system-state/system-state.service'
export { EmsConfigMode }

export const AppSystemSettingsConfigurationModule =
  // .component(emsConfigSchedulePickerComponentName, emsConfigSchedulePickerComponent)
  angular
    .module('app.system.settings.configuration', [])
    .component('emsConfig', EmsConfigV2Component)
    .component('emsConfigForm', emsConfigFormComponent)
    .component('systemState', SystemStateV2Component)
    .component('systemControlTopology', SystemControlTopologyComponent)
    .component('schedulingButton', EmsConfigSchedulingOpener)
    .component('calendarView', CalendarViewComponent)
    .component('spotPriceOptions', SpotPriceOptionsComponent)
    .component(EmsConfigScheduleComponentName, EmsConfigScheduleComponent)
    .service(SystemStateV2ServiceName, SystemStateV2Service)
    .component(SystemConfigurationComponentName, SystemConfigurationComponent)
    .component(EmsSchedulePickerComponentName, EmsSchedulePickerComponent).name
