import { material } from 'angular'
import { MappedEnergyhubList } from './system-state.service'
import { FerroConfiguration } from '../../../../service'
import { LanguageSystemSettings } from '../../../../language/language.interface'

export class EhubViewerController {
  d: material.IDialogService
  list: MappedEnergyhubList[]
  l: LanguageSystemSettings

  constructor(
    $mdDialog: material.IDialogService,
    ehubList: MappedEnergyhubList[],
    ferroConfiguration: FerroConfiguration
  ) {
    this.d = $mdDialog
    this.list = ehubList
    this.l = ferroConfiguration.language.SystemSettings
  }

  close(): void {
    this.d.hide()
  }
}
