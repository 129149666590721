import { DEFAULT_TIMEZONE } from '@environments/environment'
import ICAL from 'ical.js'
import { RRule, Weekday } from 'rrule'

import moment from 'moment-timezone'

export interface IcalParsed {
  rules?: RRule
  event: ICAL.Event
  // For how long is the event
  eventDurationMs: number
  dtstart: Date
  dtend: Date
}

export const iCalParser = (icalendar: string): IcalParsed => {
  const jcal = ICAL.parse(`BEGIN:VCALENDAR\r
${icalendar}
END:VCALENDAR\r`)
  const component = new ICAL.Component(jcal)
  const vevent = component.getFirstSubcomponent('vevent')
  const event = new ICAL.Event(vevent)
  const rrules = event.component.getAllProperties('rrule')
  const dtstart = vevent.getFirstPropertyValue('dtstart')
  const dtend = vevent.getFirstPropertyValue('dtend')
  const eventDurationMs = (event.endDate.toUnixTime() - event.startDate.toUnixTime()) * 1000
  let rules
  if (rrules && rrules?.length) rules = RRule.fromString(rrules[0].toICALString())
  return {
    event,
    rules,
    eventDurationMs,
    dtstart: new Date(dtstart.toString()),
    dtend: new Date(dtend.toString())
  }
}

export const createRRule = (timezone: string, byday: Weekday[]): string => {
  const rule = new RRule({
    freq: RRule.WEEKLY,
    byweekday: byday
  })
  return rule.toString()
}

export type CreateVCalendarInputs = {
  title: string
  description: string
  start: Date
  end: Date
  days: Weekday[]
  timezone: string
  hasRecurrance: boolean
  showStart: string | null
}

const checkForBase64 = (value: IcalParsed) => {
  const description = value.event.description
  try {
    const fromBase64 = atob(description)
    // Check if acctually is base64 encoded
    if (btoa(fromBase64) === description) {
      value.event.description = atob(description)
    }
  } catch {
    //
  }
  return value
}

export const toVCalendarInputs = (vCalendar: string): IcalParsed => {
  const parsed = iCalParser(vCalendar)

  return checkForBase64(parsed)
}

export const createVCalendar = (options: CreateVCalendarInputs): string => {
  const { title, description, start, end, days, timezone } = options

  const tz = timezone || DEFAULT_TIMEZONE
  const rule = new RRule({
    freq: RRule.WEEKLY,
    byweekday: days
  }).toString()

  const event = `BEGIN:VEVENT\r
SUMMARY:${title}\r
DTSTART;TZID=${timezone};VALUE=DATE-TIME:${moment.tz(start, tz).format('YYYYMMDDTHHmm00')}\r
DTEND;TZID=${timezone};VALUE=DATE-TIME:${moment.tz(end, tz).format('YYYYMMDDTHHmm00')}\r
DESCRIPTION:${description.replace(/\n/g, '\\n')}\r
${options.hasRecurrance ? rule.replace(/\s/g, '') : ''}\r
END:VEVENT\r`
  return event
}

// 'BEGIN:VCALENDAR\r
// VERSION:2.0\r
// CALSCALE:GREGORIAN\r
// PRODID:ECLOUD\r
// METHOD:PUBLISH\r
// X-PUBLISHED-TTL:PT1H\r
// BEGIN:VEVENT\r
// UID:JHEKPUzNzkWO_Ynt1LjMn\r
// SUMMARY:Dinner\r
// DTSTAMP:20211213T143800Z\r
// DTSTART:20211213T133800Z\r
// DTEND:20211213T133800Z\r
// DESCRIPTION:Nightly thing I do\r
// X-MICROSOFT-CDO-BUSYSTATUS:FREE\r
// RRULE:DTSTART;TZID=Europe/Stockholm:20211213T143804
// RRULE:FREQ=WEEKLY;BYDAY=MO,TU;UNTIL=20211213T143804\r
// END:VEVENT\r
// END:VCALENDAR\r
// '
