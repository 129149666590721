import { isReadAllFacilityAdmin, isWriteAllFacilityAdmin } from '@app/auth/roles'
import { FerroConfiguration, FerroConfigurationName } from './ferro-configuration.service'

export const RolesServicesName = 'rolesService'
export class RolesServices {
  constructor(private config: FerroConfiguration) {}

  canViewFacilitySystemSettings(): boolean {
    return this.config.facility?.users?.length > 0 || isReadAllFacilityAdmin()
  }

  canUpdateFacilitySystemSettings(): boolean {
    return this.config.facility?.users?.length > 0 || isWriteAllFacilityAdmin()
  }
}

RolesServices.$inject = [FerroConfigurationName]
