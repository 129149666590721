import * as Sentry from '@sentry/browser'
import { ScopeContext } from '@sentry/types'
import { Environment } from '../../environments/environment'

export const InitateSentry = (): void => {
  const dns = 'https://d7b12738faf54721aa6a5f80ac8cf252@o405551.ingest.sentry.io/5271505'
  if (DEVELOPMENT) return

  Sentry.init({
    dsn: dns,
    release: Environment.release,
    environment: Environment.environment,
    debug: !!DEVELOPMENT,
    sampleRate: 0.01,
    maxBreadcrumbs: 1
  })

  Sentry.setUser({
    id: String(Environment.sub),
    userLanguage: Environment.language
  })
}

export const captureSentryException = (error: Error | any, context?: Partial<ScopeContext>): void => {
  if (DEVELOPMENT) {
    // eslint-disable-next-line no-console
    console.error(`captureSentryException`, { error, context })
  } else {
    Sentry.captureException(error, context)
  }
}
