import { languagesAvailable } from '@app/service/language.service'
import { DEFAULT_TIMEZONE } from '@environments/environment'
import moment, { Moment } from 'moment-timezone'

export const checkEndDate = (inStart: Moment | Date, inEnd: Moment | Date, timezone: string): Date => {
  const end = moment.tz(inEnd, timezone || DEFAULT_TIMEZONE)
  const start = moment.tz(inStart, timezone || DEFAULT_TIMEZONE)
  const endHourMinte = end.minutes() + end.hour() * 60
  const startHourMinute = start.minutes() + start.hour() * 60
  if (endHourMinte > startHourMinute && end.isAfter(start, 'dates')) {
    return moment(end).subtract(1, 'day').toDate()
  } // If the end-date is less than start, we assume that it is ending the next day.
  else if (start.isAfter(end)) {
    return moment(end).add(1, 'day').toDate()
  } else {
    return end.toDate()
  }
}

export const resolveSupportLink = (language: languagesAvailable): string => {
  const links = {
    se: 'https://support.ferroamp.com/sv-SE/support/solutions/articles/47001257187',
    us: 'https://support.ferroamp.com/en/support/solutions/articles/47001257187'
  }

  return links[language]
}
