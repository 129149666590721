import { ECharts, EChartsLoadingOption, EChartTitleOption } from 'echarts'
import { captureSentryException } from '../../app/config/sentry'
import { ResizeObserver } from '@juggle/resize-observer'
import { getFerroConfiguration } from '../general/get-ferroconfiguration'

const resizeObserverStorer: { [_id: string]: ResizeObserver } = {}

export function echartsOnResize(chart: ECharts): void {
  try {
    const resizeObserver = new ResizeObserver(() => {
      chart.resize()
    })
    /**
     * Store to be used by {@link clearEchartsInstance}
     */
    resizeObserverStorer[(chart as unknown as { id: string }).id] = resizeObserver
    resizeObserver.observe(chart.getDom())
  } catch (e) {
    captureSentryException(e)
  }
}

export function echartsNoDataTitleOptions(noData: string): EChartTitleOption {
  return {
    show: true,
    textStyle: {
      color: 'grey',
      fontSize: 20
    },
    text: noData,
    left: 'center',
    top: 'center'
  }
}

export const EchartsShowLoadingType = 'default'

export function echartsShowLoadingOptions(): EChartsLoadingOption {
  const fc = getFerroConfiguration()
  return {
    text: 'loading',
    color: fc.themeColor.primary.base,
    textColor: '#000',
    maskColor: 'rgba(255, 255, 255, 0.8)',
    zlevel: 0,

    // Font size. Available since `v4.8.0`.
    fontSize: 12,
    // Show an animated "spinner" or not. Available since `v4.8.0`.
    showSpinner: true,
    // Radius of the "spinner". Available since `v4.8.0`.
    spinnerRadius: 10,
    // Line width of the "spinner". Available since `v4.8.0`.
    lineWidth: 5
  }
}

/**
 * https://medium.com/@kelvinau4413/memory-leak-from-echarts-occurs-if-not-properly-disposed-7050c5d93028
 */
export function clearEchartsInstance(chart: ECharts): void {
  if (chart) {
    const resizeObserver = resizeObserverStorer[(chart as unknown as { id: string }).id]
    if (resizeObserver) resizeObserver.disconnect()
    chart.dispose()
  }
  chart = null
}
