import { IHttpResponse, IPromise } from 'angular'
import { DEFAULT_TIMEZONE } from '../../../../environments/environment'
import { PowerViewService, PowerViewServiceName, processMainAggregateDataType } from '../../views/power-view'
import { serviceClb } from '../../../service/types.service'
import { EnergyViewService, EnergyViewServiceName } from '../../views/energy-view'
import { ProcessedEchartEnergyData } from '../../views/energy-view/energy-view.tools'

export type batterySimulationPowerResolution = 'minute' | 'second'
export type batterySimulationEnergyResolution = 'equartz' | 'hour'
type FacilityId = number
type FacilityTimezone = string
export const BatterySimulationServiceName = 'batterySimulationServiceName'

export class BatterySimulationService {
  facilityId: FacilityId
  canceller: IPromise<unknown>
  timezone = DEFAULT_TIMEZONE
  initialized = false

  pService: PowerViewService
  eService: EnergyViewService

  constructor(powerViewService: PowerViewService, energyViewService: EnergyViewService) {
    this.pService = powerViewService
    this.eService = energyViewService
  }

  init(facilityId: FacilityId, timezone: FacilityTimezone, canceller: IPromise<unknown>): void {
    this.initialized = true
    this.facilityId = facilityId
    this.timezone = timezone ? timezone : DEFAULT_TIMEZONE
    this.canceller = canceller
    this.pService.init(facilityId, canceller)
    this.eService.init(facilityId, timezone, canceller)
  }

  async getDataPower(
    starttime: Date,
    endtime: Date,
    resolution: batterySimulationPowerResolution
  ): Promise<processMainAggregateDataType> {
    if (!this.initialized) throw new Error('Must initialize batterySimService before use')
    const timeDifference = endtime.getTime() - starttime.getTime()
    if (resolution === 'second') {
      if (timeDifference > 24 * 3600 * 1000) {
        throw new Error('Cannot request more than 24 hours for 1 second resolution')
      }
      return this.pService.getAggregateData(starttime, endtime, 1)
    } else if (resolution === 'minute') {
      if (timeDifference > 7 * 24 * 3600 * 1000) {
        throw new Error('Cannot request more than 7 Days for 1 minute resolution')
      }
      return this.pService.getAggregateData(starttime, endtime, 60)
    } else {
      throw new Error('Invalid Resolution')
    }
  }

  getDataEnergy(
    starttime: Date,
    endtime: Date,
    resolution: batterySimulationEnergyResolution,
    callback: serviceClb<IHttpResponse<string>, ProcessedEchartEnergyData>
  ): void {
    if (!this.initialized) throw new Error('Must initialize batterySimService before use')
    if (resolution === 'equartz') {
      this.eService.getEquartz(starttime, endtime, callback)
    } else if (resolution === 'hour') {
      this.eService.getDataHourly(starttime, endtime, callback)
    } else {
      throw new Error('Invalid resolution')
    }
  }
}

BatterySimulationService.$inject = [PowerViewServiceName, EnergyViewServiceName]
