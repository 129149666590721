import { GetBessesStateofChargeQuery } from '@app/graphql/generated'
import { EChartOption } from 'echarts'
import {
  defaultEchartsDataZoom,
  defaultEchartsGrid,
  defaultEchartsYAxisPower,
  echartsToolbox,
  echartsTooltip,
  isMobile,
  tooltipDateFormatter
} from '../../../../lib'
import { LanguageViews } from '../../../language/language.interface'
import { ElectricalComponentColors } from '../../../service'
import {
  POWER_DATA_DIMENSIONS,
  powerViewTitleAndLabels,
  PROCESSED_BATTERY_ENERGY_DATA_DIMENSIONS
} from './power-view.tools'

const type = 'line'

function createMainSeries(labels: powerViewTitleAndLabels) {
  const series: EChartOption.Series[] = [
    {
      type,
      name: labels.main.l[0],
      areaStyle: { opacity: 0.2 },
      smooth: true,
      showSymbol: false,
      connectNulls: false,
      symbol: 'none',
      encode: {
        x: 'ts',
        y: 'grid'
      }
    },
    {
      type,
      name: labels.main.l[1],
      areaStyle: { opacity: 0.2 },
      symbol: 'none',
      smooth: true,

      showSymbol: false,
      connectNulls: false,
      encode: {
        x: 'ts',
        y: 'load'
      }
    },
    {
      type,
      name: labels.main.l[2],
      areaStyle: { opacity: 0.2 },
      symbol: 'none',
      showSymbol: false,
      smooth: true,
      connectNulls: false,

      encode: {
        x: 'ts',
        y: 'solar'
      }
    },
    {
      type,
      name: labels.main.l[3],
      areaStyle: { opacity: 0.2 },
      connectNulls: false,
      smooth: true,
      showSymbol: false,
      symbol: 'none',
      encode: {
        x: 'ts',
        y: 'battery'
      }
    }
  ]
  return series
}

export function createPowerViewChart(
  labels: powerViewTitleAndLabels,
  colors: ElectricalComponentColors,
  l: LanguageViews,
  timezone: string,
  resolution?: 'seconds' | 'minutes'
): EChartOption {
  const tooltip = echartsTooltip()

  /**
   * DataZoom fix. Fixes for PCRI-218
   * We need this weird thing, due get the values from dataZoom on onZoomCallback
   */
  const dataZoom = isMobile()
    ? defaultEchartsDataZoom
    : ({
        xAxisIndex: 0,
        show: false
      } as unknown as EChartOption.DataZoom[])

  tooltip.formatter = tooltipDateFormatter(resolution ? resolution : 'minutes', timezone)
  const ops: EChartOption = {
    toolbox: echartsToolbox(),
    tooltip: tooltip,
    color: [colors.grid.line, colors.consumption.line, colors.solar.line, colors.battery.line],
    legend: {
      data: labels.main.l
    },
    dataset: {
      source: [],
      dimensions: POWER_DATA_DIMENSIONS
    },
    dataZoom: dataZoom,
    yAxis: defaultEchartsYAxisPower(l.POWER),
    xAxis: [
      {
        type: 'time',
        name: l.DATE_AND_TIME
      }
    ],
    series: createMainSeries(labels)
  }

  return ops
}

//
// export function updatePowerViewChart(){
//     if (['ed', 'eq', 'er', 'id', 'iq', 'ir', 'u'].indexOf(this.choosenValue) + 1) {
//         clr = [colors.phase1, colors.phase2, colors.phase3, colors.neutral]
//     } else {
//
//     }
//
//
//
//     if (this.mainGraph.graph) {
//         this.mainGraph.update(
//             {
//                 "file": data,
//                 "labels": this.typeOfGraph[this.choosenValue].l,
//                 "yLabel": isMobile() ? null : this.typeOfGraph[this.choosenValue].y,
//                 "xLabel": this.l.DATE_AND_TIME,
//                 "fillGraph": this.choosenValue === 'main',
//                 "colors": clr,
//                 "yFormatter": this.choosenValue === 'main' ? null : "default",
//                 "showRangeSelector": isMobile(),
//                 "_onZoomCallback": this._onZoomCallback,
//                 "_onDrawCallback": this._onDrawCallback,
//                 "dateWindow": dateWindow ? dateWindow : null
//             });
//     } else {
//         this.mainGraph.setUp(data,
//             {
//                 "labels": this.typeOfGraph[this.choosenValue].l,
//                 "yLabel": isMobile() ? null : this.typeOfGraph[this.choosenValue].y,
//                 "xLabel": this.l.DATE_AND_TIME,
//                 "fillGraph": this.choosenValue === 'main',
//                 "colors": clr,
//                 "yFormatter": this.choosenValue === 'main' ? null : "default",
//                 "showRangeSelector": isMobile(),
//                 "_onZoomCallback": this._onZoomCallback,
//                 "_onDrawCallback": this._onDrawCallback,
//                 "includeZero": this.choosenValue !== 'udc'
//             });
//     }
// }

const coeff = 1000 * 60
const toNearestMinute = (date: string) => {
  return new Date(Math.round(new Date(date).getTime() / coeff) * coeff)
}

export const batteryDataToDataset = (
  data: GetBessesStateofChargeQuery['facility']
): EChartOption.Dataset[] => {
  return data?.batteries
    ?.filter(bat => bat?.log?.history?.length)
    .map(({ productId, log }) => {
      return {
        id: productId,
        name: productId,
        source: log.history.map(({ stateOfCharge, updatedAt }) => [
          toNearestMinute(updatedAt),
          stateOfCharge
        ]),
        dimensions: PROCESSED_BATTERY_ENERGY_DATA_DIMENSIONS
      }
    })
}

export function batteryYAxis(type: 'percent' | 'kwh', l: LanguageViews): EChartOption.YAxis {
  if (type === 'percent') {
    return {
      type: 'value',
      name: `${l.STATE_OF_CHARGE} [%]`,
      axisLabel: { formatter: '{value} %' },
      min: 0
    }
  } else {
    return {
      type: 'value',
      name: `${l.STATE_OF_CHARGE} [kWh]`,
      axisLabel: { formatter: '{value} kWh' },
      min: 0
    }
  }
}

export const createSeries = (data: GetBessesStateofChargeQuery['facility']): EChartOption.Series[] => {
  return data?.batteries
    ?.filter(bat => bat?.log?.history?.length)
    .map(({ productId }, index) => ({
      id: productId,
      name: productId,
      type: 'line',
      showSymbol: false,
      datasetIndex: index,
      encode: {
        x: 'ts',
        y: 'socs'
      }
    }))
}

export function createPowerViewBatteryChart(
  data: GetBessesStateofChargeQuery['facility'],
  colors: string[],
  l: LanguageViews,
  timezone: string,
  type: 'percent' | 'kwh'
): EChartOption {
  const tooltip = echartsTooltip()

  tooltip.formatter = tooltipDateFormatter('minutes', timezone)
  const ops: EChartOption = {
    toolbox: echartsToolbox(),
    tooltip: tooltip,
    grid: defaultEchartsGrid,
    color: colors,
    legend: {
      data: data?.batteries?.filter(bat => bat?.log?.history?.length)?.map(({ productId }) => productId)
    },
    dataZoom: defaultEchartsDataZoom,
    dataset: batteryDataToDataset(data),

    yAxis: batteryYAxis(type, l),
    xAxis: [
      {
        type: 'time',
        name: l.DATE_AND_TIME
      }
    ],
    series: createSeries(data)
  }
  return ops
}
