import './graph-dialog.scss'

import graphDialogTemplateUrl from './graph.popup.dialog.html'
import { IScope } from 'angular'

export { graphDialogTemplateUrl }

export class GraphDialogControllerTemplate {
  ready = false
  error = ''
  dialogTitle: string

  graphTitle1: string
  graphOneExists: boolean
  graphOneId = 'graphArea1'
  graphTitle2: string
  graphTwoExists: boolean
  graphTwoId = 'graphArea2'
  graphThreeExists: boolean
  graphTitle3: string
  graphThreeId = 'graphArea3'

  dialog: angular.material.IDialogService
  $s: IScope

  constructor($mdDialog: angular.material.IDialogService, $scope: IScope) {
    this.dialog = $mdDialog
    this.$s = $scope
  }

  stopLoading(): void {
    this.$s.$applyAsync(() => {
      this.ready = true
    })
  }
}
