import angular from 'angular'
import { PowerDataGraphqlService, PowerDataGraphqlServiceName } from './power-data-graphql.service'
import { UidataGraphqlService, UidataGraphqlServiceName } from './uidata-graphql.service'
import { GraphqlPowerDataRetentionsService } from './retention.queries'
import { GraphqlDashboardServiceName, GraphqlDashboardService } from './graphql-dashboard.service'
import {
  SystemConfigurationGraphqlServiceName,
  SystemConfigurationGraphqlService
} from './system-configuration'

import {
  GraphqlEnergyCounterServiceName,
  GraphqlEnergyCounterService
} from './graphql-energycounters.service'
import { FeatureService, FeatureServiceName } from './features.service'
export * from './lib'
export { UidataGraphqlServiceName, UidataGraphqlService }
export { PowerDataGraphqlServiceName, PowerDataGraphqlService }
export { GraphqlDashboardServiceName, GraphqlDashboardService }
export { SystemConfigurationGraphqlServiceName, SystemConfigurationGraphqlService }

export { GraphqlEnergyCounterServiceName, GraphqlEnergyCounterService }

export const AppGraphQl = angular
  .module('app.graphql', [])
  .service(PowerDataGraphqlServiceName, PowerDataGraphqlService)
  .service(UidataGraphqlServiceName, UidataGraphqlService)
  .service('graphqlPowerDataRetentionsService', GraphqlPowerDataRetentionsService)
  .service(GraphqlDashboardServiceName, GraphqlDashboardService)
  .service(GraphqlEnergyCounterServiceName, GraphqlEnergyCounterService)
  .service(SystemConfigurationGraphqlServiceName, SystemConfigurationGraphqlService)
  .service(FeatureServiceName, FeatureService).name
