import { GetOneFacilityQuery } from '@app/graphql/generated'
import { SystemStateCurrentStateResponse } from '@app/service/system-state-v2-http.service'
import { EmsParam } from '@ferroamp/system-configuration-lib'
import { StateService } from '@uirouter/angularjs'
import { IComponentOptions, IHttpResponse, IScope } from 'angular'
import { echartsTimeFormatters } from '../../../../lib'
import { versionParser } from '../../../../lib/general/version-parser'
import { captureSentryException } from '../../../config/sentry'
import { LanguageSystemdashboard } from '../../../language/language.interface'
import {
  EmsConfigV2HttpServiceName,
  FacilityTopologyHttpV1Service,
  FacilityTopologyHttpV1ServiceName,
  FerroConfiguration,
  FerroConfigurationName,
  SystemStateV2HttpService,
  SystemStateV2HttpServiceName
} from '../../../service'
import {
  EmsConfigCollectionDocuments,
  EmsConfigV2HttpService
} from '../../../service/ems-config-v2-http.service'
import {
  EmsConfigMode,
  ParsedSystemState,
  SystemStateV2Service,
  SystemStateV2ServiceName
} from '../../settings/system-configuration'
import {
  SystemConfigParsed,
  SystemDashboardService,
  SystemDashboardServiceName
} from '../system-dashboard.service'
import templateUrl from './system-dashboard-information.component.html'
import './system-dashboard-information.component.scss'

function getEnabledDisabledOptions(l: LanguageSystemdashboard, mode: 0 | 1, threshold?: number) {
  if (mode === 1) {
    return {
      icon: 'check_circle',
      tooltip: l.ENABLED,
      class: 'md-primary',
      threshold
    }
  } else if (mode === 0) {
    return {
      icon: 'cancel',
      tooltip: l.DISABLED,
      class: 'md-disabled',
      threshold
    }
  }
}

class SystemDashboardInformationController {
  hasEms2 = true
  facility: GetOneFacilityQuery['facility']
  l: LanguageSystemdashboard
  sv2: {
    emsMode: string
    ems: EmsParam
    sysState: ParsedSystemState
    ace: {
      threshold: number
      class: string
      icon: string
      tooltip: string
    }
    pv: {
      class: string
      icon: string
      tooltip: string
    }
  }


  opsSettings: SystemConfigParsed

  emsv2Found = false
  sysStateV2Found = false
  sysStateTooltip = ''
  private dService: SystemDashboardService
  private fcService: FerroConfiguration
  private emsHttpService: EmsConfigV2HttpService
  private sysService: SystemStateV2HttpService
  private ssService: SystemStateV2Service
  private fsS: FacilityTopologyHttpV1Service
  private $state: StateService
  private $s: IScope

  VERSION: string

  constructor(
    ferroConfiguration: FerroConfiguration,
    systemStateV2: SystemStateV2HttpService,
    emsConfigV2: EmsConfigV2HttpService,
    dService: SystemDashboardService,
    $state: StateService,
    systemStateService: SystemStateV2Service,
    $scope: IScope,
    topologyService: FacilityTopologyHttpV1Service
  ) {
    this.ssService = systemStateService
    this.dService = dService
    this.$s = $scope
    this.$state = $state
    this.fcService = ferroConfiguration
    this.emsHttpService = emsConfigV2
    this.sysService = systemStateV2
    this.fsS = topologyService

    this.facility = ferroConfiguration.facility
    this.l = ferroConfiguration.language.SystemDashboard
    if (this.fcService.facilityHasEms2()) {
      this.hasEms2 = true
    }
  }

  async $onInit(): Promise<void> {
    this.VERSION = versionParser(this.facility?.info?.version).version
    const sysState = await this.sysService.getCurrentSystemState(Number(this.facility.id)).catch(error => {
      captureSentryException(error)
    })
    const emsConfig = (await this.emsHttpService
      .getCurrentEmsConfig(Number(this.facility.id))
      .catch(error => {
        captureSentryException(error)
      })) as IHttpResponse<EmsConfigCollectionDocuments>

    this.$s.$applyAsync(() => {
      this.emsv2Found = emsConfig?.status !== 204
      try {
        let pv = {
          class: 'md-warn',
          tooltip: this.l.UNAVAILABLE,
          icon: 'cross'
        }
        let sysStatDisplay = {
          mode: this.l.UNAVAILABLE,
          cssClass: 'fe-warn'
        }

        let ace = {
          threshold: 0,
          tooltip: this.l.UNAVAILABLE,
          class: 'mw-warn',
          icon: 'cross'
        }

        if (sysState && sysState.status === 204) {
          //
        } else {
          const state = (sysState as IHttpResponse<SystemStateCurrentStateResponse>)
            .data as SystemStateCurrentStateResponse
          sysStatDisplay = this.ssService.currentStateToString(state.currentState)
          this.sysStateTooltip = echartsTimeFormatters(this.facility.timezone).seconds(state.updatedAt)
          this.sysStateV2Found = true
        }

        let emsOperationSettingsMode = this.l.UNAVAILABLE
        if (emsConfig.data && emsConfig.data.emsConfig) {
          const ems = emsConfig.data.emsConfig.data
          const mode = ems.mode
          EmsConfigMode.forEach(m => {
            if (mode === m.mode) {
              emsOperationSettingsMode = m.name
            }
          })

          pv = getEnabledDisabledOptions(this.l, ems.pv.mode)
          const aceOptions = ems.grid.ace
          ace = getEnabledDisabledOptions(this.l, aceOptions.mode, aceOptions.threshold)

          this.sv2 = {
            emsMode: emsOperationSettingsMode,
            ems: emsConfig.data.emsConfig.data,
            sysState: sysStatDisplay,
            pv: pv,
            ace
          }
        }
      } catch (e) {
        captureSentryException(new Error(e))
        this.emsv2Found = false
        this.sysStateV2Found = false
      }
    })
  }
}

SystemDashboardInformationController.$inject = [
  FerroConfigurationName,
  SystemStateV2HttpServiceName,
  EmsConfigV2HttpServiceName,
  SystemDashboardServiceName,
  '$state',
  SystemStateV2ServiceName,
  '$scope',
  FacilityTopologyHttpV1ServiceName
]

export const SystemDashboardInformationComponent: IComponentOptions = {
  controller: SystemDashboardInformationController,
  templateUrl
}
