import { MILLISECONDS, roundTo2Decimals, SQRT2, tooOneDecimal } from './math'
import { getFerroConfiguration } from './get-ferroconfiguration'

const __KWH = 1000
const __MWH = __KWH * __KWH
const __GWH = __MWH * __KWH
const __TWH = __GWH * __KWH

export function power2String(d: number): string {
  const m = MILLISECONDS * MILLISECONDS
  const M = m * MILLISECONDS
  const G = M * MILLISECONDS

  let value: number | string
  let suffix = ''

  if (isNaN(d)) {
    return '0 W'
  } else if (d < MILLISECONDS) {
    value = d
    suffix = 'W'
  } else if (d > MILLISECONDS && d < m) {
    value = d / MILLISECONDS
    suffix = 'kW'
  } else if (d > m && d < M) {
    value = d / m
    suffix = 'MW'
  } else if (d > G) {
    value = d / M
    suffix = 'GW'
  } else {
    return '0 W'
  }
  return `${roundTo2Decimals(value)} ${suffix}`
}

export function energy2String(d: number): string {
  const ferroConfiguration = getFerroConfiguration()
  const notAvailable = ferroConfiguration.language.Globally.NOT_AVAILABLE

  let value: number | string
  let suffix = ''

  if (isNaN(d)) {
    if (typeof d === 'string') {
      value = d
    } else return notAvailable
  } else if (d < 0) {
    return notAvailable
  } else if (0 <= d && d < __KWH) {
    value = tooOneDecimal(d)
    suffix = 'Wh'
  } else if (d > __KWH && d < __MWH) {
    value = tooOneDecimal(d / __KWH)
    suffix = 'kWh'
  } else if (d > __MWH && d < __GWH) {
    value = tooOneDecimal(d / __MWH)
    suffix = 'MWh'
  } else if (d > __GWH && d < __TWH) {
    value = tooOneDecimal(d / __GWH)
    suffix = 'GWh'
  } else if (d > __TWH) {
    value = tooOneDecimal(d / __TWH)
    suffix = 'TWh'
  } else {
    return notAvailable
  }

  return `${value} ${suffix}`
}

export function loadCurrent(EQ: number, IQ: number, ED: number, ID: number): number {
  if (EQ === null || IQ === null || ED === null || ID === null) {
    return null
  }
  const first = (Math.pow(EQ - IQ, 2) + Math.pow(ED - ID, 2)) / 2
  return Math.sqrt(first)
}

export function ppow(nr: number): number {
  return Math.pow(nr, 2)
}

export function netPower(u1: number, u2: number, u3: number, i1: number, i2: number, i3: number): number {
  return (u1 * i1 + u2 * i2 + u3 * i3) / SQRT2
}

export function phasePV(iinvq: number, iinvd: number): number {
  return Math.sqrt(Math.pow(iinvd, 2) + Math.pow(iinvq, 2)) / SQRT2
}

/**
 * @name IaIbIaExists
 * @private
 * @param arr
 * @returns {boolean}
 */
function IaIbIaExists(arr: number[]): boolean {
  const isNumber = !isNaN(arr[0]) && !isNaN(arr[1]) && !isNaN(arr[2])
  const notNull = arr.indexOf(null) + 1 === 0
  const notUndefined = arr.indexOf(undefined) + 1 === 0
  return isNumber && notNull && notUndefined
}

export function neutralCurrent(Ia: number, Ib: number, Ic: number): number {
  if (IaIbIaExists([Ia, Ib, Ic])) {
    return Math.sqrt(Math.pow(Ia, 2) + Math.pow(Ib, 2) + Math.pow(Ic, 2) - Ia * Ib - Ib * Ic - Ia * Ic)
  } else {
    return null
  }
}
