import { keycloak } from '@app/auth/keycloak'
import { Environment } from '@environments/environment'

import { UnleashClient } from 'unleash-proxy-client'

export const FeatureFlagServiceName = 'featureFlagService'

const ferroampRegexp = /ferroamp/g

export class FeatureFlagService {
  unleash: UnleashClient
  isReady: Promise<void> | null

  isFerroampEmployee: boolean

  constructor() {
    const user = keycloak.userInfo

    this.isFerroampEmployee = ferroampRegexp.test((user as { email: string })?.email)

    this.unleash = new UnleashClient({
      url: Environment.unleashUrl,
      clientKey: 'PqhN&QNJ3oeL',
      appName: Environment.unleashAppName
    })
    this.isReady = null
  }

  async start(facilityId: number): Promise<void> {
    try {
      if (!this.isReady) {
        await this.unleash.updateContext({ userId: facilityId.toString() })
        // We're only allowed to start unleash once, so let's reuse it's readiness
        this.isReady = this.unleash.start()
        return this.isReady
      } else {
        await this.isReady
        return this.unleash.updateContext({ userId: facilityId.toString() })
      }
    } catch {
      // We don't want the UI to completely fail if the feature flags are unable to load
      return
    }
  }

  isEnabled(featureName: string): boolean {
    return this.unleash.isEnabled(featureName)
  }

  isSystemAlarmsEnabled(): boolean {
    return this.unleash.isEnabled('system-alarms-frontend')
  }

  hasOngoingIncident(): boolean {
    return this.unleash.isEnabled('ongoing-incident')
  }

  isHistoricalPerSecondDataEnabled() {
    if (this.isFerroampEmployee) return true
    return !this.unleash.isEnabled('hide-historical-per-second-data')
  }

  onGoingPlannedMaintenance() {
    return this.unleash.isEnabled('on-going-planned-maintenance')
  }

  spotPriceIsEnabled() {
    return this.unleash.isEnabled('spot-price-enabled')
  }

  useSpotPriceFeedback() {
    return this.unleash.isEnabled('use-spot-price-feedback')
  }
}
