import { EChartOption } from 'echarts'
import { neutralCurrent, roundTo2Decimals, SQRT2 } from '../../../../lib'
import {
  SystemData,
  UidataAggregatedPowerData,
  UidataDcDataQuery,
  UidataEnergyhubRmsCurrentQuery,
  UidataGridRmsCurrentQuery,
  UidataGridVoltageQuery,
  UidataMonitorDataResult,
  UiDataQueryTypes
} from '../../../../types'
import { LanguageViews } from '../../../language/language.interface'

export type processedDataQueryData = (Date | number)[][]
type queryType = {
  [k: string]: number
}

export type processedDataQuery = {
  series: EChartOption.Series[]
  legendData: string[]
  dataset: EChartOption.Dataset
}

export function processDataQuery(
  data: UidataMonitorDataResult,
  type: UiDataQueryTypes,
  l: LanguageViews
): processedDataQuery {
  const length = data.length
  let i: number, d: queryType
  let datasetSource = []
  let datasetDimensions: string[]

  let series: EChartOption.SeriesLine[]
  let legendData: string[] = [l.PHASE_1, l.PHASE_2, l.PHASE_3]

  if (type === 'udc') {
    datasetDimensions = ['ts', 'uNeg', 'uPos']
    datasetSource = data.map((d: UidataDcDataQuery) => [
      new Date(d.timestamp),
      d.uDCneg ? Math.abs(d.uDCneg) : null,
      d.uDCpos ? Math.abs(d.uDCpos) : null
    ])
    legendData = ['DC+', 'DC-']

    series = [
      {
        type: 'line',
        name: 'DC+',
        connectNulls: false,
        encode: {
          x: 'ts',
          y: 'uPos'
        }
      },
      {
        type: 'line',
        name: 'DC-',
        connectNulls: false,
        encode: {
          x: 'ts',
          y: 'uNeg'
        }
      }
    ]
  } else if (['ir', 'u'].includes(type)) {
    datasetDimensions = Object.keys(data[0]).filter(d => !(d == '__typename'))
    if (['ir'].includes(type))
      datasetSource = data.map((d: UidataEnergyhubRmsCurrentQuery) => [
        d.timestamp,
        d.iInvRms1,
        d.iInvRms2,
        d.iInvRms3
      ])
    else datasetSource = data.map((d: UidataGridVoltageQuery) => [d.timestamp, d.u1, d.u2, d.u3])
    series = [
      {
        type: 'line',
        name: l.PHASE_1,
        connectNulls: false,
        encode: {
          x: 'timestamp',
          y: datasetDimensions[1]
        }
      },
      {
        type: 'line',
        name: l.PHASE_2,
        connectNulls: false,
        encode: {
          x: 'timestamp',
          y: datasetDimensions[2]
        }
      },
      {
        type: 'line',
        name: l.PHASE_3,
        connectNulls: false,
        encode: {
          x: 'timestamp',
          y: datasetDimensions[3]
        }
      }
    ]
  } else if ('er' === type) {
    datasetDimensions = ['timestamp', 'iExtRms1', 'iExtRms2', 'iExtRms3', 'neutral']
    datasetSource = data.map((d: UidataGridRmsCurrentQuery) => {
      return [
        d.timestamp,
        d.iExtRms1,
        d.iExtRms2,
        d.iExtRms3,
        roundTo2Decimals(neutralCurrent(d.iExtRms1, d.iExtRms2, d.iExtRms3))
      ]
    })

    series = [
      {
        name: l.PHASE_1,
        type: 'line',
        connectNulls: false,
        encode: {
          x: 'timestamp',
          y: 'iExtRms1'
        }
      },
      {
        name: l.PHASE_2,
        connectNulls: false,
        type: 'line',
        encode: {
          x: 'timestamp',
          y: 'iExtRms2'
        }
      },
      {
        name: l.PHASE_3,
        connectNulls: false,
        type: 'line',
        encode: {
          x: 'timestamp',
          y: 'iExtRms3'
        }
      },
      {
        name: l.NEUTRAL,
        connectNulls: false,
        type: 'line',
        encode: {
          x: 'timestamp',
          y: 'neutral'
        }
      }
    ]

    legendData.push(l.NEUTRAL)
  } else if (['iq', 'id', 'eq', 'ed'].includes(type)) {
    //        case 'iq': // EnergyHub Active Current
    //            return GET_UIDATA_ENERGYHUB_ACTIVE_CURRENT;
    //        case 'ir': //EnergyHub Total current
    //            return GET_UIDATA_ENERGYHUB_TOTAL_CURRENT;
    //        case 'id': // EnergyHub Reactive Current
    //            return GET_UIDATA_ENERGYHUB_REACTIVE_CURRENT;
    //        case 'eq': // Grid Active Current
    //            return GET_UIDATA_GRID_ACTIVE_CURRENT;
    //        case 'er': // Grid total current
    //            return GET_UIDATA_GRID_TOTAL_CURRENT;
    //        case 'ed': // Grid reactive current
    //            return GET_UIDATA_GRID_REACTIVE_CURRENT;
    //        case 'udc': // DC voltage
    //            return GET_UIDATA_DC_VOLTAGE;
    //        case 'u': // Grid voltage
    //            return GET_UIDATA_GRID_VOLTAGE;

    datasetDimensions = Object.keys(data[0]).filter(d => !(d == '__typename'))

    for (i = 0; i < length; i++) {
      d = data[i] as unknown as queryType
      if (d.timestamp) {
        const d1 = d[datasetDimensions[1]]
        const d2 = d[datasetDimensions[2]]
        const d3 = d[datasetDimensions[3]]
        datasetSource.push([
          new Date(d.timestamp),
          d1 ? d1 / SQRT2 : null,
          d2 ? d2 / SQRT2 : null,
          d3 ? d3 / SQRT2 : null
        ])
      }
    }

    series = [
      {
        name: l.PHASE_1,
        connectNulls: false,
        type: 'line',
        encode: {
          x: 'timestamp',
          y: datasetDimensions[1]
        }
      },
      {
        name: l.PHASE_2,
        connectNulls: false,
        type: 'line',
        encode: {
          x: 'timestamp',
          y: datasetDimensions[2]
        }
      },
      {
        name: l.PHASE_3,
        connectNulls: false,
        type: 'line',
        encode: {
          x: 'timestamp',
          y: datasetDimensions[3]
        }
      }
    ]
  }

  return {
    dataset: {
      source: datasetSource,
      dimensions: datasetDimensions
    },
    series: series.map(serie => {
      serie.symbol = 'none'
      return serie
    }),
    legendData
  }
}

export type POWER_DATA_DIMENSIONS_TYPE = ['ts', 'grid', 'load', 'solar', 'battery']
export const POWER_DATA_DIMENSIONS: POWER_DATA_DIMENSIONS_TYPE = ['ts', 'grid', 'load', 'solar', 'battery']
export type POWER_DATA_TYPE = [Date, number, number, number, number][]

export type processMainAggregateDataType = {
  data: POWER_DATA_TYPE
  startDate: Date
  endDate: Date
  resolution: number
}

export function processMainAggData(
  __data: UidataAggregatedPowerData[],
  startDate: Date,
  endDate: Date,
  resolution: number
): processMainAggregateDataType {
  const length = __data.length
  const data: POWER_DATA_TYPE = []

  /**
     * result.push([
     doc.ts as Date, // IN 0 TIMESTAMP
     doc.gp as number, // IN 1 GRIDPOWER
     doc.pvp as number, // IN 2 PV POWER
     doc.bp as number, // IN 3 BATTERY POWER
     doc.lp as number // IN 4 LOADPOWER
     ]);

     */
  const kDivider = 1000
  const sqrtKDivider = kDivider * Math.SQRT2

  for (let i = 0; i < length; i++) {
    const d = __data[i]

    // [timestamp, gridpower, loadpower,solarpower,batterypower]
    if (d && d[0]) {
      data.push([
        new Date(d[0]),
        d[1] / sqrtKDivider,
        roundTo2Decimals((d[1] - d[4]) / sqrtKDivider),
        d[2] / kDivider,
        d[3] / kDivider
      ])
    }
  }

  return {
    data: data,
    startDate: startDate,
    endDate: endDate,
    resolution: resolution
  }
}

export const PROCESSED_BATTERY_ENERGY_DATA_DIMENSIONS = ['ts', 'socs']

export type processedBatteryEnergyData = {
  stateOfHealth: number
  ratedCapacity: number
  processedData: [Date, number][]
  data: SystemData[]
}

// export function processBatteryEnergyData(
//   data: GetBessesStateofChargeQuery['facility'],
//   isUsingPercent: boolean
// ): processedBatteryEnergyData {
//   let value
//   let soh
//   let rc
//   const ratedCapacity: { [key: string]: number } = {}

//   return {
//     data: data,
//     stateOfHealth: soh,
//     ratedCapacity: rc,
//     processedData: processedData
//   }
// }

export type powerViewTitleAndLabels = {
  [k in 'main' | 'ed' | 'eq' | 'iq' | 'id' | 'ir' | 'er' | 'udc' | 'u']: {
    y: string
    t: string
    l: string[]
  }
}

export function generateTitleAndLabels(language: LanguageViews): powerViewTitleAndLabels {
  const phases = ['L1', 'L2', 'L3']
  return {
    main: {
      y: 'kW',
      t: language.PRODUCTION_CONSUMPTION,
      l: [language.ELECTRICAL_GRID, language.CONSUMPTION, language.SOLAR_PRODUCTION, language.BATTERY]
    },
    ed: {
      y: 'Arms',
      t: language.GRID_REACTIVE_CURRENT,
      l: phases
    },
    eq: {
      y: 'Arms',
      t: language.GRID_ACTIVE_CURRENT,
      l: phases
    },
    iq: {
      y: 'Arms',
      t: language.ENERGYHUB_ACTIVE_CURRENT,
      l: phases
    },
    id: {
      y: 'Arms',
      t: language.ENERGYHUB_REACTIVE_CURRENT,
      l: phases
    },
    ir: {
      y: 'Arms',
      t: language.ENERGYHUB_CURRENT,
      l: phases
    },
    er: {
      y: 'Arms',
      t: language.GRID_CURRENT,
      l: ['L1', 'L2', 'L3', language.NEUTRAL]
    },
    udc: {
      y: 'V',
      t: language.DC_GRID_VOLTAGE,
      l: ['U+', 'U-']
    },
    u: {
      y: 'Vrms',
      t: language.GRID_VOLTAGE,
      l: phases
    }
  }
}
