import { StateService } from '@uirouter/angularjs'
import { svg } from 'd3-fetch'
import { select, Selection } from 'd3-selection'
import {
  dotHidden,
  roundValues,
  genPowerText,
  direction,
  isValidValue,
  calculateMicrogridPower,
  PowerSchematicValues,
  UpperLowerSchematicLimits
} from '../schematic.tools'
import { LivedataSysData } from '../../../types'
import { getId } from '../../general'
import { LanguageSystemdashboard } from '../../../app/language/language.interface'

// import * as  ehubImageSrc from '../../../assets/images/ehub_front.png';

import ehubFront from '../../../assets/images/ehub_front.png'

import powerSchematicUrl from './power-schematic.svg'

export interface PowerSchematicMicroGridInterface {
  stateName: string
  state: StateService
}

/*
 <circle id="DEAE" r="5" cy="290" cx="180" class="dot_ehub-ac-out dot-hidden dot"/>
    <circle id="DEDE" r="5" cy="165" cx="180" class="dot_ehub-dc-out dot-hidden dot"/>
    <circle id="DEAI" r="5" cy="375" cx="180" class="dot_ehub-ac-in dot-hidden dot"/>
    <circle id="DEDI" r="5" cy="80" cx="180" class="dot_ehub-dc-in dot-hidden dot"/>
    <circle id="DCE" r="5" cy="374" cx="230" class="dot_consumption-exp dot-hidden dot"/>
    <circle id="DCI" r="5" cy="374" cx="180" class="dot_consumption dot-hidden dot"/>
    <circle id="DGE" r="5" cy="374" cx="180" class="dot_grid-export dot-hidden dot"/>
    <circle id="DGI" r="5" cy="374" cx="130" class="dot_grid-import dot-hidden dot"/>
    <circle id="DBC" r="5" cy="74" cx="180" class="dot_battery-charge dot-hidden dot"/>
    <circle id="DBD" r="5" cy="74" cx="230" class="dot_battery-discharge dot-hidden dot"/>
    <circle id="DPV" r="5" cy="74" cx="130" class="dot_pv dot-hidden dot"/>
    <circle id="MDCE" r="5" cy="20" cx="180" class="dot_microgrid-in dot-hidden  dot"/>
    <circle id="MDCI" r="5" cy="74" cx="180" class="dot_microgrid-out dot-hidden  dot"/>

 */

export class PowerSchematicService {
  readonly id: string = 'powerSchematicId'
  private url = powerSchematicUrl
  // private ehubImageSrc = ehubImageSrc;

  private isReady = false

  private svg: Selection<HTMLElement, undefined, null, undefined>
  private xml: HTMLElement
  private batteryIsShown = false

  private store: {
    pv: number
    battery: number
    consumption: number
    grid: number
    ehub: number
    microgrid: number
  } = {
    pv: null,
    battery: null,
    consumption: null,
    grid: null,
    ehub: null,
    microgrid: null
  }
  private direction = {
    ehub: 0,
    consumption: 0,
    grid: 0,
    battery: 0,
    pv: 0,
    microgrid: 0
  }

  private directionCache = {
    ehub: 0,
    consumption: 0,
    grid: 0,
    battery: 0,
    pv: 0
  }

  private limits = {
    upperLimit: 0.15,
    lowerLimit: -0.15
  }

  private language: LanguageSystemdashboard

  private text: Selection<HTMLElement, undefined, undefined, undefined>

  private powerText: Selection<HTMLElement, undefined, undefined, undefined>
  private connections: Selection<HTMLElement, undefined, undefined, undefined>
  private dots: {
    DEAE?: Selection<HTMLElement, undefined, undefined, undefined>
    DEDE?: Selection<HTMLElement, undefined, undefined, undefined>
    DEAI?: Selection<HTMLElement, undefined, undefined, undefined>
    DEDI?: Selection<HTMLElement, undefined, undefined, undefined>
    DCE?: Selection<HTMLElement, undefined, undefined, undefined>
    DCI?: Selection<HTMLElement, undefined, undefined, undefined>
    DGE?: Selection<HTMLElement, undefined, undefined, undefined>
    DGI?: Selection<HTMLElement, undefined, undefined, undefined>
    DBC?: Selection<HTMLElement, undefined, undefined, undefined>
    DBD?: Selection<HTMLElement, undefined, undefined, undefined>
    DPV?: Selection<HTMLElement, undefined, undefined, undefined>
    MDCE?: Selection<HTMLElement, undefined, undefined, undefined>
    MDCI?: Selection<HTMLElement, undefined, undefined, undefined>
  } = {}
  // private metaText: number;
  /* EHUB INTERNAL*/
  private ehubPower: Selection<HTMLElement, undefined, undefined, undefined>
  private ehubDcConnector: Selection<HTMLElement, undefined, undefined, undefined>

  private ehubDir: Selection<HTMLElement, undefined, undefined, undefined>

  /* CONSUMPTION / LOAD */
  private consumptionPower: Selection<HTMLElement, undefined, undefined, undefined>
  private externalProduction: Selection<HTMLElement, undefined, undefined, undefined>
  /* GRID */

  private gridPower: Selection<HTMLElement, undefined, undefined, undefined>
  private gridDirection: Selection<HTMLElement, undefined, undefined, undefined>

  /* SOLAR PV */
  private hasPV: boolean

  private pvPower: Selection<HTMLElement, undefined, undefined, undefined>

  private pv: Selection<HTMLElement, undefined, undefined, undefined>

  private pvConnector: Selection<HTMLElement, undefined, undefined, undefined>

  private pvTitle: Selection<HTMLElement, undefined, undefined, undefined>

  /* BATTERY */
  private hasBattery = false

  private battery: Selection<HTMLElement, undefined, undefined, undefined>

  private batteryPower: Selection<HTMLElement, undefined, undefined, undefined>

  private batteryStatus: Selection<HTMLElement, undefined, undefined, undefined>

  private batteryTitle: Selection<HTMLElement, undefined, undefined, undefined>

  private batteryConnector: Selection<HTMLElement, undefined, undefined, undefined>

  private batteryInfo: Selection<HTMLElement, undefined, undefined, undefined>

  private batteryDirection: Selection<HTMLElement, undefined, undefined, undefined>

  /* MICROGRID */

  private microgrid: PowerSchematicMicroGridInterface
  private partOfMicrogrid: boolean

  private PSCMicrogrid: Selection<HTMLElement, undefined, undefined, undefined>

  private PSPTMicrogrid: Selection<HTMLElement, undefined, undefined, undefined>

  /**
   *
   * microgrid By setting partOfCluster we enable the potential of using dc
   * microgrids within system view.
   * microgrid.name the name of the microgrid
   * microgrid.goto If microgrid is present add a function to goto to the
   *   microgrid view
   *
   */
  constructor(id: string, language: LanguageSystemdashboard, microgrid?: PowerSchematicMicroGridInterface) {
    this.id = id
    this.microgrid = microgrid
    this.partOfMicrogrid = !!microgrid
    this.language = language

    svg(this.url)
      .then(svg => {
        this.xml = svg.documentElement
        this.addSchematic()
      })
      .catch(error => {
        throw error
      })
  }

  private addSchematic() {
    const id = getId(this.id)
    if (id) {
      this.svg = select(id.appendChild(this.xml))

      this.svg.select('#EhubImage').attr('xlink:href', ehubFront)

      this.start()
    } else {
      setTimeout(() => {
        this.addSchematic()
      }, 300)
    }
  }

  private start() {
    this.battery = this.svg.select('#PSBattery')
    this.pv = this.svg.select('#PSSolar')
    this.dots = {}

    this.svg
      .selectAll<HTMLElement, undefined>('#PSDots')
      .selectAll<HTMLElement, undefined>('circle')
      .nodes()
      .forEach(a => {
        // Settings AS 'DEAE' due to is one of them.
        this.dots[a.id as 'DEAE'] = select(a)
      })
    this.text = this.svg.select<HTMLElement>('#PSText')
    this.powerText = this.svg.select<HTMLElement>('#PSPowerText')
    this.batteryInfo = this.svg.select<HTMLElement>('#PSBS')
    this.connections = this.svg.select<HTMLElement>('#PSConnections')
    this.pvTitle = this.text.select<HTMLElement>('#PSTSol').text(this.language.SOLAR)
    this.batteryTitle = this.text.select<HTMLElement>('#PSTBat').text(this.language.BATTERY)
    this.text.select('#PSTGrid').text(this.language.GRID)
    this.text.select('#PSTCons').text(this.language.CONSUMPTION)
    this.externalProduction = this.text.select('#PSTExtCons')
    this.pvConnector = this.connections.select('#PSCSol')
    this.batteryConnector = this.connections.select('#PSCBat')
    this.ehubDcConnector = this.connections.select('#PSCEDc')
    this.ehubDir = this.text.select('#PSTEhubDir')

    this.batteryDirection = this.text.select('#PSTBatCharge')
    this.gridDirection = this.text.select<HTMLElement>('#PSTGridDir')
    this.batteryStatus = this.battery.select('#PSBCharge')

    this.pvPower = this.powerText.select<HTMLElement>('#PSPTSol')
    this.batteryPower = this.powerText.select('#PSPTBat')
    this.gridPower = this.powerText.select('#PSPTGrid')
    this.consumptionPower = this.powerText.select('#PSPTCons')
    this.ehubPower = this.powerText.select('#PSPTEhub')

    // Add titles
    if (this.partOfMicrogrid) {
      this.showMicrogrid()
    }

    this.isReady = true
  }

  /**
   *
   *  {PowerSchematicValues} values
   */
  private pvHandler(values: PowerSchematicValues) {
    if (!isNaN(values.pvPower)) {
      if (!this.hasPV) {
        this.showSolar()
        this.showHideDcConnector(true)
      }
      this.hasPV = true
      const pvPower = genPowerText(values.pvPower, this.limits)
      if (values.pvPower !== this.store.pv) {
        this.pvPower.text(pvPower)
      }
      this.store.pv = values.pvPower
    }
  }

  /**
   *
   *  {PowerSchematicValues} values
   */
  private batteryHandler(values: PowerSchematicValues) {
    if (!isNaN(values.batPower)) {
      if (!this.hasBattery) {
        this.showBattery()
        this.showHideDcConnector(true)
      }
      this.hasBattery = true
      const batteryPower = genPowerText(values.batPower, this.limits)
      if (values.batPower !== this.store.battery) {
        this.batteryPower.text(batteryPower)
      }

      this.store.battery = values.batPower
    }
  }

  private directionsHandler(values: PowerSchematicValues, lims: UpperLowerSchematicLimits) {
    this.direction.battery = direction(values.batPower)
    this.direction.pv = direction(isValidValue(values.pvPower, lims))
    this.direction.grid = direction(isValidValue(values.gridPower, lims))
    this.direction.consumption = direction(isValidValue(values.loadPower, lims))
    this.direction.ehub = direction(isValidValue(values.ehubPower, lims))
  }

  public update(updateValues: PowerSchematicValues): void {
    const values = roundValues(updateValues)
    if (!values || !this.isReady) {
      return
    }
    const lims = this.limits
    this.pvHandler(values)
    this.batteryHandler(values)
    if (this.partOfMicrogrid) {
      this.microgridHandler(values)
    }
    const ehubPower = genPowerText(values.ehubPower, lims)
    const gridPower = genPowerText(values.gridPower, lims)
    const consumptionPower = genPowerText(values.loadPower, lims)
    const batPower = genPowerText(values.batPower, lims)
    const solarPower = genPowerText(values.pvPower, lims)
    if (values.batPower !== this.store.battery) {
      this.batteryPower.text(batPower)
    }
    if (values.pvPower !== this.store.pv) {
      this.pvPower.text(solarPower)
    }

    if (values.ehubPower !== this.store.ehub) {
      this.ehubPower.text(ehubPower)
    }
    if (values.gridPower !== this.store.grid) {
      this.gridPower.text(gridPower)
    }
    if (values.loadPower !== this.store.consumption) {
      this.consumptionPower.text(consumptionPower)
    }
    this.store.ehub = values.ehubPower
    this.store.grid = values.gridPower
    this.store.consumption = values.loadPower
    this.store.battery = values.batPower
    this.store.pv = values.pvPower
    this.directionsHandler(values, lims)
    this.setDirection()
    this.directionCache = JSON.parse(JSON.stringify(this.direction))
  }

  private setDirection() {
    const gridDirection = this.direction.grid
    if (gridDirection !== this.directionCache.grid) {
      const posGrid = gridDirection >= 0
      const negGrid = gridDirection <= 0
      this.dots.DGE.classed(dotHidden, posGrid)
      this.dots.DGI.classed(dotHidden, negGrid)
      if (gridDirection < 0) {
        this.gridDirection.text(this.language.SELLING)
      } else if (gridDirection > 0) {
        this.gridDirection.text(this.language.BUYING)
      } else {
        this.gridDirection.text('')
      }
    }

    const consumptionDirection = this.direction.consumption
    if (consumptionDirection !== this.directionCache.consumption) {
      const posCons = consumptionDirection >= 0
      const negCons = consumptionDirection <= 0
      this.dots.DCI.classed(dotHidden, negCons)
      this.dots.DCE.classed(dotHidden, posCons)
      if (consumptionDirection < 0) {
        this.externalProduction.text(this.language.EXTERNAL_PRODUCTION)
      } else {
        this.externalProduction.text('')
      }
    }

    const batDirection = this.direction.battery
    if (batDirection !== this.directionCache.battery) {
      const isCharging = batDirection < 0
      const isDischarging = batDirection > 0
      this.dots.DBC.classed(dotHidden, !isCharging)
      this.dots.DBD.classed(dotHidden, !isDischarging)
      if (isCharging) {
        this.batteryDirection.text(this.language.CHARGING)
      } else if (isDischarging) {
        this.batteryDirection.text(this.language.DISCHARGING)
      }
    }

    const ehubDirection = this.direction.ehub
    if (ehubDirection !== this.directionCache.ehub) {
      const isExporting = ehubDirection > 0
      const zeroE = ehubDirection === 0
      this.dots.DEAE.classed(dotHidden, isExporting || zeroE)
      this.dots.DEAI.classed(dotHidden, !isExporting || zeroE)
      this.dots.DEDI.classed(dotHidden, isExporting || zeroE)
      this.dots.DEDE.classed(dotHidden, !isExporting || zeroE)
      if (ehubDirection > 0) {
        this.ehubDir.text(this.language.IMPORTING)
      } else if (ehubDirection < 0) {
        this.ehubDir.text(this.language.EXPORTING)
      }
    }

    const pvDirection = this.direction.pv
    if (pvDirection !== this.directionCache.pv) {
      this.dots.DPV.classed(dotHidden, pvDirection === 0)
    }
  }

  private showHideDcConnector(show: boolean) {
    this.ehubDcConnector.style('fill', show ? 'unset' : 'none')
  }

  public updateBatteryStatus(status: LivedataSysData): void {
    return
    if (!this.isReady || !this.batteryInfo) {
      setTimeout(() => {
        this.updateBatteryStatus(status)
      }, 500)

      return
    }
    this.batteryInfo.classed(dotHidden, false)
    this.batteryInfo
      .selectAll('text')
      .nodes()
      .forEach((d, i) => {
        const text =
          i === 0
            ? `${this.language.RATED_CAPACITY} ${status.ratedCapacity / 1000} kWh`
            : `${this.language.STATE_OF_CHARGE}  ${Math.round(status.soc)}%`
        select(d).text(text)
      })
    this.showBattery()
    const defaultY = 34
    const defaultHeight = 50
    const height = (status.soc * defaultHeight) / 100
    this.batteryStatus.attr('height', height).attr('y', defaultY + defaultHeight - height)
  }

  private showSolar() {
    this.pv.classed('hide-solar-battery', false)
    this.pvTitle.style('fill', 'black')
    this.pvConnector.style('fill', null)
    this.pvPower.text('')
  }

  private hideSolar() {
    this.pv.classed('hide-solar-battery', true)
    this.pvTitle.style('fill', '#ffffff')
    this.pvConnector.style('fill', 'none')
    this.dots.DPV.classed(dotHidden, true)
    this.pvPower.text('')
  }

  private showBattery() {
    if (!this.batteryIsShown) {
      this.batteryIsShown = true
      this.battery.classed('hide-solar-battery', false)
      this.batteryTitle.style('fill', 'black')
      this.batteryConnector.style('fill', null)
      this.batteryPower.text('')
    }
  }

  private hideBattery() {
    this.batteryIsShown = false
    this.battery.classed('hide-solar-battery', true)
    this.batteryTitle.style('fill', '#ffffff')
    this.batteryConnector.style('fill', 'none')
    this.batteryPower.text('')
  }

  private showMicrogrid(microgrid?: { stateName: string; state: StateService }) {
    this.microgrid = microgrid
    this.PSCMicrogrid = this.connections.select('#PSCMicrogrid')
    this.PSCMicrogrid.style('fill', 'black')
    this.PSPTMicrogrid = this.powerText.select<HTMLElement>('#PSPTMicrogrid')
    this.svg
      .select('#PSMicrogrid')
      .style('cursor', 'pointer')
      .classed('hidden', false)
      .on('click', () => {
        this.microgrid.state.go(this.microgrid.stateName)
      })

    this.partOfMicrogrid = true
  }

  private microgridHandler(values: PowerSchematicValues) {
    const microgridPower = calculateMicrogridPower(values.pvPower, values.batPower, values.ehubPower)
    const { limits } = this
    this.direction.microgrid = direction(isValidValue(microgridPower, limits))
    const dir = this.direction.microgrid
    this.dots.MDCE.classed(dotHidden, dir <= 0)
    this.dots.MDCI.classed(dotHidden, dir >= 0)

    const mc = genPowerText(microgridPower, this.limits)
    this.PSPTMicrogrid.text(mc)
  }
}
