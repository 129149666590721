import angular, { IHttpResponse, IHttpService, IPromise } from 'angular'
import { Environment } from '../../../environments/environment'
import {
  AnalysisLoadEventDetectionResultId,
  GetUserByUserIdListResponse,
  MongoDbObjectId
} from '../../../types'
import { FerroConfiguration, FerroConfigurationName } from '../../service'
import { serviceClb } from '../../service/types.service'
import {
  MdbAnalysisLoadEventDetectionJobs,
  MdbAnalysisLoadEventDetectionResults,
  MdbAnalysisResultsId
} from './@types/load-event-detection'

export interface CreateLoadEventRequest {
  facility_id: number
  starttime: Date
  endtime: Date
}

export interface PendingData {
  resultsid: string
  analysismodel: 'LOADEVENTDETECTION'
  processingstatus: 'PENDING'
  resultslink: string
}

export interface CreateLoadEventResponse {
  facility_id: number
  starttime: Date
  endtime: Date
  histtype: 'signscatt'
  resultsid: MdbAnalysisResultsId
  analysismodel: 'LOADEVENTDETECTION'
  processingstatus: 'PENDING'
  resultslink: string
}

export interface GetDailyTrendDataRequest {
  facility_id: number
  starttime: Date
  endtime: Date
}

export interface StoredLoadEventDetectionAsSaveAnalysisResponse {
  _id: MongoDbObjectId
  resultsid: string
  histtype: string
  endtime: Date
  starttime: Date
}

export interface ParsedStoredAnalysis extends StoredLoadEventDetectionAsSaveAnalysisResponse {
  email: string
  finishedTime: Date
  jobstatus: number
  status?: string
}

export interface DeleteStoredAnalysisResponse {
  resultsid: MdbAnalysisResultsId
  message: string
}

const BASE_URI_ANALYSIS = Environment.endpoints.analysisApiUrl

export const AnalysisHttpServiceName = 'analysisHttpService'

export class AnalysisHttpService {
  $http: IHttpService
  fcService: FerroConfiguration

  canceller: IPromise<unknown>

  constructor($http: IHttpService, ferroConfiguration: FerroConfiguration) {
    this.$http = $http
    this.fcService = ferroConfiguration
  }

  /**
   * If you wanna have a canceller of the request available please use the init method
   */
  init(canceller: IPromise<unknown>): void {
    this.canceller = canceller
  }

  getStoredLoadEventDetection(facilityId: number, done: serviceClb<string, ParsedStoredAnalysis[]>): void {
    this.$http<StoredLoadEventDetectionAsSaveAnalysisResponse[]>({
      method: 'GET',
      url: `${BASE_URI_ANALYSIS}/loadeventdetection/results/${facilityId}`
    }).then(
      response => {
        if (response.status === 204) {
          done('NO_DATA')
        } else {
          this.mapUserIdWithEmail(response.data, list => {
            done(null, list)
          })
        }
      },
      ErrorResponse => {
        done(ErrorResponse.data)
      }
    )
  }

  private onUserMapping(
    storedAnalysisList: StoredLoadEventDetectionAsSaveAnalysisResponse[],
    _error: boolean | IHttpResponse<unknown>,
    _userEmailIdMap: GetUserByUserIdListResponse[],
    callback: (result: ParsedStoredAnalysis[]) => void
  ): void {
    const parsedStoredAnalysisList: ParsedStoredAnalysis[] = angular.copy(
      storedAnalysisList
    ) as ParsedStoredAnalysis[]

    const list = parsedStoredAnalysisList.map(d => {
      const timestamp = d._id.toString().substring(0, 8)
      d.finishedTime = new Date(parseInt(timestamp, 16) * 1000)
      // d.executionTime = (ts.getTime() - new Date(d.timestamp).getTime()) / 1000;

      // if (this.fcService.isDemo) {
      //   d.email = "demo@ferroamp.se";
      // } else if (mapUser) {
      //   d.email = userEmailIdMap[d.userid].email;
      // } else {
      //   d.email = this.fcService.language.SystemAnalysis.ERROR_USER_MAP;
      // }
      return d
    })
    callback(list)
  }

  mapUserIdWithEmail(
    storedAnalysisList: StoredLoadEventDetectionAsSaveAnalysisResponse[],
    callback: (result: ParsedStoredAnalysis[]) => void
  ): void {
    if (!storedAnalysisList) return callback([])
    else if (!storedAnalysisList.length) return callback([])

    this.onUserMapping(storedAnalysisList, true, [], callback)
  }

  deleteSavedAnalysis(
    resultid: AnalysisLoadEventDetectionResultId,
    facilityId: number,
    callback: serviceClb<IHttpResponse<string>, DeleteStoredAnalysisResponse>
  ): void {
    this.$http<DeleteStoredAnalysisResponse>({
      method: 'DELETE',
      url: `${BASE_URI_ANALYSIS}/loadeventdetection/${resultid}?facilityId=${facilityId}`
    }).then(
      response => {
        callback(null, response.data)
      },
      error => {
        callback(error)
      }
    )
  }

  getCurrentLoadEventJobs(
    facilityId: number,
    callback: (data: MdbAnalysisLoadEventDetectionJobs[]) => void
  ): void {
    this.$http<MdbAnalysisLoadEventDetectionJobs[]>({
      method: 'GET',
      url: `${BASE_URI_ANALYSIS}/loadeventdetection/jobs/${facilityId}`
    }).then(
      response => {
        callback(response.data)
      },
      () => {
        callback(null)
      }
    )
  }

  createJob(
    starttime: Date,
    endtime: Date,
    facilityId: number,
    callback: serviceClb<IHttpResponse<string>, CreateLoadEventResponse>
  ): void {
    const data: CreateLoadEventRequest = {
      starttime: new Date(starttime),
      endtime: new Date(endtime),
      facility_id: facilityId
    }

    this.$http<CreateLoadEventResponse>({
      method: 'POST',
      url: `${BASE_URI_ANALYSIS}/loadeventdetection?test=false`,
      data: data
    }).then(
      response => {
        callback(null, response.data)
      },
      response => {
        callback(response, null)
      }
    )
  }

  getLoadEventJob(
    resultsId: MdbAnalysisResultsId,
    facilityId: number,
    type: 'scatter' | 'signature',
    callback: serviceClb<IHttpResponse<string> | PendingData, MdbAnalysisLoadEventDetectionResults>
  ): void {
    this.$http<MdbAnalysisLoadEventDetectionResults>({
      method: 'GET',
      url: `${BASE_URI_ANALYSIS}/loadeventdetection/${resultsId}?type=${type}&facilityId=${facilityId}`
    }).then(
      response => {
        if (response.status === 204) {
          callback(response.data as unknown as PendingData)
          return
        }
        callback(null, response.data)
      },
      response => {
        callback(response, null)
      }
    )
  }
}

AnalysisHttpService.$inject = ['$http', FerroConfigurationName]
