import { material } from 'angular'
import { isNumber } from 'lodash'
import './notifications.scss'

export class NotificationService {
  toast: material.IToastService
  constructor(toast: material.IToastService) {
    this.toast = toast
  }

  onError(errorMessage: string, delay?: number): () => void {
    const t = this.toast
      .simple()
      .textContent(errorMessage)
      .toastClass('toast-error-message')
      // The center is applied in packages/app/src/scss/modified/angular_material_modifications.scss
      .position('bottom center')
      .hideDelay(isNumber(delay) ? delay : 3000)
    this.toast.show(t)
    return () => this.toast.hide(t)
  }

  onSuccess(successMessage: string, delay?: number): () => void {
    const t = this.toast
      .simple()
      .textContent(successMessage)
      .toastClass('toast-success-message')
      .position('bottom center')
      .hideDelay(isNumber(delay) ? delay : 3000)
    this.toast.show(t)
    return () => this.toast.hide(t)
  }

  onInfo(infoMessage: string, delay?: number): () => void {
    const t = this.toast
      .simple()
      .textContent(infoMessage)
      .toastClass('toast-info-message')
      .position('bottom center')
      .hideDelay(isNumber(delay) ? delay : 3000)

    this.toast.show(t)
    return () => this.toast.hide(t)
  }
}

NotificationService.$inject = ['$mdToast']
