import * as angular from 'angular'
import { ColorService, ColorServiceName } from './color.service'
import { FerroConfiguration, FerroConfigurationName } from './ferro-configuration.service'
import { GdprService, GdprServiceName } from './gdpr.service'

import { AVAILABLE_EXTERNAL_MODULES } from '../../environments/environment'
import { EhubHttpService, EhubHttpServiceName } from './ehub-http.service'
import { EmsConfigV2HttpService, EmsConfigV2HttpServiceName } from './ems-config-v2-http.service'
import { EmsConfigV2IoService, EmsConfigV2IoServiceName } from './ems-config-v2-io.service'
import { FacilityDataHttpService, FacilityDataHttpServiceName } from './facility-data-http.service'
import {
  FacilityTopologyHttpV1Service,
  FacilityTopologyHttpV1ServiceName
} from './facility-topology-http.v1.service'
import { LanguageService, LanguageServiceName } from './language.service'
import { LivedataService, LiveDataServiceName } from './livedata.service'
import { StaticDataHttpService, StaticDataHttpServiceName } from './static-data-http.service'
import { SystemStateV2HttpService, SystemStateV2HttpServiceName } from './system-state-v2-http.service'
import { SystemStateV2IoService, SystemStateV2IoServiceName } from './system-state-v2-io.service'

import { AmplitudeService, AmplitudeServiceName } from './amplitude.service'
import { FeatureFlagService, FeatureFlagServiceName } from './feature-flag.service'
import { OpenWeatherMapService, OpenWeatherMapServiceName } from './open-weather-map.service'
import { RolesServices, RolesServicesName } from './roles.services'
import { UnleashPortal, UnleashPortalName } from './unleash-portal'

export * from './color.service'
export {
  AmplitudeService,
  EhubHttpService,
  EmsConfigV2IoService,
  FacilityDataHttpService,
  FacilityTopologyHttpV1Service,
  FeatureFlagService,
  FerroConfiguration,
  GdprService,
  LanguageService,
  LivedataService,
  SystemStateV2HttpService
}

export {
  EhubHttpServiceName,
  EmsConfigV2HttpServiceName,
  EmsConfigV2IoServiceName,
  FacilityDataHttpServiceName,
  FacilityTopologyHttpV1ServiceName,
  FeatureFlagServiceName,
  FerroConfigurationName,
  GdprServiceName,
  LanguageServiceName,
  LiveDataServiceName,
  StaticDataHttpServiceName,
  SystemStateV2HttpServiceName
}

export const AppServiceModule = angular
  .module('app.service', [AVAILABLE_EXTERNAL_MODULES.ngMaterial, AVAILABLE_EXTERNAL_MODULES.ngAnimate])
  .service(GdprServiceName, GdprService)
  .service(ColorServiceName, ColorService)
  .service(FerroConfigurationName, FerroConfiguration)
  .service(LanguageServiceName, LanguageService)
  .service(FacilityTopologyHttpV1ServiceName, FacilityTopologyHttpV1Service)
  .service(FacilityDataHttpServiceName, FacilityDataHttpService)
  .service(LiveDataServiceName, LivedataService)
  .service(StaticDataHttpServiceName, StaticDataHttpService)
  .service(EmsConfigV2HttpServiceName, EmsConfigV2HttpService)
  .service(SystemStateV2HttpServiceName, SystemStateV2HttpService)
  .service(SystemStateV2IoServiceName, SystemStateV2IoService)
  .service(EmsConfigV2IoServiceName, EmsConfigV2IoService)
  .service(EhubHttpServiceName, EhubHttpService)
  .service(OpenWeatherMapServiceName, OpenWeatherMapService)
  .service(FeatureFlagServiceName, FeatureFlagService)
  .service(AmplitudeServiceName, AmplitudeService)
  .service(RolesServicesName, RolesServices)
  .service(UnleashPortalName, UnleashPortal).name
