import { StateService } from '@uirouter/angularjs'
import { copy, IComponentOptions, IScope } from 'angular'

import { LanguageSystemSettings } from '../../../language/language.interface'
import { FerroConfiguration, FerroConfigurationName, StaticDataHttpServiceName } from '../../../service'

import { StaticDataHttpService, timezonesListData } from '../../../service/static-data-http.service'
import './system-information.component.scss'

import { NotificationService } from '@app/components'
import { EnergyBiddingZone, GetOneFacilityQuery } from '@app/graphql/generated'
import { runQuery } from '@app/graphql/graphql'
import { RolesServices, RolesServicesName } from '@app/service/roles.services'
import templateUrl from './system-information.component.html'

class SystemInformationController {
  error = ''

  metainfo: GetOneFacilityQuery['facility']

  changeListOrder = '-updateTime'
  currencies: string[]
  timezones: timezonesListData
  energyBiddingZones = Object.values(EnergyBiddingZone)
  l: LanguageSystemSettings
  formattedAddress: string
  $state: StateService
  staticDataHttpService: StaticDataHttpService
  errorGeo = ''

  facilityId: number
  commentError = ''
  hasSavedTimezone = true

  // TODO: Keep latitude & longitude as numbers in all places
  latitude: number | null = null
  longitude: number | null = null
  lastUpdatedBy: string | undefined | null = null

  errorLocation?: string
  errorMisc?: string
  errorCapacity?: string
  loading = false
  $scope: IScope
  notificationService: NotificationService
  notAllowed: boolean
  constructor(
    $state: StateService,
    ferroConfiguration: FerroConfiguration,
    staticDataHttpService: StaticDataHttpService,
    $scope: IScope,
    notificationService: NotificationService,
    roles: RolesServices
  ) {
    this.notAllowed = !roles.canUpdateFacilitySystemSettings()
    this.notificationService = notificationService

    this.$scope = $scope
    this.$state = $state
    this.staticDataHttpService = staticDataHttpService

    this.facilityId = Number(ferroConfiguration.facility.id)
    this.l = ferroConfiguration.language.SystemSettings

    staticDataHttpService.getCurrencies(currenciesLIst => {
      const currenciesMap: { [_: string]: number } = {}
      currenciesLIst.map(c => (currenciesMap[c.currencycode] = 1))
      this.currencies = Object.keys(currenciesMap).sort()
    })
    staticDataHttpService.getTimezones(timezones1 => {
      this.timezones = timezones1
    })
  }

  async $onInit() {
    await this.bump()
  }

  async bump() {
    this.loading = true
    const facilityId = this.facilityId
    try {
      const res = await runQuery.getOneFacilityQuery({
        variables: {
          facilityId
        }
      })

      this.metainfo = copy(res.data.facility)
      this.metainfo.misc = copy(res?.data?.facility?.misc || ({} as any))
      this.metainfo.location = copy(res?.data?.facility?.location || ({} as any))

      this.hasSavedTimezone = !!this.metainfo.timezone

      const lastUpdatedBy = this.metainfo?.lastUpdatedBy?.email
      if (/ferroamp/g.test(lastUpdatedBy)) {
        this.lastUpdatedBy = 'Ferroamp Support'
      } else {
        this.lastUpdatedBy = lastUpdatedBy
      }

      this.latitude = res?.data?.facility?.location?.latitude
      this.longitude = res?.data?.facility?.location?.longitude
    } catch {
      this.notificationService.onError(this.l.ERROR_OCCURRED)
      this.error = this.l.ERROR_OCCURRED
    }
    this.$scope.$applyAsync(() => {
      this.loading = false
    })
  }

  async updateMetaInfo(isValid: boolean) {
    if (this.notAllowed) return
    if (!isValid) {
      this.error = this.l.INVALID_FORM
      return
    }
    this.loading = true

    try {
      const { city, country, address, propertyDesignation, electricalGridId } = this.metainfo?.location || {}
      const res = await runQuery.updateOneFacilityMutation({
        variables: {
          id: this.facilityId,
          data: {
            name: this.metainfo?.name,
            installedOn: this.metainfo?.installedOn || undefined
          },
          location: {
            address: address || undefined,
            country: country || undefined,
            propertyDesignation: propertyDesignation || undefined,
            electricalGridId: electricalGridId || undefined,
            city: city || undefined,
            longitude: this.longitude || undefined,
            latitude: this.latitude || undefined,
            energyBiddingZone: this.metainfo.location.energyBiddingZone
          },
          misc: {
            costElectricityKWh: this.metainfo?.misc?.costElectricityKWh || undefined,
            revenueSoldToGridkWh: this.metainfo?.misc?.revenueSoldToGridkWh || undefined,
            currencyCode: this.metainfo?.misc?.currencyCode || undefined
          },
          capacity: { installedPv: this.metainfo?.capacity?.installedPv }
        }
      })
      const { errors, data } = res

      if (errors && errors.length) {
        this.error = this.l.ERROR_OCCURRED
        this.notificationService.onError(this.l.ERROR_OCCURRED)
        return
      }

      if (
        !data?.updateOneFacility?.success ||
        !data?.updateOneFacilityLocation?.success ||
        !data?.updateOneFacilityMisc?.success
      ) {
        this.error = this.l.ERROR_OCCURRED
        this.notificationService.onError(this.l.ERROR_OCCURRED)
      } else {
        this.notificationService.onSuccess(`${this.l.SUCCESS}. ${this.l.PAGE_WILL_BE_RELOADED}`)
        setTimeout(() => {
          window.location.reload()
        }, 3000)
      }
    } catch {
      this.error = this.l.ERROR_OCCURRED
      this.notificationService.onError(this.l.ERROR_OCCURRED)
    }
    this.$scope.$applyAsync(() => {
      this.loading = false
    })
  }

  geoRequest(): void {
    const geoLocationRequest = {
      city: this.metainfo?.location?.city,
      country: this.metainfo?.location?.country,
      address: this.metainfo?.location?.address
      // getTimestamp: true
    }

    this.formattedAddress = null
    this.errorGeo = null
    if (!geoLocationRequest.address) {
      this.errorGeo = `${this.l.YOU_MUST_FILL} ${this.l.ADDRESS}`
      return
    }
    if (!geoLocationRequest.city) {
      this.errorGeo = `${this.l.YOU_MUST_FILL} ${this.l.CITY}`
      return
    }
    if (!geoLocationRequest.country) {
      this.errorGeo = `${this.l.YOU_MUST_FILL} ${this.l.COUNTRY}`
      return
    }
    this.staticDataHttpService.getGeoLocations(geoLocationRequest, (error, locations) => {
      if (error) {
        this.errorGeo = this.l.NOT_FOUND_LOCATION
      } else {
        this.latitude = locations.lat
        this.longitude = locations.lng
        this.formattedAddress = locations.formatted_address
        if (!this.hasSavedTimezone) {
          this.metainfo.timezone = locations.tz
        }
      }
    })
  }
}

SystemInformationController.$inject = [
  '$state',
  FerroConfigurationName,
  StaticDataHttpServiceName,
  '$scope',
  'notificationService',
  RolesServicesName
]

export const SystemInformationComponentName = 'systemInformationComponent'

export const SystemInformationComponent: IComponentOptions = {
  controller: SystemInformationController,
  templateUrl: templateUrl
}
