import { NotificationService } from '@app/components'
import {
  omitTypename,
  SystemConfigurationGraphqlService,
  SystemConfigurationGraphqlServiceName
} from '@app/graphql'
import { DefaultEmsConfigScheduleInput, EmsConfig, EmsConfigSchedule } from '@app/graphql/generated'
import { emsConfigFormToEmsParam } from '@app/graphql/system-configuration/system-configuration.parsers'
import { LanguageScheduling } from '@app/language/language.interface'
import {
  AmplitudeService,
  EmsConfigV2HttpServiceName,
  EmsConfigV2IoService,
  EmsConfigV2IoServiceName,
  FerroConfiguration,
  FerroConfigurationName,
  LanguageService
} from '@app/service'
import { AmplitudeServiceName } from '@app/service/amplitude.service'
import {
  EmsConfigWebsocketCommandEvents,
  EmsConfigWebsocketEmsConfChangedEvent,
  SystemConfigurationEventTypesEnum
} from '@app/service/ems-config-v2-io.service'
import { LanguageServiceName } from '@app/service/language.service'
import { DEFAULT_TIMEZONE, OTHER_TRANSACTION_ONGOING_STRING } from '@environments/environment'
import type { EmsConfNonMeasureDataObject, EmsParam } from '@ferroamp/system-configuration-lib'
import { StateService } from '@uirouter/core'
import angular, { IComponentOptions, IFormController, IScope, material } from 'angular'
import { DateTime } from 'luxon'
import { Weekday } from 'rrule'
import { systemSettingsStates } from '../../system-settings.routing.config'
import { BatteryWizardController, batteryWizardTemplate } from '../battery-wizard/battery-wizard.controller'
import { EmsConfigV2Language, en, se } from '../ems-config-form/ems-config.language'
import { emsConfigV2FrontendValidation } from '../ems-config/ems-config.validation'
import { reasonParser } from '../ems-config/reasons'
import templateUrl from './ems-config-schedule-picker.component.html'
import './ems-config-schedule-picker.component.scss'
import { checkEndDate } from './ems-schedule-picker.tools'
import { shouldSaveAndApplyDefault } from './helpers'
import { createVCalendar, CreateVCalendarInputs, toVCalendarInputs } from './i-calendar'

const createEmptyScheduling = (emsParams: EmsConfig): EmsConfigSchedule => ({
  id: '',
  // facility: { id: null } as any,
  iCalendarEvent: '',
  emsParam: emsParams,
  lastApplied: null,
  lastUpdate: null,
  lastUpdateBy: null,
  default: null
})

export interface FormScope extends IScope {
  iCalendarForm: IFormController
}

export class EmsConfigSchedulePickerController {
  days: { enabled: boolean; day: string; rrule: Weekday }[] = []
  iCal: CreateVCalendarInputs = {
    start: null,
    end: null,
    title: '',
    description: '',
    days: [],
    timezone: '',
    hasRecurrance: true,
    showStart: null
  }
  title = ''
  validationError: string | null = null
  isDefault: boolean
  isUpdating: boolean
  scheduling: EmsConfigSchedule
  baseEmsParams: EmsConfig
  l2: EmsConfigV2Language
  scheduleId: string
  facilityId: number
  timezone: string
  emsForm: IFormController
  l: LanguageScheduling
  loading = true
  initialLoad = true

  defaultIsActive = false

  hideToastFn?: () => void
  constructor(
    private ferroConfiguration: FerroConfiguration,
    private notifications: NotificationService,
    private service: SystemConfigurationGraphqlService,
    private languageService: LanguageService,
    private amplitude: AmplitudeService,
    private $state: StateService,
    private $scope: FormScope,
    private $mdDialog: material.IDialogService,
    private emsIoService: EmsConfigV2IoService
  ) {
    const params = this.$state.params
    this.isDefault = params.isDefault
    this.isUpdating = params.isUpdating
    this.scheduleId = params.scheduleId
    this.l = this.ferroConfiguration.language.Scheduling
    this.title = this.l.SCHEDULING_EVENT
    this.days = ferroConfiguration.language.Globally.dayshort.map((day, index) => {
      return {
        day,
        enabled: false,
        rrule: new Weekday(index)
      }
    })
    this.iCal.timezone = ferroConfiguration.facility.timezone || DEFAULT_TIMEZONE
    this.facilityId = Number(ferroConfiguration.facility.id)
    this.timezone = ferroConfiguration.facility.timezone
    this.l2 = this.languageService.language === 'us' ? en : se
  }

  private onIoData(event: EmsConfigWebsocketEmsConfChangedEvent | EmsConfigWebsocketCommandEvents) {
    this.hideToastFn?.()

    if (event.eventType === SystemConfigurationEventTypesEnum.SUCCEEDED) {
      this.notifications.onSuccess(this.l2.SUCCESSFULLY_UPDATED_DEFAULT_ON_SYSTEM, 6000)
      this.stopLoading()
      this.$state.go(systemSettingsStates.scheduling)
    } else if (event.eventType === SystemConfigurationEventTypesEnum.FAILED) {
      const reason = (event.data as EmsConfNonMeasureDataObject).reason
      if (reason === OTHER_TRANSACTION_ONGOING_STRING) {
        this.notifications.onInfo(this.l2.OTHER_TRANSACTION_ONGOING_STRING)
      } else {
        this.stopLoading()

        const parsedReason = reasonParser({
          reason,
          language: this.languageService.language
        })
        this.notifications.onInfo(this.l2.FAILED_TO_UPDATE_DEFAULT_ON_SYSTEM + ' ' + parsedReason, 10000)
      }
      this.$state.go(systemSettingsStates.scheduling)
    }
  }

  async $onInit() {
    const emsConfig = await this.service.getEmsConfig(Number(this.facilityId))
    if (emsConfig) this.baseEmsParams = emsConfig.data.facility.emsConfig

    this.emsIoService.init(this.facilityId, this.onIoData.bind(this), this.onIoData.bind(this), () => {})

    const res = await this.service.getEmsConfigSchedules(this.facilityId)
    const data = res?.data?.facility
    const defaultSchedule = data?.defaultEmsConfigSchedule

    const scheduling = data?.emsConfigSchedules?.find(({ id }) => this.scheduleId == id)

    this.defaultIsActive = shouldSaveAndApplyDefault({
      def: defaultSchedule,
      events: data?.emsConfigSchedules
    })

    if (scheduling) this.scheduling = omitTypename(scheduling)
    if (this.isDefault && this.isUpdating)
      this.scheduling = omitTypename(defaultSchedule) as unknown as EmsConfigSchedule
    if (!this.scheduling) {
      this.scheduling = createEmptyScheduling(this.baseEmsParams)
    } else if (!this.isDefault) {
      const parsed = toVCalendarInputs(this.scheduling.iCalendarEvent)
      this.iCal.title = parsed.event.summary
      this.iCal.description = parsed.event.description
      this.iCal.start = parsed.event.startDate.toJSDate()
      this.iCal.end = parsed.event.endDate.toJSDate()
      if (parsed.rules) {
        this.iCal.hasRecurrance = true
        parsed.rules.options.byweekday.forEach(day => {
          this.days[day].enabled = true
        })
      } else {
        this.iCal.hasRecurrance = false
      }
    }
    if (!this.iCal.hasRecurrance && this.iCal.start) {
      this.iCal.showStart = DateTime.fromJSDate(this.iCal.start, { zone: this.timezone }).toFormat(
        'yyyy-MM-dd'
      )
    }
    this.$scope.$applyAsync(() => {
      this.initialLoad = false
    })

    this.stopLoading()
  }

  $onDestroy() {
    this.emsIoService.disconnect()
  }

  stopLoading() {
    this.$scope.$applyAsync(() => {
      this.loading = false
    })
  }

  store(): void {
    this.$scope.$applyAsync(() => {
      this.loading = true
    })
    this.validationError = emsConfigV2FrontendValidation(
      emsConfigFormToEmsParam(this.scheduling.emsParam) as EmsParam,
      this.l2
    )

    if (this.validationError) {
      this.notifications.onError(this.validationError)
      return this.stopLoading()
    }

    if (this.emsForm && !this.emsForm.$valid) {
      this.notifications.onError(this.l.SETTINGS_INVALID)
      return this.stopLoading()
    }
    if (!this.isDefault) {
      const icalform = this.$scope.iCalendarForm
      if (icalform && !icalform.$valid) {
        const summaryError = icalform.$error.required[0]
        ;(summaryError as any).$$element.focus()
        summaryError.$setTouched()
        this.notifications.onError(this.l.PLEASE_ADD_SUMMARY)
        return this.stopLoading()
      }

      if (this.iCal.hasRecurrance) {
        this.days.forEach(day => {
          if (day.enabled) {
            this.iCal.days.push(day.rrule)
          }
        })

        if (!this.iCal.days.length) {
          this.notifications.onError(this.l.MUST_CHOOSEN_ONE_DAY)
          return this.stopLoading()
        }
      }

      const start = this.iCal.start
      const end = this.iCal.end

      if (start.getHours() === end.getHours() && start.getMinutes() === end.getMinutes()) {
        this.notifications.onError(this.l.START_END_NOT_SAME)
        return this.stopLoading()
      }
      this.iCal.end = checkEndDate(start, end, this.timezone)

      const vCalendar = createVCalendar(this.iCal)
      this.scheduling.iCalendarEvent = vCalendar
      this.scheduling.default = !!this.isDefault
    }

    if (this.isDefault) {
      this.amplitude.logEvent('Storing Modified Ems Config Schedule', {
        eventType: 'editDefault'
      })
      delete this.scheduling?.iCalendarEvent
      this.service
        .setDefaultEmsConfigSchedule(
          this.facilityId,
          this.scheduling as unknown as DefaultEmsConfigScheduleInput,
          this.defaultIsActive
        )
        .then(this.onSuccess(this.l.UPDATED_THE_DEFAULT_APPLIED_NEXT, this.defaultIsActive, false))
        .catch(this.onError(this.l.UPDATED_THE_DEFAULT))
    } else if (this.isUpdating) {
      this.amplitude.logEvent('Storing Modified Ems Config Schedule', {
        eventType: 'updateExisting'
      })
      this.service
        .updateEmsConfigSchedule(this.facilityId, this.scheduling)
        .then(this.onSuccess(this.l.UPDATE_THE))
        .catch(this.onError(this.l.UPDATE_THE))
    } else {
      this.amplitude.logEvent('Storing Modified Ems Config Schedule', {
        eventType: 'creatingNew'
      })
      this.service
        .createEmsConfigSchedule(this.facilityId, this.scheduling)
        .then(this.onSuccess(this.l.CREATE_A_NEW))
        .catch(this.onError(this.l.CREATE_A_NEW))
    }
  }

  onSuccess(message: string, waitForTransition?: boolean, notUseDefault?: boolean) {
    this.hideToastFn?.()
    return () => {
      if (!waitForTransition) {
        if (notUseDefault) this.notifications.onSuccess(message)
        else this.notifications.onSuccess(this.l.SUCCESSFULLY.replace('{VALUE}', message))
        this.stopLoading()
        this.$state.go(systemSettingsStates.scheduling)
      }
      if (waitForTransition)
        this.hideToastFn = this.notifications.onInfo(this.l2.WAIT_FOR_CONFIG_TO_BE_APPLIED_ON_SYSTEM, 0)
    }
  }

  onError(message: string) {
    this.hideToastFn?.()
    return () => {
      this.notifications.onError(this.l.FAILED_TO.replace('{VALUE}', message))
    }
  }

  cancel() {
    this.$state.go(systemSettingsStates.scheduling)
  }

  wizard(): void {
    this.amplitude.logEvent('Using Battery Wizard On Ems Config')

    this.$mdDialog
      .show({
        locals: {
          config: this.scheduling.emsParam
        },
        controller: [
          '$mdDialog',
          'config',
          EmsConfigV2HttpServiceName,
          FerroConfigurationName,
          LanguageServiceName,
          BatteryWizardController
        ],
        templateUrl: batteryWizardTemplate,
        controllerAs: 'vm',
        parent: angular.element(document.body),
        clickOutsideToClose: true
      })
      .then((config: EmsParam) => {
        this.$scope.$applyAsync(() => {
          this.scheduling.emsParam = config
        })
      })
  }
}

EmsConfigSchedulePickerController.$inject = [
  FerroConfigurationName,
  'notificationService',
  SystemConfigurationGraphqlServiceName,
  LanguageServiceName,
  AmplitudeServiceName,
  '$state',
  '$scope',
  '$mdDialog',
  EmsConfigV2IoServiceName
]

export const EmsSchedulePickerComponentName = 'emsSchedulingPicker'
export const EmsSchedulePickerComponent: IComponentOptions = {
  controller: EmsConfigSchedulePickerController,
  templateUrl
}
