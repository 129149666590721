import { IComponentOptions } from 'angular'
import { LanguageSystemdashboard } from '../../../language/language.interface'
import { FerroConfiguration, FerroConfigurationName } from '../../../service'
import './evse-status.component.scss'
export enum EvseStatus {
  CHARGING = 'charging',
  OFFLINE = 'offline',
  ONLINE = 'online'
}

class EvseStatusController {
  l: LanguageSystemdashboard
  constructor(ferroConfiguration: FerroConfiguration) {
    this.l = ferroConfiguration.language.SystemDashboard
  }

  resolveStatus(status: EvseStatus): string {
    switch (status) {
      case EvseStatus.ONLINE:
        return this.l.ONLINE
      case EvseStatus.OFFLINE:
        return this.l.OFFLINE
      case EvseStatus.CHARGING:
        return this.l.CHARGING
      default:
        return EvseStatus.OFFLINE
    }
  }
}

EvseStatusController.$inject = [FerroConfigurationName]

export const EvseStatusComponent: IComponentOptions = {
  controller: EvseStatusController,
  template: ` 
        <div class='evse-status' ng-class="$ctrl.status">
            <h3>
            {{$ctrl.resolveStatus($ctrl.status)}} 
            </h3>
        </div>
    `,
  bindings: {
    status: '='
  }
}
