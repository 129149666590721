import { StateService } from '@uirouter/angularjs'
import angular, { IComponentOptions, IScope, material } from 'angular'
import { LanguageAnalaysis } from '../../../language/language.interface'
import { FerroConfiguration, FerroConfigurationName } from '../../../service'
import { MdbAnalysisLoadEventDetectionJobs, MdbAnalysisResultsId } from '../@types/load-event-detection'
import { AnalysisHttpService, AnalysisHttpServiceName, ParsedStoredAnalysis } from '../analysis-http.service'
import { systemAnalysisStates } from '../system-analysis.routing'

type chooseOneCtlResponse = {
  type: 'scatter' | 'signature'
  resultsId: MdbAnalysisResultsId
}

import templateUrl from './stored-analysis.component.html'

function chooseOneController(
  $scope: IScope,
  $mdDialog: material.IDialogService,
  resultId: MdbAnalysisResultsId
) {
  this.scatter = () => {
    $mdDialog.hide({ type: 'scatter', resultsId: resultId })
  }

  this.signature = () => {
    $mdDialog.hide({ type: 'signature', resultsId: resultId })
  }
  this.hide = () => {
    $mdDialog.cancel()
  }
}

class StoredAnalysisController {
  l: LanguageAnalaysis
  analysisJobs: ParsedStoredAnalysis[] = []

  errorMessage = ''
  okMessage = ''

  fcService: FerroConfiguration
  aService: AnalysisHttpService

  $state: StateService
  dialog: material.IDialogService

  onResultsId?: (resultsId: MdbAnalysisResultsId) => void
  analysisType?: string

  constructor(
    ferroConfiguration: FerroConfiguration,
    $mdDialog: material.IDialogService,
    $state: StateService,
    analysisService: AnalysisHttpService
  ) {
    const facilityId = Number(ferroConfiguration.facility.id)
    this.$state = $state
    this.dialog = $mdDialog
    this.fcService = ferroConfiguration
    this.aService = analysisService
    this.l = ferroConfiguration.language.SystemAnalysis

    analysisService.getStoredLoadEventDetection(facilityId, (error, list) => {
      if (error) {
        if (error === 'NO_DATA') {
          this.errorMessage = this.l.NO_GENERATED_ANALYSIS
        } else this.errorMessage = this.l.ERROR_OCCURRED_LIST
      } else {
        this.analysisJobs = list.map(d => {
          d.status = d.jobstatus === 0 ? this.l.ANALYSIS_SUCCESS : this.l.ANALYSIS_ERROR
          return d
        })
      }
    })
  }

  removeThisAnalys(ev: MouseEvent, resultid: MdbAnalysisResultsId): void {
    this.okMessage = ''
    this.errorMessage = ''
    // Appending dialog to document.body to cover sidenav in docs app
    this.fcService.confirm(this.l.LIKE_TO_DELETE_ANALYSIS, '', this.l.DELETE, this.l.KEEP, ev).then(() => {
      this.aService.deleteSavedAnalysis(resultid, Number(this.fcService.facility.id), (error, data) => {
        if (error) {
          this.errorMessage = error.data
        } else {
          const jobs: ParsedStoredAnalysis[] = []
          this.analysisJobs.forEach(d => {
            if (d) {
              if (d.resultsid !== resultid) {
                jobs.push(d)
              }
            }
          })
          this.analysisJobs = jobs
          this.okMessage = `${data.message} with resultsid: ${resultid}`
        }
      })
    })
  }

  // eslint-disable-next-line
  openThisAnalysis(ev: MouseEvent, analysis: any | MdbAnalysisLoadEventDetectionJobs) {
    if (this.onResultsId) {
      this.onResultsId(analysis.resultsid)
      return
    }

    const chooseScatterOrSignature = `<md-dialog ng-cloak>
<form>
  <md-dialog-content>
    <div class="md-dialog-content">
      <h2> ${this.l.CHOOSE_ONE}</h2>
    </div>
  </md-dialog-content>
<md-dialog-actions layout="row">
    <span flex></span>
    <md-button ng-click="ctl.scatter()">   ${this.l.FUSE_ANALYSIS} </md-button>
  </md-dialog-actions>
  <md-dialog-actions layout="row">
    <span flex></span>
    <md-button ng-click="ctl.signature()">${this.l.LOAD_SIGNATURE}</md-button></md-dialog-actions>
  <md-dialog-actions layout="row">
    <span flex></span>
    <md-button ng-click="ctl.hide()"> ${this.l.NO_ONE}</md-button>
  </md-dialog-actions></form>
</md-dialog>`

    this.okMessage = ''
    this.errorMessage = ''

    const params = { resultsId: analysis.resultsid }
    if (analysis.histtype === 'signscatt') {
      this.dialog
        .show({
          locals: {
            resultsId: analysis.resultsid
          },
          controller: ['$scope', '$mdDialog', 'resultsId', chooseOneController],
          controllerAs: 'ctl',
          template: chooseScatterOrSignature,
          parent: angular.element(document.body),
          targetEvent: ev
        })
        .then((result: chooseOneCtlResponse) => {
          if (result.type === 'scatter') {
            this.$state.go(systemAnalysisStates.fuseAnalysis, params)
          } else if (result.type === 'signature') {
            this.$state.go(systemAnalysisStates.loadSignature, params)
          }
        })
        .catch()
    }
  }
}

StoredAnalysisController.$inject = [FerroConfigurationName, '$mdDialog', '$state', AnalysisHttpServiceName]

export const StoredAnalysisComponentName = 'storedAnalysisComponent'

export const StoredAnalysisComponent: IComponentOptions = {
  templateUrl,
  controller: StoredAnalysisController,
  bindings: {
    onResultsId: '<'
  }
}
