import { StateService } from '@uirouter/angularjs'
import { IComponentOptions, IFormController, material } from 'angular'
import { TimezoneObject } from '../../../types'
import { LanguageGlobalSettings, LanguageSystemSettings } from '../../language/language.interface'
import { AmplitudeService, FerroConfiguration, FerroConfigurationName } from '../../service'
import {
  geoLocationRequest,
  StaticDataHttpService,
  StaticDataHttpServiceName
} from '../../service/static-data-http.service'
import './create-system.component.scss'

import { NotificationService } from '@app/components'
import { runQuery } from '@app/graphql/graphql'
import { AmplitudeServiceName } from '@app/service/amplitude.service'
import { SuccessfulFacilityCreatedResponse } from '@app/service/facility-data-http.service'
import { SystemStates } from '@app/system/system-routing.config'
import { getBrowserTimeZone } from '@lib/date-and-time'
import templateUrl from './create-system.component.html'
import { EnergyBiddingZone } from '@app/graphql/generated'

class CreateSystemController {
  timezones: TimezoneObject[]

  $state: StateService

  l: LanguageGlobalSettings
  l2: LanguageSystemSettings
  loading = false
  system: {
    name: string | undefined
    timezone: string | undefined
    device_id1: string | undefined
    device_id2: string | undefined
    device_id3: string | undefined
    device_id4: string | undefined
    country: string | undefined
    city: string | undefined
    address: string | undefined
    energyBiddingZone: EnergyBiddingZone | undefined
  } = {} as any
  error = ''
  formattedAddress: string
  errorGeo: string
  errorTimezone: string
  errorGeoReq: string
  latitude: number | null = null
  longitude: number | null = null
  facilityResponse: SuccessfulFacilityCreatedResponse | null = null

  service: StaticDataHttpService
  amplitudeService: AmplitudeService

  notis: NotificationService
  toast: material.IToastService
  energyBiddingZones = Object.values(EnergyBiddingZone)

  constructor(
    s: StaticDataHttpService,
    $state: StateService,
    ferroConfiguration: FerroConfiguration,
    amplitudeService: AmplitudeService,
    notification: NotificationService,
    toast: material.IToastService
  ) {
    this.toast = toast
    this.l = ferroConfiguration.language.GlobalSettings
    this.l2 = ferroConfiguration.language.SystemSettings
    this.service = s
    this.$state = $state
    this.notis = notification
    s.getTimezones(timezones => {
      this.timezones = timezones
    })
    this.system.timezone = getBrowserTimeZone()
    this.amplitudeService = amplitudeService
  }

  geoRequest(): void {
    const system = this.system

    const geoLocationRequest: geoLocationRequest = {
      city: system.city,
      country: system.country,
      address: system.address
    }
    this.formattedAddress = null
    this.errorGeo = null
    if (!geoLocationRequest.address) {
      this.errorGeo = `${this.l.PLEASE_FILL} ${this.l.ADDRESS}`
    } else if (!geoLocationRequest.city) {
      this.errorGeo = `${this.l.PLEASE_FILL} ${this.l.CITY}`
    } else if (!geoLocationRequest.country) {
      this.errorGeo = `${this.l.PLEASE_FILL} ${this.l.COUNTRY}`
    } else {
      this.service.getGeoLocations(geoLocationRequest, (error, location) => {
        if (error) {
          this.errorGeoReq = this.l.ERROR_OCCURRED
        } else {
          if (!location) {
            this.errorTimezone = this.l.TIMEZONE_ERROR
          } else {
            this.latitude = location.lat
            this.longitude = location.lng
            this.formattedAddress = location.formatted_address
            this.system.timezone = location.tz
          }
        }
      })
    }
  }

  async addSystem(form: IFormController) {
    if (this.loading) return

    this.loading = true
    this.error = ''
    this.facilityResponse = null
    if (!form.$valid) {
      this.error = this.l.FORM_INVALID
      return
    }

    const device_id = `${this.system.device_id1}-${this.system.device_id2}-${this.system.device_id3}-${this.system.device_id4}`
    if (device_id.length !== 35) {
      this.error = this.l.DEVICE_IDENTIFICATION_LENGTH
      return
    }

    const { name, timezone, city, country, address, energyBiddingZone } = this.system
    try {
      const res = await runQuery.createOneFacilityMutation({
        variables: {
          registrationKey: device_id.toLowerCase(),
          facilityData: {
            name,
            timezone,
            installedOn: new Date(),
            location: {
              latitude: this.latitude,
              longitude: this.longitude,
              address,
              city,
              country,
              energyBiddingZone
            }
          }
        }
      })

      const data = res.data?.createOneGenerationOneFacility

      if (data?.success) {
        form.$setPristine()
        form.$setUntouched()
        this.amplitudeService.logEvent('Create System', {
          facilityId: res?.data?.createOneGenerationOneFacility?.facility?.id
        })
        const t = setTimeout(() => {
          this.$state.go(SystemStates.dashboard, { id: data.facility.id })
        }, 10000)
        this.toast
          .show(
            this.toast
              .simple()
              .textContent(`${this.l.ADD_SYSTEM_SUCCESS}. ${this.l.NEW_SYSTEM_LINK}.`)
              .toastClass('toast-success-message')
              .action('Ok')
              .position('bottom center')
              .highlightAction(true)
              // Accent is used by default, this just demonstrates the usage.
              .highlightClass('md-accent')
              .hideDelay(0)
          )
          .then(response => {
            if (response === 'ok') {
              clearTimeout(t)
              this.$state.go(SystemStates.dashboard, { id: data.facility.id })
            }
          })
          .catch(_error => {
            return
          })
      } else if (!data.success) {
        form.$setDirty()
        this.error = data.errors
          .map(err => {
            return this.l[err.__typename]
          })
          .join('. ')
        this.notis.onError(this.error)
      } else {
        form.$setDirty()

        this.error = this.l.ERROR_OCCURRED
        this.notis.onError(this.error)
      }
    } catch {
      this.error = this.l.ERROR_OCCURRED
    }

    this.loading = false
  }
}

CreateSystemController.$inject = [
  StaticDataHttpServiceName,
  '$state',
  FerroConfigurationName,
  AmplitudeServiceName,
  'notificationService',
  '$mdToast'
]

export const CreateSystemComponentName = 'createSystemComponent'

export const CreateSystemComponent: IComponentOptions = {
  controller: CreateSystemController,
  templateUrl
}
