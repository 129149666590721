import { select, Selection } from 'd3-selection'
import { svg } from 'd3-fetch'
import { dotHidden, genPowerText, isValidValue, PowerSchematicValues, roundValues } from '../schematic.tools'
import { LanguageSystemdashboard } from '../../../app/language/language.interface'
import powerSchematicUrl from './powershare-schematic.svg'
import ehubFront from '../../../assets/images/ehub_front.png'
import { elementExists, getId } from '../../general'

export interface MultisystemSchematicLanguage {
  SELLING: string
  BUYING: string
  DISCHARGING: string
  CHARGING: string
  SOLAR: string
  BATTERY: string
  CONSUMPTION: string
  GRID: string
  EXTERNAL_PRODUCTION: string
  DC_MICROGRID: string
  RATED_CAPACITY: string
  STATE_OF_CHARGE: string
}

interface Dots {
  [k: string]: Selection<Element, unknown, HTMLElement, undefined>

  gridDotExt: Selection<Element, unknown, HTMLElement, undefined>
  gridDotImp: Selection<Element, unknown, HTMLElement, undefined>
  consumptionDot: Selection<Element, unknown, HTMLElement, undefined>
  consumptionExtDot: Selection<Element, unknown, HTMLElement, undefined>
  ehubAcGridDotOut: Selection<Element, unknown, HTMLElement, undefined>
  ehubAcGridDotIn: Selection<Element, unknown, HTMLElement, undefined>
  ehubDcGridDotOut: Selection<Element, unknown, HTMLElement, undefined>
  ehubDcGridDotIn: Selection<Element, unknown, HTMLElement, undefined>
}

export class PowershareSchematic {
  private id: string
  private url = powerSchematicUrl
  private svg: Selection<HTMLElement, unknown, null, undefined>
  private isReady = false

  private limits = {
    upperLimit: 0.3,
    lowerLimit: -0.3
  }

  private gridPower: Selection<Element, unknown, HTMLElement, undefined> = null
  private consumptionPower: Selection<Element, unknown, HTMLElement, undefined> = null
  private ehubPower: Selection<Element, unknown, HTMLElement, undefined> = null

  private direction: {
    ehub: number
    consumption: number
    grid: number
    battery: number
    pv: number
  } = {
    ehub: null,
    consumption: null,
    grid: null,
    battery: null,
    pv: null
  }

  private xml: HTMLElement
  private language: MultisystemSchematicLanguage

  private ehubDcConnector: Selection<Element, unknown, HTMLElement, undefined> = null

  private dcBrigdePower: Selection<Element, unknown, HTMLElement, undefined>
  externalProduction: Selection<Element, unknown, HTMLElement, undefined>
  batteryDirection: Selection<Element, unknown, HTMLElement, undefined>
  gridDirection: Selection<Element, unknown, HTMLElement, undefined>
  dcBridgePowerDirection: Selection<Element, unknown, HTMLElement, undefined>
  dcVOLTAGETEXTneg: Selection<Element, unknown, HTMLElement, undefined>
  dcVOLTAGETEXTpos: Selection<Element, unknown, HTMLElement, undefined>

  private dots: Dots = {
    gridDotExt: null,
    gridDotImp: null,
    consumptionDot: null,
    consumptionExtDot: null,
    ehubAcGridDotIn: null,
    ehubAcGridDotOut: null,
    ehubDcGridDotIn: null,
    ehubDcGridDotOut: null
  }

  constructor(id: string, language: LanguageSystemdashboard) {
    this.id = id
    this.language = {
      SELLING: language.SELLING,
      BUYING: language.BUYING,
      DISCHARGING: language.DISCHARGING,
      CHARGING: language.CHARGING,
      SOLAR: language.SOLAR,
      BATTERY: language.BATTERY,
      CONSUMPTION: language.CONSUMPTION,
      GRID: language.GRID,
      EXTERNAL_PRODUCTION: language.EXTERNAL_PRODUCTION,
      DC_MICROGRID: language.DC_MICROGRID,
      RATED_CAPACITY: language.RATED_CAPACITY,
      STATE_OF_CHARGE: language.STATE_OF_CHARGE
    }

    svg(this.url).then(xml => {
      this.xml = xml.documentElement
      this.addSchematic()
    })
  }

  private addSchematic(): void {
    elementExists(this.id)
    const id = getId(this.id)
    id.appendChild(this.xml)
    this.svg = select(id)
    this.start()
    this.isReady = true
  }

  private start(): void {
    // const children = this.svg[0][0].children;
    this.svg.select('#EhubImage').attr('xlink:href', ehubFront)
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this
    this.svg.selectAll('#dot_layer circle').each(function () {
      const _dot = select(this)
      that.dots[_dot.attr('id')] = select<Element, unknown>(this as Element)
    })

    this.svg.select('#gridTitle').text(this.language.GRID)
    this.svg.select('#consumptionTitle').text(this.language.CONSUMPTION)
    this.svg.select('#dcBrigdeTitle').text(this.language.DC_MICROGRID)

    this.externalProduction = this.svg.select('#consumptionExternalProduction')
    this.ehubPower = this.svg.select('#ehubPowerText')
    this.ehubDcConnector = this.svg.select('#ehubDcGridPath')
    this.batteryDirection = this.svg.select('#batteryPowerDirectionText')
    this.gridDirection = this.svg.select('#gridPowerDirection')
    this.gridPower = this.svg.select('#GridPowerText')
    this.consumptionPower = this.svg.select('#ConsumptionPowerText')
    this.dcBrigdePower = this.svg.select('#dc_bridge_power_text')
    this.dcBridgePowerDirection = this.svg.select('#dcBrigdePowerDirection')

    this.dcVOLTAGETEXTneg = this.svg.select('#dcVOLTAGETEXTneg')
    this.dcVOLTAGETEXTpos = this.svg.select('#dcVOLTAGETEXTpos')
    this.isReady = true
  }

  private showHideDcConnector(show: boolean): void {
    this.ehubDcConnector.style('fill', show ? '#bdbdbd' : 'none')
  }

  private dotHandler(): void {
    if (this.direction.grid < 0) {
      this.dots.gridDotExt.classed(dotHidden, false)
      this.dots.gridDotImp.classed(dotHidden, true)
    } else if (this.direction.grid > 0) {
      this.dots.gridDotExt.classed(dotHidden, true)
      this.dots.gridDotImp.classed(dotHidden, false)
    } else {
      this.dots.gridDotExt.classed(dotHidden, true)
      this.dots.gridDotImp.classed(dotHidden, true)
    }

    if (this.direction.consumption > 0) {
      this.dots.consumptionDot.classed(dotHidden, false)
      this.dots.consumptionExtDot.classed(dotHidden, true)
    } else if (this.direction.consumption < 0) {
      this.dots.consumptionDot.classed(dotHidden, true)
      this.dots.consumptionExtDot.classed(dotHidden, false)
    } else {
      this.dots.consumptionDot.classed(dotHidden, true)
      this.dots.consumptionExtDot.classed(dotHidden, true)
    }
    if (this.direction.ehub < 0) {
      this.dots.ehubAcGridDotOut.classed(dotHidden, false)
      this.dots.ehubAcGridDotIn.classed(dotHidden, true)
      this.dots.ehubDcGridDotOut.classed(dotHidden, true)
      this.dots.ehubDcGridDotIn.classed(dotHidden, false)
    } else if (this.direction.ehub > 0) {
      this.dots.ehubAcGridDotOut.classed(dotHidden, true)
      this.dots.ehubAcGridDotIn.classed(dotHidden, false)
      this.dots.ehubDcGridDotOut.classed(dotHidden, false)
      this.dots.ehubDcGridDotIn.classed(dotHidden, true)
    } else {
      this.dots.ehubAcGridDotOut.classed(dotHidden, true)
      this.dots.ehubAcGridDotIn.classed(dotHidden, true)
      this.dots.ehubDcGridDotOut.classed(dotHidden, true)
      this.dots.ehubDcGridDotIn.classed(dotHidden, true)
    }
  }

  public update(newValues: PowerSchematicValues): void {
    if (!newValues) return
    const values = roundValues(newValues)
    if (!values || !this.isReady) {
      return
    }

    this.direction.grid = isValidValue(values.gridPower, this.limits)
    this.direction.consumption = isValidValue(values.loadPower, this.limits)
    this.direction.ehub = isValidValue(values.ehubPower, this.limits)

    if (values.gridPower < 0) {
      this.gridDirection.text(this.language.SELLING)
    } else if (values.gridPower > 0) {
      this.gridDirection.text(this.language.BUYING)
    } else {
      this.gridDirection.text('')
    }
    if (values.loadPower < 0) {
      this.externalProduction.text(this.language.EXTERNAL_PRODUCTION)
    } else if (values.loadPower > 1) {
      this.externalProduction.text('')
    } else {
      this.externalProduction.text('')
    }

    this.ehubPower.text(genPowerText(values.ehubPower, this.limits))
    this.gridPower.text(genPowerText(values.gridPower, this.limits))
    this.consumptionPower.text(genPowerText(values.loadPower, this.limits))
    this.dcBrigdePower.text(genPowerText(values.ehubPower, this.limits))

    this.dotHandler()
  }
}
