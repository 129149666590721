import { FuseAnalysisComponentName } from './fuse-analysis/fuse-analysis.component'
import { LoadSignatureComponentName } from './load-signature/load-signature.component'
import { DailyTrendCompnentName } from './daily-trend/daily-trend.component'
import { StoredAnalysisComponentName } from './stored-analysis/stored-analysis.component'
import { StateProvider } from '@uirouter/angularjs'
import { BatterySimulationComponentName } from './index'

export const systemAnalysisStates = {
  analysis: 'system.analysis',
  dailyTrend: 'system.analysis.dailyTrend',
  fuseAnalysis: 'system.analysis.fuseAnalysis',
  loadSignature: 'system.analysis.loadSignature',
  batterySimulation: 'system.analysis.batterySimulation',
  storedAnalysis: 'system.analysis.storedAnalysis'
}

export function systemAnalysisRouting($stateProvider: StateProvider): void {
  $stateProvider
    .state(systemAnalysisStates.analysis, {
      url: '/analysis'
    })
    .state(systemAnalysisStates.fuseAnalysis, {
      url: '/fuse?resultsId',
      component: FuseAnalysisComponentName,
      params: {}
    })
    .state(systemAnalysisStates.loadSignature, {
      url: '/signature?resultsId',
      component: LoadSignatureComponentName
    })
    .state(systemAnalysisStates.dailyTrend, {
      url: '/daily-trend',
      component: DailyTrendCompnentName
    })

    .state(systemAnalysisStates.storedAnalysis, {
      url: '/stored',
      component: StoredAnalysisComponentName
    })
    .state(systemAnalysisStates.batterySimulation, {
      url: '/battery-simulation',
      component: BatterySimulationComponentName
    })
}

systemAnalysisRouting.$inject = ['$stateProvider']
