import { DEFAULT_TIMEZONE } from '../../environments/environment'
import { getFerroConfiguration } from '../general/get-ferroconfiguration'

/**
 * Handle idiotic date from Python
 * @param date
 */
export const rawTS = (date: string): Date => {
  date = date.replace('T', ' ')
  date = date.replace('UTC', '')
  const arr = date.split(/[- :]/)
  return new Date(
    Date.UTC(
      Number(arr[0]),
      Number(arr[1]) - 1,
      Number(arr[2]),
      Number(arr[3]),
      Number(arr[4]),
      Number(arr[5])
    )
  )
}

export const daysInMonth = (year: number, month: number): number => {
  return new Date(year, month, 0).getDate()
}

export const getMonday = (d: Date): Date => {
  d = new Date(d)
  const day = d.getDay()
  const diff = d.getDate() - day + (day === 0 ? -6 : 1) // adjust when day is sunday
  return new Date(d.setDate(diff))
}

export function zeropad(x: number): string {
  if (x < 10) return `0${x}`
  else return `${x}`
}

function getMonthString(d: number, type: 'monthlon' | 'monshort'): string {
  const ferroConfiguration = getFerroConfiguration()
  let d2
  const l = ferroConfiguration.language.Globally[type]

  if (d === 0) d2 = l[0]
  else if (d === 1) d2 = l[1]
  else if (d === 2) d2 = l[2]
  else if (d === 3) d2 = l[3]
  else if (d === 4) d2 = l[4]
  else if (d === 5) d2 = l[5]
  else if (d === 6) d2 = l[6]
  else if (d === 7) d2 = l[7]
  else if (d === 8) d2 = l[8]
  else if (d === 9) d2 = l[9]
  else if (d === 10) d2 = l[10]
  else if (d === 11) d2 = l[11]
  return d2
}

export const monthMMM = (d: number): string => {
  return getMonthString(d, 'monshort')
}

export const monthWhole = (d: number): string => {
  return getMonthString(d, 'monthlon')
}

/**
 * inserting from year and building a list starting from 2000
 * @param {Number} ThisYear
 * @return {Array}
 */
export const getListedYears = (ThisYear: number): number[] => {
  const yearArray = []
  if (ThisYear > 2050 || ThisYear < 2000) {
    return
  }
  for (let k = ThisYear; k >= 1999; k--) {
    yearArray.push(k)
  }
  return yearArray
}

export const ISOdate = (date: Date): string => {
  const d = new Date(date)
  const yyyy = d.getFullYear(),
    mm = d.getMonth() + 1,
    dd = d.getDate(),
    hh = d.getHours(),
    ss = d.getSeconds()
  const fs = `${yyyy}-${zeropad(mm)}-${zeropad(dd)}`
  if (hh === 0 && ss === 0) return fs
  return `${fs} ${zeropad(hh)}:${zeropad(ss)}`
}

export const getTimezoneString = (): string => {
  const ferroConfiguration = getFerroConfiguration()
  return ferroConfiguration.facility.timezone || DEFAULT_TIMEZONE
}

export const timezoneOffset = (): number => {
  // returns timezone in minutes
  return new Date().getTimezoneOffset()
}
export const firstofmonth = (d: Date): Date => {
  let date = new Date()
  if (d) date = new Date(d)
  return new Date(date.getFullYear(), date.getMonth(), 1)
}
export const dateAtMidnight = (): Date => {
  const d = new Date()
  const date = new Date(d.setDate(d.getDate() - 1))
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0)
}

export const getMidnightTime = (dateIn: Date): Date => {
  const date = new Date(dateIn)
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0)
}

export const setFromDateWeek = (d: Date): Date => {
  d = new Date(d)
  const day = d.getDay(),
    diff = d.getDate() - day + (day === 0 ? -6 : 1)
  const p = new Date(d.setDate(diff))
  return new Date(p.getFullYear(), p.getMonth(), p.getDate())
}

export const setFromDateDay = (d: Date): Date => {
  d = new Date(d)
  return new Date(d.getFullYear(), d.getMonth(), d.getDate())
}

/**
 * @desc if seconds above 3600 returns hours, if seconds above 60 retunr minutes else return seconds
 * @return HH hours MM minutes SS seconds
 *
 * Used in fuseAnalytics_service titleTooltip
 * @TODO multi language
 */
export const getNumberOfTime = function (seconds: number): string | number {
  let ss, mm
  if (seconds < 60) return `${Math.round(seconds)} Seconds`
  else if (seconds >= 60 && seconds < 3600) {
    ss = seconds % 60
    mm = (seconds - ss) / 60
    return `${mm} minutes ${Math.round(ss)} seconds`
  } else if (seconds > 3600) {
    const sm = seconds % 3600
    const hh = (seconds - sm) / 3600
    ss = sm % 60
    mm = (sm - ss) / 60
    return `${hh} hours ${mm} minutes ${Math.round(ss)} seconds`
  } else return NaN
}
