import { IQService, IComponentOptions, IDeferred, IHttpResponse, IScope } from 'angular'
import moment from 'moment-timezone'
import { FerroConfiguration, ElectricalComponentColors, AmplitudeService } from '../../../service'
import { EnergyViewService } from './energy-view.service'
import { LanguageViews } from '../../../language/language.interface'
import { ProcessedEchartEnergyData } from './energy-view.tools'
import './energy-view.component.scss'
import { DEFAULT_TIMEZONE } from '../../../../environments/environment'
import { createEnergyViewChart } from './energy-view.chart'
import { EChartOption, ECharts } from 'echarts'
import templateUrl from './energy-view.component.html'
import {
  clearEchartsInstance,
  echartsNoDataTitleOptions,
  echartsShowLoadingOptions,
  EchartsShowLoadingType,
  echartsTimeFormatters,
  tooltipDateFormatter
} from '../../../../lib'
import { AmplitudeServiceName } from '@app/service/amplitude.service'
import { energyViewDownloadCsv } from './download-csv'

type PlotFormat = 'accumulated' | 'relative'

class EnergyViewController {
  loading = true
  error = ''
  l: LanguageViews
  plotFormat: PlotFormat = 'relative'
  resolution = 'hour'
  errormsg = false
  readonly maxDate: Date
  readonly minDate: Date
  readonly maxDateDatepicker: Date
  readonly minDateDatepicker: Date
  showpies = true
  procornot: string
  LABEL2: string[]
  activeDecision = false

  canceller: IDeferred<unknown>
  resolved = false

  totalBarChart: ECharts
  perPhaseBarChart: ECharts
  showOne = {
    pv: true,
    load: true,
    boughtgrid: true,
    ownpv: true,
    soldpv: true
  }
  data: ProcessedEchartEnergyData
  starttime: Date
  endtime: Date

  colors: ElectricalComponentColors

  $q: IQService
  $s: IScope

  service: EnergyViewService
  timezone: string
  fc: FerroConfiguration
  amplitudeService: AmplitudeService

  constructor(
    $scope: IScope,
    $q: IQService,
    ferroConfiguration: FerroConfiguration,
    energyViewService: EnergyViewService,
    amplitudeService: AmplitudeService
  ) {
    this.amplitudeService = amplitudeService
    this.service = energyViewService
    this.$q = $q
    this.$s = $scope
    this.fc = ferroConfiguration
    this.canceller = $q.defer()
    this.l = ferroConfiguration.language.Views
    const facility = ferroConfiguration.facility
    this.colors = ferroConfiguration.electricColors
    this.timezone = facility.timezone ? facility.timezone : DEFAULT_TIMEZONE
    this.maxDate = ferroConfiguration.getMaxDate()
    this.minDate = ferroConfiguration.getMinDate()
    this.maxDateDatepicker = new Date(this.maxDate)
    this.minDateDatepicker = new Date(this.minDate)
    this.starttime = moment.tz(new Date(), this.timezone).subtract(30, 'days').startOf('day').toDate() // new Date(new Date().setDate(-30));
    this.endtime = moment.tz(new Date(), this.timezone).endOf('day').toDate()

    energyViewService.init(Number(facility.id), this.timezone, this.canceller.promise)
  }

  $onDestroy(): void {
    if (this.totalBarChart) clearEchartsInstance(this.totalBarChart)
    this.canceller.resolve()
  }

  onNoData(): void {
    if (this.totalBarChart) {
      this.totalBarChart.setOption({
        title: echartsNoDataTitleOptions(this.l.NO_DATA_FOUND),
        dataset: { source: [] }
      })
    } else {
      this.error = this.l.NO_DATA_FOUND
    }
    this.stopLoading()
  }

  private stopLoading() {
    this.$s.$applyAsync(() => {
      this.loading = false
    })
    if (this.totalBarChart) this.totalBarChart.hideLoading()
  }

  private _onPeriodResult(error: IHttpResponse<string>, result?: ProcessedEchartEnergyData): void {
    if (error) {
      if (error.status === 204) this.onNoData()
      else this.error = this.l.ERROR_OCCURRED
    } else if (!result) {
      this.onNoData()
    } else {
      this.plotNew(result)
    }
  }

  private _onGetAccumulated(error: IHttpResponse<string>, result?: ProcessedEchartEnergyData): void {
    if (error) {
      if (error.status) this.onNoData()
      else this.error = this.l.ERROR_OCCURRED
    } else if (!result) {
      this.onNoData()
    } else {
      this.plotNew(result)
    }
  }

  private _checkMonthDateRange(): boolean {
    if ('month' === this.resolution) {
      const diff = new Date(this.endtime).getTime() - new Date(this.starttime).getTime()
      if (diff < 7776000000) {
        this.error = this.l.WHEN_MONTH_ATLEAST_THREE_MONTH
        return false
      } else {
        return true
      }
    } else {
      return true
    }
  }

  changePeriod(): void {
    this.activeDecision = true
    this.loading = true
    if (this.totalBarChart)
      this.totalBarChart.showLoading(EchartsShowLoadingType, echartsShowLoadingOptions())
    this.error = ''
    if (this.resolved) {
      this.canceller.resolve('http call aborted')
    }
    this.canceller = this.$q.defer()
    this.resolved = true

    if (new Date(this.starttime) > new Date(this.endtime)) {
      this.error = this.l.ENDTIME_GREATER_STARTTIME
    } else if (new Date(this.starttime) > this.maxDate || new Date(this.endtime) > this.maxDate) {
      this.error = 'Error: Cannot choose dates in the future.'
    } else {
      this._getData()
    }
  }

  private _getData(): void {
    if (this.plotFormat === 'accumulated') {
      this.resolution = 'hour'
      this.service.getAccumulated(this.starttime, this.endtime, this._onGetAccumulated.bind(this))
    } else {
      this.endtime = moment.tz(this.endtime, this.timezone).endOf('day').toDate()

      switch (this.resolution) {
        case 'equartz':
          this.service.getEquartz(this.starttime, this.endtime, this._onPeriodResult.bind(this))
          break
        case 'hour':
          this.service.getDataHourly(this.starttime, this.endtime, (error, result) => {
            this._onPeriodResult(error, result)
          })
          break
        case 'day':
        case 'week':
        case 'month':
        case 'year':
          if (this._checkMonthDateRange()) {
            this.service.getLowerResolution(
              this.starttime,
              this.endtime,
              this.resolution,
              this._onPeriodResult.bind(this)
            )
          }
          break
        default:
          this.error = this.l.CHOICE_INVALID
      }
    }

    const daysFromToday = moment
      .tz(this.timezone)
      .endOf('day')
      .diff(moment.tz(this.starttime, this.timezone), 'days')

    this.amplitudeService.logEvent('Get Energy Data', {
      plotFormat: this.plotFormat,
      resolution: this.resolution,
      daysFromToday,
      activeDecision: this.activeDecision
    })
  }

  getCorrectCustomDateValue(): string {
    const useDates = ['day', 'week', 'year']
    if (useDates.indexOf(this.resolution) + 1) {
      return 'dates'
    } else if (this.resolution === 'month') {
      return 'yyyymm'
    } else if (this.resolution === 'hour') {
      return 'hours'
    } else if (this.resolution === 'equartz') {
      return 'equartz'
    } else {
      return 'default'
    }
  }

  fixMonthDate(date: Date): Date {
    if (!moment.isDate(date)) return null
    return moment(date).date(1).hours(0).minutes(0).milliseconds(0).toDate()
  }

  fixDataMonth(data: ProcessedEchartEnergyData): ProcessedEchartEnergyData {
    if (this.resolution === 'month') {
      return data
    } else {
      return data
    }
  }

  async plotNew(data: ProcessedEchartEnergyData): Promise<void> {
    data = this.fixDataMonth(data)
    this.data = data
    if (!this.totalBarChart) {
      if (this.totalBarChart) clearEchartsInstance(this.totalBarChart)
      this.totalBarChart = await createEnergyViewChart(data, this.l, this.colors, this.timezone)
    } else {
      const ops = this.yAxisLabelPicker()

      const _data = data.totalEnergyData
      const updateDataOps: EChartOption = {
        title: {
          show: false
        },
        yAxis: {
          name: ops.yAxisName
        },
        tooltip: {
          formatter: ops.formatter
        },
        dataset: {
          source: _data.all
        },
        xAxis: {
          type: ops.xAxisType,
          axisLabel: {
            formatter: ops.xAxisFormatter
          }
        }
      }
      this.totalBarChart.setOption(updateDataOps as EChartOption)
    }
    this.stopLoading()
  }

  yAxisLabelPicker(): {
    yAxisName: string
    formatter: EChartOption.Tooltip.Formatter
    xAxisFormatter: any
    xAxisType: EChartOption.BasicComponents.CartesianAxis.Type
  } {
    const prefix = 'kWh'
    let formatter: EChartOption.Tooltip.Formatter
    let suffix: string

    const UTC = 'UTC'
    let xAxisFormatter = null
    let xAxisType: EChartOption.BasicComponents.CartesianAxis.Type = 'category'
    switch (this.resolution) {
      case 'hour':
        suffix = this.l.HOUR.toLowerCase()
        formatter = tooltipDateFormatter('hours', this.timezone)
        xAxisFormatter = echartsTimeFormatters(this.timezone).hours

        break
      case 'equartz':
        suffix = '15 min'
        xAxisFormatter = echartsTimeFormatters(this.timezone).minutes
        formatter = tooltipDateFormatter('minutes', this.timezone)
        break
      case 'week':
        formatter = tooltipDateFormatter('weekNumber', UTC)
        xAxisFormatter = echartsTimeFormatters(UTC).weekNumber
        xAxisType = 'category'
        break
      case 'day':
        suffix = this.l.DAY.toLowerCase()
        formatter = tooltipDateFormatter('days', UTC)
        xAxisType = 'category'
        break
      case 'month':
        formatter = tooltipDateFormatter('month', UTC)
        suffix = this.l.MONTH.toLowerCase()
        xAxisFormatter = echartsTimeFormatters(UTC).month
        xAxisType = 'category'
        break
      case 'year':
        formatter = tooltipDateFormatter('year', UTC)
        xAxisFormatter = echartsTimeFormatters(UTC).year
        xAxisType = 'category'
        break
      default:
        suffix = ''
    }

    return {
      yAxisName: `${prefix}${suffix ? '/' : ''}${suffix}`,
      formatter,
      xAxisFormatter,
      xAxisType
    }
  }

  changePlotFormat(): void {
    this.activeDecision = true
    if (this.plotFormat === 'accumulated') this.resolution = 'hour'
  }

  download() {
    const { l } = this
    const header = [
      l.DATE_AND_TIME,
      l.SOLAR_PRODUCTION,
      l.CONSUMPTION,
      l.IMPORTED_FROM_GRID,
      l.EXPORTED_TO_GRID,
      l.SELF_CONSUMPTION_OF_SOLAR
    ].map((column, index) => {
      if (index) return `${column} (kWh)`
      return column
    })

    const resolution = this.resolution === 'equartz' ? '15m' : this.resolution
    const fileName = `energy-${Number(
      this.fc.facility.id
    )}-${this.starttime.toISOString()}-${this.endtime.toISOString()}-${resolution}.csv`

    energyViewDownloadCsv({
      fileName,
      header,
      data: this.data.totalEnergyData.all
    })
    this.amplitudeService.logEvent('Download Energy Data', {
      resolution: this.resolution,
      daysFromToday: moment
        .tz(this.timezone)
        .endOf('day')
        .diff(moment.tz(this.starttime, this.timezone), 'days'),

      numberOfDays: moment(this.starttime).diff(moment(this.endtime), 'days')
    })
  }
}

EnergyViewController.$inject = [
  '$scope',
  '$q',
  'ferroConfiguration',
  'energyViewService',
  AmplitudeServiceName
]

export const EnergyViewComponentName = 'energyViewComponent'

export const EnergyViewComponent: IComponentOptions = {
  controller: EnergyViewController,
  templateUrl: templateUrl
}
