/* eslint-disable */

import { IComponentOptions, ITimeoutService } from 'angular'

import { FeatureFlagService, FeatureFlagServiceName } from '@app/service/feature-flag.service'
import { ECharts } from 'echarts'
import moment from 'moment-timezone'
import {
  clearEchartsInstance,
  echartsInitWrapper,
  echartsShowLoadingOptions,
  EchartsShowLoadingType
} from '../../../../lib'
import { LanguageSystemdashboard } from '../../../language/language.interface'
import { ElectricalComponentColors, FerroConfiguration, FerroConfigurationName } from '../../../service'
import {
  processEchartsEnergyData,
  ProcessedEchartEnergyData
} from '../../views/energy-view/energy-view.tools'
import { SystemDashboardService, SystemDashboardServiceName } from '../system-dashboard.service'
import {
  createDailyChart,
  createEquartzGraph,
  createMonthlyEnergyGraph,
  createYearlyEnergyGraph
} from './chart-box.charts'

// eslint-disable-next-line import/no-unresolved
import { GraphqlDashboardService, GraphqlDashboardServiceName } from '@app/graphql'
import { EnergyTimePeriod } from '@app/graphql/graphql-energycounters.service'
import { DEFAULT_TIMEZONE } from '../../../../environments/environment'
import template from './chart-box.component.html?inline'
import './chart-box.component.scss'

let yearGraph: ECharts
let monthGraph: ECharts
let equartzChart: ECharts
let dailyChart: ECharts
let updateDailyChart: (__data: ProcessedEchartEnergyData, error?: string) => void

class ChartBoxController {
  dService: SystemDashboardService
  l: LanguageSystemdashboard
  colors: ElectricalComponentColors
  fcService: FerroConfiguration
  equartzTime: Date
  maxDate: Date
  minDate: Date
  facilityId: number
  timezone: string
  equartzerror: string
  graphqlDashboardService: GraphqlDashboardService
  featureFlagService: FeatureFlagService

  $t: ITimeoutService
  view = 'day'
  dailyTime = new Date()

  constructor(
    ferroConfiguration: FerroConfiguration,
    systemDashboardService: SystemDashboardService,
    $timeout: ITimeoutService,
    graphqlDashboardService: GraphqlDashboardService,
    featureFlagService: FeatureFlagService
  ) {
    this.graphqlDashboardService = graphqlDashboardService

    this.dService = systemDashboardService
    this.fcService = ferroConfiguration

    this.maxDate = ferroConfiguration.getMaxDate()
    this.minDate = ferroConfiguration.getMinDate()
    this.colors = ferroConfiguration.electricColors
    this.facilityId = Number(ferroConfiguration.facility.id)
    this.timezone = ferroConfiguration.facility.timezone
    this.l = ferroConfiguration.language.SystemDashboard
    this.featureFlagService = featureFlagService
    this.equartzTime = moment.tz(new Date(), this.timezone).startOf('isoWeek').toDate()
    this.$t = $timeout
  }

  $onInit(): void {
    this.$t(() => {
      this.dashboardEnergyGraphs()
      this.eqChange()
    }, 500)
  }

  $onDestroy(): void {
    if (yearGraph) clearEchartsInstance(yearGraph)
    yearGraph = null
    if (monthGraph) clearEchartsInstance(monthGraph)
    monthGraph = null
    if (equartzChart) clearEchartsInstance(equartzChart)
    equartzChart = null
    if (dailyChart) clearEchartsInstance(dailyChart)
    dailyChart = null
  }

  async dashboardEnergyGraphs(): Promise<void> {
    this.graphqlDashboardService
      .energyCounterChart(
        this.facilityId,
        {
          gte: moment.tz(new Date(), this.timezone).subtract(5, 'years').toDate(),
          lt: new Date()
        },
        EnergyTimePeriod.Yearly
      )
      .then(async oldFormat => {
        yearGraph = await createYearlyEnergyGraph(
          oldFormat as any,
          this.l,
          this.colors,
          this.timezone,
          undefined
        )
      })
      .finally(() => {
        if (dailyChart) dailyChart.hideLoading()
      })

    const starttime = moment.tz(new Date(), this.timezone).subtract(3, 'years').toDate()
    const endtime = new Date()

    this.graphqlDashboardService
      .energyCounterChart(
        this.facilityId,
        {
          gte: starttime,
          lt: endtime
        },
        EnergyTimePeriod.Monthly
      )
      .then(async oldFormat => {
        monthGraph = await createMonthlyEnergyGraph(
          oldFormat as any,
          this.l,
          this.fcService.language.Globally,
          this.colors,
          this.timezone,
          undefined
        )
      })
      .finally(() => {
        if (dailyChart) dailyChart.hideLoading()
      })

    dailyChart = await echartsInitWrapper('chartBoxDay')
    updateDailyChart = createDailyChart(dailyChart, this.l, this.colors, this.timezone)
    this.dayChange()
  }

  change(): void {
    return
  }

  private dayChange(): void {
    const date = new Date(this.dailyTime)
    const day = moment.tz(date, this.timezone || DEFAULT_TIMEZONE)
    const starttime = day.startOf('day').toDate()
    const endtime = day.endOf('day').add(1, 'hour').toDate()
    if (dailyChart) dailyChart.showLoading(EchartsShowLoadingType, echartsShowLoadingOptions())

    this.graphqlDashboardService
      .energyCounterChart(
        this.facilityId,
        {
          gte: starttime,
          lt: endtime
        },
        EnergyTimePeriod.Hourly
      )
      .then(oldFormat => {
        const parsedData = processEchartsEnergyData(oldFormat as any, false, this.timezone)

        updateDailyChart(parsedData)
      })
      .finally(() => {
        if (dailyChart) dailyChart.hideLoading()
      })
  }

  private async eqChange(): Promise<void> {
    const date = new Date(this.equartzTime)
    const week = moment.tz(date, this.timezone)
    const starttime = week.startOf('isoWeek').toDate()
    const endtime = week.endOf('isoWeek').toDate()
    this.equartzerror = ''
    if (!equartzChart) {
      equartzChart = await echartsInitWrapper('equartzGraph')
      createEquartzGraph(equartzChart, this.l, this.colors, this.timezone)
    }

    if (equartzChart) equartzChart.showLoading(EchartsShowLoadingType, echartsShowLoadingOptions())

    this.graphqlDashboardService
      .energyCounterChart(
        this.facilityId,
        {
          gte: starttime,
          lt: endtime
        },
        EnergyTimePeriod.QuarterHour
      )
      .then(oldFormat => {
        const parsedData = processEchartsEnergyData(oldFormat as any, false, this.timezone)
        equartzChart.setOption({
          title: null,
          dataset: { source: parsedData.totalEnergyData.all }
        })
      })
      .finally(() => {
        if (equartzChart) equartzChart.hideLoading()
      })
  }

  onlyMondays(date: Date): boolean {
    const day = date.getDay()
    return day === 1
  }
}

ChartBoxController.$inject = [
  FerroConfigurationName,
  SystemDashboardServiceName,
  '$timeout',
  GraphqlDashboardServiceName,
  FeatureFlagServiceName
]

export const SystemDashboardChartBoxComponent: IComponentOptions = {
  controller: ChartBoxController,
  template
}
