import { ProcessedEchartEnergyData } from './energy-view.tools'
import { LanguageViews } from '../../../language/language.interface'
import { ElectricalComponentColors } from '../../../service'
import { ECharts, EChartOption } from 'echarts'
import {
  defaultEchartsDataZoom,
  defaultEchartsGrid,
  defaultEchartsYAxisEnergy,
  echartsOnResize,
  echartsToolbox,
  echartsTooltip,
  tooltipDateFormatter,
  echartsInitWrapper,
  echartsTimeFormatters
} from '../../../../lib'

const chartType = 'bar'
export function createSeries(
  data: ProcessedEchartEnergyData,
  l: LanguageViews,
  timezone: string
): {
  series: EChartOption.Series[]
  xAxis: EChartOption.XAxis
  dataset: EChartOption.Dataset
} {
  const dataset = {
    source: data.totalEnergyData.all,
    dimensions: data.totalEnergyData.allHeader
  }

  const series: EChartOption.Series[] = [
    {
      type: chartType,
      name: l.SOLAR_PRODUCTION,
      encode: {
        x: 'ts',
        y: 'pve'
      }
      //data: te.solar,
    },
    {
      type: chartType,
      name: l.CONSUMPTION,
      encode: {
        x: 'ts',
        y: 'load'
      }
    },
    {
      type: chartType,
      name: l.IMPORTED_FROM_GRID,
      encode: {
        x: 'ts',
        y: 'grid'
      }
    },
    {
      type: chartType,
      name: l.SELF_CONSUMPTION_OF_SOLAR,
      encode: {
        x: 'ts',
        y: 'selfCons'
      }
    },
    {
      type: chartType,
      name: l.EXPORTED_TO_GRID,
      encode: {
        x: 'ts',
        y: 'soldToGrid'
      }
    }
  ]

  const xAxis: EChartOption.XAxis = {
    type: 'category',
    boundaryGap: true,
    axisLabel: {
      formatter: echartsTimeFormatters(timezone).hours
    }
  }
  return { series, xAxis, dataset }
}

export async function createEnergyViewChart(
  data: ProcessedEchartEnergyData,
  l: LanguageViews,
  colors: ElectricalComponentColors,
  timezone: string
): Promise<ECharts> {
  const chart = await echartsInitWrapper('BARCHART')
  const tooltip = echartsTooltip()

  tooltip.formatter = tooltipDateFormatter('hours', timezone)
  const _data = createSeries(data, l, timezone)
  const legend = _data.series.map(series => series.name)

  const toolbox: any = echartsToolbox()
  if (toolbox && toolbox.feature) {
    toolbox.feature.magicType = {
      type: ['line', 'bar'],
      title: {
        line: 'Line',
        bar: 'Bar'
      }
    }
  }
  const ops: EChartOption = {
    grid: defaultEchartsGrid,
    legend: {
      data: legend
    },
    dataset: _data.dataset,
    dataZoom: defaultEchartsDataZoom,

    color: [
      colors.solar.line,
      colors.consumption.line,
      colors.grid.line,
      colors.selfConsumption.line,
      colors.gridExport.line
    ],
    yAxis: defaultEchartsYAxisEnergy(l.ENERGY),
    tooltip: tooltip,
    toolbox: toolbox,
    xAxis: _data.xAxis,
    series: _data.series
  }

  echartsOnResize(chart)
  chart.setOption(ops)
  return chart
}
