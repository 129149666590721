export const SpotPriceEnglish = {
  TITLE: 'Spot price optimization',
  UPDATE: 'Update',
  ENABLED: 'Enable',
  DISABLE: 'Disable',
  SPOT_PRICE_INFO:
    "<p>This operating mode is used to control the system according to the current price in the electricity market. This means that the battery charges when the electricity price is at its lowest during the day and discharges when the electricity price is at its highest.</p>      <p><b>About the setting:</b></p>    <ul>     <li>       Set the price delta for when spot price optimization should be enabled.       When the difference between the day's highest and lowest prices is greater than the specified delta, one or more schedule events are created. At other times, the system runs according to the default schedule.   </li>     <li>      If the difference during the day is lower than the specified price delta, the feature will not be enabled and the system will run on the default schedule.     </li>     <li>       Set a limit value for electricity network imports if you pay power tariffs and want to avoid the charging to cause power peaks.     </li>   </ul>",
  MINIMUM_EARNINGS: 'Lower limit for price delta:',
  MINIMUM: 'Minimum value is',
  NO_ENERGY_BIDDING_ZONE: 'No electricity bidding area specified.',
  NO_ENERGY_BIDDING_ZONE_INFO:
    'To be able to run spot price control, we need to know in which electricity bidding area your facility is in.',
  GO_TO_SYSTEM_SETTINGS: 'Go to system settings and electricity bidding area',
  FOR_BIDDING_ZONE: 'Spot price optimization will be run with prices from bidding area:',
  START_SPOT_PRICE: 'ENABLE SPOT PRICE OPTIMIZATION',
  LIMIT_IMPORT_THRESHOLD: 'Limit value for imports',
  LIMIT_IMPORT_THRESHOLD_HINT:
    'Activate the button and fill in the limit value to avoid power peaks being created by charging the battery.',
  SUCCESS_FULLY_CREATED_SPOT_PRICE: 'Successfully activated spot price optimization',
  SUCCESSFULLY_UPDATED_SPOT_PRICE:
    'Successfully updated spot price. If there are calculated spot price schedules for today, the new settings will not be applied until tomorrow.',
  MissingEnergyBiddingZoneError: 'Error occurred: You are missing electricity bidding area',
  ValidationError: 'There is an validation error in your data:',
  DISABLE_SPOT_PRICE_TITLE: 'Do you want to disable spot price optimization?',
  DISABLE_SPOT_PRICE_DESCRIPTION:
    'By doing this the system will stop generating schedules for this facility.',
  SUCCESSFULLY_DISABLED_SPOT_PRICE: 'Successfully disabled spot price optimization',
  ERROR_DISABLE_SPOT_PRICE: 'Something happend which caused the spot price optimization to not stop',
  ACCEPT_DISABLE: 'Alright, disable it.',
  DONT_DISABLE: 'No. I want to keep it',
  DEFAULT_VALUES_INFO: 'By enabling this ferroamp takes cares of the spot price optimization for you',
  LAST_CALCULATIONS_ATTEMPT: 'The latest spot price calculation was run at',
  EMS_CONFIG_MISSING:
    'Missing configuration from the system. Ensure that the system has started and that configuration has been sent.',
  ENERGY_BIDDING_ZONE_NOT_SET: 'No electricity bidding area specified. Set under "System Information"',
  MINIMUM_EARNINGS_NOT_SET: 'Lower limit for price delta missing. Fill in above.',
  CALCULATED: 'Charge and discharge events were created for',
  FAILURE: 'Last spot price schedule calculation failed',
  PRICE_DIFF_TOO_LOW:
    'The price difference between the highest and lowest hour is during the next 24 hours lower than the selected price delta. No schedule event is created',
  CURRENCY_NOT_SET: 'No currency specified',
  CURRENCY_NOT_SET_GO_TO_SYSTEM_SETTINGS: 'Go to system settings and update the currency',
  FEEDBACK: 'Feedback',
  FEEDBACK_TITLE: 'Feedback on spot price optimization',
  RATING: 'What do you think about the spot price optimization?',
  RATING_INFO:
    'Spot price optimization is a new feature in EnergyCloud and we would like to know what you think about it.',
  MUST_CHOOSE_RATING: 'Rate by selecting 1-5 stars',

  COMMENT: 'Comments',
  SAVE: 'Save',
  ABORT: 'Skip',
  ON_DEACTIVATED_FEEDBACK: 'Please rate the feature and let us know why you choose to disable it.',
  ON_SUCCESSFULLY_SENT_FEEDBACK:
    'Thank you for providing feedback, we read all of it and try to improve our services.',
  MissingCurrencyError: 'Error occurred: You are missing currency',
  OTHER_MISSING_DATA: '',
  MissingBatteryError: 'The system does not have a battery, which is required to use spot price control.',
  MissingLatitudeLongitudeError: ''
}

export const SpotPriceSwedish: typeof SpotPriceEnglish = {
  TITLE: 'Optimering av spotpris',
  UPDATE: 'Uppdatera',
  ENABLED: 'Aktivera',
  DISABLE: 'Avaktivera',
  SPOT_PRICE_INFO: `
<p>
  Detta driftläge används för att styra efter det aktuella priset på elmarknaden. Det betyder att batteriet
  laddar när elpriset är som lägst under dygnet och laddar ur när elpriset är som högst.
</p>
<p><b>Om inställningen</b>:</p>
<ul>
  <li>
    Ställ in prisdeltat för när spotprisoptimering ska aktiveras. När skillnaden mellan dygnets högsta och
    lägsta priser är större än det angivna deltat skapas ett eller flera schemaevent. Övrig tid körs systemet
    efter standardschemat.
  </li>
  <li>
    Om skillnaden under dygnet är lägre än det angivna prisdeltat aktiveras inte funktionen och systemet körs
    efter standardschemat.
  </li>
  <li>
    Ställ in gränsvärde för elnätsimporten om du betalar effekttariffer och vill undvika att laddning skapar
    effekttoppar.
  </li>
</ul>
<p>
  Efter att spotpriserna på elmarknaden publicerats (cirka 13.00 dagen före) skapas ett antal event i
  schemaläggningen. Det går att vid behov modifiera dessa i efterhand.
</p>
`,
  MINIMUM_EARNINGS: 'Nedre gräns för prisdelta',
  MINIMUM: 'Minimivärde är',
  NO_ENERGY_BIDDING_ZONE: 'Inget elområde angivet.',
  NO_ENERGY_BIDDING_ZONE_INFO:
    'För att aktivera spotprisoptimering behöver vi veta i vilket elområde anläggningen befinner sig.',
  GO_TO_SYSTEM_SETTINGS: 'Ställ in elområde under ”Systeminformation”',
  FOR_BIDDING_ZONE: 'Spotprisoptimering kommer att köras med priser från elområde:',
  START_SPOT_PRICE: 'AKTIVERA SPOTPRISOPTIMERING',
  LIMIT_IMPORT_THRESHOLD: 'Gränsvärde för import',
  LIMIT_IMPORT_THRESHOLD_HINT:
    'Aktivera knapp och fyll i övre gränsvärde för import från elnätet för att undvika att effekttoppar skapas av att batteriet laddas.',
  SUCCESS_FULLY_CREATED_SPOT_PRICE: 'Spotprisoptimeringen har startat framgångsrikt',
  SUCCESSFULLY_UPDATED_SPOT_PRICE:
    'Spotpris uppdaterat framgångsrikt. Finns det beräknade spotprisscheman för idag så kommer de nya inställningarna inte appliceras för än imorgon.',
  MissingEnergyBiddingZoneError: 'Fel inträffade: Du saknar elområde',
  ValidationError: 'Det finns ett valideringsfel i dina data:',
  DISABLE_SPOT_PRICE_TITLE: 'Vill du inaktivera spotprisoptimering?',
  DISABLE_SPOT_PRICE_DESCRIPTION:
    'Genom att göra detta kommer systemet att sluta generera scheman för denna anläggning.',
  SUCCESSFULLY_DISABLED_SPOT_PRICE: 'Spotprisoptimering inaktiverades framgångsrikt',
  ERROR_DISABLE_SPOT_PRICE: 'Något hände som gjorde att spotprisoptimeringen inte stängdes av',
  ACCEPT_DISABLE: ' Okej, inaktivera den.',
  DONT_DISABLE: 'Nej. Jag vill behålla den',
  DEFAULT_VALUES_INFO: 'Genom att aktivera detta tar Ferroamp hand om spotprisoptimeringen åt dig',
  LAST_CALCULATIONS_ATTEMPT: 'Senaste spotprisberäkningarna kördes klockan',
  EMS_CONFIG_MISSING:
    'Saknar konfiguration från systemet. Säkerställ att systemet startat och att konfiguration skickats.',
  ENERGY_BIDDING_ZONE_NOT_SET: 'Inget elområde angivet. Ställ in under ”Systeminformation”',
  MINIMUM_EARNINGS_NOT_SET: 'Nedre gräns för prisdelta saknas. Fyll i ovan.',
  CALCULATED: 'Scheman för i- och urladdning har skapats för',
  FAILURE: 'Senaste beräkningen av spotprisschema misslyckades',
  PRICE_DIFF_TOO_LOW:
    'Prisskillnaden mellan högsta och lägsta timmen är under kommande dygn lägre än valt prisdelta. Inget schemaevent skapas',
  CURRENCY_NOT_SET: 'Ingen valuta angiven',
  CURRENCY_NOT_SET_GO_TO_SYSTEM_SETTINGS: 'Ställ in valuta under ”Systeminformation”',
  FEEDBACK: 'Tyck till',
  FEEDBACK_TITLE: 'Tyck till om spotprisoptimeringen',
  RATING: 'Vad tycker du om spotprisoptimeringen?',
  RATING_INFO:
    'Spotprisoptimering är en ny funktion i EnergyCloud och vi är nyfikna på vad du tycker om den.',
  MUST_CHOOSE_RATING: 'Ge betyg genom att markera 1-5 stjärnor',
  COMMENT: 'Kommentarer',
  SAVE: 'Spara',
  ABORT: 'Hoppa över',
  ON_DEACTIVATED_FEEDBACK:
    'Ge gärna ett betyg på funktionen och berätta varför du väljer att inaktivera den. ',
  ON_SUCCESSFULLY_SENT_FEEDBACK: 'Tack för att du ger feedback, vi läser allt och försöker förbättra.',
  MissingCurrencyError: 'Fel inträffade, du saknar valuta',
  OTHER_MISSING_DATA: '',
  MissingBatteryError: 'Anläggningen har inget batteri vilket krävs för att använda spotprisstyrning',
  MissingLatitudeLongitudeError: ''
}

export enum SpotPriceFeedbackType {
  GENERAL = 'general',
  DEACTIVATED = 'deactivated'
}
