import { FacilityRatingContext, UserRating } from '@app/graphql/generated'

import { NotificationService } from '@app/components'
import { runQuery } from '@app/graphql/graphql'
import { FerroConfiguration, FerroConfigurationName } from '@app/service'
import { IScope, material } from 'angular'
import { SpotPriceEnglish, SpotPriceFeedbackType } from './spot-price-common'
import './spot-price-feedback.scss'
export class SpotPriceFeedback {
  options: {
    comment: string
    context: FacilityRatingContext
    rating: UserRating
    facilityId: number
  } = {
    comment: '',
    context: FacilityRatingContext.General,
    rating: UserRating.Neutral,
    facilityId: 0
  }

  ratings = [
    {
      rate: UserRating.VeryBad,
      filled: false
    },
    { rate: UserRating.Bad, filled: false },
    {
      rate: UserRating.Neutral,
      filled: false
    },
    {
      rate: UserRating.Good,
      filled: false
    },
    { rate: UserRating.VeryGood, filled: false }
  ]

  l: typeof SpotPriceEnglish
  contextTitle: string
  contextInfo: string
  ratingChoosen = false
  showRatingFeedback = false
  constructor(
    private $mdDialog: material.IDialogService,

    private fc: FerroConfiguration,
    private type: SpotPriceFeedbackType,
    private currentLanguage: typeof SpotPriceEnglish,
    private notifications: NotificationService,
    private $scope: IScope
  ) {
    this.l = currentLanguage
    this.contextTitle =
      type === SpotPriceFeedbackType.GENERAL ? this.l.RATING : this.l.ON_DEACTIVATED_FEEDBACK
    this.contextInfo = SpotPriceFeedbackType.GENERAL ? this.l.RATING_INFO : ''
    this.options.context =
      type == SpotPriceFeedbackType.DEACTIVATED
        ? FacilityRatingContext.SpotPriceDeactivated
        : FacilityRatingContext.SpotPriceGeneral
    this.options.facilityId = +this.fc.facility.id
  }

  $onInit() {
    this.contextTitle =
      this.type === SpotPriceFeedbackType.GENERAL ? this.l.RATING : this.l.ON_DEACTIVATED_FEEDBACK
    this.contextInfo = this.type === SpotPriceFeedbackType.GENERAL ? this.l.RATING_INFO : ''
    this.options.context =
      this.type == SpotPriceFeedbackType.DEACTIVATED
        ? FacilityRatingContext.SpotPriceDeactivated
        : FacilityRatingContext.SpotPriceGeneral
    this.options.facilityId = +this.fc.facility.id
  }

  apply() {
    this.showRatingFeedback = !this.ratingChoosen

    if (this.showRatingFeedback) {
      return
    }

    runQuery
      .spotPriceFeedbackMutation({
        variables: {
          comment: this.options.comment,
          facilityId: +this.fc.facility.id,
          rating: this.options.rating,
          context: this.options.context
        }
      })
      .then(result => {
        if (result?.data?.createOneFacilityRating?.success) {
          this.notifications.onSuccess(this.l.ON_SUCCESSFULLY_SENT_FEEDBACK)
        } else {
          this.notifications.onError(this.fc.language.SystemSettings.ERROR_OCCURRED)
        }
        this.close()
      })
      .catch(() => {
        this.notifications.onError(this.fc.language.SystemSettings.ERROR_OCCURRED)
      })
  }

  close() {
    this.$mdDialog.cancel()
  }

  rate(rate: UserRating) {
    this.options.rating = rate
    this.ratingChoosen = true
    let filled = true
    this.$scope.$applyAsync(() => {
      this.ratings = this.ratings.map(rated => {
        if (rated.rate === rate) {
          filled = false
          return {
            ...rated,
            filled: true
          }
        }
        return {
          ...rated,
          filled
        }
      })
    })
  }
}

SpotPriceFeedback.$inject = [
  '$mdDialog',
  FerroConfigurationName,
  'type',
  'currentLanguage',
  'notificationService',
  '$scope'
]
