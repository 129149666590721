import { copy } from 'angular' //
import { MdbAnalysisLoadEventDetectionResults } from '../@types/load-event-detection'
import { ElectricalComponentColors } from '../../../service'
import { EChartOption } from 'echarts'
import { LanguageAnalaysis } from '../../../language/language.interface'
import { echartsToolbox, roundTo2Decimals } from '../../../../lib'

export interface ParsedLoadSignatureData {
  dataActive: EChartOption.Series[]
  dataReactive: EChartOption.Series[]
  xAxisData: number[]
  yMax: number
  xMax: number
}

export function parseLoadSignatureData(
  data: MdbAnalysisLoadEventDetectionResults,
  colors: ElectricalComponentColors,
  l: LanguageAnalaysis
): ParsedLoadSignatureData {
  const power = data.data.signature.bins
  const events = data.data.signature.events
  const timestamps = data.data.signature.timestamps
  const tsal1 = timestamps.al1
  const tsal2 = timestamps.al2
  const tsal3 = timestamps.al3
  tsal1.pop()
  tsal2.pop()
  tsal3.pop()

  let ymax = 0
  const xmax = power[power.length - 2] / 1000

  power.pop()
  if (power.length > 20) {
    events.al1.shift()
    events.al2.shift()
    events.al3.shift()
    power.shift()
  }
  const powerFixed: number[] = []
  for (let i = 0; i < power.length; i++) {
    if (events.al1[i] > ymax) ymax = events.al1[i]
    if (events.al2[i] > ymax) ymax = events.al2[i]
    if (events.al3[i] > ymax) ymax = events.al3[i]
    // if (events.rl1[i] > ymax) ymax = events.rl1[i];
    // if (events.rl2[i] > ymax) ymax = events.rl2[i];
    // if (events.rl3[i] > ymax) ymax = events.rl3[i];

    powerFixed.push(roundTo2Decimals(power[i] / 1000))
  }

  const barWidth = '5'
  return {
    xAxisData: powerFixed,
    dataActive: [
      {
        type: 'bar',
        name: l.PHASE_1,
        data: events.al1,
        stack: 'one',
        barWidth,
        itemStyle: {
          color: colors.phase1.line
        }
      },
      {
        name: l.PHASE_2,
        type: 'bar',
        stack: 'one',
        barWidth,

        data: events.al2,
        itemStyle: {
          color: colors.phase2.line
        }
      },
      {
        name: l.PHASE_3,
        type: 'bar',
        stack: 'one',
        barWidth,

        data: events.al3,
        itemStyle: {
          color: colors.phase3.line
        }
      }
    ],

    dataReactive: [
      //     {
      //     type: 'bar',
      //     name: 'Reactive L1',
      //     data: rl1,
      //     stack: 'stacked',
      //     itemStyle: {
      //         color: colors.phase1.line,
      //         opacity: 0.4
      //     }
      // }, {
      //     type: 'bar',
      //
      //     name: 'Reactive L2',
      //     data: rl2,
      //     stack: 'stacked',
      //
      //     itemStyle: {
      //         color: colors.phase1.line,
      //         opacity: 0.4
      //     }
      // }, {
      //     type: 'bar',
      //     name: 'Reactive L3',
      //     data: rl3,
      //     stack: 'stacked',
      //     itemStyle: {
      //         color: colors.phase1.line,
      //         opacity: 0.4
      //     }
      // }
    ],
    yMax: ymax,
    xMax: xmax
  }
}

export function getLoadSignatureChartOptions(
  data: ParsedLoadSignatureData,
  l: LanguageAnalaysis
): { optionsMain: EChartOption; optionsSecond: EChartOption } {
  const toolbox = echartsToolbox()

  const baseOptions: EChartOption = {
    legend: {
      data: [l.PHASE_1, l.PHASE_2, l.PHASE_3]
    },
    tooltip: {
      trigger: 'item',
      axisPointer: {
        type: 'cross'
      }
    },
    toolbox: toolbox,
    dataZoom: [
      {
        show: true,
        start: 0,
        end: 100
      },
      {
        show: true,
        yAxisIndex: 0,
        filterMode: 'empty',
        showDataShadow: null,
        left: '93%'
      }
    ],
    yAxis: {
      type: 'value',
      name: l.OCCURRANCES,
      min: 0
    },
    xAxis: {
      data: data.xAxisData,
      type: 'category',
      name: `${l.POWER} [kW]`,
      min: 0
    }
  }

  const optionsMain = copy(baseOptions)
  const optionsSecond = copy(baseOptions)

  optionsMain.series = data.dataActive
  // optionsSecond.series = copy(data.dataActive).map((serie) => {
  //     serie.type = 'bar';
  //     return serie as EChartOption.SeriesBar;
  // });

  return {
    optionsMain,
    optionsSecond
  }
}
