import { EmsParam } from '@ferroamp/system-configuration-lib'
import { EmsConfigV2Language } from '../ems-config-form/ems-config.language'

export function emsConfigV2FrontendValidation(
  config: EmsParam,
  language: EmsConfigV2Language
): string | null {
  if (config.grid.thresholds.low > config.grid.thresholds.high) {
    return language.VAL_GRID_UP_GT_LW // 'Grid upper threshold must be greater than lower threshold';
  }
  const soc = config.battery.socRef
  if (soc.low < 0 || soc.low > 100) return language.VAL_BAT_SOC_BETWEEN // "Battery SoC must be between 0 and 100"
  if (soc.high < 0 || soc.high > 100) return language.VAL_BAT_SOC_BETWEEN // "Battery SoC must be between 0 and 100"
  if (soc.high < soc.low) return language.VAL_BAT_SOC_UP_GT_LW //  "Upper SoC reference must be greater than lower SoC reference"

  return null
}
